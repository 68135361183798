import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import { CloseTicketModal } from '../../modals';
import { getSubjectResultOptions } from '../../../../utils/types';

const CloseTicketModalButton = ({disabled, hidden, resultOptions = [], ticket = {}, handleCloseTicket, handleTicketPropertyChange}) => {
	const [show, setShow] = useState(false);

	if (hidden) return null;
	
	const handleShow = () => setShow(true);
	
	const handleHide = () => setShow(false);
	
	const handleSubmit = ({firstIntention, result, resultComment}) => {
		handleCloseTicket({firstIntention, result, resultComment});
		handleHide();
	}
	
  const subjectResultOptions = getSubjectResultOptions(resultOptions, ticket.subject);

	return <>
		<Button
			bsStyle='primary'
			className='pull-right'
			disabled={disabled}
			onClick={handleShow}
		>
			Clôturer
		</Button>
		
		<CloseTicketModal
			show={show}
			subjectResultOptions={subjectResultOptions}
			ticket={ticket}
			onSubmit={handleSubmit}
			onHide={handleHide}
		/>
	</>;
}

CloseTicketModalButton.propTypes = {
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	resultOptions: PropTypes.array,
	ticket: PropTypes.object,
	handleCloseTicket: PropTypes.func,
	handleTicketPropertyChange: PropTypes.func,
};

export default CloseTicketModalButton;