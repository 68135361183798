import React from 'react';
import {Nav, NavItem, Panel, Tab} from 'react-bootstrap';
import B2B from './B2B';
import FSPlusAddress from './FSPlusAddress';
import GroupedActions from './GroupedActions';
import UserManager from './UserManager';

const ADMIN_TABS = [
	{
		label: 'Actions groupées',
		component: <GroupedActions/>,
	},
	{
		label: 'Gestion des utilisateurs',
		component: <UserManager/>,
	}, {
		label: 'Adresses FS+',
		component: <FSPlusAddress/>,
	}, {
		label: 'B2B',
		component: <B2B/>,
	},
];

const AdminView = () => (
	<Tab.Container id='account-tabs' defaultActiveKey={1}>
		<Panel className='with-nav-tabs'>
			<Panel.Heading>
				<Nav bsStyle='tabs'>
					{ADMIN_TABS.map((tab, index) => (
						<NavItem key={index} eventKey={index + 1}>
							{tab.label}
						</NavItem>
					))}
				</Nav>
			</Panel.Heading>
			<Panel.Body>
				<Tab.Content animation className='with-panel-loading'>
					{ADMIN_TABS.map((tab, index) => (
						<Tab.Pane key={index} eventKey={index + 1}>
							{tab.component}
						</Tab.Pane>
					))}
				</Tab.Content>
			</Panel.Body>
		</Panel>
	</Tab.Container>
);

export default AdminView;