import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Panel, Row } from 'react-bootstrap';
import {TICKET_STATES} from '../../../utils/constants';

const TicketDetailsPanel = ({ handleClose, ticket, content, actions }) => {

  const ref = useRef(null);

  useEffect(function useScrollPanelIntoView() {
    
    const scrollPanelIntoView = () => {
      if (ref.current) ref.current.scrollIntoView();
    };
    
    scrollPanelIntoView();
  }, []);

  let panelClassName = 'warning';
  if (ticket?.state === TICKET_STATES.DOING) panelClassName = 'success';
  else if (ticket?.state === TICKET_STATES.DONE) panelClassName = 'default';
  
  return (
    <div ref={ref}>
      <Panel bsStyle={panelClassName}>
        <Panel.Heading>
          <Row>
            <Col md={12}>
              {ticket?.ticketNumber}
              <span className='close pull-right' onClick={handleClose}>
                <span aria-hidden='true'>&times;</span>
              </span>
            </Col>
          </Row>
        </Panel.Heading>
        <Panel.Body>
          {content}
        </Panel.Body>
        <Panel.Footer>
          {actions}
        </Panel.Footer>
      </Panel>
    </div>
  );
}

TicketDetailsPanel.propTypes = {
  handleClose: PropTypes.func,
  ticket: PropTypes.object,
  content: PropTypes.node,
  actions: PropTypes.node,
};

export default TicketDetailsPanel;