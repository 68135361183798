import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderNothing } from 'recompose';
import { Label } from 'react-bootstrap';

import { SUBSCRIPTION_STATUS_TYPES } from '../../../../../utils/Subscription';

const SubscriptionLabelPure = ({ cancelled, subscription }) => {
    const { subscriptionStatusType } = subscription;
    let subscriptionLabel;

    if (cancelled === true) {
        subscriptionLabel = <Label bsStyle='danger'>Annulé</Label>;
    } else if (subscriptionStatusType === SUBSCRIPTION_STATUS_TYPES.ACTIVATED.value) {
        subscriptionLabel = <Label bsStyle='success'>Abonnement actif</Label>;
    } else if (subscriptionStatusType === SUBSCRIPTION_STATUS_TYPES.DEACTIVATED.value) {
        subscriptionLabel = <Label bsStyle='danger'>Abonnement inactif</Label>;
    } else if (subscriptionStatusType === SUBSCRIPTION_STATUS_TYPES.CONTRACT_CANCELED.value) {
        subscriptionLabel = [
            <Label key={0} bsStyle='danger'>Abonnement inactif</Label>,
            <span key={1}>&nbsp;</span>,
            <Label key={2} bsStyle='danger'>Contrat annulé</Label>,
        ];
    }

    return subscriptionLabel;
};

const SubscriptionLabel = compose(
    branch(
        ({ subscription }) => !subscription,
        renderNothing,
    ),
    pure,
)(SubscriptionLabelPure);

SubscriptionLabel.propTypes = {
  cancelled: PropTypes.bool,
  subscription: PropTypes.object,
};

export default SubscriptionLabel;