import moment from 'moment';
import {errorMsg} from '../../../../../../utils/Notify';
import {isPrepayed} from '../../../../../../utils/Subscription';
import jsRoutes from '../../../../../../routes/routes';

const {BreakageInsuranceController, SupportV2Controller} = jsRoutes.controllers.backoffice;

const submitSubscriptionState = ({currentEditedSubscriptionState, subscription}) => (subscriptionState) => {
  if (subscriptionState.dateBegin) {
    if (currentEditedSubscriptionState + 1 < subscription.metadata.subscriptionStates.length) {
      let previousSubscriptionState = subscription.metadata.subscriptionStates[currentEditedSubscriptionState + 1];

      if (
        !isPrepayed(previousSubscriptionState.type) &&
        !previousSubscriptionState.dateEnd
      ) {
        subscription.metadata.subscriptionStates[currentEditedSubscriptionState + 1].dateEnd = moment(subscriptionState.dateBegin).subtract(1, 'd').valueOf();
      }
    }
  }

  if (currentEditedSubscriptionState === -1) {
    subscription.metadata.subscriptionStates.unshift(subscriptionState);
  } else {
    subscription.metadata.subscriptionStates[currentEditedSubscriptionState] = subscriptionState;
  }

  return {subscription: {...subscription}, currentEditedSubscriptionState: null};
};

const setSubscription = ({
                           contractNumber,
                           savedSubscription,
                           subscription,
                           setLoading,
                         }, subscriberCustomerID, successCallback, errorCallback) =>
  setLoading(
    true,
    () => {
      let subscriptionCopy = {...subscription};
      delete subscriptionCopy.postmanInstallation;

      if (subscriberCustomerID && subscriptionCopy?.metadata?.subscriptionStates?.[0]) {
        subscriptionCopy.metadata.subscriptionStates[0].subscriberCustomerID = subscriberCustomerID;
      }

      subscriptionCopy.terminationReason = subscriptionCopy.terminationReason || null;

      if ([undefined, null].includes(subscriptionCopy.warranty.extensionEnd)) {
        subscriptionCopy.warranty.extension = null;
      }

      SupportV2Controller.setSubscription(contractNumber).ajax({
        data: JSON.stringify(subscriptionCopy),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
      }).then(
        successCallback,
        errorCallback,
      );
    },
  );

const updateBreakageInsuranceStatus = ({
                                         contractNumber,
                                         savedSubscription,
                                         subscription,
                                         handleGetSubscription,
                                         setLoading,
                                       }, response) => {
  if (
    savedSubscription.breakageInsurance &&
    subscription.breakageInsurance &&
    savedSubscription.breakageInsurance.status !== subscription.breakageInsurance.status
  ) {
    BreakageInsuranceController.updateBreakageInsuranceStatus(contractNumber, subscription.breakageInsurance.status)
      .ajax()
      .then(
        () => setLoading(
          false,
          () => handleGetSubscription({
            ...response,
            breakageInsurance: {
              ...response.breakageInsurance,
              status: subscription.breakageInsurance.status,
            },
          }),
        ),
        () => setLoading(false, () => errorMsg('Erreur lors de la mise à jour de l\'option casse')),
      );
  } else {
    setLoading(false, () => handleGetSubscription(response));
  }
};

const saveSubscription = (props, subscriberCustomerID, callback = () => {
}) => {
  const {savedSubscription, setLoading} = props;
  setSubscription(
    props,
    subscriberCustomerID,
    response => {
      // Si date de fin de service séléctionnée/autogénérée antérieure à jour J, pop-up d'alerte
      const localDateToday = new Date();
      localDateToday.setHours(0);
      localDateToday.setMinutes(0);

      if (response.serviceTermination &&
        response.serviceTermination !== savedSubscription.serviceTermination &&
        response.serviceTermination < localDateToday.getTime()) {
        alert('Attention votre demande d\'arrêt ne sera pas traitée automatiquement, désactivez manuellement.');
      }

      updateBreakageInsuranceStatus(props, response);

      callback(response);
    },
    () => setLoading(false, () => errorMsg('Erreur lors de la sauvegarde de la ligne d\'abonnement')),
  );
};

export {
  submitSubscriptionState,
  saveSubscription,
};