import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Glyphicon} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withHandlers, withState} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import jsRoutes from '../../../../../routes/routes';
import {isN2OrHigher} from '../../../../../utils/functions';

const FLAVOR_OPTIONS = ['ardoizBase', 'ardoizTooti2', 'simplyBase', 'simplyTooti2', 'simplyTactilea'];

const FlavorEditPure = ({dmd, isButtonFlavorDisabled, handleFlavorChanged, validateFlavorChanged}) => (
  <FormGroup>
    <ControlLabel>Variante</ControlLabel>
    <FormControl componentClass='select'
                 value={dmd.flavor ? dmd.flavor : ''}
                 onChange={handleFlavorChanged}>
      {FLAVOR_OPTIONS.map(flavor => <option key={flavor} value={flavor}>{flavor}</option>)}
    </FormControl>
    <br/>
    <Button disabled={isButtonFlavorDisabled}
            bsStyle='primary'
            bsSize='small'
            onClick={validateFlavorChanged}>
      <Glyphicon glyph='pencil'/>&nbsp;Valider
    </Button>
  </FormGroup>
);

FlavorEditPure.propTypes = {
  isButtonFlavorDisabled: PropTypes.bool,
  handleFlavorChanged: PropTypes.func,
  validateFlavorChanged: PropTypes.func,
};

const withIsButtonFlavorDisabledState = withState('isButtonFlavorDisabled', 'setIsButtonFlavorDisabledState', true);

const handlers = withHandlers({
  handleFlavorChanged: ({dmd, handleDmdChange, setIsButtonFlavorDisabledState}) => (e) => {
    const value = e.target.value;

    setIsButtonFlavorDisabledState(
      false,
      () => handleDmdChange('flavor', value),
    );
  },
  validateFlavorChanged: ({contractNumber, dmd, setIsButtonFlavorDisabledState}) => () =>
    jsRoutes.controllers.backoffice.SupportV2Controller.setFlavorToAmd(contractNumber, dmd.flavor).ajax({}).then(
      () => setIsButtonFlavorDisabledState(true),
      () => setIsButtonFlavorDisabledState(true),
    ),
});

const FlavorEdit = compose(
  connectMeAndTeamsAndConstantsContext,
  branch(
    ({webUserJson}) => !isN2OrHigher(webUserJson),
    renderNothing,
  ),
  withIsButtonFlavorDisabledState,
  handlers,
  pure,
)(FlavorEditPure);

FlavorEdit.propTypes = {
  contractNumber: PropTypes.string,
  dmd: PropTypes.object,
  handleDmdChange: PropTypes.func,
};

export default FlavorEdit;