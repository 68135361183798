import React from 'react';
import {Col, ControlLabel} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import {FRENCH_DAY_LABELS, FRENCH_MONTH_LABELS, TICKET_STATES} from '../../../../utils/constants';
import {DatePicker} from '../../../components/DatePicker';
import PropTypes from 'prop-types';

const TakeActionFieldsPure = ({
                                tempDate,
                                tempDateForLastDay,
                                handleDateChange,
                              }) => (
  <>
    <Col md={2}>
      <ControlLabel>A traiter le</ControlLabel>
      <DatePicker dayLabels={FRENCH_DAY_LABELS}
                  monthLabels={FRENCH_MONTH_LABELS}
                  value={tempDate}
                  weekStartsOnMonday
                  onChange={d => handleDateChange('takeActionDay', d)}/>
    </Col>
    <Col md={2}>
      <ControlLabel>Au plus tard le </ControlLabel>
      <DatePicker dayLabels={FRENCH_DAY_LABELS}
                  monthLabels={FRENCH_MONTH_LABELS}
                  value={tempDateForLastDay}
                  weekStartsOnMonday
                  onChange={d => handleDateChange('noLaterThanDay', d)}/>
    </Col>
  </>
);

TakeActionFieldsPure.propTypes = {
  tempDate: PropTypes.string,
  tempDateForLastDay: PropTypes.string,
  handleDateChange: PropTypes.func,
};

const TakeActionFields = compose(
  branch(
    ({state}) => state !== TICKET_STATES.DOING && state !== TICKET_STATES.TODO,
    renderNothing,
  ),
  pure,
)(TakeActionFieldsPure);

TakeActionFields.propTypes = {
  state: PropTypes.string,
  tempDate: PropTypes.string,
  tempDateForLastDay: PropTypes.string,
  handleDateChange: PropTypes.func,
};

export default TakeActionFields;