import React from 'react';
import { Glyphicon } from 'react-bootstrap';

const TicketsTableHead = ({property = {}, sort, handleSort}) => {
	let arrow;
	if (sort?.name === property.name) {
		if (sort.reverse) {
			arrow = <Glyphicon glyph='triangle-bottom' bsSize='small' />;
		} else {
			arrow = <Glyphicon glyph='triangle-top' bsSize='small' />;
		}
	}
	return (
		<th style={{ width: property.size, cursor: 'pointer' }} onClick={handleSort}>
			{property.label}&nbsp;{arrow}
		</th>
	);
}

export default TicketsTableHead;