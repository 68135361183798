import React from 'react';
import {sortAlpha} from '../../../utils/Array';
import {SAUVEGARDE_PREFIX, TICKET_SUBJECTS} from '../../../utils/constants';
import {getResultType} from '../../../utils/functions';

const hasValidFirstIntentionSelected = (ticket, types) =>
	ticket.firstIntention
	&& ticket.firstIntention !== '---'

const canSubmitTicketResult = (ticket, types) => getResultType(types, ticket.result) !== 'SAUVEGARDE' || hasValidFirstIntentionSelected(ticket, types);

const getClotureAndFirstIntentions = (options, ticket, types) => {
	let clotures = options.type_cloture.filter(_ => _.startsWith(ticket.subject + '.')),
			clotureOptions,
			firstIntentionValues;

	const sortAndGetResultTypes = c => sortAlpha(c, _ => getResultType(types, _));

	if ([
		TICKET_SUBJECTS.SATISFAIT_REMBOURSE,
		TICKET_SUBJECTS.RESILIATION,
		TICKET_SUBJECTS.WELCOME_CALL,
	].includes(ticket.subject)) {
		// Special case CAN-69
		firstIntentionValues = sortAndGetResultTypes(clotures)
														.filter(cloture => getResultType(types, cloture).startsWith(SAUVEGARDE_PREFIX));

		clotures = clotures.filter(cloture => !getResultType(types, cloture).startsWith(SAUVEGARDE_PREFIX));
	}

	if (clotures.length > 0) {
		clotureOptions = sortAndGetResultTypes(clotures)
											.map(cloture => (
												<option key={cloture} value={cloture}>
													{getResultType(types, cloture)}
												</option>
											));
	}

	return {
		clotureOptions,
		firstIntentionValues,
	}
};

export {
	canSubmitTicketResult,
	getClotureAndFirstIntentions,
	hasValidFirstIntentionSelected,
};