import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import SpecialContract from './SpecialContract';
import ContractManager from './ContractManager';

const LaPosteContractLayout = ({
  contractNumber,
  loading,
  progress,
  ticketNumber,
  tags,
  tickets,
  addTicket,
  deleteTicket,
  removeTicket,
  setTicket,
  setAnswer,
}) => (
  <SpecialContract
    header={
      <Alert bsStyle="warning">
        <h3>La Poste</h3>
        <p>Référencer ici les tickets La Poste non liés à une tablette.</p>
      </Alert>
    }
    contractNumber={contractNumber}
    loading={loading}
    progress={progress}
    ticketNumber={ticketNumber}
    tags={tags}
    tickets={tickets}
    deleteTicket={deleteTicket}
    removeTicket={removeTicket}
    addTicket={addTicket}
    setTicket={setTicket}
    setAnswer={setAnswer}
  />
);

LaPosteContractLayout.propTypes = {
  contractNumber: PropTypes.string,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  ticketNumber: PropTypes.string,
  tags: PropTypes.array,
  tickets: PropTypes.array,
  deleteTicket: PropTypes.func,
  removeTicket: PropTypes.func,
  setTicket: PropTypes.func,
  setAnswer: PropTypes.func,
};

const LaPosteContract = ({contractNumber, ticketNumber}) => (
  <ContractManager
    Component={LaPosteContractLayout}
    contractNumber={contractNumber}
    ticketNumber={ticketNumber}
  />
);

LaPosteContract.propTypes = {
  contractNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
};

export default LaPosteContract;