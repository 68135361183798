import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DetachContractModal = ({device, handleClose, handleSubmit}) => {
  return (
    <Modal show={!!device} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Détacher le contrat</Modal.Title>
      </Modal.Header>
      <Modal.Body>{'Êtes-vous sur  de vouloir détacher le contrat ' + (device ? device.contractNumber : '') + ' ?'}</Modal.Body>
      <Modal.Footer>
        <Button bsStyle='success' onClick={handleClose}>
          Annuler
        </Button>
        <Button bsStyle='danger' className='pull-left' onClick={handleSubmit}>
          Détacher
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DetachContractModal;