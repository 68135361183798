import React from 'react';
import { Button, ButtonToolbar, Col, Panel }from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers } from 'recompose';
import { notify } from 'react-notify-toast';

import jsRoutes from '../../../routes/routes';
import withLoading from '../../../hoc/withLoading';

const addressSchema = {
    type: 'object',
    'definitions': {},
    required: [],
    properties: {
        'gender': { title: 'Genre', type: 'string' },
        'firstName': { title: 'Prénom', type: 'string' },
        'lastName': { title: 'Nom', type: 'string' },
        'company': { title: 'Société', type: 'string' },
        'email': { title: 'Email', type: 'string' },
        'phone': { title: 'Téléphone', type: 'string' },
        'addressLine': {
            title: 'Adresses',
            type: 'array',
            items: { type: 'string' }
        },
        'city': { title: 'Ville', type: 'string' },
        'zipCode': { title: 'Code postal', type: 'string' },
    }
};

const FSPlusAddressEditPure = ({ address, zipCode, handleAddressChange, handleHide, handleSubmit }) => (
    <div className='center-block'>
        <Col md={6} className='center-block' style={{ float: 'none' }}>
            <Panel show='true' bsStyle='info'>
                <Panel.Heading>
                    <Panel.Title>Édition d'une adresse FS+</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <Col md={8} className='center-block' style={{ float: 'none' }}>
                        <Form schema={addressSchema}
                              formData={address}
                              onChange={({ formData }) => handleAddressChange(formData, zipCode)}>
                            <ButtonToolbar className='pull-right'>
                                <Button bsStyle='success' onClick={handleSubmit}>Sauvegarder</Button>
                                <Button bsStyle='danger' onClick={handleHide}>Annuler</Button>
                            </ButtonToolbar>
                        </Form>
                    </Col>
                </Panel.Body>
            </Panel>
        </Col>
    </div>
);

const setFSPlusAddressRequest = (zipCode, address) => jsRoutes.controllers.backoffice.SupportV2Controller.setFSPlusAddress(zipCode).ajax({
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(address)
});

const withSubmitHandler = withHandlers({
    handleHide: ({ handleAddressChange }) => () => handleAddressChange(null),
    handleSubmit: ({ address, zipCode, handleAddressChange, setLoading }) => () =>
        setLoading(
            true,
            () => setFSPlusAddressRequest(zipCode, address).then(
                () => setLoading(false),
                () => {
                    setLoading(false);
                    notify.show('Erreur lors de la sauvegarde', 'error');
                },
            )
        ),
});

FSPlusAddressEditPure.propTypes = {
    handleHide: PropTypes.func,
    handleSubmit: PropTypes.func,
};

const FSPlusAddressEdit = compose(
    withLoading,
    withSubmitHandler,
    pure,
)(FSPlusAddressEditPure);

FSPlusAddressEdit.propTypes = {
    address: PropTypes.object,
    zipCode: PropTypes.string,
    handleAddressChange: PropTypes.func,
};

export default FSPlusAddressEdit;