import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {compose, lifecycle, pure, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import GET_WEBUSER from '../../../operations/WebUser/Get';
import {sortAlpha} from '../../../utils/Array';
import {USER_TYPES} from '../../../utils/constants';
import {errorMsg} from '../../../utils/Notify';
import {convertRights, isAdmin, USER_RIGHTS} from '../../../utils/UserRights';
import JsonSchemaForm from '../../components/Form/JsonSchemaForm';

const MODAL_TITLE_ADD = 'Ajouter un utilisateur';
const MODAL_TITLE_EDIT = 'Modification de l\'utilisateur';
const SUBMIT_LABEL_ADD = 'Ajouter';
const SUBMIT_LABEL_EDIT = 'Modifier';

const formatUserRightsProperties = () => {
    const formattedUserRightsProperties = {};
    USER_RIGHTS.forEach(
        ({key, label}) => formattedUserRightsProperties[key] = ({
            title: label,
            type: 'boolean',
        }),
    );
    return formattedUserRightsProperties;
}

const disableAdminOnlyUserRightsPropertiesIfNeeded = webUserJson => {
    const disabledRightsProperties = {};
    USER_RIGHTS.forEach(
            ({key, adminOnly}) => {
                if (adminOnly && !isAdmin(webUserJson)) {
                    disabledRightsProperties[key] = ({
                        'ui:disabled': key,
                    });
                }
            },
        );
    return disabledRightsProperties;
}

const schemaFactory = ({formData, types, webUserJson}) => {
    const userTypesId = Object.keys(USER_TYPES).map(key => parseInt(key, 10));
    const userTypesLabel = Object.values(USER_TYPES);

    const teams = sortAlpha(Object.entries(types.type_team), _ => _[1]);
    const teamsId = teams.map(keyValue => parseInt(keyValue[0], 10));
    const teamsLabel = teams.map(keyValue => keyValue[1]);

    let properties = {
        type: {
            type: 'number',
            title: 'Type',
            enum: userTypesId,
            enumNames: userTypesLabel,
            default: 1,
        },
        identifier: {type: 'string', title: 'Identifiant'},
        team: {
            type: 'number',
            title: 'Equipe',
            enum: teamsId,
            enumNames: teamsLabel,
        },
        rights: {
            title: 'Droits utilisateur',
            type: 'object',
            properties: {
                ...formatUserRightsProperties(),
            },
        },
    };

    if (formData.type && formData.type === 2) { // Autre
        properties = {
            ...properties,
            lastName: {type: 'string', title: 'Nom'},
            firstName: {type: 'string', title: 'Prénom'},
        };
    }

    return {
        type: 'object',
        properties: properties,
    };
};
const uiSchemaFactory = ({formData, webUserJson}) => {
    return {
        type: {
            'ui:widget': 'select',
            'ui:readonly': !!formData.id,
        },
        identifier: {
            'ui:readonly': !!formData.id,
        },
        rights: {
            ...disableAdminOnlyUserRightsPropertiesIfNeeded(webUserJson),
        },
    };
};

const UserEditPure = ({
                          formData,
                          initialUser,
                          schema,
                          show,
                          uiSchema,
                          userId,
                          handleSubmit,
                          onHide,
                          setFormData,
                      }) => (
    <Modal show={show}
           onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-lg'>
                {userId ? `${MODAL_TITLE_EDIT} ${initialUser.identifier}` : MODAL_TITLE_ADD}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <JsonSchemaForm formData={formData}
                            schema={schema}
                            showErrorList={false}
                            uiSchema={uiSchema}
                            onChange={_ => setFormData(_.formData)}
                            onSubmit={handleSubmit}>
                <div className='text-right'>
                    <Button bsStyle='primary'
                            data-test-id='submit'
                            style={{marginRight: 10}}
                            type='submit'>
                        {userId ? SUBMIT_LABEL_EDIT : SUBMIT_LABEL_ADD}
                    </Button>
                    <Button bsStyle='danger'
                            data-test-id='cancel'
                            onClick={onHide}>
                        Annuler
                    </Button>
                </div>
            </JsonSchemaForm>
        </Modal.Body>
    </Modal>
);

UserEditPure.propTypes = {
    formData: PropTypes.object,
    initialUser: PropTypes.object,
    schema: PropTypes.object,
    uiSchema: PropTypes.object,
    show: PropTypes.bool,
    userId: PropTypes.string,
    onHide: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFormData: PropTypes.func,
};

const withFormDataState = withState('formData', 'setFormData', {
    _id: '',
    id: '',
    type: 1,
    lastName: '',
    firstName: '',
    team: '',
    rights: {},
});

const withUserState = withState('initialUser', 'setInitialUser', {});

const withLifecycle = lifecycle({
    componentDidMount() {
        if (this.props.userId) {
            const {userId, setFormData, setInitialUser} = this.props;
            GET_WEBUSER(
                userId,
                user => {
                    const rights = {};
                    USER_RIGHTS
                        .forEach(({key}) =>
                            rights[key] = !!user.rights.find(right => right.value === key),
                        );

                    setInitialUser(
                        user,
                        () => setFormData({
                            ...user,
                            team: user.team ? parseInt(user.team, 10) : null,
                            rights,
                        }),
                    );
                },
                () => errorMsg('Erreur lors de la récupération de l\'utilisateur'),
            );
        }
    },
});

const withCalculatedProps = withProps(
    props => ({
        schema: schemaFactory(props),
        uiSchema: uiSchemaFactory(props),
    }),
);

const handlers = withHandlers({
    handleSubmit: ({formData, onSubmit}) => () => {
        let user = formData;
        if (user.type === 1) { // CCA
            user = {
                ...user,
                lastName: user.identifier,
                firstName: 'CCA',
                isCCAUser: true,
            };
        } else {
            user.isCCAUser = false;
        }

        user.team = user.team.toString();
        user.rights = convertRights(user.rights);

        onSubmit(user);
    },
});

const UserEdit = compose(
    connectMeAndTeamsAndConstantsContext,
    withFormDataState,
    withLoading,
    withUserState,
    withLifecycle,
    withCalculatedProps,
    handlers,
    pure,
)(UserEditPure);

UserEdit.propTypes = {
    show: PropTypes.bool,
    userId: PropTypes.string,
    onHide: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default UserEdit;