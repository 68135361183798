import React from 'react';
import {Col, ControlLabel, FormGroup, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {compose, pure, withProps} from 'recompose';

import { connectMeAndTeamsAndConstantsContext } from '../../../../../hoc/withMeAndTeamsAndConstants';
import {
    AFTERSALE_STATUS,
    PRODUCT_STATES,
    PRODUCT_TYPE,
    QUOTE_STATUS,
    RETURN_TYPES,
} from '../../../../../utils/AfterSale';
import {isN3User} from '../../../../../utils/functions';
import Select from './Select';

const ProcessingInfosPure = ({afterSale, canEditProcessingFields, canEditStatus, hasProcessingFields, handleAfterSaleInfosChange, handleAfterSaleRequestChange}) => (
    <fieldset className='custom-fieldset-blue'>
        <legend>Traitement</legend>
        <Row>
            <Col md={12}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>État</ControlLabel>
                    <Select arrayValues={Object.values(AFTERSALE_STATUS)}
                            disabled={!canEditStatus}
                            value={afterSale.currentStatus}
                            handleChange={e => handleAfterSaleInfosChange('currentStatus', e.target.value)}/>
                </FormGroup>
            </Col>
        </Row>
        {hasProcessingFields &&
        <>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <ControlLabel bsSize='sm'>Traitement client</ControlLabel>
                        <Select arrayValues={Object.values(QUOTE_STATUS)}
                                disabled={canEditProcessingFields}
                                value={afterSale?.afterSaleRequest?.quoteState}
                                handleChange={e => handleAfterSaleRequestChange('quoteState', e.target.value)}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <ControlLabel bsSize='sm'>État du produit de remplacement</ControlLabel>
                        <Select arrayValues={PRODUCT_STATES}
                                disabled={canEditProcessingFields}
                                value={afterSale?.afterSaleRequest?.productState}
                                handleChange={e => handleAfterSaleRequestChange('productState', e.target.value)}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <ControlLabel bsSize='sm'>Modèle de remplacement</ControlLabel>
                        <Select arrayValues={PRODUCT_TYPE}
                                disabled={canEditProcessingFields}
                                value={afterSale?.afterSaleRequest?.productType}
                                handleChange={e => handleAfterSaleRequestChange('productType', e.target.value)}/>
                    </FormGroup>
                </Col>
            </Row>
        </>
        }
    </fieldset>
);

ProcessingInfosPure.propTypes = {
    afterSale: PropTypes.object,
    canEditProcessingFields: PropTypes.bool,
    canEditStatus: PropTypes.bool,
    hasProcessingFields: PropTypes.bool,
};

const getHasProcessingFields = returnType =>
    RETURN_TYPES.AFTER_SALE_SERVICE.value === returnType;

const withCalculatedProps = withProps(
    ({afterSale, webUserJson}) => ({
        canEditProcessingFields: !afterSale?.currentState?.canBeUpdatedWithQuoteAnswer,
        canEditStatus: isN3User(webUserJson),
        hasProcessingFields: getHasProcessingFields(afterSale?.returnType),
    })
);

const ProcessingInfos = compose(
    connectMeAndTeamsAndConstantsContext,
    withCalculatedProps,
    pure,
)(ProcessingInfosPure);

ProcessingInfos.propTypes = {
    afterSale: PropTypes.object,
    handleAfterSaleInfosChange: PropTypes.func,
    handleAfterSaleRequestChange: PropTypes.func,
};
export default ProcessingInfos;