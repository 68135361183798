import React, { Component } from 'react';
import { Button, FormControl, FormGroup, Glyphicon, Table } from 'react-bootstrap';
import moment from 'moment';

import {arrayOrObjectEquals, navigateToTicket} from '../../../../../utils/functions';
import UltimatePagination from '../../../../components/UltimatePagination';

import AntennasTableEntry from './AntennasTableEntry';

class AntennasTableBlock extends Component {

    constructor(props) {
        super(props);
        this.state = this.getComponentInitialState();
    }

    getComponentInitialState() {
        return {
            loading: false,
            entries: null,
            properties: [
                {
                    name: 'Opérateur',
                    size: '10%',
                    type: 'operator'
                }, {
                    name: 'Adresse',
                    size: '40%'
                }, {
                    name: 'Distance',
                    size: '10%'
                }, {
                    name: 'Type antenne',
                    size: '10%',
                    type: 'typeAntenna'
                }, {
                    name: 'Fréquence antenne',
                    size: '20%',
                    type: 'frequencyAntenna'
                }, {
                    name: 'Dispo',
                    size: '10%',
                }
            ],
            activePage: 1,
            sort: {
                name: 'Distance',
                reverse: false
            },
            filters: {'Type antenne': '3G'},
            currentAntenna: null
        };
    }

    componentDidMount() {
        this.setEntries(this.props.antennas);
    }

    componentDidUpdate(previousProps) {
        if (previousProps.contractNumber !== this.props.contractNumber ||
        !arrayOrObjectEquals(this.props.antennas, previousProps.antennas)) {
            this.setState(this.getComponentInitialState());
            this.setEntries(this.props.antennas);
        }
    }

    onEntryClick(ID) {
        navigateToTicket(this.props.contractNumber, ID);
    }

    tryToParse(value){
      let number = parseInt(value, 10);
        if(isNaN(number)){
            return value;
        }
        return number;
    }

    formatEntries(antenna) {
        moment.locale('fr');
        if(antenna) {
            let lat, lon;
            if(antenna.fields && antenna.fields.coordonnees) {
                const coords = antenna.fields.coordonnees.split(', ');
                if(coords.length === 2) {
                    lon = coords[1];
                    lat = coords[0];
                }
            }

            return {
                '_id': antenna.fields ? antenna.fields.id : 0,
                'Opérateur': antenna.fields ? antenna.fields.adm_lb_nom : '',
                'Adresse': antenna.fields ? antenna.fields.adr_lb_add1 + ' '+ (antenna.fields.adr_lb_add2 ? antenna.fields.adr_lb_add2 : '') + ' ' + (antenna.fields.nom_com ? antenna.fields.nom_com : '') : '',
                'Distance': antenna.fields ? parseInt(antenna.fields.dist, 10) : '',
                'Type antenne': antenna.fields ? antenna.fields.generation : '',
                'Fréquence antenne': antenna.fields ? antenna.fields.emr_lb_systeme : '',
                'Dispo': antenna.fields ? antenna.fields.en_service : '',
                'lat': lat,
                'lon': lon,
            };
        }
    }

    setEntries(antennas) {
        let _this = this;
        if(antennas){
            let entries = antennas.map(antenna => _this.formatEntries(antenna));
            let activePage = 1;

            this.setState({
                entries: entries,
                activePage: activePage
            });
        }
    }

    addEntry(antenna) {}

    onPageSelected(num) {
        this.setState({activePage: num});
    }

    onFilterInputChange(name, event) {
        if (name === '') {
            this.setState({filters: null, activePage: 1});
            return;
        }
        var filters = this.state.filters;
        if (!filters) {
            filters = [];
        }
        if (!event.target.value) {
            delete filters[name];
        } else {
            filters[name] = event.target.value;
        }
        this.setState({filters: filters, activePage: 1});
    }

    onSortClicked(name, event) {
        var sort = this.state.sort;
        if (!sort) {
            sort = [];
        }
        if (sort.name === name) {
            sort.reverse = !sort.reverse;
        } else {
            sort.reverse = false;
        }
        sort.name = name;
        this.setState({sort: sort});
    }

    isFiltered(entry) {
        if (!this.state.filters)
            return true;

        for (var property in this.state.filters) {
            let filterValue = this.state.filters[property];
            let revertFilter = filterValue  && filterValue.toString().indexOf('$') === 0;
            let cleanedValue = revertFilter ? filterValue.substring(1):filterValue;

            let containsValue;

            if (property === 'Demande' || property === 'État'){
                containsValue = entry.hasOwnProperty(property) && entry[property]  && entry[property].toString() === cleanedValue;
            }else {
                containsValue = entry.hasOwnProperty(property) && entry[property]  && entry[property].toString().toLocaleLowerCase().indexOf(cleanedValue.toLocaleLowerCase()) !== -1;
            }
            if((containsValue && revertFilter) || (!containsValue && !revertFilter)) {
                return false;
            }
        }
        return true;
    }

    toFilterSelectInput(property, list){
        return (
            <FormGroup className='small-control' bsSize='small'>
                <FormControl componentClass='select'
                             value={this.state.filters && this.state.filters[property.name] ? this.state.filters[property.name]: ''}
                             onChange={this.onFilterInputChange.bind(this, property.name)}
                             placeholder={property.name}>
                    <option value=''>---</option>
                    {list.map(type_value=>
                        <option key={type_value} value={type_value}>{type_value}</option>
                    )}
                </FormControl>
            </FormGroup>
        );
    }

    toFilterInput(property){
        let filterInput;
        let tabAntennas = ['2G', '3G', '4G'];
        let tabOperators = this.props.adm_nom;
        let tabFrequency = this.props.emr_systems;
        if(property.name === 'Type antenne'){
            filterInput = this.toFilterSelectInput(property, tabAntennas);
        }else if(property.name === 'Fréquence antenne'){
            filterInput = this.toFilterSelectInput(property, tabFrequency);
        }else if(property.name === 'Opérateur'){
            filterInput = this.toFilterSelectInput(property, tabOperators);
        }
          else{
            filterInput = (
                <FormGroup className='small-control' bsSize='small'>
                    <FormControl type='text'
                                 value={this.state.filters && this.state.filters[property.name] ? this.state.filters[property.name]: ''}
                                 onChange={this.onFilterInputChange.bind(this, property.name)} />
                </FormGroup>
            );
        }
        return <th key={'property_filter_'+property.name}>{filterInput}</th>;
    }

    getFilteredAndSortedEntries(entries){
        let _this = this;
        var filteredEntries = entries.filter(e => _this.isFiltered(e));

        return filteredEntries.sort(function(a, b) {
            if (!_this.state.sort) {
                return a.ID.localeCompare(b.ID);
            } else {
                let diff;
                if (_this.state.sort.reverse) {
                    if(!b[_this.state.sort.name]){
                        if(!a[_this.state.sort.name]){
                            diff = 0;
                        }else {
                            diff = 1;
                        }
                    }else {
                        if (!a[_this.state.sort.name]) {
                            diff = -1;
                        } else {
                            if (typeof b[_this.state.sort.name] === 'number') {
                                diff = b[_this.state.sort.name] - a[_this.state.sort.name];
                            } else {
                                diff = b[_this.state.sort.name].toString().localeCompare(a[_this.state.sort.name].toString())
                            }
                        }
                    }
                } else {
                    if(!a[_this.state.sort.name]){
                        if(!b[_this.state.sort.name]){
                            diff = 0;
                        }else {
                            diff = 1;
                        }
                    }else {
                        if (!b[_this.state.sort.name]) {
                            diff = -1;
                        } else {
                            if (typeof a[_this.state.sort.name] === 'number') {
                                diff = a[_this.state.sort.name] - b[_this.state.sort.name];
                            } else {
                                diff = a[_this.state.sort.name].toString().localeCompare(b[_this.state.sort.name].toString())
                            }
                        }
                    }
                }
                if (diff === 0) {
                    diff = b.ID - a.ID;
                }
                return diff;
            }
        });
    }

    render() {
        var CRUDEntries;
        var _this = this;
        var clearButton;
        var pageGroup;
        if (this.state.entries) {
            var itemsByPage = 10;


            clearButton = (
                <Button className='center-block' bsStyle='primary' bsSize='small' onClick={this.onFilterInputChange.bind(_this, '')} disabled={this.state.loading && this.state.filters}>
                    <Glyphicon glyph='remove'/> Effacer
                </Button>
            );

            var filteredEntries = this.getFilteredAndSortedEntries(this.state.entries);

            if(filteredEntries.length > 0) {
                const pageNumber = Math.ceil(filteredEntries.length / itemsByPage);
                pageGroup = (<UltimatePagination totalPages={pageNumber} currentPage={this.state.activePage} onChange={this.onPageSelected.bind(this)}/>);
            }

            var entries = filteredEntries
                .slice((_this.state.activePage - 1) * itemsByPage, (_this.state.activePage) * itemsByPage)
                .map(entry =>
                    <AntennasTableEntry key={entry._id} onEntryClick={_this.onEntryClick.bind(_this, entry.ID)} properties={_this.state.properties} entry={entry} />
                );
            CRUDEntries = (
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            {this.state.properties.map(property => {
                                let caret;
                                if (_this.state.sort.name === property.name) {
                                    if (_this.state.sort.reverse) {
                                        caret = <Glyphicon glyph='triangle-bottom' bsSize='small'/>;
                                    } else {
                                        caret = <Glyphicon glyph='triangle-top' bsSize='small'/>;
                                    }
                                }
                                return (
                                    <th style={{
                                        width: property.size,
                                        cursor: 'pointer'
                                    }} key={'property_header_' + property.name} onClick={_this.onSortClicked.bind(_this, property.name)}>
                                        {property.name}&nbsp; {caret}
                                    </th>
                                );
                            })}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.state.properties.map(property => _this.toFilterInput(property))}
                            <th>{clearButton}</th>
                        </tr>
                        {entries}
                    </tbody>
                </Table>
            );

        }

        return (
            <div>
                {CRUDEntries}
                <div className='text-center'>
                    {pageGroup}
                </div>
            </div>
        )

    }
}

export default AntennasTableBlock;