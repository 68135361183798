import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, HelpBlock, Panel, Row} from 'react-bootstrap';
import {connectTypesAndWebUserContext} from '../../../../hoc/withMeAndTeamsAndConstants';
import {sortAlpha} from '../../../../utils/Array';
import {getCanalType, isN2OrHigher} from '../../../../utils/functions';
import { AUTOMATED_TICKET_SUBJECTS } from '../../../../utils/constants';

const NewTicketAnswer = connectTypesAndWebUserContext(({ webUserJson, types, closed, ticketSubject, options, newAnswer = {}, setNewAnswer }) => {
	const webUserIsLowerThanN2 = !isN2OrHigher(webUserJson);
	const locked = AUTOMATED_TICKET_SUBJECTS.includes(ticketSubject);
	const lockedForLowerThanN2 = locked && webUserIsLowerThanN2;
	
	if (closed || lockedForLowerThanN2) return null;
	
	const handleAnswerCanalChange = event => {
		const canal = event.target.value;
		setNewAnswer(answer => ({...answer, canal}));
	}

	const handleAnswerContentChange = event => {
		const content = event.target.value;
		setNewAnswer(answer => ({...answer, content}));
	}

	const handleAnswerAttachmentChange = event => {
		const attachment = event.target.files[0];
		setNewAnswer(answer => ({...answer, attachment}));
	}
	
	const { canal, content } = newAnswer;
	return (
		<Panel>
			<Panel.Heading>Ajouter une réponse</Panel.Heading>
			<Panel.Body>
				<Row>
					<Col md={4}>
						<FormGroup>
							<ControlLabel>Canal</ControlLabel>
							<FormControl
								componentClass='select'
								data-test-id='answerCanalSelect'
								placeholder='Canal'
								value={canal}
								onChange={handleAnswerCanalChange}
							>
								<option value=''>---</option>

								{sortAlpha(options.type_canal, _ => getCanalType(types, _))
								.map(type_canal => (
									<option key={type_canal} value={type_canal}>
										{getCanalType(types, type_canal)}
									</option>
								))}
							</FormControl>
						</FormGroup>
					</Col>
					<Col md={8}>
						<FormGroup>
							<ControlLabel>Contenu de la réponse</ControlLabel>
							<FormControl
								componentClass='textarea'
								data-test-id='answerContentInput'
								placeholder='Saisir le contenu du ticket'
								value={content}
								onChange={handleAnswerContentChange}
							/>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<ControlLabel>Fichier</ControlLabel>
							<FormControl
								data-test-id='answerAttachmentInput'
								type='file'
								onChange={handleAnswerAttachmentChange}
							/>
							<HelpBlock>Fichier à ajouter à la réponse </HelpBlock>
						</FormGroup>
					</Col>
				</Row>
			</Panel.Body>
		</Panel>
	);
});

NewTicketAnswer.propTypes = {
	closed: PropTypes.bool,
	ticketSubject: PropTypes.string,
	options: PropTypes.object,
	newAnswer: PropTypes.object,
	setNewAnswer: PropTypes.func,
};

export default NewTicketAnswer;