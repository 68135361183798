import React from 'react';
import { Button, Form, FormControl, FormGroup, Glyphicon, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withState } from 'recompose';
import jsRoutes from '../../../routes/routes';
import withLoading from '../../../hoc/withLoading';

const withZipCodeState = withState('zipCode', 'setZipCode', '');

const FSPlusAddressSearchPure = ({ zipCode, handleAddressSearch, setZipCode }) => (
    <Form>
        <Row>
            <Col md={5} />
            <Col md={2}>
                <FormGroup>
                    <FormControl value={zipCode} onChange={(event) => setZipCode(event.target.value)} type='text' placeholder='FS+'/>
                </FormGroup>
            </Col>
            <Col>
                <Button disabled={!zipCode} type='submit' onClick={() => handleAddressSearch()}><Glyphicon glyph='search'/></Button>
            </Col>
        </Row>
    </Form>
);

const withSearchHandler = withHandlers({
    handleAddressSearch: ({ zipCode, handleAddressChange, setLoading }) => () => {
        let route = jsRoutes.controllers.backoffice.SupportV2Controller.getFSPlusAddress(zipCode);
        setLoading(true);
        route.ajax({
            dataType: 'json',
            processData: false,
        }).then(function (response, textStatus, jqXHR) {
            setLoading(false);
            handleAddressChange(response, zipCode);
        }, function (jqXHR, textStatus, errorThrown) {
            setLoading(false);
        });
    }
});

FSPlusAddressSearchPure.propTypes = {
    zipCode: PropTypes.string,
    handleAddressSearch: PropTypes.func,
    setZipCode: PropTypes.func,
};

const FSPlusAddressSearch = compose(
    withZipCodeState,
    withLoading,
    withSearchHandler,
    pure,
)(FSPlusAddressSearchPure);

FSPlusAddressSearch.propTypes = {
    handleAddressChange: PropTypes.func,
};

export default FSPlusAddressSearch;