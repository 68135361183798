import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';
import DateInput from './DateInput';

const InstallationInfosPure = ({
                                 deliveryDate,
                                 postmanInstallationDate,
                                 postmanInstallationLabel,
                                 purchaseDate,
                                 shippedDate,
                                 subscription,
                                 trackingNumber,
                                 handleDateChange,
                               }) => (
  <>
    <h4>Installation (première commande)</h4>
    <Row>
      <Col md={4}>
        <FormGroup>
          <ControlLabel>Date d'achat</ControlLabel>
          <FormControl type="text" value={purchaseDate} readOnly/>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <ControlLabel>Date d'envoi</ControlLabel>
          <FormControl type="text" value={shippedDate} readOnly/>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <ControlLabel>Numéro de tracking</ControlLabel>
          <FormControl type="text" value={trackingNumber} readOnly/>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col md={4}>
        <FormGroup>
          <ControlLabel>Date de livraison</ControlLabel>
          <FormControl type="text" value={deliveryDate} readOnly/>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <ControlLabel>Prestation facteur</ControlLabel>
          <FormControl type="text" value={postmanInstallationLabel} readOnly/>
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <ControlLabel>Date prestation facteur</ControlLabel>
          <FormControl type="text" value={postmanInstallationDate} readOnly/>
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col md={3}>
        <FormGroup>
          <ControlLabel>Date de formation Sérénité</ControlLabel>
          <DateInput modificationEnabled
                     value={subscription?.metadata?.sereniteFormationTimestamp}
                     handleChange={event => handleDateChange('metadata.sereniteFormationTimestamp', event)}/>
        </FormGroup>
      </Col>
    </Row>
  </>
);

InstallationInfosPure.propTypes = {
  deliveryDate: PropTypes.string,
  postmanInstallationDate: PropTypes.string,
  postmanInstallationLabel: PropTypes.string,
  purchaseDate: PropTypes.string,
  shippedDate: PropTypes.string,
  trackingNumber: PropTypes.string,
};

const formatDate = subscription => dateProperty => (subscription && subscription[dateProperty] && moment(subscription[dateProperty]).format('L')) || '';

const withCalculatedProps = withProps(
  ({subscription}) => {
    const formatSubscriptionDate = formatDate(subscription);
    const {postmanInstallation, postmanInstallationType, postmanInstallationTypes} = subscription;
    const postmanInstallationLabel = postmanInstallation ? postmanInstallationType ? postmanInstallationTypes[postmanInstallationType] : 'SIMPLE' : 'NON';

    return {
      deliveryDate: formatSubscriptionDate('deliveryDate'),
      postmanInstallationDate: formatSubscriptionDate('postmanInstallationDate'),
      postmanInstallationLabel,
      purchaseDate: formatSubscriptionDate('purchaseDate'),
      shippedDate: formatSubscriptionDate('shippedDate'),
      trackingNumber: subscription.trackingNumber || '',
    };
  },
);

const InstallationInfos = compose(
  withCalculatedProps,
  pure,
)(InstallationInfosPure);

InstallationInfos.propTypes = {
  subscription: PropTypes.object,
  handleDateChange: PropTypes.func,
};

export default InstallationInfos;