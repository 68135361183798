import gql from 'graphql-tag';

const TOUCANLISTOPERATIONERROR_FRAGMENTS = {
    StringType: gql`
        fragment toucanListOperationErrorStringType on StringType {
            reason
            subject
        }
    `,
};

export {
    TOUCANLISTOPERATIONERROR_FRAGMENTS,
};