import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, Checkbox, Modal} from 'react-bootstrap';
import {branch, compose, renderNothing, withHandlers, withState} from 'recompose';
import {connectContractNumberContext} from '../../../../../hoc/contractNumber';
import {DELETE_LAST_SUBSCRIPTION_STATE} from '../../../../../schema/SubscriptionSchema';
import {errorMsg} from '../../../../../utils/Notify';

const SubscriptionStateDeleteLastPure = ({
                                           lastSubscriptionStateToDelete,
                                           removeEndDatePreviousLine,
                                           setRemoveEndDatePreviousLine,
                                           submissionInProgress,
                                           handleSubmit,
                                           handleDeleteLastSubscriptionStateCancel,
                                         }) => (
  <Modal backdrop="static"
         bsSize="lg"
         show
         onHide={handleDeleteLastSubscriptionStateCancel}>
    <Modal.Header>
      <Modal.Title>Suppression de la dernière ligne d'abonnement</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      La ligne d'abonnement de type {lastSubscriptionStateToDelete.type} démarrant
      le {moment(lastSubscriptionStateToDelete.dateBegin).format('L')} sera supprimée sans contrôle particulier ni
      synchronisation
      dans ProAbono.
      <br/>
      Il est de votre responsabilité d'ajuster l'état dans ProAbono si nécessaire.
      <Checkbox checked={removeEndDatePreviousLine}
                onChange={e => setRemoveEndDatePreviousLine(e.target.checked)}>
        Supprimer la date de fin de la ligne précédente
      </Checkbox>
    </Modal.Body>

    <Modal.Footer>
      <Button bsStyle="danger"
              onClick={handleDeleteLastSubscriptionStateCancel}>Annuler</Button>
      <Button bsStyle="success"
              disabled={submissionInProgress}
              onClick={handleSubmit}>Ok (vous devez vérifier l'état dans ProAbono ensuite !)</Button>
    </Modal.Footer>
  </Modal>
);

SubscriptionStateDeleteLastPure.propTypes = {
  lastSubscriptionStateToDelete: PropTypes.object,
  removeEndDatePreviousLine: PropTypes.bool,
  setRemoveEndDatePreviousLine: PropTypes.func,
  submissionInProgress: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleDeleteLastSubscriptionStateCancel: PropTypes.func,
};

const withRemoveEndDatePreviousLineState = withState('removeEndDatePreviousLine', 'setRemoveEndDatePreviousLine', false);
const withSubmissionInProgressState = withState('submissionInProgress', 'setSubmissionInProgress', false);

const deleteLastSubscriptionState = mutate => (contractNumber, removeEndDatePreviousLine, callback) => {
  mutate({
      variables: {
        input: {
          contractNumber,
          removeEndDatePreviousLine,
        },
      },
      update(store, {data}) {
        const result = data.deleteLastSubscriptionState;
        if (result.__typename === 'DeleteLastSubscriptionStateErrorOutput') {
          switch (result.errorReason) {
            case 'LAST_STATE_NOT_IN_FUTURE':
              errorMsg('L\'abonnement ne doit pas avoir débuté');
              break;
            case 'NOT_FOUND':
            case 'NO_METADATA':
            case 'NO_SUBSCRIPTION_STATES':
              errorMsg('Abonnement introuvable');
              break;
            case 'UNAUTHORIZED':
              errorMsg('Droits insuffisants');
              break;
            case 'SAVING_FAILED':
            default:
              errorMsg('Erreur lors de la suppression de la ligne');
              break;
          }
        } else {
          callback();
        }
      },
    },
  );
};

const withDeleteLastSubscriptionStateMutation = graphql(DELETE_LAST_SUBSCRIPTION_STATE, {
  props: props => ({
    deleteLastSubscriptionState: deleteLastSubscriptionState(props.mutate),
  }),
});

const handlers = withHandlers({
  handleSubmit: ({
                   contractNumber,
                   handleDeleteLastSubscriptionStateSubmitted,
                   deleteLastSubscriptionState,
                   setSubmissionInProgress,
                   removeEndDatePreviousLine,
                 }) => () => {
    setSubmissionInProgress(true);
    deleteLastSubscriptionState(
      contractNumber,
      removeEndDatePreviousLine,
      handleDeleteLastSubscriptionStateSubmitted,
    );
  },
});

const SubscriptionStateDeleteLast = compose(
  branch(
    ({lastSubscriptionStateToDelete}) => lastSubscriptionStateToDelete === null,
    renderNothing,
  ),
  connectContractNumberContext,
  withRemoveEndDatePreviousLineState,
  withSubmissionInProgressState,
  withDeleteLastSubscriptionStateMutation,
  handlers,
)(SubscriptionStateDeleteLastPure);

SubscriptionStateDeleteLast.propTypes = {
  lastSubscriptionStateToDelete: PropTypes.object,
  handleDeleteLastSubscriptionStateSubmitted: PropTypes.func,
  handleDeleteLastSubscriptionStateCancel: PropTypes.func,
};

export default SubscriptionStateDeleteLast;