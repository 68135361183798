import React from 'react';
import PropTypes from 'prop-types';
import {compose, pure, withHandlers, withState} from 'recompose';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import Form from 'react-jsonschema-form';

const schema = {
    'type': 'object',
    'properties': {
        'newContractNumber': { title: 'N° de contrat', type: 'string' },
    },
    'required': ['newContractNumber'],
};

const validateForm = (formData, errors) => {
    if(!/^[0-9]{8}$/.test(formData.newContractNumber)) {
        errors.newContractNumber.addError('Le numéro de contrat est composé de 8 chiffres.');
    }

    return errors;
};

const OrderMigratePure = ({formData, handleSubmit, onHideClick}) => (
    <Modal show onHide={onHideClick}>
        <Modal.Header closeButton>
            <Modal.Title>Édition/création d'une commande</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form formData={formData}
                  schema={schema}
                  showErrorList={false}
                  onSubmit={handleSubmit}
                  validate={validateForm}>
                <Row>
                    <Col md={2} mdOffset={10}>
                        <Button type='submit' bsStyle='success' className='pull-right'>
                            Valider
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal.Body>
    </Modal>
);

OrderMigratePure.propTypes = {
    formData: PropTypes.object,
    handleSubmit: PropTypes.func,
};

const withFormDataState = withState('formData', 'setFormData', {});

const withSubmitHandler = withHandlers({
    handleSubmit: ({ onSubmitClick }) => (event) => onSubmitClick(event.formData),
});

const OrderMigrate = compose(
    withFormDataState,
    withSubmitHandler,
    pure,
)(OrderMigratePure);

OrderMigrate.propTypes = {
    onHideClick: PropTypes.func,
    onSubmitClick: PropTypes.func,
};

export default OrderMigrate;