import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import AssignTicketModal from './AssignTicketModal';

const AssignTicketModalButton = ({ ticket, disabled, hidden, handleAssignTicket, handleDateChange }) => {

  const [show, setShow] = useState(false);

  if (hidden) return null;
  
  const handleShow = () => setShow(true);

  const handleHide = () => setShow(false);

  const handleSubmit = () => {
    handleAssignTicket();
    setShow(false);
  }

  return <>
    <Button
      bsStyle='success'
      className='pull-right'
      disabled={disabled}
      onClick={handleShow}
    >
      En cours
    </Button>

    <AssignTicketModal
      show={show}
      ticket={ticket}
      onHide={handleHide}
      onSubmit={handleSubmit}
      handleDateChange={handleDateChange}
    />
  </>;
};

AssignTicketModalButton.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  ticket: PropTypes.object,
  handleAssignTicket: PropTypes.func,
  handleDateChange: PropTypes.func,
};

export default AssignTicketModalButton;