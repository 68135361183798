import React, { useEffect, useState } from 'react';
import Aviator from 'aviator';
import { Grid, Tab, Tabs } from 'react-bootstrap';

import { getTeamType } from '../../../utils/functions';
import jsRoutes from '../../../routes/routes';
import { connectMeAndTeamsAndConstantsContext } from '../../../hoc/withMeAndTeamsAndConstants';

import TeamTicketsTab from './TeamTicketsTab';
import LoadingProgress from '../../components/LoadingProgress';

const TeamsTicketsTabView = ({webUserJson, types, selectedTeam}) => {

	const [loading, setLoading] = useState(false);
	const [teams, setTeams] = useState([]);

	useEffect(function useFetchTeams() {
		const getTeams = () => {
			setLoading(true);
			jsRoutes.controllers.backoffice.SupportV2Controller.getTicketTeams()
			.ajax({dataType: 'json', processData: false})
			.then((response, textStatus, jqXHR) => {
				setTeams(response);
				setLoading(false);
			}, (jqXHR, textStatus, errorThrown) => {
				setLoading(false);
			});
		}

		getTeams();
	}, []);

  const handleSelect = key => {
		Aviator.navigate((jsRoutes.controllers.backoffice.SupportV2Controller.getTickets().url + '/' + key).replace('/supportV2', ''));
	}

	const sortedTeams = teams.sort((a, b) => {
		if (a === webUserJson.team) return -1;
		if (b === webUserJson.team) return 1;
		return a.localeCompare(b);
	});

	return (
		<Grid fluid>
			<LoadingProgress show={loading} />

			<Tabs id='main-tabs' activeKey={selectedTeam} onSelect={handleSelect}>
				{sortedTeams.map(team => {
					const teamType = getTeamType(types, team);
					return (
						<Tab key={teamType} eventKey={teamType} title={teamType}>
							<TeamTicketsTab team={team} isSelected={selectedTeam === teamType} />
						</Tab>
					);
				})}
			</Tabs>
		</Grid>
	)
}

export default connectMeAndTeamsAndConstantsContext(TeamsTicketsTabView);