import PropTypes from 'prop-types';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import ChangeResultModalButton from './ChangeResultModalButton';
import AssignTicketModalButton from './AssignTicketModalButton';
import ChangeSubjectModalButton from './ChangeSubjectModalButton';
import DeleteTicketModalButton from './DeleteTicketModalButton';
import ReOpenTicketButton from './ReOpenTicketButton';
import AfterSaleTabletReturnModalButton from './AfterSaleTabletReturnModalButton';
import TransferTicketModalButton from './TransferTicketModalButton';
import CloseTicketModalButton from './CloseTicketModalButton';
import jsRoutes from '../../../../routes/routes';
import moment from 'moment';
import { TICKET_STATES } from '../../../../utils/constants';
import { connectTypesAndWebUserContext } from '../../../../hoc/withMeAndTeamsAndConstants';
import { hasValidFirstIntentionSelected } from '../helpers';
import { getResultType, getSubjectTypeLabel, getTeamType, getWebUserTeam, isN2OrHigher } from '../../../../utils/functions';


const TicketActionsButtons = connectTypesAndWebUserContext(({
	types,
	webUserJson,
	newAnswer,
	setNewAnswer,
	closed,
	locked,
	notAnswered,
	ticket,
	initialTicket,
	setCurrentTicket,
	options,
	handleSaveTicket,
	handleDeleteTicket,
}) => {

	const createAnswerData = answer => {
		const data = {};
	
		if (answer) {
			data.answer_0 = answer;
		}
	
		const {content, canal, attachment} = newAnswer;
		if (content.length > 0) {
			data.answer_1 = {content, canal};

	
			if (attachment) {
				data.answer_1_attachment_0 = attachment;
			}
	
			setNewAnswer({
				attachment: null,
				content: '',
				canal: '',
			});
		}
	
		return data;
	};

  const handleTicketPropertyChange = (propsName, event) => setCurrentTicket({ ...ticket, [propsName]: event.target.value });

  const handleDateChange = (propsName, value) => {
    ticket[propsName] = moment(value).valueOf();
    setCurrentTicket(ticket);
  }

  const handleCloseTicket = ({ result, firstIntention, resultComment }) => {
    const newTicket = {
			...ticket,
			state: TICKET_STATES.DONE,
			result: firstIntention !== '' ? firstIntention : result
		};

		if (resultComment) newTicket.resultComment = resultComment;

    const data = createAnswerData();
    handleSaveTicket({...data, ticket: newTicket});
  }

  const handleReOpenTicket = () => {
    const data = {
      ticketNumber: initialTicket.ticketNumber,
      contractNumber: initialTicket.contractNumber,
    };

    jsRoutes.controllers.backoffice.SupportV2Controller.reOpenTicket(initialTicket.contractNumber, initialTicket.ticketNumber)
    .ajax({
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      processData: false,
    })
    .then(response => {
      handleSaveTicket({ticket: response});
    });
  }

	const handleTransferTicket = () => {
		const newTeam = ticket.team,
					oldTeam = initialTicket.team;

		const answer = createAnswerData({
			content: 'Transfer de ' + getTeamType(types, oldTeam) + ' vers ' + getTeamType(types, newTeam),
			type: 'TEAM_CHANGE',
		});

		const newTicket = {
			...ticket,
			team: newTeam,
			state: TICKET_STATES.TODO
		};
    handleSaveTicket({...answer, ticket: newTicket});
	}
									
	const handleAssignTicket = () => {
		const newTicket = {...ticket};
		if (getWebUserTeam(webUserJson) === ticket.team) {
			newTicket.state = TICKET_STATES.DOING;
		}

		const answer = createAnswerData();
		handleSaveTicket({...answer, ticket: newTicket});
	}

	const handleChangeResultTicket = ({ result, firstIntention, resultComment }) => {
		const oldResult = initialTicket.result;
		const answer = createAnswerData({
			content: 'Changement de clôture de \'' + getResultType(types, oldResult) + '\' à \'' + getResultType(types, result) + '\'',
			type: 'RESULT_CHANGE',
		});
		
		const newTicket = {
			...ticket,
			result: firstIntention !== '' ? firstIntention : result
		};
		if (resultComment) newTicket.resultComment = resultComment;

		handleSaveTicket({...answer, ticket: newTicket});
	}

	const handleChangeSubjectTicket = ({subject, result, firstIntention, resultComment}) => {
		const oldSubject = initialTicket.subject;
		
		const newTicket = {...ticket, subject};

		let finaleSentence = '';

		if (ticket && oldSubject !== subject) {
			finaleSentence = `Changement de demande de ${getSubjectTypeLabel(types, oldSubject)} à ${getSubjectTypeLabel(types, subject)} \n`;
		}

		if (closed) {
			const oldResult = initialTicket.result;

			newTicket.result = result;

			if (hasValidFirstIntentionSelected(ticket, types)) {
				newTicket.result = ticket.firstIntention;
				delete ticket.firstIntention;
			}
			finaleSentence = finaleSentence + `Changement de clôture de ${getResultType(types, oldResult)} à ${getResultType(types, result)} \n`;
		}

		const answer = createAnswerData({
			content: finaleSentence,
			type: 'SUBJECT_CHANGE',
		});

		handleSaveTicket({...answer, ticket: newTicket});
	}
	
	const webUserIsLowerThanN2 = !isN2OrHigher(webUserJson);
	const open = !closed;
	const lockedForLowerThanN2 = locked && webUserIsLowerThanN2;
	return (
		<Row>
			<Col md={12}>
				<DeleteTicketModalButton hidden={webUserIsLowerThanN2} handleDeleteTicket={handleDeleteTicket} />

				<ChangeSubjectModalButton
					closed={closed}
					hidden={lockedForLowerThanN2}
					ticket={ticket}
					initialTicket={initialTicket}
					subjectOptions={options.type_demande}
					resultOptions={options.type_cloture}
					handleTicketPropertyChange={handleTicketPropertyChange}
					handleChangeSubjectTicket={handleChangeSubjectTicket}
				/>

				<ChangeResultModalButton
					closed={closed}
					hidden={open || lockedForLowerThanN2}
					ticket={ticket}
					resultOptions={options.type_cloture}
					handleChangeResultTicket={handleChangeResultTicket}
				/>

				<ReOpenTicketButton
					closed={closed}
					hidden={open || lockedForLowerThanN2}
					ticketSubject={ticket.subject}
					handleReOpenTicket={handleReOpenTicket}
				/>

				<AfterSaleTabletReturnModalButton
					disabled={notAnswered}
					hidden={closed || lockedForLowerThanN2}
					options={options}
					ticket={ticket}
				/>

				<CloseTicketModalButton
					disabled={notAnswered}
					hidden={closed || lockedForLowerThanN2}
					resultOptions={options.type_cloture}
					ticket={ticket}
					handleCloseTicket={handleCloseTicket}
					handleTicketPropertyChange={handleTicketPropertyChange}
				/>

				<TransferTicketModalButton
					disabled={notAnswered}
					hidden={closed || lockedForLowerThanN2}
					teamOptions={options.type_team}
					ticket={ticket}
					initialTicket={initialTicket}
					setCurrentTicket={setCurrentTicket}
					handleTicketPropertyChange={handleTicketPropertyChange}
					handleDateChange={handleDateChange}
					handleTransferTicket={handleTransferTicket}
				/>

				<AssignTicketModalButton
					ticket={ticket}
					disabled={notAnswered}
					hidden={closed || lockedForLowerThanN2}
					handleDateChange={handleDateChange}
					handleAssignTicket={handleAssignTicket}
				/>
			</Col>
		</Row>
	);
});

TicketActionsButtons.propTypes = {
	newAnswer: PropTypes.object,
	setNewAnswer: PropTypes.func,
	closed: PropTypes.bool,
	locked: PropTypes.bool,
	notAnswered: PropTypes.bool,
	ticket: PropTypes.object,
	initialTicket: PropTypes.object,
	setCurrentTicket: PropTypes.func,
	options: PropTypes.object,
	handleSaveTicket: PropTypes.func,
	handleDeleteTicket: PropTypes.func,
};

export default TicketActionsButtons;