import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import './SelectCurrentSubscriptionTypeButton.css';
import withGetCurrentSubscriptionType from './withGetCurrentSubscriptionType';

const SelectCurrentSubscriptionTypeButtonPure = ({handleSelectCurrentSubscriptionType}) => (
  <Button bsSize="small"
          bsStyle="primary"
          className="select-current-subscription-type-button"
          data-test-id="select-current-subscription-type-button"
          onClick={handleSelectCurrentSubscriptionType}>
    Sélectionner l'offre actuelle
  </Button>
);

SelectCurrentSubscriptionTypeButtonPure.propTypes = {
  handleSelectCurrentSubscriptionType: PropTypes.func,
};

const SelectCurrentSubscriptionTypeButton = compose(
  withGetCurrentSubscriptionType(false),
  branch(
    ({subscriptionType}) => !subscriptionType,
    renderNothing,
  ),
  pure,
)(SelectCurrentSubscriptionTypeButtonPure);

SelectCurrentSubscriptionTypeButton.propTypes = {
  handleSelectCurrentSubscriptionType: PropTypes.func,
};

export default SelectCurrentSubscriptionTypeButton;