import gql from 'graphql-tag';

const CUSTOMER_RETURN_ADDRESS = gql`
    fragment customerReturnAddressFields on CustomerAddress {
        addressLine
        city
        company
        country
        firstName
        gender
        lastName
        phone
        zipCode
    }
`;

const AFTERSALE_ALL_FIELDS_FRAGMENT = gql`
    fragment afterSaleAllFields on AfterSaleOrder {
        id
        automaticTicketNumber
        contractNumber
        currentStatus
        afterSaleRequest {
            comment
            contractNumber
            email
            phone
            provider
            returnType
            trackingNumber
            ... on AfterSaleServiceRequest {
                afterSaleServiceRequestReason: reason
                productState
                productType
                serialNumber             
                customerReturnAddress {
                    ...customerReturnAddressFields
                }
            }
            ... on SatisfiedOrRefundedRequest {
                satisfiedOrRefundedRequestReason: reason
                serialNumber
            }
        }
        currentState {
            canBeCancelled
            canBeDeleted
            canBeUpdatedWithAfterSaleProviderOutput
            canBeUpdatedWithCustomerAddress
            canBeUpdatedWithQuoteAnswer
        }
        metadata {
            created
            lastModified
        }
        returnToCustomer {
            shippingDate
            trackingNumber
        }
    }
    ${CUSTOMER_RETURN_ADDRESS}
`;
const AFTERSALES_BY_CONTRACTNUMBER_QUERY = gql`
    query afterSaleOrders($getAfterSaleOrdersInput: GetAfterSaleOrdersInput!) {
        afterSaleOrders(input: $getAfterSaleOrdersInput) {
            afterSaleOrders {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

const CREATE_AFTERSALE_SERVICE_MUTATION = gql`
    mutation CreateAfterSalteServiceMutation($afterSale: AfterSaleOrderCreateAfterSaleServiceInput!) {
        afterSaleOrderCreateAfterSaleService(input : $afterSale) {
            afterSaleOrder {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

const CREATE_SATISFIED_OR_REFUNDED_MUTATION = gql`
    mutation CreateSatisfiedOrRefundedMutation($afterSale: AfterSaleOrderCreateSatisfiedOrRefundedInput!) {
        afterSaleOrderCreateSatisfiedOrRefunded(input : $afterSale) {
            afterSaleOrder {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

const CANCEL_AFTERSALE_MUTATION = gql`
    mutation CancelAfterSalteMutation($afterSaleOrderCancelInput: AfterSaleOrderCancelInput!) {
        afterSaleOrderCancel(input : $afterSaleOrderCancelInput) {
            afterSaleOrder {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

const UPDATE_AFTERSALE_STATUS_MUTATION = gql`
    mutation AfterSaleUpdateStatus($afterSaleOrderUpdateInput: AdminAfterSaleOrderUpdateWithProviderOutputInput!) {
        adminAfterSaleOrderUpdateWithProviderOutput(input : $afterSaleOrderUpdateInput) {
            afterSaleOrder {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

const UPDATE_AFTERSALE_ADDRESS_MUTATION = gql`
    mutation AfterSaleUpdateAddress($afterSaleOrderUpdateInput: AfterSaleOrderUpdateWithCustomerAddressInput!) {
        afterSaleOrderUpdateWithCustomerAddress(input : $afterSaleOrderUpdateInput) {
            afterSaleOrder {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

const UPDATE_AFTERSALE_QUOTE_STATE_MUTATION = gql`
    mutation AfterSaleUpdateQuoteState($afterSaleOrderUpdateInput: AfterSaleOrderUpdateWithQuoteStateInput!) {
        afterSaleOrderUpdateWithQuoteState(input : $afterSaleOrderUpdateInput) {
            afterSaleOrder {
                ...afterSaleAllFields
            }
        }
    }
    ${AFTERSALE_ALL_FIELDS_FRAGMENT}
`;

export {
    AFTERSALES_BY_CONTRACTNUMBER_QUERY,
    CREATE_AFTERSALE_SERVICE_MUTATION,
    CREATE_SATISFIED_OR_REFUNDED_MUTATION,
    CANCEL_AFTERSALE_MUTATION,
    UPDATE_AFTERSALE_STATUS_MUTATION,
    UPDATE_AFTERSALE_ADDRESS_MUTATION,
    UPDATE_AFTERSALE_QUOTE_STATE_MUTATION,
};