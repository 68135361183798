import React, { useMemo } from 'react';
import MultipleSelect from './MultipleSelect';
import { sortAlpha } from '../../../utils/Array';

const TagsSelect = ({tags = [], value = [], onChange, ...props}) => {
	const tagOptions = useMemo(() => sortAlpha(tags).map(tag => ({ value: tag, label: tag })), [tags]);
	return (
		<MultipleSelect
			name='tags'
			placeholder='Rechercher un tag...'
			options={tagOptions}
			value={value}
			onChange={onChange}
			{...props}
		/>
	);
}

export default TagsSelect;