import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ButtonToolbar, Col, ControlLabel, FormControl, Row} from 'react-bootstrap';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {compose, pure, withContext, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {GET_SUBSCRIPTION_TYPES_LIST, SET_SUBSCRIPTIONSTATE_ON_SUBSCRIPTIONS} from '../../../schema/SubscriptionSchema';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg, successMsg} from '../../../utils/Notify';
import {SUBSCRIPTION_TYPES} from '../../../utils/Subscription';
import SubscriptionTypeLabel
  from '../../contract/CustomerContract/tabs/Subscription/SubscriptionState/SubscriptionStateForm/SubscriptionType/SubscriptionTypeLabel';
import SubscriptionTypeSelector
  from '../../contract/CustomerContract/tabs/Subscription/SubscriptionState/SubscriptionStateForm/SubscriptionType/SubscriptionTypeSelector';
import SingleDatePicker from '../../components/DatePicker/SingleDatePicker';
import {
  convertResult,
  formatContractNumbers,
  formatContractNumbersForSave,
  isContractNumbersMalformed,
  withToucanListOperationResult,
} from '../ContractsHelpers';

const SetSubscriptionStatePure = ({
                                    canSave,
                                    contractNumbers,
                                    dateEnd,
                                    endDateAvailable,
                                    endDateNeeded,
                                    handleSave,
                                    setContractNumbers,
                                    setDateEnd,
                                    setSubscriptionType,
                                  }) => (
  <>
    <Row>
      <Col md={12}>
        <FormGroup>
          <ControlLabel>Numéros de contrat *</ControlLabel>
          <FormControl componentClass="textarea"
                       data-test-id="contract-numbers-textarea"
                       rows={4}
                       type="text"
                       value={contractNumbers}
                       onChange={e => setContractNumbers(formatContractNumbers(e.target.value))}/>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <SubscriptionTypeSelector data-test-id="subscription-type-selector"
                                  updateSubscriptionType={setSubscriptionType}
                                  withoutOnlySimGroupOption/>
      </Col>
    </Row>

    <Row>
      <Col md={6}>
        <SubscriptionTypeLabel/>
      </Col>
    </Row>

    {endDateAvailable && (
      <Row>
        <Col md={6}>
          <ControlLabel>Date de fin{endDateNeeded ? ' *' : ''}</ControlLabel>
          <SingleDatePicker data-test-id="date-end-picker"
                            value={dateEnd}
                            onChange={setDateEnd}/>
        </Col>
      </Row>
    )}

    <Row>
      <Col md={12}>
        <ButtonToolbar className="pull-right" style={{paddingTop: 10}}>
          <Button bsStyle="primary"
                  data-test-id="initialize-button"
                  disabled={!canSave}
                  onClick={handleSave}>
            Modifier les abonnements
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>
  </>
);

SetSubscriptionStatePure.propTypes = {
  canSave: PropTypes.bool,
  contractNumbers: PropTypes.string,
  dateEnd: PropTypes.number,
  endDateAvailable: PropTypes.bool,
  endDateNeeded: PropTypes.bool,
  handleSave: PropTypes.func,
  setContractNumbers: PropTypes.func,
  setDateEnd: PropTypes.func,
  setSubscriptionType: PropTypes.func,
};

const withContractNumbersState = withState('contractNumbers', 'setContractNumbers', undefined);
const withDateEndState = withState('dateEnd', 'setDateEnd', undefined);
const withSubscriptionTypeState = withState('subscriptionType', 'setSubscriptionType', undefined);

const mutationErrorReasons = {
    TICKET: 'Erreur lors de la création du ticket N3T, le traitement a cependant été effectué veuillez contacter les administrateurs du site',
    UNAUTHORIZED: 'Vous n\'avez pas les droits nécessaires pour effectuer cette action',
};

const ToucanListOperationErrorReason = {
  ALREADY_SET: {
    message: 'l\'abonnement actuel doit être DEMO ou DEMO_SIM',
    value: 'ALREADY_SET',
  },
  NOT_FOUND: {
    message: 'n\'existe pas',
    value: 'NOT_FOUND',
  },
  SAVE: {
    message: 'erreur de sauvegarde',
    value: 'SAVE',
  },
  WRONG_STATE: {
    message: 'modification impossible',
    value: 'WRONG_STATE'
  }
};

const submitAddSubscriptionStateToSubscriptions = mutate => ({contractNumbers, subscriptionState}, props) => {
  const {setLoading} = props;
  setLoading(
    true,
    () => mutate({
      variables: {
        input: {
          contractNumbers,
          subscriptionState,
        },
      },
        update: (_store, result) => {
          const results = result?.data?.subscriptionSetSubscriptionStateOnContractList;

          if ('errorReason' in results) {
              setLoading(false);
              errorMsg(mutationErrorReasons[results.errorReason]);
          } else {
              convertResult(results, props, ToucanListOperationErrorReason);
              successMsg('L\'opération a été effectuée. Un ticket N3T avec les numéros de contrat mis à jour et les erreurs éventuelles a été généré.')
          }
        },

    }).then(
      emptyFunction,
      error => {
        console.error(error);
        setLoading(false, () => errorMsg('Erreur lors de la sauvegarde des lignes d\'abonnement'));
      },
    ),
  );
};

const withAddSubscriptionStateToSubscriptionsMutation = graphql(SET_SUBSCRIPTIONSTATE_ON_SUBSCRIPTIONS, {
  props: ({mutate}) => ({
    handleAddSubscriptionStateToSubscriptions: submitAddSubscriptionStateToSubscriptions(mutate),
  }),
});

const handlers = withHandlers({
  handleSave: props => () => {
    const {
      author,
      contractNumbers,
      dateEnd,
      endDateAvailable,
      subscriptionType,
      handleAddSubscriptionStateToSubscriptions,
    } = props;
    const formattedContractNumbers = formatContractNumbersForSave(contractNumbers);

    handleAddSubscriptionStateToSubscriptions(
      {
        contractNumbers: formattedContractNumbers,
        subscriptionState: {
          author,
          dateEnd: endDateAvailable ? dateEnd : undefined,
          type: subscriptionType,
        },
      },
      props,
    );
  },
});

export const excludeNonNeededSubscriptionTypes = (subscriptionTypes) =>
  (subscriptionTypes || [])
    .filter(
      subscriptionType =>
        subscriptionType.clientTypology === 'B2B' ||
        (subscriptionType.clientTypology === 'B2C' && subscriptionType.group === 'CLASSIQUE_PREPAYE'),
    );

export const SUBSCRIPTION_TYPES_WITHOUT_END_DATE = [
  SUBSCRIPTION_TYPES.OFFRE_ONLY_SIM,
  SUBSCRIPTION_TYPES.OFFRE_ONLY_SIM_PLUS,
  SUBSCRIPTION_TYPES.PREPAYE_DUREE_VIE,
];

export const SUBSCRIPTION_TYPES_WITHOUT_MANDATORY_END_DATE = [
  ...SUBSCRIPTION_TYPES_WITHOUT_END_DATE,
  SUBSCRIPTION_TYPES.DEMO
];

export const isEndDateNeeded = (subscriptionType) => !SUBSCRIPTION_TYPES_WITHOUT_MANDATORY_END_DATE.find(_ => _ === subscriptionType);

export const isEndDateAvailable = (subscriptionType) => !SUBSCRIPTION_TYPES_WITHOUT_END_DATE.find(_ => _ === subscriptionType);

export const getCanSave = ({
                             contractNumbers,
                             dateEnd,
                             endDateNeeded,
                             subscriptionType,
                           }) => !!(
  contractNumbers &&
  !isContractNumbersMalformed(contractNumbers) &&
  (dateEnd || !endDateNeeded) &&
  subscriptionType
);

const SetSubscriptionState = compose(
  connectMeAndTeamsAndConstantsContext,
  withContractNumbersState,
  withDateEndState,
  withSubscriptionTypeState,
  graphql(GET_SUBSCRIPTION_TYPES_LIST),
  withProps(({
               contractNumbers,
               data,
               dateEnd,
               subscriptionType,
               webUserJson,
             }) => {
    const endDateNeeded = isEndDateNeeded(subscriptionType);
    const endDateAvailable = isEndDateAvailable(subscriptionType);
    return {
      author: webUserJson.email,
      canSave: getCanSave({
        contractNumbers,
        dateEnd,
        endDateNeeded,
        subscriptionType,
      }),
      endDateNeeded,
      endDateAvailable,
      subscriptionTypes: excludeNonNeededSubscriptionTypes(data?.listSubscriptionTypes?.subscriptionTypeClassifications),
    };
  }),
  withContext({
      currentSubscriptionType: PropTypes.string,
      subscriptionState: PropTypes.object,
      subscriptionTypes: PropTypes.array,
    },
    ({
       dateEnd,
       subscriptionType,
       subscriptionTypes,
     }) => ({
      currentSubscriptionType: SUBSCRIPTION_TYPES.DEMO,
      subscriptionState: {
        dateEnd,
        type: subscriptionType,
      },
      subscriptionTypes,
    }),
  ),
  withToucanListOperationResult,
  withLoading,
  withAddSubscriptionStateToSubscriptionsMutation,
  handlers,
  pure,
)(SetSubscriptionStatePure);

SetSubscriptionState.propTypes = {};

export default SetSubscriptionState;