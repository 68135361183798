import PropTypes from 'prop-types';
import React from 'react';
import {Nav, NavItem, Panel, Tab} from 'react-bootstrap';
import {ENABLE_LMR_FEATURES} from '../../../utils/constants';
import {isN2OrHigher} from '../../../utils/functions';
import { connectWebUserContext } from '../../../hoc/withMeAndTeamsAndConstants';
import B2BInformations from './tabs/B2BInformations';
import Antenna from './tabs/Antenna';
import Client from './tabs/Client';
import Device from './tabs/Device';
import AccountMaintenance from './tabs/AccountMaintenance';
import Order from './tabs/Order';
import Subscription from './tabs/Subscription';

const ContractTabs = connectWebUserContext(({
  contractNumber,
  addTicket,
  contract,
  dmd,
  webUserJson,
  loadContactInfo,
  shouldShowB2BTab,
  setCurrentEditedAfterSale,
  setDmd,
}) => (
  <Tab.Container id="account-tabs" defaultActiveKey={1}>
    <Panel className="with-nav-tabs">
      <Panel.Heading>
        <Nav bsStyle="tabs">
          <NavItem eventKey={1}>
            Clients
          </NavItem>
          <NavItem eventKey={2}>
            Abonnement
          </NavItem>
          <NavItem eventKey={3}>
            Matériel
          </NavItem>
          {isN2OrHigher(webUserJson) &&
            <NavItem eventKey={4}>
              Maintenance
            </NavItem>
          }
          <NavItem eventKey={5}>
            {ENABLE_LMR_FEATURES ?
              'Commandes et Retours' : 'Commandes'
            }
          </NavItem>
          <NavItem eventKey={6}>
            Antennes
          </NavItem>
          {shouldShowB2BTab &&
            <NavItem eventKey={7}>
              B2B
            </NavItem>
          }
        </Nav>
      </Panel.Heading>
      <Panel.Body>
        <Tab.Content animation className="with-panel-loading">
          <Tab.Pane eventKey={1}>
            <Client
              contract={contract}
              dmd={dmd}
              loadContactInfo={loadContactInfo}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={2}>
            <Subscription contractNumber={contractNumber}/>
          </Tab.Pane>
          <Tab.Pane eventKey={3}>
            <Device contractNumber={contractNumber} loadDmd={setDmd}/>
          </Tab.Pane>
          {isN2OrHigher(webUserJson) &&
            <Tab.Pane eventKey={4}>
              <AccountMaintenance contractNumber={contractNumber}/>
            </Tab.Pane>
          }
          <Tab.Pane eventKey={5}>
            <Order
              contractNumber={contractNumber}
              addTicket={addTicket}
              setCurrentEditedAfterSale={setCurrentEditedAfterSale}
            />
          </Tab.Pane>
          <Tab.Pane eventKey={6}>
            <Antenna contractNumber={contractNumber}/>
          </Tab.Pane>
          <Tab.Pane eventKey={7}>
            <B2BInformations contractNumber={contractNumber}/>
          </Tab.Pane>
        </Tab.Content>
      </Panel.Body>
    </Panel>
  </Tab.Container>
));

ContractTabs.propTypes = {
  contract: PropTypes.object,
  contractNumber: PropTypes.string,
  webUserJson: PropTypes.object,
  addTicket: PropTypes.func,
  loadContactInfo: PropTypes.func,
  shouldShowB2BTab: PropTypes.bool,
  setDmd: PropTypes.func,
};

export default ContractTabs;