import React from 'react';
import MaintenanceView from './Maintenance/MaintenanceView';
import { navigateTo } from '../utils/functions';
import { connectWebUserContext } from '../hoc/withMeAndTeamsAndConstants';
import { isMaintenanceVisible } from '../utils/accessRights';

const Maintenance = connectWebUserContext(({webUserJson}) => {
  if (!webUserJson || !isMaintenanceVisible(webUserJson)) {
    navigateTo('/ticket');
  }
  return <MaintenanceView />;
});

export default Maintenance;