import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TicketTransferModal from './TicketTransferModal';
import TicketTransferButton from './TicketTransferButton';

const TicketTransferRowButton = ({ ticket, transferTicket }) => {

  const [error, setError] = useState(null);
  const [ticketToTransferID, setTicketToTransferID] = useState(null);

  const handleOpen = id => {
    setError(null);
    setTicketToTransferID(id);
  }

  const handleHide = id => {
    setTicketToTransferID(null);
  }

  const handleTransferTicket = ({ newContractNumber, copyMode }) => {
    setError(null);
    transferTicket({
      newContractNumber,
      ticket,
      copyMode
    },
    {
      onSuccess: handleHide,
      onError: setError
    });
  }

  const show = !!ticketToTransferID;
  return <>
    <TicketTransferButton onClick={handleOpen} />

    <TicketTransferModal
      show={show}
      error={error}
      onHide={handleHide}
      onSubmit={handleTransferTicket}
    />
  </>;
};

TicketTransferRowButton.propTypes = {
  ticket: PropTypes.object,
  transferTicket: PropTypes.func,
};

export default TicketTransferRowButton;