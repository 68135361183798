import { getContext, withContext } from 'recompose';
import PropTypes from 'prop-types';

const withMeAndTeamsAndConstantsContext = (getFromProps) => withContext(
	{
		tags: PropTypes.object,
		teams: PropTypes.object,
		types: PropTypes.object,
		webUserJson: PropTypes.object,
	},
	getFromProps
);

const connectMeAndTeamsAndConstantsContext = Component => getContext(
	{
		tags: PropTypes.object,
		teams: PropTypes.object,
		types: PropTypes.object,
		webUserJson: PropTypes.object,
	}
)(Component);

const connectTagsAndTypesContext = Component => getContext(
	{
		tags: PropTypes.object,
		types: PropTypes.object,
	}
)(Component);

const connectTagsContext = Component => getContext(
	{
		tags: PropTypes.object,
	}
)(Component);

const connectTeamsContext = Component => getContext(
	{
		teams: PropTypes.object,
	}
)(Component);

const connectTypesAndTeamsContext = Component => getContext(
	{
		types: PropTypes.object,
		teams: PropTypes.object,
	}
)(Component);

const connectTypesContext = Component => getContext(
	{
		types: PropTypes.object,
	}
)(Component);

const connectTypesAndWebUserContext = Component => getContext(
	{
		types: PropTypes.object,
		webUserJson: PropTypes.object,
	}
)(Component);

const connectWebUserContext = Component => getContext(
	{
		webUserJson: PropTypes.object,
	}
)(Component);

const connectWebUserAndTeamsContext = Component => getContext(
	{
		webUserJson: PropTypes.object,
		teams: PropTypes.object,
	}
)(Component);

export {
	withMeAndTeamsAndConstantsContext,
	connectMeAndTeamsAndConstantsContext,
	connectTagsAndTypesContext,
	connectTagsContext,
	connectTeamsContext,
	connectTypesAndTeamsContext,
	connectTypesContext,
	connectTypesAndWebUserContext,
	connectWebUserContext,
	connectWebUserAndTeamsContext,
};