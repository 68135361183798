import {compose, withHandlers, withProps, withState} from 'recompose';

const withCurrentStepState = withState('currentStep', 'setCurrentStep', ({initialState}) => initialState || 0);

const handlers = withHandlers({
    stepDown: ({currentStep, setCurrentStep}) => () => setCurrentStep(currentStep - 1),
    stepUp: ({currentStep, setCurrentStep}) => () => setCurrentStep(currentStep + 1),
});

const withCalculatedProps = withProps(
    ({currentStep, steps}) => ({
        showStepDownButton: currentStep >= 1,
        showStepUpButton: steps.length > 1 && currentStep <= steps.length - 2,
    })
);

const withStepper = compose(
    withCurrentStepState,
    handlers,
    withCalculatedProps,
);

export default withStepper;