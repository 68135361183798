import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap';
import Select from 'react-select';

import {sortAlpha} from '../../../utils/Array';
import {getCanalType, getSubjectTypeLabel, isCCAUser} from '../../../utils/functions';
import { connectTypesAndWebUserContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { getUnlockedSubjectOptions } from '../../../utils/types';

const NewTicketForm = connectTypesAndWebUserContext(({ webUserJson, types, disabled, selectedTags, ticket, canalTypes, subjectTypes, handleChange, handleTagChange, handleFileChange, tagList }) => {

	const createInputChangeHandler = property => event => handleChange(property, event);
	
	const ticketTagsOptions = useMemo(() => sortAlpha(tagList).map(tag => ({ value: tag, label: tag })), [tagList]);

	const SelectTagComponent = useMemo(() => isCCAUser(webUserJson) ? Select : Select.Creatable, [webUserJson]);
	
	const canalOptions = useMemo(() => sortAlpha(canalTypes, canal => getCanalType(types, canal)), [canalTypes, types]);
	
	const subjectOptions = useMemo(() => getUnlockedSubjectOptions(subjectTypes, types), [subjectTypes, types]);
	
	return (
    <form>
			<FormGroup>
				<ControlLabel>Contenu du ticket</ControlLabel>
				<FormControl
					componentClass='textarea'
					disabled={disabled}
					placeholder='Saisir le contenu du ticket'
					value={ticket.content}
					onChange={createInputChangeHandler('content')}
				/>
			</FormGroup>

			<FormGroup>
				<ControlLabel>Tags</ControlLabel>
				<SelectTagComponent
					placeholder='Rechercher un tag...'
					disabled={disabled}
					options={ticketTagsOptions}
					multi={true}
					value={selectedTags}
					onChange={handleTagChange}
				/>
			</FormGroup>

			<Row>
				<Col md={4}>
					<FormGroup>
						<ControlLabel>Canal</ControlLabel>
						<FormControl
							componentClass='select'
							disabled={disabled}
							value={ticket.canal}
							onChange={createInputChangeHandler('canal')}
							placeholder='Canal'
						>
							<option value='---'>---</option>
							{canalOptions.map(canal => (
								<option key={canal} value={canal}>
									{getCanalType(types, canal)}
								</option>
							))}
						</FormControl>
					</FormGroup>
				</Col>
				<Col md={4}>
					<FormGroup>
						<ControlLabel>Demande</ControlLabel>
						<FormControl
							componentClass='select'
							disabled={disabled}
							placeholder='Demande'
							value={ticket.subject}
							onChange={createInputChangeHandler('subject')}
						>
							<option value='---'>---</option>
							{subjectOptions.map(subject => (
								<option key={subject} value={subject}>
									{getSubjectTypeLabel(types, subject)}
								</option>
							))}
						</FormControl>
					</FormGroup>
				</Col>
				<Col md={4}>
					<FormGroup>
						<ControlLabel>Fichier</ControlLabel>
						<FormControl
							disabled={disabled}
							type='file'
							onChange={handleFileChange}
						/>
						<HelpBlock>Fichier à ajouter au ticket</HelpBlock>
					</FormGroup>
				</Col>
			</Row>
    </form>
	);
});

NewTicketForm.propTypes = {
	types: PropTypes.object,
	disabled: PropTypes.bool,
	selectedTags: PropTypes.array,
	ticket: PropTypes.object,
	tagList: PropTypes.array,
	canalTypes: PropTypes.array,
	subjectTypes: PropTypes.array,
	handleChange: PropTypes.func,
	handleTagChange: PropTypes.func,
	handleFileChange: PropTypes.func,
};

export default NewTicketForm;