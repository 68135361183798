import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonGroup, Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {connectWebUserContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {isN2OrHigher, isValidationUser} from '../../../../../utils/functions';
import {getSimStateClass, getSimStateIcon, SIM_OPERATORS} from '../../../../../utils/Sim';
import FormGroupWithFAIconAndLabelAndTooltip from '../../../../components/FormGroupWithFAIconAndLabelAndTooltip';
import SimManagement from '../../../../sims/SimManagement';

const hasEditionRights = (webUserJson) => isValidationUser(webUserJson) || isN2OrHigher(webUserJson);

const Sim = connectWebUserContext(({
  webUserJson,
  dmd,
  handleDmdPropertyChange,
  handleLoading,
  handleManageSim,
  onEditDMDClick,
}) => (
  <Row className='vertical-align'>
    <Col md={5}>
      {dmd.ICCID ?
        <FormGroupWithFAIconAndLabelAndTooltip
          id='iccid' tooltip={dmd.simState}
          bsStyle={getSimStateClass(dmd.simState)}
          icon={getSimStateIcon(dmd.simState)}
          spin={dmd.simState.indexOf('TING') !== -1}
          text={dmd.ICCID}
          label='Numéro ICCID'
          readOnly/> :
        <FormGroup>
          <ControlLabel>Numéro ICCID</ControlLabel>
          <FormControl type='text' value={dmd.ICCID || ''} readOnly/>
        </FormGroup>
      }
    </Col>
    <Col md={4}>
      <FormGroup>
        <ControlLabel>Opérateur</ControlLabel>
        <FormControl componentClass='select'
                     value={dmd.operator || undefined}
                     onChange={(e) => handleDmdPropertyChange('operator', e.target.value)}
                     disabled={!isN2OrHigher(webUserJson)}
        >
          {Object.values(SIM_OPERATORS).map(operator => <option key={operator.name}
                                                                value={operator.name}>{operator.name}</option>)}
        </FormControl>
      </FormGroup>
    </Col>
    {hasEditionRights(webUserJson) &&
    <Col md={3}>
      <ButtonGroup vertical block>
        <Button bsStyle='info' bsSize='xsmall' onClick={onEditDMDClick}>
          Changer SIM
        </Button>
      </ButtonGroup>
      {dmd.ICCID &&
      <SimManagement
        displayVertical
        fromDevice
        operator={dmd.operator}
        sim={dmd.sim}
        callBack={handleManageSim}
        handleLoading={handleLoading}
      />
      }
    </Col>
    }
  </Row>
));

Sim.propTypes = {
  dmd: PropTypes.object,
  handleDmdPropertyChange: PropTypes.func,
  handleLoading: PropTypes.func,
  handleManageSim: PropTypes.func,
  onEditDMDClick: PropTypes.func,
};

export default Sim;