import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TicketTransferModal = ({ show, error, onHide, onSubmit }) => {
	const [newContractNumber, setNewContractNumber] = useState('');

	const handleChange = e => setNewContractNumber(e.target.value)
	
	const createSubmitHandler = ({copyMode}) => () => onSubmit({ newContractNumber, copyMode });

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Copier / Déplacer</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormGroup>
					<ControlLabel>Numéro de contrat</ControlLabel>
					<FormControl
						type='text'
						value={newContractNumber}
						onChange={handleChange}
					/>
				</FormGroup>
				{error && <label>{error}</label>}
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='primary' onClick={createSubmitHandler(({copyMode: true}))}>Copier</Button>
				<Button bsStyle='primary' onClick={createSubmitHandler(({copyMode: false}))}>Déplacer</Button>
			</Modal.Footer>
		</Modal>
	);
}

TicketTransferModal.propTypes = {
	error: PropTypes.string,
	show: PropTypes.bool,
	onHide: PropTypes.func,
	onSubmit: PropTypes.func,
}

export default TicketTransferModal;