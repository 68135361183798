import {graphql} from 'react-apollo';
import {CREATE_CUSTOMER} from '../../schema/CustomerSchema';

const createCustomer = mutate => (contractNumber, customer, callback) => {
    const {customerTypologyType, ...address} = customer;

    mutate({
        variables: {
            customerInput: {
                address,
                contractNumber,
                customerTypologyType,
            },
        },
        update(store, {data}) {
            if (callback) {
                callback(data?.customerCreate?.customer);
            }
        },
    });
};

const withCreateCustomerMutation = graphql(CREATE_CUSTOMER, {
    props: props => ({
        createCustomer: createCustomer(props.mutate),
    }),
});

export default withCreateCustomerMutation;