import moment from 'moment';
import 'moment/locale/fr';
import { findIndexByKeyValue, sortByPriorityAndCreation } from './Array';

moment.locale('fr');

export const getTicketPriority = (createAt, takeActionDate) => {
	if (takeActionDate) {
		return Math.round(moment().diff(moment(takeActionDate), 'days', true));
	} else {
		return Math.round(moment().diff(moment(createAt), 'days', true));
	}
}

export const getTicketCustomerFullName = (ticket) => {
	if (ticket.mainUserFirstName  && ticket.mainUserLastName ){
		return ticket.mainUserFirstName + ' ' + ticket.mainUserLastName;
	}

	if (ticket.buyerFirstName && ticket.buyerLastName) {
		return ticket.buyerFirstName + ' ' + ticket.buyerLastName;
	}

	return '';
}

export const parseTicketId = id => {
  if (id.includes('old_ardoiz_')) {
    return - parseInt(id.replace('old_ardoiz_', ''), 10);
  }
  return parseInt(id, 10);
}

export const compareTicketID = (a, b) => parseTicketId(a) > parseTicketId(b) ? 1 : -1;

export const createTicketPropertySorter = property => (a = {}, b = {}) => {
	
	if (!property) return sortByPriorityAndCreation(a, b);

	let aValue = a[property.name];
	let bValue = b[property.name];
	
	if (property.reverse) {
		aValue = b[property.name];
		bValue = a[property.name];
	}

  if (aValue) {
    if (bValue) {
      if (typeof aValue === 'number') {
				const diff = aValue - bValue;
        return diff === 0 ? sortByPriorityAndCreation(a, b) : diff;
      } else if (property.name === 'ID') {
        return compareTicketID(aValue, bValue);
      } else {
				const diff = aValue.toString().localeCompare(bValue.toString());
        return diff === 0 ? sortByPriorityAndCreation(a, b) : diff;
      }
    }
		return -1;
  } else if (bValue) {
    return 1;
  }
  return sortByPriorityAndCreation(a, b);
}

export const getSelectedTicketActivePage = (tickets, selectedTicketID, itemsByPage) => Math.floor(findIndexByKeyValue(tickets, 'id', selectedTicketID) / itemsByPage) + 1;