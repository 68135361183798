import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeleteTicketModal = ({ show, onSubmit, onHide }) => {
	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Suppression du ticket</Modal.Title>
			</Modal.Header>

			<Modal.Body>Êtes-vous sûr ?</Modal.Body>

			<Modal.Footer>
				<Button bsStyle='success' data-test-id='cancelButton' onClick={onHide}>
					Annuler
				</Button>
				<Button bsStyle='danger' className='pull-left' data-test-id='deleteButton' onClick={onSubmit}>
					Supprimer
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

DeleteTicketModal.propTypes = {
	show: PropTypes.bool,
	onHide: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default DeleteTicketModal;