import PropTypes from 'prop-types';
import React from 'react';
import {compose, getContext, withState} from 'recompose';
import {SIM_PLANS} from '../../../../utils/Sim';
import SelectPlanModal from './SelectPlanModal';

export const withButtonContext = getContext(
  {
    buttonSize: PropTypes.string,
    sim: PropTypes.object,
    callBack: PropTypes.func,
    handleLoading: PropTypes.func,
  },
);

const withShowModalState = withState('showModal', 'setShowModal', false);
const withSimPlanState = withState(
  'simPlan',
  'setSimPlan',
  ({sim}) => sim?.currentState?.simState?.plan || SIM_PLANS.CLASSIC.value,
);
export const wrapWithModal = BaseComponent => props => {
  const {
    showModal,
    simPlan,
    onValidateClick,
    setShowModal,
    setSimPlan,
  } = props;
  return (
    <>
      <BaseComponent {...props}/>

      <SelectPlanModal show={showModal}
                       simPlan={simPlan}
                       onValidateClick={onValidateClick}
                       setShow={setShowModal}
                       setSimPlan={setSimPlan}/>
    </>
  );
};

export const withSelectPlanStates = compose(
  withShowModalState,
  withSimPlanState,
);

export const changeSimStateWithPlanFunction = (
  {
    route,
    sim,
    callBack,
    handleLoading,
  },
) => async (simPlan) => {
  await handleLoading(true);

  route(sim.ICCID)
    .ajax({
      data: JSON.stringify({simPlan}),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      processData: false,
    })
    .then(
      async (response) => {
        await callBack(response);
        handleLoading(false);
      },
      () => handleLoading(false),
    );
};

export const changeSimStateFunction = (
  {
    route,
    sim,
    callBack,
    handleLoading,
  },
) => async () => {
  await handleLoading(true);

  route(sim.ICCID)
    .ajax({
      dataType: 'json',
    })
    .then(
      async (response) => {
        await callBack(response);
        handleLoading(false);
      },
      () => handleLoading(false),
    );
};