import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderComponent } from 'recompose';
import { Label } from 'react-bootstrap';
import FontAwesome from '../../../../components/FontAwesome';
import moment from 'moment';

moment.locale('fr');

const Connection3GLabelPure = () => <Label><FontAwesome name='signal'/> 3G</Label>;
const ConnectionWifiLabelPure = () => <Label><FontAwesome name='wifi'/> WIFI</Label>;

const ConnectionLabel = compose(
    pure,
    branch(
        ({ is3G }) => is3G,
        renderComponent(Connection3GLabelPure),
        renderComponent(ConnectionWifiLabelPure),
    ),
)();

ConnectionLabel.propTypes = {
    is3G: PropTypes.bool,
};

export default ConnectionLabel;