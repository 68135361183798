import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

const EditTicketTagsButton = ({editing, handleEdit, handleSaveTags}) => {

	if (editing) return (
		<Button bsStyle='success' className='pull-right' onClick={handleSaveTags}>
			Sauver les tags
    </Button>
	);

	return (
    <Button className='pull-right' onClick={handleEdit}>
			Modifier les tags
    </Button>
	);
}

EditTicketTagsButton.propTypes = {
	editing: PropTypes.bool,
	handleSaveTags: PropTypes.func,
	handleEdit: PropTypes.func,
};

export default EditTicketTagsButton;