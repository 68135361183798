import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'react-apollo';
import {Col, ControlLabel, Panel, Row} from 'react-bootstrap';
import {pure, withProps} from 'recompose';

import {toFrenchDateString} from '../../../../../../utils/Date';
import {isN2CommercialUser, isN3User} from '../../../../../../utils/functions';
import { connectMeAndTeamsAndConstantsContext } from '../../../../../../hoc/withMeAndTeamsAndConstants';

const format = (value, accessor) => {
    if (['dateBegin', 'engagementEnd', 'dateEnd', 'dateCrea'].includes(accessor)) {
        return toFrenchDateString(value);
    }
    return value;
};

const extraColumns = [
    {Header: 'Source', accessor: 'author'},
];

const SubEntryPure = ({columns, subscriptionState, webUserJson}) => (
    <Panel bsStyle='info' style={{padding: '20px'}}>
        {
            (isN2CommercialUser(webUserJson) || isN3User(webUserJson)) &&
            <Row>
                <Col md={3}><ControlLabel>ID</ControlLabel></Col>
                <Col md={8}>{subscriptionState._original.id}</Col>
            </Row>
        }

        {
            columns.map(
                column => (
                    <Row key={column.accessor}>
                        <Col md={3}><ControlLabel>{column.Header}</ControlLabel></Col>
                        <Col md={8}>{format(subscriptionState._original[column.accessor], column.accessor)}</Col>
                    </Row>
                )
            )
        }
    </Panel>
);

SubEntryPure.propTypes = {
    columns: PropTypes.array,
    subscriptionState: PropTypes.object,
    webUserJson: PropTypes.object,
};

const SubEntry = compose(
    connectMeAndTeamsAndConstantsContext,
    withProps(
        ({columns}) => ({
            columns: [
                ...columns,
                ...extraColumns,
            ].filter(_ => !!_.accessor), // Filter action columns for example
        }),
    ),
    pure,
)(SubEntryPure);

SubEntry.propTypes = {
    columns: PropTypes.array,
    subscriptionState: PropTypes.object,
};

export default SubEntry;