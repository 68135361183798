import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withProps} from 'recompose';

import {connectContractNumberContext} from '../../../../../../hoc/contractNumber';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../../hoc/withMeAndTeamsAndConstants';
import {isN2CommercialUser, isN3User, isValidationUser} from '../../../../../../utils/functions';
import computeProAbonoUrl from '../../../../../../utils/ProAbonoUrl';

const ProabonoLinkPure = ({url}) => (
    <Button bsStyle='link'
            href={url}
            target='_blank'
            onClick={e => e.stopPropagation()}>
        Accéder à Proabono
    </Button>
);

ProabonoLinkPure.propTypes = {
    url: PropTypes.string,
};

const ProabonoLink = compose(
    connectMeAndTeamsAndConstantsContext,
    branch(
        ({webUserJson}) =>
            !isValidationUser(webUserJson) &&
            !isN2CommercialUser(webUserJson) &&
            !isN3User(webUserJson),
        renderNothing,
    ),
    connectContractNumberContext,
    withProps(
        ({contractNumber}) => ({
            url: computeProAbonoUrl(contractNumber),
        }),
    ),
    pure,
)(ProabonoLinkPure);

ProabonoLink.propTypes = {};

export default ProabonoLink;