import React from 'react';
import {Button, ButtonToolbar, Glyphicon, Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {compose, pure, withHandlers} from 'recompose';

import {GCM_ORDERS_TYPES} from '../../../../../../utils/constants';
import {tsToDateForInput} from '../../../../../../utils/Date';
import {getUIFriendlyAccountMaintenanceType} from '../../../../../../utils/functions';
import withLoading from '../../../../../../hoc/withLoading';
import SendGCMOrderButton from './SendGCMOrderButton';
import jsRoutes from '../../../../../../routes/routes';

const {MaintenanceV2Controller} = jsRoutes.controllers.backoffice;

const AccountMaintenanceTablePure = ({
                                  contractNumber,
                                  maintenances,
                                  handleCreateMaintenance,
                                  handleDeleteAllMaintenances,
                                  handleDeleteMaintenance,
                                  handleReload,
                                  setLoading,
                              }) => (
    <Table striped bordered condensed hover>
        <thead>
        <tr>
            <th style={{width: '20%'}}>Date</th>
            <th style={{width: '5%'}}>Type</th>
            <th style={{width: '5%'}}>Statut</th>
            <th style={{width: '20%', 'wordBreak': 'break-all'}}>Cible</th>
            <th style={{width: '15%'}}>Fichier</th>
            <th style={{width: '15%'}}>Stacktrace</th>
            <th style={{width: '20%'}}>
                <ButtonToolbar>
                    <Button title='Rafraichir' bsSize='xsmall' bsStyle='primary' onClick={handleReload}>
                        <Glyphicon glyph='refresh'/>
                    </Button>

                    <SendGCMOrderButton contractNumber={contractNumber}
                                        type={GCM_ORDERS_TYPES.MAINTENANCE}
                                        setLoading={setLoading} />

                    <Button title='Supprimer les maintenances' bsSize='xsmall' bsStyle='danger'
                            onClick={handleDeleteAllMaintenances}>
                        <Glyphicon glyph='trash'/>
                    </Button>

                    <Button title='Ajouter une maintenance' bsSize='xsmall' bsStyle='success'
                            onClick={handleCreateMaintenance}>
                        <Glyphicon glyph='plus'/>
                    </Button>
                </ButtonToolbar>
            </th>
        </tr>
        </thead>
        <tbody>

        {maintenances
            .map(maintenance =>
                <tr key={maintenance.id}>
                    <td>{maintenance.dateCrea ? tsToDateForInput(maintenance.dateCrea) : ''}</td>
                    <td>{maintenance.type ? getUIFriendlyAccountMaintenanceType(maintenance.type) : ''}</td>
                    <td>{maintenance.state}</td>
                    <td style={{'wordBreak': 'break-all'}}>{maintenance.targetFilePath}</td>
                    <td>
                        {maintenance.fileExist ? (
                            <Button target='_blank'
                                    href={MaintenanceV2Controller.getMaintenanceFile(contractNumber, maintenance.id).url}
                                    bsSize='xsmall'>
                                <Glyphicon glyph='download-alt'/>&nbsp;Télécharger
                            </Button>
                        ) : 'Pas de fichier'
                        }
                    </td>
                    <td>
                        {maintenance.stackTrace !== '' && maintenance.stackTrace ? (
                            <Button target='_blank'
                                    href={MaintenanceV2Controller.getMaintenanceStacktrace(contractNumber, maintenance.id).url}
                                    bsSize='xsmall'>
                                <Glyphicon glyph='download-alt'/>&nbsp;Télécharger
                            </Button>
                        ) : 'Pas de stacktrace'
                        }
                    </td>
                    <td className='text-right'>
                        <Button bsSize='xsmall' bsStyle='danger'
                                onClick={() => handleDeleteMaintenance(maintenance.id)}>
                            <Glyphicon glyph='trash'/>
                        </Button>
                    </td>
                </tr>
            )}
        </tbody>
    </Table>
);

AccountMaintenanceTablePure.propTypes = {
    handleDeleteAllMaintenances: PropTypes.func,
    handleDeleteMaintenance: PropTypes.func,
    handleReload: PropTypes.func,
    setLoading: PropTypes.func,
};

const deleteAllMaintenances = (contractNumber) => MaintenanceV2Controller.deleteAllMaintenances(contractNumber).ajax();
const deleteMaintenance = (contractNumber, id) => MaintenanceV2Controller.deleteMaintenance(contractNumber, id).ajax();

const handlers = withHandlers({
    handleDeleteAllMaintenances: ({contractNumber, handleUpdateMaintenanceInfo, setLoading}) => () =>
        setLoading(
            true,
            () => deleteAllMaintenances(contractNumber).then(
                () => handleUpdateMaintenanceInfo(
                    {maintenances: []},
                    () => setLoading(false)
                ),
                () => setLoading(false),
            )
        ),
    handleDeleteMaintenance: ({contractNumber, maintenances, handleUpdateMaintenanceInfo, setLoading}) => (id) =>
        setLoading(
            true,
            () => deleteMaintenance(contractNumber, id).then(
                () => {
                    const newMaintenances = maintenances.filter(maintenance => maintenance.id !== id);
                    handleUpdateMaintenanceInfo({
                            maintenances: newMaintenances
                        },
                        () => setLoading(false)
                    )
                },
                () => setLoading(false),
            )
        ),
    handleReload: ({ handleReloadMaintenances, setLoading }) => () => handleReloadMaintenances(setLoading),
});

const AccountMaintenanceTable = compose(
    withLoading,
    handlers,
    pure,
)(AccountMaintenanceTablePure);

AccountMaintenanceTable.propTypes = {
    contractNumber: PropTypes.string,
    maintenances: PropTypes.array,
    handleCreateMaintenance: PropTypes.func,
    handleUpdateMaintenanceInfo: PropTypes.func,
    handleReloadMaintenances: PropTypes.func,
};

export default AccountMaintenanceTable;