import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReloadIcon from '../../../images/icons/reload.svg';
import {ASK_UPDATE_METRIC_MUTATION, GET_METRICS} from '../../../schema/MaintenanceSchema';
import {errorMsg, successMsg} from '../../../utils/Notify';
import LoadingProgress from '../../components/LoadingProgress';
import './AskMetricUpdateButton.css';
import {tsToFullDate} from "../../../utils/Date";

const updateMetricsCache = (cache, {data}, {name, metricType}) => {
  const metricsData = cache.readQuery({
    query: GET_METRICS,
  });
  
  const askMetricUpdate = data.askMetricUpdate;

  if (askMetricUpdate.__typename === 'AskMetricUpdateOutput') {
    const metricToUpdate = askMetricUpdate.metric;

    cache.writeQuery({
      data: {
        ...metricsData,
        getMetrics: {
          ...metricsData.getMetrics,
          metrics: metricsData.getMetrics.metrics.map(
            _ => _.id === metricToUpdate.id ? metricToUpdate : _,
          ),
        },
      },
      query: GET_METRICS,
    });

    successMsg(`Métrique ${name} (${metricType}) mise à jour !`);
  } else {
    const errorReason = askMetricUpdate.errorReason;
    errorMsg(`Erreur lors de la mise à jour de la métrique ${name} (${metricType}) : ${errorReason}`);
  }
}

const AskMetricUpdateButton = graphql(ASK_UPDATE_METRIC_MUTATION)(({ metric, mutate, result }) => {
  
  const {loading} = result;
  if (loading) {
    return <LoadingProgress show />;
  }
  
  const {metricType} = metric;
  const handleMetricUpdate = () => mutate({
    variables: {
      metricType,
    },
    update: (cache, {data}) => updateMetricsCache(cache, {data}, metric)
  });

  const reloadTooltip = ({name, lastModified}) => (
      <Tooltip id={"tooltip_" + name}>
        Dernier appel<br/>{tsToFullDate(lastModified)}
      </Tooltip>
  )

  return (
      <OverlayTrigger placement="right" overlay={reloadTooltip(metric)}>
        <div className="ask-metric-update-button" onClick={handleMetricUpdate}>
            <Image src={ReloadIcon}/>
        </div>
      </OverlayTrigger>
  );
});

AskMetricUpdateButton.propTypes = {
  metric: PropTypes.object,
};

export default AskMetricUpdateButton;