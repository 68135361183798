import moment from 'moment';
import {getNextMonthFirstDayMidday, getTodayMidday} from './Date';
import {ENABLE_IMMEDIATE_SUBSCRIPTION_SWITCH_FEATURE} from './constants';

const SUBSCRIPTION_TYPES = {
  ANCIEN_CLIENT_3G: 'ANCIEN_CLIENT_3G',
  ANCIEN_CLIENT_WIFI: 'ANCIEN_CLIENT_WIFI',
  CLASSIQUE_PREPAYE: 'CLASSIQUE_PREPAYE',
  DEMO: 'DEMO',
  DEMO_SIM: 'DEMO_SIM',
  DEMO_SIM_PLUS: 'DEMO_SIM_PLUS',
  LOCATION: 'LOCATION',
  LOCATION_SIM: 'LOCATION_SIM',
  LOCATION_SIM_PLUS: 'LOCATION_SIM_PLUS',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  OFFRE_EQUILIBRE: 'OFFRE_EQUILIBRE',
  OFFRE_EQUILIBRE_SIM: 'OFFRE_EQUILIBRE_SIM',
  OFFRE_EQUILIBRE_SIM_PLUS: 'OFFRE_EQUILIBRE_SIM_PLUS',
  OFFRE_EQUILIBRE_CASSE: 'OFFRE_EQUILIBRE_CASSE',
  OFFRE_EQUILIBRE_SIM_CASSE: 'OFFRE_EQUILIBRE_SIM_CASSE',
  OFFRE_EQUILIBRE_SIM_PLUS_CASSE: 'OFFRE_EQUILIBRE_SIM_PLUS_CASSE',
  OFFRE_EQUILIBRE_FIDELITE: 'OFFRE_EQUILIBRE_FIDELITE',
  OFFRE_EQUILIBRE_FIDELITE_CASSE: 'OFFRE_EQUILIBRE_FIDELITE_CASSE',
  OFFRE_EQUILIBRE_FIDELITE_SIM: 'OFFRE_EQUILIBRE_FIDELITE_SIM',
  OFFRE_EQUILIBRE_FIDELITE_SIM_CASSE: 'OFFRE_EQUILIBRE_FIDELITE_SIM_CASSE',
  OFFRE_EQUILIBRE_FIDELITE_SIM_PLUS: 'OFFRE_EQUILIBRE_FIDELITE_SIM_PLUS',
  OFFRE_EQUILIBRE_FIDELITE_SIM_PLUS_CASSE: 'OFFRE_EQUILIBRE_FIDELITE_SIM_PLUS_CASSE',
  OFFRE_LANCEMENT_3G: 'OFFRE_LANCEMENT_3G',
  OFFRE_LANCEMENT_3G_CASSE: 'OFFRE_LANCEMENT_3G_CASSE',
  OFFRE_LANCEMENT_SIM_PLUS: 'OFFRE_LANCEMENT_SIM_PLUS',
  OFFRE_LANCEMENT_SIM_PLUS_CASSE: 'OFFRE_LANCEMENT_SIM_PLUS_CASSE',
  OFFRE_LANCEMENT_WIFI: 'OFFRE_LANCEMENT_WIFI',
  OFFRE_LANCEMENT_WIFI_CASSE: 'OFFRE_LANCEMENT_WIFI_CASSE',
  OFFRE_LIBERTE: 'OFFRE_LIBERTE',
  OFFRE_LIBERTE_CASSE: 'OFFRE_LIBERTE_CASSE',
  OFFRE_LIBERTE_SIM: 'OFFRE_LIBERTE_SIM',
  OFFRE_LIBERTE_SIM_CASSE: 'OFFRE_LIBERTE_SIM_CASSE',
  OFFRE_LIBERTE_SIM_PLUS: 'OFFRE_LIBERTE_SIM_PLUS',
  OFFRE_LIBERTE_SIM_PLUS_CASSE: 'OFFRE_LIBERTE_SIM_PLUS_CASSE',
  OFFRE_ONLY_SIM: 'OFFRE_ONLY_SIM',
  OFFRE_ONLY_SIM_PLUS: 'OFFRE_ONLY_SIM_PLUS',
  OFFRE_RENOUV_SIM: 'OFFRE_RENOUV_SIM',
  OFFRE_RENOUV_SIM_CASSE: 'OFFRE_RENOUV_SIM_CASSE',
  OFFRE_RENOUV_SIM_PLUS: 'OFFRE_RENOUV_SIM_PLUS',
  OFFRE_RENOUV_SIM_PLUS_CASSE: 'OFFRE_RENOUV_SIM_PLUS_CASSE',
  OFFRE_RENOUV_WIFI: 'OFFRE_RENOUV_WIFI',
  OFFRE_RENOUV_WIFI_CASSE: 'OFFRE_RENOUV_WIFI_CASSE',
  OFFRE_SERENITE: 'OFFRE_SERENITE',
  OFFRE_SERENITE_PACK: 'OFFRE_SERENITE_PACK',
  OFFRE_SERENITE_PACK_SIM: 'OFFRE_SERENITE_PACK_SIM',
  OFFRE_SERENITE_PACK_SIM_PLUS: 'OFFRE_SERENITE_PACK_SIM_PLUS',
  OFFRE_SERENITE_SIM: 'OFFRE_SERENITE_SIM',
  OFFRE_SERENITE_SIM_PLUS: 'OFFRE_SERENITE_SIM_PLUS',
  OFFRE_SERENITE_FIDELITE: 'OFFRE_SERENITE_FIDELITE',
  OFFRE_SERENITE_FIDELITE_PACK: 'OFFRE_SERENITE_FIDELITE_PACK',
  OFFRE_SERENITE_FIDELITE_PACK_SIM: 'OFFRE_SERENITE_FIDELITE_PACK_SIM',
  OFFRE_SERENITE_FIDELITE_PACK_SIM_PLUS: 'OFFRE_SERENITE_FIDELITE_PACK_SIM_PLUS',
  OFFRE_SERENITE_FIDELITE_SIM: 'OFFRE_SERENITE_FIDELITE_SIM',
  OFFRE_SERENITE_FIDELITE_SIM_PLUS: 'OFFRE_SERENITE_FIDELITE_SIM_PLUS',
  OFFRE_SIM_CASSE: 'OFFRE_SIM_CASSE',
  OFFRE_SIM_PLUS: 'OFFRE_SIM_PLUS',
  OFFRE_SIM_PLUS_CASSE: 'OFFRE_SIM_PLUS_CASSE',
  OFFRE_STANDARD_3G: 'OFFRE_STANDARD_3G',
  OFFRE_STANDARD_WIFI: 'OFFRE_STANDARD_WIFI',
  OFFRE_TAB_INCLUSE_SIM: 'OFFRE_TAB_INCLUSE_SIM',
  OFFRE_TAB_INCLUSE_SIM_PLUS: 'OFFRE_TAB_INCLUSE_SIM_PLUS',
  OFFRE_TAB_INCLUSE_WIFI: 'OFFRE_TAB_INCLUSE_WIFI',
  OFFRE_WIFI_CASSE: 'OFFRE_WIFI_CASSE',
  PREPAYE_3G: 'PREPAYE_3G',
  PREPAYE_DUREE_VIE: 'PREPAYE_DUREE_VIE',
  PREPAYE_EQUILIBRE: 'PREPAYE_EQUILIBRE',
  PREPAYE_EQUILIBRE_SIM: 'PREPAYE_EQUILIBRE_SIM',
  PREPAYE_EQUILIBRE_SIM_PLUS: 'PREPAYE_EQUILIBRE_SIM_PLUS',
  PREPAYE_EQUILIBRE_CASSE: 'PREPAYE_EQUILIBRE_CASSE',
  PREPAYE_EQUILIBRE_SIM_CASSE: 'PREPAYE_EQUILIBRE_SIM_CASSE',
  PREPAYE_EQUILIBRE_SIM_PLUS_CASSE: 'PREPAYE_EQUILIBRE_SIM_PLUS_CASSE',
  PREPAYE_LIBERTE: 'PREPAYE_LIBERTE',
  PREPAYE_LIBERTE_CASSE: 'PREPAYE_LIBERTE_CASSE',
  PREPAYE_LIBERTE_SIM: 'PREPAYE_LIBERTE_SIM',
  PREPAYE_LIBERTE_SIM_CASSE: 'PREPAYE_LIBERTE_SIM_CASSE',
  PREPAYE_LIBERTE_SIM_PLUS: 'PREPAYE_LIBERTE_SIM_PLUS',
  PREPAYE_LIBERTE_SIM_PLUS_CASSE: 'PREPAYE_LIBERTE_SIM_PLUS_CASSE',
  PREPAYE_ONLY_SIM: 'PREPAYE_ONLY_SIM',
  PREPAYE_ONLY_SIM_PLUS: 'PREPAYE_ONLY_SIM_PLUS',
  PREPAYE_SIM_CASSE: 'PREPAYE_SIM_CASSE',
  PREPAYE_SIM_PLUS: 'PREPAYE_SIM_PLUS',
  PREPAYE_SIM_PLUS_CASSE: 'PREPAYE_SIM_PLUS_CASSE',
  PREPAYE_WIFI: 'PREPAYE_WIFI',
  PREPAYE_WIFI_CASSE: 'PREPAYE_WIFI_CASSE',
  PRET: 'PRET',
  PRET_SIM: 'PRET_SIM',
  PRET_SIM_PLUS: 'PRET_SIM_PLUS',
  SIMPLY: 'SIMPLY',
  TOOTI_ABO_3G: 'TOOTI_ABO_3G',
  TOOTI_ABO_WIFI: 'TOOTI_ABO_WIFI',
  TOOTI_PASS_WIFI: 'TOOTI_PASS_WIFI',
  TOOTI_SANS_ABO: 'TOOTI_SANS_ABO',
};

const SUBSCRIPTION_STATUS_TYPES = {
  ACTIVATED: {
    label: 'Actif',
    value: 'ACTIVATED',
  },
  DEACTIVATED: {
    label: 'Inactif',
    value: 'DEACTIVATED',
  },
  CONTRACT_CANCELED: {
    label: 'Contrat Annulé',
    value: 'CONTRACT_CANCELED',
  },
};

const SUBSCRIPTION_TERMINATION_REASONS = {
  ADMIN: {
    label: 'admin',
    value: '0',
  },
  UNPAID: {
    label: 'impayé',
    value: '1',
  },
  DEATH_HEALTH: {
    label: 'décès-santé',
    value: '2',
  },
  UNCOMMITTED: {
    label: 'désengagée',
    value: '3',
  },
  FIRST_YEAR: {
    label: 'engagée',
    value: '4',
  },
  NEGOTIATED: {
    label: 'négociée',
    value: '5',
  },
  SR: {
    label: 'SR',
    value: '6',
  },
  CANCELED: {
    label: 'contrat annulé',
    value: '7',
  },
  UNKNOWN: {
    label: 'NC',
    value: 'UNKNOWN',
  },
};

const isDemo = subscriptionType => [
  SUBSCRIPTION_TYPES.DEMO,
  SUBSCRIPTION_TYPES.DEMO_SIM,
  SUBSCRIPTION_TYPES.DEMO_SIM_PLUS,
].includes(subscriptionType);

const isPrepayed = subscriptionType => [
  SUBSCRIPTION_TYPES.PREPAYE_3G,
  SUBSCRIPTION_TYPES.PREPAYE_WIFI,
  SUBSCRIPTION_TYPES.PREPAYE_WIFI_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_SIM_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_SIM_PLUS,
  SUBSCRIPTION_TYPES.PREPAYE_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_EQUILIBRE,
  SUBSCRIPTION_TYPES.PREPAYE_EQUILIBRE_SIM,
  SUBSCRIPTION_TYPES.PREPAYE_EQUILIBRE_SIM_PLUS,
  SUBSCRIPTION_TYPES.PREPAYE_EQUILIBRE_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_EQUILIBRE_SIM_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_EQUILIBRE_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_LIBERTE,
  SUBSCRIPTION_TYPES.PREPAYE_LIBERTE_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_LIBERTE_SIM,
  SUBSCRIPTION_TYPES.PREPAYE_LIBERTE_SIM_PLUS,
  SUBSCRIPTION_TYPES.PREPAYE_LIBERTE_SIM_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_LIBERTE_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.PREPAYE_DUREE_VIE,
  SUBSCRIPTION_TYPES.PREPAYE_ONLY_SIM,
  SUBSCRIPTION_TYPES.PREPAYE_ONLY_SIM_PLUS,
  SUBSCRIPTION_TYPES.PRET,
  SUBSCRIPTION_TYPES.PRET_SIM,
  SUBSCRIPTION_TYPES.PRET_SIM_PLUS,
  SUBSCRIPTION_TYPES.LOCATION,
  SUBSCRIPTION_TYPES.LOCATION_SIM,
  SUBSCRIPTION_TYPES.LOCATION_SIM_PLUS,
].includes(subscriptionType);

const isPrepayedOrDemo = subscriptionType => isPrepayed(subscriptionType) || isDemo(subscriptionType);

const isProAbono = subscriptionType => [
  SUBSCRIPTION_TYPES.OFFRE_STANDARD_WIFI,
  SUBSCRIPTION_TYPES.OFFRE_STANDARD_3G,
  SUBSCRIPTION_TYPES.OFFRE_WIFI_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_SIM_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_RENOUV_WIFI,
  SUBSCRIPTION_TYPES.OFFRE_RENOUV_SIM,
  SUBSCRIPTION_TYPES.OFFRE_RENOUV_WIFI_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_RENOUV_SIM_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_RENOUV_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_RENOUV_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_LANCEMENT_WIFI,
  SUBSCRIPTION_TYPES.OFFRE_LANCEMENT_3G,
  SUBSCRIPTION_TYPES.OFFRE_LANCEMENT_WIFI_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_LANCEMENT_3G_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_LANCEMENT_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_LANCEMENT_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_ONLY_SIM,
  SUBSCRIPTION_TYPES.OFFRE_ONLY_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_PACK,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_PACK_SIM,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_PACK_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_SIM,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_FIDELITE,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_FIDELITE_PACK,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_FIDELITE_PACK_SIM,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_FIDELITE_PACK_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_FIDELITE_SIM,
  SUBSCRIPTION_TYPES.OFFRE_SERENITE_FIDELITE_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_LIBERTE,
  SUBSCRIPTION_TYPES.OFFRE_LIBERTE_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_LIBERTE_SIM,
  SUBSCRIPTION_TYPES.OFFRE_LIBERTE_SIM_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_LIBERTE_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_LIBERTE_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_SIM,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_SIM_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_SIM_PLUS_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_FIDELITE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_FIDELITE_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_FIDELITE_SIM,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_FIDELITE_SIM_CASSE,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_FIDELITE_SIM_PLUS,
  SUBSCRIPTION_TYPES.OFFRE_EQUILIBRE_FIDELITE_SIM_PLUS_CASSE,
].includes(subscriptionType);

const canSwitchSubscriptionTypeImmediately = (currentSubscription, newSubscriptionState) => {
  if (ENABLE_IMMEDIATE_SUBSCRIPTION_SWITCH_FEATURE) {
    const currentSubscriptionState = currentSubscription?.metadata?.subscriptionStates?.[0];
    const today = moment().startOf('day');
    const dateEnd = moment(currentSubscriptionState?.dateEnd).startOf('day');
    const terminated = dateEnd.isBefore(today);

    if (!currentSubscriptionState?.type || !newSubscriptionState?.type) {
      return false;
    }

    if (isProAbono(currentSubscriptionState?.type)) {
      if (isProAbono(newSubscriptionState?.type)) {
        return !terminated;
      } else {
        return terminated;
      }
    }
    return !isProAbono(newSubscriptionState?.type);
  }
  return false;
};

const canSwitchSubscriptionTypeFreely = (currentSubscription, newSubscriptionState) => {
  const currentSubscriptionState = currentSubscription?.metadata?.subscriptionStates?.[0];

  if (!currentSubscriptionState?.type || !newSubscriptionState?.type) return false;

  return !isProAbono(newSubscriptionState?.type);
};

const getDateBeginMinDate = (currentSubscriptionState) => {
  const today = getTodayMidday();
  const dateBegin = getNextMonthFirstDayMidday(today);
  const currentSubscriptionStateDateEnd = moment(currentSubscriptionState?.dateEnd);

  if (currentSubscriptionStateDateEnd.isAfter(getNextMonthFirstDayMidday(today))) {
    return currentSubscriptionStateDateEnd.valueOf();
  } else {
    return dateBegin.valueOf();
  }
};

export const SUBSCRIPTION_TYPE_GROUPS = {
  // B2C
  CLASSIQUE_MENSUEL: 'CLASSIQUE_MENSUEL',
  CLASSIQUE_PREPAYE: 'CLASSIQUE_PREPAYE',
  EQUILIBRE: 'EQUILIBRE',
  EQUILIBRE_FIDELITE: 'EQUILIBRE_FIDELITE',
  EQUILIBRE_PREPAYE: 'EQUILIBRE_PREPAYE',
  LANCEMENT: 'LANCEMENT',
  LIBERTE: 'LIBERTE',
  LIBERTE_PREPAYE: 'LIBERTE_PREPAYE',
  SERENITE: 'SERENITE',
  SERENITE_FIDELITE: 'SERENITE_FIDELITE',
  RENOUV: 'RENOUV',

  // B2B
  DEMO: 'DEMO',
  ONLY_SIM: 'ONLY_SIM',
  LOCATION: 'LOCATION',
  PRET: 'PRET',
  B2B_PREPAYE: 'B2B_PREPAYE',

  // LEGACY
  ANCIEN_CLIENT: 'ANCIEN_CLIENT',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  OFFRE_TAB_INCLUSE: 'OFFRE_TAB_INCLUSE',
  SIMPLY: 'SIMPLY',
  TOOTI_MENSUEL: 'TOOTI_MENSUEL',
  TOOTI_PREPAYE: 'TOOTI_PREPAYE',
  TOOTI_SANS_ABO: 'TOOTI_SANS_ABO',
};

export {
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_STATUS_TYPES,
  SUBSCRIPTION_TERMINATION_REASONS,
  isDemo,
  isPrepayed,
  isPrepayedOrDemo,
  isProAbono,
  canSwitchSubscriptionTypeImmediately,
  canSwitchSubscriptionTypeFreely,
  getDateBeginMinDate,
};