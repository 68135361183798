import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateFunction, withButtonContext} from './Helpers';

const SetUnknownButtonPure = ({
                                buttonSize,
                                onValidateClick,
                              }) => (
  <Button bsSize={buttonSize} onClick={onValidateClick}>
    Unknown
  </Button>
);

SetUnknownButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  onValidateClick: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateFunction,
});

const SetUnknownButton = compose(
  withButtonContext,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.reset,
    }),
  ),
  handlers,
  pure,
)(SetUnknownButtonPure);

SetUnknownButton.propTypes = {};

export default SetUnknownButton;