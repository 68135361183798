import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';

const ClearFiltersButton = props => (
	<Button bsSize='small' bsStyle='primary' className='center-block' {...props}>
		<Glyphicon glyph='remove'/>&nbsp;Effacer
	</Button>
);

ClearFiltersButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
};

export default ClearFiltersButton;