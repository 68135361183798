const PRODUCT_REFERENCE_LABELS = {
  TABLETTE_ARDOIZ_V1_LPM: 'Tablette Ardoiz V1 LPM',
  TABLETTE_ARDOIZ_V1_TRANSATEL: 'Tablette Ardoiz V1 Transatel',

  TABLETTE_ARDOIZ_V2_LPM: 'Tablette Ardoiz V2 LPM',
  TABLETTE_ARDOIZ_V2_TRANSATEL: 'Tablette Ardoiz V2 Transatel',

  TABLETTE_DEMO_ARDOIZ_V2_LPM: 'Tablette DEMO Ardoiz V2 LPM',
  TABLETTE_DEMO_ARDOIZ_V2_TRANSATEL: 'Tablette DEMO Ardoiz V2 Transatel',

  PACK_TABLETTE_ARDOIZ_V2_LPM_ET_ENCEINTE: 'Pack Tablette Ardoiz V2 LPM + Station',
  PACK_TABLETTE_ARDOIZ_V2_TRANSATEL_ET_ENCEINTE: 'Pack Tablette Ardoiz V2 Transatel + Station',

  PACK_TABLETTE_DEMO_ARDOIZ_V2_LPM_ET_ENCEINTE: 'Pack Tablette Ardoiz V2 LPM + Station',
  PACK_TABLETTE_DEMO_ARDOIZ_V2_TRANSATEL_ET_ENCEINTE: 'Pack Tablette Ardoiz V2 Transatel + Station',

  PACK_TABLETTE_ARDOIZ_V2_CASE_ACCESSORY: 'Pack Tablette Ardoiz V2',
  PACK_TABLETTE_ARDOIZ_V2_RECONDITIONED_CASE_ACCESSORY: 'Pack Tablette Ardoiz V2 reconditionnée',

  PACK_TABLETTE_ARDOIZ_V2_CASE_ACCESSORY_DOCKING: 'Pack Tablette Ardoiz V2 + Station',
  PACK_TABLETTE_ARDOIZ_V2_RECONDITIONED_CASE_ACCESSORY_DOCKING: 'Pack Tablette Ardoiz V2 reconditionnée + Station',
  
  TABLETTE_ARDOIZ_V3_LPM: 'Tablette Ardoiz V3 LPM',
  TABLETTE_ARDOIZ_V3_TRANSATEL: 'Tablette Ardoiz V3 Transatel',
  
  TABLETTE_ARDOIZ_V3_LPM_RECONDITIONED: 'Tablette Ardoiz V3 LPM reconditionnée',
  TABLETTE_ARDOIZ_V3_TRANSATEL_RECONDITIONED: 'Tablette Ardoiz V3 Transatel reconditionnée',
  
  PACK_TABLETTE_ARDOIZ_V3_LPM_ET_ENCEINTE: 'Pack Tablette Ardoiz V3 LPM + Station',
  PACK_TABLETTE_ARDOIZ_V3_TRANSATEL_ET_ENCEINTE: 'Pack Tablette Ardoiz V3 Transatel + Station',
  
  PACK_TABLETTE_ARDOIZ_V3_LPM_ET_ENCEINTE_RECONDITIONED: 'Pack Tablette Ardoiz V3 LPM reconditionnée + Station',
  PACK_TABLETTE_ARDOIZ_V3_TRANSATEL_ET_ENCEINTE_RECONDITIONED: 'Pack Tablette Ardoiz V3 Transatel reconditionnée + Station',

  ENCEINTE: 'Station Ardoiz',
  CHARGEUR_V2: 'Chargeur V2',
};

const getOrderProduct = order => {
  const dapoProductReferences = order.prestations.dapo?.products || [];
  const lmrProductReferences = order.prestations.lmr?.products || [];
  return [
    ...dapoProductReferences,
    ...lmrProductReferences,
  ]
    .map(product => formatProductReference(product.reference))
    .join('\n');
};

const formatProductReference = productReference => {
  if (!productReference) return;

  return PRODUCT_REFERENCE_LABELS[productReference] || productReference;
};

export {
  getOrderProduct,
};