import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withState} from 'recompose';
import {withButtonContext} from './ActionButtons/Helpers';
import ContractHistoryModal from './ContractHistory/ContractHistoryModal';

const ContractHistoryPure = ({
                               buttonSize,
                               showModal,
                               simId,
                               setShowModal,
                             }) => (
  <ButtonGroup block vertical>
    <Button bsStyle="default" bsSize={buttonSize} data-test-id="show-modal-button" onClick={() => setShowModal(true)}>Historique des contrats</Button>
    <ContractHistoryModal show={showModal} simId={simId} setShowModal={setShowModal}/>
  </ButtonGroup>
);

ContractHistoryPure.propTypes = {
  buttonSize: PropTypes.string,
  showModal: PropTypes.bool,
  simId: PropTypes.string,
  setShowModal: PropTypes.func,
};

const withShowModalState = withState('showModal', 'setShowModal', false);

const ContractHistory = compose(
  branch(
    ({simId}) => !simId,
    renderNothing
  ),
  withButtonContext,
  withShowModalState,
  pure,
)(ContractHistoryPure);

ContractHistory.propTypes = {
  simId: PropTypes.string,
};

export default ContractHistory;