import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connectWebUserContext} from '../hoc/withMeAndTeamsAndConstants';
import AppBar from './AppBar';

const AppLayout = connectWebUserContext(({ children, topNavProps, webUserJson }) => (
  <>
    {webUserJson && (
      <div id='nav'>
        <AppBar {...topNavProps} />
      </div>
    )}

    <div id='content'>
      {children}
    </div>

    <div className='clearfix' />

    {webUserJson && (
      <div className='bg-primary'>
        <div id='footerContainer' className='bg-primary container'>
          <footer className='text-center footer'>
              &copy; {moment().year()} - <a style={{ color: 'white' }} href='http://www.tikeasy.com'>Tikeasy</a>
          </footer>
        </div>
      </div>
    )}
  </>
));

AppLayout.propTypes = {
  children: PropTypes.object,
  topNavProps: PropTypes.object,
};

export default AppLayout;