import PropTypes from 'prop-types';
import React from 'react';
import {NavItem} from 'react-bootstrap';
import {connectWebUserContext} from '../../hoc/withMeAndTeamsAndConstants';
import { isDevicesAndSimsVisible } from '../../utils/accessRights';
import { navigateTo } from '../../utils/functions';

const AdminLinks = connectWebUserContext(({ webUserJson, currentPage }) => {
  if (isDevicesAndSimsVisible(webUserJson)) {
    return (
      <>
        <NavItem key="device" active={currentPage === 'device'} onClick={() => navigateTo('/device')}>Devices</NavItem>,
        <NavItem key="sim" active={currentPage === 'sim'} onClick={() => navigateTo('/sim')}>Sims</NavItem>
      </>
    );

  }

  return null;
});

AdminLinks.propTypes = {
  currentPage: PropTypes.string,
};

export default AdminLinks;