import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import { connectWebUserContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { isValidationOrHigher } from '../../../utils/functions';

import TicketTransferRowButton from '../TicketTransferRowButton';

const TeamTicketsTableRowActions = connectWebUserContext(({webUserJson, ticket, transferTicket, handleEdit, handleOpen, handleOpenInNewTab}) => {
	return (
		<td>
			<ButtonGroup vertical block>
				<Button className='center-block' bsSize='xsmall' onClick={handleOpen}>Voir</Button>

				{isValidationOrHigher(webUserJson) && (
					<Button className='center-block' bsSize='xsmall' onClick={handleEdit}>Éditer</Button>
				)}

				<Button className='center-block' bsSize='xsmall' onClick={handleOpenInNewTab}>
					<span className='glyphicon glyphicon-plus' aria-hidden='true' /> Nouvel onglet
				</Button>

				<TicketTransferRowButton ticket={ticket} transferTicket={transferTicket} />
			</ButtonGroup>
		</td>
	);
});

export default TeamTicketsTableRowActions;