import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import ChangeSubjectModal from './ChangeSubjectModal';

const ChangeSubjectModalButton = ({
	closed,
	hidden,
	ticket = {},
	initialTicket = {},
	resultOptions,
	subjectOptions,
	handleTicketPropertyChange,
	handleChangeSubjectTicket,
}) => {
	const [show, setShow] = useState(false);

	if (hidden) return null;
	
	const handleShow = () => setShow(true);
	
	const handleHide = () => setShow(false);

	const handleSubmit = ({subject, result, firstIntention, resultComment}) => {
		handleChangeSubjectTicket({subject, result, firstIntention, resultComment});
		handleHide();
	}
  
  return <>
    <Button className='pull-left' onClick={handleShow}>
			Changer la demande
    </Button>
		<ChangeSubjectModal
			show={show}
			onHide={handleHide}
			onSubmit={handleSubmit}
			closed={closed}
			ticket={ticket}
			initialTicket={initialTicket}
			subjectOptions={subjectOptions}
			resultOptions={resultOptions}
			handleTicketPropertyChange={handleTicketPropertyChange}
			handleChangeSubjectTicket={handleChangeSubjectTicket}
		/>
	</>;
}

ChangeSubjectModalButton.propTypes = {
	closed: PropTypes.bool,
	hidden: PropTypes.bool,
	ticket: PropTypes.object,
	initialTicket: PropTypes.object,
	resultOptions: PropTypes.array,
	subjectOptions: PropTypes.array,
	handleTicketPropertyChange: PropTypes.func,
	handleChangeSubjectTicket: PropTypes.func,
};

export default ChangeSubjectModalButton;