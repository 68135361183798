import React from 'react';
import { Panel } from 'react-bootstrap';
import DevicesTable from './DevicesTable';
import DevicesTableTitle from './DevicesTableTitle';

const DevicesSearchResults = ({searchProperty, searchValue, ...props}) => (			
	<Panel bsStyle='info'>
		<DevicesTableTitle searchProperty={searchProperty} searchValue={searchValue} properties={props.properties} />
		<Panel.Body>
			<DevicesTable
				{...props}
				noPagination // TODO (CAN-544) : restore a real pagination with the backend
			/>
		</Panel.Body>
	</Panel>
);

export default DevicesSearchResults;