import PropTypes from 'prop-types';
import React from 'react';
import {FormControl, FormGroup} from 'react-bootstrap';
import { ClearFiltersButton } from '../../components';

const DevicesTableFilters = ({loading, filters, properties, createFilterInputChangeHandler}) => (
	<tr>
		{properties.map(property => (
			<th key={'property_filter_' + property.name}>
				<FormGroup className='small-control' bsSize='small'>
					<FormControl
						type='text'
						value={filters?.[property.name] || ''}
						onChange={createFilterInputChangeHandler(property.name)}
					/>
				</FormGroup>
			</th>
		))}
		<th>
			<ClearFiltersButton
				disabled={loading && !!filters}
				onClick={createFilterInputChangeHandler('')}
			/>
		</th>
	</tr>
);

DevicesTableFilters.propTypes = {
	loading: PropTypes.bool,
	filters: PropTypes.object,
	properties: PropTypes.array,
	createFilterInputChangeHandler: PropTypes.func,
};

export default DevicesTableFilters;