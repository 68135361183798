import React from 'react';
import { Glyphicon, Label } from 'react-bootstrap';

// 1. takeActionDay or noLaterThanDay before today priority = diff day
// 2. takeActionDay or noLaterThanDay is today => 0
// 3. 'À faire' by 'Fraicheur' si pas de takeActionDay => 1
// 4. 'En cours' by 'Fraicheur' si pas de takeActionDay => 2
// 5. takeActionDay restant => 3
const TicketPriority = ({value}) => {

  if (value < 0) {
    return <Label bsStyle='default'>J{value}</Label>;
  }
  if (value === 0) {
    return <Label bsStyle='success'>J+{value}</Label>;
  }
  if (value === 1) {
    return <Label bsStyle='warning'><Glyphicon glyph='warning-sign' /> J+{value}</Label>;
  }
  if (value === 2) {
    return <Label bsStyle='danger'><Glyphicon glyph='warning-sign' /> J+{value}</Label>;
  }
  // > 2
  return <Label bsStyle='danger'><Glyphicon glyph='exclamation-sign' /> J+{value}</Label>;
};

export default TicketPriority;