import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withContext, withState } from 'recompose';
import UltimatePagination from '../views/components/UltimatePagination';

const wrapWithPagination = BaseComponent => props => (
    <Fragment>
        <BaseComponent {...props} />
        {props.pageNumber > 1 && (
            <div className='text-center'>
                <UltimatePagination totalPages={props.pageNumber} currentPage={props.activePage} onChange={(num) => props.setActivePage(num)}/>
            </div>
        )}
    </Fragment>
);

const withPagination = compose(
    withState('activePage', 'setActivePage', 1),
    withContext({
            setActivePage: PropTypes.func,
        },
        (p) => ({ setActivePage: p.setActivePage })
    ),
    wrapWithPagination,
    pure,
);

export default withPagination;