import React from 'react';
import {Col, Panel, Row} from 'react-bootstrap';
import {compose, pure, withState} from 'recompose';
import {isTransatel, SIM_STATES} from '../../utils/Sim';
import ToucanListOperationErrors from '../components/ToucanListOperation/ToucanListOperationErrors';
import ToucanListOperationSuccess from '../components/ToucanListOperation/ToucanListOperationSuccess';

const contractNumberSchemaField = {
  title: 'Numéros de contrat*',
  type: 'string',
};

const contractNumbersUiSchemaField = {
  'ui:widget': 'textarea',
  'ui:options': {
    rows: 4,
  },
};

// Reformat contractNumbers without extra space
const onFormDataChange = ({setFormData}) => ({formData}) =>
  setFormData({
    ...formData,
    contractNumbers: formatContractNumbers(formData.contractNumbers || ''),
  });

export const formatContractNumbers = (contractNumbers) =>
  contractNumbers
    .split('\n')
    .map(_ => _.trim())
    .join('\n');

export const isContractNumbersMalformed = (contractNumbers) =>
  contractNumbers
    .split('\n')
    .find(_ => !/^\d{8}$/.test(_));

const validateForm = ({contractNumbers}, errors) => {
  const malformedContractNumber = isContractNumbersMalformed(contractNumbers);

  if (malformedContractNumber) {
    errors.contractNumbers.addError('Veuillez saisir un numéro de contrat par ligne');
    errors.contractNumbers.addError(`Numéro de contrat invalide : ${malformedContractNumber}`);
  }

  return errors;
};

const withResultState = withState('result', 'setResult', {errors: [], success: []});

const convertResult = ({contracts, simInfos, subscriptions, errors}, {setLoading, setResult}, errorsTranslations) => {
  const result = {errors: [], success: []},
    successResults = contracts || subscriptions;

  if (successResults?.length) {
    result.success = successResults.map(_ => `Contrat ${_.contractNumber} mis à jour`);
  } else if (simInfos) {
    result.success = simInfos.map(
      ({contractNumber, sim}) => {
        if (
          isTransatel(sim) &&
          sim?.currentState?.state === SIM_STATES.NEED_TERMINATION.value
        ) {
          return `Contrat ${contractNumber} : procédure manuelle, ticket N2T créé`;
        }
        return `Contrat ${contractNumber} : Sim ${SIM_STATES[sim?.currentState?.state]?.label}`;
      },
    );
  }

  const translateErrorFunction = translateError(errorsTranslations);

  if (errors?.length) {
    result.errors = errors.map(translateErrorFunction);
  }

  setLoading(false, () => setResult(result));
};

const translateError = errorsTranslations => error => `${error.subject} : ${errorsTranslations[error.reason]?.message}`;

const wrapForm = BaseComponent => ({result, ...props}) => (
  <Panel bsStyle="success">
    <Panel.Body>
      <Row>
        <Col md={6} mdOffset={3}>
          <ToucanListOperationSuccess success={result.success}/>
          <ToucanListOperationErrors errors={result.errors}/>
        </Col>
      </Row>
      <Row>
        <Col md={6} mdOffset={3}>
          <BaseComponent {...props}/>
        </Col>
      </Row>
    </Panel.Body>
  </Panel>
);

const withToucanListOperationResult = compose(
  withResultState,
  wrapForm,
  pure,
);

const formatContractNumbersForSave = contractNumbers => contractNumbers.split('\n').filter(_ => !!_);

export {
  contractNumberSchemaField,
  contractNumbersUiSchemaField,
  withToucanListOperationResult,
  convertResult,
  formatContractNumbersForSave,
  onFormDataChange,
  validateForm,
};