import React from 'react';
import {compose} from 'recompose';
import {ApolloProvider} from 'react-apollo';

import {withMeAndTeamsAndConstantsContext} from './withMeAndTeamsAndConstants';
import {withApolloClientContext} from '../utils/Apollo';

const Root = ({apolloClient, ...props}) =>
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;

const RootContext = compose(
    withMeAndTeamsAndConstantsContext(
        ({ tags, teams, types, webUserJson }) => ({ tags, teams, types, webUserJson })
    ),
    withApolloClientContext(
        ({apolloClient}) => ({apolloClient})
    ),
)(Root);

export default RootContext;