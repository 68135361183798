import PropTypes from 'prop-types';
import React from 'react';
import {Col, Grid, Modal, Row} from 'react-bootstrap';
import {getOrderNumberFromAfterSaleId} from '../../../utils/AfterSale';
import ContractTabs from './ContractTabs';
import ContractDetails from './ContractDetails';
import ContractManager from '../ContractManager';
import ContractTickets from '../../Tickets/ContractTickets';
import AfterSaleTabletReturnForm from '../../Tickets/CreateNewTicketsPanel/AfterSaleTabletReturnForm';

const CustomerContractLayout = ({
  contractNumber,
  loading,
  progress,
  ticketNumber,
  tags,
  tickets,
  addTicket,
  deleteTicket,
  removeTicket,
  setTicket,
  setAnswer,

  contract,
  currentEditedAfterSale,
  dmd,
  loadContactInfo,
  shouldShowB2BTab,
  setCurrentEditedAfterSale,
  setDmd,
}) => (
  <>
    <Grid>
      <Row>
        <Col md={8}>
          <ContractTabs
            contractNumber={contractNumber}
            addTicket={addTicket}
            contract={contract}
            dmd={dmd}
            loadContactInfo={loadContactInfo}
            shouldShowB2BTab={shouldShowB2BTab}
            setCurrentEditedAfterSale={setCurrentEditedAfterSale}
            setDmd={setDmd}
          />
        </Col>
        <Col md={4}>
          <ContractDetails/>
        </Col>
      </Row>
      {currentEditedAfterSale &&
        <Modal bsSize="lg" dialogClassName="afterSaleFormModal" show onHide={() => setCurrentEditedAfterSale()}>
          <Modal.Header closeButton>
            <Modal.Title>
              Modification du retour N°{getOrderNumberFromAfterSaleId(currentEditedAfterSale.id)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AfterSaleTabletReturnForm
              editedAfterSale={currentEditedAfterSale}
              isEditMode
              onHide={() => setCurrentEditedAfterSale()}
            />
          </Modal.Body>
        </Modal>
      }
    </Grid>
    <hr/>
    <Grid fluid>
      <ContractTickets
        contractNumber={contractNumber}
        loading={loading}
        progress={progress}
        ticketNumber={ticketNumber}
        tags={tags}
        tickets={tickets}
        deleteTicket={deleteTicket}
        removeTicket={removeTicket}
        addTicket={addTicket}
        setTicket={setTicket}
        setAnswer={setAnswer}
      />
    </Grid>
  </>
);

CustomerContractLayout.propTypes = {
  contract: PropTypes.object,
  contractNumber: PropTypes.string,
  currentEditedAfterSale: PropTypes.object,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  ticketNumber: PropTypes.string,
  tags: PropTypes.array,
  tickets: PropTypes.array,
  addTicket: PropTypes.func,
  setTicket: PropTypes.func,
  deleteTicket: PropTypes.func,
  loadContactInfo: PropTypes.func,
  removeTicket: PropTypes.func,
  shouldShowB2BTab: PropTypes.bool,
  setAnswer: PropTypes.func,
  setCurrentEditedAfterSale: PropTypes.func,
  setDmd: PropTypes.func,
};

const CustomerContract = ({contractNumber, ticketNumber}) => (
  <ContractManager
    Component={CustomerContractLayout}
    contractNumber={contractNumber}
    ticketNumber={ticketNumber}
  />
);

CustomerContract.propTypes = {
  contractNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
};

export default CustomerContract;