import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';
import {connectTypesAndWebUserContext} from '../../../../hoc/withMeAndTeamsAndConstants';

import {sortAlpha} from '../../../../utils/Array';
import {getTeamType} from '../../../../utils/functions';
import DateBlock from '../DateBlock';

const TransferNewTicketModal = connectTypesAndWebUserContext(({webUserJson, types, ticket, typeTeam, addTicketTags, handleTicketChange, handleSave, afterValidate, onHide}) => {

	const [team, setTeam] = useState(ticket.team);
	const [answerContent, setAnswerContent] = useState('');

	const handleTeamChange = event => setTeam(event.target.value);

	const handleAnswerContentChange = event => setAnswerContent(event.target.value);
	
	const handleValidateTransfer = () => {

		const createDataWithAnswer = answer => {
			let data = new FormData();
			if (answer) {
				data.append('answer_0', JSON.stringify(answer));
			}
			if (answerContent && answerContent.length > 0) {
				data.append('answer_1', JSON.stringify({'content': answerContent}));
			}
			return data;
		};

		delete ticket.result;
		ticket = addTicketTags(ticket);
		ticket.team = team;

		handleTicketChange(ticket);

		const transferAnswer = {
			'content': `Transfert de ${getTeamType(types, webUserJson.team)} vers ${getTeamType(types, team)}`,
			'type': 'TEAM_CHANGE',
		};
		const answer = createDataWithAnswer(transferAnswer);
		handleSave(answer);

		afterValidate();
	}

	return (
    <Modal show={true} onHide={onHide}>
			<Modal.Header closeButton>
					<Modal.Title>Transférer le ticket</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormGroup>
					<ControlLabel>Transférer à une autre team</ControlLabel>
					<FormControl
						componentClass='select'
						placeholder='Equipe'
						value={team}
						onChange={handleTeamChange}
					>
						{sortAlpha(typeTeam, _ => getTeamType(types, _)).map(type_team => (
							<option key={type_team} value={type_team}>
								{getTeamType(types, type_team)}
							</option>
						))}
					</FormControl>
					<DateBlock ticket={ticket} handleTicketChange={handleTicketChange} />
				</FormGroup>
				<FormGroup>
					<ControlLabel>Ajouter un commentaire</ControlLabel>
					<FormControl
						componentClass='textarea'
						placeholder='Saisir le commentaire'
						value={answerContent}
						onChange={handleAnswerContentChange}
					/>
				</FormGroup>
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='warning' onClick={handleValidateTransfer}>
					Transférer
				</Button>
			</Modal.Footer>
    </Modal>
	);
});

TransferNewTicketModal.propTypes = {
	ticket: PropTypes.object,
	typeTeam: PropTypes.array,
	addTicketTags: PropTypes.func,
	afterValidate: PropTypes.func,
	handleTicketChange: PropTypes.func,
	handleSave: PropTypes.func,
	onHide: PropTypes.func,
};

export default TransferNewTicketModal;