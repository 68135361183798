import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, Form, FormControl, FormGroup, Glyphicon, InputGroup, Row} from 'react-bootstrap';
import {compose, lifecycle, pure, withHandlers, withProps, withState} from 'recompose';
import {connectContractNumberContext} from '../../../../../hoc/contractNumber';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {formatPhone} from '../../../../../utils/functions';
import LoadingProgress from '../../../../components/LoadingProgress';
import ActionButtonGroup from './ActionButtonGroup';
import CopyUserData from './CopyUserData';
import getUser, {bindUser} from './getUser';
import saveUser from './saveUser';

const USER_TYPES = {
    BUYER: 'Acheteur',
    MAIN_USER: 'Utilisateur',
    SUBSCRIBER: 'Souscripteur',
};

const ClientUserPure = ({
                            contract,
                            dmd,
                            formData,
                            loading,
                            locked,
                            lockedInputs,
                            userType,
                            validator,
                            handleCancel,
                            handleChange,
                            handleSave,
                            setLocked,
                        }) => (
    <>
        <Row>
            <Col md={12}>
                <LoadingProgress show={loading}/>
                <CopyUserData contract={contract}
                              dmd={dmd}
                              user={formData}/>
            </Col>
        </Row>

        <Row>
            <Col md={12}>
                <Form>
                    <LoadingProgress show={loading}/>
                    <ControlLabel>{userType}</ControlLabel>
                    <div className='clearfix'/>
                    <Row>
                        <Col md={4}>
                            <FormGroup validationState={validator.gender}>
                                <InputGroup>
                                    <InputGroup.Addon><Glyphicon glyph='user'/></InputGroup.Addon>
                                    <FormControl placeholder='Genre'
                                                 readOnly={locked || lockedInputs}
                                                 type='text'
                                                 value={formData.gender}
                                                 onChange={e => handleChange(e, 'gender')}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup validationState={validator.lastName}>
                                <InputGroup>
                                    <InputGroup.Addon><Glyphicon glyph='user'/></InputGroup.Addon>
                                    <FormControl placeholder='Nom'
                                                 readOnly={locked || lockedInputs}
                                                 type='text'
                                                 value={formData.lastName}
                                                 onChange={e => handleChange(e, 'lastName')}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup validationState={validator.firstName}>
                                <FormControl placeholder='Prénom'
                                             readOnly={locked || lockedInputs}
                                             type='text'
                                             value={formData.firstName}
                                             onChange={e => handleChange(e, 'firstName')}/>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='home'/></InputGroup.Addon>
                            <FormControl placeholder='Société'
                                         readOnly={locked || lockedInputs}
                                         type='text'
                                         value={formData.company}
                                         onChange={e => handleChange(e, 'company')}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup validationState={validator.addressLine0}>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='home'/></InputGroup.Addon>
                            <FormControl placeholder='Adresse 1'
                                         readOnly={locked || lockedInputs}
                                         type='text'
                                         value={formData.addressLine0}
                                         onChange={e => handleChange(e, 'addressLine0')}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='home'/></InputGroup.Addon>
                            <FormControl placeholder='Adresse 2'
                                         readOnly={locked || userType === 'Utilisateur' || lockedInputs}
                                         type='text'
                                         value={formData.addressLine1}
                                         onChange={e => handleChange(e, 'addressLine1')}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='home'/></InputGroup.Addon>
                            <FormControl placeholder='Adresse 3'
                                         readOnly={locked || userType === 'Utilisateur' || lockedInputs}
                                         type='text'
                                         value={formData.addressLine2}
                                         onChange={e => handleChange(e, 'addressLine2')}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='home'/></InputGroup.Addon>
                            <FormControl placeholder='Adresse 4'
                                         readOnly={locked || userType === 'Utilisateur' || lockedInputs}
                                         type='text'
                                         value={formData.addressLine3}
                                         onChange={e => handleChange(e, 'addressLine3')}/>
                        </InputGroup>
                    </FormGroup>
                    <Row>
                        <Col md={6}>
                            <FormGroup validationState={validator.zipcode}>
                                <InputGroup>
                                    <InputGroup.Addon><Glyphicon glyph='globe'/></InputGroup.Addon>
                                    <FormControl placeholder='Code postal'
                                                 readOnly={locked || lockedInputs}
                                                 type='text'
                                                 value={formData.zipcode}
                                                 onChange={e => handleChange(e, 'zipcode')}/>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup validationState={validator.city}>
                                <FormControl placeholder='Ville'
                                             readOnly={locked || lockedInputs}
                                             type='text'
                                             value={formData.city}
                                             onChange={e => handleChange(e, 'city')}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup validationState={validator.country}>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='globe'/></InputGroup.Addon>
                            <FormControl placeholder='Pays'
                                         readOnly={locked || lockedInputs}
                                         type='text'
                                         value={formData.country}
                                         onChange={e => handleChange(e, 'country')}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup validationState={validator.email}>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph='envelope'/></InputGroup.Addon>
                            <FormControl placeholder='Email'
                                         readOnly={locked || userType === 'Utilisateur'}
                                         type='text'
                                         value={formData.email}
                                         onChange={e => handleChange(e, 'email')}/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputGroup.Addon><Glyphicon glyph='earphone'/></InputGroup.Addon>
                                    <FormControl placeholder='Téléphone'
                                                 readOnly={locked}
                                                 type='text'
                                                 value={formData.phone}
                                                 onChange={e => handleChange(e, 'phone')}/>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <InputGroup.Addon><Glyphicon glyph='phone'/></InputGroup.Addon>
                                    <FormControl placeholder='Téléphone portable'
                                                 readOnly={locked || lockedInputs}
                                                 type='text'
                                                 value={formData.cellphone}
                                                 onChange={e => handleChange(e, 'cellphone')}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </FormGroup>

                    <ActionButtonGroup locked={locked}
                                       handleCancel={handleCancel}
                                       handleEdit={() => setLocked(false)}
                                       handleSave={handleSave}/>
                </Form>
            </Col>
        </Row>
    </>
);

ClientUserPure.propTypes = {
    contract: PropTypes.object,
    dmd: PropTypes.object,
    formData: PropTypes.object,
    loading: PropTypes.bool,
    locked: PropTypes.bool,
    lockedInputs: PropTypes.bool,
    userType: PropTypes.string,
    validator: PropTypes.object,
    handleCancel: PropTypes.func,
    handleChange: PropTypes.func,
    handleSave: PropTypes.func,
    setLocked: PropTypes.func,
};

const defaultFormData = {
    addressLine0: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    cellphone: '',
    city: '',
    company: '',
    country: '',
    email: '',
    firstName: '',
    gender: '',
    lastName: '',
    phone: '',
    zipcode: '',
};

const withFormDataState = withState('formData', 'setFormData', defaultFormData);
const withLoadingState = withState('loading', 'setLoading', false);
const withLockedState = withState('locked', 'setLocked', true);
const withUserState = withState('user', 'setUser', {});

const validatorDefaultValues = {
    gender: null,
    firstName: null,
    lastName: null,
    addressLine0: null,
    zipcode: null,
    city: null,
    country: null,
    email: null,
    phone: null,
};
const withValidatorState = withState('validator', 'setValidator', validatorDefaultValues);

const handlers = withHandlers({
    handleCancel: (props) => () => {
        const {user, setLocked, setValidator} = props;
        setValidator(
            validatorDefaultValues,
            () => setLocked(
                true,
                () => bindUser(props, user),
            ),
        );
    },
    handleChange: ({formData, setFormData}) => (event, stateName) => {
        let value = event.target.value;

        if (stateName === 'phone' || stateName === 'cellphone') {
            value = value.replace(/\s/g, '');
        }

        setFormData({
            ...formData,
            [stateName]: value,
        });
    },
    handleSave: props => () => saveUser(props),
});

const formatNullValues = (formData, locked) => {
    const formattedData = {};

    Object.entries(formData)
        .forEach(([key, value]) => formattedData[key] = value || '');

    if (locked) {
        formattedData['cellphone'] = formatPhone(formData['cellphone']);
        formattedData['phone'] = formatPhone(formData['phone']);
    }

    return formattedData;
};

const withCalculatedProps = withProps(
    ({formData, locked, userType}) => ({
        formData: formatNullValues(formData, locked),
        lockedInputs: userType === 'Souscripteur' && !formData.isPrepayed && !formData.isDemo,
    }),
);

const withLifecycle = lifecycle({
    componentDidMount() {
        getUser(this.props);
    },
});

const ClientUser = compose(
    connectContractNumberContext,
    connectMeAndTeamsAndConstantsContext,
    withFormDataState,
    withLoadingState,
    withLockedState,
    withUserState,
    withValidatorState,
    handlers,
    withCalculatedProps,
    withLifecycle,
    pure,
)(ClientUserPure);

ClientUser.propTypes = {
    buyer: PropTypes.bool,
    contract: PropTypes.object,
    dmd: PropTypes.object,
    userType: PropTypes.string,
    loadContactInfo: PropTypes.func,
    selectBuyer: PropTypes.func,
};

export {
    ClientUser as default,
    USER_TYPES,
};