import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Glyphicon, Panel} from 'react-bootstrap';
import ReactTable from 'react-table';
import {compose, pure, withProps, withState} from 'recompose';

const columns = [
    {
        Header: 'SN',
        accessor: 'deviceId',
        Cell: ({value}) => <div>{value?.replace('dmd::', '') || null}</div>,
    }, {
        Header: 'Attachée le',
        accessor: 'attached',
        Cell: ({value}) => <div>{value ? moment(value).format('L') : null}</div>,
    }, {
        Header: 'Détachée le',
        accessor: 'detached',
        Cell: ({value}) => <div>{value ? moment(value).format('L') : null}</div>,
    },
];

const getTrProps = (rowInfo, currentDmdStateIndex) => {
    if (rowInfo?.index === currentDmdStateIndex) {
        return {
            style: {
                background: 'rgba(217, 237, 255, 0.5)',
            },
        };
    }
    return {};
};

const DevicesHistoryPure = ({currentDmdStateIndex, showHistory, states, tableHeight, setShowHistory}) => (
    <>
        <h4 onClick={() => setShowHistory(!showHistory)} className='cursor-pointer'>
            <span>Historique des tablettes</span>
            <Glyphicon className='pull-right' glyph={showHistory ? 'triangle-top' : 'triangle-bottom'}/>
        </h4>
        <Panel expanded={showHistory}
               onToggle={() => {}}>
            <Panel.Collapse>
                <Panel.Body>
                    <ReactTable
                        fill
                        data={states}
                        columns={columns}
                        resizable={false}
                        pageSize={states.length || 1}
                        showPagination={false}
                        style={{
                            border: 'none',
                            height: tableHeight,
                        }}
                        className='-highlight'
                        getTrProps={(state, rowInfo) => getTrProps(rowInfo, currentDmdStateIndex)}
                        getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
                    />
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    </>
);

DevicesHistoryPure.propTypes = {
    showHistory: PropTypes.bool,
    states: PropTypes.array,
    tableHeight: PropTypes.string,
    setShowHistory: PropTypes.func,
};

const withShowHistoryState = withState('showHistory', 'setShowHistory', false);

const DevicesHistory = compose(
    withProps(({dmd}) => ({
        states: dmd?.states || [],
        tableHeight: `${((dmd?.states?.length || 1) * 50 + 100)}px`,
    })),
    withShowHistoryState,
    pure,
)(DevicesHistoryPure);

DevicesHistory.propTypes = {
    currentDmdStateIndex: PropTypes.number,
    dmd: PropTypes.object,
};

export default DevicesHistory;