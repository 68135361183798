import React, { useMemo, useState } from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import {isNotEmpty} from '../../../utils/Array';
import {getResultType, checkIfIsOtherResult} from '../../../utils/functions';
import { connectTypesContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { canSubmitResult, getSortedResultFirstIntentionOptions, getSortedResultOptionsWithoutFirstIntentions, parseResult } from '../../../utils/types';

const CloseTicketModal = connectTypesContext(({ types, title = 'Clôturer le ticket', subjectResultOptions = [], ticket, show = true, onHide, onSubmit }) => {
	
	const [result, setResult] = useState(ticket.result ? parseResult(ticket.result) : '');
	const [firstIntention, setFirstIntention] = useState(ticket.result || ticket.firstIntention || '');
	const [resultComment, setResultComment] = useState(ticket.resultComment || '');
	
	const handleResultChange = event => {
		const newResult = event.target.value;
		setResult(newResult);
		setFirstIntention('');

		const isNotOtherResult = !checkIfIsOtherResult(newResult);
		if (isNotOtherResult) setResultComment('');
	}

	const handleCommentChange = event => setResultComment(event.target.value);

	const handleFirstIntentionChange = event => setFirstIntention(event.target.value);

	const handleSubmit = () => onSubmit({ result, firstIntention, resultComment });

	const resultOptions = useMemo(() => getSortedResultOptionsWithoutFirstIntentions(subjectResultOptions, types), [subjectResultOptions, types]);

	const firstIntentionOptions = useMemo(() => getSortedResultFirstIntentionOptions(subjectResultOptions, types, result), [subjectResultOptions, types, result]);

	const isOtherResult = checkIfIsOtherResult(result);

	const submitEnabled = canSubmitResult({firstIntention, firstIntentionOptions});

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormGroup>
					<ControlLabel>Résultat</ControlLabel>
					<FormControl componentClass='select' value={result} onChange={handleResultChange} placeholder='Résultat'>
						<option value=''>---</option>
						{resultOptions.map(result => (
							<option key={result} value={result}>
								{getResultType(types, result)}
							</option>
						))}
					</FormControl>
				</FormGroup>
				{isNotEmpty(firstIntentionOptions) && <FormGroup>
					<ControlLabel>Première intention</ControlLabel>
					<FormControl
						componentClass='select'
						placeholder='Première intention'
						value={firstIntention}
						onChange={handleFirstIntentionChange}
					>
						<option value=''>---</option>
						{firstIntentionOptions.map(firstIntention => (
							<option key={firstIntention} value={firstIntention}>
								{getResultType(types, firstIntention)}
							</option>
						))}
					</FormControl>
				</FormGroup>}
				{isOtherResult && (
					<FormGroup>
						<ControlLabel>Commentaire</ControlLabel>
						<FormControl value={resultComment} rows={4} componentClass='textarea' onChange={handleCommentChange} />
					</FormGroup>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='primary' onClick={handleSubmit} disabled={result === '' || !submitEnabled}>
					Clôturer
				</Button>
			</Modal.Footer>
		</Modal>
	);
});

CloseTicketModal.propTypes = {
	subjectResultOptions: PropTypes.array,
	ticket: PropTypes.object,
	types: PropTypes.object,
	onHide: PropTypes.func,
};

export default CloseTicketModal;