import PropTypes from 'prop-types';
import {getContext, withContext} from 'recompose';

const withDmdContext = withContext({
    dmd: PropTypes.object,
    setDmd: PropTypes.func,
  },
  ({dmd, setDmd}) => ({dmd, setDmd}),
);

const connectDmdContext = Component => getContext(
  {
    dmd: PropTypes.object,
    setDmd: PropTypes.func,
  },
)(Component);

export {withDmdContext, connectDmdContext};