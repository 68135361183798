import {isNotEmpty, sortAlpha} from './Array';
import { AUTOMATED_TICKET_SUBJECTS } from './constants';
import { getSubjectTypeLabel, getResultType, getStateType } from './functions';

export const parseResult = (result = '') => {
	const [subjectIndex, resultIndex] = result.split('.');
	return [subjectIndex, resultIndex].join('.');
};

export const parseFirstIntention = (result = '') => {
	return result.split('.').length > 2 ? result : '';
};

export const canSubmitResult = ({firstIntention, firstIntentionOptions}) => {
	const hasFirstIntentions = isNotEmpty(firstIntentionOptions);
	
	const firstIntentionSelected = firstIntention && firstIntention !== '';
	
	const submitTicketResultEnabled = !hasFirstIntentions || firstIntentionSelected;

	return submitTicketResultEnabled;
}

export const isSubjectTypeHidden = (types, subjectValue) => {
  return types.type_demande[subjectValue]?.hidden
}

export const isResultTypeHidden = (types, resultValue) => {
  return types.type_cloture[resultValue]?.hidden;
}

export const getSortedStatesOptions = (stateTypes, types) => {
	return sortAlpha(stateTypes, state => getStateType(types, state));
}

export const getSortedSubjectOptions = (subjectTypes, types) => {
  return sortAlpha(subjectTypes, subject => getSubjectTypeLabel(types, subject));
}

export const getVisibleSubjectOptions = (subjectTypes, types) => {
  const sortedSubjects = getSortedSubjectOptions(subjectTypes, types);
	
  const visibleSubjects = sortedSubjects.filter(subjectType => !isSubjectTypeHidden(types, subjectType));

  return visibleSubjects;
}

export const getUnlockedSubjectOptions = (subjectTypes, types) => {
  const visibleSubjects = getVisibleSubjectOptions(subjectTypes, types);

  const unlockedSubjects = visibleSubjects.filter(subjectType => !AUTOMATED_TICKET_SUBJECTS.includes(subjectType));

  return unlockedSubjects;
}

export const getSubjectResultOptions = (resultOptions, subject) => {
	return resultOptions.filter(type_cloture => type_cloture.startsWith(`${subject}.`));
}

export const getSortedResultOptionsWithoutFirstIntentions = (subjectResultOptions, types) => {
	// Remove first intentions by removing results with x.y.z indexes
	const resultOptions = subjectResultOptions.filter(subjectResult => !/(\d+\.){2}\d+/.test(subjectResult));

	const sortedResultOptions = sortAlpha(resultOptions, result => getResultType(types, result));

	const visibleResultOptions = sortedResultOptions.filter(result => !isResultTypeHidden(types, result));

	return visibleResultOptions;
}

export const getSortedResultFirstIntentionOptions = (subjectResultOptions, types, result) => {
	
	const firstIntentionOptions = subjectResultOptions.filter(subjectResult => subjectResult.startsWith(`${result}.`));
	
	const sortedFirstIntentionOptions = sortAlpha(firstIntentionOptions, firstIntention => getResultType(types, firstIntention));
	
	const visibleFirstIntentionOptions = sortedFirstIntentionOptions.filter(firstIntention => !isResultTypeHidden(types, firstIntention));
	
	return visibleFirstIntentionOptions;
}