import { isNotEmpty } from './Array';
import { tsToFullDate } from './Date';

export const extractPackagesInfoFromMaintenance = (maintenance) => {
  const packages = Object.entries(maintenance?.lastUpdateCall?.checkUpdate?.packagesWithVersion || {}).reduce((packages = {}, [packageName, packageVersion]) => {
    packages[packageName] = {
      name: packageName,
      version: packageVersion
    };
  
    const appVersionHistory = maintenance.lastUpdateCall.appVersionHistorical.find(appVersionHistory => appVersionHistory?.packageName === packageName)?.history;
    if (isNotEmpty(appVersionHistory)) {
      const [lastUpdateLog, ...history] = appVersionHistory;
      packages[packageName].history = history.map(({since, versionName}) => ({updateDate: tsToFullDate(since), version: versionName}));
      
      const packageLastUpdateDate = lastUpdateLog?.since;
      if (packageLastUpdateDate) packages[packageName].lastUpdateDate = tsToFullDate(packageLastUpdateDate);
    }
    return packages;
  }, {});

  return packages;
}