import jQuery from 'jquery';

jQuery.ajaxSetup({ cache: false });

/* eslint-disable */
var jsRoutes = {};
(function(_root){
    var _nS = function(c,f,b){var e=c.split(f||"."),g=b||_root,d,a;for(d=0,a=e.length;d<a;d++){g=g[e[d]]=g[e[d]]||{}}return g}
    var _qS = function(items){var qs = ''; for(var i=0;i<items.length;i++) {if(items[i]) qs += (qs ? '&' : '') + items[i]}; return qs ? ('?' + qs) : ''}
    var _s = function(p,s){return p+((s===true||(s&&s.secure))?'s':'')+'://'}
    var _wA = function(r){return {ajax:function(c){c=c||{};c.url=r.url;c.type=r.method;return jQuery.ajax(c)}, method:r.method,type:r.method,url:r.url,absoluteURL: function(s){return _s('http',s)+'canari.tikeasy.com'+r.url},webSocketURL: function(s){return _s('ws',s)+'canari.tikeasy.com'+r.url}}}
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['getMaintenances'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['setMaintenance'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['sendGCMMaintenanceOrder'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/gcm"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['sendGCMTeamViewerOrder'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/teamviewer"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['setAccountResetAuthorized'] =
        function(contractNumber0,value1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/resetAuthorized/" + encodeURIComponent((function(k,v) {return v})("value", value1))})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['deleteMaintenance'] =
        function(contractNumber0,id1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/delete/" + encodeURIComponent((function(k,v) {return v})("id", id1))})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['deleteAllMaintenances'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/delete/all"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['getMaintenanceFile'] =
        function(contractNumber0,id1) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/" + encodeURIComponent((function(k,v) {return v})("id", id1)) + "/file"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['getMaintenanceStacktrace'] =
        function(contractNumber0,id1) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/" + encodeURIComponent((function(k,v) {return v})("id", id1)) + "/stack"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['getSynchronizationTestStatus'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/synchronization/status"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['startSynchronizationTest'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/synchronization/start"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['cleanOrphanBlobs'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/cleanOrphanBlobs"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['setSGPassword'] =
        function(contractNumber0,sgPassword1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/sgPassword/" + encodeURIComponent((function(k,v) {return v})("sgPassword", sgPassword1))})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['removeChannel'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/removeChannel"})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['switchChannelAndDevice'] =
        function(contractNumber0,targetContractNumber1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/maintenance/switch/" + encodeURIComponent((function(k,v) {return v})("targetContractNumber", targetContractNumber1))})
        }
    ;
    _nS('controllers.backoffice.MaintenanceV2Controller'); _root['controllers']['backoffice']['MaintenanceV2Controller']['sendGCMUpdateOrder'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/update/gcm"})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['searchDevice'] =
        function(searchProperty0,searchValue1) {
            return _wA({method:"GET", url:"/" + "supportV2/device/" + encodeURIComponent((function(k,v) {return v})("searchProperty", searchProperty0)) + "/" + encodeURIComponent((function(k,v) {return v})("searchValue", searchValue1))})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['createContract'] =
        function(dmdId0,tootiContract1,flavor2,customerTypologyType3) {
            return _wA({method:"POST", url:"/" + "supportV2/device/create/" + encodeURIComponent((function(k,v) {return v})("dmdId", dmdId0)) + "/" + encodeURIComponent((function(k,v){return !!v})("tootiContract", tootiContract1)) + "/" + encodeURIComponent((function(k,v) {return v})("flavor", flavor2)) + "/" + encodeURIComponent((function(k,v) {return v})("customerTypologyType", customerTypologyType3))})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['detachContract'] =
        function(dmdId0) {
            return _wA({method:"POST", url:"/" + "supportV2/device/detach/" + encodeURIComponent((function(k,v) {return v})("dmdId", dmdId0))})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['assignContract'] =
        function(dmdId0,contractNumber1,replaceSIM2) {
            return _wA({method:"POST", url:"/" + "supportV2/device/assign/" + encodeURIComponent((function(k,v) {return v})("dmdId", dmdId0)) + "/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber1)) + "/" + encodeURIComponent((function(k,v){return !!v})("replaceSIM", replaceSIM2))})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['importOldDevice'] =
        function() {
            return _wA({method:"POST", url:"/" + "supportV2/device/import/old"})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['changeSIM'] =
        function(dmdId0,ICCID1) {

            if (ICCID1 == "") {
                return _wA({method:"POST", url:"/" + "supportV2/device/changeSIM/" + encodeURIComponent((function(k,v) {return v})("dmdId", dmdId0))})
            }

            if (true) {
                return _wA({method:"POST", url:"/" + "supportV2/device/changeSIM/" + encodeURIComponent((function(k,v) {return v})("dmdId", dmdId0)) + "/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID1))})
            }

        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['changeDMD'] =
        function(dmdId0) {
            return _wA({method:"POST", url:"/" + "supportV2/device/changeDMD/" + encodeURIComponent((function(k,v) {return v})("dmdId", dmdId0))})
        }
    ;
    _nS('controllers.backoffice.DeviceV2Controller'); _root['controllers']['backoffice']['DeviceV2Controller']['changeCanBeTransferred'] =
        function(contractNumber0,enable1) {
            return _wA({method:"GET", url:"/" + "supportV2/device/changeCanBeTransferred/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/" + encodeURIComponent((function(k,v){return !!v})("enable", enable1))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getMeAndTeamsAndConstants'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/meAndTeamsAndConstants"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getContract'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['search'] =
        function(mode0,searchValue1) {
            return _wA({method:"POST", url:"/" + "supportV2/search/" + encodeURIComponent((function(k,v) {return v})("mode", mode0)) + "/" + encodeURIComponent((function(k,v) {return v})("searchValue", searchValue1))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getMainUser'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/mainUser"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setMainUser'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/mainUser"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getContractTags'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/tags"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setContractTags'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/tags"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getBuyer'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/buyer"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setBuyer'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/buyer"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getSubscriber'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/subscriber"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setSubscriber'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/subscriber"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['createCustomer'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/customer"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getSubscription'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/subscription"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setSubscription'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/subscription"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getTickets'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/ticket"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getAttachment'] =
        function(path0) {
            return _wA({method:"GET", url:"/" + "supportV2/attachment/" + (function(k,v) {return v})("path", path0)})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getTicketsByTeam'] =
        function(teamValue0) {
            return _wA({method:"GET", url:"/" + "supportV2/ticket/team/" + encodeURIComponent((function(k,v) {return v})("teamValue", teamValue0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getContractTickets'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/ticket"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getContractOrders'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/order"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['calculateFSPlusAddress'] =
        function(contractNumber0,occurrence1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/order/" + encodeURIComponent((function(k,v) {return v})("occurrence", occurrence1))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setOrderContractNumber'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/order/migrate"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getTicketTypes'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/ticket/type"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getTicketTeams'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/ticket/team"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setTicket'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/ticket"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['deleteTicket'] =
        function(contractNumber0,ticketId1) {
            return _wA({method:"DELETE", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/ticket/" + encodeURIComponent((function(k,v) {return v})("ticketId", ticketId1))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getSubscriptionTypes'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/subscription/type"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setOrder'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/order"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getSims'] =
        function() {
            return _wA({method:"POST", url:"/" + "supportV2/sim"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getHardwareMetadata'] =
        function(contractNumber0) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/dmd"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getDMDHistory'] =
        function(SN0) {
            return _wA({method:"GET", url:"/" + "supportV2/device/getDMDHistory/" + encodeURIComponent((function(k,v) {return v})("SN", SN0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setDMD'] =
        function(contractNumber0) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/dmd"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setFlavorToAmd'] =
        function(contractNumber0,flavor1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/" + encodeURIComponent((function(k,v) {return v})("flavor", flavor1)) + "/setFlavorToAmd"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getLastLPMFile'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/sim/lastLPMFile"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setSim'] =
        function(ICCID0) {
            return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['createSim'] =
        function(ICCID0) {
            return _wA({method:"PUT", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setAnswer'] =
        function(contractNumber0,ticketId1) {
            return _wA({method:"POST", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/ticket/" + encodeURIComponent((function(k,v) {return v})("ticketId", ticketId1)) + "/answer"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getFSPlusAddress'] =
        function(zipCode0) {
            return _wA({method:"GET", url:"/" + "supportV2/fsplus/address/" + encodeURIComponent((function(k,v) {return v})("zipCode", zipCode0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['setFSPlusAddress'] =
        function(zipCode0) {
            return _wA({method:"PUT", url:"/" + "supportV2/fsplus/address/" + encodeURIComponent((function(k,v) {return v})("zipCode", zipCode0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getStatusTracking'] =
        function(carrier0,trackingNumber1) {
            return _wA({method:"GET", url:"/" + "supportV2/tracking/" + encodeURIComponent((function(k,v) {return v})("carrier", carrier0)) + "/" + encodeURIComponent((function(k,v) {return v})("trackingNumber", trackingNumber1))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getMailBoxInfo'] =
        function(localPart0) {
            return _wA({method:"GET", url:"/" + "supportV2/nuxit/mailinfo/" + encodeURIComponent((function(k,v) {return v})("localPart", localPart0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['deleteMailBoxInfo'] =
        function(localPart0) {
            return _wA({method:"GET", url:"/" + "supportV2/nuxit/maildelete/" + encodeURIComponent((function(k,v) {return v})("localPart", localPart0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['reOpenTicket'] =
        function(contractNumber0,ticketNumber1) {
            return _wA({method:"GET", url:"/" + "supportV2/contract/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/ticket/" + encodeURIComponent((function(k,v) {return v})("ticketNumber", ticketNumber1)) + "/reOpenTicket"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['transferTicketToOtherContract'] =
        function() {
            return _wA({method:"POST", url:"/" + "supportV2/ticket/transferTicketToOtherContract"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getTags'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/tags/all"})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['getDMDHistory'] =
        function(SN0) {
            return _wA({method:"GET", url:"/" + "supportV2/device/getDMDHistory/" + encodeURIComponent((function(k,v) {return v})("SN", SN0))})
        }
    ;
    _nS('controllers.backoffice.SupportV2Controller'); _root['controllers']['backoffice']['SupportV2Controller']['generateTicketsFromIdsContract'] =
        function() {

            if (true) {
                return _wA({method:"POST", url:"/" + "supportV2/generateTicketsFromIdsContract"})
            }

        }
    ;
    _nS('controllers.backoffice.BreakageInsuranceController'); _root['controllers']['backoffice']['BreakageInsuranceController']['updateBreakageInsuranceStatus'] =
        function(contractNumber0,status1) {
            return _wA({method:"POST", url:"/" + "supportV2/breakageInsurance/" + encodeURIComponent((function(k,v) {return v})("contractNumber", contractNumber0)) + "/" + encodeURIComponent((function(k,v) {return v})("status", status1))})
        }
    ;
    _nS('controllers.backoffice.UserV2Controller'); _root['controllers']['backoffice']['UserV2Controller']['getUsers'] =
        function() {
            return _wA({method:"GET", url:"/" + "supportV2/admin/users"})
        }
    ;
    _nS('controllers.backoffice.UserV2Controller'); _root['controllers']['backoffice']['UserV2Controller']['getUser'] =
        function(id0) {
            return _wA({method:"GET", url:"/" + "supportV2/admin/users/" + encodeURIComponent((function(k,v) {return v})("id", id0))})
        }
    ;
    _nS('controllers.backoffice.UserV2Controller'); _root['controllers']['backoffice']['UserV2Controller']['deleteUser'] =
        function(id0) {
            return _wA({method:"DELETE", url:"/" + "supportV2/admin/users/" + encodeURIComponent((function(k,v) {return v})("id", id0))})
        }
    ;
    _nS('controllers.backoffice.UserV2Controller'); _root['controllers']['backoffice']['UserV2Controller']['addUser'] =
        function() {
            return _wA({method:"PUT", url:"/" + "supportV2/admin/users/create"})
        }
    ;
    _nS('controllers.backoffice.UserV2Controller'); _root['controllers']['backoffice']['UserV2Controller']['updateUser'] =
        function(id0) {
            return _wA({method:"POST", url:"/" + "supportV2/admin/users/" + encodeURIComponent((function(k,v) {return v})("id", id0))})
        }
    ;
    _nS('controllers.backoffice.UserV2Controller'); _root['controllers']['backoffice']['UserV2Controller']['resetPassword'] =
        function() {
            return _wA({method:"POST", url:"/" + "supportV2/admin/users/resetPassword"})
        }
    ;
    _nS('controllers.Authentication'); _root['controllers']['Authentication']['supportV2Login'] =
        function() {
            return _wA({method:"POST", url:"/" + "supportV2/login"})
        }
    ;
    _nS('controllers.Authentication'); _root['controllers']['Authentication']['supportV2Logout'] =
        function() {
            return _wA({method:"POST", url:"/" + "supportV2/logout"})
        }
    ;
    _nS('controllers.backoffice.Proxy'); _root['controllers']['backoffice']['Proxy']['index'] =
        function(url0) {

            if (true) {
                return _wA({method:"GET", url:"/" + "proxy/" + encodeURIComponent((function(k,v) {return v})("url", url0))})
            }

        }
    ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['reset'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/reset"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['activate'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/activate"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['cancelActivation'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/cancelActivation"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['confirmActivation'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/confirmActivation"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['forceActivation'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/forceActivation"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['changePlan'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/changePlan"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['cancelChangePlan'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/cancelChangePlan"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['terminate'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/terminate"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['cancelTermination'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/cancelTermination"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['confirmTermination'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/confirmTermination"})
    }
  ;
  _nS('controllers.backoffice.SupportV2SimController'); _root['controllers']['backoffice']['SupportV2SimController']['forceTerminate'] =
    function(ICCID0) {
      return _wA({method:"POST", url:"/" + "supportV2/sim/" + encodeURIComponent((function(k,v) {return v})("ICCID", ICCID0)) + "/forceTerminate"})
    }
  ;
})(jsRoutes);
/* eslint-enable */

export default jsRoutes;