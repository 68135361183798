import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, Modal} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withContext, withHandlers, withProps, withState} from 'recompose';
import {connectContractNumberContext} from '../../../../../hoc/contractNumber';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {GET_CURRENT_SUBSCRIBER} from '../../../../../schema/CustomerSchema';
import {isValidationUser} from '../../../../../utils/functions';
import {getDateBeginMinDate, isPrepayedOrDemo} from '../../../../../utils/Subscription';
import SubscriptionStateAndSubscriberForm from './SubscriptionState/SubscriptionStateAndSubscriberForm';
import SubscriptionStateForm from './SubscriptionState/SubscriptionStateForm';
import handleSubmitSubscriptionStateHandler from './SubscriptionStateEdit/handleSubmitSubscriptionState';

const SubscriptionStateEditPure = ({
                                     currentSubscriber,
                                     FormComponent,
                                     mode,
                                     subscription,
                                     subscriptionState,
                                     handleFormDataChange,
                                     handleSelectCurrentSubscriptionType,
                                     handleSubmitSubscriptionState,
                                     onHideClick,
                                     setMode,
                                   }) => (
  <Modal backdrop="static"
         bsSize="lg"
         show
         onHide={onHideClick}>
    <Modal.Header closeButton>
      <Modal.Title>Édition de l'abonnement</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormComponent currentSubscriber={currentSubscriber}
                     isACreation={isCreationMode(mode)}
                     mode={mode}
                     subscription={subscription}
                     handleSelectCurrentSubscriptionType={handleSelectCurrentSubscriptionType}
                     handleSubmitSubscriptionState={handleSubmitSubscriptionState}
                     setMode={setMode}
                     setSubscriptionState={handleFormDataChange}/>
    </Modal.Body>

    {mode !== FORM_MODES.SUBSCRIBER_CREATE && !!subscriptionState.type &&
    <Modal.Footer>
      <Button bsStyle="info"
              onClick={handleSubmitSubscriptionState}>{'Ok (pensez à valider ensuite)'}</Button>
    </Modal.Footer>
    }

  </Modal>
);

SubscriptionStateEditPure.propTypes = {
  currentSubscriber: PropTypes.object,
  mode: PropTypes.number,
  subscription: PropTypes.object,
  subscriptionState: PropTypes.object,
  handleFormDataChange: PropTypes.func,
  handleSelectCurrentSubscriptionType: PropTypes.func,
  handleSubmitSubscriptionState: PropTypes.func,
  onHideClick: PropTypes.func,
  setMode: PropTypes.func,
};

const FORM_MODES = {CREATE: 1, SUBSCRIBER_CREATE: 2, EDIT: 3};
const isCreationMode = mode => mode === FORM_MODES.CREATE || mode === FORM_MODES.SUBSCRIBER_CREATE;

const withSubscriberState = withState('subscriber', 'setSubscriber', {});

const withSubscriptionState = withState('subscriptionState', 'setSubscriptionState', ({
                                                                                        currentEditedSubscriptionState,
                                                                                        subscription,
                                                                                        webUserJson,
                                                                                      }) => {
  if (currentEditedSubscriptionState === -1) {
    const dateBegin = getDateBeginMinDate(subscription?.metadata?.subscriptionStates?.[0]);

    return {
      author: webUserJson.email,
      dateBegin,
      dateCrea: moment().valueOf(),
      notSaved: true,
      type: subscription?.metadata?.subscriptionStates?.[0]?.type,
      updateNeedToBeConfirmedByGESAB: isValidationUser(webUserJson),
    };
  }

  return {...subscription?.metadata?.subscriptionStates[currentEditedSubscriptionState]};
});
const withModeState = withState('mode', 'setMode', ({currentEditedSubscriptionState, subscriptionState}) => {
  if (currentEditedSubscriptionState === -1) {
    return FORM_MODES.CREATE;
  }

  if (subscriptionState?.notSaved) {
    if (subscriptionState?.current) {
      return FORM_MODES.SUBSCRIBER_CREATE;
    }

    return FORM_MODES.CREATE;
  }

  return FORM_MODES.EDIT;
});

const handlers = withHandlers({
  handleFormDataChange: ({mode, subscriptionState, setSubscriptionState}) => formData => {
    if (!isPrepayedOrDemo(formData.type) && (isCreationMode(mode) || subscriptionState.notSaved)) {
      formData.dateEnd = undefined;
    }
    setSubscriptionState(formData);
  },
  handleSelectCurrentSubscriptionType: ({subscription, subscriptionState, setSubscriptionState}) => () =>
    setSubscriptionState({
      ...subscriptionState,
      type: subscription.type,
    }),
  handleSubmitSubscriptionState: handleSubmitSubscriptionStateHandler,
});

const withCurrentCustomerData = graphql(GET_CURRENT_SUBSCRIBER, {
  options: ({contractNumber}) => ({
    variables: {
      input: {
        contractNumber: contractNumber,
      },
    },
  }),
});

const SubscriptionStateEdit = compose(
  connectContractNumberContext,
  connectMeAndTeamsAndConstantsContext,
  branch(
    ({currentEditedSubscriptionState}) => !Number.isInteger(currentEditedSubscriptionState),
    renderNothing,
  ),
  withSubscriberState,
  withSubscriptionState,
  withModeState,
  withCurrentCustomerData,
  withProps(
    ({data, mode}) => ({
      currentSubscriber: data?.currentSubscriber?.subscriber,
      FormComponent: mode === FORM_MODES.SUBSCRIBER_CREATE ? SubscriptionStateAndSubscriberForm : SubscriptionStateForm,
    }),
  ),
  withContext({
      currentEditedSubscriptionState: PropTypes.number,
      currentSubscriptionType: PropTypes.string,
      subscription: PropTypes.object,
      subscriptionState: PropTypes.object,
    },
    ({
       currentEditedSubscriptionState,
       subscription,
       subscriptionState,
     }) => ({
      currentEditedSubscriptionState,
      currentSubscriptionType: subscription?.metadata?.subscriptionStates?.[0]?.type,
      subscription,
      subscriptionState,
    }),
  ),
  handlers,
  pure,
)(SubscriptionStateEditPure);

SubscriptionStateEdit.propTypes = {
  currentEditedSubscriptionState: PropTypes.number,
  subscription: PropTypes.object,
  onSubmitClick: PropTypes.func,
  onHideClick: PropTypes.func,
};

export {
  SubscriptionStateEdit as default,
  FORM_MODES,
};