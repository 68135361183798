import { fetchContract } from '../../../../utils/requests';

const getContract = ({contractNumber, setContract, setLoading}) => {
    fetchContract(contractNumber).then(
        contract => setContract(
            contract,
            () => setLoading(false)
        ),
        () => setLoading(false)
    );
}

export default getContract;