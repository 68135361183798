import React, { useMemo } from 'react';
import SimpleSelect from './SimpleSelect';
import { connectTypesContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { getSortedStatesOptions } from '../../../utils/types';
import { getStateType } from '../../../utils/functions';

const StateSelect = connectTypesContext(({types, states = Object.keys(types.type_etat), value = '', onChange, ...props}) => {
  const stateOptions = useMemo(() => {
    return getSortedStatesOptions(states, types).map(stateValue => ({
      value: stateValue,
      label: getStateType(types, stateValue)
    }));
  }, [states, types]);
  return (
    <SimpleSelect
      value={value}
      onChange={onChange}
      options={stateOptions}
      {...props}
    />
  );
});

export default StateSelect;