import React, {useCallback, useState} from 'react';
import { notify } from 'react-notify-toast';
import jsRoutes from '../../routes/routes';
import {clone, isFunction} from '../../utils/functions';
import {AssignContractModal, ContractHistoryModal, CreateContractModal, DetachContractModal, DeviceEditModal, ImportOldDeviceModal} from './modals';
import DevicesSearchInputs from './DevicesSearchInputs';
import DevicesSearchResults from './DevicesSearchResults';
import LoadingProgress from '../components/LoadingProgress';
import { createDateSorter } from '../../utils/Array';
import { Grid, Row } from 'react-bootstrap';

const ENTER_KEY_CODE = 13;

const formatDevice = device => ({
	'_id': device.id,
	'simState': device.simState,
	'contractNumber': device.contractNumber,
	'canBeTransferred': device.canBeTransferred,
	'SN': device.SN,
	'IMEI': device.IMEI1,
	'MAC': device.MAC,
	'ICCID': device.ICCID,
	'Version cible': device.targetCoreVersion,
	'Version actuelle': device.currentCoreVersion,
	'Dernière vérification MAJ': device.lastUpdateCheck,
	'Contract': device.contractNumber,
});

const DEVICE_PROPERTIES = [
	{
		name: 'SN',
		key: 'SN',
		size: '15%',
	},
	{
		name: 'IMEI',
		key: 'IMEI',
		searchable: true,
		size: '15%',
	},
	{
		name: 'MAC',
		key: 'MAC',
		searchable: true,
		size: '10%',
	},
	{
		name: 'ICCID',
		key: 'ICCID',
		searchable: true,
		type: 'sim',
		size: '20%',
	},
	{
		name: 'Version cible',
		key: 'targetCoreVersion',
		searchable: true,
		feminine: true,
		size: '8%',
	},
	{
		name: 'Version actuelle',
		key: 'currentCoreVersion',
		searchable: true,
		feminine: true,
		size: '8%',
	},
	{
		name: 'Dernière vérification MAJ',
		key: 'lastUpdateCheck',
		type: 'date',
		size: '15%',
	},
	{
		name: 'Contract',
		key: 'contractNumber',
		type: 'contract',
		size: '9%',
	},
];

const DevicesSearchView = () => {
	const [loading, toggleLoading] = useState(false);
	
	const [detachContractDevice, setDetachContractDevice] = useState(null);
	
	const [assignContractDevice, setAssignContractDevice] = useState(null);
	
	const [createContractDevice, setCreateContractDevice] = useState(null);
	
	const [editDMD, setEditDMD] = useState(null);

	const [showImportOldDevice, toggleImportOldDevice] = useState(false);
	const [importOldDeviceTag, setImportOldDeviceTag] = useState('');

	const [showContractHistory, toggleContractHistory] = useState(false);
	const [contractHistory, setContractHistory] = useState([]);

	const [searchInputs, setSearchInputs] = useState({});
	const [{property: searchProperty, value: searchValue}, setSearch] = useState({property: null, value: null});
	
	const [entries, setEntries] = useState([]);
	const [devices, setDevices] = useState([]);
	const [tags, setTags] = useState([]);
	
	const { SN } = searchInputs;

	const handleSearch = useCallback((property, value) => {
		toggleLoading(true);
		setSearch({property, value});

		jsRoutes.controllers.backoffice.DeviceV2Controller.searchDevice(property, value)
		.ajax({
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			processData: false,
		})
		.then((response, textStatus, jqXHR) => {
			const entries = response.devices.map(formatDevice);
			const devices = response.devices;
			setEntries(entries);
			setDevices(devices);
			setTags(response.tags);
			toggleLoading(false);
		}, (jqXHR, textStatus, errorThrown) => {
			if (value) notify.show('Échec de la recherche !', 'error', -1);
			toggleLoading(false);
		});
	}, []);

	const createInputChangeHandler = property => event => {
		const value = event.target.value;
		setSearchInputs(prevSearches => ({...prevSearches, [property]: value}));
	}

	const createKeyPressHandler = useCallback(property => target => {
		if (target.keyCode === ENTER_KEY_CODE && (property !== 'SN' || target.ctrlKey)) {
			handleSearch(property, searchInputs[property], null, null);
		}
	}, [searchInputs, handleSearch]);

	const getDeviceById = useCallback(ID => clone(devices.filter(device => device.id === ID)[0]), [devices]);

	const sendAndUpdate = useCallback((ajaxRequest, options = {}) => {
		const { successCallback, errorMessage } = options;
		toggleLoading(true);

		ajaxRequest.then((response, textStatus, jqXHR) => {
			
			handleSearch(searchProperty, searchValue);
			
			if (isFunction(successCallback)) successCallback();

		}, (jqXHR, textStatus, errorThrown) => {

			if (errorMessage) notify.show(errorMessage, 'error', -1);
		
			toggleLoading(false);
		});
	}, [searchProperty, searchValue, handleSearch]);
	
	const createContractHistoryClickHandler = useCallback(ID => () => {
		toggleLoading(true);

		jsRoutes.controllers.backoffice.SupportV2Controller.getDMDHistory(ID)
		.ajax({
			dataType: 'json',
			contentType: 'application/json; charset=utf-8',
			processData: false
		})
		.then((response, textStatus, jqXHR) => {
			const statesForOneDevice = [];
			response.forEach(device => {
				if (device.states !== undefined && device.states) {
					device.states.forEach((state, index) => {
						statesForOneDevice.push({
							contractNumber : index === 0 ? device.contractNumber : '',
							attached : state.attached,
							detached : state.detached,
						});
					});
				}
			});
			statesForOneDevice.sort(createDateSorter({attribute: 'attached', parseFormat: 'DD/MM/YYYY'}));
			setContractHistory(statesForOneDevice);
			toggleContractHistory(true);
			toggleLoading(false);
		}, (jqXHR, textStatus, errorThrown) => {
			notify.show('Impossible de récupérer l\'historique !', 'error', -1);
			toggleLoading(false);
		});
	}, []);

	const handleContractHistoryModalClose = () => {
		toggleContractHistory(false);
		setContractHistory([]);
	}

	const createDetachContractClickHandler = useCallback(ID => () => {
		const device = getDeviceById(ID);
		setDetachContractDevice(device);
	}, [getDeviceById]);

	const handleDetachContractModalClose = () => setDetachContractDevice(null);

	const handleDetachContractSubmit = () => {
		sendAndUpdate(
			jsRoutes.controllers.backoffice.DeviceV2Controller.detachContract(detachContractDevice.id)
			.ajax({
				dataType: 'json',
				processData: false,
			}), {
				successCallback: handleDetachContractModalClose,
				errorMessage: 'Impossible de détacher le contrat !'
			}
		);
	}

	const createCreateContractClickHandler = useCallback(ID => () => {
		let device = getDeviceById(ID);
		setCreateContractDevice(device);
	}, [getDeviceById]);

	const handleCreateContractModalClose = () => setCreateContractDevice(null);
	
	const handleCreateContractSubmit = ({ contract }) => () => {
		const device = createContractDevice;
		if (!device) return;

		const {customerTypology, flavor, isTooti, tag} = contract;

		const tags = [];
		if (tag) tags.push(tag);

		let contractFlavor = 'default';
		if (flavor) contractFlavor = flavor;

		const json = {
			tags
		};

		sendAndUpdate(
			jsRoutes.controllers.backoffice.DeviceV2Controller.createContract(device.id, isTooti, contractFlavor, customerTypology)
			.ajax({
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				processData: false,
				data: JSON.stringify(json)
			}), {
				successCallback: handleCreateContractModalClose,
				errorMessage: 'Impossible de créer le contrat !'
			}
		);
	}

	const createAssignContractClickHandler = useCallback(ID => () => {
		const device = getDeviceById(ID);
		setAssignContractDevice(device);
	}, [getDeviceById]);

	const handleAssignContractModalClose = () => setAssignContractDevice(null);

	const handleAssignContractSubmit = ({ assignContractNumber, assignContractReplaceSIM }) => () => {
		if (!assignContractDevice) return;

		sendAndUpdate(
			jsRoutes.controllers.backoffice.DeviceV2Controller.assignContract(assignContractDevice.id, assignContractNumber, assignContractReplaceSIM)
			.ajax({
				dataType: 'json',
				processData: false,
			}), {
				successCallback: handleAssignContractModalClose,
				errorMessage: 'Impossible d\'assigner le contrat !'
			}
		);
	}

	const changeTransferableState = useCallback((contractNumber, {enable}) => {
		sendAndUpdate(
			jsRoutes.controllers.backoffice.DeviceV2Controller.changeCanBeTransferred(contractNumber, enable)
			.ajax({
				dataType: 'json',
				processData: false,
			}), {
				errorMessage: 'Impossible de changer le statut de transfert !'
			}
		);
	}, [sendAndUpdate]);

	const createDisableTransferClickHandler = useCallback(ID => () => {
		const device = getDeviceById(ID);
		changeTransferableState(device.contractNumber, {enable: false});
	}, [getDeviceById, changeTransferableState]);

	const createEnableTransferClickHandler = useCallback(ID => () => {
		const device = getDeviceById(ID);
		changeTransferableState(device.contractNumber, {enable: true});
	}, [getDeviceById, changeTransferableState]);

	const createEditDMDClickHandler = useCallback(ID => () => {
		const device = getDeviceById(ID);
		setEditDMD(device);
	}, [getDeviceById]);

	const onHideEditDMDClick = useCallback(() => setEditDMD(null), []);

	const handleAfterEdit = useCallback(response => {
		handleSearch(searchProperty, searchValue);
	}, [searchProperty, searchValue, handleSearch]);

	const handleSNSearch = useCallback(() => handleSearch('SN', SN), [SN, handleSearch]);

	const handleImportOldDeviceModalOpen = useCallback(() => {
		toggleImportOldDevice(true);
		setImportOldDeviceTag('');
	}, []);

	const handleImportOldDeviceModalClose = () => toggleImportOldDevice(false);

	const handleSNImport = () => {
		toggleLoading(true);

		let tags = [];
		if (importOldDeviceTag !== '') {
			tags.push(importOldDeviceTag);
		}
		let json = {
			SN: SN.split('\n'),
			tags,
		};

		jsRoutes.controllers.backoffice.DeviceV2Controller.importOldDevice()
		.ajax({
			contentType: 'application/json; charset=utf-8',
			data: JSON.stringify(json),
		})
		.then((response, textStatus, jqXHR) => {
			handleImportOldDeviceModalClose();
			handleSNSearch();
		}, (jqXHR, textStatus, errorThrown) => {
			notify.show('Impossible d\'importer les tablettes !', 'error', -1);
			toggleLoading(false);
		});
	}

	return (
		<div>
			<Grid>
				<Row>
					<div>
						<LoadingProgress show={loading} />

						<ContractHistoryModal
							show={showContractHistory}
							contractHistory={contractHistory}
							handleClose={handleContractHistoryModalClose}
						/>

						<DetachContractModal
							device={detachContractDevice}
							handleClose={handleDetachContractModalClose}
							handleSubmit={handleDetachContractSubmit}
						/>

						<CreateContractModal
							key={createContractDevice?.id}
							device={createContractDevice}
							handleClose={handleCreateContractModalClose}
							handleSubmit={handleCreateContractSubmit}
						/>

						<AssignContractModal
							key={assignContractDevice?.id}
							show={!!assignContractDevice}
							handleClose={handleAssignContractModalClose}
							handleSubmit={handleAssignContractSubmit}
						/>

						<ImportOldDeviceModal
							show={showImportOldDevice}
							importOldDeviceTag={importOldDeviceTag}
							SN={SN}
							handleClose={handleImportOldDeviceModalClose}
							handleSubmit={handleSNImport}
							createInputChangeHandler={createInputChangeHandler}
						/>
						
						{!!editDMD && (
							<DeviceEditModal
								key={editDMD.id}
								dmd={editDMD}
								onHide={onHideEditDMDClick}
								handleAfterEdit={handleAfterEdit}
							/>
						)}

						<DevicesSearchInputs
							properties={DEVICE_PROPERTIES}
							searchInputs={searchInputs}
							createKeyPressHandler={createKeyPressHandler}
							createInputChangeHandler={createInputChangeHandler}
							handleSNSearch={handleSNSearch}
							handleImportOldDeviceModalOpen={handleImportOldDeviceModalOpen}
						/>
						
						<DevicesSearchResults
							entries={entries}
							loading={loading}
							properties={DEVICE_PROPERTIES}
							searchProperty={searchProperty}
							searchValue={searchValue}
							tags={tags}
							createAssignContractClickHandler={createAssignContractClickHandler}
							createCreateContractClickHandler={createCreateContractClickHandler}
							createDetachContractClickHandler={createDetachContractClickHandler}
							createEnableTransferClickHandler={createEnableTransferClickHandler}
							createDisableTransferClickHandler={createDisableTransferClickHandler}
							createEditDMDClickHandler={createEditDMDClickHandler}
							createContractHistoryClickHandler={createContractHistoryClickHandler}
						/>
					</div>
				</Row>
			</Grid>
		</div>
	);
}

export default DevicesSearchView;