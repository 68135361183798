import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import NeedActivationButton from './ActionButtons/NeedActivationButton';
import SetUnknownButton from './ActionButtons/SetUnknownButton';

const NotActivatedGroupPure = ({
                                   displayVertical,
                                 }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <NeedActivationButton/>
    <SetUnknownButton/>
  </ButtonGroup>
);

NotActivatedGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const NotActivatedButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(NotActivatedGroupPure);

NotActivatedButtonGroup.propTypes = {};

export default NotActivatedButtonGroup;