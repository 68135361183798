import gql from 'graphql-tag';
import {TOUCANLISTOPERATIONERROR_FRAGMENTS} from './ToucanSchema';

const SIM_STATE_FRAGMENT = gql`
    fragment deviceSimStateFields on DeviceSimState {
        attached
        detached
        deviceId
        id
    }
`;

const GET_CONTRACT_SIM_STATES = gql`
    query GetCurrentSubscriber($contractNumber: String!) {
        contract(contractNumber: $contractNumber) {
            contract {
                amd {
                    simStates {
                        ...deviceSimStateFields
                    }
                }
            }
        }
    }
    ${SIM_STATE_FRAGMENT}
`;

const SET_CONTRACTS_BUYER = gql`
    mutation ContractSetBuyerOnContractList($input: ContractSetBuyerOnContractListInput!) {
        contractSetBuyerOnContractList(input: $input) {
            contracts {
                contractNumber
                buyerCustomerID
            }
            errors {
                ...toucanListOperationErrorStringType
            }
        }
    }
    ${TOUCANLISTOPERATIONERROR_FRAGMENTS.StringType}
`;

const UPDATE_CONTRACTS_TAGS = gql`
    mutation ContractUpdateTagsToContractList($input: ContractUpdateTagsOnContractListInput!) {
        contractUpdateTagsOnContractList(input: $input) {
            contracts {
                contractNumber
            }
            errors {
                ...toucanListOperationErrorStringType
            }
        }
    }
    ${TOUCANLISTOPERATIONERROR_FRAGMENTS.StringType}
`;

export {
    SIM_STATE_FRAGMENT,
    GET_CONTRACT_SIM_STATES,
    SET_CONTRACTS_BUYER,
    UPDATE_CONTRACTS_TAGS,
};