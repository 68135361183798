import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateFunction, withButtonContext} from './Helpers';

const SetTerminatedButtonPure = ({
                                   buttonSize,
                                   onValidateClick,
                                 }) => (
  <Button bsStyle='info' bsSize={buttonSize} onClick={() => onValidateClick()}>
    Confirmer la désactivation
  </Button>
);

SetTerminatedButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  onValidateClick: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateFunction,
});

const SetTerminatedButton = compose(
  withButtonContext,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.confirmTermination,
    }),
  ),
  handlers,
  pure,
)(SetTerminatedButtonPure);

SetTerminatedButton.propTypes = {};

export default SetTerminatedButton;