import React from 'react';
import {emptyFunction} from '../../../../../../utils/functions.js';
import createConfirmationDialog from '../../../../../components/ConfirmationDialog/createConfirmationDialog';
import {FORM_MODES} from '../SubscriptionStateEdit';

export default ({currentSubscriber, mode, subscriptionState, onSubmitClick}) => ({isASubscriptionState, ...newSubscriptionState}) => {
    const submit = () => {
        const {dateBeginFree, ...subscriptionStateFields} = isASubscriptionState ? newSubscriptionState : subscriptionState;
        onSubmitClick(subscriptionStateFields);
    };

    if (
        mode === FORM_MODES.CREATE &&
        !currentSubscriber?.bankID
    ) {
        createConfirmationDialog(
            <div>
                Le souscripteur actuel n'a pas de moyen de paiement actif.<br/><br/>
                IMPORTANT étape suivante:  l'éditer et revalider le SEPA pour l'activer dans Proabono.
            </div>,
            {confirmationLabel: 'OK', title: 'Avertissement'},
        ).then(
            submit,
            emptyFunction,
        );
    } else {
        submit();
    }
};
