import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Alert, Button, ButtonToolbar, ControlLabel, FormControl, FormGroup, HelpBlock, Modal} from 'react-bootstrap';
import {isN3User} from '../../../utils/functions';
import {SIM_OPERATORS, SIM_STATES} from '../../../utils/Sim';
import { postDMD, postSIM, postICCID } from '../../../utils/requests';
import { isIMEI, isMAC } from '../../../utils/dmd';
import {errorMsg, successMsg} from '../../../utils/Notify';
import {connectWebUserContext} from '../../../hoc/withMeAndTeamsAndConstants';
import ICCIDField from '../../components/ICCIDField';

const DeviceEditModal = connectWebUserContext(({ webUserJson, onlySim, dmd, onHide, handleAfterEdit }) => {
  const [SIM, setSIM] = useState({
    ICCID: dmd.sim?.ICCID || '',
    simFound: null,
    operator: dmd.sim?.operator || SIM_OPERATORS.LPM,
  });
  const [MAC, setMAC] = useState(dmd.MAC || '');
  const [IMEI1, setIMEI1] = useState(dmd.IMEI1 || '');

  const handleMACchange = event => setMAC(event.target.value);
  
  const handleIMEIchange = event => setIMEI1(event.target.value);
  
  const handleDMDsubmit = () => {
    postDMD(dmd.id, {MAC, IMEI1})
    .then((response, textStatus, jqXHR) => {
      handleAfterEdit(response);
      onHide();
      successMsg('Le DMD a été mis à jour !');
    }, (jqXHR, textStatus, errorThrown) => {
      errorMsg(`Erreur lors de l'édition du DMD avec le MAC ${MAC} et/ou l'IMEI ${IMEI1}`);
    });
  }

  const handleSIMsubmit = () => {
    const {operator, ICCID, simFound} = SIM;

    postICCID(dmd.id, ICCID)
    .then(async response => {
      if (simFound || !ICCID) {
        await handleAfterEdit(response);
        onHide();
        successMsg("L'ICCID a été mis à jour !");
      } else {
        const operatorValue = operator || Object.values(SIM_OPERATORS)[0].name;
        postSIM({
          currentState: {
            timestamp: +moment(),
            state: SIM_STATES.NOT_ACTIVATED.value,
          },
          ICCID,
          operator: operatorValue,
        })
        .then(async response => {
          await handleAfterEdit(response);
          onHide();
          successMsg('La SIM a été mise à jour !');
        },
        () => errorMsg(`Erreur lors de la mise à jour de l'opérateur ${operator} pour la SIM d'ICCID ${ICCID}`));
      }
    },
    () => errorMsg(`Erreur lors de l'édition de la SIM d'ICCID ${ICCID}`));
  }

  const fullDMDedition = !onlySim && isN3User(webUserJson);

  const MACchanged = MAC !== dmd.MAC;
  const IMEIchanged = IMEI1 !== dmd.IMEI1;
  const DMDchanged = MACchanged || IMEIchanged;
  const invalidMAC = !isMAC(MAC);
  const invalidIMEI = !isIMEI(IMEI1);
  const DMDsubmitDisabled = !DMDchanged || invalidMAC || invalidIMEI;

  return (
    <Modal show={!!dmd} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{fullDMDedition ? 'Éditer de DMD' : 'Changer la carte SIM'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fullDMDedition && <>
          <FormGroup validationState={invalidMAC ? 'error' : MACchanged ? 'success' : null}>
            <ControlLabel>MAC</ControlLabel>
            <FormControl
              type='text'
              value={MAC}
              onChange={handleMACchange}
            />
            {invalidMAC && <HelpBlock>Adresse MAC invalide</HelpBlock>}
          </FormGroup>
          <FormGroup validationState={invalidIMEI ? 'error' : IMEIchanged ? 'success' : null}>
            <ControlLabel>IMEI</ControlLabel>
            <FormControl
              type='text'
              value={IMEI1}
              onChange={handleIMEIchange}
            />
            {invalidIMEI && <HelpBlock>IMEI invalide</HelpBlock>}
          </FormGroup>
          <ButtonToolbar className='form-group'>
            <Button bsStyle='danger' className='pull-right' onClick={handleDMDsubmit} disabled={DMDsubmitDisabled}>
              Changer le DMD
            </Button>
          </ButtonToolbar>
          <hr/>
        </>}
        {SIM.simFound === false && (
          <Alert bsStyle='warning'>
            Cet ICCID n'est pas déclaré en base, merci de vérifier votre saisie.
          </Alert>
        )}
        Laisser vide pour détacher la SIM.
        <ICCIDField
          SIM={SIM}
          onChange={setSIM}
        />
        {fullDMDedition && (
          <ButtonToolbar>
            <Button bsStyle='danger' className='pull-right' onClick={handleSIMsubmit} disabled={dmd.sim?.ICCID === SIM.ICCID}>
              Changer la carte SIM
            </Button>
          </ButtonToolbar>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!fullDMDedition && (
          <Button bsStyle='danger' className='pull-left' onClick={handleSIMsubmit} disabled={dmd.sim?.ICCID === SIM.ICCID}>
            Mettre à jour
          </Button>
        )}
        <Button bsStyle='success' onClick={onHide}>
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

DeviceEditModal.propTypes = {
  dmd: PropTypes.object,
  onHide: PropTypes.func,
  onlySim: PropTypes.bool,
  handleAfterEdit: PropTypes.func,
};

export default DeviceEditModal;