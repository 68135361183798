import React from 'react';
import {graphql} from 'react-apollo';
import {Alert, Button, ButtonToolbar, Checkbox, Col, ControlLabel, FormControl, Row} from 'react-bootstrap';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {B2B_DELETE_LAST_SUBSCRIPTION_STATE} from '../../../schema/SubscriptionSchema';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg} from '../../../utils/Notify';
import {
  convertResult,
  formatContractNumbers,
  formatContractNumbersForSave,
  isContractNumbersMalformed,
  withToucanListOperationResult,
} from '../ContractsHelpers';

const DeleteLastSubscriptionStatePure = ({
                                       canSave,
                                       contractNumbers,
                                       shouldRemoveEndDatePreviousLine,
                                       handleSave,
                                       setContractNumbers,
                                       setShouldRemoveEndDatePreviousLine,
                                     }) => (
  <>
    <Row>
      <Col md={12}>
        <Alert bsStyle="warning">
          <i>Cette fonction permet de supprimer la dernière ligne d'abonnement des contrats listés si celle-ci <b>n'a
            pas encore débuté</b>.</i>
        </Alert>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <FormGroup>
          <ControlLabel>Numéros de contrat *</ControlLabel>
          <FormControl componentClass="textarea"
                       data-test-id="contract-numbers-textarea"
                       rows={4}
                       type="text"
                       value={contractNumbers}
                       onChange={e => setContractNumbers(formatContractNumbers(e.target.value))}/>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <FormGroup>
          <Checkbox data-test-id="should-remove-end-date-previous-line-checkbox"
                    checked={shouldRemoveEndDatePreviousLine}
                    onChange={e => setShouldRemoveEndDatePreviousLine(e.target.checked)}>
            Réinitialiser la date de fin de la ligne précédente ?
          </Checkbox>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <ButtonToolbar className="pull-right" style={{paddingTop: 10}}>
          <Button bsStyle="primary"
                  data-test-id="initialize-button"
                  disabled={!canSave}
                  onClick={handleSave}>
            Supprimer
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>
  </>
);

DeleteLastSubscriptionStatePure.propTypes = {};

const withContractNumbersState = withState('contractNumbers', 'setContractNumbers', undefined);
const withShouldRemoveEndDatePreviousLineState = withState('shouldRemoveEndDatePreviousLine', 'setShouldRemoveEndDatePreviousLine', false);

const ToucanListOperationErrorReason = {
  LAST_STATE_NOT_IN_FUTURE: {
    message: 'L\'abonnement ne doit pas avoir débuté',
    value: 'LAST_STATE_NOT_IN_FUTURE',
  },
  NOT_FOUND: {
    message: 'Abonnement introuvable',
    value: 'NOT_FOUND',
  },
  NO_METADATA: {
    message: 'Abonnement introuvable',
    value: 'NO_METADATA',
  },
  NO_SUBSCRIPTION_STATES: {
    message: 'Aucune ligne d\'abonnement',
    value: 'NO_SUBSCRIPTION_STATES',
  },
  SAVING_FAILED: {
    message: 'Erreur de sauvegarde',
    value: 'SAVING_FAILED',
  },
  UNAUTHORIZED: {
    message: 'Action non autorisée',
    value: 'UNAUTHORIZED',
  },
};

const submitB2bDeleteLastSubscriptionStateMutation = mutate => ({contractNumbers, shouldRemoveEndDatePreviousLine}, props) => {
  const {setLoading} = props;
  setLoading(
    true,
    () => mutate({
      variables: {
        input: {
          contractNumbers,
          removeEndDatePreviousLine: shouldRemoveEndDatePreviousLine,
        },
      },
      update: (_store, result) => convertResult(
        {
          contracts: result?.data?.b2bDeleteLastSubscriptionState?.successes?.map(_ => ({
            contractNumber: _.subscription.contractNumber,
          })),
          errors: result?.data?.b2bDeleteLastSubscriptionState?.failures?.map(_ => ({
            subject: `Contrat ${_.contractNumber}`,
            reason: _.errorReason,
          })),
        },
        props,
        ToucanListOperationErrorReason,
      ),
    }).then(
      emptyFunction,
      error => {
        console.error(error);
        setLoading(false, () => errorMsg('Erreur lors de la sauvegarde des lignes d\'abonnement'));
      },
    ),
  )
  ;
};

const withB2bDeleteLastSubscriptionStateMutation = graphql(B2B_DELETE_LAST_SUBSCRIPTION_STATE, {
  props: ({mutate}) => ({
    handleB2bDeleteLastSubscriptionState: submitB2bDeleteLastSubscriptionStateMutation(mutate),
  }),
});

const handlers = withHandlers({
  handleSave: props => () => {
    const {
      contractNumbers,
      shouldRemoveEndDatePreviousLine,
      handleB2bDeleteLastSubscriptionState,
    } = props;
    const formattedContractNumbers = formatContractNumbersForSave(contractNumbers);

    handleB2bDeleteLastSubscriptionState(
      {
        contractNumbers: formattedContractNumbers,
        shouldRemoveEndDatePreviousLine,
      },
      props,
    );
  },
});

const DeleteLastSubscriptionState = compose(
  connectMeAndTeamsAndConstantsContext,
  withContractNumbersState,
  withShouldRemoveEndDatePreviousLineState,
  withProps(({
               contractNumbers,
             }) => {
    return {
      canSave: contractNumbers && !isContractNumbersMalformed(contractNumbers),
    };
  }),
  withToucanListOperationResult,
  withLoading,
  withB2bDeleteLastSubscriptionStateMutation,
  handlers,
  pure,
)(DeleteLastSubscriptionStatePure);

export default DeleteLastSubscriptionState;