import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import SetActivatedButton from './ActionButtons/SetActivatedButton';
import SetUnknownButton from './ActionButtons/SetUnknownButton';

const ActivatingGroupPure = ({
                                 displayVertical,
                               }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <SetActivatedButton
      bsStyle='info'
      label="Confirmer l'activation"/>

    <SetUnknownButton/>
  </ButtonGroup>
);

ActivatingGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const ActivatingButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(ActivatingGroupPure);

ActivatingButtonGroup.propTypes = {};

export default ActivatingButtonGroup;