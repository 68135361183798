import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import ReactTable from 'react-table';
import {compose, pure, withProps, withState} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import withPagination from '../../../../../hoc/withPagination';
import {tsToDateForInput} from '../../../../../utils/Date';
import {filterDateColumn, filterTextColumn, isN2OrHigher} from '../../../../../utils/functions';
import OrderSubEntry from './OrderSubEntry';

const getActionButtons = (entry, props) => {
  const {webUserJson} = props;

  if (entry?.original?.prestations.dapo && isN2OrHigher(webUserJson)) {
    const buttons = [];
    const entryId = entry.original._id;
    const originalEntry = entry.original;
    const {
      isFreeContract,
      onEditClick,
      onMigrateToContractClick,
      onRecalculateClick,
      onCloneClick,
      onEnableClick,
      onCancelClick,
      onResendFSPlusClick,
    } = props;

    if (isFreeContract) {
      buttons.push(
        <Button key={entryId + '_reactivate'} bsStyle='info' bsSize='xsmall'
                onClick={() => onMigrateToContractClick(entryId)}>
          Réactiver
        </Button>,
      );
    } else {
      buttons.push(
        <Button key={entryId + '_edit'} bsStyle='info' bsSize='xsmall' onClick={() => onEditClick(entryId)}>
          Éditer
        </Button>,
      );

      buttons.push(
        <Button key={entryId + '_fsplus'} bsStyle='info' bsSize='xsmall'
                onClick={() => onRecalculateClick(entryId)}>
          Recalculer<br/>FSPlus
        </Button>);

      buttons.push(
        <Button key={entryId + '_duplicate'} bsStyle='success' bsSize='xsmall'
                onClick={() => onCloneClick(entryId)}>
          Dupliquer
        </Button>,
      );

      if (originalEntry.cancelled) {
        buttons.push(
          <Button key={entryId + '_reactivate'} bsStyle='warning' bsSize='xsmall'
                  onClick={() => onEnableClick(entryId)}>
            Réactiver
          </Button>,
        );
      } else if (originalEntry.cancellable) {
        buttons.push(
          <Button key={entryId + '_cancel'} bsStyle='danger' bsSize='xsmall'
                  onClick={() => onCancelClick(entryId)}>
            Annuler
          </Button>,
        );
      }

      if (originalEntry.fsPlusError === true) {
        buttons.push(
          <Button key={entryId + '_resend'} bsStyle='info' bsSize='xsmall'
                  onClick={() => onResendFSPlusClick(entryId)}>
            Refaire FS+
          </Button>,
        );
      }
    }

    return (
      <ButtonGroup vertical block>
        {buttons}
      </ButtonGroup>
    );
  }

  return null;
};

const getWidth = (baseWidth, isFreeContract) => isFreeContract ? undefined : baseWidth;

const getColumns = (props) => ([
  {
    Header: 'Id',
    accessor: 'Id',
    width: 40,
    filterMethod: (filter, row) => filterTextColumn('Id', row, filter.value),
  }, {
    Header: 'Date Créa',
    accessor: 'Date Créa',
    type: 'date',
    width: 80,
    Cell: props => <div>{props.value ? tsToDateForInput(props.value) : null}</div>,
    filterMethod: (filter, row) => filterDateColumn('Date Créa', row, filter.value),
  }, {
    Header: 'Produit',
    accessor: 'Produit',
    width: getWidth(100, props.isFreeContract),
    filterMethod: (filter, row) => filterTextColumn('Produit', row, filter.value),
  }, {
    Header: 'Adresse FS+',
    accessor: 'Adresse FS+',
    width: getWidth(100, props.isFreeContract),
    filterMethod: (filter, row) => filterTextColumn('Adresse FS+', row, filter.value),
  }, {
    Header: 'Adresse client',
    accessor: 'Adresse client',
    width: getWidth(115, props.isFreeContract),
    filterMethod: (filter, row) => filterTextColumn('Adresse client', row, filter.value),
  }, {
    Header: 'DAPO',
    accessor: 'DAPO',
    width: getWidth(50, props.isFreeContract),
    filterMethod: (filter, row) => filterTextColumn('DAPO', row, filter.value),
  }, {
    Header: 'Livraison',
    accessor: 'Livraison',
    width: getWidth(50, props.isFreeContract),
    filterMethod: (filter, row) => filterTextColumn('Livraison', row, filter.value),
  }, {
    Header: 'FS+',
    accessor: 'FS+',
    width: getWidth(40, props.isFreeContract),
    filterMethod: (filter, row) => filterTextColumn('FS+', row, filter.value),
  }, {
    Header: '',
    accessor: '_id',
    width: 85,
    Cell: entry => getActionButtons(entry, props),
    Filter: () => (
      <Button bsSize='sm' bsStyle='primary' onClick={() => props.setFiltered([])}>Effacer</Button>
    ),
  },
]);

const ITEMS_PER_PAGE = 4;

const compareOrders = (a, b) => a.Id.localeCompare(b.Id);

const getPagedOrders = (orders, activePage) => orders.sort(compareOrders).slice((activePage - 1) * ITEMS_PER_PAGE, (activePage) * ITEMS_PER_PAGE);

const withFilteredState = withState('filtered', 'setFiltered', []);

const OrderTablePure = ({activePage, filtered, orders, ...props}) => {
  const columns = getColumns(props);
  const pagedOrders = getPagedOrders(orders, activePage);
  return (
    <ReactTable
      fill
      filterable
      data={pagedOrders}
      columns={columns}
      resizable={false}
      pageSize={(pagedOrders && pagedOrders.length >= ITEMS_PER_PAGE) ? ITEMS_PER_PAGE : pagedOrders.length}
      showPagination={false}
      style={{
        height: `${pagedOrders ? pagedOrders.length * 50 + 305 : 300}px`,
        border: 'none',
      }}
      className='-striped'
      getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
      SubComponent={({row}) => <OrderSubEntry order={row} columns={columns}/>}
      onFilteredChange={filtered => props.setFiltered(filtered)}
      filtered={[...filtered]}
    />
  );
};

OrderTablePure.propTypes = {
  activePage: PropTypes.number,
  filtered: PropTypes.array,
  pageNumber: PropTypes.number,
  webUserJson: PropTypes.object,
  setFiltered: PropTypes.func,
};

const OrdersTable = compose(
  connectMeAndTeamsAndConstantsContext,
  withProps(({orders}) => ({pageNumber: Math.ceil(orders.length / ITEMS_PER_PAGE)})),
  withFilteredState,
  withPagination,
  pure,
)(OrderTablePure);

OrdersTable.propTypes = {
  isFreeContract: PropTypes.bool,
  orders: PropTypes.array,
  onEditClick: PropTypes.func,
  onCloneClick: PropTypes.func,
  onMigrateToContractClick: PropTypes.func,
  onRecalculateClick: PropTypes.func,
  onEnableClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onResendFSPlusClick: PropTypes.func,
};

export default OrdersTable;