import React, { Component } from 'react';

import jsRoutes from '../../../../routes/routes';
import LoadingProgress from '../../../components/LoadingProgress';

import Antennas from './Antenna/Antennas';

class Antenna extends Component {

    constructor(props) {
        super(props);
        this.state = this.getComponentInitialState();
    }

    getComponentInitialState() {
        return { address:'', loading: true };
    }

    getUser(contractNumber) {
        let route = jsRoutes.controllers.backoffice.SupportV2Controller.getMainUser(contractNumber);
        let _this = this;
        route.ajax({dataType: 'json', contentType: 'application/json; charset=utf-8', processData: false}).then(function(response, textStatus, jqXHR) {
            _this.setState({loading: false});
            _this.getAddress(response);
        }, function(jqXHR, textStatus, errorThrown) {
            if(_this.props.usertype === 'Utilisateur'){
                _this.props.selectBuyer();
            }
            _this.setState({loading: false});
        });
    }

    getAddress(user){
        let address= (user.addressLine[0] ? user.addressLine[0] : '') +' '+
                        (user.addressLine[1] ? user.addressLine[1] : '')  +' '+
                        (user.addressLine[2] ? user.addressLine[2] : '')  +' '+
                        (user.addressLine[3] ? user.addressLine[3] : '')  +' '+
                        (user.zipCode ? user.zipCode : '') +' '+
                        (user.city ? user.city : '') +' ' +
                        (user.country ? user.country : '');

        this.setState({
            user: user,
            address: address,
        });
    }

    componentDidMount(){
        this.getUser(this.props.contractNumber);
    }

    componentDidUpdate(previousProps) {
        if (this.props.contractNumber !== previousProps.contractNumber){
            this.getUser(this.props.contractNumber);
        }
    }

    render() {
        return (
            <div>
                <LoadingProgress show={this.state.loading}/>
                <Antennas address={this.state.address}/>
            </div>
        )
    }
}

export default Antenna;