import PropTypes from 'prop-types';
import React from 'react';
import EditSepaModal from '../../../contract/CustomerContract/tabs/Subscription/Sepa/EditSepaModal';
import LoadingProgress from '../../../components/LoadingProgress';
import TicketAnswers from './TicketAnswers';
import TicketDetailsTable from './TicketDetailsTable';
import NewTicketAnswer from './NewTicketAnswer';
import TicketAttachments from './TicketAttachments';
import TicketClosureResult from './TicketClosureResult';
import TicketTags from './TicketTags';

const TicketContent = ({
	newAnswer,
	setNewAnswer,
	closed,
	loading,
	options,
	ticket,
	tagList,
	handleSaveAnswer,
	handleSaveTicket,
}) => (
	<>
		<LoadingProgress show={loading} />

		<TicketClosureResult
			closed={closed}
			ticket={ticket}
		/>

		<TicketDetailsTable ticket={ticket} />

		<TicketTags
			ticket={ticket}
			tagList={tagList}
			handleSaveTicket={handleSaveTicket}
		/>

		<TicketAttachments attachments={ticket?.attachments} />

		<TicketAnswers
			ticketClosed={closed}
			answers={ticket?.answers}
			handleSaveAnswer={handleSaveAnswer}
		/>

		<NewTicketAnswer
			ticketSubject={ticket.subject}
      newAnswer={newAnswer}
      setNewAnswer={setNewAnswer}
			closed={closed}
			options={options}
		/>
		
		<EditSepaModal ticket={Array.isArray(ticket) ? {} : ticket} contractNumber={ticket.contractNumber} />

		<small>{ticket.creator ? `Créé par ${ticket.creator}` : ''}</small>
	</>
);

TicketContent.propTypes = {
	newAnswer: PropTypes.object,
	setNewAnswer: PropTypes.func,
	closed: PropTypes.bool,
	loading: PropTypes.bool,
	options: PropTypes.object,
	ticket: PropTypes.object,
	tagList: PropTypes.array,
	handleSaveAnswer: PropTypes.func,
	handleSaveTicket: PropTypes.func,
};

export default TicketContent;