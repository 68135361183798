import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {UPDATE_CONTRACTS_TAGS} from '../../../schema/ContractSchema';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg} from '../../../utils/Notify';
import JsonSchemaForm from '../../components/Form/JsonSchemaForm';
import ReactSelect from '../../components/Form/ReactSelect/ReactSelect';
import LayoutGridField from '../../components/LayoutGridFields';
import {
    contractNumberSchemaField,
    contractNumbersUiSchemaField,
    convertResult,
    formatContractNumbersForSave,
    onFormDataChange,
    validateForm,
    withToucanListOperationResult,
} from '../ContractsHelpers';

const getSchema = ({tags}) => ({
    type: 'object',
    properties: {
        contractNumbers: contractNumberSchemaField,
        tagsToAdd: {
            title: 'Tags à ajouter',
            type: 'string',
            params: {
                creatable: true,
                isMultiFromString: true,
                options: Object.entries(tags)
                    .map(([value, label]) => ({label, value})),
            },
        },
        tagsToRemove: {
            title: 'Tags à supprimer',
            type: 'string',
            params: {
                isMultiFromString: true,
                options: Object.entries(tags)
                    .map(([value, label]) => ({label, value})),
            },
        },
    },
    required: ['contractNumbers'],
});

const uiSchema = {
    contractNumbers: contractNumbersUiSchemaField,
    tagsToAdd: {
        'ui:widget': 'reactSelect',
    },
    tagsToRemove: {
        'ui:widget': 'reactSelect',
    },
    'ui:field': 'layout_grid',
    'ui:layout_grid': [{
        'ui:row': [
            {'ui:col': {md: 6, children: ['contractNumbers']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['tagsToAdd']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['tagsToRemove']}},
        ],
    }],
};

const widgets = {
    reactSelect: ReactSelect,
};

const TagsUpdatePure = ({
                            formData,
                            schema,
                            handleFormDataChange,
                            handleSave,
                        }) => (
    <JsonSchemaForm fields={{layout_grid: LayoutGridField}}
                    formData={formData}
                    schema={schema}
                    showErrorList={false}
                    uiSchema={uiSchema}
                    widgets={widgets}
                    onChange={handleFormDataChange}
                    onSubmit={handleSave}
                    validate={validateForm}>
        <ButtonToolbar className='pull-right'>
            <Button bsStyle='primary'
                    type='submit'>
                Modifier les tags
            </Button>
        </ButtonToolbar>
    </JsonSchemaForm>
);

TagsUpdatePure.propTypes = {
    formData: PropTypes.object,
    schema: PropTypes.object,
    handleFormDataChange: PropTypes.func,
    handleSave: PropTypes.func,
};

const withFormDataState = withState('formData', 'setFormData', {});

const ToucanListOperationErrorReason = {
    NOT_FOUND: {
        message: 'n\'existe pas',
        value: 'NOT_FOUND',
    },
    SAVE: {
        message: 'erreur de sauvegarde',
        value: 'SAVE',
    },
};

const submitAddTagsToContractList = mutate => ({contractNumbers, tagsToAdd, tagsToRemove}, props) => {
    const {setLoading} = props;
    setLoading(
        true,
        () => mutate({
            variables: {
                input: {
                    contractNumbers,
                    tagsToAdd: tagsToAdd || [],
                    tagsToRemove: tagsToRemove || [],
                },
            },
            update: (store, result) => convertResult(
                result?.data?.contractUpdateTagsOnContractList,
                props,
                ToucanListOperationErrorReason,
            ),
        }).then(
            emptyFunction,
            error => {
                console.error(error);
                setLoading(false, () => errorMsg('Erreur lors de la sauvegarde des tags'));
            },
        ),
    );
};

const withAddTagsToContractListMutation = graphql(UPDATE_CONTRACTS_TAGS, {
    props: ({mutate}) => ({
        handleAddTagsToContractList: submitAddTagsToContractList(mutate),
    }),
});

const handlers = withHandlers({
    handleFormDataChange: onFormDataChange,
    handleSave: props => () => {
        const {formData, handleAddTagsToContractList} = props,
            contractNumbers = formatContractNumbersForSave(formData.contractNumbers),
            tagsToAdd = formData.tagsToAdd?.split('|'),
            tagsToRemove = formData.tagsToRemove?.split('|');

        handleAddTagsToContractList(
            {
                contractNumbers,
                tagsToAdd,
                tagsToRemove,
            },
            props,
        );
    },
});

const TagsUpdate = compose(
    connectMeAndTeamsAndConstantsContext,
    withFormDataState,
    withToucanListOperationResult,
    withLoading,
    withAddTagsToContractListMutation,
    handlers,
    withProps(props => ({
        schema: getSchema(props),
    })),
    pure,
)(TagsUpdatePure);

TagsUpdate.propTypes = {};

export default TagsUpdate;