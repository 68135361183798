import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import {SIM_OPERATORS} from '../../../../utils/Sim';

const TransatelMessagePure = () => (
    <Alert bsStyle='warning'>
        <b>ATTENTION</b> <i>cette fonction permet d'agir sur l'affichage du statut de la sim en base, pour l'activer ou la
        désactiver <b>en réel</b> merci de passer par la plateforme Transatel ou l'envoi du fichier d'activation vers Transatel.</i>
    </Alert>
);

TransatelMessagePure.propTypes = {};

const InformationMessage = compose(
    branch(
        ({operator}) => operator === SIM_OPERATORS.LPM.name,
        renderNothing,
    ),
    pure,
)(TransatelMessagePure);

InformationMessage.propTypes = {
    operator: PropTypes.string,
};

export default InformationMessage;