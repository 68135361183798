import jsRoutes from '../routes/routes';

export const fetchTicketTypes = () => jsRoutes.controllers.backoffice.SupportV2Controller.getTicketTypes()
  .ajax({
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    processData: false
  });

export const postTicketTransferToOtherContract = data => jsRoutes.controllers.backoffice.SupportV2Controller.transferTicketToOtherContract()
  .ajax({
    data: JSON.stringify(data),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    processData: false
  });

export const postTicketDeletion = (contractNumber, id) => jsRoutes.controllers.backoffice.SupportV2Controller.deleteTicket(contractNumber, id)
  .ajax({
    contentType: false,
    processData: false,
  });

export const postTicketAnswer = (contractNumber, id, data) => jsRoutes.controllers.backoffice.SupportV2Controller.setAnswer(contractNumber, id)
  .ajax({
    contentType: false,
    processData: false,
    data,
  });

export const fetchContractTickets = contractNumber => jsRoutes.controllers.backoffice.SupportV2Controller.getContractTickets(contractNumber).ajax({
    dataType: 'json',
    processData: false,
  });

export const postTicket = (contractNumber, data, options = {}) => jsRoutes.controllers.backoffice.SupportV2Controller.setTicket(contractNumber)
  .ajax({
    contentType: false,
    processData: false,
    data,
    ...options
  });

export const fetchTeamTickets = team => jsRoutes.controllers.backoffice.SupportV2Controller.getTicketsByTeam(team)
  .ajax({
    dataType: 'json',
    processData: false
  });

export const fetchContract = contractNumber => jsRoutes.controllers.backoffice.SupportV2Controller.getContract(contractNumber)
  .ajax({
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    processData: false
  });

export const detachContract = dmdID => jsRoutes.controllers.backoffice.DeviceV2Controller.detachContract(dmdID)
  .ajax({
    dataType: 'json',
    processData: false,
  });

export const getContractURL = contractNumber => jsRoutes.controllers.backoffice.SupportV2Controller.getContract(contractNumber).url.replace('/supportV2', '');

export const getTicketURL = ticket => getContractURL(ticket.contractNumber) + `/ticket/${ticket.ticketNumber}`;

export const fetchMaintenances = contractNumber => jsRoutes.controllers.backoffice.MaintenanceV2Controller.getMaintenances(contractNumber)
  .ajax({
    dataType: 'json',
    processData: false,
  });

export const fetchDMD = contractNumber => jsRoutes.controllers.backoffice.SupportV2Controller.getHardwareMetadata(contractNumber)
  .ajax({
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    processData: false
  });

export const patchDMD = (contractNumber, data) => jsRoutes.controllers.backoffice.SupportV2Controller.setDMD(contractNumber)
  .ajax({
    data: JSON.stringify(data),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    processData: false,
  });

export const postDMD = (DMDid, {MAC, IMEI1}) => jsRoutes.controllers.backoffice.DeviceV2Controller.changeDMD(DMDid)
  .ajax({
    data: JSON.stringify({MAC, IMEI1}),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    processData: false,
  });

export const postICCID = (DMDid, ICCID) => jsRoutes.controllers.backoffice.DeviceV2Controller.changeSIM(DMDid, ICCID)
  .ajax({
    dataType: 'json',
    processData: false,
  });

export const postSIM = ({ currentState, ICCID, operator }) => jsRoutes.controllers.backoffice.SupportV2Controller.setSim(ICCID)
  .ajax({
    data: JSON.stringify({
      currentState,
      ICCID,
      operator,
    }),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    processData: false,
  });

export const fetchSIMs = ICCID => jsRoutes.controllers.backoffice.SupportV2Controller.getSims()
  .ajax({
    data: JSON.stringify({ICCIDS: [ICCID]}),
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
    processData: false,
  });

export const changeDeviceTransferStatus = (contractNumber, {enable}) => jsRoutes.controllers.backoffice.DeviceV2Controller.changeCanBeTransferred(contractNumber, enable)
  .ajax({
    dataType: 'json',
    processData: false,
  });

export const logout = () => jsRoutes.controllers.Authentication.supportV2Logout()
  .ajax({
    dataType: 'html',
    processData: false,
    addHeaders: [{ name: 'DontRedirect', value: '303' }],
  });
