import React, { useMemo } from 'react';
import SimpleSelect from './SimpleSelect';
import { connectTypesContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { getSortedSubjectOptions } from '../../../utils/types';
import { getSubjectTypeLabel } from '../../../utils/functions';

const SubjectSelect = connectTypesContext(({types, subjects = Object.keys(types.type_demande), value = '', onChange, ...props}) => {
  const subjectOptions = useMemo(() => {
    return getSortedSubjectOptions(subjects, types).map(subjectValue => ({
      value: getSubjectTypeLabel(types, subjectValue),
      label: getSubjectTypeLabel(types, subjectValue)
    }));
  }, [subjects, types]);
  
  return (
    <SimpleSelect
      value={value}
      onChange={onChange}
      options={subjectOptions}
      {...props}
    />
  );
});

export default SubjectSelect;