import React from 'react';

import UltimatePagination from '../../components/UltimatePagination';
import { useEffect } from 'react';

const TicketsTablePagination = ({activePage = 1, totalPages = 1, setActivePage}) => {
	
	useEffect(() => {
		if (activePage > totalPages) setActivePage(1);
	}, [activePage, setActivePage, totalPages]);

	return (
		<div className='text-center'>
			<UltimatePagination
				totalPages={totalPages}
				currentPage={activePage}
				onChange={setActivePage}
			/>
		</div>
	);
}

export default TicketsTablePagination;