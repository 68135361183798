import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import {CUSTOMER_TYPOLOGY_TYPES} from '../../../utils/constants';

const schema = {
	type: 'object',
	properties: {
		customerTypology: {
			type: 'string',
			title: 'Type de client', enum: Object.values(CUSTOMER_TYPOLOGY_TYPES)
		},
		tag: {
			type: 'string',
			title: 'Tag'
		},
		isTooti: {
			type: 'boolean',
			title: 'Créer un contrat Tooti'
		},
		flavor: {
			type: 'string',
			title: 'Flavor'
		},
	},
};

const CreateContractModal = ({ device, handleClose, handleSubmit }) => {
	const [contract, setContract] = useState({
		flavor: '',
		isTooti: false,
		tag: '',
	});

	const handleChange = ({ formData }) => setContract(formData);

	if (!device) return null;

	return (
		<Modal show onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Créer un contrat</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form schema={schema} formData={contract} onChange={handleChange} onSubmit={handleSubmit}>
					<Button type='submit' className='none' />
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='success' onClick={handleClose}>
					Annuler
				</Button>
				<Button bsStyle='danger' className='pull-left' onClick={handleSubmit({contract})}>
					Créer
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

CreateContractModal.propTypes = {
	device: PropTypes.object,
	handleClose: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default CreateContractModal;