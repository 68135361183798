import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withState } from 'recompose';
import Form from 'react-jsonschema-form';
import { Button, Modal } from 'react-bootstrap';
import { TICKET_DUPLICATA_RESULTS, TICKET_DUPLICATA_SUBRESULTS } from '../../../../../utils/constants';

const ORDER_DUPLICATION_REASONS_KEYS = Object.keys(TICKET_DUPLICATA_RESULTS);
const ORDER_DUPLICATION_REASONS_VALUES = Object.values(TICKET_DUPLICATA_RESULTS);

const getOrderDuplicationSubKeys = (duplicationReason) =>
    !duplicationReason || !TICKET_DUPLICATA_SUBRESULTS[duplicationReason] ? [] : Object.keys(TICKET_DUPLICATA_SUBRESULTS[duplicationReason]);


const getOrderDuplicationSubValues = (duplicationReason) =>
    !duplicationReason || !TICKET_DUPLICATA_SUBRESULTS[duplicationReason] ? [] : Object.values(TICKET_DUPLICATA_SUBRESULTS[duplicationReason]);

const getSchema = ({ duplicationReason }) => {
    let duplicationSubReason = {};

    if(duplicationReason && !!TICKET_DUPLICATA_SUBRESULTS[duplicationReason]) {
        duplicationSubReason = {
            duplicationSubReason: {
                type: 'string',
                title: 'Préciser le motif*',
                enum: getOrderDuplicationSubKeys(duplicationReason),
                enumNames: getOrderDuplicationSubValues(duplicationReason),
            },
        };
    }

    return ({
        type: 'object',
        title: '',
        properties: {
            duplicationReason: {
                type: 'string',
                title: 'Motif de duplicata*',
                enum: ORDER_DUPLICATION_REASONS_KEYS,
                enumNames: ORDER_DUPLICATION_REASONS_VALUES,
            },
            ...duplicationSubReason
        },
    });
};

const validate = ({ duplicationReason, duplicationSubReason }, errors) => {
    if(!duplicationReason) {
        errors.duplicationReason.addError('Veuillez sélectionner un motif de duplicata.');
    }

    if(duplicationReason && !duplicationSubReason && getOrderDuplicationSubKeys(duplicationReason).length > 0) {
        errors.duplicationSubReason.addError('Veuillez préciser le motif de duplicata.');
    }

    return errors;
};

const DuplicationEditPure = ({ formData, onHideClick, onSubmitClick, setFormData }) => (
    <Modal show={true} bsSize='lg' onHide={onHideClick}>
        <Modal.Header closeButton>
            <Modal.Title>Qualification du duplicata</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form schema={getSchema(formData)}
                  onSubmit={onSubmitClick}
                  formData={formData}
                  validate={validate}
                  showErrorList={false}
                  onChange={({ formData }) => setFormData(formData)} >
                <Button type='submit' bsStyle='info'>Valider</Button>
            </Form>
        </Modal.Body>
    </Modal>
);

const withFormDataState = withState('formData', 'setFormData', { duplicationReason: '', duplicationSubReason: '' });

const DuplicationResult = compose(
    withFormDataState,
    pure,
)(DuplicationEditPure);

DuplicationResult.propTypes = {
    order: PropTypes.object,
    onSubmitClick: PropTypes.func,
    onHideClick: PropTypes.func,
};

export default DuplicationResult;