import React, { useMemo, useState } from 'react';
import {Table} from 'react-bootstrap';

import TicketAnswersTableHead from './TicketAnswersTableHead';
import TicketAnswersTableBody from './TicketAnswersTableBody';

const TICKET_ANSWERS_PROPERTIES = [
	{
		name: 'Date Créa',
		type: 'date',
		size: '10%'
	},
	{
		name: 'Équipe',
		size: '10%'
	},
	{
		name: 'Créateur',
		size: '10%'
	},
	{
		name: 'Canal',
		size: '7%'
	},
	{
		name: 'Réponse',
		type: 'content',
		size: '50%'
	},
	{
		name: 'Pièce jointe',
		type: 'attachment',
		size: '10%'
	}
];

const createAnswerPropertySorter = property => (a, b) => {
	if (!property) return a.ID.localeCompare(b.ID);

	let aValue = a[property.name];
	let bValue = b[property.name];

	if (property.reverse) {
		aValue = b[property.name];
		bValue = a[property.name];
	}

	if (aValue) {
		if (bValue) {
			return aValue.toString().localeCompare(bValue.toString())
		}
		return -1;
	} else if (bValue) {
		return 1;
	}
	return b.ID.localeCompare(a.ID);
}

const TicketAnswersTable = ({filteredAnswers = [], loading, ticketClosed, handleSaveAnswer, properties = TICKET_ANSWERS_PROPERTIES, itemsPerPage, activePage, filters, createFilterInputChangeHandler}) => {

	const [sortBy, setSortBy] = useState({ name: 'Date Créa', reverse: false });
	
	const paginatedAnswers = useMemo(() => {
		return filteredAnswers.sort(createAnswerPropertySorter(sortBy)).slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
	}, [activePage, filteredAnswers, itemsPerPage, sortBy]);

	return (
		<Table striped bordered condensed hover>
			<TicketAnswersTableHead
				sortBy={sortBy}
				setSortBy={setSortBy}
				properties={properties}
			/>
			<TicketAnswersTableBody
				paginatedAnswers={paginatedAnswers}
				filters={filters}
				loading={loading}
				ticketClosed={ticketClosed}
				handleSaveAnswer={handleSaveAnswer}
				createFilterInputChangeHandler={createFilterInputChangeHandler}
				properties={properties}
			/>
		</Table>
	);
}

export default TicketAnswersTable;