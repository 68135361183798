import React from 'react';
import PropTypes from 'prop-types';
import {Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';

import {ADDRESS_TYPES} from '../../../../../utils/AfterSale';
import Select from './Select';

const ContactInfosPure = ({ addressTypes, canEdit, currentAddress, afterSale, handleAddressTypeChange, handleAfterSaleInfosChange, handleCurrentAddressChange }) => (
    <>
        <Row>
            <Col sm={4}>
                <ControlLabel bsSize='sm'>Adresse de retour</ControlLabel>
            </Col>
            <Col md={8}>
                <Select arrayValues={addressTypes}
                        disabled={!canEdit}
                        mandatory
                        value={afterSale.addressType}
                        handleChange={handleAddressTypeChange} />
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <fieldset className='custom-fieldset-blue' disabled={!canEdit || afterSale.addressType !== ADDRESS_TYPES.OTHER.value}>
                    <legend>Adresse</legend>
                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Civilité</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.gender}
                                             onChange={e => handleCurrentAddressChange('gender', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Nom</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.lastName}
                                             onChange={e => handleCurrentAddressChange('lastName', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Prénom</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.firstName}
                                             onChange={e => handleCurrentAddressChange('firstName', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Adresse</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.addressLine0}
                                             onChange={e => handleCurrentAddressChange('addressLine0', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Adresse 2</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.addressLine1}
                                             onChange={e => handleCurrentAddressChange('addressLine1', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Code Postal</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.zipcode}
                                             onChange={e => handleCurrentAddressChange('zipcode', e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col md={9}>
                            <FormGroup>
                                <ControlLabel bsSize='sm'>Ville</ControlLabel>
                                <FormControl bsSize='sm'
                                             value={currentAddress.city}
                                             onChange={e => handleCurrentAddressChange('city', e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </fieldset>
            </Col>
        </Row>
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Téléphone de suivi</ControlLabel>
                    <FormControl type='tel'
                                 bsSize='sm'
                                 value={afterSale.phone}
                                 onChange={e => handleAfterSaleInfosChange('phone', e.target.value)} />
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Mail de suivi</ControlLabel>
                    <FormControl type='mail'
                                 bsSize='sm'
                                 value={afterSale.email}
                                 onChange={e => handleAfterSaleInfosChange('email', e.target.value)} />
                </FormGroup>
            </Col>
        </Row>
    </>
);

ContactInfosPure.propTypes = {
    addressTypes: PropTypes.array,
    currentAddress: PropTypes.object,
    afterSale: PropTypes.object,
    handleAddressTypeChange: PropTypes.func,
    handleAfterSaleInfosChange: PropTypes.func,
    handleCurrentAddressChange: PropTypes.func,
};

const withCalculatedProps = withProps(
    ({ afterSale, isEditMode }) => {
        const addressTypes = do {
            if(afterSale.addressType === ADDRESS_TYPES.WITHOUT_SEND_BACK.value) {
                [ADDRESS_TYPES.WITHOUT_SEND_BACK];
            } else {
                Object.values(ADDRESS_TYPES)
                    .filter(_ => _.value !== ADDRESS_TYPES.WITHOUT_SEND_BACK.value);
            }
        };

        return ({
            addressTypes,
            canEdit: !isEditMode || afterSale?.currentState?.canBeUpdatedWithCustomerAddress,
        })
    }
);

const ContactInfos = compose(
    withCalculatedProps,
    pure,
)(ContactInfosPure);

ContactInfos.propTypes = {
    afterSale: PropTypes.object,
    currentAddress: PropTypes.object,
    isEditMode: PropTypes.bool,
    handleAddressTypeChange: PropTypes.func,
    handleAfterSaleInfosChange: PropTypes.func,
    handleCurrentAddressChange: PropTypes.func,
};

export default ContactInfos;