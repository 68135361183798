import React, { useState } from 'react';
import { Button, Checkbox, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

const AssignContractModal = ({show, handleClose, handleSubmit}) => {
	const [formData, setFormData] = useState({
		assignContractNumber: '',
		assignContractReplaceSIM: true,
	});

	const handleContractNumberChange = event => {
    const {value} = event.target;
    setFormData(({ formData }) => ({...formData, assignContractNumber: value}));
  }

  const handleSIMReplaceChange = event => {
    const {checked} = event.target;
    setFormData(({ formData }) => ({...formData, assignContractReplaceSIM: checked}));
  }

  const { assignContractNumber, assignContractReplaceSIM } = formData;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assigner un contrat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <ControlLabel>Numéro de contrat</ControlLabel>
          <FormControl
            type='text'
            value={assignContractNumber}
            onChange={handleContractNumberChange}
          />
          <Checkbox checked={assignContractReplaceSIM} onChange={handleSIMReplaceChange}>
            Transférer la carte SIM
          </Checkbox>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='success' onClick={handleClose}>
          Annuler
        </Button>
        <Button bsStyle='danger' className='pull-left' onClick={handleSubmit(formData)}>
          Assigner
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AssignContractModal;