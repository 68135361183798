import React from 'react';
import AdminView from './admin/AdminView';
import { navigateTo } from '../utils/functions';
import { connectWebUserContext } from '../hoc/withMeAndTeamsAndConstants';
import { isAdminVisible } from '../utils/accessRights';

const Admin = connectWebUserContext(({webUserJson}) => {
  if (!webUserJson || !isAdminVisible(webUserJson)) {
    navigateTo('/ticket');
  }
  return <AdminView />;
});

export default Admin;