import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonToolbar, Glyphicon} from 'react-bootstrap';
import {branch, compose, renderComponent} from 'recompose';

const LockedButtonGroupPure = ({handleEdit}) => (
    <ButtonToolbar className='pull-right'>
        <Button bsSize='small'
                bsStyle='primary'
                onClick={handleEdit}>
            <Glyphicon glyph='pencil'/>&nbsp;Modifier
        </Button>
    </ButtonToolbar>
);

LockedButtonGroupPure.propTypes = {
    handleEdit: PropTypes.func,
};

const EditingButtonGroupPure = ({handleCancel, handleSave}) => (
    <ButtonToolbar className='pull-right'>
        <Button bsSize='small'
                bsStyle='danger'
                onClick={handleCancel}>
            Annuler</Button>

        <Button bsSize='small'
                bsStyle='success'
                onClick={handleSave}>
            Valider
        </Button>
    </ButtonToolbar>
);

EditingButtonGroupPure.propTypes = {
    handleCancel: PropTypes.func,
    handleSave: PropTypes.func,
};

const ActionButtonGroup = compose(
    branch(
        ({locked}) => locked,
        renderComponent(LockedButtonGroupPure),
    ),
)(EditingButtonGroupPure);

ActionButtonGroup.propTypes = {
    locked: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleEdit: PropTypes.func,
    handleSave: PropTypes.func,
};

export default ActionButtonGroup;