import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

const TicketAnswersTableFilterSelect = ({name, value, onChange, options, getOptionLabel}) => {		
	return (
		<FormGroup className='small-control' bsSize='small'>
			<FormControl
				componentClass='select'
				value={value ?? ''}
				onChange={onChange}
				placeholder={name}
			>
				<option value=''>---</option>

				{Object.keys(options).map(type_value => (
					<option key={type_value} value={type_value}>
						{getOptionLabel(type_value)}
					</option>
				))}
			</FormControl>
		</FormGroup>
	);
}

export default TicketAnswersTableFilterSelect;