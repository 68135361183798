import React, { useMemo } from 'react';

import TicketsTableRow from './TicketsTableRow';

const TicketsTableRows = ({properties, activePage = 1, itemsByPage = 8, filteredTickets, selectedTicketID, actions}) => {

	const paginatedTickets = useMemo(() => {
		return filteredTickets.slice((activePage - 1) * itemsByPage, activePage * itemsByPage);
	}, [filteredTickets, activePage, itemsByPage]);

	return paginatedTickets.map(ticket => (
		<TicketsTableRow
			key={ticket.id}
			selected={selectedTicketID === ticket.id}
			ticket={ticket}
			properties={properties}
			actions={actions}
		/>
	));
}

export default TicketsTableRows;