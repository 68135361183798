import React from 'react';

import { connectWebUserContext } from '../../../hoc/withMeAndTeamsAndConstants';
import TicketsTableCell from './TicketsTableCell';

const TicketsTableRow = connectWebUserContext(({webUserJson, ticket, selected, properties = [], actions}) => {
	return (
		<tr className={selected ? 'info' : ''}>
			{properties.map(property => (
				<TicketsTableCell
					key={property.name}
					ticket={ticket}
					property={property}
				/>
			))}
			{actions && actions(ticket)}
		</tr>
	);
});

export default TicketsTableRow;