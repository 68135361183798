import { difference } from 'lodash';
import moment from 'moment';
moment.locale('fr');

export function isNotEmpty(array) {
	return Array.isArray(array) && !!array.length;
}

export function isEmpty(array) {
	return !Array.isArray(array) || !array.length;
}

export function isDifferent(a, b) {
  return (isEmpty(a) && isNotEmpty(b)) || (isNotEmpty(a) && isEmpty(b)) || isNotEmpty(difference(a, b)) || isNotEmpty(difference(b, a));
}

export function isSame(a, b) {
  return !isDifferent(a, b);
}

export function sortAlpha(arr = [], transformationFunction) {
	return arr.sort((a, b) => {
		let newA = a,
				newB = b;

		if (transformationFunction) {
			newA = transformationFunction(a);
			newB = transformationFunction(b);
		}

		if (newA && newB && newA.toLowerCase && newB.toLowerCase) {
			return newA.toLowerCase() < newB.toLowerCase() ? -1 : 1;
		}
		return 0;
	});
}

export const sortByPriorityAndCreation = (a = {}, b = {}) => {
	let diff = a.priority - b.priority;
	if (diff === 0) {
		diff = a.created - b.created;
	}
	return diff;
}

export function createDateSorter({attribute, order = 'desc', parseFormat}) {
	const compare = order === 'asc' ? 'isBefore' : 'isAfter';
	return (a, b) => moment(a[attribute], parseFormat)[compare](moment(b[attribute], parseFormat)) ? -1 : 1;
}

export const findIndexByKeyValue = (array, key, value) => {
  return array.findIndex(element => element[key] === value);
};