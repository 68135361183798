import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormGroup, Glyphicon, ListGroup, ListGroupItem} from 'react-bootstrap';
import jsRoutes from '../../../../routes/routes';

const {getAttachment} = jsRoutes.controllers.backoffice.SupportV2Controller;

const TicketAttachments = ({attachments}) => {
	if (!attachments?.length) return null;
	return (
		<FormGroup>
			<ControlLabel>Pièces jointes</ControlLabel>
			<ListGroup>
				{attachments.map((attachment, index) =>
					<ListGroupItem key={index} href={getAttachment(attachment.path).url}>
						<Glyphicon glyph='file'/>&nbsp;{attachment.name}
					</ListGroupItem>,
				)}
			</ListGroup>
		</FormGroup>
	);
}

TicketAttachments.propTypes = {
	attachments: PropTypes.array,
};

export default TicketAttachments;