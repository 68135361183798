import React from 'react';
import { Button } from 'react-bootstrap';

import TicketTransferRowButton from '../TicketTransferRowButton';

const ContractTicketsTableRowActions = ({ticket, handleOpen, transferTicket}) => {
	return (
		<td>
			<Button className='center-block' onClick={handleOpen}>Voir</Button>

			<TicketTransferRowButton ticket={ticket} transferTicket={transferTicket} />
		</td>
	);
};

export default ContractTicketsTableRowActions;