import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';

import {tsToDateForInput} from '../../../utils/Date';
import { navigateToContract, navigateToSIM } from '../../../utils/functions';
import {SIM_STATES} from '../../../utils/Sim';
import FontAwesome from '../../components/FontAwesome';
import ContractTags from '../../components/ContractTags';

const DevicesTableRowFields = ({ properties, entry, tags, handleContractHistoryClick }) => {
	return properties.map(({name, type, ...property}) => {
		const value = entry[name];
		let render = value;

		switch (type) {
			case 'date':
				if (value) render = tsToDateForInput(value);
				break;

			case 'contract':
				render = (
					<div>
						{value && <>
							<Button bsStyle='link' className='cursor-pointer' style={{ padding: '6px 0' }} onClick={() => navigateToContract(value)}>
								{value}
							</Button>&nbsp;
		
							<Button bsStyle='link' className='cursor-pointer' style={{ padding: '6px 0' }} onClick={() => navigateToContract(value, true)}>
								<FontAwesome name='external-link' />
							</Button>
		
							<ContractTags tagsOptions={tags} contractNumber={value} />
						</>}
							
						<Button bsSize='xsmall' onClick={handleContractHistoryClick}>
							<Glyphicon glyph='pencil'/> Historique
						</Button>
					</div>
				);
				break;

			case 'sim':
				if (!value) break;

				let bsStyle = 'default';
				if (entry.simState) {
					if([
						SIM_STATES.ACTIVATED.value,
						SIM_STATES.ACTIVATING.value,
						SIM_STATES.NEED_ACTIVATION.value
					].includes(entry.simState)) {
						bsStyle = 'success';
					} else {
						bsStyle = 'warning';
					}
				}

				const handleSimClick = () => navigateToSIM(value, true);

				render = (
					<Button bsStyle={bsStyle} bsSize='xsmall' onClick={handleSimClick}>
						{value}
					</Button>
				);
				break;
		
			default:
				break;
		}

		return <td key={entry._id + '_' + name}>{render}</td>;
	});
}

export default DevicesTableRowFields;