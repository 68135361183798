import React from 'react';
import { Label } from 'react-bootstrap';

const TicketTags = ({value}) => {

  if (value) {
    return value.map(tag => (
      <span key={tag}>
        <Label className='label-block' bsStyle={tag === 'URGENT' ? 'danger' : 'default'}>
          {tag.replace(/_/g, '\n')}
        </Label>
        <br />
      </span>
    ));
  }
  return null;
};

export default TicketTags;