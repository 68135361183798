import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {UPDATE_SIMSTATE_ON_CONTRACT_LIST} from '../../../schema/SimSchema';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg} from '../../../utils/Notify';
import {SIM_OPERATORS, SIM_STATES} from '../../../utils/Sim';
import JsonSchemaForm from '../../components/Form/JsonSchemaForm';
import LayoutGridField from '../../components/LayoutGridFields';
import {
  contractNumberSchemaField,
  contractNumbersUiSchemaField,
  convertResult,
  formatContractNumbersForSave,
  onFormDataChange,
  validateForm,
  withToucanListOperationResult,
} from '../ContractsHelpers';
import InformationMessage from './SimStateUpdate/InformationMessage';

const getSchema = ({activationState, terminationState}) => ({
  type: 'object',
  properties: {
    contractNumbers: contractNumberSchemaField,
    operator: {
      title: 'Opérateur*',
      enum: [SIM_OPERATORS.LPM.name, SIM_OPERATORS.TRANSATEL.name],
    },
    simState: {
      title: 'Action*',
      type: 'string',
      enum: [activationState, terminationState],
      enumNames: ['Activation', 'Désactivation'],
    },
  },
  required: ['contractNumbers', 'operator', 'simState'],
});

const uiSchema = {
  contractNumbers: contractNumbersUiSchemaField,
  operator: {
    'ui:options': {
      widget: 'radio',
      inline: true,
    },
  },
  simState: {
    'ui:options': {
      widget: 'radio',
      inline: true,
    },
  },
  'ui:field': 'layout_grid',
  'ui:layout_grid': [{
    'ui:row': [
      {'ui:col': {md: 6, children: ['contractNumbers']}},
    ],
  }, {
    'ui:row': [
      {'ui:col': {md: 12, children: ['operator']}},
    ],
  }, {
    'ui:row': [
      {'ui:col': {md: 12, children: ['simState']}},
    ],
  }],
};

const SimStateUpdatePure = ({
                              formData,
                              schema,
                              submitLabel,
                              handleFormDataChange,
                              handleSave,
                            }) => (
  <>
    <InformationMessage operator={formData.operator}/>
    <JsonSchemaForm fields={{layout_grid: LayoutGridField}}
                    formData={formData}
                    schema={schema}
                    showErrorList={false}
                    uiSchema={uiSchema}
                    onChange={handleFormDataChange}
                    onSubmit={handleSave}
                    validate={validateForm}>
      <ButtonToolbar className={`pull-right ${!formData?.simState && 'hidden'}`}>
        <Button bsStyle="primary"
                type="submit">
          {submitLabel}
        </Button>
      </ButtonToolbar>
    </JsonSchemaForm>
  </>
);

SimStateUpdatePure.propTypes = {
  formData: PropTypes.object,
  schema: PropTypes.object,
  submitLabel: PropTypes.string,
  handleFormDataChange: PropTypes.func,
  handleSave: PropTypes.func,
};

const withFormDataState = withState('formData', 'setFormData', {});

const getToucanListOperationErrorReasons = operator => ({
  NOT_FOUND: {
    message: 'n\'existe pas',
    value: 'NOT_FOUND',
  },
  SAVE: {
    message: 'erreur de sauvegarde',
    value: 'SAVE',
  },
  WRONG_OPERATOR: {
    message: `l'opérateur de la carte SIM doit être ${operator}`,
    value: 'WRONG_OPERATOR',
  },
  WRONG_STATE: {
    message: 'le statut ne permet pas d\'effectuer l\'action',
    value: 'WRONG_STATE',
  },
});

const submitUpdateContractsSimState = mutate => ({contractNumbers, simState}, props) => {
  const {toucanListOperationErrorReasons, setLoading} = props;
  setLoading(
    true,
    () => mutate({
      variables: {
        input: {
          contractNumbers,
          simState,
        },
      },
      update: (store, result) => convertResult(
        result?.data?.simUpdateSimStateOnContractList,
        props,
        toucanListOperationErrorReasons,
      ),
    }).then(
      emptyFunction,
      error => {
        console.error(error);
        setLoading(false, () => errorMsg('Erreur lors de la sauvegarde des cartes SIM'));
      },
    ),
  );
};

const withUpdateContractsSimStateMutation = graphql(UPDATE_SIMSTATE_ON_CONTRACT_LIST, {
  props: ({mutate}) => ({
    handleUpdateContractsSimState: submitUpdateContractsSimState(mutate),
  }),
});

export const getSimStateForOperator = ({operator, simState}) => {
  if (operator === SIM_OPERATORS.LPM.name) {
    if (simState === SIM_STATES.ACTIVATED.value) {
      return SIM_STATES.NEED_ACTIVATION.value;
    }

    if (simState === SIM_STATES.TERMINATED.value) {
      return SIM_STATES.NEED_TERMINATION.value;
    }
  }

  return simState;
};

const handlers = withHandlers({
  handleFormDataChange: onFormDataChange,
  handleSave: props => () => {
    const {formData, handleUpdateContractsSimState} = props;
    const contractNumbers = formatContractNumbersForSave(formData.contractNumbers);

    handleUpdateContractsSimState(
      {
        contractNumbers,
        simState: getSimStateForOperator(formData),
      },
      props,
    );
  },
});

const calculatedProps = withProps(
  ({formData}) => {
    const [activationState, terminationState] = [SIM_STATES.ACTIVATED.value, SIM_STATES.TERMINATED.value];

    return {
      activationState,
      schema: getSchema({activationState, terminationState}),
      submitLabel: `${formData?.simState === activationState ? 'Activer' : 'Désactiver'} les cartes SIM`,
      terminationState,
      toucanListOperationErrorReasons: getToucanListOperationErrorReasons(formData.operator),
    };
  },
);

const SimStateUpdate = compose(
  withFormDataState,
  withToucanListOperationResult,
  withLoading,
  calculatedProps,
  withUpdateContractsSimStateMutation,
  handlers,
  pure,
)(SimStateUpdatePure);

export default SimStateUpdate;