import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

const getClassNameForValue = (value) => {
    switch (value) {
        case 'ORANGE':
            return 'orange';
        case 'SFR':
            return 'sfr';
        case 'BOUYGUES TELECOM':
            return 'bouygues';
        case 'FREE MOBILE':
            return 'free';
        default:
            return '';
    }
};

class AntennasTableEntry extends Component {
    onEntryClick() {
        const URL_CARTO = 'https://www.cartoradio.fr/index.html#/cartographie/all/lonlat';
        window.open(`${URL_CARTO}/${this.props.entry.lon}/${this.props.entry.lat}`);
    }

    render() {
        var _this = this;
        var fields = this.props.properties.map(prop => {
            var value = _this.props.entry[prop.name];
            if (prop.type === 'operator' && value ) {
                const className = getClassNameForValue(value);
                value = <span className={className}>{value}</span>;
                return  <td key={_this.props.entry._id + '_' + prop.name}>{value}</td>;
            } else if(value) {
                return  <td key={_this.props.entry._id + '_' + prop.name}>{value}</td>;
            }

            return <td key={_this.props.entry._id + '_' + prop.name} />;
        });

        let classRowActive = '';
        if (_this.props.entry['Type antenne'].indexOf('3G') !== -1){
            classRowActive = _this.props.entry['Dispo'] === 'En service' ? '' : 'warning';
        }

        return (
            <tr className={classRowActive}>
                {fields}
                <td>
                    <Button className='center-block' disabled={!this.props.entry.lon || !this.props.entry.lat} bsSize={this.props.bsSize} onClick={this.onEntryClick.bind(this)}>Voir</Button>
                </td>
            </tr>
        );
    }
}

export default AntennasTableEntry;