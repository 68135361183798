import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Button, Checkbox, Col, FormControl, Glyphicon, Row} from 'react-bootstrap';
import {compose, pure, withHandlers, withState} from 'recompose';
import SingleDatePicker from '../../../components/DatePicker/SingleDatePicker';

const formatDateValue = date => date ? moment(date) : null;

const B2BFeatureFormPure = ({
                              availableFeatures,
                              error,
                              tempB2BFeature,
                              handleAddFeature,
                              handleTempB2BFeatureChange,
                            }) => {
  const handleDateValueChange = property => value => handleTempB2BFeatureChange(property, value ? moment(value).valueOf() : value);

  return (
    <>
      <Row>
        <Col md={3}>
          <FormControl componentClass="select"
                       data-test-id="feature-select"
                       rows={4}
                       value={tempB2BFeature.featureId}
                       onChange={e => handleTempB2BFeatureChange('featureId', e.target.value)}
          >
            <option value=''/>

            {availableFeatures.map(feature =>
              <option key={feature.id}
                      value={feature.id}>
                {feature.label}
              </option>,
            )}
          </FormControl>
        </Col>

        <Col md={2}>
          <Checkbox checked={tempB2BFeature.enabled}
                    onChange={e => handleTempB2BFeatureChange('enabled', e.target.checked)}>
            Active
          </Checkbox>
        </Col>

        <Col md={3}>
          <SingleDatePicker
            data-test-id="start-date-picker"
            value={formatDateValue(tempB2BFeature.start)}
            onChange={handleDateValueChange('start')}
          />
        </Col>

        <Col md={3}>
          <SingleDatePicker
            value={formatDateValue(tempB2BFeature.end)}
            onChange={handleDateValueChange('end')}
          />
        </Col>

        <Col md={1}>
          <Button bsStyle="success" data-test-id="save-button" onClick={handleAddFeature}>
            <Glyphicon glyph="plus"/>
          </Button>
        </Col>
      </Row>

      {error && (
        <Row data-test-id="error-message">
          <Col md={12}>
            <Alert bsStyle="danger">{error}</Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

B2BFeatureFormPure.propTypes = {
  availableFeatures: PropTypes.array,
  tempB2BFeature: PropTypes.object,
  handleAddFeature: PropTypes.func,
  handleTempB2BFeatureChange: PropTypes.func,
};

const initialData = {
  enabled: false,
  end: null,
  featureId: '',
  start: null,
};

const withTempB2BFeatureState = withState('tempB2BFeature', 'setTempB2BFeature', initialData);
const withErrorState = withState('error', 'setError', null);

const handlers = withHandlers({
  handleAddFeature: ({tempB2BFeature, addB2BFeature, setError, setTempB2BFeature}) => () => {
    if (tempB2BFeature.featureId && tempB2BFeature.start) {
      addB2BFeature(tempB2BFeature);
      setTempB2BFeature(initialData);
    } else {
      setError('Veuillez sélectionner une option et renseigner sa date de début.');
    }
  },
  handleTempB2BFeatureChange: ({
                                 tempB2BFeature,
                                 setError,
                                 setTempB2BFeature,
                               }) => (
    property,
    value,
  ) => {
    setError(null);
    setTempB2BFeature({
      ...tempB2BFeature,
      [property]: value,
    });
  },
});

const B2BFeatureForm = compose(
  withTempB2BFeatureState,
  withErrorState,
  handlers,
  pure,
)(B2BFeatureFormPure);

B2BFeatureForm.propTypes = {
  availableFeatures: PropTypes.array,
  addB2BFeature: PropTypes.func,
};

export default B2BFeatureForm;