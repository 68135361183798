import PropTypes from 'prop-types';
import React from 'react';
import {Label} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';
import {tsToDateForInput} from '../../../utils/Date';
import {getSimStateClass} from '../../../utils/Sim';
import SimManagement from '../SimManagement';

const SimsTableEntryPure = ({
                              fields,
                              sim,
                              editOperatorHandler,
                              handleLoading,
                              handleManageSim,
                            }) => (
  <tr>
    {fields}
    <td>
      <SimManagement displayVertical
                     sim={sim}
                     callBack={handleManageSim}
                     editOperatorHandler={editOperatorHandler}
                     handleLoading={handleLoading}/>
    </td>
  </tr>
);

SimsTableEntryPure.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.node),
};

const SimsTableEntry = compose(
  withProps(
    ({entry, properties}) => {
      const fields = properties.map(
        prop => {
          let value = entry[prop.name];

          if (prop.type === 'date' && typeof value !== 'undefined') {
            value = tsToDateForInput(value);
          }
          if (prop.type === 'state') {
            value = <Label bsStyle={getSimStateClass(value)}>{value.toUpperCase()}</Label>;
          }

          return <td key={entry._id + '_' + prop.name}>{value}</td>;
        });

      return {
        fields,
      };
    },
  ),
  pure,
)(SimsTableEntryPure);

SimsTableEntry.propTypes = {
  entry: PropTypes.object,
  properties: PropTypes.array,
};

export default SimsTableEntry;