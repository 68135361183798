import React, { Component } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import jsRoutes from '../../routes/routes';

class PasswordReminder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            email: '',
        };
    }

    handleDismissAlert() {
        this.setState({ showAlert: false });
    }

    handleMailChange(e) {
        const value = e.target.value;
        this.setState({
            email: value,
        });
    }

    handleSubmit() {
        const { email } = this.state;
        const _this = this;

        jsRoutes.controllers.backoffice.UserV2Controller.resetPassword().ajax({
            data: { email : email },
            error: data => {
                alert('Erreur lors de la récupération du mot de passe.');
            },
            success: data => {
                _this.props.handleShowChange(false);
            },
        });
    }

    render() {
        let submitDisabled = true;
        let forgetEmailInputGroupClass = '';
        let forgetEmailInputGlyphiconClass = '';

        if(this.state.email !== ''){
            if(
                /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(this.state.email) ||
                /^MR[0-9]{4}$/.test(this.state.email)
            ) {
                forgetEmailInputGroupClass = 'has-success';
                forgetEmailInputGlyphiconClass = 'glyphicon-ok';
                submitDisabled = false;
            } else {
                forgetEmailInputGroupClass = 'has-error';
                forgetEmailInputGlyphiconClass = 'glyphicon-remove';
            }
        }

        return (
            <Modal show={this.props.showModal} onHide={() => this.props.handleShowChange(false)}>
                <Modal.Header closeButton>
                    <h4 className='modal-title' id='myModalLabel'>Récupération des identifiants</h4>
                </Modal.Header>
                <Modal.Body>
                    { this.state.showAlert &&
                        <Alert bsStyle='danger' onDismiss={this.handleDismissAlert.bind(this)}>
                            <strong>Email inconnu !</strong><br/>
                            Vérifiez votre saisie. Si votre email est correct, votre contact a peut être été supprimé par l'utilisateur de la tablette. Nous vous invitons alors à le contacter.
                        </Alert>
                    }

                    <Form className='form-horizontal innerAll'>
                        <div className={`input-group ${forgetEmailInputGroupClass}`}>
                            <span className='input-group-addon' id='forget_email_desc'>
                                <i className='fa fa-envelope' />
                            </span>
                            <input type='email' className='form-control' id='forget_email_input' placeholder='Saisissez votre adresse email' onChange={this.handleMailChange.bind(this)} value={this.state.email} />
                            <span id='forget_email_input_glyphicon' className={`glyphicon form-control-feedback ${forgetEmailInputGlyphiconClass}`} />
                            <span id='inputSuccess2Status' className='sr-only'>(success)</span>
                        </div>
                    </Form>

                    Un email contenant votre identifiant et un nouveau mot de passe sera envoyé à l'adresse email.
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.handleShowChange(false)}>Annuler</Button>
                    <Button id='sendForgotEmail' type='button' className='btn btn-primary' disabled={submitDisabled} onClick={this.handleSubmit.bind(this)}>
                        Envoyer l'email
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

PasswordReminder.propTypes = {
    showModal: PropTypes.bool,
    handleShowChange: PropTypes.func,
}

export default PasswordReminder;