import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateWithPlanFunction, withButtonContext, withSelectPlanStates, wrapWithModal} from './Helpers';

const NeedActivationButtonPure = ({
                                    buttonSize,
                                    setShowModal,
                                  }) => (
  <Button bsStyle='success' bsSize={buttonSize} onClick={() => setShowModal(true)}>
    Activer
  </Button>
);

NeedActivationButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  setShowModal: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateWithPlanFunction,
});

const NeedActivationButton = compose(
  withButtonContext,
  withSelectPlanStates,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.activate,
    }),
  ),
  handlers,
  wrapWithModal,
  pure,
)(NeedActivationButtonPure);

NeedActivationButton.propTypes = {};

export default NeedActivationButton;