import PropTypes from 'prop-types';
import {withContext} from 'recompose';

export const withSimManagementContext = withContext({
    buttonSize: PropTypes.string,
    displayVertical: PropTypes.bool,
    fromDevice: PropTypes.bool,
    sim: PropTypes.object,
    callBack: PropTypes.func,
    editOperatorHandler: PropTypes.func,
    handleLoading: PropTypes.func,
  },
  ({
     buttonSize,
     displayVertical,
     fromDevice,
     sim,
     callBack,
     editOperatorHandler,
     handleLoading,
   }) => ({
    buttonSize,
    displayVertical,
    fromDevice,
    sim,
    callBack,
    editOperatorHandler,
    handleLoading,
  }),
);