import PropTypes from 'prop-types';
import React from 'react';
import './MaintenanceStatus.css';

const MaintenanceStatus = ({status}) => {
  switch (status) {
    case 'INTERNAL_ERROR':
      return <div className="maintenance-status internal-error">Int. Error</div>;
    case 'KO':
      return <div className="maintenance-status ko">KO</div>;
    case 'OK':
      return <div className="maintenance-status ok">OK</div>;
    case 'WARNING':
      return <div className="maintenance-status warning">Warning</div>;
    case 'EMPTY':
    default:
      return <div className="maintenance-status empty">Empty</div>;
  }
};

MaintenanceStatus.propTypes = {
  status: PropTypes.string,
};

export default MaintenanceStatus;