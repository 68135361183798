import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { compose, pure, withHandlers } from 'recompose';

import withLoading from '../../../../../../hoc/withLoading';
import jsRoutes from '../../../../../../routes/routes';

const RemoveChannelModalPure = ({ handleRemoveChannel, onHide }) => (
    <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Suppression du channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Êtes-vous sûr ?
        </Modal.Body>
        <Modal.Footer>
            <Button bsStyle='success' onClick={onHide}>
                Annuler
            </Button>
            <Button bsStyle='danger' className='pull-left' onClick={handleRemoveChannel}>
                Supprimer
            </Button>
        </Modal.Footer>
    </Modal>
);

RemoveChannelModalPure.propTypes = {
    handleRemoveChannel: PropTypes.func,
};

const removeChannel = (contractNumber) => jsRoutes.controllers.backoffice.MaintenanceV2Controller.removeChannel(contractNumber).ajax();

const handlers = withHandlers({
    handleRemoveChannel: ({ contractNumber, onHide, setLoading }) => () => {
        setLoading(
            true,
            () => removeChannel(contractNumber).then(
                () => onHide(),
                () => setLoading(false),
            )
        );
    },
});

const RemoveChannelModal = compose(
    withLoading,
    handlers,
    pure,
)(RemoveChannelModalPure);

RemoveChannelModal.propTypes = {
    contractNumber: PropTypes.string,
    onHide: PropTypes.func,
};

export default RemoveChannelModal;