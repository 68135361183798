import React from 'react';

import { getSubjectTypeLabel, navigateToTicket } from '../../../utils/functions';
import { getTicketURL } from '../../../utils/requests';

import TicketsTable from '../TicketsTable';
import TicketsTableRowActions from './TeamTicketsTableRowActions';
import { useMemo } from 'react';
import { connectTypesContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { getTicketPriority, getTicketCustomerFullName } from '../../../utils/tickets';

const TEAM_TICKET_PROPERTIES = [
	{
		label: 'ID',
		name: 'ticketNumber',
		size: '3%'
	},
	{
		label: 'Date Créa',
		name: 'created',
		type: 'date',
		size: '6%'
	},
	{
		label: 'À traiter le',
		name: 'takeActionDay',
		size: '5%'
	},
	{
		label: 'Au plus tard le',
		name: 'noLaterThanDay',
		size: '5%'
	},
	{
		label: 'Demande',
		name: 'subjectLabel',
		size: '10%'
	},
	{
		label: 'État',
		name: 'state',
		size: '3%'
	},
	{
		label: 'Fraich- eur',
		name: 'priority',
		size: '3%'
	},
	{
		label: 'Tags',
		name: 'tags',
		size: '7%'
	},
	{
		label: 'Client',
		name: 'contractNumber',
		size: '5%'
	},
	{
		label: 'Nom',
		name: 'customerName',
		size: '7%'
	},
	{
		label: 'Contenu',
		name: 'content',
		size: '50%'
	},
	{
		label: 'Contrat',
		name: 'contractType',
		size: '5%'
	}
];

const createTeamTicketFormatter = types => (ticket = {}) => {
	const {answers = []} = ticket;

	let lastAnswer;
	if (answers.length > 0) {
    answers.sort((a = {}, b = {}) => a.created - b.created);
		lastAnswer = answers[answers.length - 1];
	}
	return {
		...ticket,
		lastAnswer,
		subjectLabel: getSubjectTypeLabel(types, ticket.subject) || '',
		priority: getTicketPriority(ticket.created, ticket.takeActionDay),
		customerName: getTicketCustomerFullName(ticket),
		tags: ticket.metadata ? ticket.metadata.tags : []
	};
}

const TeamTicketsTable = connectTypesContext(({types, loading, tickets, tagList, transferTicket, setSelectedTicket}) => {

  const formatTeamTicket = useMemo(() => createTeamTicketFormatter(types), [types]);

	const createEditHandler = ticket => () => {
		setSelectedTicket(ticket);
	}

	const createOpenHandler = ticket => () => {
		navigateToTicket(ticket.contractNumber, ticket.ticketNumber);
	}

	const createOpenInNewTabHandler = ({contractNumber, ticketNumber, ...ticket}) => () => {
		const ticketUrl = getTicketURL({contractNumber, ticketNumber});
		window.open(ticketUrl, '_blank');
	}

	return (
		<TicketsTable
			loading={loading}
			tickets={tickets}
			tagList={tagList}
			properties={TEAM_TICKET_PROPERTIES}
			formatTicket={formatTeamTicket}
			defaultSort={{name: 'takeActionDay', reverse: false}}
			actions={ticket => (
				<TicketsTableRowActions
					ticket={ticket}
					handleEdit={createEditHandler(ticket)}
					handleOpen={createOpenHandler(ticket)}
					handleOpenInNewTab={createOpenInNewTabHandler(ticket)}
					transferTicket={transferTicket}
				/>
			)}
		/>
	);
});

export default TeamTicketsTable;