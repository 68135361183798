import React from 'react';
import {Modal} from 'react-bootstrap';
import {emptyFunction} from '../../../../../utils/functions';
import AfterSaleTabletReturnForm from '../../../CreateNewTicketsPanel/AfterSaleTabletReturnForm';
import PropTypes from 'prop-types';

const AfterSaleTabletReturnModal = ({ options, show, ticket, onHide }) => (
	<Modal
		bsSize='lg'
		dialogClassName='afterSaleFormModal'
		show={show}
		onHide={onHide}
	>
		<Modal.Header closeButton>
			<Modal.Title>Création d'un retour</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<AfterSaleTabletReturnForm
				onHide={onHide}
				ticket={ticket}
				options={options}

				// WTH is supposed to happen here ??
				addTicketTags={emptyFunction}
				handleSave={emptyFunction}
				handleTicketChange={emptyFunction}
				
				// editedAfterSale={editedAfterSale}
				// isEditMode={isEditMode}
			/>
		</Modal.Body>
	</Modal>
);

AfterSaleTabletReturnModal.propTypes = {
	options: PropTypes.object,
	show: PropTypes.bool,
	ticket: PropTypes.object,
	onHide: PropTypes.func,
};

export default AfterSaleTabletReturnModal;