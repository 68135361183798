import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

const TicketAnswersTableFilterInput = ({value, onChange}) => {
	return (
		<FormGroup className='small-control' bsSize='small'>
			<FormControl
				type='text'
				value={value ?? ''}
				onChange={onChange}
			/>
		</FormGroup>
	);
}

export default TicketAnswersTableFilterInput;