import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {ControlLabel, FormControl, FormGroup, InputGroup} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, toClass, withHandlers, withProps, withState} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../../hoc/withMeAndTeamsAndConstants';
import withLoading from '../../../../../../hoc/withLoading';
import {
    GET_ALL_SUPPORT_ANDROID_UPDATE,
    UPDATE_ACCOUNT_METADATA_SUPPORT_CHANNELS,
} from '../../../../../../schema/AndroidUpdateSchema';
import {sortAlpha} from '../../../../../../utils/Array';
import {GCM_ORDERS_TYPES} from '../../../../../../utils/constants';
import {isN2TechnicalUser, isN3User} from '../../../../../../utils/functions';
import handleSupportChannelChange from './AndroidUpdateSelect/handleSupportChannelChange';
import SendGCMOrderButton from './SendGCMOrderButton';

const AndroidUpdateSelectPure = ({channelOptions, contractNumber, currentSupportChannel, handleSupportChannelChange, setLoading}) => (
    <FormGroup>
        <ControlLabel>Patchs disponibles</ControlLabel>
        <InputGroup>
            <FormControl componentClass='select'
                         value={currentSupportChannel}
                         onChange={e => handleSupportChannelChange(e.target.value)}>
                {channelOptions.map(_ => <option key={_.id} value={_.id} disabled={_.disabled}>{_.label}</option>)}
            </FormControl>
            <InputGroup.Addon>
                <SendGCMOrderButton contractNumber={contractNumber}
                                    type={GCM_ORDERS_TYPES.UPDATE}
                                    setLoading={setLoading}/>
            </InputGroup.Addon>
        </InputGroup>
    </FormGroup>
);

AndroidUpdateSelectPure.propTypes = {
    channelOptions: PropTypes.array,
    contractNumber: PropTypes.string,
    currentSupportChannel: PropTypes.string,
    handleSupportChannelChange: PropTypes.func,
    setLoading: PropTypes.func,
};

const EMPTY_UPDATE = {id: 'empty', name: 'empty', label: 'Aucun'};
const getSupportChannel = supportChannels => supportChannels && supportChannels.length > 0 ? [...supportChannels].map(_ => ({
    id: _.name,
    label: _.label,
    name: _.name,
})).pop() : EMPTY_UPDATE;

const withCurrentSupportChannelState = withState(
    'currentSupportChannel',
    'setCurrentSupportChannel',
    ({supportChannels}) => getSupportChannel(supportChannels).id,
);

const withAndroidUpdateData = graphql(GET_ALL_SUPPORT_ANDROID_UPDATE, {
    options: ({
        variables: {
            input: {},
        },
    }),
});

const submitUpdateAccountMetadataSupportChannels = mutate => (supportChannels, {contractNumber, setCurrentSupportChannel}) =>
    mutate({
        variables: {
            input: {
                contractNumber,
                supportChannels,
            },
        },
        update: (store, result) => {
            const newSupportChannels = result.data.accountMetadataUpdateSupportChannels.supportChannels;
            const supportChannelId = newSupportChannels?.length > 0 ? [...newSupportChannels].pop() : EMPTY_UPDATE.id;

            setCurrentSupportChannel(supportChannelId);
        },
    });

const withAndroidUpdateMutation = graphql(UPDATE_ACCOUNT_METADATA_SUPPORT_CHANNELS, {
    props: ({mutate}) => ({
        updateAccountMetadataSupportChannels: submitUpdateAccountMetadataSupportChannels(mutate),
    }),
});

const withCalculatedProps = withProps(
    ({data, supportChannels}) => {
        const initialSupportChannel = getSupportChannel(supportChannels);
        let androidUpdates = data?.androidUpdates?.androidUpdates || [];

        if (initialSupportChannel && initialSupportChannel.id !== EMPTY_UPDATE.id) {
            if (!androidUpdates.find(_ => _.name === initialSupportChannel.id)) {
                androidUpdates = [{...initialSupportChannel, disabled: true}, ...androidUpdates];
            }
        }

        const androidUpdatesOpt = sortAlpha(androidUpdates, _ => _.label)
            .map(_ => ({id: _.name, disabled: _.disabled, label: _.label, name: _.name}));

        return {
            initialSupportChannel,
            channelOptions: [EMPTY_UPDATE, ...androidUpdatesOpt],
        };
    },
);

const handlers = withHandlers({
    handleSupportChannelChange,
});

const AndroidUpdateSelect = compose(
    connectMeAndTeamsAndConstantsContext,
    branch(
        ({webUserJson}) => !isN2TechnicalUser(webUserJson) && !isN3User(webUserJson),
        renderNothing,
    ),
    branch(
        ({supportChannels}) => !supportChannels,
        renderNothing,
    ),
    withCurrentSupportChannelState,
    withAndroidUpdateData,
    withCalculatedProps,
    withLoading,
    withAndroidUpdateMutation,
    handlers,
    pure,
)(toClass(AndroidUpdateSelectPure));

AndroidUpdateSelect.propTypes = {
    contractNumber: PropTypes.string,
    supportChannels: PropTypes.array,
};

export {
    AndroidUpdateSelect as default,
    EMPTY_UPDATE,
};