import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Button, Col, ControlLabel, FormControl, FormGroup, Panel, Row} from 'react-bootstrap';
import {compose, pure, withHandlers, withState} from 'recompose';
import jsRoutes from '../../routes/routes';
import {isICCIDValid} from '../../utils/functions';
import {SIM_STATES} from '../../utils/Sim';
import ICCIDField from '../components/ICCIDField';
import LoadingProgress from '../components/LoadingProgress';

const withLoadingState = withState('loading', 'setLoading', false);
const withNeedActivationState = withState('needActivation', 'setNeedActivation', true);
const initialSimState = {ICCID: '', simFound: null, operator: ''};
const withNewSimState = withState('newSim', 'setNewSim', initialSimState);
const withPhoneState = withState('phone', 'setPhone', '');

const CreateSimPanelPure = ({
                              loading,
                              needActivation,
                              newSim,
                              phone,
                              handleSubmit,
                              setNeedActivation,
                              setNewSim,
                              setPhone,
                            }) => (
  <Panel bsStyle='success'>
    <Panel.Heading>Ajouter une SIM</Panel.Heading>
    <Panel.Body>
      <LoadingProgress show={loading}/>
      <Row>
        <Col md={4}>
          {newSim.simFound &&
          <Alert bsStyle='warning'>Cet ICCID existe déjà en base, merci de vérifier votre saisie.</Alert>}
          <ICCIDField
            SIM={newSim}
            onChange={(newSim) => setNewSim(newSim)}
          />
        </Col>
        <Col md={2}>
          <FormGroup>
            <ControlLabel>Téléphone</ControlLabel>
            <FormControl type='text' value={phone}
                         onChange={(event) => setPhone(event.target.value)}/>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <ControlLabel>Envoyer une demande d'activation</ControlLabel>
            <FormControl componentClass='select'
                         value={needActivation}
                         onChange={(event) => setNeedActivation(event.target.value)}>
              <option value={true}>Oui</option>
              <option value={false}>Non</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col md={1}>
          <FormGroup>
            <ControlLabel>Valider</ControlLabel>
            <Button bsStyle='success' bsSize='small'
                    onClick={handleSubmit}
                    disabled={!isICCIDValid(newSim.ICCID) || newSim.simFound}>
              Valider
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Panel.Body>
  </Panel>
);

CreateSimPanelPure.propTypes = {
  loading: PropTypes.bool,
  needActivation: PropTypes.bool,
  newSim: PropTypes.object,
  phone: PropTypes.string,
  handleSubmit: PropTypes.func,
  setNeedActivation: PropTypes.func,
  setNewSim: PropTypes.func,
  setPhone: PropTypes.func,
};

const getCreateSimRequest = (sim) => (
  jsRoutes.controllers.backoffice.SupportV2Controller.createSim(sim.ICCID)
    .ajax({
      data: JSON.stringify(sim),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      processData: false,
    })
);

const withSubmitHandler = withHandlers({
  handleSubmit: ({
                   need_activation,
                   newSim,
                   phone,
                   afterCreateSim,
                   setLoading,
                   setNeedActivation,
                   setNewSim,
                   setPhone,
                 }) => () => {
    if (newSim.simFound) {
      alert('Dude, you already have registered this SIM !');
    } else {
      const simOperator = newSim.operator || 'LPM';
      let sim = {
        ICCID: newSim.ICCID,
        operator: simOperator,
        phone: phone,
      };

      if (need_activation === true) {
        sim.currentState = {
          state: SIM_STATES.NEED_ACTIVATION.value,
          timestamp: moment().valueOf(),
        };
      } else {
        sim.currentState = {
          state: SIM_STATES.NOT_ACTIVATED.value,
          timestamp: moment().valueOf(),
        };
      }

      setLoading(true, () => {
        getCreateSimRequest(sim).then(
          async (response) => {
            await setLoading(false);
            await setNeedActivation(true);
            await setNewSim(initialSimState);
            await setPhone('');
            afterCreateSim(response);

            if (response.alreadyExists === true) {
              alert('Dude, you already have registered this SIM !');
            }
          }, () => setLoading(false));
      });
    }
  },
});

const CreateSimPanel = compose(
  withLoadingState,
  withNeedActivationState,
  withNewSimState,
  withPhoneState,
  withSubmitHandler,
  pure,
)(CreateSimPanelPure);

CreateSimPanel.propTypes = {
  afterCreateSim: PropTypes.func,
};

export default CreateSimPanel;