import gql from 'graphql-tag';

const ANDROID_UPDATE_FRAGMENT = gql`
    fragment androidUpdateFields on AndroidUpdate {
        apps
        assignableBySupport
        created
        id
        kind
        label
        lastModified
        name
    }
`;

const GET_ALL_SUPPORT_ANDROID_UPDATE = gql`
    query getAllSupportAndroidUpdate {
        androidUpdates(input: {}) {
            androidUpdates {
                apps...androidUpdateFields
            }
        }
    }
    ${ANDROID_UPDATE_FRAGMENT}
`;

const UPDATE_ACCOUNT_METADATA_SUPPORT_CHANNELS = gql`
    mutation UpdateAccountMetadataSupportChannels($input: AccountMetadataUpdateSupportChannelsInput!) {
        accountMetadataUpdateSupportChannels(input: $input) {
            supportChannels
        }
    }
`;

export {
    ANDROID_UPDATE_FRAGMENT,
    GET_ALL_SUPPORT_ANDROID_UPDATE,
    UPDATE_ACCOUNT_METADATA_SUPPORT_CHANNELS,
}