import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';

import { connectTypesAndWebUserContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { createTicketPropertySorter, getSelectedTicketActivePage } from '../../../utils/tickets';

import TicketsTableHead from './TicketsTableHead';
import TicketsTableBody from './TicketsTableBody';
import TicketsTablePagination from './TicketsTablePagination';

export const PAGINATION = {
	BOTH: 'both',
	TOP: 'top',
	BOTTOM: 'bottom',
	NONE: 'none', // or simply use false
}

const TicketsTable = connectTypesAndWebUserContext(({
	webUserJson,
	types,
	tickets = [],
	properties = [],
	formatTicket = ticket => ticket,
	loading,
	noFilters,
	selectedTicket,
	tagList,
	actions,
	pagination = PAGINATION.BOTH,
	defaultSort = {name: 'created', reverse: true}
}) => {

	const [sort, setSort] = useState(defaultSort);
	const [filters, setFilters] = useState(null);

	const createSortHandler = propertyName => () => {
    setSort(sort => sort && sort.name === propertyName && sort.reverse ? null : {
			name: propertyName,
			reverse: sort?.name === propertyName ? !sort.reverse : false
		});
  }

	const filteredTickets = useMemo(() => {

		const isFiltered = entry => {
			if (!filters) return true;

			for (var property in filters) {
				
				const filterValue = filters[property];
				let entryHasFilterValue;

				if (property === 'tags') {
					if (filterValue && filterValue.length > 0) {
						entryHasFilterValue = entry.hasOwnProperty(property) &&
																				entry[property] &&
																				filterValue.every(data => entry[property].indexOf(data.value) > -1); // eslint-disable-line no-loop-func
						
						if (!entryHasFilterValue) return false;
					}
				} else {
					const revertFilter = filterValue && filterValue.toString().indexOf('$') === 0;
					const cleanedValue = revertFilter ? filterValue.substring(1) : filterValue;

					switch (property) {
						case 'created':
						case 'resultTimestamp':
						case 'takeActionDay':
						case 'noLaterThanDay':
							entryHasFilterValue = entry.hasOwnProperty(property) && entry[property] && moment(entry[property]).isSame(cleanedValue, 'day');
							break;
						
						case 'state':
						case 'subjectLabel':
							entryHasFilterValue = entry.hasOwnProperty(property) && entry[property] && entry[property].toString() === cleanedValue;
							break;

						default:
							entryHasFilterValue = entry.hasOwnProperty(property) && entry[property] && entry[property].toString().toLocaleLowerCase().indexOf(cleanedValue.toLocaleLowerCase()) !== -1;
							if (property === 'content' && !entryHasFilterValue) {
								entryHasFilterValue = entry.lastAnswer && entry.lastAnswer?.content && entry.lastAnswer?.content.toString().toLocaleLowerCase().indexOf(cleanedValue.toLocaleLowerCase()) !== -1;
							}
							break;
					}
					if ((entryHasFilterValue && revertFilter) || (!entryHasFilterValue && !revertFilter)) {
						return false;
					}
				}
			}
			return true;
		}
		
		return tickets.map(formatTicket).sort(createTicketPropertySorter(sort)).filter(isFiltered);
	}, [filters, formatTicket, sort, tickets]);

	const itemsByPage = 8;

	let totalPages = 1;
	if (filteredTickets.length > 0) {
		totalPages = Math.ceil(filteredTickets.length / itemsByPage);
	}
	
	const selectedTicketID = selectedTicket?.id;
	const selectedTicketActivePage = getSelectedTicketActivePage(filteredTickets, selectedTicketID, itemsByPage);
	const [activePage, setActivePage] = useState(selectedTicketActivePage || 1);

	useEffect(() => {
		setActivePage(selectedTicketActivePage || 1);
	}, [selectedTicketActivePage]);

	return <>
		{[PAGINATION.BOTH, PAGINATION.TOP].includes(pagination) && (
			<TicketsTablePagination
				totalPages={totalPages}
				activePage={activePage}
				setActivePage={setActivePage}
			/>
		)}

		<Table striped bordered condensed hover>
			<TicketsTableHead
				properties={properties}
				sort={sort}
				createSortHandler={createSortHandler}
				actionsHeader={!!actions}
			/>

			<TicketsTableBody
				properties={properties}
				loading={loading}
				noFilters={noFilters}
				filteredTickets={filteredTickets}
				selectedTicketID={selectedTicketID}
				tagList={tagList}
				activePage={activePage}
				setActivePage={setActivePage}
				filters={filters}
				setFilters={setFilters}
				actions={actions}
			/>
		</Table>

		{[PAGINATION.BOTH, PAGINATION.BOTTOM].includes(pagination) && (
			<TicketsTablePagination
				totalPages={totalPages}
				activePage={activePage}
				setActivePage={setActivePage}
			/>
		)}
	</>;
});

export default TicketsTable;