import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import TransferTicketModal from './TransferTicketModal';

const TransferTicketModalButton = ({ initialTicket = {}, setCurrentTicket, disabled, hidden, initialTeam, teamOptions, ticket = {}, handleTicketPropertyChange, handleDateChange, handleTransferTicket }) => {
	const [show, setShow] = useState(false);

	if (hidden) return null;
	
	const handleShow = () => setShow(true);
	
	const handleHide = () => {
		setCurrentTicket(initialTicket);
		setShow(false);
	}

	const handleSubmit = () => {
		handleTransferTicket();
		setShow(false);
	}

	const handleTeamChange = e => handleTicketPropertyChange('team', e);

	return <>
		<Button bsStyle='warning' className='pull-right' disabled={disabled} onClick={handleShow}>
			Transférer
    </Button>
		<TransferTicketModal
			show={show}
			onHide={handleHide}
			initialTeam={initialTicket.team}
			teamOptions={teamOptions}
			ticket={ticket}
			handleTeamChange={handleTeamChange}
			handleDateChange={handleDateChange}
			onSubmit={handleSubmit}
		/>
	</>;
}

TransferTicketModalButton.propTypes = {
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	initialTeam: PropTypes.object,
	setCurrentTicket: PropTypes.func,
	teamOptions: PropTypes.array,
	ticket: PropTypes.object,
	handleTicketPropertyChange: PropTypes.func,
	handleDateChange: PropTypes.func,
	handleTransferTicket: PropTypes.func,
};

export default TransferTicketModalButton;