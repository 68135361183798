import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, Panel, Row} from 'react-bootstrap';
import {tsToDateForInput} from '../../../../../utils/Date';

const format = (value, accessor) => {
  if (accessor === 'Date Créa') {
    return value ? tsToDateForInput(value) : '';
  }
  return value;
};

const filterColumns = order => ({accessor}) =>
  !order?._original?.prestations.lmr ||
  !['DAPO', 'Livraison', 'FS+'].includes(accessor);

const OrderSubEntry = ({columns, order}) => (
  <Panel bsStyle='info' style={{padding: '20px'}}>
    {
      columns
        .filter(filterColumns(order))
        .map(column => (
          <Row key={column.accessor}>
            <Col md={3}><ControlLabel>{column.Header}</ControlLabel></Col>
            <Col md={8}>{format(order[column.accessor], column.accessor)}</Col>
          </Row>
        ))
    }
  </Panel>
);

OrderSubEntry.propTypes = {
  columns: PropTypes.array,
  order: PropTypes.object,
};

export default OrderSubEntry;