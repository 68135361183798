import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {compose, getContext, pure, withHandlers, withProps, withState} from 'recompose';

import Stepper from '../../../../../components/Stepper/Stepper';
import withStepper from '../../../../../components/Stepper/withStepper';
import SepaForm from '../Sepa/SepaForm';
import SubscriberForm from '../Sepa/SubscriberForm';
import SubscriptionStateForm from './SubscriptionStateForm';

const STEPS = {
  SUBSCRIPTION_STATE: 0,
  SUBSCRIBER: 1,
  SEPA: 2,
};

const SubscriptionStateAndSubscriberFormPure = ({
                                                  currentStep,
                                                  currentSubscriber,
                                                  isACreation,
                                                  mode,
                                                  showStepDownButton,
                                                  showStepUpButton,
                                                  steps,
                                                  subscriber,
                                                  subscription,
                                                  handleSaveBankInfos,
                                                  handleSelectCurrentSubscriptionType,
                                                  handleStepUp,
                                                  handleSubmitSubscriber,
                                                  setMode,
                                                  setSepaFormEl,
                                                  setSubscriber,
                                                  setSubscriberFormEl,
                                                  setSubscriptionState,
                                                  stepDown,
                                                }) => (
  <>
    <Stepper current={currentStep}
             steps={steps}/>

    {currentStep === STEPS.SUBSCRIPTION_STATE &&
    <SubscriptionStateForm currentSubscriber={currentSubscriber}
                           isACreation={isACreation}
                           mode={mode}
                           subscription={subscription}
                           handleSelectCurrentSubscriptionType={handleSelectCurrentSubscriptionType}
                           setMode={setMode}
                           setSubscriptionState={setSubscriptionState}/>
    }

    {currentStep === STEPS.SUBSCRIBER &&
    <SubscriberForm hideSubmitButton
                    isACreation={isACreation}
                    subscriber={subscriber}
                    handleSaveSubscriber={handleSubmitSubscriber}
                    setFormEl={setSubscriberFormEl}
                    setSubscriber={setSubscriber}/>
    }

    {currentStep === STEPS.SEPA &&
    <SepaForm hideSubmitButton
              subscriber={subscriber}
              handleSaveBankInfos={handleSaveBankInfos}
              setFormEl={setSepaFormEl}/>
    }

    <Modal.Footer>
      {showStepDownButton &&
      <Button bsStyle="primary"
              className="pull-left"
              onClick={stepDown}>Précédent</Button>
      }

      {showStepUpButton &&
      <Button bsStyle="primary"
              type="submit"
              onClick={handleStepUp}>Suivant</Button>
      }

      {!showStepUpButton &&
      <Button bsStyle="success"
              onClick={handleStepUp}>Valider</Button>
      }
    </Modal.Footer>
  </>
);

SubscriptionStateAndSubscriberFormPure.propTypes = {
  currentStep: PropTypes.number,
  currentSubscriber: PropTypes.object,
  isACreation: PropTypes.bool,
  mode: PropTypes.number,
  showStepDownButton: PropTypes.bool,
  showStepUpButton: PropTypes.bool,
  steps: PropTypes.array,
  subscription: PropTypes.object,
  handleSaveBankInfos: PropTypes.func,
  handleSelectCurrentSubscriptionType: PropTypes.func,
  handleStepUp: PropTypes.func,
  handleSubmitSubscriber: PropTypes.func,
  setMode: PropTypes.func,
  setSepaFormEl: PropTypes.func,
  setSubscriberFormEl: PropTypes.func,
  setSubscriptionState: PropTypes.func,
  stepDown: PropTypes.func,
};

const withSepaFormElState = withState('sepaFormEl', 'setSepaFormEl', null);
const withSubscriberState = withState('subscriber', 'setSubscriber', ({subscriptionState}) => subscriptionState || {});
const withSubscriberFormElState = withState('subscriberFormEl', 'setSubscriberFormEl', null);

const handlers = withHandlers({
  handleSaveBankInfos: ({subscriber, subscriptionState, handleSubmitSubscriptionState}) => ({formData}) =>
    handleSubmitSubscriptionState({
      ...subscriptionState,
      bankID: {
        ...formData,
        DateSignature: moment(formData?.DateSignature)
          .startOf('day')
          .valueOf(),
      },
      current: {
        ...subscriber.current,
        isAnUpdateOfCurrentCustomer: false,
      },
      isASubscriptionState: true,
    }),
  handleStepUp: ({currentStep, sepaFormEl, subscriberFormEl, stepUp}) => () => {
    if (currentStep === STEPS.SUBSCRIPTION_STATE) {
      stepUp();
    } else if (currentStep === STEPS.SUBSCRIBER) {
      subscriberFormEl.submit();
    } else if (currentStep === STEPS.SEPA) {
      sepaFormEl.submit();
    }
  },
  handleSubmitSubscriber: ({stepUp}) => ({formData}) => {
    stepUp();
  },
});

const SubscriptionStateAndSubscriberForm = compose(
  getContext(
    {
      subscriptionState: PropTypes.object,
    },
  ),
  withProps(
    () => ({
      initialState: STEPS.SUBSCRIBER,
      steps: [{title: 'Abonnement'}, {title: 'Souscripteur'}, {title: 'SEPA'}],
    }),
  ),
  withStepper,
  withSepaFormElState,
  withSubscriberState,
  withSubscriberFormElState,
  handlers,
  pure,
)(SubscriptionStateAndSubscriberFormPure);

SubscriptionStateAndSubscriberForm.propTypes = {
  currentSubscriber: PropTypes.object,
  isACreation: PropTypes.bool,
  mode: PropTypes.number,
  subscription: PropTypes.object,
  handleSelectCurrentSubscriptionType: PropTypes.func,
  handleSubmitSubscriptionState: PropTypes.func,
  setMode: PropTypes.func,
  setSubscriptionState: PropTypes.func,
};

export default SubscriptionStateAndSubscriberForm;