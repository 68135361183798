import gql from 'graphql-tag';

export const B2B_FEATURE_FRAGMENT = gql`
    fragment b2bFeatureFields on B2BFeature {
        id
        description
        label
    }
`;

export const B2B_FEATURE_RECORD_FRAGMENT = gql`
    fragment b2bFeatureRecordFields on FeatureRecord {
        enabled
        end
        feature {
            id
            label
        }
        start
    }
`;

export const GET_B2BFEATURES_QUERY = gql`
    query GetB2BFeatures {
        b2bFeatures {
            b2bFeatures {
                ...b2bFeatureFields
            }
        }
    }
    ${B2B_FEATURE_FRAGMENT}
`;

export const GET_B2B_SUBSCRIPTIONS_FOR_CONTRACTS_MUTATION = gql`
    query GetB2BSubscriptionsForContracts($input: GetB2BSubscriptionsInput!) {
        getB2BSubscriptionsForContracts(input: $input) {
            b2bSubscriptionsOrError {
                __typename
                ... on B2BSubscription {
                    contractNumber
                    featureRecords {
                        ...b2bFeatureRecordFields
                    }
                }
                ... on GetB2BSubscriptionError {
                    message
                    type
                }
            }
        }
    }
    ${B2B_FEATURE_RECORD_FRAGMENT}
`;

export const GetB2BSubscriptionErrorType = {
    MALFORMED_CONTRACT_NUMBER: 'MALFORMED_CONTRACT_NUMBER',
    NOT_FOUND: 'NOT_FOUND',
};

export const CREATE_B2BFEATURE_MUTATION = gql`
    mutation CreateB2BFeature($createFeatureInput: CreateFeatureInput!) {
        createB2BFeature(createFeatureInput: $createFeatureInput) {
            __typename
            ... on B2BFeatureCreationError {
                message
                type
            }
            ... on CreateFeatureOutput {
                feature {
                    ...b2bFeatureFields
                }
            }
        }
    }
    ${B2B_FEATURE_FRAGMENT}
`;

export const B2BFeatureCreationErrorType = {
  CANNOT_CREATE: 'CANNOT_CREATE',
  ID_COLLISION: 'ID_COLLISION',
  LABEL_IS_BLANK: 'LABEL_IS_BLANK',
};

export const DELETE_B2BFEATURE_MUTATION = gql`
    mutation DeleteB2BFeature($deleteFeatureInput: DeleteFeatureInput!) {
        deleteB2BFeature(deleteFeatureInput: $deleteFeatureInput) {
            __typename
            ... on B2BFeatureDeletionError {
                message
                type
            }
            ... on DeleteFeatureOutput {
                id
            }
        }
    }
`;

export const B2BFeatureDeletionErrorType = {
  CANNOT_DELETE: 'CANNOT_DELETE',
  ID_NOT_FOUND: 'ID_NOT_FOUND',
};

export const ADD_B2B_FEATURES_ON_CONTRACTS_MUTATION = gql`
    mutation AddB2BFeaturesOnContracts($input: AddB2BFeaturesOnContractsInput!) {
        addB2BFeaturesOnContracts(input: $input) {
            b2bSubscriptionsOrError {
                __typename
                ... on AddB2BFeaturesOnContractsError {
                    contractNumber
                    message
                    type
                }
                ... on B2BSubscription {
                    contractNumber
                }
            }

        }
    }
`;

export const AddB2BFeaturesOnContractErrorType = {
  CANNOT_ADD: 'CANNOT_ADD',
  CONTRACT_NOT_FOUND: 'CONTRACT_NOT_FOUND',
  INVALID_CONTRACT_NUMBER: 'INVALID_CONTRACT_NUMBER',
  INVALID_FEATURE_ID: 'INVALID_FEATURE_ID',
  MALFORMED_CONTRACT_NUMBER: 'MALFORMED_CONTRACT_NUMBER',
  CANNOT_DELETE: 'CANNOT_DELETE',
  ID_NOT_FOUND: 'ID_NOT_FOUND',
};