import jsRoutes from '../../../../../routes/routes';
import {isValideEmail} from '../../../../../utils/functions';
import {USER_TYPES} from './ClientUser';

const {setBuyer, setMainUser, setSubscriber} = jsRoutes.controllers.backoffice.SupportV2Controller;

const saveUser = props => {
    const user = {...props.user},
        {formData} = props;

    user.gender = formData.gender;
    user.firstName = formData.firstName;
    user.lastName = formData.lastName;
    user.company = formData.company;
    user.addressLine = [
        formData.addressLine0 || '',
        formData.addressLine1 || '',
        formData.addressLine2 || '',
        formData.addressLine3 || '',
    ];
    user.zipCode = formData.zipcode;
    user.city = formData.city;
    user.country = formData.country;
    user.email = formData.email;
    user.phone = formData.phone;
    user.cellphone = formData.cellphone;

    validateAndSendData(user, props);
};

const validateAndSendData = (user, props) => {
    const validator = {...props.validator},
        {locked, setValidator} = props;
    const getError = value => !value && !locked ? 'error' : null;

    validator.gender = getError(user.gender);
    validator.firstName = getError(user.firstName);
    validator.lastName = getError(user.lastName);
    validator.addressLine0 = getError(user.addressLine[0]);
    validator.zipcode = getError(user.zipCode);
    validator.city = getError(user.city);
    validator.country = getError(user.country);
    validator.email = !!user.email && !isValideEmail(user.email) && !locked ? 'error' : null;

    const hasError = Object.values(validator).includes('error'),
        sendUserFunction = hasError ? () => {} : () => sendUser(user, props);

    setValidator(
        validator,
        sendUserFunction,
    );
};

const sendUser = (user, props) => {
    const {contractNumber, userType, setLoading, setLocked} = props;
    let route;
    if (userType === USER_TYPES.BUYER) {
        route = setBuyer(contractNumber);
    } else if (userType === USER_TYPES.SUBSCRIBER) {
        route = setSubscriber(contractNumber);
    } else {
        route = setMainUser(contractNumber);
    }

    setLoading(
        true,
        () => route.ajax({
            data: JSON.stringify(user),
            contentType: 'application/json; charset=utf-8',
            processData: false,
        }).then(
            () => setLoading(
                false,
                () => setLocked(true),
            ),
            () => setLoading(false),
        ),
    );
};

export default saveUser;