import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Glyphicon, Table } from 'react-bootstrap';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';

import jsRoutes from '../../../../../routes/routes';
import { tsToDateForInput } from '../../../../../utils/Date';
import withLoading from '../../../../../hoc/withLoading';

const MaintenanceV2Controller = jsRoutes.controllers.backoffice.MaintenanceV2Controller;

const STATUS_LABELS = {
    'GCM_NOT_RECEIVED': 'Ordre non reçu par la tablette (1/5)',
    'GCM_RECEIVED': 'Ordre reçu par la tablette (2/5)',
    'PULL_COMPLETE': 'Données reçues par la tablette (3/5)',
    'PUSH_COMPLETE': 'Données de la tablette reçues par le serveur (4/5)',
    'PUSH_RECEIVED_ON_SERVER': 'Test réussi (5/5)',
    'PULL_ERROR': 'Une erreur est survenue'
};

const SynchronizationTestPure = ({ loading, status, handleGetSynchronizationTestStatus, handleStartSynchronizationTest }) => (
    <Table striped bordered condensed hover>
        <thead>
            <tr>
                <th className='col-md-1' style={{ width: '20%' }} >Date de lancement</th>
                <th className='col-md-1' style={{ width: '20%' }} >Dernière modification</th>
                <th className='col-md-1' style={{ width: '20%' }} >Status</th>
                <th className='text-right' style={{ width: '40%' }} >
                    <ButtonToolbar>
                        <Button bsStyle='primary'
                                bsSize='xsmall'
                                onClick={handleStartSynchronizationTest}
                                disabled={loading}>
                            Tester la synchronization
                        </Button>
                        <Button bsStyle='primary' bsSize='xsmall'
                                onClick={handleGetSynchronizationTestStatus}
                                disabled={loading}>
                            <Glyphicon glyph='refresh'/>
                        </Button>
                    </ButtonToolbar>
                </th>
            </tr>
        </thead>
        <tbody>
        <tr>
            <td>{status ? tsToDateForInput(status.startTimestamp) : 'NA'}</td>
            <td>{status ? tsToDateForInput(status.lastModifiedTimestamp) : 'NA'}</td>
            <td>{status ? STATUS_LABELS[status.state] : 'NA'}</td>
            <td/>
        </tr>
        </tbody>
    </Table>
);

SynchronizationTestPure.propTypes = {
    loading: PropTypes.bool,
    status: PropTypes.object,
    handleGetSynchronizationTestStatus: PropTypes.func,
    handleStartSynchronizationTest: PropTypes.func,
};

const withStatusState = withState('status', 'setStatus', null);

const getSynchronizationTestStatus = (contractNumber) => MaintenanceV2Controller.getSynchronizationTestStatus(contractNumber)
    .ajax({
        dataType: 'json',
        processData: false
    });

const getSynchronizationTestStatusHandler = withHandlers({
    handleGetSynchronizationTestStatus: ({ contractNumber, setLoading, setStatus }) => () => {
        setLoading(
            false,
            () => getSynchronizationTestStatus(contractNumber).then(
                (response) =>
                    setStatus(
                        response,
                        () => setLoading(false)
                    ),
                () => setLoading(false),
            )
        );
    },
});

const startSynchronizationTest = (contractNumber) => MaintenanceV2Controller.startSynchronizationTest(contractNumber).ajax();

const startSynchronizationHandler = withHandlers({
    handleStartSynchronizationTest: ({ contractNumber, handleGetSynchronizationTestStatus, setLoading }) => () => {
        setLoading(
            false,
            () => startSynchronizationTest(contractNumber).then(
                () =>
                    setLoading(
                        false,
                        handleGetSynchronizationTestStatus
                    ),
                () => setLoading(false),
            )
        );
    },
});

const withLifecycle = lifecycle({
    componentDidMount() {
        this.props.handleGetSynchronizationTestStatus();
    },
});

const SynchronizationTest = compose(
    withLoading,
    withStatusState,
    getSynchronizationTestStatusHandler,
    startSynchronizationHandler,
    withLifecycle,
    pure,
)(SynchronizationTestPure);

SynchronizationTest.propTypes = {
    contractNumber: PropTypes.string,
};

export default SynchronizationTest;