import React from 'react';
import { Label } from 'react-bootstrap';

const TicketSubject = ({label = '', value}) => {

	let color = 'label-block label-' + value;
	const subjectLabel = label.replace(/_/g, '\n');

	return <Label className={color}>{subjectLabel}</Label>;
};

export default TicketSubject;