import PropTypes from 'prop-types';
import React from 'react';
import {InputGroup} from 'react-bootstrap';
import OriginalReactSelect from 'react-select';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';

const promptTextCreator = label => `Créer la valeur : ${label}`;

const ReactSelectPure = ({options, rawErrors, schema, SelectComponent, value, handleChange, onNewOptionClick}) => (
    <InputGroup bsSize='sm'
                className={`singleDatePickerInputGroup ${rawErrors?.length ? 'inError' : ''} center-block`}>
        <SelectComponent
            disabled={schema?.params?.disabled}
            multi
            onNewOptionClick={onNewOptionClick}
            onSelectResetsInput={false}
            onBlurResetsInput={false}
            value={value}
            onChange={handleChange}
            promptTextCreator={promptTextCreator}
            {...schema?.params}
            options={options}
        />
    </InputGroup>
);

ReactSelectPure.propTypes = {
    rawErrors: PropTypes.array,
    schema: PropTypes.object,
    SelectComponent: PropTypes.func,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onNewOptionClick: PropTypes.func,
};

const withCreatedOptions = withState('createdOptions', 'setCreatedOptions', ({value}) => value ? [{
    label: value,
    value,
}] : []);

const formatValue = (value, isMulti, isMultiFromString) => {
    let formattedValue = value;

    if (isMultiFromString) {
        formattedValue = value
            .map(_ => _.value)
            .join('|');
    } else if (!isMulti) {
        formattedValue = value?.pop()?.value;
    }

    return formattedValue;
};

const handlers = withHandlers({
    handleChange: ({isMulti, isMultiFromString, onChange}) => value => {
        const formattedValue = formatValue(value, isMulti, isMultiFromString);
        onChange(formattedValue);
    },
    /**
     * Keep created options in local state or they will be lost
     * on re-render
     */
    onNewOptionClick: ({createdOptions, isMulti, isMultiFromString, value, onChange, setCreatedOptions}) => option => {
        const formattedValue = formatValue([...(value || []), option], isMulti, isMultiFromString);
        setCreatedOptions(
            [...createdOptions, option],
            () => onChange(formattedValue),
        );
    },
});

const ReactSelect = compose(
    withCreatedOptions,
    withProps(
        ({createdOptions, schema, value}) => {
            const {options} = schema?.params;
            let initialOptions = [];

            if (Array.isArray(options)) {
                initialOptions = options;
            }

            const allOptions = [...createdOptions, ...initialOptions];
            const {isMultiFromString, multi} = schema?.params || {};
            let formattedValue = value;

            if (isMultiFromString && typeof value === 'string') {
                formattedValue = value
                    .split('|')
                    .map(_ => allOptions.find(option => option.value === _));
            }

            return {
                isMulti: multi,
                isMultiFromString,
                SelectComponent: schema?.params?.creatable ? OriginalReactSelect.Creatable : OriginalReactSelect,
                options: allOptions,
                value: formattedValue,
            };
        },
    ),
    handlers,
    pure,
)(ReactSelectPure);

ReactSelect.propTypes = {
    rawErrors: PropTypes.array,
    schema: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
};

export default ReactSelect;