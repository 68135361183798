import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormControl} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../hoc/withMeAndTeamsAndConstants';
import {TICKET_STATES} from '../../../../utils/constants';
import {getResultType} from '../../../../utils/functions';
import { getSortedResultFirstIntentionOptions, getSortedResultOptionsWithoutFirstIntentions, parseFirstIntention, parseResult } from '../../../../utils/types';
import { useMemo } from 'react';
import { isNotEmpty } from '../../../../utils/Array';

const ResultFieldPure = ({ subjectResultOptions, result, types, handleChange }) => {

  const resultValue = result && result !== '---' ? parseResult(result) : '---';
  const firstIntentionValue = result && result !== '---' ? parseFirstIntention(result) : '';
  
	const {sortedSubjectresultOptions, firstIntentionOptions} = useMemo(() => {
		return {
			sortedSubjectresultOptions: getSortedResultOptionsWithoutFirstIntentions(subjectResultOptions, types),
			firstIntentionOptions: getSortedResultFirstIntentionOptions(subjectResultOptions, types, resultValue)
		}
	}, [subjectResultOptions, types, resultValue]);

  return <>
    <Col md={2}>
      <ControlLabel>Resultat *</ControlLabel>
      <FormControl
        componentClass='select'
        value={resultValue}
        onChange={e => handleChange('result', e)}
        placeholder='Résultat'
      >
        <option value='---'>---</option>
        {sortedSubjectresultOptions.map(type_cloture => (
          <option key={type_cloture} value={type_cloture}>
            {getResultType(types, type_cloture)}
          </option>
        ))}
      </FormControl>
    </Col>
    {isNotEmpty(firstIntentionOptions) && <Col md={2}>
      <ControlLabel>Première intention</ControlLabel>
      <FormControl
        componentClass='select'
        data-test-id='firstIntentionSelect'
        value={firstIntentionValue}
        onChange={e => handleChange('result', e)}
        placeholder='Première intention'
      >
        <option value=''>---</option>
        
        {firstIntentionOptions.map(firstIntention => (
          <option key={firstIntention} value={firstIntention}>
            {getResultType(types, firstIntention)}
          </option>
        ))}
      </FormControl>
    </Col>}
  </>;
}

ResultFieldPure.propTypes = {
  subjectResultOptions: PropTypes.array,
  result: PropTypes.string,
  types: PropTypes.object,
  handleChange: PropTypes.func,
};

const ResultField = compose(
  connectMeAndTeamsAndConstantsContext,
  branch(
    ({state}) => state !== TICKET_STATES.DONE,
    renderNothing,
  ),
  pure,
)(ResultFieldPure);

ResultField.propTypes = {
  subjectResultOptions: PropTypes.array,
  result: PropTypes.string,
  state: PropTypes.string,
  handleChange: PropTypes.func,
};

export default ResultField;