import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, Row} from 'react-bootstrap';
import {branch, compose, pure, renderComponent, renderNothing} from 'recompose';
import BreakageInsuranceLabel from './ContractInfo/BreakageInsuranceLabel';
import ConnectionLabel from './ContractInfo/ConnectionLabel';
import InitializedLabel from './ContractInfo/InitializedLabel';
import MoreInfoBlock from './ContractInfo/MoreInfoBlock';
import ShopInfoBlock from './ContractInfo/ShopInfoBlock';
import SubscriptionLabel from './ContractInfo/SubscriptionLabel';
import SubscriptionSoftwareLabel from './ContractInfo/SubscriptionSoftwareLabel';
import TagsLabel from './ContractInfo/TagsLabel';
import WarrantyLabel from './ContractInfo/WarrantyLabel';

const ContractInfoPure = ({contract, modal}) => (
    <>
        <p>
            <big><InitializedLabel initialized={contract.initialized}/></big>&nbsp;
            <big><SubscriptionLabel cancelled={contract.cancelled} subscription={contract.subscription}/></big>
        </p>
        <p>
            <WarrantyLabel subscription={contract.subscription}/>&nbsp;
            <BreakageInsuranceLabel breakageInsurance={contract.breakageInsurance}/>
        </p>
        <p><TagsLabel contractTags={contract.tags}/></p>
        <p><ConnectionLabel is3G={contract.is3G}/></p>
        <p><SubscriptionSoftwareLabel isProAbono={contract.contractMetadata?.hasToBeSyncInProAbono}/></p>
        <Row>
            <Col md={6}>
                <ControlLabel>Numéro de contrat</ControlLabel>
                <p>{contract.contractNumber}</p>
            </Col>
            <Col md={6}>
                <ControlLabel>Type de contrat</ControlLabel>
                <p>{contract.type}</p>
            </Col>
        </Row>
        <ShopInfoBlock contract={contract}/>
        <MoreInfoBlock contract={contract}
                       modal={modal}/>
    </>
);

const ContractInfoModalPure = ({contract, modal}) => contract?.contractNumber !== '00000000' && (
    <Row className='vertical-align'>
        <Col md={4}>
            <ConnectionLabel is3G={contract.is3G}/>
            &nbsp;<MoreInfoBlock contract={contract}
                                 modal={modal}/>
        </Col>
        <Col md={3}>
            <InitializedLabel initialized={contract.initialized}/>
            &nbsp;<SubscriptionLabel cancelled={contract.cancelled}
                                     subscription={contract.subscription}/>
        </Col>
        <Col md={5}>
            <WarrantyLabel subscription={contract.subscription}/>
        </Col>
        <Col md={5}>
            <TagsLabel contractTags={contract.tags}/>
        </Col>
    </Row>
);

const ContractInfo = compose(
    pure,
    branch(
        ({contract}) => !contract,
        renderNothing,
    ),
    branch(
        ({modal}) => modal,
        renderComponent(ContractInfoModalPure),
    ),
)(ContractInfoPure);

ContractInfo.propTypes = {
    contract: PropTypes.object,
    modal: PropTypes.bool,
};

export default ContractInfo;