import React from 'react';
import {emptyFunction} from '../../../../../../../utils/functions';
import createConfirmationDialog from '../../../../../../components/ConfirmationDialog/createConfirmationDialog';
import {EMPTY_UPDATE} from '../AndroidUpdateSelect';

const handleSupportChannelChange = ({channelOptions, updateAccountMetadataSupportChannels, ...props}) => (value) => {
    const updateSupportChannel = () => {
        const supportChannels = value === EMPTY_UPDATE.id ? [] : [value];
        updateAccountMetadataSupportChannels(supportChannels, props);
    };

    if (value !== EMPTY_UPDATE.id) {
        const channel = channelOptions.find(_ => _.id === value);
        createConfirmationDialog(
            <div>Le patch {channel.name} sera rattaché à la tablette. Utilisez le bouton d'ordre de maintenance pour
                déclencher la mise à jour.<br/><br/>
                Souhaitez-vous valider cette modification ?
            </div>
        ).then(
            updateSupportChannel,
            emptyFunction
        );
    } else {
        updateSupportChannel();
    }
};

export default handleSupportChannelChange;