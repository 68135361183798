import {notify} from 'react-notify-toast';

const DEFAULT_TIMEOUT = 3000;

const errorMsg = message => notify.show(message, 'error', DEFAULT_TIMEOUT);

const successMsg = message => notify.show(message, 'success', DEFAULT_TIMEOUT);

export {
    errorMsg,
    successMsg,
};