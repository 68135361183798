import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { SingleDatePicker, StateSelect, SubjectSelect, TagsSelect } from '../../components';

const TicketsTableFilterInput = ({ name, value, onChange, ...props }) => {

	let filterInput;
	switch (name) {
		
		case 'created':
		case 'resultTimestamp':
		case 'takeActionDay':
		case 'noLaterThanDay':
			filterInput = (
				<SingleDatePicker
					className='filter-date-picker'
					value={value}
					onChange={value => onChange({target: {value}})}
					schema={{params: {isOutsideRange: () => false}}}
					{...props}
				/>
			);
			break;
		
		case 'state':
			filterInput = (
				<StateSelect
					value={value}
					onChange={onChange}
					{...props}
				/>
			);
			break;

		case 'subjectLabel':
			filterInput = (
				<SubjectSelect
					value={value}
					onChange={onChange}
					{...props}
				/>
			);
			break;

		case 'tags':
			filterInput = (
				<TagsSelect
					value={value}
					onChange={onChange}
					{...props}
				/>
			);
			break;
	
		default:
			filterInput = (
				<FormGroup className='small-control' bsSize='small'>
					<FormControl
						type='text'
						value={value ?? ''}
						onChange={onChange}
						{...props}
					/>
				</FormGroup>
			);
			break;
	}

	return (
		<th key={'property_filter_' + name}>
			{filterInput}
		</th>
	);
};

export default TicketsTableFilterInput;