import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

const CONTRACT_HISTORY_PROPERTIES = [
	{
		name: 'Contrat',
		key: 'contractNumber',
		size: '33%'
	},
	{
		name: 'Attachée le',
		key: 'attached',
		size: '33%'
	},
	{
		name: 'Détachée le',
		key: 'detached',
		size: '33%'
	},
];

const ContractHistoryModal = ({ contractHistory = [], show = false, handleClose }) => (
	<Modal show={show} onHide={handleClose}>
		<Modal.Header>
			<Modal.Title>Historique</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<div>
				<Table striped bordered condensed hover>
					<thead>
						<tr>
							{CONTRACT_HISTORY_PROPERTIES.map(({name, size, ...property}) => (
								<th style={{ width: size }} key={'property_header_' + name}>
									{name}&nbsp;
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{contractHistory.map((device, index) => (
							<tr key={index}>
								{CONTRACT_HISTORY_PROPERTIES.map(({key, ...property}) => (
									<td key={key}> {device[key]} </td>
								))}
							</tr>
						))}
					</tbody>
				</Table>

			</div>
		</Modal.Body>
		<Modal.Footer>
			<Button bsStyle='success' onClick={handleClose}>
				Ok
			</Button>
		</Modal.Footer>
	</Modal>
);

export default ContractHistoryModal;