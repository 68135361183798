import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withHandlers} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {isN2OrHigher} from '../../../utils/functions';
import {SIM_OPERATORS} from '../../../utils/Sim';
import {connectSearchSimContext} from './Helpers';

const ChangeOperatorModalPure = ({
                                   callback4OperatorEdition,
                                   changeSIMOperator,
                                   onHideEditOperatorClick,
                                   setChangeSIMOperator,
                                   onSubmitChangeSIMOperatorClick,
                                 }) => (
  <Modal show={!!callback4OperatorEdition}
         onHide={onHideEditOperatorClick}>
    <Modal.Header closeButton>
      <Modal.Title>Changer l'opérateur</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormGroup>
        <ControlLabel>Opérateur</ControlLabel>
        <FormControl componentClass='select'
                     value={changeSIMOperator}
                     onChange={e => setChangeSIMOperator(e.target.value)}>
          {Object.values(SIM_OPERATORS).map(operator => <option key={operator.name}
                                                                value={operator.name}>{operator.name}</option>)}
        </FormControl>
      </FormGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle='danger' className='pull-left'
              onClick={onSubmitChangeSIMOperatorClick}>Changer</Button>
      <Button bsStyle='success' onClick={onHideEditOperatorClick}>Annuler</Button>
    </Modal.Footer>
  </Modal>
);

ChangeOperatorModalPure.propTypes = {
  changeSIMOperator: PropTypes.string,
  callback4OperatorEdition: PropTypes.func,
  onHideEditOperatorClick: PropTypes.func,
  setChangeSIMOperator: PropTypes.func,
  onSubmitChangeSIMOperatorClick: PropTypes.func,
};

const handlers = withHandlers({
  onHideEditOperatorClick: ({setCallback4OperatorEdition, setChangeSIMOperator}) => async () => {
    await setCallback4OperatorEdition(null);
    await setChangeSIMOperator('null');
  },
  onSubmitChangeSIMOperatorClick: ({
                                     callback4OperatorEdition,
                                     changeSIMOperator,
                                   }) => () => callback4OperatorEdition(changeSIMOperator),
});

const ChangeOperatorModal = compose(
  connectMeAndTeamsAndConstantsContext,
  branch(
    ({webUserJson}) => !isN2OrHigher(webUserJson),
    renderNothing,
  ),
  connectSearchSimContext,
  handlers,
  pure,
)(ChangeOperatorModalPure);

ChangeOperatorModal.propTypes = {};

export default ChangeOperatorModal;