import React from 'react';
import ReactTable from 'react-table';
import { Button, ButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {compose, pure, withProps, withState} from 'recompose';
import {graphql} from 'react-apollo';

import withLoading from '../../../../../hoc/withLoading';
import { connectMeAndTeamsAndConstantsContext } from '../../../../../hoc/withMeAndTeamsAndConstants';
import withPagination from '../../../../../hoc/withPagination';
import {AFTERSALE_STATUS, getOrderNumberFromAfterSaleId, RETURN_TYPES} from '../../../../../utils/AfterSale';
import {tsToDateForInput} from '../../../../../utils/Date';
import {
    filterDateColumn,
    filterTextColumn,
    navigateToTicket,
} from '../../../../../utils/functions';
import {AFTERSALES_BY_CONTRACTNUMBER_QUERY} from '../../../../../schema/AfterSaleSchema';
import CancelAfterSale from './AfterSales/CancelAfterSale';
import './AfterSales.css';

const ITEMS_PER_PAGE = 4;
const getPagedAfterSales = (afterSales, activePage) => afterSales.slice((activePage - 1) * ITEMS_PER_PAGE, (activePage) * ITEMS_PER_PAGE);

const getColumns = (props) => ([
    {
        Header: 'N°',
        accessor: 'orderNumber',
        width: 40,
        filterMethod: (filter, row) => filterTextColumn('id', row, filter.value),
    }, {
        Header: 'Date Créa',
        accessor: 'metadata.created',
        type: 'date',
        width: 80,
        Cell: cell => <div>{cell.value ? tsToDateForInput(cell.value) : null}</div>,
        filterMethod: (filter, row) => filterDateColumn('metadata.created', row, filter.value),
    }, {
        Header: 'Type',
        accessor: 'afterSaleRequest.returnType',
        width: 160,
        Cell: cell => <div>{Object.values(RETURN_TYPES).find(r => r.value === cell.value).label}</div>,
        filterMethod: (filter, row) => filterTextColumn('subjectLabel', row, filter.value),
    }, {
        Header: 'Numéro de série',
        accessor: 'afterSaleRequest.serialNumber',
        width: 120,
        filterMethod: (filter, row) => filterTextColumn('serialNumber', row, filter.value),
    }, {
        Header: 'Etat de la demande',
        accessor: 'currentStatus',
        width: 130,
        Cell: cell => <div>{Object.values(AFTERSALE_STATUS).find(r => r.value === cell.value).label}</div>,
        filterMethod: (filter, row) => filterTextColumn('stateLabel', row, filter.value),
    }, {
        Header: 'Date Maj',
        accessor: 'metadata.lastModified',
        type: 'date',
        width: 80,
        Cell: cell => <div>{cell.value ? tsToDateForInput(cell.value) : null}</div>,
        filterMethod: (filter, row) => filterDateColumn('metadata.lastModified', row, filter.value),
    }, {
        Header: '',
        accessor: 'id',
        width: 85,
        Cell: entry => getActionButtons(entry, props),
        Filter: () => (
            <Button bsSize='sm' bsStyle='primary' onClick={() => props.setFiltered([])}>Effacer</Button>
        )
    }
]);

const getActionButtons = ({ row }, { contractNumber, setCurrentEditedAfterSale }) => (
    <ButtonGroup vertical block>
        <Button bsStyle='primary' bsSize='xsmall' onClick={() => setCurrentEditedAfterSale(row._original)}>Editer</Button>
        <Button bsStyle='warning' bsSize='xsmall' onClick={() => navigateToTicket(contractNumber, row._original.automaticTicketNumber)}>Ticket</Button>
        <CancelAfterSale afterSale={row._original}/>
    </ButtonGroup>
);

const AfterSalesPure = ({ activePage, columns, filtered, afterSales, setFiltered }) => (
    <ReactTable
        fill
        filterable
        data={getPagedAfterSales(afterSales, activePage)}
        columns={columns}
        resizable={false}
        pageSize={(afterSales && afterSales.length >= ITEMS_PER_PAGE) ? ITEMS_PER_PAGE : afterSales.length}
        showPagination={false}
        style={{
            height: `${afterSales.length ? ITEMS_PER_PAGE * 50 + 200 : 230}px`,
            border: 'none',
        }}
        className='accountAfterSale -striped'
        getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
        onFilteredChange={newFiltered => setFiltered(newFiltered)}
        filtered={[...filtered]}
    />
);

AfterSalesPure.propTypes = {
    filtered: PropTypes.array,
    afterSales: PropTypes.array,
    setFiltered: PropTypes.func,
};

const withAfterSalesState = withState('afterSales', 'setAfterSales', []);

const withFilteredState = withState('filtered', 'setFiltered', []);

const withData = graphql(AFTERSALES_BY_CONTRACTNUMBER_QUERY, {
    options: ({ contractNumber }) => ({
        variables: {
            getAfterSaleOrdersInput: {
                byContractNumber: contractNumber
            },
        },
    }),
});

const getProps = withProps(
    (props) => {
        const sortFunction = (a, b) => b.orderNumber - a.orderNumber;
        const afterSales =
            props?.data?.afterSaleOrders?.afterSaleOrders
                .map(afterSale => ({ ...afterSale, orderNumber: getOrderNumberFromAfterSaleId(afterSale.id) }))
                .sort(sortFunction) || [];
        return {
            afterSales,
            columns: getColumns(props),
            pageNumber: Math.ceil(afterSales.length / ITEMS_PER_PAGE),
        };
    }
);

const AfterSales = compose(
    connectMeAndTeamsAndConstantsContext,
    withLoading,
    withAfterSalesState,
    withFilteredState,
    withData,
    getProps,
    withPagination,
    pure,
)(AfterSalesPure);

AfterSales.propTypes = {
  contractNumber: PropTypes.string,
};

export default AfterSales;