import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { errorMsg, successMsg } from '../../../../../utils/Notify';
import { changeDeviceTransferStatus } from '../../../../../utils/requests';
import LoadingProgress from '../../../../components/LoadingProgress';

const ToggleDeviceTransferButton = ({contractNumber, canBeTransferred, setDmd}) => {
  const [loading, setLoading] = useState(false);

	const changeTransferableState = async ({enable}) => {
    setLoading(true);
    await changeDeviceTransferStatus(contractNumber, {enable})
    .then((response, textStatus, jqXHR) => {
      setDmd(dmd => ({...dmd, ...response}));
			successMsg(`La reprise a été ${canBeTransferred ? 'dés' : ''}activée !`);
		}, (jqXHR, textStatus, errorThrown) => {
			errorMsg('Impossible de changer le statut de transfert !');
		})
    setLoading(false);
	}

	const handleDisableTransferClick = () => changeTransferableState({enable: false});

	const handleEnableTransferClick = () => changeTransferableState({enable: true});

  return <>
    <LoadingProgress show={loading} />

    {canBeTransferred
    ? <Button bsStyle='success' onClick={handleDisableTransferClick} block>
        Désactiver la reprise
      </Button>
    : <Button bsStyle='warning' onClick={handleEnableTransferClick} block>
        Activer la reprise
      </Button>
    }
  </>;
};

ToggleDeviceTransferButton.propTypes = {
  contractNumber: PropTypes.string.isRequired,
  canBeTransferred: PropTypes.bool,
  setDmd: PropTypes.func,
};

export default ToggleDeviceTransferButton;