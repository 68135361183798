import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {compose, pure, withHandlers, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {UPDATE_SERVICETERMINATION_ON_SUBSCRIPTIONS} from '../../../schema/SubscriptionSchema';
import {isDateInFuture} from '../../../utils/Date';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg} from '../../../utils/Notify';
import {SUBSCRIPTION_TERMINATION_REASONS} from '../../../utils/Subscription';
import SingleDatePicker from '../../components/DatePicker/SingleDatePicker';
import JsonSchemaForm from '../../components/Form/JsonSchemaForm';
import LayoutGridField from '../../components/LayoutGridFields';
import {
    contractNumberSchemaField,
    contractNumbersUiSchemaField,
    convertResult,
    formatContractNumbersForSave,
    onFormDataChange,
    validateForm,
    withToucanListOperationResult,
} from '../ContractsHelpers';

const schema = {
    type: 'object',
    properties: {
        contractNumbers: contractNumberSchemaField,
        serviceTermination: {
            type: 'object',
            properties: {
                terminationAsk: {
                    type: 'number',
                    title: 'Date de demande de résiliation',
                    params: {
                        isOutsideRange: isDateInFuture,
                    },
                },
                serviceTermination: {
                    type: 'number',
                    title: 'Date de fin de service',
                },
                terminationReason: {
                    title: 'Nature de la résiliation',
                    type: 'string',
                    enum: Object.keys(SUBSCRIPTION_TERMINATION_REASONS),
                    enumNames: Object.values(SUBSCRIPTION_TERMINATION_REASONS).map(_ => _.label),
                },
            },
            required: ['serviceTermination', 'terminationAsk', 'terminationReason'],
        },
    },
    required: ['contractNumbers', 'serviceTermination'],
};

const uiSchema = {
    contractNumbers: contractNumbersUiSchemaField,
    serviceTermination: {
        serviceTermination: {
            'ui:widget': 'singleDatePicker',
        },
        terminationAsk: {
            'ui:widget': 'singleDatePicker',
        },
    },
    'ui:field': 'layout_grid',
    'ui:layout_grid': [{
        'ui:row': [
            {'ui:col': {md: 6, children: ['contractNumbers']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 4, children: ['serviceTermination.terminationAsk']}},
            {'ui:col': {md: 4, children: ['serviceTermination.terminationReason']}},
            {'ui:col': {md: 4, children: ['serviceTermination.serviceTermination']}},
        ],
    }],
};

const widgets = {
    singleDatePicker: SingleDatePicker,
};

const ServiceTerminationUpdatePure = ({
                                          formData,
                                          handleFormDataChange,
                                          handleSave,
                                      }) => (
    <JsonSchemaForm fields={{layout_grid: LayoutGridField}}
                    formData={formData}
                    schema={schema}
                    showErrorList={false}
                    uiSchema={uiSchema}
                    widgets={widgets}
                    onChange={handleFormDataChange}
                    onSubmit={handleSave}
                    validate={validateForm}>
        <ButtonToolbar className='pull-right'>
            <Button bsStyle='primary'
                    type='submit'>
                Enregistrer la fin de service
            </Button>
        </ButtonToolbar>
    </JsonSchemaForm>
);

ServiceTerminationUpdatePure.propTypes = {
    formData: PropTypes.object,
    handleFormDataChange: PropTypes.func,
    handleSave: PropTypes.func,
};

const withFormDataState = withState('formData', 'setFormData', {});

const ToucanListOperationErrorReason = {
    ALREADY_SET: {
        message: 'la fin de service est déjà enregistrée',
        value: 'ALREADY_SET',
    },
    NOT_FOUND: {
        message: 'n\'existe pas',
        value: 'NOT_FOUND',
    },
    SAVE: {
        message: 'erreur de sauvegarde',
        value: 'SAVE',
    },
};

const submitSetServiceTerminationOnSubscriptions = mutate => ({contractNumbers, serviceTermination}, props) => {
    const {setLoading} = props;
    setLoading(
        true,
        () => mutate({
            variables: {
                input: {
                    contractNumbers,
                    serviceTermination,
                },
            },
            update: (store, result) => convertResult(
                result?.data?.subscriptionSetServiceTerminationOnContractList,
                props,
                ToucanListOperationErrorReason,
            ),
        }).then(
            emptyFunction,
            error => {
                console.error(error);
                setLoading(false, () => errorMsg('Erreur lors de la sauvegarde des demandes de fin de service'));
            },
        ),
    );
};

const withSetServiceTerminationOnSubscriptionsMutation = graphql(UPDATE_SERVICETERMINATION_ON_SUBSCRIPTIONS, {
    props: ({mutate}) => ({
        handleSetServiceTerminationOnSubscriptions: submitSetServiceTerminationOnSubscriptions(mutate),
    }),
});

const handlers = withHandlers({
    handleFormDataChange: onFormDataChange,
    handleSave: props => () => {
        const {formData, handleSetServiceTerminationOnSubscriptions} = props;
        const contractNumbers = formatContractNumbersForSave(formData.contractNumbers);

        handleSetServiceTerminationOnSubscriptions(
            {
                ...formData,
                contractNumbers,
            },
            props,
        );
    },
});

const ServiceTerminationUpdate = compose(
    withFormDataState,
    withToucanListOperationResult,
    withLoading,
    withSetServiceTerminationOnSubscriptionsMutation,
    handlers,
    pure,
)(ServiceTerminationUpdatePure);

ServiceTerminationUpdate.propTypes = {};

export default ServiceTerminationUpdate;