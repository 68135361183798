import React from 'react';
import DevicesTableRowFields from './DevicesTableRowFields';
import DevicesTableRowButtons from './DevicesTableRowButtons';

const DevicesTableRow = ({
	properties,
	entry,
	tags,
	handleAssignContractClick,
	handleCreateContractClick,
	handleDetachContractClick,
	handleEnableTransferClick,
	handleDisableTransferClick,
	handleEditDMDClick,
	handleContractHistoryClick,
}) => {
	return (
		<tr>
			<DevicesTableRowFields properties={properties} entry={entry} tags={tags} handleContractHistoryClick={handleContractHistoryClick} />
			
			<DevicesTableRowButtons
				entry={entry}
				handleAssignContractClick={handleAssignContractClick}
				handleCreateContractClick={handleCreateContractClick}
				handleDetachContractClick={handleDetachContractClick}
				handleEnableTransferClick={handleEnableTransferClick}
				handleDisableTransferClick={handleDisableTransferClick}
				handleEditDMDClick={handleEditDMDClick}
			/>
		</tr>
	);
}

export default DevicesTableRow;