import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import ChangePlanButton from './ActionButtons/ChangePlanButton';
import NeedTerminationButton from './ActionButtons/NeedTerminationButton';
import SetUnknownButton from './ActionButtons/SetUnknownButton';

const ActivatedButtonGroupPure = ({
                                    displayVertical,
                                  }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <ChangePlanButton/>
    <NeedTerminationButton/>
    <SetUnknownButton/>
  </ButtonGroup>
);

ActivatedButtonGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const ActivatedButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(ActivatedButtonGroupPure);

ActivatedButtonGroup.propTypes = {};

export default ActivatedButtonGroup;