import PropTypes from 'prop-types';
import React from 'react';
import {getSimStateClass, getSimStateIcon} from '../../../../utils/Sim';
import FormGroupWithFAIconAndLabelAndTooltip from '../../../components/FormGroupWithFAIconAndLabelAndTooltip';

const FormattedICCID = ({dmd}) => {
	if (!dmd || !dmd.simState) return null;
	return (
		<FormGroupWithFAIconAndLabelAndTooltip
			bsStyle={getSimStateClass(dmd.simState)}
			icon={getSimStateIcon(dmd.simState)}
			id='iccid'
			label='Numéro ICCID'
			readOnly
			spin={dmd.simState.indexOf('ting') !== -1}
			text={dmd.ICCID}
			tooltip={dmd.simState}
		/>
	);
}

FormattedICCID.propTypes = {
	dmd: PropTypes.object,
};

export default FormattedICCID;