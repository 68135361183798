import React from 'react';
import { Panel } from 'react-bootstrap'

const ServerError = () => (
    <div style={{ textAlign: 'center' }}>
        <Panel bsStyle='danger' style={{ width: '50%', margin: '100px auto' }}>
            <Panel.Heading>
                <Panel.Title>Erreur de communication avec le serveur</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
                Le serveur ne répond pas, veuillez contacter l'administrateur du site.
            </Panel.Body>
        </Panel>
    </div>
);

export default ServerError;