import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {Col, Row} from 'react-bootstrap';
import {connectTypesContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {navigateToContract, navigateToTicket} from '../../../utils/functions';
import { postTicketTransferToOtherContract } from '../../../utils/requests';
import { SAME_CONTRACT_ERROR, TRANSFER_ERROR } from '../../../utils/errors';
import ContractTicketsTable from './ContractTicketsTable';
import TicketDetails from '../TicketDetails';

const ContractTicketsTablePanel = connectTypesContext(({ types, contractNumber, ticketNumber, tagList, tickets, deleteTicket, removeTicket, addTicket, setTicket, setAnswer, loading }) => {

  const selectedTicket = useMemo(() => tickets.find(_ => _.ticketNumber === ticketNumber), [ticketNumber, tickets]);

  const handleTicketClose = () => navigateToContract(contractNumber);

  const transferTicket = ({ newContractNumber, ticket, copyMode }, { onSuccess, onError }) => {
    const oldContractNumber = contractNumber;
    const {ticketNumber, id: ticketID} = ticket;
    const data = {
      oldContractNumber,
      newContractNumber,
      ticketNumber: parseInt(ticketNumber),
      copyMode,
    };

    if (oldContractNumber === newContractNumber) {
      onError(SAME_CONTRACT_ERROR);
    } else {
      postTicketTransferToOtherContract(data)
      .then(response => {

        if (response === undefined) {
          onError(TRANSFER_ERROR);
          return;
        }
        
        if (copyMode) {
          addTicket(response.ticket);

          if (selectedTicket) {
            selectedTicket.answers = response.ticket.answers;
          }
          
        } else {
          navigateToContract(contractNumber);
          
          removeTicket(ticketID);
        }
        onSuccess();
      }, response => {
        if (response.status === 404 || response.status === 603) {
          onError(TRANSFER_ERROR);
        }
      });
    }
  }

  const openTicket = id => navigateToTicket(contractNumber, id);

  const showSelectedTicketDetails = !!selectedTicket;
  return (
    <Row>
      <Col lg={showSelectedTicketDetails ? 6 : 12}>
        <ContractTicketsTable
          loading={loading}
          tagList={tagList}
          tickets={tickets}
          selectedTicket={selectedTicket}
          openTicket={openTicket}
          transferTicket={transferTicket}
        />
      </Col>
      {showSelectedTicketDetails && (
        <Col lg={6}>
          <TicketDetails
            key={selectedTicket.ticketNumber}
            contractNumber={contractNumber}
            initialTicket={selectedTicket}
            tagList={tagList}
            deleteTicket={deleteTicket}
            handleClose={handleTicketClose}
            setAnswer={setAnswer}
            setTicket={setTicket}
          />
        </Col>
      )}
    </Row>
  );
});

ContractTicketsTablePanel.propTypes = {
  contractNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
  tagList: PropTypes.array,
  tickets: PropTypes.array,
  deleteTicket: PropTypes.func,
  removeTicket: PropTypes.func,
  addTicket: PropTypes.func,
  setTicket: PropTypes.func,
  setAnswer: PropTypes.func,
};

export default ContractTicketsTablePanel;