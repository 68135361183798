import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderNothing } from 'recompose';
import { Label } from 'react-bootstrap';
import moment from 'moment';

moment.locale('fr');

const WarrantyLabelPure = ({ subscription }) => {
    const warrantyEnd = subscription?.warranty?.baseEnd,
        warrantyExtensionEnd = subscription?.warranty?.extensionEnd;

    if(!warrantyEnd) {
        return <Label bsStyle='warning'>GARANTIE à vérifier</Label>;
    } else {
        const today = moment();
        if(warrantyExtensionEnd && today.isBefore(warrantyExtensionEnd, 'day')) {
            return <Label bsStyle='success'>EXTENSION GARANTIE</Label>;
        } else if(today.isBefore(warrantyEnd, 'day')) {
            return <Label bsStyle='success'>GARANTIE</Label>;
        } else {
            return <Label bsStyle='danger'>NON GARANTIE</Label>;
        }
    }
}

const WarrantyLabel = compose(
    branch(
        ({ subscription }) => !subscription,
        renderNothing,
    ),
    pure,
)(WarrantyLabelPure);

WarrantyLabel.propTypes = {
    subscription: PropTypes.object,
};

export default WarrantyLabel;