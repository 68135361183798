import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Button, Col, ControlLabel, FormControl, HelpBlock, Panel, Row} from 'react-bootstrap';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Select from 'react-select';
import {compose, lifecycle, pure, withHandlers, withProps, withState} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import jsRoutes from '../../../routes/routes';
import {sortAlpha} from '../../../utils/Array';
import {TICKET_STATES} from '../../../utils/constants';
import {getCanalType, getStateType, getSubjectTypeLabel, getSubjectValues, getTeamType} from '../../../utils/functions';
import LoadingProgress from '../../components/LoadingProgress';
import ResultField from './TicketGenerator/ResultField';
import TakeActionFields from './TicketGenerator/TakeActionFields';
import { getSubjectResultOptions, getVisibleSubjectOptions } from '../../../utils/types';

const {
  generateTicketsFromIdsContract,
  getTags,
  getTicketTypes,
} = jsRoutes.controllers.backoffice.SupportV2Controller;

const TicketsGeneratorPure = ({
                         filteredClotures,
                         form,
                         isFormInvalid,
                         loading,
                         options,
                         saveResponse,
                         tagsForForm,
                         ticketTagsOptions,
                         types,
                         handleChange,
                         handleDateChange,
                         handleFileChange,
                         handleTextChange,
                         setTagsForForm,
                         validateForm,
                       }) => (
  <Panel bsStyle='success' className='center-block'>
    {saveResponse.message &&
    <Alert bsStyle={saveResponse.style}>{saveResponse.message}</Alert>}
    <Panel.Body>
      <LoadingProgress show={loading}/>
      <Row>
        <Col md={2} mdOffset={4}>
          <ControlLabel>Demande *</ControlLabel>
          <FormControl componentClass='select' value={form.subject}
                       onChange={e => handleChange('subject', e)} placeholder='Demande'>
            <option value='---'>---</option>
            {getVisibleSubjectOptions(options.type_demande, types).map(type_demande => (
              <option key={type_demande} value={type_demande}>
                {getSubjectTypeLabel(types, type_demande)}
              </option>
            ))}
          </FormControl>
        </Col>
        <Col md={2}>
          <ControlLabel>Etat *</ControlLabel>
          <FormControl componentClass='select' value={form.state}
                       onChange={e => handleChange('state', e)} placeholder='Etat'>
            <option value='---'>---</option>
            {options.type_etat.map(type_etat =>
              <option key={type_etat}
                      value={type_etat}>{getStateType(types, type_etat)}</option>,
            )}
          </FormControl>
        </Col>

        <ResultField subjectResultOptions={filteredClotures}
                     result={form.result}
                     state={form.state}
                     handleChange={handleChange}/>

        <TakeActionFields state={form.state}
                          tempDate={form.tempDate}
                          tempDateForLastDay={form.tempDateForLastDay}
                          handleDateChange={handleDateChange}/>

      </Row>
      <br/>
      <Row>
        <Col md={2} mdOffset={4}>
          <ControlLabel>Equipe *</ControlLabel>
          <FormControl componentClass='select' value={form.team}
                       onChange={e => handleChange('team', e)} placeholder='Equipe'>
            <option value='---'>---</option>
            {sortAlpha(options.type_team, _ => getTeamType(types, _))
              .map(type_team =>
                <option key={type_team}
                        value={type_team}>{getTeamType(types, type_team)}</option>,
              )}
          </FormControl>
        </Col>
        <Col md={2}>
          <ControlLabel>Canal *</ControlLabel>
          <FormControl componentClass='select' value={form.canal}
                       onChange={e => handleChange('canal', e)} placeholder='Canal'>
            <option value='---'>---</option>
            {sortAlpha(options.type_canal, _ => getCanalType(types, _))
              .map(type_canal =>
                <option key={type_canal}
                        value={type_canal}>{getCanalType(types, type_canal)}</option>,
              )}
          </FormControl>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col md={2} mdOffset={4}>
          <FormGroup>
            <ControlLabel>Commentaire</ControlLabel>
            <FormControl componentClass='textarea'
                         type='text' value={form.comment}
                         onChange={e => handleTextChange('comment', e)}/>
          </FormGroup>
        </Col>
        <Col md={2}>
          <FormGroup>
            <ControlLabel>Tags</ControlLabel>
            <Select.Creatable multi={true}
                              options={ticketTagsOptions}
                              value={tagsForForm}
                              onChange={value => setTagsForForm(value)}/>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4} mdOffset={4}>
          <FormGroup>
            <ControlLabel>Fichier</ControlLabel>
            <FormControl type='file' onChange={e => handleFileChange(e)}/>
            <HelpBlock>Fichier à ajouter au ticket</HelpBlock>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={4} mdOffset={4}>
          <FormGroup>
            <ControlLabel>Numéros de contrat *</ControlLabel>
            <FormControl componentClass='textarea'
                         style={{height: 200}}
                         type='text' value={form.ids}
                         onChange={e => handleTextChange('ids', e)}/>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={2} mdOffset={6}>
          <Button disabled={isFormInvalid}
                  className='pull-right'
                  onClick={validateForm}>
            Valider
          </Button>
        </Col>
      </Row>
    </Panel.Body>
  </Panel>
);

TicketsGeneratorPure.propTypes = {
  filteredClotures: PropTypes.array,
  form: PropTypes.object,
  isFormInvalid: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.object,
  saveResponse: PropTypes.object,
  tagsForForm: PropTypes.array,
  ticketTagsOptions: PropTypes.array,
  types: PropTypes.object,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleFileChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  setTagsForForm: PropTypes.func,
  validateForm: PropTypes.func,
};

const withLoadingState = withState('loading', 'setLoading', false);
const withFormState = withState('form', 'setForm', {});
const withOptionsState = withState('options', 'setOptions', {
  type_demande: [],
  type_etat: [],
  type_team: [],
  type_canal: [],
  type_cloture: [],
});
const withFilteredCloturesState = withState('filteredClotures', 'setFilteredClotures', []);
const withTagsState = withState('tags', 'setTags', []);
const withTagsForFormState = withState('tagsForForm', 'setTagsForForm', []);
const withFileState = withState('file', 'setFile', null);
const withSaveResponseState = withState('saveResponse', 'setSaveResponse', {
  style: null, // success, danger or warning
  message: null,
});

const resetState = async (withMessage, props) => {
  const {
    setForm,
    setTagsForForm,
    setTags,
    setFile,
    setSaveResponse,
  } = props;

  await setForm({
    ids: '',
    result: '',
    team: '',
    subject: '',
    state: '',
    canal: '',
    tags: '',
    date: '',
    tempDate: '',
    comment: '',
    tempDateForLastDay: '',
  });
  await setTagsForForm([]);
  await setTags([]);
  await setFile(null);
  await setSaveResponse({
    style: null,
    message: null,
  });

  if (withMessage) {
    await setSaveResponse(withMessage);
  }
};

const getFormattedIds = ids => ids.trim().split('\n').map(_ => _.trim()).filter(_ => !!_);

const handlers = withHandlers({
  handleTextChange: ({form, setForm}) => (propertyName, event) =>
    setForm({
      ...form,
      [propertyName]: event.target.value,
    }),

  handleFileChange: ({setFile}) => (event) => setFile(event.target.files[0]),

  handleChange: ({
                   form,
                   options,
                   setFilteredClotures,
                   setForm,
                 }) => async (propertyName, event) => {
    const value = event.target.value;

    await setForm({
      ...form,
      [propertyName]: value,
    });

    // Fix: form is sometimes not reloaded in time
    const state = propertyName === 'state' ? value : form.state;

    if (
      state === TICKET_STATES.DONE &&
      (propertyName === 'subject' || propertyName === 'state')
    ) {
      // Fix: form is sometimes not reloaded in time
      const subject = propertyName === 'subject' ? value : form.subject;

      const clotures = getSubjectResultOptions(options.type_cloture, subject);
      
      setFilteredClotures(clotures);
    }
  },

  handleDateChange: ({form, setForm}) => (propertyName, value) => {
    const tempDateProp = propertyName === 'takeActionDay' ? 'tempDate' : 'tempDateForLastDay';
    setForm({
      ...form,
      takeActionDay: new Date(value).getTime(),
      [tempDateProp]: value,
    });
  },

  validateForm: (props) => () => {
    const {
      file,
      form,
      tagsForForm,
    } = props;

    if (form.ids) {
      const formattedFormData = {
        ...form,
        ids: getFormattedIds(form.ids),
        tags: tagsForForm.map(_ => _.value),
        test: false
      }

      const formData = new FormData();
      formData.append('ticket', JSON.stringify(formattedFormData));

      if (file) {
        formData.append('attachment_0', this.state.file);
      }

      generateTicketsFromIdsContract().ajax({
        data: formData,
        contentType: false,
        processData: false,
      }).then(
        async (response) => {
          let saveMessage;
          const res = JSON.parse(response);

          if (res.created && !res.failed) {
            saveMessage = {style: 'success', message: `${res.created} ticket(s) créé(s)`};
          } else if (!res.created) {
            saveMessage = {style: 'danger', message: `Echec de la création de ${res.failed} ticket(s)`};
          } else {
            saveMessage = {
              style: 'warning',
              message: `${res.created} ticket(s) créé(s) et ${res.failed} en échec`,
            };
          }

          await resetState(saveMessage, props);
        },
        () => resetState({style: 'danger', message: 'Erreur lors de la création des tickets'}, props),
      );
    }
  },
});

const checkFormValidity = (form) => {
  const {ids, canal, state, result, subject, team} = form;
  let isIdsValid = true;

  if (ids) {
    const arrId = getFormattedIds(ids);

    arrId.forEach(id => {
      const contractNumber = id.trim();
      isIdsValid = isIdsValid && /^\d{1,8}$/.test(contractNumber); // Test contractNumber 8 numbers
    });
  }

  return !ids ||
    !isIdsValid ||
    !canal ||
    !subject ||
    !team ||
    !state ||
    (state === TICKET_STATES.DONE && !result);
};

const withCalculatedProps = withProps(
  ({form, tags}) => ({
    isFormInvalid: checkFormValidity(form),
    ticketTagsOptions: sortAlpha(tags).map(tag => ({value: tag, label: tag})),
  }),
);

const setTicketTypes = (props) => {
  const {
    webUserJson,
    setLoading,
    setOptions,
  } = props;

  return getTicketTypes().ajax({
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    processData: false,
  }).then(
    async (response) => {
      await setLoading(false);
      await setOptions({
        ...response,
        type_demande: getSubjectValues(response.type_demande, webUserJson),
      });
    },
    () => setLoading(false),
  );
};

const setTags = (props) => {
  const {
    setLoading,
    setTags,
  } = props;

  return getTags().ajax({
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    processData: false,
  }).then(
    async (response) => {
      await setLoading(false);
      await setTags(response.tags);
    },
    () => setLoading(false),
  );
};

const withLifecycle = lifecycle({
  async componentDidMount() {
    await setTicketTypes(this.props);
    await setTags(this.props);
  },
});

const TicketGenerator = compose(
  connectMeAndTeamsAndConstantsContext,
  withLoadingState,
  withFormState,
  withOptionsState,
  withFilteredCloturesState,
  withTagsState,
  withTagsForFormState,
  withFileState,
  withSaveResponseState,
  handlers,
  withCalculatedProps,
  withLifecycle,
  pure,
)(TicketsGeneratorPure);

TicketGenerator.propTypes = {};

export default TicketGenerator;