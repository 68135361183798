import React, { Component } from 'react';
import { Alert, Button, ButtonToolbar, FormControl, Modal } from 'react-bootstrap';

import GoogleMapsAPIFactory from '../../../../../utils/GoogleMapsAPIFactory';
import LoadingProgress from '../../../../components/LoadingProgress';

import AntennasTableBlock from './AntennasTableBlock';
import { getDistance } from '../../../../../utils/Maths';
import jsRoutes from '../../../../../routes/routes';

class Antennas extends Component {

    constructor(props) {
        super(props);
        this.state = this.getComponentInitialState();
    }

    getComponentInitialState() {
        return { address: '', url: '', urlCartoRadio:'', nbAntennas:'', showModal: false, alert: '', dataAnfr: null, emr_systemes:[], adm_nom:[] };
    }

    componentDidMount(){
        this.setState({ address: this.props.address });
    }

    componentDidUpdate(previousProps) {
        if (this.props.address !== previousProps.address){
            this.setState({ address: this.props.address });
        }
    }

    handleChange(event) {
        this.setState({address: event.target.value});
    }

    handleSave() {
        const address = this.state.address;
        const _this = this;

        GoogleMapsAPIFactory((google) => {
            const geocoder = new google.maps.Geocoder();
            _this.codeAddress(google, geocoder, address);
        });
    }

    open() {
        const address = this.props.address;
        const _this = this;

        GoogleMapsAPIFactory((google) => {
            const geocoder = new google.maps.Geocoder();
            _this.codeAddress(google, geocoder, address);
            _this.setState({loading: true});
        });
    }

    close() {
        this.setState({showModal: false});
    }

    formatData(dataAnfr, latitude, longitude){
        if (dataAnfr &&  dataAnfr.records &&  dataAnfr.records.length > 0){
            var arrayInput = dataAnfr.records;
            var arrayOutput = [];
            var arrayFrequency = [arrayInput[0].fields.emr_lb_systeme];
            var arrayOperator = [arrayInput[0].fields.adm_lb_nom];

            arrayInput.forEach(function (recordInput) {
                const coords = recordInput.fields.coordonnees.split(', ');
                if(coords.length === 2) {
                    const lon = parseFloat(coords[1]);
                    const lat = parseFloat(coords[0]);

                    recordInput.fields.dist = getDistance(latitude, longitude, lat, lon);
                }

                arrayFrequency.push(recordInput.fields.emr_lb_systeme);
                arrayOperator.push(recordInput.fields.adm_lb_nom);

                let foundGlobal = false;

                if (arrayOutput.length === 0){
                    arrayOutput.push(recordInput)
                }else {
                    arrayOutput.forEach(function (recordOutput) {
                        let found = recordInput.fields.dist === recordOutput.fields.dist
                            && recordInput.fields.adm_lb_nom.trim().valueOf() ===  recordOutput.fields.adm_lb_nom.trim().valueOf()
                            && recordInput.fields.generation.trim().valueOf() === recordOutput.fields.generation.trim().valueOf();
                        if (found){
                            if(recordOutput.fields.emr_lb_systeme.indexOf(recordInput.fields.emr_lb_systeme) === -1) {
                                recordOutput.fields.emr_lb_systeme = recordOutput.fields.emr_lb_systeme + '\n' + recordInput.fields.emr_lb_systeme;
                            }
                            foundGlobal = true;
                        }
                    })

                    if (!foundGlobal){
                        arrayOutput.push(recordInput);
                    }
                }

            });

            var emr_systems = arrayFrequency.filter((elem, index, self) => index === self.indexOf(elem));
            var adm_nom = arrayOperator.filter((elem, index, self) => index === self.indexOf(elem));

            adm_nom.sort(function(a, b){
                if(a < b) return -1;
                if(a > b) return 1;
                return 0;
            });
            emr_systems.sort(function(a, b){
                if(a < b) return -1;
                if(a > b) return 1;
                return 0;
            });

            this.setState({emr_systems:emr_systems, adm_nom:adm_nom});
            return arrayOutput;
        }else {
            this.setState({showModal: true, address: '', alert: 'Aucune antenne trouvée pour cette adresse.'});
        }
        return null;
    }

    openMap(){
        window.open(this.state.urlCartoRadio, '_blank');
    }

    openModal(){
        this.setState({showModal: true});
    }

    codeAddress(google, geocoder, address) {
        const  _this = this;

        geocoder.geocode({
            address: address
        }, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();

                let urlCartoRadio = 'https://www.cartoradio.fr/index.html#/cartographie/all/lonlat/';
                urlCartoRadio += longitude + '/' + latitude;

                let urlAnfr = 'https://data.anfr.fr/api/records/1.0/search/dataset=observatoire_2g_3g_4g&rows=1000&start=0&geofilter.distance=';
                urlAnfr += `${latitude}%2C${longitude}%2C3000`;

                jsRoutes.controllers.backoffice.Proxy.index(urlAnfr)
                    .ajax({
                        dataType: 'html',
                        contentType: 'application/html; charset=utf-8',
                        processData: false,
                        crossDomain: true,
                        xhrFields: { withCredentials: true },
                    }).then((response) => {
                            const res = JSON.parse(response);
                            _this.setState({
                                loading: false,
                                nbAntennas: res.nhits,
                                dataAnfr: _this.formatData(res, latitude, longitude),
                            });
                        },
                        () => _this.setState({loading: false})
                    );

                _this.setState({
                    url: urlAnfr,
                    urlCartoRadio: urlCartoRadio,
                    alert: '',
                    showModal: false
                });
            } else {
                let alert;
                if (status === 'ZERO_RESULTS') {
                    alert = 'Le géocodage n\'a pas trouvé de résultats pour cette adresse, veuillez entrer une adresse valide';
                } else {
                    alert = 'Le géocodage n\'a pu etre effectué pour la raison suivante: ' + status
                }

                _this.setState({
                    showModal: true,
                    address: '' ,
                    alert: alert});
            }
        });
    }

    render() {
        var alert = this.state.alert !== ''
            ? <Alert bsStyle='warning'>
                {this.state.alert}</Alert>
            : ''

        let modal = <Modal show={this.state.showModal} onHide={this.close.bind(this)}>
            <Modal.Header closeButton>
                Géolocalisation des antennes
            </Modal.Header>
            <Modal.Body>
                {alert}
                <br/>
                <FormControl type='text' value={this.state.address} onChange={this.handleChange.bind(this)} placeholder='Entrez une adresse ici'/><br/>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='success' className='pull-right' onClick={this.handleSave.bind(this)}>Valider</Button>
            </Modal.Footer>
        </Modal>

        let buttonDisplayMap = '';
        let nbAntennas = '';
        let buttonGeocode = '';

        if (this.state.dataAnfr ) {
            buttonDisplayMap = <Button className='pull-right' bsStyle='success' onClick={this.openMap.bind(this)}>Voir carte</Button>
            nbAntennas = <p> Il y a {this.state.nbAntennas} antennes dans un rayon de 3 kilomètres de l'adresse, affichage des {this.state.dataAnfr.length} premières cellules </p>
        }
        else{
            buttonGeocode = <ButtonToolbar>
                <Button bsStyle='success' onClick={this.open.bind(this)}>Géolocalisation</Button>
                <Button bsStyle='warning' onClick={this.openModal.bind(this)}>Géolocaliser une autre adresse</Button>
            </ButtonToolbar>
        }

        return (
            <div>
                <LoadingProgress show={this.state.loading}/>
                <p>Adresse : {this.state.address}</p>
                {nbAntennas}
                <AntennasTableBlock emr_systems={this.state.emr_systems} adm_nom={this.state.adm_nom} antennas={this.state.dataAnfr}/>
                {buttonDisplayMap}
                {buttonGeocode}
                {modal}
            </div>
        );
    }
}

Antennas.defaultProps = {
    propAddress: ''
}

export default Antennas;