import React, { Component } from 'react';
import { ControlLabel, FormControl, FormGroup, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FontAwesome from './FontAwesome';

class FormGroupWithFAIconAndLabelAndTooltip extends Component {
    render() {
        let tooltip = <Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>;
        let bsStyle = 'pill-'+this.props.bsStyle;
        return (
            <OverlayTrigger
                overlay={tooltip} placement='right'
                delayShow={300} delayHide={150}>
                <FormGroup>
                    <ControlLabel>{this.props.label}</ControlLabel><br/>
                    <InputGroup>
                        <InputGroup.Addon><FontAwesome name={this.props.icon} className={bsStyle} spin={this.props.spin} pulse={this.props.pulse}/></InputGroup.Addon>
                        <FormControl type='text' value={this.props.text} readOnly={this.props.readOnly}/>
                    </InputGroup>
                </FormGroup>
            </OverlayTrigger>
        )
    }
}

export default FormGroupWithFAIconAndLabelAndTooltip;