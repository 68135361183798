import React, { useState } from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DateBlock from '../DateBlock';

const AssignNewTicketModal = ({ ticket, handleChange, onHide, addTicketTags, handleSave }) => {

	const [answerContent, setAnswerContent] = useState('');
	
	const handleValidateAssign = () => {

		const createDataWithAnswerContent = () => {
			const data = new FormData();
			if (answerContent && answerContent.length > 0) {
				data.append('answer_1', JSON.stringify({'content': answerContent}));
			}
			return data;
		}

		ticket.state = '1';
		delete ticket.result;
		ticket = addTicketTags(ticket);
		handleChange(ticket);
		handleSave(createDataWithAnswerContent());
		onHide();
	}

	return (
    <Modal show={true} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Créer le ticket avec l'état 'En cours'</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<DateBlock ticket={ticket} handleChange={handleChange} />
				<FormGroup>
					<ControlLabel>Ajouter un commentaire</ControlLabel>
					<FormControl componentClass='textarea' value={answerContent} onChange={(event) => setAnswerContent(event.target.value)} placeholder='Saisir le commentaire'/>
				</FormGroup>
			</Modal.Body>
			<Modal.Footer>
				<Button bsStyle='success' onClick={handleValidateAssign}>Créer</Button>
			</Modal.Footer>
    </Modal>
	);
}

AssignNewTicketModal.propTypes = {
	ticket: PropTypes.object,
	addTicketTags: PropTypes.func,
	handleChange: PropTypes.func,
	handleSave: PropTypes.func,
	onHide: PropTypes.func,
};

export default AssignNewTicketModal;