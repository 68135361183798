import React from 'react';
import PropTypes from 'prop-types';
import {ControlLabel, FormControl, FormGroup, HelpBlock, Label} from 'react-bootstrap';
import {getICCIDValidationState} from '../../utils/functions';
import {SIM_OPERATORS} from '../../utils/Sim';
import { fetchSIMs } from '../../utils/requests';
import { errorMsg } from '../../utils/Notify';

const ICCIDField = ({SIM, onChange}) => {

	const {operator, ICCID, simFound} = SIM;
		
	const handleICCIDChange = event => {
		const ICCID = event.target.value;
		if (getICCIDValidationState(ICCID) === 'success') {
			fetchSIMs(ICCID)
			.then((response, textStatus, jqXHR) => {
				onChange({
					ICCID,
					simFound: response.length === 1,
					operator: response.length === 1 ? response[0].operator : '',
				});
			}, (jqXHR, textStatus, errorThrown) => {
				errorMsg("Erreur lors de la récupération de l'opérateur de la SIM");
			});
		} else {
			onChange({
				ICCID,
				simFound: null,
				operator: '',
			});
		}
	}

	const handleOperatorChange = event => {
		onChange({
			...SIM,
			operator: event.target.value,
		});
	}
  
	let operatorField = null;

	if (simFound) {
		operatorField = <Label>Opérateur : {operator || 'Non défini'}</Label>;
	} else if (simFound === false) {
		operatorField = (
			<FormGroup>
				<ControlLabel>Opérateur</ControlLabel>
				<FormControl
					componentClass='select'
					value={operator}
					onChange={handleOperatorChange}
				>
					{Object.values(SIM_OPERATORS).map(({name, ...operator}) => (
						<option key={name} value={name}>
							{name}
						</option>
					))}
				</FormControl>
			</FormGroup>
		);
	}

	return <>
		<FormGroup validationState={getICCIDValidationState(ICCID)}>
			<ControlLabel>ICCID</ControlLabel>
			<FormControl
				type='text'
				value={ICCID}
				onChange={handleICCIDChange}
			/>
			{getICCIDValidationState(ICCID) === 'error' && <HelpBlock>ICCID invalide</HelpBlock>}
		</FormGroup>
		{operatorField}
	</>;
};

ICCIDField.propTypes = {
	SIM: PropTypes.object,
	onChange: PropTypes.func,
};

export default ICCIDField;