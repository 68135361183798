import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import { CloseTicketModal } from '../../modals';
import { getSubjectResultOptions } from '../../../../utils/types';

const ChangeResultModalButton = ({closed, hidden, ticket = {}, resultOptions = [], handleChangeResultTicket}) => {
	const [show, setShow] = useState(false);

  if (hidden) return null;
	
	const handleShow = () => setShow(true);
	
	const handleHide = () => setShow(false);

	const handleSubmit = ({ result, firstIntention, resultComment }) => {
		handleChangeResultTicket({ result, firstIntention, resultComment });
		handleHide();
	}

  const subjectResultOptions = getSubjectResultOptions(resultOptions, ticket.subject);
	
	return <>
    <Button className='pull-left' onClick={handleShow}>
			Changer la clôture
    </Button>
		
		<CloseTicketModal
			title='Changer le résultat'
			show={show}
			subjectResultOptions={subjectResultOptions}
			ticket={ticket}
			onSubmit={handleSubmit}
			onHide={handleHide}
		/>
	</>;
}

ChangeResultModalButton.propTypes = {
	closed: PropTypes.bool,
	hidden: PropTypes.bool,
	ticket: PropTypes.object,
	resultOptions: PropTypes.array,
	handleChangeResultTicket: PropTypes.func,
};

export default ChangeResultModalButton;