import jsRoutes from '../../../routes/routes';
import { CONTRACT_DETACHED } from '../../../utils/constants';
import {filterTeamTransfer, getSubjectValues} from '../../../utils/functions';

const {
	getContract: fetchContractRoute,
	getHardwareMetadata: fetchHardwareMetadataRoute,
	getTicketTypes: fetchTicketTypesRoute,
} = jsRoutes.controllers.backoffice.SupportV2Controller;

const fetchContract = ({ contractNumber, setContract, setLoading }) => {
	const stopLoading = () => setLoading(false);

	if (!contractNumber || contractNumber === CONTRACT_DETACHED) return stopLoading();

	fetchContractRoute(contractNumber)
	.ajax({
		dataType: 'json',
		contentType: 'application/json; charset=utf-8',
		processData: false,
	}).then(contract => {
		setContract(contract);
		stopLoading();
	}, stopLoading);
};

const fetchDmd = ({ contractNumber, setDmd, setLoading }) => {
	const stopLoading = () => setLoading(false);

	if (!contractNumber || contractNumber === CONTRACT_DETACHED) return stopLoading();

	fetchHardwareMetadataRoute(contractNumber)
	.ajax({
		dataType: 'json',
		contentType: 'application/json;charset=utf-8',
		processData: false,
	})
	.then(dmd => {
		setDmd(dmd);
		stopLoading();
	}, stopLoading);
};

const fetchTicketTypes = ({ webUserJson, setLoading, setOptions }) => {
	const stopLoading = () => setLoading(false);

	fetchTicketTypesRoute()
	.ajax({
		dataType: 'json',
		contentType: 'application/json; charset=utf-8',
		processData: false,
	})
	.then(options => {
		options.type_team = filterTeamTransfer(options.type_team, webUserJson);
		options.type_demande = getSubjectValues(options.type_demande, webUserJson);
		setOptions(options);
		stopLoading();
	}, stopLoading);
};

export {
	fetchContract,
	fetchDmd,
	fetchTicketTypes,
};