import React, { Component } from 'react';
import { Alert, Button, Col, Panel } from 'react-bootstrap';
import Form from 'react-jsonschema-form';

import jsRoutes from '../routes/routes';
import {initApp} from '../routes/routes_init';
import PasswordReminder from './Login/PasswordReminder';

import '../css/bootstrap.css';
import '../css/views/Login.css';

const schema = {
    type: 'object',
    properties: {
        username: { type: 'string', title: 'Identifiant' },
        password: { type: 'string', title: 'Mot de passe' },
    },
};

const uiSchema = {
    username: {
        'ui:placeholder' : 'Saisissez votre identifiant',
    },
    password: {
        'ui:widget' : 'password',
        'ui:placeholder' : 'Saisissez votre mot de passe',
    },
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showReminderModal: false,
            showAlert: false,
            formDataState: {
                username: '',
                password: '',
            }
        };
    }

    componentWillUnmount() {
        window.jQuery('html').removeClass('csstransitions csstransforms3d');
        window.jQuery('body').removeClass('loginWrapper breakpoint-992 breakpoint-768');
    }

    onFormChange(formState) {
        this.setState({
            formDataState: formState.formData,
        });
    }

    onFormValidate() {
        const { username, password } = this.state.formDataState;
        const _this = this;

        jsRoutes.controllers.Authentication.supportV2Login()
            .ajax({
                dataType: 'html',
                method: 'POST',
                data: {
                    username: username,
                    password: password,
                }
            }).then((data, status, jqXhr) => {
                if(jqXhr.status === 200) {
                    initApp();
                }                    
            }).fail(error => {
                _this.setState({ showAlert: true });
            });
    }

    showReminderModal() {
        this.setState({ showReminderModal: true });
    }

    handleShowReminderModal(show) {
        this.setState({ showReminderModal: show });
    }

    render() {
        return (
          <>
              <h4 className='innerAll margin-none border-bottom text-center'>
                  <i className='fa fa-lock' />
                  &nbsp;Administration - Ardoiz - Connexion au site
              </h4>
              <div className='login spacing-x2'>
                  <div className='placeholder text-center' />
                  <Col sm={6} smOffset={3}>
                      <Panel>
                          <Panel.Body className='innerAll'>
                              <Alert bsStyle='danger' className={this.state.showAlert ? '' : 'hidden'}><strong>Identifiant ou mot de passe incorrect</strong></Alert>
                              <Form data-test-id='loginForm'
                                    formData={this.state.formDataState}
                                    schema={schema}
                                    uiSchema={uiSchema}
                                    onChange={this.onFormChange.bind(this)}
                                    onSubmit={this.onFormValidate.bind(this)}
                              >
                                  <Button className='btn btn-primary btn-block'
                                          data-test-id='submitLogin'
                                          type='submit'>
                                      Se connecter
                                  </Button>
                              </Form>
                              <p className='text-center'>
                                  <Button bsStyle='link' onClick={this.showReminderModal.bind(this)}>
                                      <small>Mot de passe ou identifiant oublié</small>
                                  </Button>
                              </p>
                          </Panel.Body>
                      </Panel>
                  </Col>
              </div>
              <PasswordReminder showModal={this.state.showReminderModal} handleShowChange={this.handleShowReminderModal.bind(this)} />
          </>
        );
    }
}

export default Login;