import React, { useMemo, useState } from 'react';

import {getCanalType, getTeam, getTeamType} from '../../../../../utils/functions';
import {connectTypesAndTeamsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';

import TicketAnswersTablePagination from './TicketAnswersTablePagination';
import TicketAnswersTable from './TicketAnswersTable';

const ITEMS_PER_PAGE = 100;

const TicketAnswers = connectTypesAndTeamsContext(({types, teams, loading, answers = [], ticketClosed, handleSaveAnswer, itemsPerPage = ITEMS_PER_PAGE}) => {

	const [activePage, setActivePage] = useState(1);
	const [filters, setFilters] = useState(null);

  const createFilterInputChangeHandler = propertyName => event => {
		setActivePage(1);
		if (propertyName === '') {
			setFilters(null);
			return;
		}
		let newFilters = {...filters};
		if (!newFilters) {
			newFilters = {};
		}
		if (!event.target.value) {
			delete newFilters[propertyName];
		} else {
			newFilters[propertyName] = event.target.value;
		}
		setFilters(newFilters);
	}
	
	const filteredAnswers = useMemo(() => {

		const formatAnswer = answer => ({
			'_id': answer.id,
			'_answer': answer,
			'ID': answer.id,
			'Date Créa': answer.created,
			'Équipe': getTeamType(types, getTeam(teams, answer.creator)),
			'Créateur': answer.creator,
			'Canal': getCanalType(types, answer.canal),
			'Réponse': answer.content,
			'Pièce jointe': answer.attachments,
		});

		const isFiltered = answer => {
			if (!filters) return true;
	
			for (var property in filters) {
				const filterValue = filters[property];
				const revertFilter = filterValue && filterValue.toString().indexOf('$') === 0;
				const cleanedValue = revertFilter ? filterValue.substring(1) : filterValue;
	
				let containsValue;
	
				if (property === 'Demande' || property === 'État') {
					containsValue = answer.hasOwnProperty(property) && answer[property] && answer[property].toString() === cleanedValue;
				} else {
					containsValue = answer.hasOwnProperty(property) && answer[property] && answer[property].toString().toLocaleLowerCase().indexOf(cleanedValue.toLocaleLowerCase()) !== -1;
				}
				if ((containsValue && revertFilter) || (!containsValue && !revertFilter)) {
					return false;
				}
			}
			return true;
		}

		return (answers || []).map(formatAnswer).filter(isFiltered);
	}, [answers, types, teams, filters]);
	
	return (
		<div>
			<TicketAnswersTable
				filteredAnswers={filteredAnswers}
				loading={loading}
				ticketClosed={ticketClosed}
				handleSaveAnswer={handleSaveAnswer}
				itemsPerPage={itemsPerPage}
				activePage={activePage}
				filters={filters}
				createFilterInputChangeHandler={createFilterInputChangeHandler}
			/>
			<TicketAnswersTablePagination
				currentPage={activePage}
				handlePageChange={setActivePage}
				itemsPerPage={itemsPerPage}
				numberOfAnswers={filteredAnswers.length}
			/>
		</div>
	);
});

export default TicketAnswers