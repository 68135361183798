import React from 'react';
import { Panel } from 'react-bootstrap';
import { graphql } from 'react-apollo';
import { GET_DEVICES_WITH_CORE_LIKE_COUNTER } from '../../../schema/DeviceMetaDataSchema';

const DevicesTableTitle = ({searchProperty: searchPropertyKey, searchValue, properties, data}) => {
	let searchProperty, searchPropertyLabel;
	if (searchValue && searchPropertyKey) {
		searchProperty = properties.find(property => property.key === searchPropertyKey);
		searchPropertyLabel = searchProperty?.name || searchPropertyKey;
	}
	const isSearchingByCoreVersion = searchPropertyKey === 'currentCoreVersion';
	const devicesWithCoreLike = data?.devicesWithCoreLike?.devicesWithCoreLike;
	return (
		<Panel.Heading>
			<Panel.Title componentClass='h6'>
				{isSearchingByCoreVersion && searchValue && devicesWithCoreLike !== undefined ? <><b>{devicesWithCoreLike}</b> devices</> : 'Devices'}
				{searchProperty && searchPropertyLabel && <>
					&nbsp;dont l{searchProperty.feminine ? 'a' : 'e'} <b>{searchPropertyLabel}</b> <span className='text-muted'>({searchPropertyKey})</span> vaut <b>{searchValue}</b>
				</>}
			</Panel.Title>
		</Panel.Heading>
	);
}

export default graphql(GET_DEVICES_WITH_CORE_LIKE_COUNTER, {
	options: ({searchValue}) => ({
		variables: {
			currentCoreVersion: searchValue,
		},
	}),
	skip: ({searchProperty}) => searchProperty !== 'currentCoreVersion',
})(DevicesTableTitle);