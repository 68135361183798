const objectEmptyStringsToNull = obj => {
  if (Object.isObject(obj)) {
    Object.entries(obj).forEach(
      ([key, value]) => {
        if (value === '') {
          obj[key] = null;
        } else if (Object.isObject(value)) {
          obj[key] = objectEmptyStringsToNull(value);
        }
      },
    );
  }

  return obj;
};

const removeExtraWhiteSpaces = str => {
  if(!str) return str;

  const arr = str.split('\n').map(_ => _.trim());
  return arr.join('\n');
};


export {
  objectEmptyStringsToNull,
  removeExtraWhiteSpaces,
};