import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderComponent } from 'recompose';
import { Col, ControlLabel, Label, OverlayTrigger, Popover, Row } from 'react-bootstrap';

import { getTagLabel } from '../../../../../utils/functions';
import { connectMeAndTeamsAndConstantsContext } from '../../../../../hoc/withMeAndTeamsAndConstants';


const AvailableShopInfoBlockWithoutDiscountPure = ({ contract }) => {
    const vendorInfoPopover = (
        <Popover id='vendor-information-popover' title={contract.vendor.ref}>
            <p>{contract.vendor.name}</p>
            <p>{contract.vendor.phone}</p>
            <p>{contract.vendor.mail}</p>
            <p>{contract.vendor.comment}</p>
        </Popover>
    );

    return (
        <Row>
            <Col md={12}>
                <ControlLabel>Canal de vente</ControlLabel>
                <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' overlay={vendorInfoPopover}>
                    <p>{contract.vendorLabel}</p>
                </OverlayTrigger>
            </Col>
        </Row>
    );
}

const UnavailableShopInfoBlockWithoutDiscountPure = ({ contract }) => (
    <Row>
        <Col md={12}>
            <ControlLabel>Canal de vente</ControlLabel>
            <p>{contract.vendorLabel  ? contract.vendorLabel : 'Indisponible'}</p>
        </Col>
    </Row>
);

const ShopInfoBlockWithoutDiscount = compose(
    branch(
        ({ contract }) => !!contract.vendor,
        renderComponent(AvailableShopInfoBlockWithoutDiscountPure),
        renderComponent(UnavailableShopInfoBlockWithoutDiscountPure),
    )
)();

const ShopInfoBlockWithDiscountPure = ({ contract, tags }) => (
    <div>
        <ShopInfoBlockWithoutDiscount contract={contract} />
        <Row>
            <Col md={12}>
                <ControlLabel>Offre promotionnelle</ControlLabel>
                {contract.vouchersCodes.map(tag =>
                    <p key={tag}><Label>{getTagLabel(tags, tag)}</Label></p>
                )}
            </Col>
        </Row>
    </div>
);

ShopInfoBlockWithDiscountPure.propTypes =  {
    tags: PropTypes.object,
};

const ShopInfoBlockWithDiscount = compose(
    connectMeAndTeamsAndConstantsContext,
)(ShopInfoBlockWithDiscountPure);

const ShopInfoBlock = compose(
    pure,
    branch(
        ({ contract }) => !!contract.vouchersCodes,
        renderComponent(ShopInfoBlockWithDiscount),
        renderComponent(ShopInfoBlockWithoutDiscount),
    ),
)();

ShopInfoBlock.propTypes = {
    contract: PropTypes.object,
};

export default ShopInfoBlock;