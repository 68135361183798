import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {Col, FormGroup, Row} from 'react-bootstrap';
import EditTicketTagsButton from './EditTicketTagsButton';
import TicketTagsSelect from './TicketTagsSelect';
import { sortAlpha } from '../../../../../utils/Array';

const formatTagsForSelect = (tags = []) => tags?.length ? tags.map(tag => ({value: tag, label: tag})) : [];

const formatTagsForSubmit = (tags = []) => tags?.length ? tags.map(data => data.value) : [];

const TicketTags = ({ ticket, tagList, handleSaveTicket }) => {
	const [editing, setEditing] = useState(false);
  const [tags, setTags] = useState(formatTagsForSelect(ticket?.metadata?.tags || []));

	const handleTagsChange = tags => {
		if (!editing) setEditing(true);
		setTags(tags);
	}


  const handleSaveTags = () => {
    const newTags = formatTagsForSubmit(tags);

		const metadata = ticket?.metadata || {};

    setEditing(false);
    handleSaveTicket({ticket: {metadata: {...metadata, tags: newTags}}});
  }

	const handleEdit = () => setEditing(true);

	const tagOptions = formatTagsForSelect(sortAlpha(tagList));
	
	return (
		<FormGroup>
			<Row>
				<Col md={10}>
					<TicketTagsSelect
						disabled={!editing}
						options={tagOptions}
						value={tags}
						onChange={handleTagsChange}
					/>
				</Col>
				<Col md={2}>
					<EditTicketTagsButton
						editing={editing}
						handleSaveTags={handleSaveTags}
						handleEdit={handleEdit}
					/>
				</Col>
			</Row>
		</FormGroup>
	);
}

TicketTags.propTypes = {
	ticket: PropTypes.object,
	tagList: PropTypes.array,
	handleSaveTicket: PropTypes.func,
};

export default TicketTags;