import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';

import {connectContractNumberContext} from '../../../../../../hoc/contractNumber';
import withLoading from '../../../../../../hoc/withLoading';
import withCreateCustomerMutation from '../../../../../../operations/Customer/Create';
import saveCustomer from '../../../../../../operations/Customer/Save';
import withUpdateCustomerMutation from '../../../../../../operations/Customer/Update';
import {successMsg} from '../../../../../../utils/Notify';
import JsonSchemaForm from '../../../../../components/Form/JsonSchemaForm';
import LayoutGridField from '../../../../../components/LayoutGridFields';

const getSchema = ({isACreation}) => {
    let customerTypologyType = {},
        extraRequiredProperties = [];

    if (isACreation) {
        customerTypologyType = {
            customerTypologyType: {
                type: 'string',
                title: 'Type d\'utilisateur*',
                enum: ['PART', 'PRO', 'DEMO'],
                enumNames: ['Particulier', 'Pro', 'Démo'],
            },
        };

        extraRequiredProperties.push('customerTypologyType');
    }

    return {
        type: 'object',
        properties: {
            ...customerTypologyType,
            gender: {type: 'string', title: 'Civilité*'},
            firstName: {type: 'string', title: 'Prénom*'},
            lastName: {type: 'string', title: 'Nom*'},
            company: {type: 'string', title: 'Société'},
            addressLine0: {type: 'string', title: 'Adresse*'},
            addressLine1: {type: 'string', title: 'Adresse 2'},
            addressLine2: {type: 'string', title: 'Adresse 3'},
            addressLine3: {type: 'string', title: 'Adresse 4'},
            zipCode: {type: 'string', title: 'Code Postal*'},
            city: {type: 'string', title: 'Ville*'},
            country: {type: 'string', title: 'Pays*'},
            email: {type: 'string', title: 'Email'},
            phone: {type: 'string', title: 'Téléphone'},
            cellphone: {type: 'string', title: 'Mobile'},
        },
        required: ['gender', 'firstName', 'lastName', 'addressLine0', 'zipCode', 'city', 'country', ...extraRequiredProperties],
    };
};

const getUiSchema = ({isACreation}) => {
    const customerTypologyType = isACreation ? [{
        'ui:row': [
            {'ui:col': {md: 12, children: ['customerTypologyType']}},
        ],
    }] : [];

    return {
        customerTypologyType: {
            'ui:widget': {
                component: 'radio',
                options: {
                    'inline': true,
                },
            },
        },
        'ui:field': 'layout_grid',
        'ui:layout_grid': [
            ...customerTypologyType,
            {
                'ui:row': [
                    {'ui:col': {md: 4, children: ['gender']}},
                    {'ui:col': {md: 4, children: ['firstName']}},
                    {'ui:col': {md: 4, children: ['lastName']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 12, children: ['company']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 12, children: ['addressLine0']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 12, children: ['addressLine1']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 12, children: ['addressLine2']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 12, children: ['addressLine3']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 4, children: ['zipCode']}},
                    {'ui:col': {md: 4, children: ['city']}},
                    {'ui:col': {md: 4, children: ['country']}},
                ],
            },
            {
                'ui:row': [
                    {'ui:col': {md: 4, children: ['email']}},
                    {'ui:col': {md: 4, children: ['phone']}},
                    {'ui:col': {md: 4, children: ['cellphone']}},
                ],
            },
        ],
    };
};

const SubscriberFormPure = ({
                                hideSubmitButton,
                                schema,
                                subscriber,
                                uiSchema,
                                handleSave,
                                setFormEl,
                                setSubscriber,
                            }) => (
    <JsonSchemaForm fields={{layout_grid: LayoutGridField}}
                    formData={subscriber?.current}
                    schema={schema}
                    showErrorList={false}
                    uiSchema={uiSchema}
                    onChange={({formData}) => setSubscriber({...subscriber, current: formData})}
                    onSubmit={handleSave}
                    setFormEl={el => setFormEl && setFormEl(el)}>

        <ButtonToolbar className='pull-right'
                       hidden={hideSubmitButton}>
            <Button type='submit' bsStyle='primary'>
                Mettre à jour les données souscripteur
            </Button>
        </ButtonToolbar>

    </JsonSchemaForm>
);

SubscriberFormPure.propTypes = {
    hideSubmitButton: PropTypes.bool,
    schema: PropTypes.object,
    subscriber: PropTypes.object,
    uiSchema: PropTypes.object,
    handleSave: PropTypes.func,
    setFormEl: PropTypes.func,
    setSubscriber: PropTypes.func,
};

const getSubscriberInput = subscriber => {
    const {addressLine0, addressLine1, addressLine2, addressLine3, isDemo, isPrepayed, ...otherFields} = subscriber;
    let addressLine = [];

    if (addressLine0) {
        addressLine.push(addressLine0);
    }

    if (addressLine1) {
        addressLine.push(addressLine1);
    }

    if (addressLine2) {
        addressLine.push(addressLine2);
    }

    if (addressLine3) {
        addressLine.push(addressLine3);
    }

    return {
        ...otherFields,
        addressLine,
    };
};

const handlers = withHandlers({
    handleSave: props => () => {
        const {subscriber, setSubscriber} = props;
        const subscriberInput = getSubscriberInput(subscriber?.current);

        saveCustomer(
            subscriberInput,
            subscriber.id,
            props,
            savedSubscriber =>
                setSubscriber({
                        ...savedSubscriber,
                        user: subscriberInput,
                    },
                    ()=> successMsg('Souscripteur sauvegardé')
                ),
        );
    },
});

const SubscriberForm = compose(
    connectContractNumberContext,
    withProps(
        props => {
            const {subscriber} = props;
            const {current} = subscriber || {};
            const {addressLine, ...otherProps} = current || {};

            Object.keys(otherProps).forEach(
                key => otherProps[key] === null ? otherProps[key] = undefined : otherProps[key],
            );

            return {
                schema: getSchema(props),
                subscriber: {
                    ...subscriber,
                    current: {
                        addressLine0: addressLine?.[0],
                        addressLine1: addressLine?.[1],
                        addressLine2: addressLine?.[2],
                        addressLine3: addressLine?.[3],
                        ...otherProps,
                    },
                },
                uiSchema: getUiSchema(props),
            };
        },
    ),
    withLoading,
    withCreateCustomerMutation,
    withUpdateCustomerMutation,
    handlers,
    withProps(
        ({handleSave, handleSaveSubscriber}) => ({
            handleSave: handleSaveSubscriber || handleSave,
        }),
    ),
    pure,
)(SubscriberFormPure);

SubscriberForm.propTypes = {
    isACreation: PropTypes.bool,
    hideSubmitButton: PropTypes.bool,
    subscriber: PropTypes.object,
    handleSaveSubscriber: PropTypes.func,
    setFormEl: PropTypes.func,
    setSubscriber: PropTypes.func,
};

export {
    SubscriberForm as default,
    getSubscriberInput,
};