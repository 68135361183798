import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { ExpandableCell, NullComponent } from '../../../../../components';
import AppVersionHistorySubtable from './AppVersionHistorySubtable';
import { isEmpty } from '../../../../../../utils/Array';

const APP_VERSIONS_COLUMNS = [
	{
		Header: 'Application',
		accessor: 'name',
		Cell: ExpandableCell,
	},
	{
		Header: 'Version',
		accessor: 'version',
		Cell: ExpandableCell,
	},
	{
		Header: 'Date de dernière mise à jour',
		accessor: 'lastUpdateDate',
		Cell: ExpandableCell,
		render: ({value}) => value || <span className="grey">Non renseignée</span>,
	},
];

export const EMPTY_APP_VERSIONS_ROW = {
	name: undefined,
	version: <span className="grey">Pas de détail des versions applicatives</span>,
	lastUpdateDate: ' ',
};

const AppVersionsTable = ({packagesWithVersion = {}}) => {
	const appVersions = Object.values(packagesWithVersion);
	const noAppVersions = isEmpty(appVersions);
	const data = noAppVersions ? [EMPTY_APP_VERSIONS_ROW] : appVersions;
	return (
		<ReactTable
			className='-striped -highlight app-versions-table'
			data={data}
			columns={APP_VERSIONS_COLUMNS}
			resizable={false}
			showPagination={false}
			pageSize={data.length}
			NoDataComponent={NullComponent}
			getTdProps={(state, rowInfo, column, instance) => ({className: 'app-version-cell'})}
			SubComponent={noAppVersions
				? NullComponent
				: AppVersionHistorySubtable
			}
		/>
	);
}

AppVersionsTable.propTypes = {
	packagesWithVersion: PropTypes.object,
};

export default AppVersionsTable;