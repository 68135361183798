import React from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useMemo } from 'react';
import { checkIfIsOtherResult, getResultType, getSubjectTypeLabel, isN2OrHigher } from '../../../../../utils/functions';
import { isNotEmpty } from '../../../../../utils/Array';
import { canSubmitResult, getSortedResultFirstIntentionOptions, getSortedResultOptionsWithoutFirstIntentions, getSubjectResultOptions } from '../../../../../utils/types';
import { connectTypesAndWebUserContext } from '../../../../../hoc/withMeAndTeamsAndConstants';
import { useState } from 'react';
import { getUnlockedSubjectOptions } from '../../../../../utils/types';

const ChangeSubjectModal = connectTypesAndWebUserContext(({
	types,
	webUserJson,
	show,
	onHide,
	closed,
	ticket,
	initialTicket,
	subjectOptions,
	resultOptions = [],
	handleTicketPropertyChange,
	onSubmit,
}) => {

	const [subject, setSubject] = useState(ticket.subject || '');
	const [result, setResult] = useState(ticket.result || '');
	const [firstIntention, setFirstIntention] = useState(ticket.firstIntention || '');
	const [resultComment, setResultComment] = useState(ticket.resultComment || '');

	const handleSubjectChange = event => {
		const newSubject = event.target.value;
		setSubject(newSubject);
		setResult('');
		setFirstIntention('');

		const isNotOtherResult = !checkIfIsOtherResult(newSubject);
		if (isNotOtherResult) setResultComment('');
	}

	const handleResultChange = event => {
		const newResult = event.target.value;
		setResult(newResult);
		setFirstIntention('');

		const isNotOtherResult = !checkIfIsOtherResult(result);
		if (isNotOtherResult) setResultComment('');
	}

	const handleCommentChange = event => setResultComment(event.target.value);

	const handleFirstIntentionChange = event => setFirstIntention(event.target.value);

	const handleSubmit = () => onSubmit({subject, result, firstIntention, resultComment});

	const unlockedSubjectOptions = useMemo(() => getUnlockedSubjectOptions(subjectOptions, types), [subjectOptions, types]);
  
	const {sortedSubjectresultOptions, firstIntentionOptions} = useMemo(() => {
		const subjectResultOptions = getSubjectResultOptions(resultOptions, subject);
		return {
			sortedSubjectresultOptions: getSortedResultOptionsWithoutFirstIntentions(subjectResultOptions, types),
			firstIntentionOptions: getSortedResultFirstIntentionOptions(subjectResultOptions, types, result)
		}
	}, [resultOptions, subject, types, result]);

	const isOtherResult = checkIfIsOtherResult(result);

	const submitResultDisabled = !canSubmitResult({firstIntention, firstIntentionOptions});
	const submitDisabled = subject === initialTicket.subject && (!closed || submitResultDisabled);

	return (
    <Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>Changer la demande</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormGroup>
					<ControlLabel>Demande</ControlLabel>
					<FormControl
						componentClass='select'
						data-test-id='subjectSelect'
						value={subject}
						onChange={handleSubjectChange}
						placeholder='Demande'
					>
						{unlockedSubjectOptions.map(type_demande => (
							<option key={type_demande} value={type_demande}>
								{getSubjectTypeLabel(types, type_demande)}
							</option>
						))}
					</FormControl>
				</FormGroup>

				{closed && <>
					<FormGroup>
						<ControlLabel>Résultat</ControlLabel>

						<FormControl
							componentClass='select'
							data-test-id='resultSelect'
							value={result}
							onChange={handleResultChange}
							placeholder='Cloture'
						>
							<option value=''>---</option>
							{sortedSubjectresultOptions.map(cloture => (
								<option key={cloture} value={cloture}>
									{getResultType(types, cloture)}
								</option>
							))}
						</FormControl>
					</FormGroup>

					<FormGroup>
						{isN2OrHigher(webUserJson) && isNotEmpty(firstIntentionOptions) && <>
							<FormGroup>
								<ControlLabel>Première intention</ControlLabel>
								<FormControl
									componentClass='select'
									data-test-id='firstIntentionSelect'
									value={firstIntention}
									onChange={handleFirstIntentionChange}
									placeholder='Première intention'
								>
									<option value=''>---</option>
									
									{firstIntentionOptions.map(firstIntention => (
										<option key={firstIntention} value={firstIntention}>
											{getResultType(types, firstIntention)}
										</option>
									))}
								</FormControl>
							</FormGroup>
							{isOtherResult && (
								<FormGroup>
									<ControlLabel>Commentaire</ControlLabel>
									<FormControl value={resultComment} rows={4} componentClass='textarea' onChange={handleCommentChange} />
								</FormGroup>
							)}
						</>}
					</FormGroup>
				</>}
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-id='changeSubjectModalSubmit'
					disabled={submitDisabled}
					onClick={handleSubmit}
				>
					Changer la demande
				</Button>
			</Modal.Footer>
    </Modal>
	);
});

ChangeSubjectModal.propTypes = {
	show: PropTypes.bool,
	onHide: PropTypes.func,
	onSubmit: PropTypes.func,
	ticket: PropTypes.object,
	closed: PropTypes.bool,
	initialTicket: PropTypes.object,
	subjectOptions: PropTypes.array,
	resultOptions: PropTypes.array,
	handleTicketPropertyChange: PropTypes.func,
};

export default ChangeSubjectModal;