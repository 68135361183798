import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {tsToDateForInput} from '../../../../../utils/Date';

const Software = ({currentCoreVersion = '', dmd}) => (
	<>
		<h4 style={{marginTop: '20px'}}>Logiciel</h4>
		<Row>
			<Col md={4}>
				<FormGroup>
					<ControlLabel>Version cible</ControlLabel>
					<FormControl readOnly type='text' value={dmd?.targetCoreVersion || ''} />
				</FormGroup>
			</Col>
			<Col md={4}>
				<FormGroup>
					<ControlLabel>Version actuelle</ControlLabel>
					<FormControl readOnly type='text' value={currentCoreVersion} />
				</FormGroup>
			</Col>
			<Col md={4}>
				<FormGroup>
					<ControlLabel>Dernière vérification MAJ</ControlLabel>
					<FormControl readOnly type='text' value={tsToDateForInput(dmd?.lastUpdateCheck || '')} />
				</FormGroup>
			</Col>
		</Row>
	</>
);

Software.propTypes = {
	currentCoreVersion: PropTypes.string,
	dmd: PropTypes.object,
};

export default Software;