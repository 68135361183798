import Aviator from 'aviator';

const registerCustomEvent = () => {
    (function () {
        if ( typeof window.CustomEvent === 'function' ) return false; //If not IE

        function CustomEvent ( event, params ) {
            params = params || { bubbles: false, cancelable: false, detail: undefined };
            var evt = document.createEvent( 'CustomEvent' );
            evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
            return evt;
        }

        CustomEvent.prototype = window.Event.prototype;

        window.CustomEvent = CustomEvent;
    })();
};

const registerUrlChangeEvent = () => {
    registerCustomEvent();
    Aviator.navigate = function(uri, options) {
        this._navigator.navigate(uri, options);
        window.dispatchEvent(new CustomEvent('urlchanged'), uri, options);
    };
};

export { registerUrlChangeEvent };