import React from 'react';
import {Col, Panel, Row} from 'react-bootstrap';
import FeatureForm from './Features/FeatureForm';
import FeaturesTable from './Features/FeaturesTable';

const Features = () => (
  <Panel bsStyle='success' className='center-block'>
    <Panel.Body>
      <Row>
        <Col md={8} mdOffset={2}>
          <FeatureForm />
        </Col>
      </Row>

      <Row>
        <Col md={8} mdOffset={2}>
          <FeaturesTable />
        </Col>
      </Row>
    </Panel.Body>
  </Panel>
);


export default Features;