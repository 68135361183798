import PropTypes from 'prop-types';
import React from 'react';
import './MaintenanceTable.css';
import MaintenanceTableRow from './MaintenanceTableRow';

const COLUMNS = [{
  header: 'Statut',
  width: '80px',
}, {
  header: 'Nom',
  width: '30%',
}, {
  header: 'Valeur',
}, {
  header: 'Seuils',
  width: '120px',
}, {
  header: 'Date',
  width: '200px',
}, {
  header: '',
  width: '40px',
}];

const MaintenanceTable = ({ metrics }) => (
  <table cellSpacing={10} className="maintenance-table">
    <thead>
    <tr>
      {COLUMNS.map((column, index) => (
        <th key={index} style={{width: column.width}}>
          {column.header}
        </th>
      ))}
    </tr>
    </thead>
    <tbody>
      {metrics.map(metric => (
        <MaintenanceTableRow key={metric.id} metric={metric} />
      ))}
    </tbody>
  </table>
);

MaintenanceTable.propTypes = {
  metrics: PropTypes.array,
};

export default MaintenanceTable;