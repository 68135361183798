import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import {compose, pure} from 'recompose';
import withGetCurrentSubscriptionType from './withGetCurrentSubscriptionType';

const SubscriptionTypeLabelPure = ({subscriptionType}) => (
  <FormGroup>
    <ControlLabel>Offre</ControlLabel>
    <FormControl data-test-id="subscription-type-label"
                 disabled
                 value={subscriptionType}/>
  </FormGroup>
);

SubscriptionTypeLabelPure.propTypes = {
  subscriptionType: PropTypes.string,
};

const SubscriptionTypeLabel = compose(
  withGetCurrentSubscriptionType(true),
  pure,
)(SubscriptionTypeLabelPure);

export default SubscriptionTypeLabel;