import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

const SimpleSelect = ({options = [], value = '', onChange, name, placeholder}) => (
  <FormGroup className='small-control' bsSize='small'>
    <FormControl
      componentClass='select'
			name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    >
      <option value=''>---</option>
      {options.map(({value, label}) =>
        <option key={value} value={value}>
          {label}
        </option>
      )}
    </FormControl>
  </FormGroup>
);

export default SimpleSelect;