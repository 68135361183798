import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import {compose, pure, withProps} from 'recompose';
import {tsToDateForInput} from '../../../../../utils/Date';
import {ReactTableTextProps} from '../../../../../utils/ReactTable';

const getColumns = () => ([
  {
    Header: 'Option',
    accessor: 'feature.label',
  }, {
    Header: 'Active',
    accessor: 'enabled',
    Cell: cell => cell.value ? 'Oui' : 'Non',
  }, {
    Header: 'Début',
    accessor: 'start',
    type: 'date',
    width: 80,
    Cell: cell => <div>{cell.value ? tsToDateForInput(cell.value) : null}</div>,
  }, {
    Header: 'Fin',
    accessor: 'end',
    type: 'date',
    width: 80,
    Cell: cell => <div>{cell.value ? tsToDateForInput(cell.value) : null}</div>,
  },
]);

const B2BFeaturesTablePure = ({
                                b2bFeatureRecords,
                                columns,
                              }) => (
  <ReactTable
    className="-highlight"
    columns={columns}
    data={b2bFeatureRecords}
    fill
    pageSize={b2bFeatureRecords.length}
    resizable={false}
    showPagination={false}
    style={{
      border: 'none',
      marginTop: 20,
    }}
    {...ReactTableTextProps}
  />
);

B2BFeaturesTablePure.propTypes = {
  b2bFeatures: PropTypes.array,
  columns: PropTypes.array,
};

const B2BFeaturesTable = compose(
  withProps(
    () => ({
      columns: getColumns(),
    }),
  ),
  pure,
)(B2BFeaturesTablePure);

B2BFeaturesTable.propTypes = {
  b2bFeatureRecords: PropTypes.array,
};

export default B2BFeaturesTable;