import React from 'react';

import TicketsTableRows from './TicketsTableRows';
import TicketsTableFilters from './TicketsTableFilters';

const TicketsTableBody = ({loading, noFilters, properties, filteredTickets, tagList, activePage, itemsByPage, setActivePage, filters, setFilters, selectedTicketID, actions}) => {
	return (
		<tbody>
			{!noFilters && (
				<TicketsTableFilters
					properties={properties}
					loading={loading}
					showClearButton={!!filteredTickets}
					setActivePage={setActivePage}
					tagList={tagList}
					filters={filters}
					setFilters={setFilters}
				/>
			)}
			<TicketsTableRows
				properties={properties}
				filteredTickets={filteredTickets}
				selectedTicketID={selectedTicketID}
				activePage={activePage}
				itemsByPage={itemsByPage}
				actions={actions}
			/>
		</tbody>
	);
}

export default TicketsTableBody