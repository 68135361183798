import React from 'react';
import AddFeaturesToContracts from './B2B/AddFeaturesToContracts';
import Features from './B2B/Features';
import DeleteLastSubscriptionState from './B2B/DeleteLastSubscriptionState';
import NavigableView from './NavigableView';

const B2B_FEATURES = [{
  component: Features,
  label: 'Gestion des options',
}, {
  component: AddFeaturesToContracts,
  label: 'Ajout/modification d\'options sur des contrats',
}, {
  component: DeleteLastSubscriptionState,
  label: 'Suppression de ligne d\'abonnement',
}];

const B2B = () => (
  <NavigableView conf={B2B_FEATURES}/>
);

B2B.propTypes = {};

export default B2B;