import React from 'react';
import _ from 'lodash';
import ObjectField from 'react-jsonschema-form/lib/components/fields/ObjectField';
import { retrieveSchema } from 'react-jsonschema-form/lib/utils';
import { Col } from 'react-bootstrap';
import { setIn } from '../../utils/functions';

export default class LayoutGridField extends ObjectField {
    render() {
        const { uiSchema } = this.props;
        let { layoutGridSchema } = this.props;
        if (!layoutGridSchema && uiSchema['ui:layout_grid']) {
            layoutGridSchema = uiSchema['ui:layout_grid'];
            return <div>{this.renderChildren(layoutGridSchema)}</div>;
        }
        if (layoutGridSchema['ui:row']) {
            return this.renderRow(layoutGridSchema);
        }
        if (layoutGridSchema['ui:col']) {
            return this.renderCol(layoutGridSchema);
        }
        return this.renderField(layoutGridSchema);
    }

    renderRow(layoutGridSchema) {
        const rows = layoutGridSchema['ui:row'];

        const group = layoutGridSchema['ui:group'];

        if (group) {
            const { fields, formContext } = this.props.registry;
            const { TitleField } = fields;
            const { required } = this.props;
            const title = group && typeof group === 'string' ? group : null;

            return (
                <fieldset className="rjsf-layout-grid-group">
                    {title ? <TitleField
                        title={title}
                        required={required}
                        formContext={formContext}
                    /> : null}
                    {<div className="row">{this.renderChildren(rows)}</div>}
                </fieldset>
            );
        }
        return <div className="row">{this.renderChildren(rows)}</div>;
    }

    renderCol(layoutGridSchema) {
        const { children, ...colProps } = layoutGridSchema['ui:col'];

        const group = layoutGridSchema['ui:group'];

        if (group) {
            const { fields, formContext } = this.props.registry;
            const { TitleField } = fields;
            const { required } = this.props;
            const title = group && typeof group === 'string' ? group : null;

            return (
                <Col {...colProps}>
                    <fieldset className="rjsf-layout-grid-group">
                        {title ? <TitleField
                            title={title}
                            required={required}
                            formContext={formContext}
                        /> : null}
                        {this.renderChildren(children)}
                    </fieldset>
                </Col>
            );
        }
        return <Col {...colProps}>{this.renderChildren(children)}</Col>;
    }

    renderChildren(childrenLayoutGridSchema) {
        const { definitions } = this.props.registry;
        const schema = retrieveSchema(this.props.schema, definitions);

        return childrenLayoutGridSchema.map((layoutGridSchema, index) => (
            <LayoutGridField
                {...this.props}
                key={index}
                schema={schema}
                layoutGridSchema={layoutGridSchema}
            />
        ));
    }

    onPropertyChange = path => value => {
        const newFormData = setIn(this.props.formData, path, value);
        this.props.onChange(newFormData);
    };

    renderField(layoutGridSchema) {
        const {
            uiSchema,
            errorSchema,
            idSchema,
            disabled,
            readonly,
            onBlur,
            onFocus,
            formData,
        } = this.props;
        const { definitions, fields } = this.props.registry;
        const { SchemaField } = fields;
        const schema = retrieveSchema(this.props.schema, definitions);
        let field;
        let name;
        let render;
        let required;
        if (typeof layoutGridSchema === 'string') {
            const tree = layoutGridSchema.split(/[./:]/);
            const properties = tree.map((p) => `properties.${p}`);
            const path = properties.join('.');
            properties.pop();
            const parentPath = properties.join('.');
            const parent = _.get(schema, parentPath);
            required = parent && parent.required ? parent.required : undefined;
            field = _.get(schema, path);
            name = tree.pop();
        } else {
            name = layoutGridSchema.name;
            render = layoutGridSchema.render;
        }
        if (field) {
            return (
                <SchemaField
                    name={name}
                    required={required && required.indexOf(name) > -1}
                    schema={field}
                    uiSchema={_.get(uiSchema, layoutGridSchema)}
                    errorSchema={_.get(errorSchema, layoutGridSchema)}
                    idSchema={_.get(idSchema, layoutGridSchema)}
                    formData={_.get(formData, layoutGridSchema)}
                    onChange={this.onPropertyChange(layoutGridSchema)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    registry={this.props.registry}
                    disabled={disabled}
                    readonly={readonly}
                />
            );
        }
        const UIComponent = render || (() => null);

        return (
            <UIComponent
                name={name}
                formData={formData}
                errorSchema={errorSchema}
                uiSchema={uiSchema}
                schema={schema}
                registry={this.props.registry}
            />
        );
    }
}
