import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateFunction, withButtonContext} from './Helpers';

const SetNotActivatedButtonPure = ({
                                     buttonSize,
                                     onValidateClick,
                                   }) => (
  <Button bsStyle='warning' bsSize={buttonSize} onClick={onValidateClick}>
    Annuler l'activation
  </Button>
);

SetNotActivatedButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  onValidateClick: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateFunction,
});

const SetNotActivatedButton = compose(
  withButtonContext,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.cancelActivation,
    }),
  ),
  handlers,
  pure,
)(SetNotActivatedButtonPure);

SetNotActivatedButton.propTypes = {};

export default SetNotActivatedButton;