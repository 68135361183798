import React from 'react';
import {FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {compose, pure, withProps} from 'recompose';

const SelectPure = ({ disabled, mandatory, options, value, handleChange }) => (
    <FormControl componentClass='select'
                 disabled={disabled || false}
                 value={value}
                 onChange={handleChange}>
        {!mandatory && <option value='' className='hiddenOption'/>}
        {options}
    </FormControl>
);

SelectPure.propTypes = {
    options: PropTypes.array,
    value: PropTypes.string,
    handleChange: PropTypes.func,
};

const withCalculatedProps = withProps(
    ({ arrayValues }) => ({
        options: arrayValues.map(
            ({ label, value }) => <option key={value} value={value}>{label}</option>
        ),
    })
);

const Select = compose(
    withCalculatedProps,
    pure,
)(SelectPure);

Select.propTypes = {
    arrayValues: PropTypes.array,
    disabled: PropTypes.bool,
    mandatory: PropTypes.bool,
    value: PropTypes.string,
    handleChange: PropTypes.func,
};

export default Select;