import PropTypes from 'prop-types';
import React from 'react';
import {branch, compose, getContext, pure, renderNothing, withHandlers, withProps, withState} from 'recompose';
import { SUBSCRIPTION_TYPE_GROUPS } from '../../../../../../../../utils/Subscription';
import Filter from './Filter';
import {
  getClassificationProps,
  getUpdatedClassification,
  getUpdatedClassificationWithResetFields,
  hasOptions,
  isSameClientTypology,
  isSameGroup,
  resetFieldValue,
  subscriptionOptionsMatchesClassificationValue,
} from './SubscriptionTypeSelectorHelpers';

export const SUBSCRIPTION_OPTIONS = {
  breakageInsurance: {name: 'breakageInsurance', values: ['ASSURANCE_CASSE']},
  clientTypology: {name: 'clientTypology', values: ['B2C', 'B2B', 'LEGACY']},
  group: {
    name: 'group',
    values: Object.values(SUBSCRIPTION_TYPE_GROUPS),
  },
  sim: {name: 'sim', values: ['SIM', 'SIM_PLUS']},
  pack: {name: 'pack', values: ['PACK']},
};

let SUBSCRIPTION_OPTION_ADMIN_MODIFICATION = SUBSCRIPTION_OPTIONS

export const FILTERED_CLIENT_TYPOLOGIES = ['LEGACY'];

const SubscriptionTypeSelectorPure = ({
                                        breakageInsuranceOptions,
                                        classification,
                                        clientTypologies,
                                        groups,
                                        packOptions,
                                        simOptions,
                                        onFilterChange,
                                        withoutOnlySimGroupOption = false // TODO (CAN-556) : refactor this compo to remove this specialized prop
                                      }) => (
  <div>
    <Filter filterValue={classification.clientTypology}
            label="Type de client"
            name={SUBSCRIPTION_OPTIONS.clientTypology.name}
            options={clientTypologies}
            onFilterChange={(option) => {onFilterChange(SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.clientTypology.name, option); updateOptionsB2C(option)}}/>

    <Filter filterValue={classification.group}
            label="Type d'offre"
            name={SUBSCRIPTION_OPTIONS.group.name}
            options={groups.filter(s => !withoutOnlySimGroupOption || s !== 'ONLY_SIM')}
            onFilterChange={(option) => onFilterChange(SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.group.name, option)}/>

    <Filter filterValue={classification.pack}
            label="Pack"
            name={SUBSCRIPTION_OPTIONS.pack.name}
            options={packOptions}
            onFilterChange={(option) => onFilterChange(SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.pack.name, option)}/>

    <Filter filterValue={classification.breakageInsurance}
            label="Option casse"
            name={SUBSCRIPTION_OPTIONS.breakageInsurance.name}
            options={breakageInsuranceOptions}
            onFilterChange={(option) => onFilterChange(SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.breakageInsurance.name, option)}/>

    <Filter filterValue={classification.sim}
            label="Option Sim"
            name={SUBSCRIPTION_OPTIONS.sim.name}
            options={simOptions}
            onFilterChange={(option) => onFilterChange(SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.sim.name, option)}/>
  </div>
);

function updateOptionsB2C(option) {
    if (option === 'B2C') {
        SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.breakageInsurance.values = ['SANS'];
        SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.sim.values = ['SANS'];
    } else {
        SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.breakageInsurance.values = ['ASSURANCE_CASSE'];
        SUBSCRIPTION_OPTION_ADMIN_MODIFICATION.sim.values = ['SIM', 'SIM_PLUS'];
    }
}

SubscriptionTypeSelectorPure.propTypes = {
  breakageInsuranceOptions: PropTypes.array,
  classification: PropTypes.object,
  clientTypologies: PropTypes.array,
  groups: PropTypes.array,
  packOptions: PropTypes.array,
  simOptions: PropTypes.array,
  onFilterChange: PropTypes.func,
};

const withClassificationProps = withProps(getClassificationProps);

const withClassificationState = withState(
  'classification',
  'setClassification',
  {
    breakageInsurance: resetFieldValue,
    clientTypology: {
      needToBeFilled: true,
      value: null,
    },
    group: resetFieldValue,
    pack: resetFieldValue,
    sim: resetFieldValue,
  },
);

const handlers = withHandlers({
  onFilterChange: ({
                     classification,
                     currentSubscriptionType,
                     subscriptionState,
                     subscriptionTypes,
                     setClassification,
                     updateSubscriptionType,
                   }) => async (property, option) => {
    const {
      breakageInsuranceOptions,
      groups,
      packOptions,
      simOptions,
    } = getClassificationProps({
      classification: getUpdatedClassificationWithResetFields(classification, property, option),
      currentSubscriptionType,
      subscriptionState,
      subscriptionTypes,
    });

    const hasBreakageInsuranceOptions = hasOptions(breakageInsuranceOptions);
    const hasGroups = hasOptions(groups);
    const hasPackOptions = hasOptions(packOptions);
    const hasSimOptions = hasOptions(simOptions);

    const updatedClassification = getUpdatedClassification({
      classification,
      hasBreakageInsuranceOptions,
      hasGroups,
      hasPackOptions,
      hasSimOptions,
      option,
      property,
    });

    await setClassification(updatedClassification);

    const currentSelectedSubscriptionType = subscriptionTypes.find(
      subscriptionType =>
        isSameClientTypology(updatedClassification, subscriptionType) &&
        isSameGroup(updatedClassification, subscriptionType) &&
        subscriptionOptionsMatchesClassificationValue(subscriptionType, updatedClassification.pack, SUBSCRIPTION_OPTIONS.pack.values) &&
        subscriptionOptionsMatchesClassificationValue(subscriptionType, updatedClassification.sim, SUBSCRIPTION_OPTIONS.sim.values) &&
        subscriptionOptionsMatchesClassificationValue(subscriptionType, updatedClassification.breakageInsurance, SUBSCRIPTION_OPTIONS.breakageInsurance.values),
    );

    updateSubscriptionType(currentSelectedSubscriptionType?.subscriptionType);
  },
});

const SubscriptionTypeSelector = compose(
  getContext(
    {
      currentSubscriptionType: PropTypes.string,
      subscriptionState: PropTypes.object,
      subscriptionTypes: PropTypes.array,
    },
  ),
  branch(
    ({subscriptionTypes}) => !subscriptionTypes || subscriptionTypes.length === 0,
    renderNothing,
  ),
  withClassificationState,
  withClassificationProps,
  handlers,
  pure,
)(SubscriptionTypeSelectorPure);

SubscriptionTypeSelector.propTypes = {
  updateSubscriptionType: PropTypes.func,
};

export default SubscriptionTypeSelector;