import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Alert, Button, ButtonToolbar, Checkbox, Col, ControlLabel, FormControl, Row} from 'react-bootstrap';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {SWAP_SUBSCRIPTIONS_TO_EQUILIBRE} from '../../../schema/SubscriptionSchema';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg} from '../../../utils/Notify';
import {
  formatContractNumbers,
  formatContractNumbersForSave,
  isContractNumbersMalformed,
  withToucanListOperationResult,
} from '../ContractsHelpers';

const MigrateClassicSubscriptionStateToEquilibrePure = ({
                                                          canSave,
                                                          contractNumbers,
                                                          isFidelity,
                                                          handleSave,
                                                          setContractNumbers,
                                                          setIsFidelity,
                                                        }) => (
  <>
    <Row>
      <Col md={12}>
        <Alert bsStyle="warning">A la fin de chaque traitement, un ticket N3T est créé. Il contient le résultat de la migration d'abonnement pour chaque contrat</Alert>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <FormGroup>
          <ControlLabel>Numéros de contrat *</ControlLabel>
          <FormControl componentClass="textarea"
                       data-test-id="contract-numbers-textarea"
                       rows={4}
                       type="text"
                       value={contractNumbers}
                       onChange={e => setContractNumbers(formatContractNumbers(e.target.value))}/>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <FormGroup>
          <Checkbox checked={isFidelity}
                    onChange={e => setIsFidelity(e.target.checked)}>
            Migrer vers les offres <b>fidélité</b> correspondantes
          </Checkbox>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <ButtonToolbar className="pull-right" style={{paddingTop: 10}}>
          <Button bsStyle="primary"
                  data-test-id="migrate-button"
                  disabled={!canSave}
                  onClick={handleSave}>
            Migrer les abonnements
          </Button>
        </ButtonToolbar>
      </Col>
    </Row>
  </>
);

MigrateClassicSubscriptionStateToEquilibrePure.propTypes = {
  canSave: PropTypes.bool,
  contractNumbers: PropTypes.string,
  handleSave: PropTypes.func,
  setContractNumbers: PropTypes.func,
};

const withContractNumbersState = withState('contractNumbers', 'setContractNumbers', undefined);
const withIsFidelityState = withState('isFidelity', 'setIsFidelity', false);

const mutationErrorReasons = {
  TICKET: 'Erreur lors de la création du ticket N3T, le traitement a cependant été effectué veuillez contacter les administrateurs du site',
  UNAUTHORIZED: 'Vous n\'avez pas les droits nécessaires pour effectuer cette action',
};

export const SwapSubsriptionToEquilibreFailure = {
  INVALID_SOURCE: 'INVALID_SOURCE',
  NOT_ACTIVATED: 'NOT_ACTIVATED',
  SAVE: 'SAVE',
  SUBSCRIPTION_NOT_FOUND: 'SUBSCRIPTION_NOT_FOUND',
  SUBSCRIPTION_STATES_NOT_FOUND: 'SUBSCRIPTION_STATES_NOT_FOUND',
  WAITING_SUBSCRIPTION_STATE: 'WAITING_SUBSCRIPTION_STATE',
}

const convertResult = (results, {setResult}) => {
  const {failures, successes} = results;
  const resultToDisplay = {errors: [], success: []};

  if (successes?.length) {
    resultToDisplay.success = successes.map(_ => `Abonnement du contrat ${_.contractNumber} mis à jour vers ${_.type}`);
  }

  if (failures?.length) {
    resultToDisplay.errors = failures.map(
      ({contractNumber, errorReason}) => {
        switch (errorReason) {
          case SwapSubsriptionToEquilibreFailure.INVALID_SOURCE:
            return `Contrat ${contractNumber} : Type d'abonnement actuel non éligible à la migration`;
          case SwapSubsriptionToEquilibreFailure.NOT_ACTIVATED:
            return `Contrat ${contractNumber} : Abonnement actuel non actif`;
          case SwapSubsriptionToEquilibreFailure.SAVE:
            return `Contrat ${contractNumber} : Erreur de sauvegarde de la nouvelle ligne d'abonnement`;
          case SwapSubsriptionToEquilibreFailure.SUBSCRIPTION_NOT_FOUND:
            return `Contrat ${contractNumber} : Abonnement actuel non trouvé`;
          case SwapSubsriptionToEquilibreFailure.SUBSCRIPTION_STATES_NOT_FOUND:
            return `Contrat ${contractNumber} : Ligne d'abonnement actuelle non trouvée`;
          case SwapSubsriptionToEquilibreFailure.WAITING_SUBSCRIPTION_STATE:
            return `Contrat ${contractNumber} : Nouvelle ligne d'abonnement prévue à échéance`;
          default:
            return `Contrat ${contractNumber} : Une erreur inconnue est survenue`;
        }
      },
    );
  }

  setResult(resultToDisplay);
};

const submitMigrateClassicSubscriptionStateToEquilibre = mutate => ({contractNumbers}, props) => {
  const {isFidelity, setLoading} = props;
  setLoading(
    true,
    () => mutate({
      variables: {
        input: {
          contractNumbers,
          isFidelity,
        },
      },
      update: (_store, {data}) => {
        setLoading(false);

        const results = data.swapSubscriptionsToEquilibre;

        if (results.errorReason) {
          errorMsg(mutationErrorReasons[results.errorReason]);
        } else {
          convertResult(results, props);
        }
      },
    }).then(
      emptyFunction,
      error => {
        console.error(error);
        setLoading(false, () => errorMsg('Erreur lors de la migration des lignes d\'abonnement'));
      },
    ),
  );
};

const withMigrateClassicSubscriptionStateToEquilibreMutation = graphql(SWAP_SUBSCRIPTIONS_TO_EQUILIBRE, {
  props: ({mutate}) => ({
    handleMigrateClassicSubscriptionStateToEquilibre: submitMigrateClassicSubscriptionStateToEquilibre(mutate),
  }),
});

const handlers = withHandlers({
  handleSave: props => () => {
    const {
      author,
      contractNumbers,
      dateEnd,
      isDateEndNeeded,
      subscriptionType,
      handleMigrateClassicSubscriptionStateToEquilibre,
    } = props;
    const formattedContractNumbers = formatContractNumbersForSave(contractNumbers);

    handleMigrateClassicSubscriptionStateToEquilibre(
      {
        contractNumbers: formattedContractNumbers,
        subscriptionState: {
          author,
          dateEnd: isDateEndNeeded ? dateEnd : undefined,
          type: subscriptionType,
        },
      },
      props,
    );
  },
});

export const getCanSave = ({
                             contractNumbers,
                           }) => !!(
  contractNumbers &&
  !isContractNumbersMalformed(contractNumbers)
);

const MigrateClassicSubscriptionStateToEquilibre = compose(
  connectMeAndTeamsAndConstantsContext,
  withContractNumbersState,
  withIsFidelityState,
  withProps(({
               contractNumbers,
               dateEnd,
               webUserJson,
             }) => {
    return {
      author: webUserJson.email,
      canSave: getCanSave({
        contractNumbers,
        dateEnd,
      }),
    };
  }),
  withToucanListOperationResult,
  withLoading,
  withMigrateClassicSubscriptionStateToEquilibreMutation,
  handlers,
  pure,
)(MigrateClassicSubscriptionStateToEquilibrePure);

MigrateClassicSubscriptionStateToEquilibre.propTypes = {};

export default MigrateClassicSubscriptionStateToEquilibre;