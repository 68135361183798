import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateFunction, withButtonContext} from './Helpers';

const CancelChangePlanButtonPure = ({
                                      buttonSize,
                                      onValidateClick,
                                    }) => (
  <Button bsStyle='warning' bsSize={buttonSize} onClick={onValidateClick}>
    Annuler le changement<br/> de forfait
  </Button>
);

CancelChangePlanButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  onValidateClick: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateFunction,
});


const CancelChangePlanButton = compose(
  withButtonContext,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.cancelChangePlan,
    }),
  ),
  handlers,
  pure,
)(CancelChangePlanButtonPure);

CancelChangePlanButton.propTypes = {};

export default CancelChangePlanButton;