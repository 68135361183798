import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

const ImportOldDeviceModal = ({show, importOldDeviceTag, SN, handleClose, handleSubmit, createInputChangeHandler}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Importer des tablettes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <ControlLabel>SN</ControlLabel>
          <FormControl
            componentClass='textarea'
            type='text'
            value={SN}
            onChange={createInputChangeHandler('SN')}
          />
          <ControlLabel>TAG</ControlLabel>
          <FormControl
            type='text'
            value={importOldDeviceTag}
            onChange={createInputChangeHandler('importOldDeviceTag')}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle='success' onClick={handleClose}>
          Annuler
        </Button>
        <Button bsStyle='danger' className='pull-left' onClick={handleSubmit}>
          Importer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportOldDeviceModal;