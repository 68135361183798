import React from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import { compose, pure, withHandlers, withProps } from 'recompose';
import moment from 'moment';

import { DatePicker } from '../../components/DatePicker';
import { FRENCH_DAY_LABELS, FRENCH_MONTH_LABELS } from '../../../utils/constants';

const DateBlockPure = ({ noLaterThanDay, takeActionDay, handleChangeDate }) => (
	<Row>
		<Col md={6}>
			<FormGroup>
				<ControlLabel>A traiter le</ControlLabel>
				<DatePicker value={takeActionDay} onChange={(value) => handleChangeDate('takeActionDay', value)} weekStartsOnMonday monthLabels={FRENCH_MONTH_LABELS} dayLabels={FRENCH_DAY_LABELS} />
			</FormGroup>
		</Col>
		<Col md={6}>
			<FormGroup>
				<ControlLabel>Au plus tard le</ControlLabel>
				<DatePicker value={noLaterThanDay} onChange={(value) => handleChangeDate('noLaterThanDay', value)} weekStartsOnMonday monthLabels={FRENCH_MONTH_LABELS} dayLabels={FRENCH_DAY_LABELS} />
			</FormGroup>
		</Col>
	</Row>
);

DateBlockPure.propTypes = {
	handleChangeDate: PropTypes.func,
};

const getProps = withProps(
	({ ticket }) => ({
		noLaterThanDay: ticket.noLaterThanDay ? new Date(ticket.noLaterThanDay).toISOString() : null,
		takeActionDay: ticket.takeActionDay ? new Date(ticket.takeActionDay).toISOString() : null,
	})
);

const handlers = withHandlers({
	handleChangeDate: ({ ticket, handleChange }) => (propsName, value) => {
		ticket[propsName] = moment(value).valueOf();

		if (propsName === 'takeActionDay') {
			delete ticket['noLaterThanDay'];
		} else if (propsName === 'noLaterThanDay') {
			delete ticket['takeActionDay'];
		}

		handleChange(ticket);
	},
});

const DateBlock = compose(
	getProps,
	handlers,
	pure,
)(DateBlockPure);

DateBlock.propTypes = {
	ticket: PropTypes.object,
	handleChange: PropTypes.func,
};

export default DateBlock;