import PropTypes from 'prop-types';
import React from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {compose, pure, withState} from 'recompose';

import ClientUser, {USER_TYPES} from './Client/ClientUser';

const ClientPure = ({contract, dmd, loadContactInfo, selectedKey, setSelectedKey}) => (
    <Tabs bsStyle='pills' activeKey={selectedKey} onSelect={(key) => setSelectedKey(key)} id='users-tabs'>
        <Tab eventKey={1} title='Utilisateur'>
            <ClientUser contract={contract}
                        dmd={dmd}
                        userType={USER_TYPES.MAIN_USER}
                        loadContactInfo={infos => loadContactInfo('user', infos)}
                        selectBuyer={() => setSelectedKey(3)}/>
        </Tab>
        <Tab eventKey={2} title='Souscripteur'>
            <ClientUser contract={contract}
                        dmd={dmd}
                        userType={USER_TYPES.SUBSCRIBER}
                        loadContactInfo={infos => loadContactInfo('subscriber', infos)}/>
        </Tab>
        <Tab eventKey={3} title='Acheteur'>
            <ClientUser buyer
                        contract={contract}
                        dmd={dmd}
                        userType={USER_TYPES.BUYER}
                        loadContactInfo={infos => loadContactInfo('buyer', infos)}/>
        </Tab>
    </Tabs>
);

ClientPure.propTypes = {
    contract: PropTypes.object,
    dmd: PropTypes.object,
    selectedKey: PropTypes.number,
    setSelectedKey: PropTypes.func,
};

const withSelectedKeyState = withState('selectedKey', 'setSelectedKey', 1);

const Client = compose(
    withSelectedKeyState,
    pure,
)(ClientPure);

Client.propTypes = {
    contract: PropTypes.object,
    dmd: PropTypes.object,
    loadContactInfo: PropTypes.func,
};

export default Client;