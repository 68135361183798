import PropTypes from 'prop-types';
import React from 'react';
import {NavItem} from 'react-bootstrap';
import {connectWebUserContext} from '../../hoc/withMeAndTeamsAndConstants';
import { isMaintenanceVisible } from '../../utils/accessRights';
import { navigateTo } from '../../utils/functions';

const MaintenanceSection = connectWebUserContext(({ webUserJson, active }) => {
  if (isMaintenanceVisible(webUserJson)) {
    return (
      <NavItem
        key="maintenance"
        active={active}
        onClick={() => navigateTo('/maintenance')}
      >
        Maintenance
      </NavItem>
    );
  }

  return null;
});

MaintenanceSection.propTypes = {
  active: PropTypes.bool,
};

export default MaintenanceSection;