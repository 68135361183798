import React, { Component } from 'react';

import FontAwesome from './FontAwesome';

class FAIconWithText extends Component {
    render() {
        let bsStyle = 'pill-'+this.props.bsStyle;
        return (

            <span>
                    <FontAwesome name={this.props.icon} className={bsStyle} spin={this.props.spin} pulse={this.props.pulse}/> {this.props.text}
                </span>
        )
    }
}

export default FAIconWithText;