import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Panel } from 'react-bootstrap';

import { connectWebUserContext } from '../../../hoc/withMeAndTeamsAndConstants';
import {filterTeamTransfer, getSubjectValues} from '../../../utils/functions';
import { fetchTicketTypes } from '../../../utils/requests';

import NewTicket from './NewTicket';

const CreateNewTicketsPanel = connectWebUserContext(({ webUserJson, tagList, setTicket }) => {

	const [index, setIndex] = useState(0);
	const [options, setOptions] = useState({
		type_canal: [],
		type_cloture: [],
		type_demande: [],
		type_etat: [],
		type_team: [],
	});
	const [tickets, setTickets] = useState([]);

	useEffect(function useFetchTicketTypes() {
																	
		const setTicketTypes = response => {
			let options = {...response};
			
			options.type_team = filterTeamTransfer(options.type_team, webUserJson);
			options.type_demande = getSubjectValues(options.type_demande, webUserJson);
			
			setOptions(options);
		}

		fetchTicketTypes().then(setTicketTypes);
	}, [webUserJson]);

	
	const handleRemove = index => {
		let newTickets = [...tickets];
		delete newTickets[index];
		setTickets(newTickets);
	}
	
	const handleSaveCallback = (index, ticket, success) => {
		if (success) {
			handleRemove(index, ticket);
		}
	}
	
	const handleAddTicket = () => {
		let newTickets = [...tickets];
		newTickets[index] = {
			content: '',
			subject: '---',
			canal: '---',
			result: '',
			team: webUserJson.team,
			state: '0'
		};
		setIndex(index + 1);
		setTickets(newTickets);
	}

	const handleChange = (index, ticket) => {
		let newTickets = [...tickets];
		newTickets[index] = ticket;
		setTickets(newTickets);
	}
	
	const handleSave = (index, data, callback) => {
		setTicket(index, data, (index, ticket, success) => {
			handleSaveCallback(index, ticket, success);
			if (callback) {
				callback(index, ticket, success);
			}
		});
	}

	return (
		<Panel bsStyle='success'>
			<Panel.Heading>Nouveau ticket</Panel.Heading>
			<Panel.Body>
				{tickets.map((ticket, index) => ticket && (
					<NewTicket
						key={index}
						index={index}
						ticket={ticket}
						options={options}
						tagList={tagList}
						handleTicketRemove={ticket => handleRemove(index, ticket)}
						handleTicketChange={ticket => handleChange(index, ticket)}
						handleTicketSave={(ticket, callback) => handleSave(index, ticket, callback)}
					/>
				))}
				<Button className='pull-right' bsStyle='success' onClick={handleAddTicket}>Créer un ticket</Button>
			</Panel.Body>
		</Panel>
	);
});

CreateNewTicketsPanel.propTypes = {
	tagList: PropTypes.array,
	setTicket: PropTypes.func,
};

export default CreateNewTicketsPanel;