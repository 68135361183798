import React from 'react';

import {Date, TicketState, TicketPriority, TicketTakeActionDay, TicketNoLaterThanDay, TicketSubject, TicketTags, TicketContentWithLastAnswer} from '../../components';

const TicketsTableCell = ({ticket, property}) => {

	const value = ticket[property.name];
	let render = value;

	if (property.type === 'date') render = <Date value={value} />;

	switch (property.name) {

		case 'noLaterThanDay':
			render = <TicketNoLaterThanDay value={value} />;
			break;

		case 'takeActionDay':
			render = <TicketTakeActionDay value={value} />;
			break;

		case 'subjectLabel':
			render = <TicketSubject value={ticket.subject} label={value} />;
			break;

		case 'content':
			render = <TicketContentWithLastAnswer result={ticket.result} content={value} lastAnswer={ticket.lastAnswer} />;
			break;

		case 'priority':
			render = <TicketPriority value={value} />;
			break;

		case 'state':
			render = <TicketState value={value} />;
			break;

		case 'tags':
			render = <TicketTags value={value} />;
			break;

		default:
			break;
	}

	return (
		<td key={ticket.id + '_' + property.name} style={{ wordBreak: 'break-all' }}>
			{render}
		</td>
	);
};

export default TicketsTableCell;