import React, { Component } from 'react';
import { Col, ControlLabel, Image, Panel, Row } from 'react-bootstrap';

import jsRoutes from '../../../../routes/routes';
import DeliveryState from '../../../components/DeliveryState';
import LoadingProgress from '../../../components/LoadingProgress';

import iconStatus from '../../../../images/canari/icon-status.png';

class DeliveryBlock extends Component {
    constructor(props) {
        super(props);
        this.state = this.getComponentInitialState();
    }

    getComponentInitialState() {
        return ({deliveryInfo: [], loading: true});
    }

    componentDidMount() {
        this.getStatusTracking(this.props.trackingNumber);
    }

    componentDidUpdate(previousProps) {
        if (previousProps !== this.props) {
            this.setState(this.getComponentInitialState());
            this.getStatusTracking(this.props.trackingNumber);
        }
    }

    getStatusTracking(trackingNumber) {
        let route = jsRoutes.controllers.backoffice.SupportV2Controller.getStatusTracking('chronopost', trackingNumber);
        let _this = this;
        route.ajax({
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            processData: false
        }).then(function (response, textStatus, jqXHR) {
            _this.setState({deliveryInfo: response, loading: false});
        }, function (jqXHR, textStatus, errorThrown) {
            _this.setState({loading: false});
        });
    }

    render() {

        return (
            <Panel bsStyle='warning' className='with-loading'>
                <Panel.Heading>Livraison</Panel.Heading>
                <Panel.Body>
                    <LoadingProgress show={this.state.loading}/>
                    <Row>
                        <Col md='2'>
                            <Image src={iconStatus} />
                        </Col>
                        <Col md='10'>
                            <Row>
                                <Col md='6'>
                                    <ControlLabel>N° de suivi</ControlLabel>
                                    <p>{this.state.deliveryInfo.code}</p>
                                </Col>
                                <Col md='6'>
                                    <ControlLabel>Type</ControlLabel>
                                    <p>{this.state.deliveryInfo.type}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <ControlLabel>Status</ControlLabel>
                                    <p>
                                        <DeliveryState state={this.state.deliveryInfo.status}/>
                                    </p>
                                </Col>
                                <Col md='6'>
                                    <ControlLabel>Dernière maj</ControlLabel>
                                    <p>{this.state.deliveryInfo.date}</p>
                                </Col>
                            </Row>

                            <ControlLabel>État</ControlLabel>
                            <p>{this.state.deliveryInfo.message}</p>

                            <a href={this.state.deliveryInfo.link} target='_blank' rel='noopener noreferrer'>Voir sur Internet</a>

                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        )
    }
}

export default DeliveryBlock;