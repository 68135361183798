import PropTypes from 'prop-types';
import React from 'react';
import {
  compose,
  lifecycle,
  pure,
  withHandlers,
  withProps,
  withState,
  withStateHandlers,
} from 'recompose';
import {withContactInfosContext} from '../../hoc/contactInfos';
import {withContractContext} from '../../hoc/contract';
import {withContractNumberContext} from '../../hoc/contractNumber';
import {withDmdContext} from '../../hoc/dmd';
import {connectMeAndTeamsAndConstantsContext} from '../../hoc/withMeAndTeamsAndConstants';
import {isN2OrHigher, navigateToContract, navigateToTicket} from '../../utils/functions';
import { fetchContractTickets, postTicket, postTicketAnswer, postTicketDeletion } from '../../utils/requests';

const withStateUpdaters = withStateHandlers({
  loading: false,
  progress: 0,
  tags: [],
  tickets: [],
}, {
  addTicket: ({loading, tickets}) => (ticket, state = {}) => {
    let updatedTickets = tickets.filter(_ticket => _ticket.id !== ticket.id);
    updatedTickets.push(ticket);
    return {
      tickets: updatedTickets,
      ...state,
    };
  },
  removeTicket: ({loading, tickets}, {contractNumber}) => (id, state = {}) => {
    navigateToContract(contractNumber);
    return {
      tickets: tickets.filter(_ticket => _ticket.id !== id),
      ...state,
    };
  },
  setLoading: () => (loading) => ({loading}),
  setProgress: () => (progress) => ({progress}),
  setState: () => (state) => state,
  setTags: () => (tags) => ({tags}),
  setTickets: () => (tickets) => ({tickets}),
});

const withContactInfos = withState('contactInfos', 'setContactInfos', {
  buyer: {},
  subscriber: {},
  user: {},
});

const withContractState = withState('contract', 'setContract', {});
const withCurrentEditedAfterSaleState = withState('currentEditedAfterSale', 'setCurrentEditedAfterSale', null);
const withDmd = withState('dmd', 'setDmd', {});

const handlers = withHandlers({
  deleteTicket: ({contractNumber, setLoading, removeTicket}) => (id) => {
    setLoading(true);
    postTicketDeletion(contractNumber, id)
    .then(
      () => removeTicket(id, {loading: false}),
      () => setLoading(false),
    );
  },

  loadContactInfo: ({contactInfos, setContactInfos}) => (property, value) =>
    setContactInfos({
      ...contactInfos,
      [property]: value,
    }),

  setAnswer: ({contractNumber, addTicket, setLoading}) => (id, data, callback) => {
    setLoading(true);
    postTicketAnswer(contractNumber, id, data)
    .then(
      (response) => {
        addTicket(response, {loading: false});
        callback(id, response, true);
      },
      () => {
        setLoading(false);
        callback(id, false);
      },
    );
  },

  setTicket: ({contractNumber, addTicket, setLoading, setProgress, setState}) => (id, data, callback) => {
    setLoading(true);

    const showXHRProgress = () => {

      const progress = e => {
        if (e.lengthComputable) {
          const percentComplete = e.loaded / e.total;
          setProgress(percentComplete * 100);
        }
      };

      const xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener('progress', progress, false);
      xhr.addEventListener('progress', progress, false);

      return xhr;
    }
    
    postTicket(contractNumber, data, {xhr: showXHRProgress})
    .then(
      response => {
        addTicket(response, {loading: false, progress: 0});
        navigateToTicket(contractNumber, response.ticketNumber);
        callback(id, response, true);
      },
      () => {
        setState({loading: false, progress: 0});
        callback(id, false);
      },
    );
  },
});

const getContractTickets = (props, contractNumber) => {
  const {setLoading, setState} = props;
  setLoading(true);
  fetchContractTickets(contractNumber)
  .then(
    (response) => setState({
      tickets: response.tickets,
      tags: response.tags,
      loading: false,
    }),
    () => setLoading(false),
  );
};

const withLifecycle = lifecycle({
  componentDidMount() {
    getContractTickets(this.props, this.props.contractNumber);
  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.contractNumber && this.props.contractNumber && nextProps.contractNumber !== this.props.contractNumber) {
      getContractTickets(this.props, nextProps.contractNumber);
    }
  },
});

const calculatedProps = withProps(
  ({contractNumber, webUserJson}) => {
    const shouldShowB2BTab = /^8[0-9]{7}$/.test(contractNumber) && isN2OrHigher(webUserJson);

    return {
      shouldShowB2BTab,
    };
  },
);

const ContractManager = compose(
  connectMeAndTeamsAndConstantsContext,
  withStateUpdaters,
  withContactInfos,
  withContactInfosContext,
  withContractNumberContext,
  withContractState,
  withContractContext,
  withContactInfosContext,
  withCurrentEditedAfterSaleState,
  withDmd,
  withDmdContext,
  handlers,
  withLifecycle,
  calculatedProps,
  pure,
)(({
  Component,

  contractNumber,
  loading,
  progress,
  ticketNumber,
  tags,
  tickets,
  addTicket,
  deleteTicket,
  removeTicket,
  setTicket,
  setAnswer,

  contract,
  currentEditedAfterSale,
  dmd,
  webUserJson,
  loadContactInfo,
  shouldShowB2BTab,
  setCurrentEditedAfterSale,
  setDmd,
}) => (
  <Component
    contractNumber={contractNumber}
    loading={loading}
    progress={progress}
    ticketNumber={ticketNumber}
    tags={tags}
    tickets={tickets}
    addTicket={addTicket}
    deleteTicket={deleteTicket}
    removeTicket={removeTicket}
    setTicket={setTicket}
    setAnswer={setAnswer}
    contract={contract}
    currentEditedAfterSale={currentEditedAfterSale}
    dmd={dmd}
    webUserJson={webUserJson}
    loadContactInfo={loadContactInfo}
    shouldShowB2BTab={shouldShowB2BTab}
    setCurrentEditedAfterSale={setCurrentEditedAfterSale}
    setDmd={setDmd}
  />
));

ContractManager.propTypes = {
  contract: PropTypes.object,
  contractNumber: PropTypes.string,
  currentEditedAfterSale: PropTypes.object,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  ticketNumber: PropTypes.string,
  tags: PropTypes.array,
  tickets: PropTypes.array,
  webUserJson: PropTypes.object,
  addTicket: PropTypes.func,
  setTicket: PropTypes.func,
  deleteTicket: PropTypes.func,
  loadContactInfo: PropTypes.func,
  removeTicket: PropTypes.func,
  shouldShowB2BTab: PropTypes.bool,
  setAnswer: PropTypes.func,
  setCurrentEditedAfterSale: PropTypes.func,
  setDmd: PropTypes.func,
};

export default ContractManager;