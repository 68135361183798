import React from 'react';
import PropTypes from 'prop-types';

import {ENABLE_LMR_FEATURES} from '../../../../utils/constants';
import AfterSales from './Order/AfterSales';
import Orders from './Order/Orders';

const Order = ({ contractNumber, addTicket, setCurrentEditedAfterSale }) =>
    <>
        <fieldset className='custom-fieldset-blue'>
            <legend>Commandes</legend>
            <Orders contractNumber={contractNumber} addTicket={addTicket} />
        </fieldset>
        {ENABLE_LMR_FEATURES &&
            <fieldset className='custom-fieldset-blue'>
                <legend>Retours</legend>
                <AfterSales contractNumber={contractNumber} setCurrentEditedAfterSale={setCurrentEditedAfterSale} />
            </fieldset>
        }
    </>;

Order.propTypes = {
    contractNumber: PropTypes.string,
    addTicket: PropTypes.func,
    setCurrentEditedAfterSale: PropTypes.func,
};

export default Order;