import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {errorMsg, successMsg} from '../../utils/Notify';
import FontAwesome from './FontAwesome';

const styles = {
	marginBottom: 5,
};

const CopyTableDataButton = ({entries = [], properties = []}) => {
	
	const handleCopyData = () => {
		const headers = properties.map(property => property.label).join('\t');
		const data = entries.map(entry => [...properties.map(property => entry[property.key])].join('\t'));
		const copyData = [headers, ...data].join('\n');

		navigator.clipboard.writeText(copyData).then(
			() => successMsg('Données copiées dans le presse-papier'),
			() => errorMsg('Erreur lors de la copie des données'),
		);
	}

	return (
    <Button bsSize='small' bsStyle='primary' className='pull-right' style={styles} onClick={handleCopyData}>
			<FontAwesome name='clone'/>&nbsp;Copier dans le presse-papier
    </Button>
	)
};

CopyTableDataButton.propTypes = {
	entries: PropTypes.array,
	properties: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			key: PropTypes.string
		})
	),
};

export default CopyTableDataButton;