import PropTypes from 'prop-types';
import React from 'react';
import {Glyphicon} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';

const PhonePure = ({icon, phoneNumber}) => (
    <div><Glyphicon glyph={icon}/> {phoneNumber}</div>
);

PhonePure.propTypes = {
    phoneNumber: PropTypes.string,
};

const Phone = compose(
    branch(
        ({phoneNumber}) => !phoneNumber,
        renderNothing,
    ),
    pure,
)(PhonePure);

Phone.propTypes = {
    phoneNumber: PropTypes.string,
};

const PhoneAndCellphone = ({cellphone, phone}) => (
    <>
        <Phone icon='phone-alt'
               phoneNumber={phone}/>
        <Phone icon='phone'
               phoneNumber={cellphone}/>
    </>
);

PhoneAndCellphone.propTypes = {
    cellphone: PropTypes.string,
    phone: PropTypes.string,
};

export default PhoneAndCellphone;