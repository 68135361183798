import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withHandlers, withState} from 'recompose';
import jsRoutes from '../../../../../../routes/routes';
import FontAwesome from '../../../../../components/FontAwesome';

const TeamViewerButtonPure = ({
                                data,
                                disabled,
                                loading,
                                handleSendGCMTeamViewerOrder,
                              }) => (
  <Button bsStyle='success' bsSize='xsmall' onClick={handleSendGCMTeamViewerOrder} disabled={disabled}>
    Lancer TeamViewer
    {(loading || data?.loading) && [
      ' ',
      <FontAwesome key='spinner' pulse size='1x' name='spinner'/>,
    ]}
  </Button>
);

TeamViewerButtonPure.propTypes = {
  handleSendGCMTeamViewerOrder: PropTypes.func,
};

const sendGCMTeamViewerOrder = (contractNumber) => jsRoutes.controllers.backoffice.MaintenanceV2Controller
  .sendGCMTeamViewerOrder(contractNumber)
  .ajax();

const handlers = withHandlers({
  handleSendGCMTeamViewerOrder: ({contractNumber, setLoading}) => () => {
    const hideLoading = () => setLoading(false);
    setLoading(
      true,
      () => sendGCMTeamViewerOrder(contractNumber).then(
        hideLoading,
        hideLoading,
      ),
    );
  },
});

const withLoadingState = withState('loading', 'setLoading', false);

const TeamViewerButton = compose(
  branch(
    ({hasTeamviewerRight}) => !hasTeamviewerRight,
    renderNothing,
  ),
  withLoadingState,
  handlers,
  pure,
)(TeamViewerButtonPure);

TeamViewerButton.propTypes = {
  contractNumber: PropTypes.string,
  disabled: PropTypes.bool,
  hasTeamviewerRight: PropTypes.bool,
};

export default TeamViewerButton;