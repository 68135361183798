import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Modal, ModalBody, ModalHeader, ModalTitle} from 'react-bootstrap';
import ReactTable from 'react-table';
import {branch, compose, pure, renderNothing, withProps, withState} from 'recompose';
import {GET_SIM_CONTRACT_HISTORY} from '../../../../schema/SimSchema';
import {toFrenchDateString} from '../../../../utils/Date';
import {filterDateColumn, filterTextColumn} from '../../../../utils/functions';

const columns = [{
  Header: 'Contrat',
  accessor: 'contractNumber',
  Cell: props => <a href={`/contract/${props.value}`} rel="noopener noreferrer" target="_blank">{props.value}</a>,
  filterMethod: (filter, row) => filterTextColumn('contractNumber', row, filter.value),
}, {
  Header: 'Attachée le',
  accessor: 'attached',
  type: 'date',
  Cell: props => <div>{props.value ? toFrenchDateString(props.value) : null}</div>,
  filterMethod: (filter, row) => filterDateColumn('attached', row, filter.value),
}, {
  Header: 'Détachée le',
  accessor: 'detached',
  type: 'date',
  Cell: props => <div>{props.value ? toFrenchDateString(props.value) : null}</div>,
  filterMethod: (filter, row) => filterDateColumn('detached', row, filter.value),
}];

const ContractHistoryModalPure = ({
                                    contractHistory,
                                    filtered,
                                    setFiltered,
                                    setShowModal,
                                  }) => (
  <Modal data-test-id="contract-history-modal" show onHide={() => setShowModal(false)}>
    <ModalHeader closeButton>
      <ModalTitle>Historique des contrats</ModalTitle>
    </ModalHeader>
    <ModalBody>
      <ReactTable
        className="-striped"
        columns={columns}
        data={contractHistory}
        fill
        filterable
        filtered={[...filtered]}
        pageSize={contractHistory.length}
        resizable={false}
        showPagination={false}
        getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
        onFilteredChange={_ => setFiltered(_)}
      />
    </ModalBody>
  </Modal>
);

ContractHistoryModalPure.propTypes = {
  contractHistory: PropTypes.array,
  filtered: PropTypes.array,
  setFiltered: PropTypes.func,
  setShowModal: PropTypes.func,
};

const withData = graphql(GET_SIM_CONTRACT_HISTORY, {
  options: ({simId}) => ({
    variables: {
      id: simId,
    },
  }),
});

const withFilteredState = withState('filtered', 'setFiltered', []);

const ContractHistoryModal = compose(
  branch(
    ({show}) => !show,
    renderNothing,
  ),
  withData,
  withProps(
    ({data}) => ({
      contractHistory: data?.getSimContractHistory?.contractHistory || [],
    }),
  ),
  withFilteredState,
  pure,
)(ContractHistoryModalPure);

ContractHistoryModal.propTypes = {
  show: PropTypes.bool,
  simId: PropTypes.string,
  setShowModal: PropTypes.func,
};

export default ContractHistoryModal;