import gql from 'graphql-tag';

import {CUSTOMER_FULL_FRAGMENT} from './CustomerSchema';

const BANK_FRAGMENT = gql`
    fragment bankFields on BankID {
        Bic
        DateSignature
        Iban
        NameCustomer
        ReferenceMandate
    }
`;

const CREATE_BANK_ID = gql`
    mutation CreateBankID($input: BankIDCreateInput!) {
        bankIDCreate(input: $input) {
            bankID {
                ...bankFields
            }
            customer {
                ...customerFullFields
            }
        }
    } 
    ${BANK_FRAGMENT}
    ${CUSTOMER_FULL_FRAGMENT}
`;

export {
    BANK_FRAGMENT,
    CREATE_BANK_ID,
};