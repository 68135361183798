import React from 'react';
import moment from 'moment';
import {Image} from 'react-bootstrap';
import {getEaster} from '../../../utils/functions';
import getImages from './imagesImport';

moment.locale('fr');

const images = getImages();

const currentDate = moment();

// 5 jours avant - 4 jours apres

const valentineDayStart = moment().set({'year': currentDate.year(), 'month': 1, 'date': 9});
const valentineDayEnd = moment().set({'year': currentDate.year(), 'month': 1, 'date': 18});

const patrickDayStart = moment().set({'year': currentDate.year(), 'month': 2, 'date': 12});
const patrickDayEnd = moment().set({'year': currentDate.year(), 'month': 2, 'date': 21});

const easterDayStart = getEaster(currentDate.year()).subtract(5, 'days');
const easterDayStop = getEaster(currentDate.year()).add(5, 'days');

const musicStart = moment().set({'year': currentDate.year(), 'month': 5, 'date': 16});
const musicEnd = moment().set({'year': currentDate.year(), 'month': 5, 'date': 25});

const fireworksStart = moment().set({'year': currentDate.year(), 'month': 6, 'date': 10});
const fireworksEnd = moment().set({'year': currentDate.year(), 'month': 6, 'date': 19});

const beachStart = moment().set({'year': currentDate.year(), 'month': 7, 'date': 11});
const beachEnd = moment().set({'year': currentDate.year(), 'month': 7, 'date': 19});

const workStart = moment().set({'year': currentDate.year(), 'month': 8, 'date': 1});
const workEnd = moment().set({'year': currentDate.year(), 'month': 8, 'date': 14});

const birthdayStart = moment().set({'year': currentDate.year(), 'month': 9, 'date': 5});
const birthdayEnd = moment().set({'year': currentDate.year(), 'month': 9, 'date': 14});

const halloweenStart = moment().set({'year': currentDate.year(), 'month': 9, 'date': 26});
const halloweenEnd = moment().set({'year': currentDate.year(), 'month': 10, 'date': 4});

const tonyLeaveDayStart = moment().set({'year': currentDate.year(), 'month': 10, 'date': 24});
const tonyLeaveDayEnd = moment().set({'year': currentDate.year(), 'month': 11, 'date': 1});

const christmasDayStart = moment().set({'year': currentDate.year(), 'month': 11, 'date': 16});
const christmasDayEnd = moment().set({'year': currentDate.year(), 'month': 0, 'date': 8});

const CanaryLogo = () => {
	let urlLogo = images['canari_logo.png'];
	let urlIcon = images['canari.png'];

	if (currentDate.isBetween(valentineDayStart, valentineDayEnd)) {
		urlLogo = images['canari_logo_love.png'];
		urlIcon = images['canari_love.png'];
	}

	if (currentDate.isBetween(patrickDayStart, patrickDayEnd)) {
		urlLogo = images['canari_logo_patrick.png'];
		urlIcon = images['canari_patrick.png'];
	}

	if (currentDate.isBetween(easterDayStart, easterDayStop)) {
		urlLogo = images['canari_logo_egg.png'];
		urlIcon = images['canari_egg.png'];
	}

	if (currentDate.isBetween(musicStart, musicEnd)) {
		urlLogo = images['canari_logo_music.png'];
		urlIcon = images['canari_music.png'];
	}

	if (currentDate.isBetween(fireworksStart, fireworksEnd)) {
		urlLogo = images['canari_logo_fireworks.png'];
		urlIcon = images['canari_fireworks.png'];
	}

	if (currentDate.isBetween(beachStart, beachEnd)) {
		urlLogo = images['canari_logo_beach.png'];
		urlIcon = images['canari_beach.png'];
	}

	if (currentDate.isBetween(workStart, workEnd)) {
		urlLogo = images['canari_logo_work.png'];
		urlIcon = images['canari_work.png'];
		// urlLogo = images['canari_logo_covid.png'];
		// urlIcon = images['canari_covid.png'];
	}

	if (currentDate.isBetween(birthdayStart, birthdayEnd)) {
		urlLogo = images['canari_logo_birthday.png'];
		urlIcon = images['canari_birthday.png'];
	}

	if (currentDate.isBetween(halloweenStart, halloweenEnd)) {
		urlLogo = images['canari_logo_halloween.png'];
		urlIcon = images['canari_halloween.png'];
	}

	if (currentDate.isBetween(tonyLeaveDayStart, tonyLeaveDayEnd)) {
		urlLogo = images['canari_logo_ski.png'];
		urlIcon = images['canari_ski.png'];
	}

	if ((currentDate.month() === 11 && currentDate.isAfter(christmasDayStart)) || (currentDate.month() === 0 && currentDate.isBefore(christmasDayEnd))) {
		urlLogo = images['canari_logo_noel.png'];
		urlIcon = images['canari_noel.png'];
	}

	return (
		<span>
			<a className='hidden-md hidden-sm cursor-pointer' href='/ticket'><Image src={urlLogo} height={48}/></a>
			<a className='hidden-lg hidden-xs cursor-pointer' href='/ticket'><Image src={urlIcon} height={48}/></a>
		</span>
	);
}

export default CanaryLogo;