import React from 'react';
import PropTypes from 'prop-types';
import { connectWebUserContext } from '../../../../hoc/withMeAndTeamsAndConstants';
import { isN2OrHigher } from '../../../../utils/functions';
import AccountMaintenances from './AccountMaintenance/AccountMaintenances';

const AccountMaintenance = connectWebUserContext(({ webUserJson, contractNumber }) => {
  if (!isN2OrHigher(webUserJson)) return null;
  return <AccountMaintenances contractNumber={contractNumber} />;
});

AccountMaintenance.propTypes = {
  contractNumber: PropTypes.string,
};

export default AccountMaintenance;