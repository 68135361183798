export const isSameFeatureId = feature => _ => _.feature.id === feature.feature.id;

export const addLabelsToB2BFeatures = (addedOrModifiedB2BFeatures, allFeatures) =>
  addedOrModifiedB2BFeatures.map(b2bFeatureRecord => ({
    ...b2bFeatureRecord,
    feature: allFeatures.find(_ => _.id === b2bFeatureRecord.feature.id),
  }));

export const updateB2BFeatures = (b2bFeatureRecords, addedOrModifiedB2BFeatures) => {
  const updatedB2BFeatures = b2bFeatureRecords.map(
    b2bFeatureRecord => {
      const updatedFeature = addedOrModifiedB2BFeatures.find(isSameFeatureId(b2bFeatureRecord));
      return updatedFeature || b2bFeatureRecord;
    },
  );
  const b2BFeaturesToAdd = addedOrModifiedB2BFeatures.filter(feature => !b2bFeatureRecords.find(isSameFeatureId(feature)));

  return [
    ...updatedB2BFeatures,
    ...b2BFeaturesToAdd,
  ];
};

export const isExactFeatureInArray = (feature, features) => {
  const foundFeature = features.find(isSameFeatureId(feature));

  if (foundFeature) {
    return (
      feature.enabled === foundFeature.enabled &&
      feature.end === foundFeature.end &&
      feature.start === foundFeature.start
    );
  }

  return false;
};

export const areExactFeaturesInArray = (a, b) => a.every(feature => isExactFeatureInArray(feature, b));

export const hasB2BFeaturesChanged = (b2bFeatureRecords, initialB2BFeatures) =>
  !areExactFeaturesInArray(b2bFeatureRecords, initialB2BFeatures) ||
  !areExactFeaturesInArray(initialB2BFeatures, b2bFeatureRecords);

export const getB2BFeatureRecordsFromQueryData = (contractB2BFeaturesData) => {
  const b2bSubscriptionsOrError = contractB2BFeaturesData?.getB2BSubscriptionsForContracts?.b2bSubscriptionsOrError?.[0];

  if (b2bSubscriptionsOrError?.__typename === 'B2BSubscription') {
    return b2bSubscriptionsOrError.featureRecords;
  }

  return [];
};