import React from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar} from 'react-bootstrap';

import {ENABLE_LMR_FEATURES, isLibreOrLaPosteContract} from '../../../utils/constants';
import { shouldShowAfterSaleTabletReturnService } from '../../../utils/functions';
import { connectTypesContext } from '../../../hoc/withMeAndTeamsAndConstants';
import AssignNewTicketModalButton from './AssignNewTicketModalButton';
import TransferNewTicketModalButton from './TransferNewTicketModalButton';
import CloseNewTicketModalButton from './CloseNewTicketModalButton';

const NewTicketActions = connectTypesContext(({
  types,
  handleCancel,
  showTabletReturnForm,
  handleShowTabletReturnForm,
  
  ticket,
  contractNumber,
  options,
  addTicketTags,
  handleTicketChange,
  handleSaveData,
}) => {
  
  const buttonToolbarDisabled = ticket.subject === '---' || ticket.canal === '---' || ticket.content === '';
  
  const showAfterSaleTabletReturnService = !isLibreOrLaPosteContract(contractNumber) && !buttonToolbarDisabled && shouldShowAfterSaleTabletReturnService(types, ticket.subject);

  return (
    <>
      <ButtonToolbar className='pull-left'>
        <Button bsStyle='danger' onClick={handleCancel}>
          Annuler
        </Button>
      </ButtonToolbar>

      {!showTabletReturnForm && (
        <ButtonToolbar className='pull-right'>
          <AssignNewTicketModalButton
            ticket={ticket}
            addTicketTags={addTicketTags}
            handleTicketChange={handleTicketChange}
            handleSaveData={handleSaveData}
            disabled={buttonToolbarDisabled}
          />
          <TransferNewTicketModalButton
            ticket={ticket}
            options={options}
            addTicketTags={addTicketTags}
            handleTicketChange={handleTicketChange}
            handleSaveData={handleSaveData}
            disabled={buttonToolbarDisabled}
          />
          <CloseNewTicketModalButton
            ticket={ticket}
            options={options}
            addTicketTags={addTicketTags}
            handleTicketChange={handleTicketChange}
            handleSaveData={handleSaveData}
            disabled={buttonToolbarDisabled}
          />
          {ENABLE_LMR_FEATURES && showAfterSaleTabletReturnService && (
            <Button
              bsStyle='primary'
              className='btn-violet'
              disabled={buttonToolbarDisabled}
              onClick={handleShowTabletReturnForm}
            >
              Retour
            </Button>
          )}
        </ButtonToolbar>
      )}
    </>
  );
});

NewTicketActions.propTypes = {
  showTabletReturnForm: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleShowTabletReturnForm: PropTypes.func,
};

export default NewTicketActions;