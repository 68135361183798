import React, { useMemo } from 'react';

import { connectTypesContext } from '../../../../hoc/withMeAndTeamsAndConstants';
import { getCanalType, getSubjectTypeLabel, getTeamType } from '../../../../utils/functions';
import TicketsTable from '../../TicketsTable';

const TICKET_DETAILS_PROPERTIES = [
	{
		label: 'ID',
		name: 'ticketNumber',
		size: '5%'
	},
	{
		label: 'Date Créa',
		name: 'created',
		type: 'date',
		size: '9%'
	},
	{
		label: 'Date Ferm',
		name: 'resultTimestamp',
		type: 'date',
		size: '9%'
	},
	{
		label: 'Équipe',
		name: 'teamLabel',
		size: '7%'
	},
	{
		label: 'Demande',
		name: 'subjectLabel',
		size: '5%'
	},
	{
		label: 'État',
		name: 'state',
		size: '5%'
	},
	{
		label: 'Canal',
		name: 'canalLabel',
		size: '7%'
	},
	{
		label: 'Contenu',
		name: 'content',
		size: '53%'
	}
];

const createTicketDetailsFormatter = types => (ticket = {}) => ({
	...ticket,
	teamLabel: getTeamType(types, ticket.team),
	subjectLabel: getSubjectTypeLabel(types, ticket.subject) || '',
	canalLabel: getCanalType(types, ticket.canal) || '',
});

const TicketDetailsTable  = connectTypesContext(({types, ticket}) => {

  const formatTicketDetails = useMemo(() => createTicketDetailsFormatter(types), [types]);

	return (
		<div>
			<TicketsTable
				tickets={[ticket]}
				properties={TICKET_DETAILS_PROPERTIES}
				formatTicket={formatTicketDetails}
				pagination={false}
				noFilters
			/>
		</div>
	);
});

export default TicketDetailsTable;