import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {confirmable} from 'react-confirm';

const ConfirmationDialog = ({
                                cancelLabel,
                                confirmationLabel,
                                confirmation,
                                show,
                                title,
                                cancel,
                                dismiss,
                                proceed,
                            }) => {
    return (
        <Modal onHide={dismiss} show={show}>
            <Modal.Header>
                <Modal.Title>{title || 'Confirmation'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {confirmation}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => cancel()} bsStyle='danger'>{cancelLabel || 'Annuler'}</Button>
                <Button onClick={() => proceed()} bsStyle='success'>{confirmationLabel || 'Valider'}</Button>
            </Modal.Footer>
        </Modal>
    );
};

ConfirmationDialog.propTypes = {
    cancelLabel: PropTypes.string,
    confirmationLabel: PropTypes.string,
    confirmation: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]), // arguments of your confirm function
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    title: PropTypes.string,
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmationDialog);
