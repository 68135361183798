const typeDefs = `    
    type DefaultType {
        defaultProp: String
    }
`;

const typeExtensions = `
    type DefaultExtension {
        defaultProp: String
    }
`;

export { typeDefs, typeExtensions };