import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Col, Grid, Nav, NavItem, Panel, Tab} from 'react-bootstrap';
import Orders from './CustomerContract/tabs/Order/Orders';
import SpecialContract from './SpecialContract';
import ContractManager from './ContractManager';

const FreeContractLayout = ({
  contractNumber,
  loading,
  progress,
  ticketNumber,
  tags,
  tickets,
  addTicket,
  deleteTicket,
  removeTicket,
  setTicket,
  setAnswer,
}) => (
  <Tab.Container id="account-00000001-tabs" defaultActiveKey={1}>
    <Tab.Content animation className="with-panel-loading">
      <Nav bsStyle="tabs">
        <NavItem eventKey={1}>Tickets</NavItem>
        <NavItem eventKey={2}>Commandes</NavItem>
      </Nav>
      <Tab.Pane eventKey={1}>
        <SpecialContract
          header={
            <Alert bsStyle="info">
              <h3>Libre</h3>
              <p>Référencer ici les tickets hors contrat et hors La Poste.</p>
            </Alert>
          }
          contractNumber={contractNumber}
          loading={loading}
          progress={progress}
          ticketNumber={ticketNumber}
          tags={tags}
          tickets={tickets}
          deleteTicket={deleteTicket}
          removeTicket={removeTicket}
          addTicket={addTicket}
          setAnswer={setAnswer}
          setTicket={setTicket}
        />
      </Tab.Pane>
      <Tab.Pane eventKey={2}>
        <Grid fluid>
          <Panel bsStyle="info">
            <Panel.Heading>Commandes</Panel.Heading>
            <Panel.Body>
              <Col>
                <Orders contractNumber={contractNumber} addTicket={addTicket}/>
              </Col>
            </Panel.Body>
          </Panel>
        </Grid>
      </Tab.Pane>
    </Tab.Content>
  </Tab.Container>
);

FreeContractLayout.propTypes = {
  contractNumber: PropTypes.string,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  ticketNumber: PropTypes.string,
  tags: PropTypes.array,
  tickets: PropTypes.array,
  addTicket: PropTypes.func,
  deleteTicket: PropTypes.func,
  removeTicket: PropTypes.func,
  setTicket: PropTypes.func,
  setAnswer: PropTypes.func,
};

const FreeContract = ({contractNumber, ticketNumber}) => (
  <ContractManager
    Component={FreeContractLayout}
    contractNumber={contractNumber}
    ticketNumber={ticketNumber}
  />
);

FreeContract.propTypes = {
  contractNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
};

export default FreeContract;