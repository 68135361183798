import React from 'react';
import ClearFiltersButton from '../../../../../components/ClearFiltersButton';
import TicketAnswersTableFilter from './TicketAnswersTableFilter';

const TicketAnswersTableFilters = ({filters, createFilterInputChangeHandler, loading, properties = []}) => {
	return (
		<tr>
			{properties.map(({name, ...property}) => (
				<th key={'property_filter_' + name}>
					<TicketAnswersTableFilter
						name={name}
						value={filters?.[name]}
						onChange={createFilterInputChangeHandler(name)}
					/>
				</th>
			))}
			<th>
				<ClearFiltersButton
					disabled={loading && !!filters}
					onClick={createFilterInputChangeHandler('')}
				/>
			</th>
		</tr>
	);
}

export default TicketAnswersTableFilters;