import React from 'react';
import { PropTypes } from 'prop-types';

const ExpandableCell = ({value, isExpanded, render = value => value, ...row}) => {
  return (
    <div className={isExpanded ? 'bg-info expandable-cell' : 'expandable-cell'}>{render(value)}</div>
  )
}

ExpandableCell.propTypes = {
	packagesWithVersion: PropTypes.func,
};

export default ExpandableCell