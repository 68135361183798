import React from 'react';

import TicketAnswersTableRow from './TicketAnswersTableRow';
import TicketAnswersTableFilters from './TicketAnswersTableFilters';

const TicketAnswersTableBody = ({paginatedAnswers = [], filters, loading, ticketClosed, handleSaveAnswer, createFilterInputChangeHandler, properties = []}) => {
	return (
		<tbody>
			<TicketAnswersTableFilters
				filters={filters}
				createFilterInputChangeHandler={createFilterInputChangeHandler}
				loading={loading}
				properties={properties}
			/>
			{paginatedAnswers.map((answer, index) => (
				<TicketAnswersTableRow
					key={answer.ID || index}
					handleSaveAnswer={handleSaveAnswer}
					properties={properties}
					ticketClosed={ticketClosed}
					answer={answer}
				/>
			))}
		</tbody>
	);
}

export default TicketAnswersTableBody;