import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import {TICKET_STATES} from '../../../../../utils/constants';
import {getStateType} from '../../../../../utils/functions';
import ProcessingDatesInputs from '../../ProcessingDatesInputs';
import { connectTypesContext } from '../../../../../hoc/withMeAndTeamsAndConstants';

const AssignTicketModal = connectTypesContext(({ types, show, ticket, onHide, onSubmit, handleDateChange }) => (
	<Modal show={show} onHide={onHide}>
		<Modal.Header closeButton>
			<Modal.Title>Changer l'état</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<ProcessingDatesInputs ticket={ticket} handleDateChange={handleDateChange} />
		</Modal.Body>
		<Modal.Footer>
			<Button
				bsStyle='success'
				data-test-id='assignModalSubmit'
				onClick={onSubmit}
			>
				Passer à l'état '{getStateType(types, TICKET_STATES.TODO)}'
			</Button>
		</Modal.Footer>
	</Modal>
));

AssignTicketModal.propTypes = {
	showAssignModal: PropTypes.bool,
	ticket: PropTypes.object,
	types: PropTypes.object,
	onHide: PropTypes.func,
	onSubmit: PropTypes.func,
	handleDateChange: PropTypes.func,
};

export default AssignTicketModal;