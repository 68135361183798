import PropTypes from 'prop-types';
import {getContext, withContext} from 'recompose';

const withContactInfosContext = withContext({
        contactInfos: PropTypes.object,
        setContactInfos: PropTypes.func,
    },
    ({ contactInfos, setContactInfos }) => ({ contactInfos, setContactInfos }),
);

const connectContactInfosContext = Component => getContext(
    {
        contactInfos: PropTypes.object,
        setContactInfos: PropTypes.func,
    }
)(Component);

export { withContactInfosContext, connectContactInfosContext };