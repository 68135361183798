import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {
    PRODUCT_STATES,
    AFTERSALE_PROVIDERS,
    RETURN_TYPES
} from '../../../../../utils/AfterSale';
import AfterSaleTypeSelect from './AfterSaleTypeSelect';
import Select from './Select';

const AfterSaleInfos = ({ afterSale, afterSaleReasons, snList, ticketSubject, handleAfterSaleInfosChange, handleAfterSaleSNChange }) => (
    <>
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Type de retour</ControlLabel>
                    <AfterSaleTypeSelect ticketSubject={ticketSubject}
                                         value={afterSale.returnType}
                                         handleChange={e => handleAfterSaleInfosChange('type', e.target.value)} />
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Tracking BR</ControlLabel>
                    <FormControl bsSize='sm'
                                 value={afterSale.trackingNumber}
                                 onChange={e => handleAfterSaleInfosChange('trackingNumber', e.target.value)} />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Numéro de série</ControlLabel>
                    {snList.length > 0 ?
                        <Select arrayValues={snList}
                                mandatory
                                value={afterSale.serialNumber}
                                handleChange={e => handleAfterSaleSNChange(e.target.value)} /> :
                        <FormControl bsSize='sm'
                                     value={afterSale.serialNumber}
                                     onChange={e => handleAfterSaleInfosChange('serialNumber', e.target.value)} />
                    }
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Modèle</ControlLabel>
                    <FormControl bsSize='sm'
                                 disabled
                                 value={afterSale.productType} />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Motif du retour</ControlLabel>
                    <Select arrayValues={afterSaleReasons}
                            disabled={afterSale.returnType !== RETURN_TYPES.AFTER_SALE_SERVICE.value}
                            mandatory={afterSale.returnType === RETURN_TYPES.AFTER_SALE_SERVICE.value}
                            value={afterSale.reason}
                            handleChange={e => handleAfterSaleInfosChange('reason', e.target.value)} />
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>État du produit</ControlLabel>
                    <Select arrayValues={PRODUCT_STATES}
                            disabled={afterSale.returnType !== RETURN_TYPES.AFTER_SALE_SERVICE.value}
                            value={afterSale.productState}
                            handleChange={e => handleAfterSaleInfosChange('productState', e.target.value)} />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col sm={6} smOffset={6}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Site de retour</ControlLabel>
                    <Select arrayValues={AFTERSALE_PROVIDERS}
                            mandatory
                            value={afterSale.provider}
                            handleChange={e => handleAfterSaleInfosChange('provider', e.target.value)} />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col sm={12}>
                <FormGroup>
                    <ControlLabel bsSize='sm'>Commentaire</ControlLabel>
                    <FormControl bsSize='sm'
                                 componentClass='textarea'
                                 rows={6}
                                 value={afterSale.comment}
                                 onChange={e => handleAfterSaleInfosChange('comment', e.target.value)} />
                </FormGroup>
            </Col>
        </Row>
    </>
);

AfterSaleInfos.propTypes = {
    afterSale: PropTypes.object,
    afterSaleReasons: PropTypes.array,
    snList: PropTypes.array,
    ticketSubject: PropTypes.string,
    handleAfterSaleInfosChange: PropTypes.func,
    handleAfterSaleSNChange: PropTypes.func,
};

export default AfterSaleInfos;