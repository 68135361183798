import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FontAwesome extends Component {
    render() {

        let { border, className, fixedWidth, flip, inverse, name, pulse, rotate, size, spin, stack } = this.props;
        let classNames = [];

        classNames.push('fa');
        classNames.push('fa-' + name);
        if(size) classNames.push('fa-' + size);
        if(spin) classNames.push('fa-spin');
        if(pulse) classNames.push('fa-pulse');
        if(border) classNames.push('fa-border');
        if(fixedWidth) classNames.push('fa-fw');
        if(inverse) classNames.push('fa-inverse');
        if(flip) classNames.push('fa-flip-' + flip);
        if(rotate) classNames.push('fa-rotate-' + rotate);
        if(stack) classNames.push('fa-stack-' + stack);

        // Add any custom class names at the end.
        if(className) classNames.push(className);

        return (<span className={classNames.join(' ')}/>);
    }
}

FontAwesome.propTypes = {
    border: PropTypes.bool,
    className: PropTypes.string,
    fixedWidth: PropTypes.bool,
    flip: PropTypes.oneOf(['horizontal', 'vertical']),
    inverse: PropTypes.bool,
    name: PropTypes.string.isRequired,
    pulse: PropTypes.bool,
    rotate: PropTypes.oneOf([90, 180, 270]),
    size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
    spin: PropTypes.bool,
    stack: PropTypes.oneOf(['1x', '2x'])
};

export default FontAwesome;