import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import SetTerminatedButton from './ActionButtons/SetTerminatedButton';
import SetUnknownButton from './ActionButtons/SetUnknownButton';

const TerminatingGroupPure = ({
                                  displayVertical,
                                }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <SetTerminatedButton/>
    <SetUnknownButton/>
  </ButtonGroup>
);

TerminatingGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const TerminatingButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(TerminatingGroupPure);

TerminatingButtonGroup.propTypes = {};

export default TerminatingButtonGroup;