import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import {compose, pure, withHandlers, withProps} from 'recompose';
import {FSPLUS_SERVICE_TYPES} from '../../../../../utils/constants';
import {isDateInFuture} from '../../../../../utils/Date';
import SingleDatePicker from '../../../../components/DatePicker/SingleDatePicker';

const getOrderSchema = ({DAPOProducts}) => ({
  type: 'object',
  'definitions': {
    'address': {
      'title': 'Adresse de livraison',
      'type': 'object',
      'properties': {
        'gender': {title: 'Genre', type: 'string'},
        'firstName': {title: 'Prénom', type: 'string'},
        'lastName': {title: 'Nom', type: 'string'},
        'email': {title: 'Email', type: 'string'},
        'phone': {title: 'Téléphone', type: 'string'},
        'cellphone': {title: 'Portable', type: 'string'},
        'addressLine': {
          title: 'Adresses',
          type: 'array',
          items: {type: 'string'},
        },
        'zipCode': {title: 'Code postal', type: 'string'},
        'city': {title: 'Ville', type: 'string'},
      },
      'required': [],
    },
    prestations: {
      title: 'Prestations',
      type: 'object',
      properties: {
        'fsPlus': {
          'title': 'Facteur Service Plus',
          'type': 'object',
          'properties': {
            'typePresta': {
              title: 'Type de prestation',
              'type': 'string',
              'enum': Object.keys(FSPLUS_SERVICE_TYPES),
              enumNames: Object.values(FSPLUS_SERVICE_TYPES),
            },
            'sent': {'type': 'boolean', 'title': 'Ne pas envoyer de demande de prestation', 'default': false},
          },
          'required': [],
        },
        dapo: {
          title: 'DAPO',
          type: 'object',
          properties: {
            products: {
              title: 'Produits',
              type: 'array',
              items: {
                title: 'Produit',
                type: 'string',
                enum: DAPOProducts,
              },
            },
            shipmentDate: {
              type: 'number',
              title: 'Date d\'expédition souhaitée',
              params: {
                isOutsideRange: date => !isDateInFuture(date),
              },
            },
          },
          required: ['products'],
        },
      },
    },
  },
  required: [],
  properties: {
    shippingAddress: {'$ref': '#/definitions/address'},
    prestations: {'$ref': '#/definitions/prestations'},
  },
});

const uiSchema = {
  prestations: {
    dapo: {
      shipmentDate: {
        'ui:widget': 'singleDatePicker',
      },
    },
  },
};

const widgets = {
  singleDatePicker: SingleDatePicker,
};

const OrderEditPure = ({order, orderSchema, handleSubmit, onHideClick}) => (
  <Modal show bsSize='lg' onHide={onHideClick}>
    <Modal.Header closeButton>
      <Modal.Title>Édition/création d'une commande</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form formData={order}
            schema={orderSchema}
            widgets={widgets}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}>
        <Button bsStyle='info'
                type='submit'>
          Valider
        </Button>
      </Form>
    </Modal.Body>
  </Modal>
);

const withSubmitHandler = withHandlers({
  handleSubmit: ({onSubmitClick}) => ({formData}) => {
    const {dapo, fsPlus, ...otherPrestations} = formData?.prestations || {};
    const {products, shipmentDate, ...dapoData} = dapo || {};
    const hasToBeSentToDAPONotBefore = shipmentDate ? +moment(shipmentDate).subtract(1, 'day') : null;
    const dapoProducts = products.map(reference => ({
      additionalReferences: [],
      quantity: 1,
      reference,
    }));
    const prestations = {
      ...otherPrestations,
      dapo: {
        ...dapoData,
        hasToBeSentToDAPONotBefore,
        products: dapoProducts,
      },
    };

    if (fsPlus?.typePresta) {
      prestations.fsPlus = fsPlus;
    }

    const data = {
      ...formData,
      prestations,
    };
    onSubmitClick(data);
  },
});

OrderEditPure.propTypes = {
  order: PropTypes.object,
  orderSchema: PropTypes.object,
  handleSubmit: PropTypes.func,
  onHideClick: PropTypes.func,
};

const defaultDapoPrestation = {
  prestations: {
    dapo: {
      products: [],
    },
  },
};

const transformOrder = order => {
  const {hasToBeSentToDAPONotBefore, products, ...dapoData} = (order?.prestations?.dapo || defaultDapoPrestation);
  return {
    ...order,
    prestations: {
      ...order.prestations,
      dapo: {
        ...dapoData,
        shipmentDate: hasToBeSentToDAPONotBefore && +moment(hasToBeSentToDAPONotBefore).add(1, 'day'),
        products: products.map(_ => _.reference),
      },
    },
  };
};

const OrderEdit = compose(
  withProps(
    props => ({
      order: transformOrder(props.order),
      orderSchema: getOrderSchema(props),
    }),
  ),
  withSubmitHandler,
  pure,
)(OrderEditPure);

OrderEdit.propTypes = {
  DAPOProducts: PropTypes.array,
  order: PropTypes.object,
  onHideClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
};

export default OrderEdit;