import React from 'react';

import { connectTypesAndTeamsContext } from '../../hoc/withMeAndTeamsAndConstants';
import { tsToDateForInput } from '../../utils/Date';
import { getResultType, getTeam, getTeamType } from '../../utils/functions';
import { Label } from 'react-bootstrap';
import { parseFirstIntention, parseResult } from '../../utils/types';

const TicketContentWithLastAnswer = connectTypesAndTeamsContext(({types, teams, result, content, lastAnswer}) => {
  let caption;
  if (lastAnswer) {
    const teamLabel = getTeamType(types, getTeam(teams, lastAnswer.creator));
    caption = (
      <small>
        <hr />
        <em className='text-primary'>Dernière réponse le {tsToDateForInput(lastAnswer.created, true)}{teamLabel ? ` par ${teamLabel}` : ''}</em> : {lastAnswer.content}
      </small>
    );
  }
  const firstIntention = parseFirstIntention(result);
  return (
    <div>
      {result && <>
        <Label>
          {getResultType(types, parseResult(result))}
          {firstIntention && <>
            &nbsp;>&nbsp;
            {getResultType(types, firstIntention)}
          </>}
        </Label>
      </>}
      {content}
      {caption}
    </div>
  );
});

export default TicketContentWithLastAnswer;