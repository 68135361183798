import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Col} from 'react-bootstrap';

const AddFeaturesToContractsResults = ({results}) => (
<>
  {results.success.length > 0 && (
    <Col data-test-id="success-results" md={6} mdOffset={2}>
      <Alert bsStyle="success">
        Les options ont été mises à jour sur les contrats suivants :
        <ul>
          {results.success.map(result => <li key={result.contractNumber}>{result.contractNumber}</li>)}
        </ul>
      </Alert>
    </Col>
  )}

  {results.error.length > 0 && (
    <Col data-test-id="error-results" md={6} mdOffset={2}>
      <Alert bsStyle="danger">
        Des erreurs ont été détectées :
        <ul>
          {results.error.map(result => <li key={result.contractNumber}>Contrat {result.contractNumber} : {result.message}</li>)}
        </ul>
      </Alert>
    </Col>
  )}
</>
);

AddFeaturesToContractsResults.propTypes = {
  results: PropTypes.object
};

export default AddFeaturesToContractsResults;