import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';


const AndroidSwitch = ({hasSwitchedFromArdoizToAndroidRecently}) => (
  <FormGroup>
    <ControlLabel>Session en cours sur Android</ControlLabel>
    <FormControl readOnly
                 type='text'
                 value={hasSwitchedFromArdoizToAndroidRecently ? 'Oui' : 'Non'}/>
  </FormGroup>
);

  AndroidSwitch.propTypes = {
    hasSwitchedFromArdoizToAndroidRecently: PropTypes.bool,
  };

export default AndroidSwitch;