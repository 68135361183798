import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import SetNotActivatedButton from './ActionButtons/SetNotActivatedButton';

const NeedActivationGroupPure = ({
                                     displayVertical,
                                   }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <SetNotActivatedButton/>
  </ButtonGroup>
);

NeedActivationGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const NeedActivationButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(NeedActivationGroupPure);

NeedActivationButtonGroup.propTypes = {};

export default NeedActivationButtonGroup;