import React from 'react';
import SimsSearchView from './sims/SimsSearchView';
import { navigateTo } from '../utils/functions';
import { connectWebUserContext } from '../hoc/withMeAndTeamsAndConstants';
import { isDevicesAndSimsVisible } from '../utils/accessRights';

const Sims = connectWebUserContext(({webUserJson, ICCID}) => {
  if (!webUserJson || !isDevicesAndSimsVisible(webUserJson)) {
    navigateTo('/ticket');
  }
  return <SimsSearchView ICCID={ICCID} />;
});

export default Sims;