import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Alert, Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Panel, Row} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withHandlers, withProps, withState} from 'recompose';
import {
  ADD_B2B_FEATURES_ON_CONTRACTS_MUTATION,
  AddB2BFeaturesOnContractErrorType,
  GET_B2BFEATURES_QUERY,
} from '../../../schema/B2BSchema';
import AddFeaturesToContractsResults from './AddFeaturesToContracts/AddFeaturesToContractsResults';
import B2BFeatureTableWidget from './AddFeaturesToContracts/B2BFeatureTableWidget';

const AddFeaturesToContractsPure = ({
                                      availableFeatures,
                                      canSubmit,
                                      contractError,
                                      features,
                                      formData,
                                      saveResult,
                                      handleContractNumbersChange,
                                      handleSave,
                                      setB2BFeatures,
                                    }) => (
  <Panel bsStyle="success" className="center-block">
    <Panel.Body>
      <Row>
        <Col md={6} mdOffset={2}>
          <HelpBlock>
            Les options seront ajoutées ou modifiées si elles sont déjà rattachées aux contrats.
          </HelpBlock>
        </Col>

        <AddFeaturesToContractsResults results={saveResult}/>

        <Col md={6} mdOffset={2}>
          <FormGroup>
            <ControlLabel bsSize="sm">Contrats</ControlLabel>
            <FormControl componentClass="textarea"
                         placeholder="Contrats"
                         rows={4}
                         value={formData.contractNumbers}
                         onChange={handleContractNumbersChange}
            />

            <HelpBlock>
              Saisir les numéros de contrat, un par ligne
            </HelpBlock>

            {contractError && (
              <Alert bsStyle="danger">{contractError}</Alert>
            )}
          </FormGroup>
        </Col>

        <Col md={6} mdOffset={2}>
          <B2BFeatureTableWidget availableFeatures={availableFeatures}
                                 b2bFeatures={formData.b2bFeatures}
                                 features={features}
                                 setB2BFeatures={setB2BFeatures}/>
        </Col>

        <Col md={6} mdOffset={2}>
          <Button bsStyle="primary"
                  className={`pull-right ${!canSubmit && 'hidden'}`}
                  style={{marginTop: 10}}
                  type="submit"
                  onClick={handleSave}>
            Valider
          </Button>
        </Col>
      </Row>
    </Panel.Body>
  </Panel>

);

AddFeaturesToContractsPure.propTypes = {
  availableFeatures: PropTypes.array,
  canSubmit: PropTypes.bool,
  contractError: PropTypes.bool,
  features: PropTypes.array,
  formData: PropTypes.object,
  saveResult: PropTypes.object,
  handleContractNumbersChange: PropTypes.func,
  handleSave: PropTypes.func,
  setB2BFeatures: PropTypes.func,
};

const initialData = {
  contractNumbers: '',
  b2bFeatures: [],
};

const initialResultData = {
  error: [],
  success: [],
};

const withFormDataState = withState('formData', 'setFormData', initialData);
const withContractErrorState = withState('contractError', 'setContractError', false);
const withSaveResultState = withState('saveResult', 'setSaveResult', initialResultData);

const withData = graphql(GET_B2BFEATURES_QUERY);

const withAddB2BFeaturesOnContractsMutation = graphql(ADD_B2B_FEATURES_ON_CONTRACTS_MUTATION, {
  props: ({mutate}) => ({
    addB2BFeaturesOnContracts: (featureRecords, onContractNumbers, callback) =>
      mutate({
        variables: {
          input: {
            featureRecords,
            onContractNumbers,
          },
        },
        update(store, {data}) {
          callback(data);
        },
      }),
  }),
});

const getMessageForError = errorType => {
  switch (errorType) {
    case AddB2BFeaturesOnContractErrorType.CONTRACT_NOT_FOUND:
      return 'Contrat introuvable';
    case AddB2BFeaturesOnContractErrorType.INVALID_CONTRACT_NUMBER:
      return 'Numéro de contrat invalide';

    case AddB2BFeaturesOnContractErrorType.MALFORMED_CONTRACT_NUMBER:
      return 'Numéro de contrat malformé';
    case AddB2BFeaturesOnContractErrorType.CANNOT_ADD:
    case AddB2BFeaturesOnContractErrorType.INVALID_FEATURE_ID:
    default:
      return 'Problème lors de l\'ajout des options';
  }
};

const getResultErrors = results =>
  results
    .filter(_ => _.__typename === 'AddB2BFeaturesOnContractsError')
    .map(
      _ => ({
        contractNumber: _.contractNumber,
        message: getMessageForError(_.type),
      }),
    );

const getResultSuccesses = results =>
  results
    .filter(_ => _.__typename === 'B2BSubscription')
    .map(
      _ => ({
        contractNumber: _.contractNumber,
      }),
    );

const handlers = withHandlers({
  handleContractNumbersChange: ({
                                  formData,
                                  setContractError,
                                  setFormData,
                                  setSaveResult,
                                }) => e => {
    setFormData({
      ...formData,
      contractNumbers: e.target.value,
    });
    setSaveResult(initialResultData);
    setContractError(null);
  },

  handleSave: ({
                 formData,
                 addB2BFeaturesOnContracts,
                 setContractError,
                 setFormData,
                 setSaveResult,
               }) => () => {
    const contractNumbers = formData.contractNumbers.split('\n').map(_ => _.trim());
    const isContractNumbersMalformed = contractNumbers.find(_ => !/^\d{1,8}$/.test(_));

    if (isContractNumbersMalformed) {
      setContractError(`Le numéro de contrat suivant est invalide : ${isContractNumbersMalformed}`);
    } else {
      const callback = (data) => {
        const mutationData = data.addB2BFeaturesOnContracts.b2bSubscriptionsOrError;
        const saveResult = {
          error: getResultErrors(mutationData),
          success: getResultSuccesses(mutationData),
        };

        setSaveResult(saveResult);
        setFormData(initialData);
      };

      addB2BFeaturesOnContracts(formData.b2bFeatures, contractNumbers, callback);
    }
  },

  setB2BFeatures: ({
                     formData,
                     setFormData,
                     setSaveResult,
                   }) => b2bFeatures => {
    setSaveResult(initialResultData);
    setFormData({...formData, b2bFeatures});
  },
});

const AddFeaturesToContracts = compose(
  withData,
  withAddB2BFeaturesOnContractsMutation,
  withFormDataState,
  withContractErrorState,
  withSaveResultState,
  handlers,
  withProps(
    ({data, formData}) => {
      const canSubmit = !!formData.contractNumbers && formData.b2bFeatures.length > 0;
      const features = data?.b2bFeatures?.b2bFeatures || [];
      const availableFeatures = features.filter(feature => !formData.b2bFeatures.find(b2bFeature => b2bFeature.featureId === feature.id));

      return ({
        availableFeatures,
        canSubmit,
        features,
      });
    },
  ),
  branch(
    ({features}) => !features.length,
    renderNothing,
  ),
  pure,
)(AddFeaturesToContractsPure);

AddFeaturesToContracts.propTypes = {};

export default AddFeaturesToContracts;