import jsRoutes from '../../../../../../../routes/routes';
import {GCM_ORDERS_TYPES} from '../../../../../../../utils/constants';

const sendGCMMaintenanceOrder = contractNumber => jsRoutes.controllers.backoffice.MaintenanceV2Controller.sendGCMMaintenanceOrder(contractNumber).ajax();
const sendGCMUpdateOrder = contractNumber => jsRoutes.controllers.backoffice.MaintenanceV2Controller.sendGCMUpdateOrder(contractNumber).ajax();

const sendGCMOrder = (contractNumber, type) => {
    let sendGCMOrderFn;

    switch (type) {
        case GCM_ORDERS_TYPES.UPDATE:
            sendGCMOrderFn = sendGCMUpdateOrder;
            break;
        case GCM_ORDERS_TYPES.MAINTENANCE:
        default:
            sendGCMOrderFn = sendGCMMaintenanceOrder;
    }

    return sendGCMOrderFn(contractNumber);
};

export {sendGCMOrder};
