import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const TagSelect = ({ editing, tagsOptions, tags, handleChange }) => (
	<Select.Creatable
		className='mt-1'
		disabled={!editing}
		options={tagsOptions.map(tag =>({value: tag, label: tag}))}
		value={tags}
		onChange={handleChange}
		multi
	/>
);

TagSelect.propTypes = {
	editing: PropTypes.bool,
	tagsOptions: PropTypes.array,
	tags: PropTypes.array,
	handleChange: PropTypes.func,
};

export default TagSelect;