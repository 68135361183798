import React from 'react';
import { Glyphicon, Label } from 'react-bootstrap';
import moment from 'moment';

const TicketTakeActionDay = ({value}) => {

  if (value) {

    const formattedDate = value ? moment(value).format('L') : null;

    let days = Math.round(moment().diff(moment(value, 'L'), 'days', true));
    if (days > 0) {
      return (
        <Label bsStyle='danger'>
          <span className='visible-lg-inline'>
            <Glyphicon glyph='exclamation-sign' />&nbsp;
          </span>
          {formattedDate}
        </Label>
      );
    } else if (days === 0) {
      return <Label bsStyle='success'>{formattedDate}</Label>;
    } else {
      return <Label bsStyle='default'>{formattedDate}</Label>;
    }
  }

  return null;
};

export default TicketTakeActionDay;