import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderComponent, renderNothing } from 'recompose';
import { Label } from 'react-bootstrap';
import moment from 'moment';

import { tsToDateForInput } from '../../../../../utils/Date';
import FAIconWithText from '../../../../components/FAIconWithText';
import FAIconWithTextAndTooltip from '../../../../components/FAIconWithTextAndTooltip';

moment.locale('fr');

const InitializedMoreInfoBlockPure = ({ contract, modal }) => {
    const { lastActivityDate } = contract;
    let connectionPill;

    if (lastActivityDate) {
        const lastConnectionDate = moment(lastActivityDate);
        const _15minAgoDate = moment().subtract(15, 'minutes');
        const _3hAgoDate = moment().subtract(3, 'hours');
        const lastConnectionAgo = moment.duration(lastConnectionDate.diff(moment())).humanize(true);
        let bsStyle = 'default';

        if (lastConnectionDate.isAfter(_15minAgoDate)) {
            bsStyle = 'success';
        } else if (lastConnectionDate.isAfter(_3hAgoDate)) {
            bsStyle = 'warning';
        }

        connectionPill = <FAIconWithTextAndTooltip icon='circle' bsStyle={bsStyle} id={`pill-${bsStyle}`}
                                                   text={tsToDateForInput(+lastConnectionDate)}
                                                   tooltip={lastConnectionAgo} />;
    } else {
        connectionPill = <FAIconWithText icon='circle' bsStyle='default' id='pill-default' text={'Pas d\'information'} />;
    }

    return (
        modal ? <Label className='label-special'>Dernière connexion <span>{connectionPill}</span></Label> :
            <div>
                <ControlLabel>Date de dernière activité</ControlLabel>
                <p>{connectionPill}</p>
            </div>
    )
};

const NotInitializedMoreInfoBlockPure = ({ contract }) => {
    const { deliveryDate, shippedDate } = contract;

    if (deliveryDate) {
        return (
            <div>
                <ControlLabel>Date de livraison (1ère cmd)</ControlLabel>
                <p>{moment(deliveryDate).format('L')}</p>
            </div>
        )
    } else if (shippedDate) {
        return (
            <div>
                <ControlLabel>Date d'envoi (1ère cmd)</ControlLabel>
                <p>{moment(shippedDate).format('L')}</p>
            </div>
        )
    } else {
        return (
            <div>
                <ControlLabel>Date d'envoi (1ère cmd)</ControlLabel>
                <p>Non expédié</p>
            </div>
        )
    }
};

const MoreInfoBlock = compose(
    pure,
    branch(
        ({ contract }) => contract.initialized,
        renderComponent(InitializedMoreInfoBlockPure),
        branch(
            ({ modal }) => !modal,
            renderComponent(NotInitializedMoreInfoBlockPure),
            renderNothing
        )
    ),
)();

MoreInfoBlock.propTypes = {
    contract: PropTypes.object,
    modal: PropTypes.bool,
};

export default MoreInfoBlock;