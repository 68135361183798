import Aviator from 'aviator';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {branch, renderNothing} from 'recompose';
import jsRoutes from '../routes/routes';
import {EMAIL_REGEXP, TICKET_RESULT_OTHER_REASONS, TICKET_SUBJECTS, USER_TEAMS} from './constants';
import {tsToDateForInput} from './Date';
import {SIM_OPERATORS} from './Sim';
import { getContractURL, getTicketURL } from './requests';

export const isFunction = value => value && (Object.prototype.toString.call(value) === '[object Function]' || 'function' === typeof value || value instanceof Function);

const filterTeamTransfer = (teams, webUserJson) => {
    const sortedTeams = teams.sort((a, b) => a - b);
    const filterSortedTeams = authorizedTransferTeams =>
        sortedTeams.filter(
            team =>
                !!authorizedTransferTeams.find(authorizedTeam => team === authorizedTeam),
        );

    if (isN1User(webUserJson)) {
        const authorizedTransferTeams = [
            USER_TEAMS.N1,
            USER_TEAMS.VALIDATION,
            USER_TEAMS.BO,
        ];
        return filterSortedTeams(authorizedTransferTeams);
    } else if (isValidationUser(webUserJson)) {
        const authorizedTransferTeams = [
            USER_TEAMS.N1,
            USER_TEAMS.VALIDATION,
            USER_TEAMS.N2_COMMERCIAL,
            USER_TEAMS.N2_TECHNIQUE,
            USER_TEAMS.LIVRAISON,
            USER_TEAMS.SAV,
            USER_TEAMS.CONTRACT,
            USER_TEAMS.BO,
            USER_TEAMS.B2B,
            USER_TEAMS.CAMPAIGN,
        ];
        return filterSortedTeams(authorizedTransferTeams);
    } else if (isN2User(webUserJson)) {
        return sortedTeams;
    } else {
        return sortedTeams.filter(team => team !== USER_TEAMS.BO);
    }
};

const getWebUserTeam = webUserJson => webUserJson.team;

const isN2CommercialUser = webUserJson => webUserJson.team === USER_TEAMS.N2_COMMERCIAL;

const isN2TechnicalUser = webUserJson => webUserJson.team === USER_TEAMS.N2_TECHNIQUE;

const isN2User = webUserJson => isN2CommercialUser(webUserJson) || isN2TechnicalUser(webUserJson);

const isN3User = webUserJson => webUserJson.team === USER_TEAMS.N3_TECHNIQUE;

const isN2OrHigher = webUserJson => isN2CommercialUser(webUserJson) || isN2TechnicalUser(webUserJson) || isN3User(webUserJson);

const isN1User = webUserJson => webUserJson.team === USER_TEAMS.N1;

const isValidationUser = webUserJson => webUserJson.team === USER_TEAMS.VALIDATION;

const isSavUser = webUserJson => webUserJson.team === USER_TEAMS.SAV;

const isCCAUser = webUserJson => isN1User(webUserJson) || isValidationUser(webUserJson);

const isValidationOrHigher = webUserJson => [
    USER_TEAMS.VALIDATION,
    USER_TEAMS.N2_COMMERCIAL,
    USER_TEAMS.N2_TECHNIQUE,
    USER_TEAMS.N3_TECHNIQUE,
].includes(getWebUserTeam(webUserJson));

const getTeam = (teams, username) => teams[username];

const getTagLabel = (tags, tag) => {
    let value = tags?.[tag];
    return value || tag;
};

const getTeamType = (types, teamValue) => types.type_team[teamValue];

const getSmallTeam = (teamValue) => {
    switch (teamValue) {
        case 'N2 commercial':
            return 'N2 Com';
        case 'Validation':
            return 'Valid';
        case 'Welcome Call':
            return 'Wel Call';
        case 'Contrat':
            return 'Contr.';
        case 'N2 technique':
            return 'N2 Tech';
        case 'N3 technique':
            return 'N3 Tech';
        case 'La Poste Mobile':
            return 'LPM';
        case 'Livraison':
            return 'Livr.';
        default:
            return teamValue.length > 8 ? teamValue.substring(0, 7) : teamValue;
    }
};

const getUIFriendlyAccountMaintenanceType = (maintenanceType) =>
    maintenanceType
        .replace(/(\d)_(?=\d)/g, '$1.') // Nicer version display. I.e. AFTER_2_9_XXX => AFTER 2.9 XXX
        .replaceAll('_', ' ')

const isValideEmail = (email) => {
    return EMAIL_REGEXP.test(email);
};

const getStateType = (types, stateValue) => {
    return types.type_etat[stateValue];
};

const getCanalType = (types, canalValue) => {
    return types.type_canal[canalValue];
};

const getSubjectTypeLabel = (types, subjectValue) => {
    return types.type_demande[subjectValue]?.label
};

const getSubjects = (types, webUserJson) =>
    isN3User(webUserJson) ?
        types :
        Object.keys(types)
            .filter(_ => parseInt(_, 10) >= 0)
            .reduce((acc, key) => {
                acc[key] = types[key];
                return acc;
            }, {});

const getSubjectValues = (types, webUserJson) =>
    isN3User(webUserJson) ?
        types :
        types.filter(
            _ =>
                parseInt(_, 10) >= 0 &&
                parseInt(_, 10) !== parseInt(TICKET_SUBJECTS.RETOUR_TABLETTE, 10),
        );

const getResultType = (types, resultValue) => {
    if (resultValue === '' || resultValue === '---') return resultValue;
    return types.type_cloture[resultValue]?.label;
};

const checkIfIsOtherResult = (result) => TICKET_RESULT_OTHER_REASONS.indexOf(result) !== -1;

const shouldShowAfterSaleTabletReturnService = (types, ticketSubject) => (
    ticketSubject === TICKET_SUBJECTS.SATISFAIT_REMBOURSE ||
    ticketSubject === TICKET_SUBJECTS.SAV ||
    ticketSubject === TICKET_SUBJECTS.RETOUR_TABLETTE
);

const clone = (object) => {
    return JSON.parse(JSON.stringify(object));
};

const formatPhone = (phone) => {
    if (phone) {
        phone = phone.replace(/\D[^.]/g, '');
        phone = phone.slice(0, 2) + ' ' + phone.slice(2, 4) + ' ' + phone.slice(4, 6) + ' ' + phone.slice(6, 8) + ' ' + phone.slice(8);
        return phone;
    } else {
        return '';
    }
};

const findIndexByKeyValue = (array, key, value) => {
    return array.findIndex(element => element[key] === value);
};

const getEaster = (year) => {
    var Y = year;
    var C = Math.floor(Y / 100);
    var N = Y - 19 * Math.floor(Y / 19);
    var K = Math.floor((C - 17) / 25);
    var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor((I / 30));
    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
    var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40) / 44);
    var D = L + 28 - 31 * Math.floor(M / 4);

    return moment({year: Y, month: M - 1, date: D});

};

//################################################### NAVIGATION ###################################################

const navigateTo = route => Aviator.navigate(route);

const navigateToSearch = (mode, searchValue) => {
    navigateTo((jsRoutes.controllers.backoffice.SupportV2Controller.search(mode, searchValue).url).replace('/supportV2', ''));

};

const navigateToContract = (contractNumber, inNewTab) => {
    if (inNewTab === true) {
        window.open(getContractURL(contractNumber), '_blank');
    } else {
        navigateTo(getContractURL(contractNumber));
    }
};

const navigateToSIM = (ICCID, inNewTab) => {
    if (inNewTab === true) {
        window.open((jsRoutes.controllers.backoffice.SupportV2Controller.getSims().url + '/' + ICCID).replace('/supportV2', ''), '_blank');
    } else {
        navigateTo((jsRoutes.controllers.backoffice.SupportV2Controller.getSims().url + '/' + ICCID).replace('/supportV2', ''));
    }

};

const navigateToTicket = (contractNumber, ticketNumber) => {
    navigateTo(getTicketURL({contractNumber, ticketNumber}));
};

//################################################### /NAVIGATION ###################################################
const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
};

// Import all files in a directory
const importAll = (r) => {
    let files = {};
    r.keys().forEach((item) => {
        files[item.replace('./', '')] = r(item);
    });
    return files;
};

const arrayOrObjectEquals = (arr1, arr2) => (!arr1 && !arr2) || JSON.stringify(arr1) === JSON.stringify(arr2);

const getPopoverOverlay = (content, id) => <Popover id={id}>{content}</Popover>;

const wrapWithPopover = ({column, index, value}) => (
    <OverlayTrigger trigger={['hover']} overlay={getPopoverOverlay(value, `${column.id}${index}`)}>
        <div>{value}</div>
    </OverlayTrigger>
);
const isICCIDValid = iccid => Object.values(SIM_OPERATORS).some(operator => operator.length === iccid.length && iccid.startsWith(operator.prefix));

const getICCIDValidationState = (iccid) => {
    if (!iccid || iccid.length === 0) {
        return null;
    }
    return isICCIDValid(iccid) ? 'success' : 'error';
};

/**
 * show the component if condition is satisfied, render nothing else
 * @param condition
 * @returns {*}
 */
const showIf = (condition) => branch((props) => !condition(props), renderNothing);

const filterDateColumn = (columnName, row, filterValue) => tsToDateForInput(row[columnName]).includes(filterValue);
const filterTextColumn = (columnName, row, filterValue) => row[columnName] && row[columnName].toLowerCase().includes(filterValue.toLowerCase());

const emptyFunction = () => {
};

const setIn = (obj, path, value) => _.setWith(_.clone(obj), path, value, _.clone);

export {
    filterTeamTransfer,
    getWebUserTeam,
    isN2CommercialUser,
    isN2TechnicalUser,
    isN2User,
    isN3User,
    isN2OrHigher,
    isN1User,
    isValidationUser,
    isSavUser,
    isCCAUser,
    isValidationOrHigher,
    getTeam,
    getTagLabel,
    getTeamType,
    getSmallTeam,
    isValideEmail,
    getStateType,
    getCanalType,
    getSubjectTypeLabel,
    getSubjects,
    getSubjectValues,
    getResultType,
    checkIfIsOtherResult,
    shouldShowAfterSaleTabletReturnService,
    clone,
    formatPhone,
    findIndexByKeyValue,
    getEaster,
    navigateTo,
    navigateToSearch,
    navigateToContract,
    navigateToSIM,
    navigateToTicket,
    dataURLtoBlob,
    importAll,
    arrayOrObjectEquals,
    wrapWithPopover,
    isICCIDValid,
    getICCIDValidationState,
    showIf,
    filterDateColumn,
    filterTextColumn,
    emptyFunction,
    setIn,
    getUIFriendlyAccountMaintenanceType,
};