import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import ReactTable from 'react-table';
import {compose, pure, withHandlers, withProps} from 'recompose';
import {
  B2BFeatureDeletionErrorType,
  DELETE_B2BFEATURE_MUTATION,
  GET_B2BFEATURES_QUERY,
} from '../../../../schema/B2BSchema';
import {errorMsg} from '../../../../utils/Notify';
import {ReactTableTextProps} from '../../../../utils/ReactTable';
import './FeaturesTable.css';

const getColumns = ({handleDeleteB2BFeature}) => ([
  {
    Header: 'Option',
    accessor: 'label',
  }, {
    Header: 'Description',
    accessor: 'description',
  //  TODO: See if needed
  // }, {
  //   Header: '',
  //   accessor: 'id',
  //   width: 100,
  //   Cell: cell => (
  //     <ButtonGroup vertical block>
  //       <Button bsSize="sm" bsStyle="danger" onClick={() => handleDeleteB2BFeature(cell.original.id)}>Supprimer</Button>
  //     </ButtonGroup>
  //   ),
  },
]);

const FeaturesTablePure = ({
                             columns,
                             features,
                           }) => (
  <ReactTable
    className="-highlight"
    columns={columns}
    data={features}
    fill
    pageSize={features.length}
    resizable={false}
    showPagination={false}
    style={{
      border: 'none',
      marginTop: 5,
      height: 'calc(100vh - 300px)',
    }}
    {...ReactTableTextProps}
  />
);

FeaturesTablePure.propTypes = {
  columns: PropTypes.array,
  features: PropTypes.array,
};

const withData = graphql(GET_B2BFEATURES_QUERY);

const deleteFeature = mutate => (id) =>
  mutate({
    refetchQueries: ['GetB2BFeatures'],
    variables: {
      deleteFeatureInput: {
        id,
      },
    },
    update(store, {data: deleteData}) {
      const result = deleteData.deleteB2BFeature;

      if (result.__typename === 'B2BFeatureDeletionError') {
        switch (result.type) {
          case B2BFeatureDeletionErrorType.ID_NOT_FOUND:
            errorMsg('L\'option à supprimer est introuvable');
            break;

          case B2BFeatureDeletionErrorType.CANNOT_DELETE:
          default:
            errorMsg('Erreur lors de la suppression de l\'option');
            break;
        }
      }
    },
  });

const withDeleteMutation = graphql(DELETE_B2BFEATURE_MUTATION, {
  props: props => ({
    deleteB2BFeature: deleteFeature(props.mutate),
  }),

});

const handlers = withHandlers({
  handleDeleteB2BFeature: ({deleteB2BFeature}) => (id) => deleteB2BFeature(id),
});

const FeaturesTable = compose(
  withData,
  withDeleteMutation,
  handlers,
  withProps(
    (props) => {
      const {data} = props;
      const features = data?.b2bFeatures?.b2bFeatures || [];

      return ({
        columns: getColumns(props),
        features,
      });
    },
  ),
  pure,
)(FeaturesTablePure);

FeaturesTable.propTypes = {};

export default FeaturesTable;