import React from 'react';
import { Glyphicon } from 'react-bootstrap';

const TicketAnswersTableHead = ({sortBy, setSortBy, properties = []}) => {

  const createSortClickHandler = name => event => {
		let newSortBy = {...sortBy};
		if (!newSortBy) {
			newSortBy = {};
		}
		if (newSortBy.name === name) {
			newSortBy.reverse = !newSortBy.reverse;
		} else {
			newSortBy.reverse = false;
		}
		newSortBy.name = name;
		setSortBy(newSortBy);
	}

	return (
		<thead>
			<tr>
				{properties.map(property => {
					let arrow;
					if (sortBy.name === property.name) {
						if (sortBy.reverse) {
							arrow = <Glyphicon glyph='triangle-bottom' bsSize='small'/>;
						} else {
							arrow = <Glyphicon glyph='triangle-top' bsSize='small'/>;
						}
					}
					return (
						<th style={{ width: property.size, cursor: 'pointer' }} key={'property_header_' + property.name} onClick={createSortClickHandler(property.name)}>
							{property.name}&nbsp; {arrow}
						</th>
					);
				})}
				<th></th>
			</tr>
		</thead>
	);
}

export default TicketAnswersTableHead;