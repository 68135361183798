import React, { useState, useEffect } from 'react';

import { isFunction } from '../../../utils/functions';
import { fetchTeamTickets, postTicket, postTicketAnswer, postTicketDeletion, postTicketTransferToOtherContract } from '../../../utils/requests';
import { SAME_CONTRACT_ERROR, TRANSFER_ERROR } from '../../../utils/errors';
import LoadingProgress from '../../components/LoadingProgress';

import TicketDetails from '../TicketDetails';
import TeamTicketsTable from './TeamTicketsTable';

const TeamTicketsTab = ({team, isSelected}) => {

	const [loading, setLoading] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [selectedTicket, setSelectedTicket] = useState(null);
	const [tags, setTags] = useState([]);

	useEffect(function useFetchTickets() {
		const fetchTickets = () => {
			setLoading(true);
			fetchTeamTickets(team)
			.then((response, textStatus, jqXHR) => {
				setTickets(response.tickets);
				setTags(response.tags);
				setLoading(false);
			}, (jqXHR, textStatus, errorThrown) => {
				setLoading(false);
			});
		}

		if (isSelected) {
			fetchTickets();
		}
	}, [isSelected, team]);

	const getOtherTickets = id => tickets.filter(ticket => ticket.id !== id);

	const hideTicketDetails = () => setSelectedTicket(null);

	const addTicket = newTicket => {
		const otherTickets = getOtherTickets(newTicket.id);
		let selectedTicket = null;
		if (!newTicket.result) {
			// ticket is not closed, add it to the list
			selectedTicket = newTicket;
			otherTickets.push(newTicket);
		}
		setTickets(otherTickets);
		setSelectedTicket(selectedTicket);
	}

	const removeTicket = id => {
		const tickets = getOtherTickets(id);
		setTickets(tickets);
		setSelectedTicket(null);
	}

	const editTicket = (id, data, callback) => {
		postTicket(selectedTicket.contractNumber, data)
		.then((response, textStatus, jqXHR) => {
			addTicket(response);
			setLoading(false);
			if (isFunction(callback)) callback(id, response, true);
		}, (jqXHR, textStatus, errorThrown) => {
			setLoading(false);
			if (isFunction(callback)) callback(id, false);
		});
	}

	const deleteTicket = id => {
		postTicketDeletion(selectedTicket.contractNumber, id)
		.then((response, textStatus, jqXHR) => {
			removeTicket(id);
			setLoading(false);
		}, (jqXHR, textStatus, errorThrown) => {
			setLoading(false);
		});
	}

	const setAnswer = (id, data, callback) => {
		postTicketAnswer(selectedTicket.contractNumber, id, data)
		.then((response, textStatus, jqXHR) => {
			addTicket(response);
			setLoading(false);
			if (isFunction(callback)) callback(id, response, true);
		}, (jqXHR, textStatus, errorThrown) => {
			setLoading(false);
			if (isFunction(callback)) callback(id, false);
		});
	}

  const transferTicket = ({ newContractNumber, ticket, copyMode }, { onSuccess, onError }) => {
    const {ticketNumber, id: ticketID, contractNumber: oldContractNumber} = ticket;
		
    const data = {
      oldContractNumber,
      newContractNumber,
      ticketNumber: parseInt(ticketNumber),
      copyMode,
    };

    if (oldContractNumber === newContractNumber) {
      onError(SAME_CONTRACT_ERROR);
    } else {
      postTicketTransferToOtherContract(data)
      .then(response => {

        if (response === undefined) {
          onError(TRANSFER_ERROR);
          return;
        }
        
        if (copyMode) {
          addTicket(response.ticket);

          if (selectedTicket) {
            selectedTicket.answers = response.ticket.answers;
          }
          
        } else {
          removeTicket(ticketID);
          
          setSelectedTicket(null);
        }
        onSuccess();
      }, response => {
        if (response.status === 404 || response.status === 603) {
          onError(TRANSFER_ERROR);
        }
      });
    }
  }

	return (
		<div>
			<LoadingProgress show={loading} />

			{selectedTicket && (
				<TicketDetails
					key={selectedTicket.ticketNumber}
					contractNumber={selectedTicket.contractNumber}
					initialTicket={selectedTicket}
					modal
					tagList={tags}
					deleteTicket={deleteTicket}
					handleClose={hideTicketDetails}
					setAnswer={setAnswer}
					setTicket={editTicket}
				/>
			)}
			
			{tickets && (
				<TeamTicketsTable
					loading={loading}
					tickets={tickets}
					tagList={tags}
					transferTicket={transferTicket}
					setSelectedTicket={setSelectedTicket}
				/>
			)}
		</div>
	);
}

export default TeamTicketsTab;