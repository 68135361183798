import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {branch, compose, pure, renderComponent, withContext, withProps} from 'recompose';
import { GET_SUBSCRIPTION_TYPES_LIST } from '../../../../../../../schema/SubscriptionSchema';
import './SubscriptionType/SelectCurrentSubscriptionTypeButton.css';
import SelectCurrentSubscriptionTypeButton from './SubscriptionType/SelectCurrentSubscriptionTypeButton';
import SubscriptionTypeLabel from './SubscriptionType/SubscriptionTypeLabel';
import SubscriptionTypeSelector from './SubscriptionType/SubscriptionTypeSelector';

const SubscriptionTypePure = ({
                                handleSelectCurrentSubscriptionType,
                                updateSubscriptionType,
                              }) => (
  <>
    <SelectCurrentSubscriptionTypeButton handleSelectCurrentSubscriptionType={handleSelectCurrentSubscriptionType}/>

    <SubscriptionTypeSelector updateSubscriptionType={updateSubscriptionType}/>

    <SubscriptionTypeLabel/>
  </>
);

SubscriptionTypePure.propTypes = {
  handleSelectCurrentSubscriptionType: PropTypes.func,
};

const SubscriptionType = compose(
  graphql(GET_SUBSCRIPTION_TYPES_LIST),
  withProps(
    ({data}) => ({
      subscriptionTypes: data?.listSubscriptionTypes?.subscriptionTypeClassifications,
    }),
  ),
  withContext({
      subscriptionTypes: PropTypes.array,
    },
    ({
       subscriptionTypes,
     }) => ({
      subscriptionTypes,
    }),
  ),
  branch(
    ({isACreation}) => !isACreation,
    renderComponent(SubscriptionTypeLabel),
  ),
  pure,
)(SubscriptionTypePure);

SubscriptionType.propTypes = {
  isACreation: PropTypes.bool,
  handleSelectCurrentSubscriptionType: PropTypes.func,
  updateSubscriptionType: PropTypes.func,
};

export default SubscriptionType;