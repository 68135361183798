import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import { connectTypesContext } from '../../../../hoc/withMeAndTeamsAndConstants';
import { ENABLE_LMR_FEATURES, SAUVEGARDE_PREFIX, TICKET_STATES, TICKET_SUBJECTS } from '../../../../utils/constants';
import { checkIfIsOtherResult, getResultType } from '../../../../utils/functions';
import CloseTicketModal from '../../modals/CloseTicketModal';

const CloseNewTicketModalButton = connectTypesContext(({ types, ticket, options, addTicketTags, handleTicketChange, handleSaveData, disabled }) => {

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const handleHide = () => {
    let newTicket = {
      ...ticket,
      result: '',
    };
    delete newTicket.takeActionDay;
    delete newTicket.noLaterThanDay;
    handleTicketChange(newTicket);
    setShow(false);
  }

	const handleTicketClosure = ({firstIntention, result, resultComment}) => {
		ticket.state = TICKET_STATES.DONE;
		ticket.result = firstIntention !== '' ? firstIntention : result;
		ticket.resultComment = checkIfIsOtherResult(result) && resultComment ? resultComment : '';
		ticket = addTicketTags(ticket);

		handleTicketChange(ticket);
		handleSaveData();
		setShow(false);
	}

  let subjectResultOptions = options.type_cloture.filter(type_cloture => type_cloture.startsWith(`${ticket.subject}.`));
  
  // Dead code ??? -> ENABLE_LMR_FEATURES always false
  if (ENABLE_LMR_FEATURES && ticket.subject === TICKET_SUBJECTS.SATISFAIT_REMBOURSE) {
    subjectResultOptions = subjectResultOptions.filter(cloture => {
      const resultType = getResultType(types, cloture);
      return resultType === 'SAUVEGARDE' || resultType.startsWith(SAUVEGARDE_PREFIX);
    });
  }

  return <>
    <Button
      bsStyle='primary'
      disabled={subjectResultOptions.length === 0 || disabled}
      onClick={handleShow}
    >
      {ENABLE_LMR_FEATURES ? 'Résultat' : 'Clôturer'}
    </Button>

    {show && (
      <CloseTicketModal
        subjectResultOptions={subjectResultOptions}
        ticket={ticket}
        onSubmit={handleTicketClosure}
        onHide={handleHide}
      />
    )}
  </>;
});

CloseNewTicketModalButton.propTypes = {
  disabled: PropTypes.bool,
  ticket: PropTypes.object,
  addTicketTags: PropTypes.func,
  handleTicketChange: PropTypes.func,
  handleSaveData: PropTypes.func,
};

export default CloseNewTicketModalButton;