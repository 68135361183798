import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import Aviator from 'aviator';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import Notifications from 'react-notify-toast';
import RootContext from '../hoc/RootContext';
import {getLink, mergeMockedSchema} from '../utils/Apollo';
import {USER_TEAMS} from '../utils/constants';
import {getTeamType, getWebUserTeam} from '../utils/functions';
import {initCatchFunctions, initPingFunction} from '../utils/jquery';
import Contract from '../views/Contract';
import Admin from '../views/Admin';
import AppLayout from '../views/AppLayout';
import Devices from '../views/Devices';
import ServerError from '../views/error/ServerError';
import Login from '../views/Login';
import Maintenance from '../views/Maintenance';
import Search from '../views/Search';
import Sims from '../views/Sims';
import TeamsTickets from '../views/TeamsTickets';
import jsRoutes from './routes';
import { isDevicesAndSimsVisible, isAdminVisible, isMaintenanceVisible } from '../utils/accessRights';

moment.locale('fr');

let webUser = null;
const reactDomRenderFactory = (component, topNavProps) => {
  const componentWithRootContext = (
    <RootContext
      apolloClient={window.apolloClient}
      tags={window.tags}
      teams={window.teams}
      types={window.types}
      webUserJson={window.webUserJson}
    >
      <AppLayout topNavProps={topNavProps}>
        {component}
      </AppLayout>
    </RootContext>
  );
  ReactDOM.render(componentWithRootContext, document.getElementById('app'));
  ReactDOM.render(<Notifications/>, document.getElementById('notifications'));
};

const AppSetup = {
  setup_app: () => {
    if (webUser) {
      require('../css/bootstrap-canari.css');
      require('../css/bootstrap-theme-canari.css');
      require('../css/canary.css');
      require('../css/bootstrap-panel-navtabs.css');
    }
  },
};

const renderServerError = () => ReactDOM.render(<ServerError/>, document.getElementById('app'));
const ServerErrorSetup = {
  setup_server_error: renderServerError,
};

const LoginSetup = {
  setup_login: () => {
    window.jQuery('html').addClass('csstransitions csstransforms3d');
    window.jQuery('body').addClass('loginWrapper breakpoint-992 breakpoint-768');

    reactDomRenderFactory(<Login/>);
  },
};

const ContractSetup = {
  setup_contract: (request) => {
    if (webUser)
      reactDomRenderFactory(<Contract contractNumber={request.namedParams.contractNumber} />);
  },
  setup_contract_with_ticket: (request) => {
    if (webUser)
      reactDomRenderFactory(
        <Contract
          contractNumber={request.namedParams.contractNumber}
          ticketNumber={request.namedParams.ticketNumber}
        />
      );
  },
};

const TicketsSetup = {
  setup_tickets_with_default_team: () => {
    if (webUser)
      reactDomRenderFactory(<TeamsTickets selectedTeam={getTeamType(window.types, getWebUserTeam(window.webUserJson))}/>);
  },
  setup_tickets_with_selected_team: (request) => {
    if (webUser)
      reactDomRenderFactory(<TeamsTickets selectedTeam={request.namedParams.team}/>);
  },
};

let AdminSetup = {
  admin_setup: () => {
    if (webUser && isAdminVisible(webUser))
      reactDomRenderFactory(<Admin/>);
  },
};

const SimsSetup = {
  setup_sims: () => {
    if (webUser && isDevicesAndSimsVisible(webUser)) {
      reactDomRenderFactory(<Sims/>);
    }
  },
  setup_sims_with_ICCID: (request) => {
    if (webUser && isDevicesAndSimsVisible(webUser)) {
      reactDomRenderFactory(<Sims ICCID={request.namedParams.ICCID}/>);
    }
  },
};

const DevicesSetup = {
  setup_devices: () => {
    if (webUser && isDevicesAndSimsVisible(webUser)) {
      reactDomRenderFactory(<Devices/>);
    }
  },
};

const SearchSetup = {
  setup_search: (request) => {
    if (webUser) {
      const params = {
        mode: request.namedParams.mode,
        searchValue: request.namedParams.searchValue,
      };

      reactDomRenderFactory(<Search {...params} />, params);
    }
  },
};

const MaintenanceSetup = {
  setup_maintenance: () => {
    if (webUser && isMaintenanceVisible(webUser)) {
      reactDomRenderFactory(<Maintenance/>);
    }
  },
};

const setUpRoutes = () => {
  Aviator.setRoutes({
    target: AppSetup,
    '/*': 'setup_app',
    '/servererror': {
      target: ServerErrorSetup,
      '/': 'setup_server_error',
    },
    '/login': {
      target: LoginSetup,
      '/': 'setup_login',
    },
    '/admin': {
      target: AdminSetup,
      '/': 'admin_setup',
    },
    '/ticket': {
      target: TicketsSetup,
      '/': 'setup_tickets_with_default_team',
      '/:team': 'setup_tickets_with_selected_team',
    },
    '/sim': {
      target: SimsSetup,
      '/': 'setup_sims',
      '/:ICCID': 'setup_sims_with_ICCID',
    },
    '/device': {
      target: DevicesSetup,
      '/*': 'setup_devices',
    },
    '/contract': {
      target: ContractSetup,
      '/': 'setup_table',
      '/:contractNumber': {
        '/': 'setup_contract',
        '/ticket/:ticketNumber': 'setup_contract_with_ticket',
      },
    },
    '/search': {
      target: SearchSetup,
      '/': 'setup_search',
      '/:mode/:searchValue': 'setup_search',
    },
    '/maintenance': {
      target: MaintenanceSetup,
      '/': 'setup_maintenance',
    },
  });

  Aviator.dispatch();
};

// Data initialization
const initApp = () => {
  const isDevMode = process.env.NODE_ENV !== 'production',
    appolloLink = getLink();

  if (isDevMode) {
    mergeMockedSchema(appolloLink).then(apolloClient => getMeAndTeamsAndConstants(apolloClient));
  } else {
    const apolloClient = new ApolloClient({
      link: appolloLink,
      cache: new InMemoryCache(),
    });

    getMeAndTeamsAndConstants(apolloClient);
  }
};

const getMeAndTeamsAndConstants = (apolloClient) => {
  jsRoutes.controllers.backoffice.SupportV2Controller.getMeAndTeamsAndConstants().ajax({
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    processData: false,
  }).then(response => {
    window.tags = response.tags;
    window.teams = response.teams;
    window.types = response.types;
    webUser = window.webUserJson = response.webUser;

    if (apolloClient) {
      window.apolloClient = apolloClient;
    } else {
      renderServerError();
      return;
    }

    initCatchFunctions();
    initPingFunction();
    setUpRoutes();

    if (webUser) {
      if (Aviator.getCurrentURI() === '/' || Aviator.getCurrentURI() === '/login') {
        let route = webUser.team === USER_TEAMS.SAV ? '/device' : '/ticket'; // default route
        route = window.calledRouteLogin && window.calledRouteLogin !== '/login' && window.calledRouteLogin !== '/' ? window.calledRouteLogin : route; // Called route before login
        Aviator.navigate(route);
      }
    } else Aviator.navigate('/login');
  }).fail(error => {
    if (error.status === 401 && apolloClient) {
      window.apolloClient = apolloClient;
      setUpRoutes();
      setCalledRouteBeforeLogin();
      Aviator.navigate('/login');
    } else {
      renderServerError();
    }
  });
};

const setCalledRouteBeforeLogin = () => {
  window.calledRouteLogin = Aviator.getCurrentURI();
};

export {setUpRoutes, initApp};