import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateFunction, withButtonContext} from './Helpers';

const NeedTerminationButtonPure = ({
                                     buttonSize,
                                     onValidateClick,
                                   }) => (
  <Button bsStyle='danger' bsSize={buttonSize} onClick={onValidateClick}>
    Désactiver
  </Button>
);

NeedTerminationButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  onValidateClick: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateFunction,
});

const NeedTerminationButton = compose(
  withButtonContext,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.terminate,
    }),
  ),
  handlers,
  pure,
)(NeedTerminationButtonPure);

NeedTerminationButton.propTypes = {};

export default NeedTerminationButton;