import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {connectWebUserContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {isN2OrHigher} from '../../../../../utils/functions';

const TicketTagsSelect = connectWebUserContext(({ webUserJson, disabled, options, value, onChange }) => {

	const SelectComponent = isN2OrHigher(webUserJson) ? Select.Creatable : Select;

	return (
    <SelectComponent
			multi
			disabled={disabled}
			placeholder='Rechercher un tag...'
			options={options}
			value={value}
			onChange={onChange}
		/>
	);
});

TicketTagsSelect.propTypes = {
	disabled: PropTypes.bool,
	options: PropTypes.array,
	value: PropTypes.array,
	onChange: PropTypes.func,
};

export default TicketTagsSelect;