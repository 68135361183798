import PropTypes from 'prop-types';
import React from 'react';
import {Grid, Row} from 'react-bootstrap';
import {compose, pure, withState} from 'recompose';
import ChangeOperatorModal from './Search/ChangeOperatorModal';
import {withSearchSimContext} from './Search/Helpers';
import SimsTable from './Search/SimsTable';

const SimsSearchViewPure = ({
                    ICCID,
                  }) => (
  <Grid>
    <Row>
      <ChangeOperatorModal/>
      <SimsTable ICCID={ICCID}/>
    </Row>
  </Grid>
);

SimsSearchViewPure.propTypes = {
  ICCID: PropTypes.string,
};

const withCallback4OperatorEditionState = withState('callback4OperatorEdition', 'setCallback4OperatorEdition', null);
const withChangeSIMOperatorState = withState('changeSIMOperator', 'setChangeSIMOperator', '');

const SimsSearchView = compose(
  withCallback4OperatorEditionState,
  withChangeSIMOperatorState,
  withSearchSimContext,
  pure,
)(SimsSearchViewPure);

SimsSearchView.propTypes = {
  ICCID: PropTypes.string,
};

export default SimsSearchView;