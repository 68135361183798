import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, Col, ControlLabel, FormControl, Modal, Row} from 'react-bootstrap';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';
import {
  B2BFeatureCreationErrorType,
  CREATE_B2BFEATURE_MUTATION,
  GET_B2BFEATURES_QUERY,
} from '../../../../schema/B2BSchema';
import {errorMsg} from '../../../../utils/Notify';
import LoadingProgress from '../../../components/LoadingProgress';

const FeatureFormPure = ({
                           description,
                           label,
                           loading,
                           openModalButtonLabel,
                           show,
                           title,
                           validateButtonLabel,
                           handleCreateFeature,
                           setDescription,
                           setLabel,
                           setShow,
                         }) => (
  <>
    <Button bsStyle="primary" className="pull-right" onClick={() => setShow(true)}>
      {openModalButtonLabel}
    </Button>

    <Modal show={show}
           onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <LoadingProgress show={loading}/>

        <Row>
          <Col md={8} mdOffset={2}>
            <FormGroup>
              <ControlLabel>Option *</ControlLabel>
              <FormControl type="text" value={label} onChange={e => setLabel(e.target.value)}/>
            </FormGroup>
          </Col>
          <Col md={8} mdOffset={2}>
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <FormControl componentClass="textarea"
                           rows={4}
                           type="text"
                           value={description}
                           onChange={e => setDescription(e.target.value)}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <Button bsStyle="primary"
                    disabled={!label}
                    className="pull-right"
                    onClick={handleCreateFeature}>
              {validateButtonLabel}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  </>

);

FeatureFormPure.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  openModalButtonLabel: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  validateButtonLabel: PropTypes.string,
  handleCreateFeature: PropTypes.func,
  setDescription: PropTypes.func,
  setLabel: PropTypes.func,
  setShow: PropTypes.func,
};

const withDescriptionState = withState('description', 'setDescription', ({feature}) => feature?.description || '');
const withLabelState = withState('label', 'setLabel', ({feature}) => feature?.label || '');
const withShowState = withState('show', 'setShow', false);

const withCreateMutation = graphql(CREATE_B2BFEATURE_MUTATION, {name: 'createB2BFeature'});

const handlers = withHandlers({
  handleCreateFeature: ({
                          label,
                          description,
                          createB2BFeature,
                          setDescription,
                          setLabel,
                          setShow,
                        }) => () => createB2BFeature({
    variables: {
      createFeatureInput: {
        description,
        label,
      },
    },
    update: (store, {data: createData}) => {
      const data = store.readQuery({query: GET_B2BFEATURES_QUERY});
      const result = createData.createB2BFeature;

      if (result.__typename === 'CreateFeatureOutput') {
        const newData = {
          ...data,
          b2bFeatures: {
            b2bFeatures: [
              result.feature,
              ...data.b2bFeatures.b2bFeatures,
            ],
            __typename: 'GetB2BFeatures',
          },
        };

        store.writeQuery({data: newData, query: GET_B2BFEATURES_QUERY});
      } else if (result.__typename === 'B2BFeatureCreationError') {
        switch (result.type) {
          case B2BFeatureCreationErrorType.ID_COLLISION:
            errorMsg('Une option portant ce nom existe déjà');
            break;

          case B2BFeatureCreationErrorType.LABEL_IS_BLANK:
            errorMsg('Veuillez renseigner le nom de l\'option');
            break;

          case B2BFeatureCreationErrorType.CANNOT_CREATE:
          default:
            errorMsg('Erreur lors de la sauvegarde de l\'option');
            break;
        }
      }
    },
  }).then(
    async () => {
      await setDescription('');
      await setLabel('');
      await setShow(false);
    },
    error => errorMsg('Erreur lors de la sauvegarde de l\'option'),
  ),
});

const FeatureForm = compose(
  withProps(
    ({feature}) => ({
      openModalButtonLabel: !feature ? 'Créer une option' : 'Modifier',
      title: !feature ? 'Création d\'une option' : 'Modification d\'une option',
      validateButtonLabel: !feature ? 'Créer' : 'Modifier',
    }),
  ),
  withDescriptionState,
  withLabelState,
  withShowState,
  withCreateMutation,
  handlers,
  pure,
)(FeatureFormPure);

FeatureForm.propTypes = {
  feature: PropTypes.object,
};

export default FeatureForm;