const USER_RIGHTS = [
    {key: 'ADMIN', label: 'Administrateur', adminOnly: true},
    {key: 'N1', label: 'Accès N1', adminOnly: true},
    {key: 'TEAM_VIEWER', label: 'Accès Teamviewer'},
    {key: 'B2B', label: 'Gestion B2B'},
];

const getCanariLink = webUser => webUser.links.find(_ => _.channelKey === '*');

const findRight = (rights, right) => rights.find(_ => _.value === right);

const isAdmin = webUser => {
    const canariLink = getCanariLink(webUser);
    return canariLink && !!findRight(canariLink.rights, 'ADMIN');
}

const hasTeamViewerRight = webUser => {
    const canariLink = getCanariLink(webUser);
    return canariLink && !!findRight(canariLink.rights, 'TEAM_VIEWER');
};

const convertRights = formRights =>
    Object.entries(formRights)
    .filter(([key, value]) => value)
    .map(([key, value]) => ({value: key}));

export {
    USER_RIGHTS,
    isAdmin,
    hasTeamViewerRight,
    convertRights,
};