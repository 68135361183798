import React from 'react';
import { createPortal } from 'react-dom';
import FontAwesome from './FontAwesome';

const LoadingProgress = ({show}) => {
  if (!show) return null;
  
  return createPortal(
    <div className="loading-overlay">
      <div className="loading-spinner">
        <FontAwesome pulse size="5x" name="spinner"/>
      </div>
    </div>
  , document.body);
};

export default LoadingProgress;