import registerServiceWorker from './registerServiceWorker';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-select/dist/react-select.css';
import 'react-table/react-table.css';
import 'core-js';
import 'babel-polyfill';

import {initApp} from './routes/routes_init';
import { initJQuery } from './utils/jquery';
import { registerUrlChangeEvent } from './utils/AviatorEvents';

registerServiceWorker();
registerUrlChangeEvent();

moment.locale('fr');

initJQuery();
initApp();
