import PropTypes from 'prop-types';
import React from 'react';
import {NavItem} from 'react-bootstrap';
import {connectWebUserContext} from '../../hoc/withMeAndTeamsAndConstants';
import { isAdminVisible } from '../../utils/accessRights';
import { navigateTo } from '../../utils/functions';

const AdminSection = connectWebUserContext(({ webUserJson, active }) => {
  if (isAdminVisible(webUserJson)) {
    return (
      <NavItem
        key="admin"
        active={active}
        onClick={() => navigateTo('/admin')}
      >
        Admin
      </NavItem>
    );
  }

  return null;
});

AdminSection.propTypes = {
  active: PropTypes.bool,
  navigateTo: PropTypes.func,
};

export default AdminSection;