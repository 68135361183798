import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';

const ToucanListOperationSuccessPure = ({success}) => (
    <Alert bsStyle='success'>
        <ul>
            {success.map((_, index) => <li key={index}>{_}</li>)}
        </ul>
    </Alert>
);

ToucanListOperationSuccessPure.propTypes = {
    success: PropTypes.array,
};

const ToucanListOperationSuccess = compose(
    branch(
        ({success}) => !success?.length,
        renderNothing,
    ),
    pure,
)(ToucanListOperationSuccessPure);

ToucanListOperationSuccess.propTypes = {
    success: PropTypes.array,
};

export default ToucanListOperationSuccess;