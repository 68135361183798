import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormGroup, Row} from 'react-bootstrap';
import {FRENCH_DAY_LABELS, FRENCH_MONTH_LABELS} from '../../../utils/constants';

import {DatePicker} from '../../components/DatePicker';

const ProcessingDatesInputs = ({ticket, handleDateChange}) => {
	const noLaterThanDay = ticket.noLaterThanDay ? new Date(ticket.noLaterThanDay).toISOString() : null;
	const takeActionDay = ticket.takeActionDay ? new Date(ticket.takeActionDay).toISOString() : null;

	const handleTakeActionDayChange = value => handleDateChange('takeActionDay', value);

	const handleNoLaterThanDayChange = value => handleDateChange('noLaterThanDay', value);
	
	return (
    <Row>
			<Col md={6}>
				<FormGroup>
					<ControlLabel>A traiter le</ControlLabel>
					<DatePicker
						dayLabels={FRENCH_DAY_LABELS}
						data-test-id='takeActionDayDatePicker'
						monthLabels={FRENCH_MONTH_LABELS}
						value={takeActionDay}
						weekStartsOnMonday
						onChange={handleTakeActionDayChange}
					/>
				</FormGroup>
			</Col>
			<Col md={6}>
				<FormGroup>
					<ControlLabel>Au plus tard le</ControlLabel>
					<DatePicker
						dayLabels={FRENCH_DAY_LABELS}
						data-test-id='noLaterThanDayDatePicker'
						monthLabels={FRENCH_MONTH_LABELS}
						value={noLaterThanDay}
						weekStartsOnMonday
						onChange={handleNoLaterThanDayChange}
					/>
				</FormGroup>
			</Col>
    </Row>
	);
}

ProcessingDatesInputs.propTypes = {
	ticket: PropTypes.object,
	handleDateChange: PropTypes.func,
};

export default ProcessingDatesInputs;