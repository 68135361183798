import React from 'react';
import PropTypes from 'prop-types';
import {branch, compose, pure, renderNothing, withProps} from 'recompose';
import {Panel} from 'react-bootstrap';
import ReactTable from 'react-table';

import {toFrenchDateString} from '../../../../../utils/Date';

const COLUMNS = [
    {
        Header: '',
        accessor: 'label',
        width: 120,
    }, {
        Header: 'Référence de l\'offre',
        accessor: 'referenceOffer',
    }, {
        Header: 'Date de facturation',
        accessor: 'dateMove',
        width: 140,
    },
];

const Order3xPaymentInfosPure = ({ paymentInfos }) => (
    <>
        <h4>Informations de paiement 3x sans frais</h4>
        <Panel key={3}>
            <Panel.Body>
                <ReactTable
                    className='-highlight'
                    columns={COLUMNS}
                    data={paymentInfos}
                    fill
                    pageSize={paymentInfos.length}
                    resizable={false}
                    showPagination={false}
                    style={{ border: 'none' }}
                    getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
                />
            </Panel.Body>
        </Panel>
    </>
);

const Order3xPaymentInfos = compose(
    branch(
        ({subscription}) => !subscription?.metadata?.paymentIn3Installments?.subscribed,
        renderNothing,
    ),
    withProps(({subscription}) => {
        const {secondInstallment, thirdInstallment} = subscription?.metadata?.paymentIn3Installments;
        const parseReferenceOffer = referenceOffer => referenceOffer ? referenceOffer.replace(/_/g, ' ') : '';

        return {
            paymentInfos: [
                {
                    label: '2è paiement',
                    dateMove: toFrenchDateString(secondInstallment?.dateMove),
                    referenceOffer: parseReferenceOffer(secondInstallment?.referenceOffer),
                },
                {
                    label: '3è paiement',
                    dateMove: toFrenchDateString(thirdInstallment?.dateMove),
                    referenceOffer: parseReferenceOffer(thirdInstallment?.referenceOffer),
                },
            ],
        };
    }),
    pure,
)(Order3xPaymentInfosPure);

Order3xPaymentInfos.propTypes = {
    subscription: PropTypes.object,
};

export default Order3xPaymentInfos;