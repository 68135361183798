import PropTypes from 'prop-types';
import {getContext, withContext} from 'recompose';

const withContractContext = withContext({
        contract: PropTypes.object,
        setContract: PropTypes.func,
    },
    ({ contract, setContract }) => ({ contract, setContract }),
);

const connectContractContext = Component => getContext(
    {
        contract: PropTypes.object,
        setContract: PropTypes.func,
    }
)(Component);

export { withContractContext, connectContractContext };