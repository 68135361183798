import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'react-bootstrap';
import { notify } from 'react-notify-toast';

import jsRoutes from '../../../routes/routes';
import {isNotEmpty, isSame} from '../../../utils/Array';

import EditButton from '../EditButton';
import TagSelect from './TagSelect';
import TagsList from './TagsList';
import {CancelIconButton, SaveIconButton} from '..';

const SupportV2Controller = jsRoutes.controllers.backoffice.SupportV2Controller;

const fetchTags = (contractNumber) => SupportV2Controller.getContractTags(contractNumber).ajax({
	dataType: 'json',
	contentType: 'application/json; charset=utf-8',
	processData: false
});

const saveTags = (contractNumber, tags) => SupportV2Controller.setContractTags(contractNumber).ajax({
	data: JSON.stringify(tags.map(tag => tag.value)),
	contentType: 'application/json; charset=utf-8',
	processData: false
});

const ContractTags = ({ contractNumber, tagsOptions }) => {
	const [editing, toggleEdition] = useState(false);
	const [originalTags, setOriginalTags] = useState([]);
	const [tags, setTags] = useState([]);

	useEffect(function useFetchTags() {
		fetchTags(contractNumber).then(response => {
			const tags = isNotEmpty(response) ? response.map(tag => ({ value: tag, label: tag })) : [];
			setTags(tags);
			setOriginalTags([...tags]);
		});
	}, [contractNumber]);

	const handleEdit = () => toggleEdition(true);
	
	const handleCancel = () => {
		toggleEdition(false);
		setTags(originalTags);
	}

	const handleSave = () => {
		if (isSame(tags, originalTags)) {
			toggleEdition(false);
			return;
		}
		saveTags(contractNumber, tags).then(() => {
			setTags(tags);
			setOriginalTags([...tags]);
			toggleEdition(false);
		}).catch(error => {
			notify.show('Impossible de mettre à jour les tags !', 'error', -1);
		});
	}

	if (!contractNumber) return null;
	
	return (
		<FormGroup>
			{editing
			? <>
					<SaveIconButton className='mr-1' onClick={handleSave} />
					<CancelIconButton onClick={handleCancel} />
				</>
			: <EditButton onClick={handleEdit} />
			}
			{editing
			? <TagSelect
					editing={editing}
					tagsOptions={tagsOptions}
					tags={tags}
					handleChange={setTags}
				/>
			: <div><TagsList tags={tags} /></div>
			}
		</FormGroup>
	);
}

ContractTags.propTypes = {
	contractNumber: PropTypes.string,
	tagsOptions: PropTypes.array,
};

export default ContractTags;