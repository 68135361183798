import React from 'react';
import {graphql} from 'react-apollo';
import {GET_METRICS} from '../../schema/MaintenanceSchema';
import LoadingProgress from '../components/LoadingProgress';
import MaintenanceGroup from './MaintenanceGroup';

const MaintenanceView = graphql(GET_METRICS, {
  options: {
    returnPartialData: true,
  },
})(({data}) => {
  const {getMetrics, loading} = data;

  if (loading) {
    return <LoadingProgress show />;
  }

  const metrics = getMetrics?.metrics || [];
  const groupedMetrics = [];

  metrics.forEach(metric => {
    const metricTypePrefix = metric.metricType.split('_')[0];
    const existingGroup = groupedMetrics.find(_ => _.group === metricTypePrefix);

    if (existingGroup) {
      existingGroup.metrics.push(metric);
    } else {
      groupedMetrics.push({
        group: metricTypePrefix,
        metrics: [metric],
      });
    }
  });

  return <>
    {groupedMetrics.map(groupedMetric => (
      <MaintenanceGroup
        key={groupedMetric.group}
        group={groupedMetric.group}
        metrics={groupedMetric.metrics}
      />
    ))}
  </>;
});

export default MaintenanceView;