import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonGroup} from 'react-bootstrap';
import {branch, compose, getContext, pure, renderNothing} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../hoc/withMeAndTeamsAndConstants';
import {isN2OrHigher} from '../../../../utils/functions';

const ChangeOperatorButtonPure = ({
                                    buttonSize,
                                    displayVertical,
                                    onClick,
                                  }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <Button bsStyle='info' bsSize={buttonSize} onClick={onClick}>
      Changer l'opérateur
    </Button>
  </ButtonGroup>

);

ChangeOperatorButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  displayVertical: PropTypes.bool,
  onClick: PropTypes.func,
};

const ChangeOperatorButton = compose(
  connectMeAndTeamsAndConstantsContext,
  branch(
    ({webUserJson}) => !isN2OrHigher(webUserJson),
    renderNothing,
  ),
  getContext(
    {
      buttonSize: PropTypes.string,
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(ChangeOperatorButtonPure);

ChangeOperatorButton.propTypes = {
  onClick: PropTypes.func,
};

export default ChangeOperatorButton;