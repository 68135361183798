import React from 'react';

import {getSubjectTypeLabel, getStateType} from '../../../../../../utils/functions';

import {connectTypesContext} from '../../../../../../hoc/withMeAndTeamsAndConstants';
import TicketAnswersTableFilterSelect from './TicketAnswersTableFilterSelect';
import TicketAnswersTableFilterInput from './TicketAnswersTableFilterInput copy';

const TicketAnswersTableFilter = connectTypesContext(({types, name, value, onChange}) => {

	switch (name) {
		
		case 'État':
			return (
				<TicketAnswersTableFilterSelect
					name={name}
					value={value}
					onChange={onChange}
					options={types.type_etat}
					getOptionLabel={getStateType}
				/>
			);

		case 'Demande':
			return (
				<TicketAnswersTableFilterSelect
					name={name}
					value={value}
					onChange={onChange}
					options={types.type_demande}
					getOptionLabel={getSubjectTypeLabel}
				/>
			);

		default:
			return (
				<TicketAnswersTableFilterInput
					value={value}
					onChange={onChange}
				/>
			);
	}
});

export default TicketAnswersTableFilter;