import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import {SchemaLink} from 'apollo-link-schema';
import PropTypes from 'prop-types';
import {getContext, withContext} from 'recompose';
import {introspectSchema, makeRemoteExecutableSchema, mergeSchemas} from 'graphql-tools';

import {TOUCAN_AUTHORIZATION, TOUCAN_URL} from './constants';
import initMockedData from '../mock/initMockedData';
import mockSchema from '../mock/graphql/schema/schema';
import {typeExtensions} from '../mock/graphql/schema/typeDefs';
import {schemaExtensionResolvers} from '../mock/graphql/schema/resolvers';

initMockedData();

const getLink = () =>
    new HttpLink({
        credentials: 'include',
        uri: `${TOUCAN_URL}/graphql`,
        transportBatching: true,
        fetch,
    });

const getAdminLink = () => {
    const httpLink = getLink();
    const middlewareLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: TOUCAN_AUTHORIZATION,
            }
        });
        return forward(operation);
    });

    return middlewareLink.concat(httpLink);
};

const getClient = mergedSchema =>
    new ApolloClient({
        link: new SchemaLink({ schema: mergedSchema }),
        cache: new InMemoryCache({
            dataIdFromObject: o => o.id,
            // Fix: error caused by introspection, see https://github.com/apollographql/apollo-client/issues/3397
            fragmentMatcher: new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            }),
        }),
    });

const mergeMockedSchema = async link => {
    try {
        const adminLink = getAdminLink();

        const schema = await introspectSchema(adminLink);
        const toucanSchema = makeRemoteExecutableSchema({ schema, link });
        const mergedSchema = process.env.NODE_ENV === 'production' ?
            toucanSchema :
            mergeSchemas({
                schemas: [toucanSchema, mockSchema, typeExtensions],
                resolvers: schemaExtensionResolvers,
            });

        return getClient(mergedSchema);
    } catch(e) {
        return;
    }
};

const withApolloClientContext = (getFromProps) =>
    withContext(
        {
            apolloClient: PropTypes.object,
        },
        getFromProps
    );

const connectApolloClientContext = Component => getContext(
    {
        apolloClient: PropTypes.object,
    }
)(Component);

export { getLink, mergeMockedSchema, withApolloClientContext, connectApolloClientContext };