import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import {compose, pure, withHandlers, withProps} from 'recompose';

import {dataURLtoBlob, getUIFriendlyAccountMaintenanceType} from '../../../../../../utils/functions';
import jsRoutes from '../../../../../../routes/routes';

const AccountMaintenanceEditPure = ({schema, handleSubmit, onHide}) => (
  <Modal show={true} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Création d'une maintenance</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form schema={schema}
            onSubmit={handleSubmit}
            formData={{}}
      >
        <Button className="btn btn-success btn-block"
                type="submit">Créer</Button>
      </Form>
    </Modal.Body>
  </Modal>
);

AccountMaintenanceEditPure.propTypes = {
  schema: PropTypes.object,
  handleSubmit: PropTypes.func,
};

const getMaintenancesTypes = types => {
  const removedMaintenancesTypes = ['WIPE_DATABASE'];
  return types
    .filter(_ => !removedMaintenancesTypes.includes(_))
    .sort();
};

const getProps = withProps(({maintenanceInfo}) => ({
  schema: {
    title: 'Maintenance',
    type: 'object',
    required: ['type'],
    properties: {
      type: {
          type: 'string',
          enum: getMaintenancesTypes(maintenanceInfo.types),
          enumNames: getMaintenancesTypes(maintenanceInfo.types).map(getUIFriendlyAccountMaintenanceType),
          title: 'type'},
      targetFilePath: {type: 'string', title: 'targetFilePath'},
      renamedFilePath: {type: 'string', title: 'renamedFilePath'},
      file: {type: 'string', format: 'data-url', title: 'file'},
    },
  },
}));

export const setMaintenance = ({
                                 contractNumber,
                                 formData,
                                 maintenanceInfo,
                               }) => {
  const maintenance = formData;
  maintenance.MAC = maintenanceInfo.MAC;
  maintenance.IMEI = maintenanceInfo.IMEI;
  maintenance.dateCrea = new Date().getTime();

  const {file} = formData;
  const data = new FormData();

  if (file) {
    data.append('file', dataURLtoBlob(file));
    delete formData.file;
  }

  data.append('maintenance', JSON.stringify(maintenance));

  return jsRoutes.controllers.backoffice.MaintenanceV2Controller
    .setMaintenance(contractNumber)
    .ajax({
      processData: false,
      contentType: false,
      data: data,
    });
};

const handlers = withHandlers({
  handleSubmit: ({contractNumber, maintenanceInfo, onHide, setMaintenanceInfo}) => ({formData}) =>
    setMaintenance({
      contractNumber,
      formData,
      maintenanceInfo,
    }).then(
      (response) => {
        const updatedMaintenance = {...maintenanceInfo};
        updatedMaintenance.maintenances.push(response);
        setMaintenanceInfo(
          updatedMaintenance,
          () => onHide(),
        );
      },
      () => {},
    ),
});

const AccountMaintenanceEdit = compose(
  getProps,
  handlers,
  pure,
)(AccountMaintenanceEditPure);

AccountMaintenanceEdit.propTypes = {
  contractNumber: PropTypes.string,
  maintenanceInfo: PropTypes.object,
  handleAddMaintenance: PropTypes.func,
  onHide: PropTypes.func,
  setMaintenanceInfo: PropTypes.func,
};

export default AccountMaintenanceEdit;