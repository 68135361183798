import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert, Button, ButtonGroup, Glyphicon, Panel} from 'react-bootstrap';
import ReactTable from 'react-table';
import {compose, pure, withProps, withState} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';

import {isN1User, isN2CommercialUser, wrapWithPopover} from '../../../../../utils/functions';
import {isPrepayed} from '../../../../../utils/Subscription';
import ProabonoLink from './History/ProabonoLink';
import EditSepaModal from './Sepa/EditSepaModal';
import AddSubscriptionStateButton from './History/AddSubscriptionStateButton';
import SubEntry from './History/SubEntry';
import {isDateNowOrInFuture} from '../../../../../utils/Date';
import { connectContractNumberContext } from '../../../../../hoc/contractNumber';

const formatDateCell = ({value}) => value ? moment(value).format('L') : '';

const BASE_COLUMNS = [
    {
        Header: 'Débuté le',
        accessor: 'dateBegin',
        width: 85,
        Cell: formatDateCell,
    }, {
        Header: 'Fin d\'engagement le',
        accessor: 'engagementEnd',
        width: 85,
        Cell: formatDateCell,
    }, {
        Header: 'Terminé le',
        accessor: 'dateEnd',
        width: 85,
        Cell: formatDateCell,
    }, {
        Header: 'Type',
        accessor: 'type',
        Cell: wrapWithPopover,
    }, {
        Header: 'Ajouté le',
        accessor: 'dateCrea',
        width: 85,
        Cell: formatDateCell,
    },
];

const getTrPropsFactory = (currentSubscriptionStateIndex, currentSubscriptionStateErrorIndexes) => (state, rowInfo, column, instance) => {
    if (rowInfo && rowInfo.index === currentSubscriptionStateIndex) {
        return {
            style: {
                background: 'rgba(217, 237, 255, 0.5)',
            },
        };
    } else if (rowInfo && currentSubscriptionStateErrorIndexes.indexOf(rowInfo.index) > -1) {
        return {
            style: {
                background: 'rgba(217, 83, 79, 0.5)',
            },
        };
    }
    return {};
};

const HistoryPure = ({columns, currentSubscriptionStateErrorIndexes, pageSize, showHistory, subscription, tableHeight, getTrProps, setShowHistory}) => (
    <>
        <h4 key={1} onClick={() => setShowHistory(!showHistory)} className='cursor-pointer'>
            <span>Historique</span>
            <ProabonoLink/>
            <Glyphicon className='pull-right' glyph={showHistory ? 'triangle-top' : 'triangle-bottom'}/>
        </h4>
        {currentSubscriptionStateErrorIndexes.length > 0 &&
        <Alert key={3} bsStyle='danger'>Vérifiez les dates dans l'historique d'abonnement</Alert>}
        <Panel key={2} expanded={showHistory} onToggle={() => {
        }}>
            <Panel.Collapse>
                <Panel.Body>
                    <ReactTable
                        className='-highlight'
                        columns={columns}
                        data={subscription?.metadata?.subscriptionStates || []}
                        fill
                        pageSize={pageSize}
                        resizable={false}
                        showPagination={false}
                        style={{
                            height: {tableHeight},
                            border: 'none',
                        }}
                        SubComponent={
                            ({row}) => <SubEntry columns={columns}
                                                 subscriptionState={row}/>
                        }
                        getTrProps={getTrProps}
                        getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
                    />
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    </>
);

HistoryPure.propTypes = {
    currentSubscriptionStateErrorIndexes: PropTypes.array,
    pageSize: PropTypes.number,
    showHistory: PropTypes.bool,
    subscription: PropTypes.object,
    tableHeight: PropTypes.string,
    setShowHistory: PropTypes.func,
};

const withCalculatedProps = withProps(
    ({
        contractNumber,
        currentSubscriptionStateErrorIndexes,
        currentSubscriptionStateIndex,
        subscription,
        webUserJson,
        deleteSubscriptionState,
        deleteLastSubscriptionState,
        editSubscriptionState,
     }) => {
        const pageSize = subscription?.metadata?.subscriptionStates?.length || 1;
        const lastSubscriptionState = [...subscription?.metadata?.subscriptionStates || []].shift();
        const isN1Rights = isN1User(webUserJson);
        const isN2CommercialRights = isN2CommercialUser(webUserJson);
        const isDeletableState = (index, original) =>
            isN2CommercialRights &&
            !original?.notSaved &&
            index === 0 &&
            original?.dateBegin &&
            isDateNowOrInFuture(original?.dateBegin)

        return {
            pageSize,
            tableHeight: (pageSize * 50 + 100) + 'px',
            columns: [
                ...BASE_COLUMNS,
                {
                    Header: () => !isN1Rights &&
                        <AddSubscriptionStateButton lastSubscriptionState={lastSubscriptionState}
                                                    onClick={() => editSubscriptionState(-1)}/>,
                    width: 50,
                    Cell: ({index, original}) => (
                        <ButtonGroup vertical block>
                            {!isN1Rights && (original?.notSaved || isPrepayed(original?.type)) &&
                            <Button bsStyle='info' bsSize='xsmall'
                                    onClick={() => editSubscriptionState(index)}>
                                <Glyphicon glyph='pencil'/>
                            </Button>
                            }

                            {!isPrepayed(original?.type) &&
                            <EditSepaModal isSEPAEditable
                                           subscriptionState={original}
                                           useSmallButton
                                           contractNumber={contractNumber}/>
                            }

                            {!isN1Rights && original?.notSaved &&
                            <Button bsStyle='danger' bsSize='xsmall'
                                    onClick={() => deleteSubscriptionState(index)}>
                                <Glyphicon glyph='trash'/>
                            </Button>
                            }

                            {isDeletableState(index, original) &&
                            <Button bsStyle='warning' bsSize='xsmall'
                                    onClick={() => deleteLastSubscriptionState()}>
                                <Glyphicon glyph='trash'/>
                            </Button>
                            }
                        </ButtonGroup>
                    ),
                },
            ],
            getTrProps: getTrPropsFactory(currentSubscriptionStateIndex, currentSubscriptionStateErrorIndexes),
        };
    },
);

const withShowHistoryState = withState('showHistory', 'setShowHistory', true);

const History = compose(
    connectMeAndTeamsAndConstantsContext,
    connectContractNumberContext,
    withCalculatedProps,
    withShowHistoryState,
    pure,
)(HistoryPure);

History.propTypes = {
    currentSubscriptionStateErrorIndexes: PropTypes.array,
    currentSubscriptionStateIndex: PropTypes.number,
    subscription: PropTypes.object,
    deleteSubscriptionState: PropTypes.func,
    deleteLastSubscriptionState: PropTypes.func,
    editSubscriber: PropTypes.func,
    editSubscriptionState: PropTypes.func,
};

export default History;