import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Button, FormControl, FormGroup, Glyphicon, Navbar} from 'react-bootstrap';
import Select from 'react-select';
import {navigateToSearch} from '../../utils/functions';
import { connectTagsContext } from '../../hoc/withMeAndTeamsAndConstants';

const SEARCH_MODES = [
  {
    label: 'Par Contrat',
    value: 'contract',
  }, {
    label: 'Par Nom',
    value: 'name',
  }, {
    label: 'Par Téléphone',
    value: 'phone',
  }, {
    label: 'Par Matériel',
    value: 'serial',
  }, {
    label: 'Par Tag',
    value: 'tag',
  }, {
    label: 'Par Société',
    value: 'company',
  }, {
    label: 'Par Email',
    value: 'email',
  }, {
    label: 'Par Ticket',
    value: 'ticket',
  },
];

const SearchBar = connectTagsContext(({ tags, initialSearchValue, mode }) => {
                      
  const [searchMode, setSearchMode] = useState(mode || SEARCH_MODES[0].value);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  
  const handleSearch = () => {
    if (searchMode === 'name' && /[0-9]/g.test(searchValue)) {
      setSearchMode('contract');
      navigateToSearch('contract', searchValue);
    } else if (searchMode === 'contract' && /[a-z]/i.test(searchValue)) {
      setSearchMode('name');
      navigateToSearch('name', searchValue);
    } else {
      navigateToSearch(searchMode, searchValue);
    }
  }

  const handleKeyPress = target => {
    if (target.charCode === 13) {
      handleSearch();
    }
  }

  const handleModeChange = event => {
    setSearchMode(event.target.value);
    setSearchValue(searchMode === 'tag' ? '' : searchValue);
  }

  const handleTagSearchChange = tag => setSearchValue(tag?.value || '');

  const handleSearchChange = event => setSearchValue(event.target.value);

  return (
    <Navbar.Form className='search-bar'>
      <FormGroup>
        {searchMode === 'tag'
        ? <div className="tagSelectDiv">
            <Select
              name="tags"
              value={Object.keys(tags).includes(searchValue) ? searchValue : null}
              options={Object.keys(tags).map((value) => ({value: value, label: tags[value]}))}
              onChange={handleTagSearchChange}
              multi={false}
            />
          </div>
        : <FormControl
            name='search'
            value={searchValue || ''}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            type="text"
            placeholder="Recherche"
          />
        }
      </FormGroup>
      <FormGroup className='search-criteria'>
        <FormControl componentClass="select" value={searchMode} onChange={handleModeChange} placeholder="Mode">
          {SEARCH_MODES.map(mode => (
            <option key={mode.value} value={mode.value}>
              {mode.label}
            </option>
          ))}
        </FormControl>
        <Button type="submit" onClick={handleSearch}><Glyphicon glyph="search"/></Button>
      </FormGroup>
    </Navbar.Form>
  );
});

SearchBar.propTypes = {
  mode: PropTypes.oneOf(['company', 'contract', 'email', 'name', 'phone', 'serial', 'tag', 'ticket']),
  initialSearchValue: PropTypes.string,
};

export default SearchBar;