import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import AssignNewTicketModal from './AssignNewTicketModal';

const AssignNewTicketModalButton = ({ ticket, addTicketTags, handleTicketChange, handleSaveData, disabled }) => {

  const [show, setShow] = useState(false);
  
  const handleShow = () => setShow(true);

  const handleHide = () => setShow(false);

  return <>
    <Button
      bsStyle='success'
      disabled={disabled}
      onClick={handleShow}
    >
      En cours
    </Button>

    {show && (
      <AssignNewTicketModal
        ticket={ticket}
        addTicketTags={addTicketTags}
        handleChange={handleTicketChange}
        handleSave={handleSaveData}
        onHide={handleHide}
      />
    )}
  </>;
};

AssignNewTicketModalButton.propTypes = {
  disabled: PropTypes.bool,
  ticket: PropTypes.object,
  addTicketTags: PropTypes.func,
  handleTicketChange: PropTypes.func,
  handleSaveData: PropTypes.func,
};

export default AssignNewTicketModalButton;