import React from 'react';
import PropTypes from 'prop-types';
import {compose, pure, withProps} from 'recompose';

import {TICKET_SUBJECTS} from '../../../../../utils/constants';
import {RETURN_TYPES} from '../../../../../utils/AfterSale';
import Select from './Select';

const AfterSaleTypeSelectPure = ({ arrayValues, value, handleChange }) => (
    <Select arrayValues={arrayValues}
            mandatory
            value={value}
            handleChange={handleChange} />
);

AfterSaleTypeSelectPure.propTypes = {
    arrayValues: PropTypes.array,
    value: PropTypes.string,
    handleChange: PropTypes.func,
};

const withCalculatedProps = withProps(
    ({ ticketSubject }) => {
        let arrayValues;

        switch (ticketSubject) {
            case TICKET_SUBJECTS.SAV:
                arrayValues = [ RETURN_TYPES.AFTER_SALE_SERVICE ];
                break;
            case TICKET_SUBJECTS.SATISFAIT_REMBOURSE:
                arrayValues = [ RETURN_TYPES.SATISFIED_OR_REFUNDED,RETURN_TYPES.DISPATCHED_SATISFIED_OR_REFUNDED ];
                break;
            case TICKET_SUBJECTS.RETOUR_TABLETTE:
                arrayValues = [ RETURN_TYPES.WITHOUT_REFUND, RETURN_TYPES.LEASING ];
                break;
            default:
                arrayValues = [];
        }

        return {
            arrayValues,
        }
    }
);

const AfterSaleTypeSelect = compose(
    withCalculatedProps,
    pure,
)(AfterSaleTypeSelectPure);

AfterSaleTypeSelect.propTypes = {
    ticketSubject: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
};

export default AfterSaleTypeSelect;