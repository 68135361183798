import PropTypes from 'prop-types';
import React from 'react';
import {compose, pure, withProps} from 'recompose';

import {BIC_CODES} from '../../../../../../../utils/BIC';
import ReactSelect from '../../../../../../components/Form/ReactSelect/ReactSelect';

const BICSelectPure = ({bankLabel, ...props}) => (
    <>
        <ReactSelect {...props}/>
        <span>{bankLabel}</span>
    </>
);

BICSelectPure.props = {
    bankLabel: PropTypes.string,
};

const BICSelect = compose(
    withProps(
        ({value}) => {
            let bankLabel = '';
            if (value) {
                const bicCode = BIC_CODES.find(_ => _.bic === value);
                bankLabel = bicCode ? bicCode.label : 'BIC non reconnu';
            }

            return {
                bankLabel,
            };
        },
    ),
    pure,
)(BICSelectPure);

export default BICSelect;