import React from 'react';
import FontAwesome from './FontAwesome';

const Loading = () => (
    <div className='loading-overlay'>
        <div className='loading-spinner'>
            <FontAwesome pulse size='5x' name='spinner'/>
        </div>
    </div>
);

export default Loading;