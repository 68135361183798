import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ControlLabel} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps, withState} from 'recompose';
import {
  ADD_B2B_FEATURES_ON_CONTRACTS_MUTATION,
  GET_B2B_SUBSCRIPTIONS_FOR_CONTRACTS_MUTATION,
  GET_B2BFEATURES_QUERY,
} from '../../../../schema/B2BSchema';
import B2BFeatureForm from '../../../admin/B2B/AddFeaturesToContracts/B2BFeatureForm';
import B2BFeaturesTable from './B2BInformations/B2BFeaturesTable';
import {
  addLabelsToB2BFeatures,
  getB2BFeatureRecordsFromQueryData,
  hasB2BFeaturesChanged,
  isSameFeatureId,
  updateB2BFeatures,
} from './B2BInformations/B2BInformationsHelpers';

const B2BInformationsPure = ({
                               availableFeatures,
                               b2bFeatureRecords,
                               canSubmit,
                               addB2BFeature,
                               handleSave,
                             }) => (
  <>
    <ControlLabel bsSize="sm">Ajouter ou modifier une option</ControlLabel>

    <B2BFeatureForm availableFeatures={availableFeatures}
                    addB2BFeature={addB2BFeature}/>

    <B2BFeaturesTable b2bFeatureRecords={b2bFeatureRecords}/>

    <Button bsStyle="primary"
            className={`pull-right ${!canSubmit && 'hidden'}`}
            style={{marginTop: 10}}
            type="submit"
            onClick={handleSave}>
      Valider
    </Button>
  </>

);

const withData = graphql(
  GET_B2B_SUBSCRIPTIONS_FOR_CONTRACTS_MUTATION, {
    name: 'contractB2BFeaturesData',
    options: ({contractNumber}) => ({
      returnPartialData: true,
      variables: {
        input: {
          contractNumbers: [contractNumber],
        },
      },
    }),
  },
);

const withAllB2BFeaturesData = graphql(GET_B2BFEATURES_QUERY, {name: 'allB2BFeaturesData'});

const withAddB2BFeaturesOnContractsMutation = graphql(ADD_B2B_FEATURES_ON_CONTRACTS_MUTATION, {
  props: ({mutate}) => ({
    addB2BFeaturesOnContracts: (featureRecords, onContractNumbers, callback) =>
      mutate({
        variables: {
          input: {
            featureRecords,
            onContractNumbers,
          },
        },
        update(store, {data}) {
          callback(data);
        },
      }),
  }),
});

const withAddedOrModifiedB2BFeaturesState = withState('addedOrModifiedB2BFeatures', 'setAddedOrModifiedB2BFeatures', []);

const handlers = withHandlers({
  addB2BFeature: ({
                    addedOrModifiedB2BFeatures,
                    setAddedOrModifiedB2BFeatures,
                  }) => (b2bFeature) => {
    const {featureId, ...otherB2bFeatureProps} = b2bFeature;
    const b2bFeatureRecord = {
      ...otherB2bFeatureProps,
      feature: {
        id: featureId,
      },
    };
    let needToBeAdded = true;
    const newAddedOrModifiedB2BFeatures = addedOrModifiedB2BFeatures.map(
      (feature) => {
        if (isSameFeatureId(feature)(b2bFeatureRecord)) {
          needToBeAdded = false;
          return b2bFeatureRecord;
        } else {
          return feature;
        }
      },
    );

    if (needToBeAdded) {
      newAddedOrModifiedB2BFeatures.push(b2bFeatureRecord);
    }

    setAddedOrModifiedB2BFeatures(newAddedOrModifiedB2BFeatures);
  },
  handleSave: ({
                 b2bFeatureRecords,
                 contractB2BFeaturesData,
                 contractNumber,
                 addB2BFeaturesOnContracts,
               }) => () => {
    const b2bFeaturesWithoutFeatureAndTypename = b2bFeatureRecords.map(
      ({__typename, feature, ...otherB2bFeatureRecordProps}) => ({
        ...otherB2bFeatureRecordProps,
        featureId: feature.id,
      }),
    );
    addB2BFeaturesOnContracts(b2bFeaturesWithoutFeatureAndTypename, [contractNumber], () => contractB2BFeaturesData.refetch());
  },
});

const B2BInformations = compose(
  withData,
  withAllB2BFeaturesData,
  withAddB2BFeaturesOnContractsMutation,
  withAddedOrModifiedB2BFeaturesState,
  withProps(
    ({
       addedOrModifiedB2BFeatures,
       allB2BFeaturesData,
       contractB2BFeaturesData,
     }) => {
      const allFeatures = allB2BFeaturesData?.b2bFeatures?.b2bFeatures || [];
      const addedOrModifiedB2BFeaturesWithLabels = addLabelsToB2BFeatures(addedOrModifiedB2BFeatures, allFeatures);
      const initialB2BFeatures = getB2BFeatureRecordsFromQueryData(contractB2BFeaturesData);
      const b2bFeatureRecords = updateB2BFeatures(initialB2BFeatures, addedOrModifiedB2BFeaturesWithLabels);
      const canSubmit = hasB2BFeaturesChanged(b2bFeatureRecords, initialB2BFeatures);

      return {
        availableFeatures: allFeatures,
        b2bFeatureRecords,
        canSubmit,
      };
    },
  ),
  handlers,
  pure,
)(B2BInformationsPure);

B2BInformations.propTypes = {
  contractNumber: PropTypes.string,
};

export default B2BInformations;