import React from 'react';
import PropTypes from 'prop-types';
import {Navbar} from 'react-bootstrap';
import { DEV, STAGING } from '../../utils/constants';
import CanaryLogo from '../components/Logo/CanaryLogo';
import './index.css';
import DevModeTeamSelect from './DevModeTeamSelect';
import NavTabs from './NavTabs';
import SearchBar from './SearchBar';

const AppBar = ({ mode, searchValue: initialSearchValue }) => (
  <Navbar className="header-navbar" inverse staticTop fluid>
    <Navbar.Header>
      <Navbar.Brand>
        <CanaryLogo />
      </Navbar.Brand>

      <Navbar.Toggle />
    </Navbar.Header>

    <Navbar.Collapse>
      <SearchBar
        initialSearchValue={initialSearchValue}
        mode={mode}
      />

      <NavTabs />
      
      {(DEV || STAGING) && (
        <DevModeTeamSelect />
      )}
    </Navbar.Collapse>
  </Navbar>
);

AppBar.propTypes = {
  mode: PropTypes.oneOf(['company', 'contract', 'email', 'name', 'phone', 'serial', 'tag', 'ticket']),
  searchValue: PropTypes.string,
};

export default AppBar;