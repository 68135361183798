import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Glyphicon, Panel} from 'react-bootstrap';
import ReactTable from 'react-table';
import {compose, pure, withProps, withState} from 'recompose';
import {connectContractNumberContext} from '../../../../../hoc/contractNumber';
import {GET_CONTRACT_SIM_STATES} from '../../../../../schema/ContractSchema';

const columns = [
    {
        Header: 'ICCID',
        accessor: 'id',
        width: 200,
        Cell: ({value}) => <div>{value?.replace('sim::', '') || null}</div>,
    }, {
        Header: 'Attachée le',
        accessor: 'attached',
        width: '20%',
        Cell: ({value}) => <div>{value ? moment(value).format('L') : null}</div>,
    }, {
        Header: 'Détachée le',
        accessor: 'detached',
        width: '20%',
        Cell: ({value}) => <div>{value ? moment(value).format('L') : null}</div>,
    },
    {
        Header: 'SN',
        accessor: 'deviceId',
        width: '25%',
        Cell: ({value}) => <div>{value?.replace('dmd::', '') || null}</div>,
    },
];

const SimStateTablePure = ({showHistory, simStates, tableHeight, setShowHistory}) => (
    <>
        <h4 onClick={() => setShowHistory(!showHistory)} className='cursor-pointer'>
            <span>Historique des cartes SIM</span>
            <Glyphicon className='pull-right' glyph={showHistory ? 'triangle-top' : 'triangle-bottom'}/>
        </h4>
        <Panel expanded={showHistory}
               onToggle={() => {
               }}>
            <Panel.Collapse>
                <Panel.Body>

                    <ReactTable
                        fill
                        data={simStates}
                        columns={columns}
                        resizable={false}
                        pageSize={simStates.length || 1}
                        showPagination={false}
                        style={{
                            border: 'none',
                            height: tableHeight,
                        }}
                        className='-highlight'
                        getNoDataProps={() => ({style: {'visibility': 'hidden'}})}
                    />
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    </>
);

SimStateTablePure.propTypes = {
    showHistory: PropTypes.bool,
    simStates: PropTypes.array,
    tableHeight: PropTypes.string,
    setShowHistory: PropTypes.func,
};

const withShowHistoryState = withState('showHistory', 'setShowHistory', false);

const withData = graphql(GET_CONTRACT_SIM_STATES, {
    options: ({contractNumber}) => ({
        variables: {
            contractNumber: contractNumber,
        },
    }),
});

const withCalculatedProps = withProps(
    ({data}) => ({
        simStates: data?.contract?.contract?.amd?.simStates || [],
        tableHeight: `${((data?.contract?.contract?.amd?.simStates?.length || 1) * 50 + 100)}px`,
    }),
);

const SimHistory = compose(
    connectContractNumberContext,
    withShowHistoryState,
    withData,
    withCalculatedProps,
    pure,
)(SimStateTablePure);

SimHistory.propTypes = {};

export default SimHistory;