import React, { Component } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';

class UploadProgress extends Component {

    render() {
        let showModal = this.props.progress !== 0;
        return (
            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>Sauvegarde...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar active now={this.props.progress} />
                </Modal.Body>
            </Modal>
        )
    }
}

export default UploadProgress;