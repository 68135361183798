import jsRoutes from '../../routes/routes';

const GET_WEBUSER = (_id, successFn, errorFn) => {
    jsRoutes.controllers.backoffice.UserV2Controller.getUser(_id)
        .ajax({dataType: 'json', processData: false})
        .then(
            successFn,
            errorFn,
        );
};

export default GET_WEBUSER;