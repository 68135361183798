import React from 'react';
import Form from 'react-jsonschema-form';
import {compose, pure, withProps} from 'recompose';

const JsonSchemaFormPure = ({setFormEl, ...props}) => (
    <Form {...props}
          ref={setFormEl}/>
);

JsonSchemaFormPure.propTypes = Form.propTypes;

const defaultTransformErrorsFunction = errors => errors
    .filter(error => error.name !== 'enum' && error.name !== 'oneOf')
    .map(error => {
        // To display errors on subproperties
        const property = error.property.split('.');
        if (property.length > 2) {
            error = {
                ...error,
                property: `.${property[1]}`,
                params: {...error.params, missingProperty: property[1]},
            };
        }

        // use error messages from JSON schema if any
        if (error.name === 'required') {
            return {
                ...error,
                message: 'Champs requis',
            };
        }
        if (error.name === 'pattern') {
            return {
                ...error,
                message: 'Ne respecte pas le format attendu',
            };
        }
        if (error.name === 'minimum' || error.name === 'maximum') {
            const isMini = error.name === 'minimum';
            const params = {
                name: isMini ? 'supérieur' : 'inférieur',
                equals: error.params.exclusive ? '' : 'ou égal ',
            };

            return {
                ...error,
                message: `Doit être ${params.name} ${params.equals} à ${error.params.limit}`,
            };
        }

        if (error.name === 'type') {
            return {
                ...error,
                message: 'Mauvais format',
            };
        }

        return error;
    });

const JsonSchemaForm = compose(
    withProps(
        ({transformErrors}) => ({
            transformErrors: transformErrors || defaultTransformErrorsFunction,
        }),
    ),
    pure,
)(JsonSchemaFormPure);

JsonSchemaForm.propTypes = Form.propTypes;

export {
    JsonSchemaForm as default,
    defaultTransformErrorsFunction,
};