import React from 'react';
import {Col, ControlLabel, FormGroup, Row} from 'react-bootstrap';
import {compose, pure} from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';

import {BREAKAGE_INSURANCE_STATUS_TYPES} from '../../../../../utils/constants';
import {isN2User, isValidationUser} from '../../../../../utils/functions';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import DateInput from './DateInput';

const DeadlinesInfosPure = ({ subscription, webUserJson, handleBreakageInsuranceChange, handleDateChange }) => (
    <>
        <h4>Échéances</h4>
        <Row>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Date de début de souscription</ControlLabel>
                    <DateInput modificationEnabled={isN2User(webUserJson)}
                               value={subscription.subscriptionStart}
                               handleChange={event => handleDateChange('subscriptionStart', event)} />
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Date de fin de satisfait remboursé</ControlLabel>
                    <DateInput modificationEnabled={isN2User(webUserJson) || isValidationUser(webUserJson)}
                               value={subscription.satisfyEnd}
                               handleChange={event => handleDateChange('satisfyEnd', event)} />
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Date de premier prélèvement</ControlLabel>
                    <DateInput modificationEnabled={isN2User(webUserJson) || isValidationUser(webUserJson)}
                               value={subscription.firstDebit}
                               handleChange={event => handleDateChange('firstDebit', event)} />
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Date de fin d'engagement</ControlLabel>
                    <DateInput modificationEnabled={isN2User(webUserJson)}
                               value={subscription.engagementEnd}
                               handleChange={event => handleDateChange('engagementEnd', event)} />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Date de fin de l'option d'assistance</ControlLabel>
                    <DateInput modificationEnabled={isN2User(webUserJson)}
                               value={subscription.engagementEnd}
                               handleChange={event => handleDateChange('engagementEnd', event)} />
                </FormGroup>
            </Col>
            {
                subscription?.breakageInsurance?.status === BREAKAGE_INSURANCE_STATUS_TYPES.TERMINATED.value &&
                <Col md={3}>
                    <FormGroup>
                        <ControlLabel>Date de fin de l'option casse</ControlLabel>
                        <DateInput modificationEnabled={false}
                                   value={subscription?.breakageInsurance?.subscriptionEnd}
                                   handleChange={event => handleBreakageInsuranceChange('subscriptionEnd', event.target.value ? moment(event.target.value).valueOf() : event.target.value)} />
                    </FormGroup>
                </Col>
            }
        </Row>
    </>
);

DeadlinesInfosPure.propTypes = {
    subscription: PropTypes.object,
    webUserJson: PropTypes.object,
    handleBreakageInsuranceChange: PropTypes.func,
    handleDateChange: PropTypes.func,
};

const DeadlinesInfos = compose(
    connectMeAndTeamsAndConstantsContext,
    pure,
)(DeadlinesInfosPure);

DeadlinesInfos.propTypes = {
    subscription: PropTypes.object,
    handleBreakageInsuranceChange: PropTypes.func,
    handleDateChange: PropTypes.func,
};

export default DeadlinesInfos;