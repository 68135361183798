import React, { useState } from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {connectContactInfosContext} from '../../../../../hoc/contactInfos';
import {connectContractNumberContext} from '../../../../../hoc/contractNumber';
import {connectDmdContext} from '../../../../../hoc/dmd';
import {connectWebUserContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {TICKET_SUBJECTS} from '../../../../../utils/constants';
import {isN2OrHigher} from '../../../../../utils/functions';
import {objectEmptyStringsToNull} from '../../../../../utils/Strings';
import {
	ADDRESS_TYPES,
	AFTERSALE_PROVIDERS,
	AFTERSALE_REASONS,
	AFTERSALE_SERVICE_REASONS,
	getModelFromSn,
	PRODUCT_STATES,
	RETURN_TYPES,
	SATISFIED_OR_REFUNDED_REASONS,
} from '../../../../../utils/AfterSale';
import AfterSaleInfos from './AfterSaleInfos';
import ContactInfos from './ContactInfos';
import {withAfterSaleMutation} from './withAfterSaleMutation';
import ProcessingInfos from './ProcessingInfos';

import './TabletReturnForm.css'

const initialCurrentAddress = {
	addressLine0: '',
	addressLine1: '',
	city: '',
	country: '',
	firstName: '',
	gender: '',
	lastName: '',
	zipcode: '',
};

const fillAddress = address => {
	Object.keys(address).forEach(key => {
		if (address[key] === undefined || address[key] === null) {
			address[key] = '';
		}
	});
	return address;
};

const getInitialCurrentAddress = ({contactInfos, editedAfterSale, isEditMode, returnType}) => {
	if (isEditMode) {
		const customerReturnAddress = editedAfterSale?.afterSaleRequest?.customerReturnAddress;

		if (customerReturnAddress) {
			return {
				...customerReturnAddress,
				addressLine0: customerReturnAddress.addressLine.length > 0 ? customerReturnAddress.addressLine[0] : '',
				addressLine1: customerReturnAddress.addressLine.length > 1 ? customerReturnAddress.addressLine[1] : '',
				zipcode: customerReturnAddress.zipCode,
			}
		}
	} else if (
		returnType !== RETURN_TYPES.SATISFIED_OR_REFUNDED &&
		returnType !== RETURN_TYPES.DISPATCHED_SATISFIED_OR_REFUNDED
	) {
		return fillAddress(contactInfos.user);
	}

	return initialCurrentAddress;
}

const getInitialAfterSaleInfos = ({contractNumber, dmd, editedAfterSale, isEditMode, returnType}) => {
	if (isEditMode) {
		return {
			...editedAfterSale,
			...editedAfterSale.afterSaleRequest,
			reason: editedAfterSale?.afterSaleRequest?.afterSaleServiceRequestReason ||
					editedAfterSale?.afterSaleRequest?.satisfiedOrRefundedRequestReason,
		};
	}

	let addressType,
			reason = '';

	switch (returnType) {

		case RETURN_TYPES.AFTER_SALE_SERVICE.value:

			addressType = ADDRESS_TYPES.USER.value;
			reason = AFTERSALE_SERVICE_REASONS.SCREEN.value;
			break;

		case RETURN_TYPES.DISPATCHED_SATISFIED_OR_REFUNDED.value:
		case RETURN_TYPES.SATISFIED_OR_REFUNDED.value:

			addressType = ADDRESS_TYPES.WITHOUT_SEND_BACK.value;
			reason = SATISFIED_OR_REFUNDED_REASONS.A_DEFINIR.value;
			break;

		case RETURN_TYPES.LEASING.value:
		case RETURN_TYPES.WITHOUT_REFUND.value:

			addressType = ADDRESS_TYPES.USER.value;
			break;
			
		default:
			break;
	}

	return {
		addressType: addressType,
		comment: '',
		contractNumber,
		email: '',
		phone: '',
		productState: PRODUCT_STATES[0].value,
		productType: dmd.SN ? getModelFromSn(dmd.SN) : '',
		provider: AFTERSALE_PROVIDERS[0].value,
		reason,
		returnType,
		serialNumber: dmd.SN || '',
		trackingNumber: '',
	};
}

const TabletReturnForm = connectContactInfosContext(connectContractNumberContext(connectDmdContext(connectWebUserContext(withAfterSaleMutation(({
	contactInfos,
	contractNumber,
	dmd,
	webUserJson,
	mutateAfterSale,
	isEditMode,
	handleValidateAfterSale,
	onHide,
	editedAfterSale,
	returnType,
}) => {
	
	const [currentAddress, setCurrentAddress] = useState(getInitialCurrentAddress({contactInfos, editedAfterSale, isEditMode, returnType}));
	const [afterSale, setAfterSaleInfos] = useState(getInitialAfterSaleInfos({contractNumber, dmd, editedAfterSale, isEditMode, returnType}));

	const snSet = new Set(dmd.states.map(({deviceId}) => deviceId.replace('dmd::', '')));

	const snList = Array.from(snSet).map(sn => ({label: sn, value: sn}));

	let afterSaleReasons = Object.values(AFTERSALE_REASONS);

	if (!isN2OrHigher(webUserJson)) {
		afterSaleReasons = afterSaleReasons.filter(reason => reason.value !== AFTERSALE_REASONS.TAB_REPLACEMENT.value);
	}

	let ticketSubject = '';

	switch (returnType) {

		case RETURN_TYPES.AFTER_SALE_SERVICE.value:
		
			ticketSubject = TICKET_SUBJECTS.SAV;
			break;

		case RETURN_TYPES.SATISFIED_OR_REFUNDED.value:
		case RETURN_TYPES.DISPATCHED_SATISFIED_OR_REFUNDED.value:

			ticketSubject = TICKET_SUBJECTS.SATISFAIT_REMBOURSE;
			break;

		case RETURN_TYPES.LEASING.value:
		case RETURN_TYPES.WITHOUT_REFUND.value:

			ticketSubject = TICKET_SUBJECTS.RETOUR_TABLETTE;
			break;

		default:
			break;
	}

	const handleAddressTypeChange = event => {
		const value = event.target.value;
		let newAddress;

		switch (value) {
			
			case ADDRESS_TYPES.BUYER.value:
					newAddress = contactInfos.buyer;
					break;

			case ADDRESS_TYPES.SUBSCRIBER.value:
					newAddress = contactInfos.subscriber;
					break;

			case ADDRESS_TYPES.USER.value:
					newAddress = contactInfos.user;
					break;

			case ADDRESS_TYPES.WITHOUT_SEND_BACK.value:
			case ADDRESS_TYPES.OTHER.value:
			default:
					newAddress = initialCurrentAddress;
					break;
		}

		newAddress = fillAddress(newAddress);

		setAfterSaleInfos(afterSale => ({
			...afterSale,
			addressType: value,
		}));
		setCurrentAddress(newAddress);
	}

	const handleAfterSaleInfosChange = (property, value) => setAfterSaleInfos(afterSale => ({ ...afterSale, [property]: value }));

	const handleAfterSaleRequestChange = (property, value) => setAfterSaleInfos(afterSale => ({
		...afterSale,
		afterSaleRequest: {
			...afterSale.afterSaleRequest,
			[property]: value,
		}
	}));

	const handleAfterSaleSNChange = value => setAfterSaleInfos(afterSale => ({
		...afterSale,
		serialNumber: value,
		productType: getModelFromSn(value),
	}));

	const handleCurrentAddressChange = (property, value) => setCurrentAddress(currentAddress => ({ ...currentAddress, [property]: value}));

	const handleSaveAfterSale = () => {
		const {
			addressLine0,
			addressLine1,
			city,
			country,
			firstName,
			gender,
			lastName,
			zipcode,
		} = currentAddress;

		const addressLine = [];
		if (addressLine0) addressLine.push(addressLine0);
		if (addressLine1) addressLine.push(addressLine1);

		const newAfterSale = objectEmptyStringsToNull({
			...afterSale,
			customerReturnAddress: {
				addressLine,
				city,
				country: country || 'France',
				firstName,
				gender,
				lastName,
				zipCode: zipcode,
			}
		});

		mutateAfterSale(newAfterSale);
	}

	return (
    <Form className='return-form'>
			<Row>
				<Col md={isEditMode ? 4 : 6}>
					<AfterSaleInfos
						afterSale={afterSale}
						afterSaleReasons={afterSaleReasons}
						snList={snList}
						ticketSubject={ticketSubject}
						handleAfterSaleInfosChange={handleAfterSaleInfosChange}
						handleAfterSaleSNChange={handleAfterSaleSNChange}
					/>
				</Col>

				<Col md={isEditMode ? 4 : 6}>
					<ContactInfos
						currentAddress={currentAddress}
						afterSale={afterSale}
						isEditMode={isEditMode}
						handleAddressTypeChange={handleAddressTypeChange}
						handleAfterSaleInfosChange={handleAfterSaleInfosChange}
						handleCurrentAddressChange={handleCurrentAddressChange}
					/>
				</Col>
				{isEditMode && (
					<Col md={4}>
						<ProcessingInfos
							afterSale={afterSale}
							handleAfterSaleInfosChange={handleAfterSaleInfosChange}
							handleAfterSaleRequestChange={handleAfterSaleRequestChange}
						/>
					</Col>
				)}
			</Row>

			<Button bsStyle='danger' className='pull-left' onClick={onHide}>Annuler</Button>
			<Button
				bsStyle='success'
				className='pull-right'
				onClick={isEditMode ? handleSaveAfterSale : () => handleValidateAfterSale(handleSaveAfterSale)}
			>
				Valider
			</Button>
    </Form>
	);
})))));

TabletReturnForm.propTypes = {
	editedAfterSale: PropTypes.object,
	isEditMode: PropTypes.bool,
	handleValidateAfterSale: PropTypes.func,
	onHide: PropTypes.func,
};

export default TabletReturnForm;