import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers} from 'recompose';
import {connectContractNumberContext} from '../../../../../hoc/contractNumber';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {toFrenchDateString} from '../../../../../utils/Date';
import {errorMsg, successMsg} from '../../../../../utils/Notify';

const CopyUserDataPure = ({handleCopyData}) => (
    <Button bsSize='small'
            bsStyle='primary'
            className='pull-right'
            onClick={handleCopyData}>
        Copier dans le presse-papier
    </Button>
);

CopyUserDataPure.propTypes = {
    handleCopyData: PropTypes.func,
};

const handlers = withHandlers({
    handleCopyData: ({contract, contractNumber, dmd, user}) => () => {
        const userData = [
            user.gender,
            user.lastName,
            user.firstName,
            user.addressLine0,
            [
                user.addressLine1,
                user.addressLine2,
                user.addressLine3,
            ]
                .filter(address => !!address)
                .join(', '),
            user.zipcode,
            user.city,
            user.email,
            user.phone,
            user.cellphone,
            contractNumber,
            dmd?.SN,
            contract?.is3G ? 'SIM' : 'WIFI',
            toFrenchDateString(contract?.subscription?.subscriptionStart),
            toFrenchDateString(contract?.subscription?.warrantyEnd),
        ].join('\t');

        navigator.clipboard.writeText(userData).then(
            () => successMsg('Données copiées dans le presse-papier'),
            () => errorMsg('Erreur lors de la copie des données'),
        );
    },
});

const CopyUserData = compose(
    connectMeAndTeamsAndConstantsContext,
    connectContractNumberContext,
    handlers,
    pure,
)(CopyUserDataPure);

CopyUserData.propTypes = {
    contract: PropTypes.object,
    dmd: PropTypes.object,
    user: PropTypes.object,
};

export default CopyUserData;