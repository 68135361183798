import React, { Component } from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, InputGroup, Modal, ProgressBar, Row } from 'react-bootstrap';

import { connectMeAndTeamsAndConstantsContext } from '../../../../../hoc/withMeAndTeamsAndConstants';
import { isN3User } from '../../../../../utils/functions';
import jsRoutes from '../../../../../routes/routes';
import LoadingProgress from '../../../../components/LoadingProgress';

class MailConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = this.getComponentInitialState();
    }

    getComponentInitialState() {
        return {
            loaded: false,
            loading: false,
            password:'coucou',
            showPassword:false,
            nuxitData: [],
            showDeleteModal: false,
        };
    }

    componentDidMount() {
        this.getComponentInitialState();
    }

    componentDidUpdate(previousProps) {
        if (previousProps !== this.props) {
            this.setState(this.getComponentInitialState());
        }
    }

    handleShowPasswordChange() {
       this.setState({showPassword: !this.state.showPassword});
    }

    getMailBoxInfo(localPart) {
        let route = jsRoutes.controllers.backoffice.SupportV2Controller.getMailBoxInfo(localPart);
        let _this = this;
        route.ajax({
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            processData: false
        }).then(function (response, textStatus, jqXHR) {
            _this.setState({nuxitData: response, loading: false});
        }, function (jqXHR, textStatus, errorThrown) {
            _this.setState({loading: false});
        });
    }

    getMainUserEmail(){
        let route = jsRoutes.controllers.backoffice.SupportV2Controller.getMainUser(this.props.contractNumber);

        let _this = this;
        route.ajax({
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            processData: false
        }).then(function (response, textStatus, jqXHR) {
            _this.setState({password: response.emailPassword, loaded: true});
            _this.getMailBoxInfo(response.email.split('@')[0]);
        }, function (jqXHR, textStatus, errorThrown) {
            _this.setState({loading: false});
        });
    }

    showDeleteModal() {
        this.setState({showDeleteModal: true});
    }

    hideDeleteModal() {
        this.setState({showDeleteModal: false});
    }

    handleDeleteEmail(){
        let localPart = this.state.nuxitData.local_part;
        this.deleteMailBoxInfo(localPart);
    }

    deleteMailBoxInfo(localPart) {
        let route = jsRoutes.controllers.backoffice.SupportV2Controller.deleteMailBoxInfo(localPart);
        let _this = this;
        route.ajax({
            //pas de content pour un delete
            //dataType: 'text',
            // contentType: 'application/json; charset=utf-8',
            processData: false
        }).then(function (response, textStatus, jqXHR) {
            _this.setState({nuxitData: [], loading: false, loaded: false});
            _this.hideDeleteModal();
            alert(response);
        }, function (jqXHR, textStatus, errorThrown) {
            _this.setState({loading: false});
        });
    }

    render() {
        var displayInfo = '';

        const deleteButton = isN3User(this.props.webUserJson) ? <Button bsStyle='danger' onClick={this.showDeleteModal.bind(this)}>Supprimer la boite email</Button> : '';

        if (!this.state.loaded) {
            displayInfo =
                <Button onClick={this.getMainUserEmail.bind(this)}>Charger les données de la boite
                    Email</Button>
        }
        else {
            if (this.state.nuxitData.username ) {
                let quota = Math.floor((this.state.nuxitData.bytes / this.state.nuxitData.quota) * 100);
                let displayClass = 'success';
                if (quota > 33) displayClass = 'warning';
                if (quota > 66) displayClass = 'danger';

                let hideShowPassword = (
                    <Button onClick={this.handleShowPasswordChange.bind(this)}>
                        <Glyphicon glyph={this.state.showPassword ? 'eye-close' : 'eye-open'} /> {this.state.showPassword ? 'Masquer' : 'Afficher'}
                    </Button>
                );

                displayInfo = (
                    <div>
                        <LoadingProgress show={this.state.loading}/>
                        <FormGroup>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>Boite email</ControlLabel>
                                        <FormControl type='text' value={this.state.nuxitData.username} readOnly/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>Mot de passe</ControlLabel>
                                        <InputGroup>
                                            <FormControl type={this.state.showPassword ? 'text':'password'} value={this.state.password} readOnly/>

                                            <InputGroup.Button>
                                                {hideShowPassword}
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <ControlLabel>Date de creation</ControlLabel>
                                <FormControl type='text' value={this.state.nuxitData.created} readOnly/>
                            </FormGroup>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <ControlLabel>Taille</ControlLabel>
                                        <FormControl type='text' value={this.state.nuxitData.bytes} readOnly/>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <ControlLabel>Quota</ControlLabel>
                                        <FormControl type='text' value={this.state.nuxitData.quota} readOnly/>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <ControlLabel>Messages en attente</ControlLabel>
                                        <FormControl type='text' value={this.state.nuxitData.messages} readOnly/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <ProgressBar now={quota} label={`${quota}%`} bsStyle={displayClass}/>
                            {deleteButton}
                        </FormGroup>

                        <Modal show={this.state.showDeleteModal} onHide={this.hideDeleteModal.bind(this)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Suppression de l'adresse email</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                L'adresse email va être supprimée, les messages non relevés seront perdus.
                                Avez-vous essayé de faire une reprise d'email depuis le menu réglage ?
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle='success' onClick={this.hideDeleteModal.bind(this)}>Annuler</Button>
                                <Button bsStyle='danger' className='pull-left'
                                        onClick={this.handleDeleteEmail.bind(this)}>Supprimer cette boite email</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )
            }
            else {
                displayInfo = <div>{this.state.nuxitData}</div>
            }
        }

        return <div><hr/>{displayInfo}</div>;
    }
}

export default connectMeAndTeamsAndConstantsContext(MailConfiguration);