import React from 'react';
import Aviator from 'aviator';
import {Glyphicon, Nav, NavDropdown, NavItem} from 'react-bootstrap';
import { connectWebUserContext } from '../../hoc/withMeAndTeamsAndConstants';
import LogoutButton from './LogoutButton';
import AdminLinks from './AdminLinks';
import AdminSection from './AdminSection';
import MaintenanceSection from './MaintenanceSection';
import { navigateTo } from '../../utils/functions';

const getCurrentPageTitle = currentPage => {
  const currentURI = Aviator.getCurrentURI();

  switch (currentPage) {
    case 'contract/00000000':
      return 'La Poste';

    case 'contract/00000001':
      return 'Libre';

    case 'ticket':
      return 'Tickets';

    case 'device':
      return 'Devices';

    case 'sim':
      return 'Sims';

    case 'admin':
      return 'admin';

    case 'admin/generator':
      return 'Générateur';

    default:
      return currentURI;
  }
};

const NavTabs = connectWebUserContext(({ webUserJson }) => {

  let currentURL = Aviator.getCurrentURI();
  let currentPage = (currentURL.split('/'))[1];

  if ((currentURL.split('/'))[2] === '00000000') {
    currentPage = currentPage + '/00000000';
  } else if ((currentURL.split('/'))[2] === '00000001') {
    currentPage = currentPage + '/00000001';
  }

  const currentTitle = getCurrentPageTitle(currentPage);
  document.title = `Canari | ${currentTitle.replace('/', ' ')}`;
  
  return (
    <Nav>
      <NavItem active={currentPage === 'contract/00000000'} onClick={() => navigateTo('/contract/00000000')}>La Poste</NavItem>

      <NavItem active={currentPage === 'contract/00000001'} onClick={() => navigateTo('/contract/00000001')}>Libre</NavItem>

      <NavItem active={currentPage === 'ticket'} onClick={() => navigateTo('/ticket')}>Tickets</NavItem>

      <AdminLinks currentPage={currentPage} />

      <AdminSection active={currentPage === 'admin'} />

      <MaintenanceSection active={currentPage === 'maintenance'} />

      <NavDropdown title={<Glyphicon glyph="user"/>} id="user-dropdown">
        <NavItem disabled>{webUserJson.email}</NavItem>
        <LogoutButton />
      </NavDropdown>
    </Nav>
  );
});

export default NavTabs;