import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderComponent } from 'recompose';
import { Label } from 'react-bootstrap';

const ProAbonoLabelPure = () => <Label>PROABONO</Label>;
const GesabLabelPure = () => <Label>GESAB</Label>;

const SubscriptionSoftwareLabel = compose(
    pure,
    branch(
        ({ isProAbono }) => isProAbono,
        renderComponent(ProAbonoLabelPure),
        renderComponent(GesabLabelPure),
    ),
)();

SubscriptionSoftwareLabel.propTypes = {
    isProAbono: PropTypes.bool,
};

export default SubscriptionSoftwareLabel;