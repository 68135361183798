import React, { useRef, useState, useMemo } from 'react';
import {FormControl, FormGroup, Navbar, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { connectTypesAndWebUserContext } from '../../hoc/withMeAndTeamsAndConstants';

const DevModeTeamSelect = connectTypesAndWebUserContext(({ types, webUserJson }) => {
  const originalTeam = useRef(parseInt(webUserJson.team));
  const [team, setTeam] = useState(webUserJson.team);

  const teamOptions = useMemo(() => {
    return Object.entries(types.type_team)
            .filter(([index, team]) => index <= originalTeam.current)
            .map(([index, team]) => ({ label: team, value: `${index}` }));
  }, [types.type_team]);

  const handleDevModeTeamChange = event => {
    window.webUserJson = {...webUserJson, team: event.target.value};
    setTeam(event.target.value);
  }

  return (
    <Navbar.Form>
      <OverlayTrigger
        placement='bottom'
        overlay={(
          <Tooltip id='dev-mode-team-select-tooltip'>
            <p>Sélectionner temporairement une autre équipe pour tester l'interface avec les droits d'accès de cette équipe</p>
            <span>Cette fonctionnalité n'est disponible qu'en recette ou en mode dev</span>
          </Tooltip>
        )}
      >
        <FormGroup>
          <FormControl componentClass='select' value={team} onChange={handleDevModeTeamChange} style={{ cursor: 'pointer' }}>
            {teamOptions.map(team => (
              <option key={team.value} value={team.value}>
                {team.label}
              </option>
            ))}
          </FormControl>
        </FormGroup>
      </OverlayTrigger>
    </Navbar.Form>
  );
});

export default DevModeTeamSelect;