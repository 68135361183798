import React from 'react';
import MigrateClassicSubscriptionStateToEquilibre from './GroupedActions/MigrateClassicSubscriptionStateToEquilibre';
import ServiceTerminationUpdate from './GroupedActions/ServiceTerminationUpdate';
import SetBuyer from './GroupedActions/SetBuyer';
import SetSubscriptionState from './GroupedActions/SetSubscriptionState';
import SimStateUpdate from './GroupedActions/SimStateUpdate';
import TagsUpdate from './GroupedActions/TagsUpdate';
import TicketGenerator from './GroupedActions/TicketGenerator';
import NavigableView from './NavigableView';

const GROUPED_ACTIONS = [{
  component: () => <TicketGenerator/>,
  label: 'Création de tickets',
}, {
  component: SetBuyer,
  label: 'Ajout d\'acheteur',
}, {
  component: SetSubscriptionState,
  label: 'Modification d\'abonnement',
}, {
  component: MigrateClassicSubscriptionStateToEquilibre,
  label: 'Migration d\'abonnement Classique vers Équilibre',
}, {
  component: TagsUpdate,
  label: 'Modification des tags',
}, {
  component: ServiceTerminationUpdate,
  label: 'Fin de service',
}, {
  component: SimStateUpdate,
  label: 'Activation/Désactivation SIM',
}];

const GroupedActions = () => (
  <NavigableView conf={GROUPED_ACTIONS}/>
);

GroupedActions.propTypes = {};

export default GroupedActions;