import React from 'react';
import PropTypes from 'prop-types';
import {branch, compose, pure, renderComponent} from 'recompose';
import { Label } from 'react-bootstrap';

const InitializedLabelPure = () => <Label bsStyle='success'>Initialisé</Label>;
const NotInitializedLabelPure = () => <Label bsStyle='default'>Non initialisé</Label>;

const InitializedLabel = compose(
    pure,
    branch(
        ({ initialized }) => initialized,
        renderComponent(InitializedLabelPure),
        renderComponent(NotInitializedLabelPure),
    ),
)(InitializedLabelPure);

InitializedLabel.propTypes = {
    initialized: PropTypes.bool,
};

export default InitializedLabel;