import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';
import PropTypes from 'prop-types';

const OrderInfosPure = ({ paymentMethod, postmanOffer, totalPrice }) => (
    <>
        <h4>Informations commande client</h4>
        <Row>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Offre postier</ControlLabel>
                    <FormControl type='text' value={postmanOffer} readOnly />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Montant payé à la commande</ControlLabel>
                    <FormControl type='text' value={totalPrice} readOnly />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Méthode de paiement</ControlLabel>
                    <FormControl type='text' value={paymentMethod} readOnly />
                </FormGroup>
            </Col>
        </Row>
    </>
);

OrderInfosPure.propTypes = {
    paymentMethod: PropTypes.string,
    postmanOffer: PropTypes.string,
    totalPrice: PropTypes.string,
};

const withCalculatedProps = withProps(
    ({ subscription }) => ({
        paymentMethod: subscription?.paymentMethod || 'NA',
        postmanOffer: subscription?.isPostmanOffer ? 'OUI' : 'NON',
        totalPrice: subscription?.totalPrice ? parseFloat(subscription.totalPrice) + '€' : 'NA',
    })
);

const OrderInfos = compose(
    withCalculatedProps,
    pure,
)(OrderInfosPure);

OrderInfos.propTypes = {
    subscription: PropTypes.object,
};

export default OrderInfos;
