import { notify } from 'react-notify-toast';

const initJQuery = () => {
    window.jQuery = require('jquery');
    window.jQuery.support.cors = true;
    window.jQuery.ajaxPrefilter((options, originalOptions, jqXhr) => {
        if(options.prefilterRequest !== false) {
            if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_API_URL)
                options.url = `${process.env.REACT_APP_API_URL}${options.url}`;

            options.crossDomain = true;
            options.xhrFields = { withCredentials: true };

            jqXhr.setRequestHeader('UnauthorizedPreference', '401');
        }

        if(options.addHeaders) {
            options.addHeaders.forEach(header => jqXhr.setRequestHeader(header.name, header.value));
        }
    });
};

const initCatchFunctions = () => {
    window.jQuery(document).ajaxError(( event, jqxhr, settings, exception ) => {
        switch (jqxhr.status) {
            case 401:
                window.location.href = '/login';
                break;

            case 503:
            case 504:
                notify.show('Le serveur ne répond pas.', 'error');
                break;

            default:
                break;
        }
    });
};

const initPingFunction = () => {
    const pingFn = () => window.jQuery.ajax(
        '/ping', {}
    ).then(() => {
        notify.hide();
    })
     .fail((error) => {
        notify.show('Le serveur ne répond pas !', 'error', -1);
    });

    if (process.env.NODE_ENV !== 'development') setInterval(pingFn, 5000);
};

export { initJQuery, initCatchFunctions, initPingFunction };