import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import { detachContract } from '../../../../../utils/requests';
import { errorMsg, successMsg } from '../../../../../utils/Notify';

const DetachDeviceButton = ({loading, onSuccess, dmdID}) => {

  const handleDetachDevice = () => {
    detachContract(dmdID)
    .then(response => {
      onSuccess(response)
			successMsg('La tablette a été détachée !');
    },
    (jqXHR, textStatus, errorThrown) => errorMsg('Impossible de détacher la tablette !'));
  }

  return (
    <Button
      bsStyle='danger'
      onClick={handleDetachDevice}
      disabled={loading}
      block
    >
      Détacher la tablette
    </Button>
  );
};

DetachDeviceButton.propTypes = {
  dmdID: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default DetachDeviceButton;