import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import SetActivatedButton from './ActionButtons/SetActivatedButton';

const NeedTerminationGroupPure = ({
                                      displayVertical,
                                    }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <SetActivatedButton
      bsStyle='warning'
      force
      label='Annuler la désactivation'/>
  </ButtonGroup>
);

NeedTerminationGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const NeedTerminationButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(NeedTerminationGroupPure);

NeedTerminationButtonGroup.propTypes = {};

export default NeedTerminationButtonGroup;