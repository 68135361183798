import React, { Component } from 'react';
import { Label } from 'react-bootstrap';

class DeliveryState extends Component {
    render() {
        let value = <div>{this.props.state}</div>
        if (this.props.state === 'INCONNU' || this.props.state === 'RETOUR_DESTINATAIRE')
            value = <Label bsStyle='danger'>{this.props.state}</Label>
        if (this.props.state === 'A_RETIRER' || this.props.state === 'DESTINATAIRE_INFORME')
            value = <Label bsStyle='warning'>{this.props.state}</Label>
        if (this.props.state === 'EN_LIVRAISON' || this.props.state === 'EXPEDIE' || this.props.state === 'DISTRIBUE')
            value = <Label bsStyle='success'>{this.props.state}</Label>
        if (this.props.state === 'TRI_EFFECTUE' || this.props.state === 'PRIS_EN_CHARGE')
            value = <Label bsStyle='info'>{this.props.state}</Label>
        return value;
    }
}

export default DeliveryState;