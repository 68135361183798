import React, { Component } from 'react';
import { Button, Col, FormControl, FormGroup, Glyphicon, Grid, Panel, Row, Table } from 'react-bootstrap';

import { tsToDateForInput } from '../utils/Date';
import { navigateToContract } from '../utils/functions';
import jsRoutes from '../routes/routes';
import LoadingProgress from './components/LoadingProgress';

import SearchTabEntry from './search/SearchTabEntry';
import { getContractURL } from '../utils/requests';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = this.getComponentInitialState();
    }

    getComponentInitialState() {
        return {
            entries: null,
            properties: [
                {
                    name: 'Contrat',
                    size: '10%'
                }, {
                    name: 'Acheteur',
                    size: '20%'
                }, {
                    name: 'Souscripteur',
                    size: '20%'
                }, {
                    name: 'Utilisateur',
                    size: '20%'
                }, {
                    name: 'Tablette',
                    size: '20%'
                }
            ],
            sort: {
                name: 'Contrat',
                reverse: false
            },
            filters: null,
            isFullSearch: false,
        };
    }

    search(mode, searchValue, full){
        let _this = this;
        _this.setState({loading: true});
        let data = { fullSearch: full === true};
        jsRoutes.controllers.backoffice.SupportV2Controller.search(mode, searchValue)
            .ajax({
                data: JSON.stringify(data),
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                processData: false
            }).then(function (response, textStatus, jqXHR) {
                _this.setState({
                    entries: response.map(contract => _this.formatContract(contract)),
                    loading: false
                });
        }, function (jqXHR, textStatus, errorThrown) {
            _this.setState({loading: false});
        });

    }

    formatPerson(person){
        if(person && person.current){
            return person.current.firstName + ' ' + person.current.lastName + '\n' + person.current.phone + ' ' + (person.current.cellphone  ? person.current.cellphone : '') + '\n' + person.current.zipCode + ' ' + person.current.city;
        }else {
            return 'Indisponible';
        }
    }

    formatTablet(amd, dmd){
        if(amd && dmd){
            return dmd.SN + (amd.lastActivity  ? tsToDateForInput(amd.lastActivity) : '');
        }else {
            return 'Indisponible';
        }
    }

    formatContract(contract) {
        return {
            '_id': contract.contractNumber,
            'Contrat': contract.contractNumber,
            'Acheteur': this.formatPerson(contract.buyer),
            'Souscripteur': this.formatPerson(contract.subscriber),
            'Utilisateur': this.formatPerson(contract.mainUser),
            'Tablette': this.formatTablet(contract.amd, contract.dmd)
        };
    }

    componentDidMount(){
        if (this.props.mode && this.props.searchValue) {
            this.setState({searchMode: this.props.mode, searchValue: this.props.searchValue});
            this.search(this.props.mode, this.props.searchValue);
        }
    }

    componentDidUpdate(previousProps) {
        if (this.props.mode && this.props.searchValue &&
            (this.props.mode !== previousProps.mode || this.props.searchValue !== previousProps.searchValue)) {
            this.setState({searchMode: this.props.mode, searchValue: this.props.searchValue});
            this.search(this.props.mode, this.props.searchValue);
        }
    }

    handleContractClick(contractNumber){
        navigateToContract(contractNumber);
    }

    onViewNewPageClick(contractNumber) {
        window.open(getContractURL(contractNumber), '_blank');
    }

    handleFullSearchClick(){
        this.setState({ isFullSearch: true});
        this.search(this.props.mode, this.props.searchValue, true);
    }

    onFilterInputChange(name, event) {
        if (name === '') {
            this.setState({filters: null, activePage: 1});
            return;
        }
        var filters = this.state.filters;
        if (!filters) {
            filters = [];
        }
        if (!event.target.value) {
            delete filters[name];
        } else {
            filters[name] = event.target.value;
        }
        this.setState({filters: filters, activePage: 1});
    }

    onSortClicked(name, event) {
        var sort = this.state.sort;
        if (!sort) {
            sort = [];
        }
        if (sort.name === name) {
            sort.reverse = !sort.reverse;
        } else {
            sort.reverse = false;
        }
        sort.name = name;
        this.setState({sort: sort});
    }

    isFiltered(entry) {
        if (!this.state.filters)
            return true;
        for (var property in this.state.filters) {
            if (!entry.hasOwnProperty(property) || !entry[property] || entry[property].toString().toLocaleLowerCase().indexOf(this.state.filters[property].toLocaleLowerCase()) === -1)
                return false;
        }
        return true;
    }

    render() {
        var contractTable;
        var _this = this;
        var clearButton;
        let fullSearchButton;
        if (this.state.entries) {
            if(this.state.entries.length === 50 && !this.state.isFullSearch){
                fullSearchButton = (
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Button className='center-block' bsStyle='info' onClick={this.handleFullSearchClick.bind(this)}>
                                    {'Charger tous les résultats (limité aux 50 premiers par défaut)'}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                );
            }

            var filteredEntries = this.state.entries.filter(e => _this.isFiltered(e));
            clearButton = (
                <Button className='center-block' bsStyle='primary' bsSize='small' onClick={this.onFilterInputChange.bind(_this, '')} disabled={this.state.loading && this.state.filters}>
                    <Glyphicon glyph='remove'/>&nbsp;Effacer
                </Button>
            );

            var entries = filteredEntries.sort(function(a, b) {
                if (!_this.state.sort) {
                    return a.ID.localeCompare(b.ID);
                } else {
                    let diff;
                    if (_this.state.sort.reverse) {
                        if(!b[_this.state.sort.name]){
                            if(!a[_this.state.sort.name]){
                                diff = 0;
                            }else {
                                diff = 1;
                            }
                        }else {
                            if (!a[_this.state.sort.name]) {
                                diff = -1;
                            } else {
                                if (typeof b[_this.state.sort.name] === 'number') {
                                    diff = b[_this.state.sort.name] - a[_this.state.sort.name];
                                } else {
                                    diff = b[_this.state.sort.name].toString().localeCompare(a[_this.state.sort.name].toString())
                                }
                            }
                        }
                    } else {
                        if(!a[_this.state.sort.name]){
                            if(!b[_this.state.sort.name]){
                                diff = 0;
                            }else {
                                diff = 1;
                            }
                        }else {
                            if (!b[_this.state.sort.name]) {
                                diff = -1;
                            } else {
                                if (typeof a[_this.state.sort.name] === 'number') {
                                    diff = a[_this.state.sort.name] - b[_this.state.sort.name];
                                } else {
                                    diff = a[_this.state.sort.name].toString().localeCompare(b[_this.state.sort.name].toString())
                                }
                            }
                        }
                    }
                    if (diff === 0) {
                        diff = b.ID - a.ID;
                    }
                    return diff;
                }
            }).map(entry =>
                <SearchTabEntry key={entry._id}
                                 onEntryClick={_this.handleContractClick.bind(_this, entry._id)}
                                 properties={_this.state.properties}
                                 entry={entry}
                                 onViewNewPageClick={_this.onViewNewPageClick.bind(_this, entry._id)}

                />
            );
            contractTable = (
                <Table striped bordered condensed hover>
                    <thead>
                    <tr>
                        {this.state.properties.map(property => {
                            let caret;
                            if (_this.state.sort.name === property.name) {
                                if (_this.state.sort.reverse) {
                                    caret = <Glyphicon glyph='triangle-bottom' bsSize='small'/>;
                                } else {
                                    caret = <Glyphicon glyph='triangle-top' bsSize='small'/>;
                                }
                            }
                            return (
                                <th style={{
                                    width: property.size,
                                    cursor: 'pointer'
                                }} key={'property_header_' + property.name}
                                    onClick={_this.onSortClicked.bind(_this, property.name)}>
                                    {property.name}&nbsp; {caret}
                                </th>
                            );
                        })}
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {this.state.properties.map(propertie =>
                            <th key={'property_filter_' + propertie.name}>
                                <FormGroup className='small-control' bsSize='small'>
                                    <FormControl type='text' value={_this.state.filters && _this.state.filters[propertie.name]
                                        ? _this.state.filters[propertie.name]
                                        : ''} onChange={_this.onFilterInputChange.bind(_this, propertie.name)}/>
                                </FormGroup>
                            </th>
                        )}
                        <th>{clearButton}</th>
                    </tr>
                    {entries}
                    </tbody>
                </Table>

            );

        }
        return (
            <div>
                <Grid>
                    <LoadingProgress show={this.state.loading}/>
                    <Panel bsStyle='primary'>
                        <Panel.Heading>Résultats</Panel.Heading>
                        <Panel.Body>
                            {fullSearchButton}
                            {contractTable}
                        </Panel.Body>
                    </Panel>
                </Grid>
            </div>
        )
    }
}

export default Search;