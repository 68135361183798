import React from 'react';
import UltimatePagination from '../../../../components/UltimatePagination';

const TicketAnswersTablePagination = ({currentPage, handlePageChange, numberOfAnswers = 0, itemsPerPage = 100}) => {

	const totalPages = Math.ceil(numberOfAnswers / itemsPerPage);

	if (numberOfAnswers > 0) {
		return (
			<div className='text-center'>
				<UltimatePagination
					totalPages={totalPages}
					currentPage={currentPage}
					onChange={handlePageChange}
				/>
			</div>
		);
	}
	return null;
}

export default TicketAnswersTablePagination;