import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {ENABLE_LMR_FEATURES} from '../../../../../utils/constants';
import AfterSaleTabletReturnModal from './AfterSaleTabletReturnModal';

const AfterSaleTabletReturnModalButton = ({ disabled, hidden, options, ticket }) => {
	const [show, setShow] = useState(false);

	if (!ENABLE_LMR_FEATURES || hidden) return null;

	const handleShow = () => setShow(true);

	const handleHide = () => setShow(false);
	
	return <>
    <Button
			bsStyle='primary'
			className='pull-right btn-violet'
			disabled={disabled}
			onClick={handleShow}
		>
			Retour
    </Button>

		<AfterSaleTabletReturnModal
			options={options}
			show={show}
			ticket={ticket}
			onHide={handleHide}
		/>
	</>;
}

AfterSaleTabletReturnModalButton.propTypes = {
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	options: PropTypes.object,
	ticket: PropTypes.object,
};

export default AfterSaleTabletReturnModalButton;