import React from 'react';
import { FormGroup } from 'react-bootstrap';
import ReactSelect from 'react-select';

const MultipleSelect = ({options = [], value = [], onChange, name, placeholder}) => (
	<FormGroup className='small-control' bsSize='small'>
		<ReactSelect
			name={name}
			value={value}
			options={options}
			onChange={onChange}
			placeholder={placeholder}
			multi
		/>
	</FormGroup>
);

export default MultipleSelect;