import PropTypes from 'prop-types';
import {getContext, withContext} from 'recompose';

export const withSearchSimContext = withContext(
  {
    changeSIMOperator: PropTypes.string,
    callback4OperatorEdition: PropTypes.func,
    setCallback4OperatorEdition: PropTypes.func,
    setChangeSIMOperator: PropTypes.func,
  },
  ({
     changeSIMOperator,
     callback4OperatorEdition,
     setCallback4OperatorEdition,
     setChangeSIMOperator,
   }) => ({
    changeSIMOperator,
    callback4OperatorEdition,
    setCallback4OperatorEdition,
    setChangeSIMOperator,
  }),
);

export const connectSearchSimContext = getContext(
  {
    changeSIMOperator: PropTypes.string,
    callback4OperatorEdition: PropTypes.func,
    setCallback4OperatorEdition: PropTypes.func,
    setChangeSIMOperator: PropTypes.func,
  },
);

export const compareSims = (a, b) => {
  let diff = a.ICCID - b.ICCID;
  if (diff === 0) {
    diff = a.created - b.created;
  }
  return diff;
}