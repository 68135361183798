import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import {getNextMonthFirstDayMidday} from '../../../../../../utils/Date';

const AddSubscriptionStateButtonPure = ({onClick}) => (
    <Button bsSize='xsmall'
            bsStyle='success'
            data-test-id='addSubscriptionStateButton'
            onClick={onClick}>
        <Glyphicon glyph='plus'/>
    </Button>
);

AddSubscriptionStateButtonPure.propTypes = {
    onClick: PropTypes.func,
};

const AddSubscriptionStateButton = compose(
    branch(
        ({lastSubscriptionState}) => {
            if (lastSubscriptionState) {
                const nextMonthFirstDayMidday = getNextMonthFirstDayMidday(moment());

                if (moment(lastSubscriptionState.dateBegin).isSame(nextMonthFirstDayMidday, 'day')) {
                    return true;
                }
            }
            return false;
        },
        renderNothing,
    ),
    pure,
)(AddSubscriptionStateButtonPure);

AddSubscriptionStateButton.propTypes = {
    lastSubscriptionState: PropTypes.object,
    onClick: PropTypes.func,
};

export default AddSubscriptionStateButton;