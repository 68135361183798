import gql from 'graphql-tag';
import {TOUCANLISTOPERATIONERROR_FRAGMENTS} from './ToucanSchema';

const SET_SUBSCRIPTIONSTATE_ON_SUBSCRIPTIONS = gql`
    mutation SubscriptionSetSubscriptionStateOnContractList($input: SubscriptionSetSubscriptionStateOnContractListInput!) {
        subscriptionSetSubscriptionStateOnContractList(input: $input) {
            ... on GetSubscriptionListOperationErrorOutput {
                errorReason
            }

            ... on GetSubscriptionListOperationOutput {
                subscriptions {
                    contractNumber
                }
                errors {
                    ...toucanListOperationErrorStringType
                }
            }
        }
    }
    ${TOUCANLISTOPERATIONERROR_FRAGMENTS.StringType}
`;

const UPDATE_SERVICETERMINATION_ON_SUBSCRIPTIONS = gql`
    mutation SubscriptionSetServiceTerminationOnContractList($input: SubscriptionSetServiceTerminationOnContractListInput!) {
        subscriptionSetServiceTerminationOnContractList(input: $input) {
            errors {
                reason
                subject
            }
            subscriptions {
                contractNumber
            }
        }
    }
`;

const GET_SUBSCRIPTION_TYPES_LIST = gql`
    query GetSubscriptionTypesList {
        listSubscriptionTypes {
            subscriptionTypeClassifications {
                clientTypology
                group
                options
                subscriptionType
            }
        }
    }
`;

const DELETE_LAST_SUBSCRIPTION_STATE = gql`
    mutation DeleteLastSubscriptionState($input: DeleteLastSubscriptionStateInput!) {
        deleteLastSubscriptionState(input: $input) {
            __typename
            ... on DeleteLastSubscriptionStateOutput {
                subscription {
                    contractNumber
                }
            }
            ... on DeleteLastSubscriptionStateErrorOutput {
                errorReason
            }
        }
    }
`;

const B2B_DELETE_LAST_SUBSCRIPTION_STATE = gql`
    mutation B2bDeleteLastSubscriptionState($input: B2BDeleteLastSubscriptionStateInput!) {
        b2bDeleteLastSubscriptionState(input: $input) {
            __typename
            ... on B2BDeleteLastSubscriptionStateErrorOutput {
                errorReason
            }
            ... on B2BDeleteLastSubscriptionStateOutput {
                failures {
                    contractNumber
                    errorReason
                }
                successes {
                    subscription {
                        contractNumber
                    }
                }
            }
        }
    }
`;

export const SWAP_SUBSCRIPTIONS_TO_EQUILIBRE = gql`
    mutation SwapSubscriptionsToEquilibre($input: SwapSubscriptionsToEquilibreInput!) {
        swapSubscriptionsToEquilibre(input: $input) {
            ... on SwapSubsriptionsToEquilibreErrorOutput {
                errorReason
            }

            ... on SwapSubsriptionsToEquilibreOutput {
                failures {
                    contractNumber
                    errorReason
                }
                successes {
                    contractNumber
                    type
                }
            }
        }
    }
`;

export {
  GET_SUBSCRIPTION_TYPES_LIST,
  SET_SUBSCRIPTIONSTATE_ON_SUBSCRIPTIONS,
  UPDATE_SERVICETERMINATION_ON_SUBSCRIPTIONS,
  DELETE_LAST_SUBSCRIPTION_STATE,
  B2B_DELETE_LAST_SUBSCRIPTION_STATE,
};