import gql from 'graphql-tag';

const GET_DEVICES_WITH_CORE_LIKE_COUNTER = gql`
    query dmdevicesWithCoreLike($currentCoreVersion: String!) {
        devicesWithCoreLike(currentCoreVersion: $currentCoreVersion) {
            devicesWithCoreLike
        }
    }
`;

export {
    GET_DEVICES_WITH_CORE_LIKE_COUNTER
};