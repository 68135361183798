import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import {branch, compose, getContext, pure, renderNothing} from 'recompose';
import {isPrepayedOrDemo} from '../../../../../../../utils/Subscription';
import SingleDatePicker from '../../../../../../components/DatePicker/SingleDatePicker';

const DateEndFieldPure = ({disabled, subscriptionState, setSubscriptionState}) => (
  <FormGroup className="small-control"
             bsSize="small">
    <ControlLabel>Fin</ControlLabel>
    <SingleDatePicker schema={{params: {disabled}}}
                      value={subscriptionState.dateEnd}
                      onChange={d => setSubscriptionState({...subscriptionState, dateEnd: d})}/>
  </FormGroup>
);

DateEndFieldPure.propTypes = {
  disabled: PropTypes.bool,
  subscriptionState: PropTypes.object,
  setSubscriptionState: PropTypes.func,
};

const DateEndField = compose(
  getContext(
    {
      subscriptionState: PropTypes.object,
    },
  ),
  branch(
    ({subscriptionState}) => !isPrepayedOrDemo(subscriptionState.type),
    renderNothing,
  ),
  pure,
)(DateEndFieldPure);

DateEndField.propTypes = {
  disabled: PropTypes.bool,
  setSubscriptionState: PropTypes.func,
};

export default DateEndField;