import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateWithPlanFunction, withButtonContext, withSelectPlanStates, wrapWithModal} from './Helpers';

const SetActivatedButtonPure = ({
                                  bsStyle,
                                  buttonSize,
                                  label,
                                  setShowModal,
                                }) => (
  <Button bsStyle={bsStyle} bsSize={buttonSize} onClick={() => setShowModal(true)}>
    {label}
  </Button>
);

SetActivatedButtonPure.propTypes = {
  bsStyle: PropTypes.string,
  buttonSize: PropTypes.string,
  label: PropTypes.string,
  setShowModal: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateWithPlanFunction,
});

const {confirmActivation, forceActivation} = jsRoutes.controllers.backoffice.SupportV2SimController;

const SetActivatedButton = compose(
  withButtonContext,
  withSelectPlanStates,
  withProps(
    ({force}) => {
      const route = force ? forceActivation : confirmActivation;

      return {
        route,
      };
    },
  ),
  handlers,
  wrapWithModal,
  pure,
)(SetActivatedButtonPure);

SetActivatedButton.propTypes = {
  bsStyle: PropTypes.string,
  force: PropTypes.bool,
  label: PropTypes.string,
};

export default SetActivatedButton;