import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, Row} from 'react-bootstrap';
import {connectWebUserContext} from '../../../../hoc/withMeAndTeamsAndConstants';
import {TICKET_SUBJECTS} from '../../../../utils/constants';
import {isN2OrHigher} from '../../../../utils/functions';
import SimManagement from '../../../sims/SimManagement';
import FormattedICCID from './FormattedICCID';

const Sim = connectWebUserContext(({
	webUserJson,
	dmd,
	ticket,
	handleManageSim,
	setLoading,
}) => {
	if (ticket.subject !== TICKET_SUBJECTS.THREEG || !dmd || !isN2OrHigher(webUserJson)) return null;
	return (
		<>
			<Row>
				<Col md={4}>
					<FormattedICCID dmd={dmd} />
				</Col>
				<Col md={8}>
					<ControlLabel>Gestion Sim</ControlLabel>
					<SimManagement
						callBack={handleManageSim}
						sim={dmd.sim}
						setLoading={setLoading}
					/>
				</Col>
			</Row>
			<hr/>
		</>
	);
});

Sim.propTypes = {
	ticket: PropTypes.object,
	dmd: PropTypes.object,
	handleManageSim: PropTypes.func,
	setLoading: PropTypes.func,
};

export default Sim;