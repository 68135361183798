import PropTypes from 'prop-types';
import React from 'react';
import {Button, FormGroup, Modal, Radio} from 'react-bootstrap';
import {SIM_PLANS} from '../../../../utils/Sim';

const SelectPlanModal = ({
                           simPlan,
                           show,
                           setShow,
                           setSimPlan,
                           onValidateClick,
                         }) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Choisir un forfait</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <FormGroup>
        {
          Object.values(SIM_PLANS).map(
            ({label, value}) => (
              <Radio checked={value === simPlan}
                     key={value}
                     inline
                     name='simPlan'
                     value={value}
                     onChange={() => setSimPlan(value)}>
                {label}
              </Radio>
            ),
          )
        }
      </FormGroup>
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle='success' onClick={() => onValidateClick(simPlan)}>Valider</Button>
    </Modal.Footer>
  </Modal>
);

SelectPlanModal.propTypes = {
  simPlan: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  setSimPlan: PropTypes.func,
  onValidateClick: PropTypes.func,
};

export default SelectPlanModal;