const BIC_CODES = [
    {bic: 'ABNAFRPP', label: 'THE ROYAL BANK OF SCOTLAND PLC PARIS BRANCH'},
    {bic: 'AECFFR21', label: 'AMERICAN EXPRESS CARTE FRANCE'},
    {bic: 'AFRIFRPP', label: 'BOA-FRANCE'},
    {bic: 'AGFBFRCC', label: 'ALLIANZ BANQUE S.A. (former BANQUE AGF S.A.)'},
    {bic: 'AGRIFRPI', label: 'AMUNDI ASSET MANAGEMENT'},
    {bic: 'AGRIFRPP', label: 'CREDIT AGRICOLE'},
    {bic: 'AGRIMQMX', label: 'CRCAM DE LA MARTINIQUE ET DE LA GUYANE'},
    {bic: 'AGRIRERX', label: 'CRCAM DE LA REUNION'},
    {bic: 'ARCEFRP1', label: 'ARCALIS ACTIF GENERAL LA POSTE'},
    {bic: 'AUDIFRPP', label: 'BANK AUDI FRANCE'},
    {bic: 'AXABFRPP', label: 'AXA BANQUE SA'},
    {bic: 'BAMYFR22', label: 'BANQUE MICHEL INCHAUSPE BAMI'},
    {bic: 'BARCFRPP', label: 'BARCLAYS BANK PLC'},
    {bic: 'BATIFRP1', label: 'BANQUE DU BAT ET DES TRAV PUB BTP BANQUE'},
    {bic: 'BBPIFRPP', label: 'BANCO BPI'},
    {bic: 'BCDMFRPP', label: 'BANQUE CHAABI DU MAROC'},
    {bic: 'BCHAFR21', label: 'BANQUE CHALUS'},
    {bic: 'BCITFRPP', label: 'INTESA SANPAOLO SPA Succursale de Paris'},
    {bic: 'BCMAFRPP', label: 'ATTIJARIWAFA BANK (FORMERLY BANQUE COMMERCIALE DU MAROC)'},
    {bic: 'BCRTFRP1', label: 'CORTAL CONSORS'},
    {bic: 'BCRTFRPP', label: 'CORTAL CONSORS'},
    {bic: 'BDEIFRPP', label: 'BANQUE THEMIS (FORMERLY BANQUE DE L\'ILE DE FRANCE - B.D.E.I.)'},
    {bic: 'BDFEFR2L', label: 'BANQUE DE FRANCE'},
    {bic: 'BDFEFR2T', label: 'BANQUE DE FRANCE'},
    {bic: 'BDFEFRPP', label: 'BANQUE DE FRANCE'},
    {bic: 'BDUPFR2S', label: 'BANQUE DUPUY DE PARSEVAL'},
    {bic: 'BEPOFR21', label: 'BANCA POPOLARE DI BERGAMO - CREDITO VARESINO'},
    {bic: 'BFBKFRP1', label: 'BFORBANK'},
    {bic: 'BGFIFRPP', label: 'BGFI INTERNATIONAL'},
    {bic: 'BIKCFRP1', label: 'BINCKBANK'},
    {bic: 'BMCEFRPP', label: 'BMCE BANK INTERNATIONAL PLC SUCCURSALE EN FRANCE'},
    {bic: 'BMMMFR2A', label: 'BANQUE MARTIN-MAUREL'},
    {bic: 'BMRZFR21', label: 'BANQUE MARZE'},
    {bic: 'BNABFRPP', label: 'BNP PARIBAS ARBITRAGE SNC'},
    {bic: 'BNPAFRPH', label: 'BNP PARIBAS SA (FORMERLY BANQUE NATIONALE DE PARIS S.A.)'},
    {bic: 'BNPAFRPP', label: 'BNP PARIBAS'},
    {bic: 'BNPAGFGX', label: 'BNP PARIBAS GUYANE'},
    {bic: 'BNPARERX', label: 'BNP PARIBAS REUNION'},
    {bic: 'BNPCFR21', label: 'BNP PARIBAS FACTOR'},
    {bic: 'BNUGFR21', label: 'BANQUE NUGER'},
    {bic: 'BOFAFRPP', label: 'BANK OF AMERICA, N.A. PARIS BRANCH'},
    {bic: 'BOTKFRPX', label: 'The Bank of Tokyo - Mitsubishi UFJ, Ltd.'},
    {bic: 'BOUSFRPP', label: 'BOURSORAMA'},
    {bic: 'BPCEFRPP', label: 'BPCE SA'},
    {bic: 'BPFIFRP1', label: 'LA BANQUE POSTALE FINANCEMENT'},
    {bic: 'BPNPFRP1', label: 'BPN - BANCO PORTUGUES DE NEGOCIOS SA'},
    {bic: 'BPOLPFTP', label: 'BANQUE DE POLYNESIE'},
    {bic: 'BPPBFRP1', label: 'BNP PARIBAS PRIVATE BANK'},
    {bic: 'BPSMFRPP', label: 'BANQUE BCP'},
    {bic: 'BRASFRPP', label: 'BANCO DO BRASIL AG SUCCURSALE FRANCE'},
    {bic: 'BREDFRPP', label: 'BRED BANQUE POPULAIRE'},
    {bic: 'BSAVFR2C', label: 'BANQUE DE SAVOIE'},
    {bic: 'BSPFFRPP', label: 'BANQUE PALATINE'},
    {bic: 'BSUIFRPP', label: 'CREDIT AGRICOLE CIB'},
    {bic: 'CAONFR21', label: 'CAPITAL ONE'},
    {bic: 'CCBPFRPP', label: 'BANQUE POPULAIRE'},
    {bic: 'CCFEFRP1', label: 'CCM FDS DU REGIME CC PF'},
    {bic: 'CCFRFRCR', label: 'HSBC FRANCE'},
    {bic: 'CCFRFRPP', label: 'HSBC FRANCE'},
    {bic: 'CCMOFR21', label: 'CAISSE DE CREDIT MUNICIPAL DE BORDEAUX'},
    {bic: 'CCMVFR21', label: 'CAISSE DE CREDIT MUTUEL LAVAL BRETAGNE'},
    {bic: 'CCOPFRCP', label: 'Credit Cooperatif Societe Cooperative'},
    {bic: 'CCOPFRPP', label: 'CREDIT COOPERATIF'},
    {bic: 'CCUTFR21', label: 'CSSE CIT MUNICIPAL DE TOULON'},
    {bic: 'CDCGFRPP', label: 'CAISSE DES DEPOTS ET CONSIGNATIONS'},
    {bic: 'CDPRFRP1', label: 'CDC PROJETS'},
    {bic: 'CEPAFRPP', label: 'CAISSE D\'EPARGNE PROVENCE ALPES CORSE'},
    {bic: 'CEPANCNM', label: 'BANQUE DE NOUVELLE CALEDONIE'},
    {bic: 'CFCUFR21', label: 'CAISSE FEDERALE DE CREDIT MUTUELLE NORMANDIE'},
    {bic: 'CFFIFR2L', label: 'COFIDIS'},
    {bic: 'CFFRFRPP', label: 'CREDIT FONCIER DE FRANCE'},
    {bic: 'CGCPFRP1', label: 'COMPAGNIE GENERALE DE CREDIT AUX PARTICULIERS - CREDIPAR'},
    {bic: 'CGDIFRPP', label: 'CAIXA GERAL DE DEPOSITOS S A'},
    {bic: 'CGRCFRP1', label: 'CAISSE GENERALE RETRAITE CAISSE D EPARGNE'},
    {bic: 'CHASFRPB', label: 'JPMORGAN CHASE BANK,N.A.'},
    {bic: 'CHASFRPP', label: 'JPMORGAN CHASE BANK,N.A.'},
    {bic: 'CITIFRPP', label: 'CITIBANK INTERNATIONAL LIMITED FRANCE BRANCH'},
    {bic: 'CLBQFRP1', label: 'CLF BANQUE'},
    {bic: 'CMBRFR2B', label: 'CREDIT MUTUEL ARKEA'},
    {bic: 'CMCIFR21', label: 'CREDIT MUTUEL - CIC BANQUES'},
    {bic: 'CMCIFR2A', label: 'CAISSE FEDERALE DE CREDIT MUTUEL'},
    {bic: 'CMCIFRP1', label: 'CREDIT MUTUEL - CIC BANQUES'},
    {bic: 'CMCIFRPA', label: 'CREDIT MUTUEL - CIC BANQUES'},
    {bic: 'CMCIFRPB', label: 'CM CIC SECURITIES'},
    {bic: 'CMCIFRPP', label: 'CREDIT INDUSTRIEL ET COMMERCIAL CIC'},
    {bic: 'CMDIFR21', label: 'CREDIT MUNICIPAL DE DIJON'},
    {bic: 'CMMMFR21', label: 'CRC MARIT MUT DE LA MEDITERRANEE'},
    {bic: 'CMUTFR21', label: 'CAISSE FEDERALE DU CREDIT MUTUEL DE LOIRE ATLANTIQUE ET DU CENTRE OUEST'},
    {bic: 'COBAFRPX', label: 'COMMERZBANK AG'},
    {bic: 'CONOFRP1', label: 'COFINOGA'},
    {bic: 'COUOFR21', label: 'COFIROUTE'},
    {bic: 'COURFR2T', label: 'BANQUE COURTOIS'},
    {bic: 'CPMEFRPP', label: 'BPIFRANCE'},
    {bic: 'CRAFFRP1', label: 'CREALFI'},
    {bic: 'CRGEFR2X', label: 'BANCA CARIGE SPA'},
    {bic: 'CRLYFRPP', label: 'CREDIT LYONNAIS'},
    {bic: 'CRTAFR21', label: 'CREATIS'},
    {bic: 'DELUFR22', label: 'BANQUE DELUBAC ET CIE'},
    {bic: 'DEUTFRPP', label: 'DEUTSCHE BANK AG'},
    {bic: 'ECOCFRPP', label: 'EBI SA'},
    {bic: 'EDFGFRPP', label: 'ELECTRICITE DE FRANCE'},
    {bic: 'ELFAFRP1', label: 'HSBC FACTORING FRANCE'},
    {bic: 'FACFFRPP', label: 'GE FACTOFRANCE'},
    {bic: 'FAMSFRPP', label: 'BNPPARIBAS FINAMS'},
    {bic: 'FEMBFRP1', label: 'BANQUE FRANCAISE MUTUALISTE'},
    {bic: 'FIDCFR21', label: 'BANQUE FIDUCIAL'},
    {bic: 'FPELFR21', label: 'FINANCIERE DES PAIEMENTS ELECTRONIQUES S.A.S.'},
    {bic: 'FTELFRPP', label: 'ORANGE SA'},
    {bic: 'FTNOFRP1', label: 'FORTUNEO'},
    {bic: 'GPBAFRPP', label: 'GROUPAMA BANQUE'},
    {bic: 'GSCFFR22', label: 'SOCIETE GENERALE SCF'},
    {bic: 'GSFHFRPP', label: 'SOCIETE GENERALE SFH'},
    {bic: 'GSZGFRPP', label: 'GDF SUEZ'},
    {bic: 'HCREFR21', label: 'HERVET CREDITERME'},
    {bic: 'IIDFFR21', label: 'ING DIRECT NV'},
    {bic: 'INGBFRPP', label: 'ING BANK France'},
    {bic: 'ISAEFRPP', label: 'CACEIS BANK FRANCE'},
    {bic: 'KOEXFRPP', label: 'KOREA EXCHANGE BANK'},
    {bic: 'KOLBFR21', label: 'BANQUE KOLB S.A.'},
    {bic: 'KREDFRPP', label: 'KBC BANK NV PARIS'},
    {bic: 'LAYDFR2W', label: 'BANQUE LAYDERNIER'},
    {bic: 'LCLPFRP1', label: 'LCL BANQUE PRIVEE'},
    {bic: 'LEGRFRP1', label: 'LEGAL AND GENERAL BANK (FRANCE)'},
    {bic: 'MCCFFRP1', label: 'MACIF-MUTUALITE'},
    {bic: 'MIDLFRCP', label: 'HSBC BANK PLC'},
    {bic: 'MIDLFRPX', label: 'HSBC BANK PLC'},
    {bic: 'MONTFRPP', label: 'MONTE PASCHI BANQUE S A'},
    {bic: 'NATXFRPP', label: 'Natixis SA'},
    {bic: 'NFACFR21', label: 'NATIXIS FACTOR'},
    {bic: 'NORDFRPP', label: 'CREDIT DU NORD'},
    {bic: 'NSMBFRPP', label: 'BANQUE NEUFLIZE OBC'},
    {bic: 'ODDOFRPP', label: 'ODDO ET CIE'},
    {bic: 'ODYVFRP1', label: 'ODYSSEE VENTURE'},
    {bic: 'OPSPFR21', label: 'OLKY PAYMENT SERVICE PROVIDER'},
    {bic: 'PARBFRPP', label: 'BNP PARIBAS SECURITIES SERVICES'},
    {bic: 'PAREFRPP', label: 'SOCIETE DE BOURSE PAREL'},
    {bic: 'POUYFR21', label: 'BANQUE POUYANNE'},
    {bic: 'PREUFRP1', label: 'BPE'},
    {bic: 'PSABFRPP', label: 'BANQUE PSA FINANCE'},
    {bic: 'PSSTFRPP', label: 'LA BANQUE POSTALE'},
    {bic: 'RALPFR2G', label: 'BANQUE RHONE ALPES'},
    {bic: 'REUBRERX', label: 'BANQUE DE LA REUNION'},
    {bic: 'RGFIFRPP', label: 'BANQUE DE REALISATIONS DE GESTION ET DE FINANCEMENT'},
    {bic: 'RGFPFRP1', label: 'RECETTE GENERALE DES FINANCES DE PARIS'},
    {bic: 'SBEXFRP1', label: 'STE DE BANQUE ET D EXPANSION SBE (2EME)'},
    {bic: 'SBINFRPP', label: 'STATE BANK OF INDIA'},
    {bic: 'SFBSFRP1', label: 'SOFIB'},
    {bic: 'SICVFRPP', label: 'EUROCLEAR FRANCE'},
    {bic: 'SLMPFRP1', label: 'SLIMPAY'},
    {bic: 'SMCTFR2A', label: 'STE MARSEILLAISE DE CREDIT'},
    {bic: 'SOAPFR22', label: 'CARREFOUR BANQUE'},
    {bic: 'SOCBPFTX', label: 'BANQUE SOCREDO'},
    {bic: 'SOGEFRPP', label: 'STE GENERALE'},
    {bic: 'SOGEGPGP', label: 'STE GENERALE DE BANQUE AUX ANTILLES'},
    {bic: 'SOGENCNN', label: 'STE GENERALE CALEDONIENNE DE BQ S G C B'},
    {bic: 'SORMFR2N', label: 'SOCRAM BANQUE'},
    {bic: 'TARNFR2L', label: 'BANQUE TARNEAUD'},
    {bic: 'TRGLFRP1', label: 'TRESORERIE GENERALE POUR L\'ETRANGER'},
    {bic: 'TRPUFRP1', label: 'TRESOR PUBLIC'},
    {bic: 'UBAFFRPP', label: 'UNION DES BANQUES ARABES ET FRANCAISES - UBAF'},
    {bic: 'USINFRPP', label: 'ARCELORMITTAL TREASURY'},
    {bic: 'YROCFRPP', label: 'YVES ROCHER'},
];

export {
    BIC_CODES,
};