import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, pure, renderNothing } from 'recompose';
import { Label } from 'react-bootstrap';
import moment from 'moment';

import { getTagLabel } from '../../../../../utils/functions';
import { connectMeAndTeamsAndConstantsContext } from '../../../../../hoc/withMeAndTeamsAndConstants';
import { Fragment } from 'react';

moment.locale('fr');

const IGNORED_TAGS = ['MIGRATE_CUSTOMER_TYPOLOGY_PART'];

const TagsLabelPure = ({ contractTags, tags }) =>
    contractTags
        .filter(_ => !IGNORED_TAGS.includes(_))
        .map(tag => <Fragment key={tag}>
            <Label bsStyle='primary'>{getTagLabel(tags, tag)}</Label>&nbsp;
        </Fragment>);

TagsLabelPure.propTypes = {
    tags: PropTypes.object,
    teams: PropTypes.object,
    types: PropTypes.object,
    webUserJson: PropTypes.object,
};

const TagsLabel = compose(
    connectMeAndTeamsAndConstantsContext,
    branch(
        ({ contractTags }) => !contractTags,
        renderNothing,
    ),
    pure,
)(TagsLabelPure);

TagsLabel.propTypes = {
    contractTags: PropTypes.array,
};

export default TagsLabel;