import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import { compose, pure, withHandlers, withProps, withState } from 'recompose';

import withLoading from '../../../../../../hoc/withLoading';
import jsRoutes from '../../../../../../routes/routes';

const PasswordEditPure = ({ hasPasswordChanged, sgPassword, password, handleCancel, handleSubmit, setPassword }) => (
    <Row>
        <Col sm={6}>
            <FormGroup>
                <ControlLabel>Password SG</ControlLabel>
                <FormControl type='text' value={password || sgPassword} onChange={(event) => setPassword(event.target.value)} />
            </FormGroup>
        </Col>
        <Col sm={6}>
            <FormGroup>
                <ControlLabel>&nbsp;</ControlLabel>
                <ButtonToolbar>
                    <Button bsStyle='danger' onClick={handleCancel} disabled={!hasPasswordChanged}>
                        Annuler
                    </Button>
                    <Button bsStyle='success' onClick={handleSubmit} disabled={!hasPasswordChanged}>
                        Valider
                    </Button>
                </ButtonToolbar>
            </FormGroup>
        </Col>
    </Row>
);

PasswordEditPure.propTypes = {
    hasPasswordChanged: PropTypes.bool,
    password: PropTypes.string,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    setPassword: PropTypes.func,
};

const withPasswordState = withState('password', 'setPassword', ({ sgPassword }) => sgPassword);

const getProps = withProps(({ password, sgPassword }) => ({
    hasPasswordChanged: password !== sgPassword,
}));

const setSGPassword = (contractNumber, password) => jsRoutes.controllers.backoffice.MaintenanceV2Controller
    .setSGPassword(contractNumber, password)
    .ajax({
        processData: false,
    });

const handlers = withHandlers({
    handleCancel: ({ sgPassword, setPassword }) => () => setPassword(sgPassword),
    handleSubmit: ({ contractNumber, password, handleUpdateMaintenanceInfo, setLoading }) => () =>
        setLoading(
            true,
            () => setSGPassword(contractNumber, password).then(
                () => handleUpdateMaintenanceInfo({
                    originalSGPassword: password,
                    sgPassword: password,
                }, () => setLoading(false)),
                () => setLoading(false),
            )
        ),
});

const PasswordEdit = compose(
    withLoading,
    withPasswordState,
    getProps,
    handlers,
    pure,
)(PasswordEditPure);

PasswordEdit.propTypes = {
    contractNumber: PropTypes.string,
    sgPassword: PropTypes.string,
    handleUpdateMaintenanceInfo: PropTypes.func,
};

export default PasswordEdit;