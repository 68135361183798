import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {compose, pure, withHandlers, withState} from 'recompose';
import withLoading from '../../../hoc/withLoading';
import {SET_CONTRACTS_BUYER} from '../../../schema/ContractSchema';
import {emptyFunction} from '../../../utils/functions';
import {errorMsg} from '../../../utils/Notify';
import JsonSchemaForm from '../../components/Form/JsonSchemaForm';
import LayoutGridField from '../../components/LayoutGridFields';
import {
    contractNumberSchemaField,
    contractNumbersUiSchemaField,
    convertResult,
    formatContractNumbersForSave,
    onFormDataChange,
    validateForm,
    withToucanListOperationResult,
} from '../ContractsHelpers';

const schema = {
    type: 'object',
    properties: {
        contractNumbers: contractNumberSchemaField,
        customerTypologyType: {
            title: 'Type d\'utilisateur*',
            type: 'string',
            enum: ['PART', 'PRO', 'DEMO'],
            enumNames: ['Particulier', 'Pro', 'Démo'],
        },
        address: {
            type: 'object',
            properties: {
                gender: {type: 'string', title: 'Civilité'},
                firstName: {type: 'string', title: 'Prénom'},
                lastName: {type: 'string', title: 'Nom'},
                company: {type: 'string', title: 'Société'},
                addressLine0: {type: 'string', title: 'Adresse'},
                addressLine1: {type: 'string', title: 'Adresse 2'},
                addressLine2: {type: 'string', title: 'Adresse 3'},
                addressLine3: {type: 'string', title: 'Adresse 4'},
                zipCode: {type: 'string', title: 'Code Postal'},
                city: {type: 'string', title: 'Ville'},
                country: {type: 'string', title: 'Pays'},
                email: {type: 'string', title: 'Email'},
                phone: {type: 'string', title: 'Téléphone'},
                cellphone: {type: 'string', title: 'Mobile'},
            },
            required: ['gender', 'firstName', 'lastName', 'addressLine0', 'zipCode', 'city', 'country'],
        },
    },
    required: ['contractNumbers', 'customerTypologyType', 'address'],
};

const uiSchema = {
    contractNumbers: contractNumbersUiSchemaField,
    customerTypologyType: {
        'ui:options': {
            widget: 'radio',
            inline: true,
        },
    },
    'ui:field': 'layout_grid',
    'ui:layout_grid': [{
        'ui:row': [
            {'ui:col': {md: 6, children: ['contractNumbers']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['customerTypologyType']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 4, children: ['address.gender']}},
            {'ui:col': {md: 4, children: ['address.firstName']}},
            {'ui:col': {md: 4, children: ['address.lastName']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['address.company']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['address.addressLine0']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['address.addressLine1']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['address.addressLine2']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 12, children: ['address.addressLine3']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 4, children: ['address.zipCode']}},
            {'ui:col': {md: 4, children: ['address.city']}},
            {'ui:col': {md: 4, children: ['address.country']}},
        ],
    }, {
        'ui:row': [
            {'ui:col': {md: 4, children: ['address.email']}},
            {'ui:col': {md: 4, children: ['address.phone']}},
            {'ui:col': {md: 4, children: ['address.cellphone']}},
        ],
    }],
};

const SetBuyerPure = ({
                          formData,
                          handleFormDataChange,
                          handleSave,
                      }) => (
    <JsonSchemaForm fields={{layout_grid: LayoutGridField}}
                    formData={formData}
                    schema={schema}
                    showErrorList={false}
                    uiSchema={uiSchema}
                    onChange={handleFormDataChange}
                    onSubmit={handleSave}
                    validate={validateForm}>
        <ButtonToolbar className='pull-right'>
            <Button bsStyle='primary'
                    type='submit'>
                Définir l'acheteur
            </Button>
        </ButtonToolbar>
    </JsonSchemaForm>
);

SetBuyerPure.propTypes = {
    formData: PropTypes.object,
    handleFormDataChange: PropTypes.func,
    handleSave: PropTypes.func,
};

const withFormDataState = withState('formData', 'setFormData', {});

const ToucanListOperationErrorReason = {
    ALREADY_SET: {
        message: 'acheteur déjà défini',
        value: 'ALREADY_SET',
    },
    CREATE: {
        message: 'erreur de création',
        value: 'CREATE',
    },
    NOT_FOUND: {
        message: 'le contrat n\'existe pas',
        value: 'NOT_FOUND',
    },
    SAVE: {
        message: 'erreur de sauvegarde',
        value: 'SAVE',
    },
};

const submitSetContractsBuyer = mutate => ({address, contractNumbers, customerTypologyType}, props) => {
    const {setLoading} = props;
    setLoading(
        true,
        () => mutate({
            variables: {
                input: {
                    address,
                    contractNumbers,
                    customerTypologyType,
                },
            },
            update: (store, result) => convertResult(
                result?.data?.contractSetBuyerOnContractList,
                props,
                ToucanListOperationErrorReason,
            ),
        }).then(
            emptyFunction,
            (error) => {
                console.error(error);
                setLoading(false, () => errorMsg('Erreur lors de la sauvegarde de l\'acheteur'));
            },
        ),
    );
};

const withSetContractsBuyerMutation = graphql(SET_CONTRACTS_BUYER, {
    props: ({mutate}) => ({
        handleSetContractsBuyer: submitSetContractsBuyer(mutate),
    }),
});

const handlers = withHandlers({
    handleFormDataChange: onFormDataChange,
    handleSave: props => () => {
        const {formData, handleSetContractsBuyer} = props;
        const contractNumbers = formatContractNumbersForSave(formData.contractNumbers);
        const {addressLine0, addressLine1, addressLine2, addressLine3, ...address} = formData.address;

        handleSetContractsBuyer(
            {
                ...formData,
                address: {
                    ...address,
                    addressLine: [addressLine0, addressLine1, addressLine2, addressLine3].filter(_ => !!_),
                },
                contractNumbers,
            },
            props,
        );
    },
});

const SetBuyer = compose(
    withFormDataState,
    withToucanListOperationResult,
    withLoading,
    withSetContractsBuyerMutation,
    handlers,
    pure,
)(SetBuyerPure);

SetBuyer.propTypes = {};

export default SetBuyer;