import GoogleMapsLoader from 'google-maps';
import { GOOGLE_MAPS_API_KEY } from '../config';

const GoogleMapsAPIFactory = (callback) => {
    GoogleMapsLoader.KEY = GOOGLE_MAPS_API_KEY;
    GoogleMapsLoader.LANGUAGE = 'fr';

    GoogleMapsLoader.load((google) => callback(google));
};

export default GoogleMapsAPIFactory;