import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';

const ReOpenTicketButton = ({closed, hidden, ticketSubject, handleReOpenTicket}) => {

	if (hidden) return null;

	return (
		<Button className='pull-left' onClick={handleReOpenTicket}>
			Réouvrir le ticket
		</Button>
	);
}

ReOpenTicketButton.propTypes = {
	closed: PropTypes.bool,
	hidden: PropTypes.bool,
	ticketSubject: PropTypes.string,
	handleReOpenTicket: PropTypes.func,
};

export default ReOpenTicketButton;