import React, {Component} from 'react';
import {Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Panel, Row, Table} from 'react-bootstrap';
import {compose} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../hoc/withMeAndTeamsAndConstants';
import jsRoutes from '../../../routes/routes';
import {clone, navigateToSIM} from '../../../utils/functions';
import {getSimPlanFromState} from '../../../utils/Sim';
import LoadingProgress from '../../components/LoadingProgress';
import UltimatePagination from '../../components/UltimatePagination';
import CreateSimPanel from '../CreateSimPanel';
import {compareSims, connectSearchSimContext} from './Helpers';
import SimsTableEntry from './SimsTableEntry';

class SimsTable extends Component {

  constructor(props) {
    super(props);
    this.state = this.getComponentInitialState();
  }

  getComponentInitialState() {
    let properties = [
      {
        name: 'ICCID',
        size: '30%',
      }, {
        name: 'Opérateur',
        size: '25%',
      }, {
        name: 'Date Créa',
        type: 'date',
        size: '10%',
      }, {
        name: 'Date État',
        type: 'date',
        size: '10%',
      }, {
        name: 'État',
        type: 'state',
        size: '15%',
      }, {
        name: 'Forfait',
        size: '10%',
      },
    ];
    return ({
      ICCIDS: [],
      loading: false,
      entries: null,
      properties: properties,
      activePage: 1,
      sort: {
        name: 'ICCID',
        reverse: false,
      },
      filters: [],
      sims: [],
    });
  }

  componentDidMount() {
    if (this.props.ICCID && this.state.ICCIDS.length === 0) {
      this.setState({ICCIDS: [this.props.ICCID]});
      this.getEntries([this.props.ICCID]);
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props.ICCID && this.state.ICCIDS.length === 0) {
      this.setState({ICCIDS: [this.props.ICCID]});
      this.getEntries([this.props.ICCID]);
    }
  }

  formatEntries(sim) {
    return {
      'ICCID': sim.ICCID,
      'Opérateur': sim.operator,
      'Date Créa': sim.created,
      'Date État': sim?.currentState?.timestamp || null,
      'État': sim?.currentState?.state || 'N/A',
      'Forfait': getSimPlanFromState(sim?.currentState),
    };
  }

  updateSim(sim) {
    let _this = this;
    const sims = (this.state.sims || [])
      .filter(s => s.ICCID !== sim.ICCID);
    sims.push(sim);

    this.setState({
      entries: sims.map(_ => _this.formatEntries(_)),
      sims: sims,
    });
  }

  async handleManageSim(response) {
    const {setCallback4OperatorEdition, setChangeSIMOperator} = this.props;
    await setCallback4OperatorEdition(null);
    await setChangeSIMOperator('');

    this.setState({
      need_activation: true,
      ICCID: '',
      operator: '',
      phone: '',
      loading: false,
    });
    this.updateSim(response);
  }

  async editOperatorHandler(sim, callback) {
    const {setCallback4OperatorEdition, setChangeSIMOperator} = this.props;
    await setCallback4OperatorEdition(callback);
    await setChangeSIMOperator(sim.operator);
  }

  getEntries(ICCIDS) {
    var _this = this;
    _this.setState({loading: true});
    jsRoutes.controllers.backoffice.SupportV2Controller.getSims()
      .ajax({
          data: JSON.stringify({ICCIDS: ICCIDS}),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          processData: false,
        },
      ).then(function (response, textStatus, jqXHR) {
      _this.setState({
        entries: response.map(sim => _this.formatEntries(sim)),
        sims: response,
        loading: false,
      });
    }, function (jqXHR, textStatus, errorThrown) {
      _this.setState({loading: false});
    });
  }

  onPageSelected(num) {
    this.setState({activePage: num});
  }

  onFilterInputChange(name, event) {
    if (name === '') {
      this.setState({filters: null, activePage: 1});
      return;
    }

    var filters = this.state.filters;
    if (!filters) {
      filters = [];
    }
    if (!event.target.value) {
      delete filters[name];
    } else {
      filters[name] = event.target.value;
    }
    this.setState({filters: filters, activePage: 1});

    if (name === 'ICCID') {
      if (!event.target.value) {
        navigateToSIM('');
      } else {
        navigateToSIM(event.target.value);
      }
    }
  }

  isFiltered(entry) {
    if (!this.state.filters)
      return true;
    for (var property in this.state.filters) {
      if (!entry.hasOwnProperty(property) || !entry[property] || entry[property].toString().toLocaleLowerCase().indexOf(this.state.filters[property].toLocaleLowerCase()) === -1)
        return false;
    }
    return true;
  }

  handleTextChange(propertyName, event) {
    let state = this.state;
    state[propertyName] = event.target.value;
    this.setState(state);
  }

  handleKeyPress(property, target) {
    if (target.keyCode === 13 && (property !== 'ICCIDS' || target.ctrlKey)) {
      this.handleSearch(property, this.state[property]);
    }
  }

  handleICCIDSearch() {
    this.handleSearch('ICCIDS', this.state['ICCIDS']);
  }

  handleSearch(property, value) {
    if (property !== 'ICCIDS') {
      this.getEntries(value);
    } else if (typeof value === 'string' && value) {
      this.getEntries(value.split('\n'));
    }
  }

  handleBooleanChange(propertyName, event) {
    let state = this.state;
    state[propertyName] = event.target.value === 'true';
    this.setState(state);
  }

  handleLoading(isLoading) {
    this.setState({loading: isLoading});
  }

  render() {
    var CRUDEntries;
    var _this = this;
    var clearButton;
    var downloadXLSButton;
    var pageGroup;
    if (this.state.entries) {
      var itemsByPage = 40;
      var filteredEntries = this.state.entries.filter(e => _this.isFiltered(e));

      clearButton = (
        <Button className="center-block" bsStyle="primary" bsSize="small"
                onClick={this.onFilterInputChange.bind(_this, '')}
                disabled={this.state.loading && !!this.state.filters}>
          <Glyphicon glyph="remove"/>&nbsp;Effacer
        </Button>
      );

      downloadXLSButton = (
        <Button className="center-block" bsStyle="primary" bsSize="small"
                href={jsRoutes.controllers.backoffice.SupportV2Controller.getLastLPMFile().url}><Glyphicon
          glyph="file"/>&nbsp;Télécharger le fichier
        </Button>
      );

      if (filteredEntries.length > 0) {
        const pageNumber = Math.ceil(filteredEntries.length / itemsByPage);
        pageGroup = (<UltimatePagination totalPages={pageNumber} currentPage={this.state.activePage}
                                         onChange={this.onPageSelected.bind(this)}/>);
      }

      const entries = filteredEntries
        .sort(compareSims)
        .slice((_this.state.activePage - 1) * itemsByPage, (_this.state.activePage) * itemsByPage)
        .map(entry => {
          const sim = clone(_this.state.sims.filter(_ => _.ICCID === entry.ICCID)[0]);

          return <SimsTableEntry key={entry.ICCID}
                                 properties={_this.state.properties} entry={entry}
                                 sim={sim}
                                 editOperatorHandler={_this.editOperatorHandler.bind(_this, sim)}
                                 handleLoading={_this.handleLoading.bind(_this)}
                                 handleManageSim={_this.handleManageSim.bind(_this)}/>;
        });

      CRUDEntries = (
        <Table striped bordered condensed hover>
          <thead>
          <tr>
            {this.state.properties.map(property =>
              <th style={{
                width: property.size,
              }}
                  key={'property_header_' + property.name}>
                {property.name}&nbsp;
              </th>,
            )}
            <th>{downloadXLSButton}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            {this.state.properties.map(propertie =>
              <th key={'property_filter_' + propertie.name}>
                <FormGroup className="small-control" bsSize="small">
                  <FormControl type="text"
                               value={_this.state.filters && _this.state.filters[propertie.name]
                                 ? _this.state.filters[propertie.name]
                                 : ''}
                               onChange={_this.onFilterInputChange.bind(_this, propertie.name)}/>
                </FormGroup>
              </th>,
            )}
            <th>{clearButton}</th>
          </tr>
          {entries}
          </tbody>
        </Table>

      );
    }

    return (
      <div>
        <CreateSimPanel afterCreateSim={this.updateSim.bind(this)}/>
        <Panel bsStyle="default">
          <Panel.Heading>Chercher</Panel.Heading>
          <Panel.Body>
            <LoadingProgress show={this.state.loading}/>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <ControlLabel>ICCID(s)</ControlLabel>
                  <FormControl componentClass="textarea"
                               type="text" value={this.state.SN}
                               onKeyDown={this.handleKeyPress.bind(this, 'ICCIDS')}
                               onChange={this.handleTextChange.bind(this, 'ICCIDS')}/>
                  <Button bsSize="xsmall" onClick={this.handleICCIDSearch.bind(this)}><Glyphicon
                    glyph="search"/></Button>
                </FormGroup>
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
        <Panel bsStyle="info">
          <Panel.Heading>SIMs</Panel.Heading>
          <Panel.Body>
            <div>
              {CRUDEntries}
              <div className="text-center">
                {pageGroup}
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

export default compose(
  connectMeAndTeamsAndConstantsContext,
  connectSearchSimContext,
)(SimsTable);