import PropTypes from 'prop-types';
import Steps, {Step} from 'rc-steps';
import 'rc-steps/assets/iconfont.css';
import 'rc-steps/assets/index.css';
import React from 'react';
import {compose, pure} from 'recompose';

const StepperPure = ({current, steps}) => (
    <Steps current={current}
           labelPlacement='vertical'>
        {
            steps.map(({description, title}, index) =>
                <Step description={description}
                      key={index}
                      title={title}/>)
        }
    </Steps>
);

StepperPure.propTypes = {
    current: PropTypes.number,
    steps: PropTypes.array,
};

const Stepper = compose(
    pure,
)(StepperPure);

Stepper.propTypes = {
    current: PropTypes.number,
    steps: PropTypes.array,
};

export default Stepper;