import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import { tsToDateForInput } from '../../utils/Date';
import TicketState from '../components/TicketState';

class SearchTabEntry extends Component {
    render() {
        var _this = this;
        var fields = this.props.properties.map(prop => {
            var value = _this.props.entry[prop.name];
            if (prop.type === 'date' && value ) {
                value = tsToDateForInput(value);
            }
            if (prop.type === 'state') {
                value = <TicketState value={value}/>
            }
            return <td key={_this.props.entry._id + '_' + prop.name}>{value}</td>;
        });
        return (
            <tr>
                {fields}
                <td>
                    <Button className='center-block' onClick={_this.props.onEntryClick}>Voir</Button>
                    <Button className='btn btn-default btn-sm' onClick={_this.props.onViewNewPageClick}>
                        <span className='glyphicon glyphicon-plus' aria-hidden='true'/> Nouvel onglet
                    </Button>

                </td>
            </tr>
        );
    }
}

export default SearchTabEntry;