import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup, Radio} from 'react-bootstrap';
import {branch, compose, getContext, pure, renderComponent, withHandlers, withProps, withState} from 'recompose';

import {getMidday, getNextMonthFirstDayMidday, getTodayMidday, toFrenchDateString} from '../../../../../../../utils/Date';
import {
  canSwitchSubscriptionTypeFreely,
  canSwitchSubscriptionTypeImmediately,
  isPrepayedOrDemo,
} from '../../../../../../../utils/Subscription';
import SingleDatePicker from '../../../../../../components/DatePicker/SingleDatePicker';

import './DateBeginField.css';

const DateBeginFreeFieldPure = ({currentEditedSubscriptionState, disabled, subscription, subscriptionState, setSubscriptionState}) => {
  const params = {
    disabled,
    readOnly: true,
    isOutsideRange: (date) => {
      if(
        currentEditedSubscriptionState > -1 &&
        subscription?.metadata?.subscriptionStates?.length > currentEditedSubscriptionState + 1
      ) {
        const previousSubscription = subscription?.metadata?.subscriptionStates?.[currentEditedSubscriptionState + 1]

        return moment(date).isBefore(moment(previousSubscription.dateEnd));
      }

      const currentSubscriptionStateDateEnd = moment(subscription?.metadata?.subscriptionStates?.[0]?.dateEnd);
      return moment(date).isBefore(moment(currentSubscriptionStateDateEnd));
    },
  };

  return (
    <FormGroup className="small-control"
               bsSize="small">
      <ControlLabel>Début</ControlLabel>
      <SingleDatePicker schema={{params}}
                        value={subscriptionState.dateBegin}
                        onChange={d => setSubscriptionState({...subscriptionState, dateBegin: d})}/>
    </FormGroup>
  );
}

DateBeginFreeFieldPure.propTypes = {
  disabled: PropTypes.bool,
  subscriptionState: PropTypes.object,
  setSubscriptionState: PropTypes.func,
};

const DateBeginRadioFieldPure = ({
                                   dateBeginType,
                                   dateBeginFree,
                                   disabled,
                                   hasImmediateDate,
                                   hasFreeDate,
                                   nextMonthFirstDayLabel,
                                   handleDateBeginChange,
                                   handleDateBeginFreeChange,
                                 }) => (
  <FormGroup className="small-control"
             bsSize="small">
    <ControlLabel>Début</ControlLabel>

    {hasImmediateDate &&
    <Radio checked={dateBeginType === DATE_BEGIN_TYPE.IMMEDIATE}
           disabled={disabled}
           name="dateBegin"
           onChange={() => handleDateBeginChange(DATE_BEGIN_TYPE.IMMEDIATE)}>Immédiat</Radio>
    }

    <Radio checked={dateBeginType === DATE_BEGIN_TYPE.TERM}
           disabled={disabled}
           name="dateBegin"
           onChange={() => handleDateBeginChange(DATE_BEGIN_TYPE.TERM)}>{nextMonthFirstDayLabel}</Radio>

    {hasFreeDate &&
    <Radio checked={dateBeginType === DATE_BEGIN_TYPE.FREE}
           disabled={disabled}
           name="dateBegin"
           onChange={() => handleDateBeginChange(DATE_BEGIN_TYPE.FREE)}>
      <SingleDatePicker schema={{
        params: {
          disabled: disabled || dateBeginType !== DATE_BEGIN_TYPE.FREE,
          readOnly: true,
        },
      }}
                        value={dateBeginFree}
                        onChange={handleDateBeginFreeChange}/>
    </Radio>}
  </FormGroup>
);

DateBeginRadioFieldPure.propTypes = {
  handleDateBeginChange: PropTypes.func,
  handleDateBeginFreeChange: PropTypes.func,
};

const withCalculatedProps = withProps(
  ({subscription, subscriptionState}) => {
    const today = getTodayMidday();
    const nextMonthFirstDay = getNextMonthFirstDayMidday(today).valueOf();

    return {
      hasImmediateDate: canSwitchSubscriptionTypeImmediately(subscription, subscriptionState),
      hasFreeDate: canSwitchSubscriptionTypeFreely(subscription, subscriptionState),
      nextMonthFirstDay,
      nextMonthFirstDayLabel: `À l'échéance (${toFrenchDateString(nextMonthFirstDay)})`,
      today: today.valueOf(),
    };
  },
);

const DATE_BEGIN_TYPE = {IMMEDIATE: 1, TERM: 2, FREE: 3};

const withDateBeginState = withState('dateBeginType', 'setDateBeginType', ({
                                                                             nextMonthFirstDay,
                                                                             subscriptionState,
                                                                             today,
                                                                           }) => {
  if (subscriptionState?.dateBegin) {
    const dateBegin = getMidday(subscriptionState?.dateBegin).valueOf();

    if (dateBegin === today) {
      return DATE_BEGIN_TYPE.IMMEDIATE;
    } else if (dateBegin === nextMonthFirstDay) {
      return DATE_BEGIN_TYPE.TERM;
    } else {
      return DATE_BEGIN_TYPE.FREE;
    }
  }
});
const withDateBeginFreeState = withState('dateBeginFree', 'setDateBeginFree', ({dateBeginType, subscriptionState}) => {
  if (dateBeginType === DATE_BEGIN_TYPE.FREE) {
    return subscriptionState?.dateBegin;
  }

  return null;
});

const handlers = withHandlers({
  handleDateBeginChange: ({
                            dateBeginFree,
                            nextMonthFirstDay,
                            subscriptionState,
                            today,
                            setDateBeginType,
                            setIsImmediateDate,
                            setSubscriptionState,
                          }) => dateBeginType => {
    setDateBeginType(
      dateBeginType,
      () => {
        let dateBegin;
        switch (dateBeginType) {
          case DATE_BEGIN_TYPE.IMMEDIATE:
            dateBegin = today;
            break;
          case DATE_BEGIN_TYPE.TERM:
            dateBegin = nextMonthFirstDay;
            break;
          case DATE_BEGIN_TYPE.FREE:
            dateBegin = dateBeginFree;
            break;
          default:
        }

        setIsImmediateDate(
          dateBeginType === DATE_BEGIN_TYPE.IMMEDIATE,
          () => setSubscriptionState({...subscriptionState, dateBegin}),
        );
      },
    );
  },
  handleDateBeginFreeChange: ({subscriptionState, setDateBeginFree, setSubscriptionState}) => dateBeginFree =>
    setDateBeginFree(
      dateBeginFree,
      () => setSubscriptionState({...subscriptionState, dateBegin: dateBeginFree}),
    ),
});

const DateBeginField = compose(
  getContext(
    {
      currentEditedSubscriptionState: PropTypes.number,
      subscription: PropTypes.object,
      subscriptionState: PropTypes.object,
    },
  ),
  branch(
    ({subscriptionState}) => isPrepayedOrDemo(subscriptionState.type),
    renderComponent(DateBeginFreeFieldPure),
  ),
  withCalculatedProps,
  withDateBeginState,
  withDateBeginFreeState,
  handlers,
  pure,
)(DateBeginRadioFieldPure);

DateBeginField.propTypes = {
  disabled: PropTypes.bool,
  subscription: PropTypes.object,
  setIsImmediateDate: PropTypes.func,
  setSubscriptionState: PropTypes.func,
};

export default DateBeginField;