import React, { useEffect, useState } from 'react';
import { Button, FormControl, Glyphicon, ListGroupItem } from 'react-bootstrap';

import jsRoutes from '../../../../../routes/routes';
import {tsToDateForInput} from '../../../../../utils/Date';

const TicketAnswersTableRow = ({ properties, answer: answerProp = {}, ticketClosed, handleSaveAnswer }) => {
	const [answer, setAnswer] = useState(answerProp);
	const [editing, setEdititing] = useState(false);
	
	useEffect(() => {
		setAnswer(answerProp);
	}, [answerProp]);

	const handleEdit = () => {
		if (editing) {
			const newAnswer = {...answerProp._answer, content: answer['Réponse']};
			handleSaveAnswer(newAnswer);
			setEdititing(false);
		} else {
			setEdititing(true);
		}
	}

	const handleContentChange = event => {
		const content = event.target.value;
		setAnswer(answer => ({...answer, 'Réponse': content}));
	}

	let automaticAnswer = answer._answer && answer._answer.type  && answer._answer.type !== 'DEFAULT';

	const fields = properties.map(({name, type, ...property}) => {

		let value = answer[name];

		if (type === 'date') {
			value = tsToDateForInput(value);
		}

		if (type === 'content' && editing) {
			return (
				<td key={answer._id + '_' + name}>
					<FormControl
						componentClass='textarea'
						value={value}
						placeholder='Réponse'
						onChange={handleContentChange}
					/>
				</td>
			);
		}

		if (type === 'attachment') {
			if (value !== undefined && value.length > 0) {
				return (
					<td key={answer._id + '_' + name}>
						<ListGroupItem href={jsRoutes.controllers.backoffice.SupportV2Controller.getAttachment(value[0].path).url}>
							<Glyphicon glyph='file'/>&nbsp;{value[0].name}
						</ListGroupItem>
					</td>
				);
			} else {
				return <td key={answer._id + '_' + property.name} />;
			}
		}
		
		if (automaticAnswer) {
			value = <em className='text-muted'>{value}</em>;
		}

		return <td key={answer._id + '_' + name}>{value}</td>;
	});

	return (
		<tr>
			{fields}
			<td>
				<Button bsStyle={editing ? 'success' : 'default'} disabled={ticketClosed || automaticAnswer} className='center-block' onClick={handleEdit}>
					{editing ? 'Sauver' : 'Modifier'}
				</Button>
			</td>
		</tr>
	);
}

export default TicketAnswersTableRow;