const formatCustomerInput = customer => {
    let input = {};
    const fields = ['addressLine', 'cellphone', 'city', 'company', 'country', 'customerTypologyType', 'email', 'firstName', 'gender', 'isAnUpdateOfCurrentCustomer', 'lastName', 'phone', 'zipCode'];

    Object.keys(customer)
        .forEach(key => {
            const value = customer[key];

            if(fields.includes(key)) {
                if (value === undefined) {
                    if (['gender', 'firstName', 'lastName', 'zipCode', 'city', 'country', 'email', 'phone'].includes(key)) {
                        input[key] =  '';
                    }

                    if (key === 'addressLine') {
                        input[key] =  [];
                    }
                } else {
                    input[key] = value;
                }
            }
        });

    return input;
};

const saveCustomer = (customerInput, id, {contractNumber, subscription, createCustomer, setLoading, updateCustomer}, successCallback, errorCallback) => {
    const callback = response => setLoading(false, () => successCallback(response));
    const {isAnUpdateOfCurrentCustomer, ...input} = formatCustomerInput(customerInput);
    const createOrUpdateCustommer = isAnUpdateOfCurrentCustomer === false ?
        () => createCustomer(contractNumber, input, callback) :
        () => updateCustomer(input, id, callback);

    setLoading(
        true,
        createOrUpdateCustommer
    );
};

export default saveCustomer;