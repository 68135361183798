import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {branch, compose, pure, renderComponent} from 'recompose';
import {SIM_STATES} from '../../../utils/Sim';
import SetUnknownButton from './ActionButtons/SetUnknownButton';
import ActivatedButtonGroup from './ActivatedButtonGroup';
import ActivatingButtonGroup from './ActivatingButtonGroup';
import ChangePlanButtonGroup from './ChangePlanButtonGroup';
import NeedActivationButtonGroup from './NeedActivationButtonGroup';
import NeedTerminationButtonGroup from './NeedTerminationButtonGroup';
import NotActivatedButtonGroup from './NotActivatedButtonGroup';
import TerminatingButtonGroup from './TerminatingButtonGroup';
import UnknownButtonGroup from './UnknownButtonGroup';

const ActionButtonsPure = ({
                             displayVertical,
                             onUnknownClick,
                           }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <SetUnknownButton onClick={onUnknownClick}/>
  </ButtonGroup>
);

ActionButtonsPure.propTypes = {
  displayVertical: PropTypes.bool,
  onUnknownClick: PropTypes.func,
};

const ActionButtons = compose(
  branch(
    ({simState}) => simState === SIM_STATES.UNKNOWN.value,
    renderComponent(UnknownButtonGroup),
  ),
  branch(
    ({simState}) => simState === SIM_STATES.TERMINATING.value,
    renderComponent(TerminatingButtonGroup),
  ),
  branch(
    ({simState}) => simState === SIM_STATES.ACTIVATING.value,
    renderComponent(ActivatingButtonGroup),
  ),
  branch(
    ({simState}) => simState === SIM_STATES.NEED_TERMINATION.value,
    renderComponent(NeedTerminationButtonGroup),
  ),
  branch(
    ({simState}) => simState === SIM_STATES.NEED_ACTIVATION.value,
    renderComponent(NeedActivationButtonGroup),
  ),
  branch(
    ({simState}) => simState === SIM_STATES.NOT_ACTIVATED.value,
    renderComponent(NotActivatedButtonGroup),
  ),
  branch(
    ({simState}) => simState === SIM_STATES.ACTIVATED.value,
    renderComponent(ActivatedButtonGroup),
  ),
  branch(
    ({simState}) => [
      SIM_STATES.NEED_DOWNGRADE.value,
      SIM_STATES.NEED_UPGRADE.value,
    ].includes(simState),
    renderComponent(ChangePlanButtonGroup),
  ),
  pure,
)(ActionButtonsPure);

ActionButtons.propTypes = {};

export default ActionButtons;