import React from 'react';
import PropTypes from 'prop-types';
import SubscriptionBlock from './Subscription/SubscriptionBlock';

const Subscription = ({ contractNumber }) => <SubscriptionBlock contractNumber={contractNumber}/>;

Subscription.propTypes = {
    contractNumber: PropTypes.string,
};

export default Subscription;