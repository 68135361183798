import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';

const TagsList = ({ tags }) => tags.map(tag =>
	<span key={tag.value}>
		<Label className='label-block'>{tag.value}</Label>
		<br />
	</span>
);

TagsList.propTypes = {
	tags: PropTypes.array,
};

export default TagsList;