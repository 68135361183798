import PropTypes from 'prop-types';
import React from 'react';
import {Col, Nav, NavItem, Row} from 'react-bootstrap';
import {compose, pure, withProps, withState} from 'recompose';

const NavigableViewPure = ({
                              activeKey,
                              componentProps,
                             conf,
                              SelectedComponent,
                              setActiveKey,
                            }) => (
  <Row>
    <Col md={2}>
      <Nav activeKey={activeKey}
           bsStyle='pills'
           stacked
           onSelect={key => setActiveKey(key)}>
        {conf.map(
          (action, index) =>
            <NavItem eventKey={index}
                     key={index}>
              {action.label}
            </NavItem>,
        )}
      </Nav>
    </Col>
    <Col md={10}>
      <SelectedComponent {...componentProps}/>
    </Col>
  </Row>
);

NavigableViewPure.propTypes = {
  activeKey: PropTypes.number,
  componentProps: PropTypes.object,
  SelectedComponent: PropTypes.func,
  setActiveKey: PropTypes.func,
};

const withActiveKeyState = withState('activeKey', 'setActiveKey', 0);

const withCalculatedProps = withProps(
  ({activeKey, conf}) => ({
    componentProps: conf[activeKey]?.props || {},
    SelectedComponent: conf[activeKey]?.component,
  }),
);

const NavigableView = compose(
  withActiveKeyState,
  withCalculatedProps,
  pure,
)(NavigableViewPure);

NavigableView.propTypes = {
  conf: PropTypes.array,
};

export default NavigableView;