import PropTypes from 'prop-types';
import React from 'react';
import MaintenanceTable from './MaintenanceTable';
import './MaintenanceGroup.css';

const MaintenanceGroup = ({ group, metrics }) => (
  <div className="maintenance-group">
    <div className="container">
      <div className="title">{group}</div>
      <MaintenanceTable metrics={metrics}/>
    </div>
  </div>
);

MaintenanceGroup.propTypes = {
  group: PropTypes.string,
  metrics: PropTypes.array,
};

export default MaintenanceGroup;