import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import ContractInfo from '../../../contract/CustomerContract/ContractDetails/ContractInfo';

const TicketContractPure = ({contract}) => (
    <>
        <FormGroup>
            <ControlLabel>Informations contrat</ControlLabel>
            <ContractInfo contract={contract}
                          modal/>
        </FormGroup>
        <hr/>
    </>
);

TicketContractPure.propTypes = {
    contract: PropTypes.object,
};

const TicketContract = compose(
    branch(
        ({contractNumber}) => contractNumber === '00000000',
        renderNothing,
    ),
    pure,
)(TicketContractPure);

TicketContract.propTypes = {
    contract: PropTypes.object,
    contractNumber: PropTypes.string,
};

export default TicketContract;