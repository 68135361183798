import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormGroup, Radio} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import { SUBSCRIPTION_TYPE_GROUPS } from '../../../../../../../../utils/Subscription';
import {hasOptions} from './SubscriptionTypeSelectorHelpers';

const getFormattedLabelForOption = (option) => {
  switch (option) {
    case SUBSCRIPTION_TYPE_GROUPS.RENOUV:
      return 'CLASSIQUE_FIDELITE (anciennement RENOUV)';
    case '':
    case null:
    case undefined:
      return 'Sans';
    default:
      return option;
  }
};

const FilterPure = ({filterValue, label, name, options, onFilterChange}) => (
  <>
    <ControlLabel>{label}</ControlLabel>
    <FormGroup>
      {options.map(
        (option, index) =>
          <Radio checked={filterValue.value === option}
                 data-test-id={`radio-button-${name}-${index}`}
                 inline
                 key={index}
                 name={name}
                 onChange={() => onFilterChange(option)}>
            {getFormattedLabelForOption(option)}
          </Radio>,
      )}
    </FormGroup>
  </>
);

const Filter = compose(
  branch(
    ({filterValue, options}) => !hasOptions(options) || !filterValue.needToBeFilled,
    renderNothing,
  ),
  pure,
)(FilterPure);

Filter.propTypes = {
  filterValue: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onFilterChange: PropTypes.func,
};

export default Filter;