import {TICKET_SUBJECTS} from './constants';

const RETURN_TYPES = {
    AFTER_SALE_SERVICE: {label: 'SAV ', value: 'AFTER_SALE_SERVICE'},
    DISPATCHED_SATISFIED_OR_REFUNDED: {label: 'SR Dispatché', value: 'DISPATCHED_SATISFIED_OR_REFUNDED'},
    LEASING: {label: 'LOC', value: 'LEASING'},
    SATISFIED_OR_REFUNDED: {label: 'SR', value: 'SATISFIED_OR_REFUNDED'},
    WITHOUT_REFUND: {label: 'SANS REMB', value: 'WITHOUT_REFUND'},
};

const AFTERSALE_REASONS = {
    A_DEFINIR: { label: 'A DÉFINIR', value: 'A_DEFINIR' },
    BREAK: { label: 'CASSE', value: 'BREAK' },
    CAMERA: { label: 'APPAREIL PHOTO', value: 'CAMERA' },
    CRASH_APP_ARDOIZ: { label: 'CRASH APPLI. ARDOIZ', value: 'CRASH_APP_ARDOIZ' },
    CRASH_SYS_ANDROID: { label: 'CRASH SYS. ANDROID', value: 'CRASH_SYS_ANDROID' },
    MICRO: { label: 'MICRO', value: 'MICRO' },
    NO_SIGNAL_SIM: { label: 'PAS DE SIGNAL SIM', value: 'NO_SIGNAL_SIM' },
    OTHER: { label: 'AUTRE', value: 'OTHER' },
    SCREEN: { label: 'ÉCRAN ', value: 'SCREEN' },
    SPEAKER: { label: 'HAUT-PARLEUR', value: 'SPEAKER' },
    TAB_REPLACEMENT: { label: 'REMPLACEMENT TABLETTE', value: 'TAB_REPLACEMENT' },
    TAB_WONT_CHARGE: { label: 'TAB. NE CHARGE PAS', value: 'TAB_WONT_CHARGE' },
    TAB_WONT_START: { label: 'TAB. NE DÉMARRE PAS', value: 'TAB_WONT_START' },
    TACTILE: { label: 'TACTILE', value: 'TACTILE' },
    UNDETECTED_SIM: { label: 'PAS DE DÉTECTION SIM', value: 'UNDETECTED_SIM' },
    WIFI: { label: 'PB WIFI', value: 'WIFI' },
};

const AFTERSALE_SERVICE_REASONS = {
    BREAK: { label: 'CASSE', value: 'BREAK' },
    CAMERA: { label: 'APPAREIL PHOTO', value: 'CAMERA' },
    CRASH_APP_ARDOIZ: { label: 'CRASH APPLI. ARDOIZ', value: 'CRASH_APP_ARDOIZ' },
    CRASH_SYS_ANDROID: { label: 'CRASH SYS. ANDROID', value: 'CRASH_SYS_ANDROID' },
    MICRO: { label: 'MICRO', value: 'MICRO' },
    NO_SIGNAL_SIM: { label: 'PAS DE SIGNAL SIM', value: 'NO_SIGNAL_SIM' },
    OTHER: { label: 'AUTRE', value: 'OTHER' },
    SCREEN: { label: 'ÉCRAN ', value: 'SCREEN' },
    SPEAKER: { label: 'HAUT-PARLEUR', value: 'SPEAKER' },
    TAB_REPLACEMENT: { label: 'REMPLACEMENT TABLETTE', value: 'TAB_REPLACEMENT' },
    TAB_WONT_CHARGE: { label: 'TAB. NE CHARGE PAS', value: 'TAB_WONT_CHARGE' },
    TAB_WONT_START: { label: 'TAB. NE DÉMARRE PAS', value: 'TAB_WONT_START' },
    TACTILE: { label: 'TACTILE', value: 'TACTILE' },
    UNDETECTED_SIM: { label: 'PAS DE DÉTECTION SIM', value: 'UNDETECTED_SIM' },
    WIFI: { label: 'PB WIFI', value: 'WIFI' },
};

const SATISFIED_OR_REFUNDED_REASONS = {
    A_DEFINIR: {label: 'A DÉFINIR', value: 'A_DEFINIR'},
};

const PRODUCT_STATES = [
    { label: 'NEUF ', value: 'NEW' },
    { label: 'RECO ', value: 'RECONDITIONED' },
    { label: 'LOC ', value: 'LEASED' },
];

const ADDRESS_TYPES = {
    USER: { label: 'Utilisateur', value: 'USER' },
    BUYER: { label: 'Acheteur', value: 'BUYER' },
    SUBSCRIBER: { label: 'Souscripteur', value: 'SUBSCRIBER' },
    WITHOUT_SEND_BACK: { label: 'Sans renvoi', value: 'WITHOUT_SEND_BACK' },
    OTHER: { label: 'Autre', value: 'OTHER' },
};

const AFTERSALE_PROVIDERS = [
    { label: 'Let Me Repair', value: 'LET_ME_REPAIR' },
    { label: 'Assureur', value: 'INSURER' },
    { label: 'Tikeasy', value: 'TIKEASY' },
];

const AFTERSALE_STATUS = {
    ABANDON_PRODUIT: { label: 'Abandon produit', value: 'ABANDON_PRODUIT' },
    DEVIS_ABANDON_PRODUIT: { label: 'Devis refusé (abandon produit)', value: 'DEVIS_ABANDON_PRODUIT' },
    DEVIS_ACCEPTE: { label: 'Devis accepté', value: 'DEVIS_ACCEPTE' },
    DEVIS_A_FAIRE: { label: 'Devis à faire', value: 'DEVIS_A_FAIRE' },
    DEVIS_EXPEDIE: { label: 'Devis expédié', value: 'DEVIS_EXPEDIE' },
    DEVIS_REFUSE: { label: 'Devis refusé', value: 'DEVIS_REFUSE' },
    DIAGNOSTIC_EN_COURS: { label: 'Diagnostic en cours', value: 'DIAGNOSTIC_EN_COURS' },
    EMBALLAGE: { label: 'Emballage', value: 'EMBALLAGE' },
    EN_ATTENTE_DE_RETOUR: { label: 'En attente de retour', value: 'EN_ATTENTE_DE_RETOUR' },
    EN_ATTENTE_PIECES_DETACHEES: { label: 'En attente de pièces détachées', value: 'EN_ATTENTE_PIECES_DETACHEES' },
    FINALISATION_DES_TESTS: { label: 'Finalisation des tests', value: 'FINALISATION_DES_TESTS' },
    RENVOI_PRODUIT_KO: { label: 'Renvoi produit ko', value: 'RENVOI_PRODUIT_KO' },
    RENVOI_PRODUIT_OK: { label: 'Renvoi produit ok', value: 'RENVOI_PRODUIT_OK' },
    RETOUR_ANNULE: { label: 'Retour annulé', value: 'RETOUR_ANNULE' },
    TABLETTE_EN_COURS_D_EXPEDITION: { label: 'Tablette en cours d\'expédition', value: 'TABLETTE_EN_COURS_D_EXPEDITION' },
    TABLETTE_EXPEDIEE: { label: 'Tablette expediée', value: 'TABLETTE_EXPEDIEE' },
    TABLETTE_LIVREE: { label: 'Tablette livrée', value: 'TABLETTE_LIVREE' },
    TABLETTE_RECUE: { label: 'Tablette reçue', value: 'TABLETTE_RECUE' },
};

const PRODUCT_TYPE = [{
    label: 'LS17',
    value: 'LS17',
    prefixes: ['LS17'],
},{
    label: 'TA01',
    value: 'TA01',
    prefixes: ['TA01'],
},{
    label: 'TA02',
    value: 'TA02',
    prefixes: ['TA02'],
}];

const QUOTE_STATUS = {
    DEVIS_ACCEPTE: { label: 'Devis accepté', value: 'DEVIS_ACCEPTE' },
    DEVIS_EXPEDIE: { label: 'Devis expedié', value: 'DEVIS_EXPEDIE' },
    DEVIS_REFUSE: { label: 'Devis refusé', value: 'DEVIS_REFUSE' },
    DEVIS_ABANDON_PRODUIT: { label: 'Devis refusé (abandon produit)', value: 'DEVIS_ABANDON_PRODUIT' },
};

const isAfterSale = subject =>
    subject === TICKET_SUBJECTS.SAV ||
    subject === TICKET_SUBJECTS.RETOUR_TABLETTE ||
    subject === TICKET_SUBJECTS.SATISFAIT_REMBOURSE;

const getModelFromSn = sn => {
    const model = PRODUCT_TYPE.find(
        model =>
            !!model.prefixes.find(prefix => sn.startsWith(prefix))
    );

    return model ? model.label : '';
};

const getOrderNumberFromAfterSaleId = id => id.split('::').pop();

export {
    RETURN_TYPES,
    AFTERSALE_REASONS,
    AFTERSALE_SERVICE_REASONS,
    SATISFIED_OR_REFUNDED_REASONS,
    PRODUCT_STATES,
    ADDRESS_TYPES,
    AFTERSALE_PROVIDERS,
    AFTERSALE_STATUS,
    PRODUCT_TYPE,
    QUOTE_STATUS,
    isAfterSale,
    getModelFromSn,
    getOrderNumberFromAfterSaleId,
};