import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, Glyphicon, Modal, Panel, Tab, Tabs} from 'react-bootstrap';
import {branch, compose, lifecycle, pure, renderNothing, withProps, withState} from 'recompose';

import jsRoutes from '../../../../../../routes/routes';
import {GET_CURRENT_SUBSCRIBER, GET_CUSTOMER} from '../../../../../../schema/CustomerSchema';
import {CONTRACT_DETACHED, CUSTOMER_TYPOLOGY_TYPES, TICKET_STATES, TICKET_SUBJECTS} from '../../../../../../utils/constants';
import {SUBSCRIPTION_TYPES} from '../../../../../../utils/Subscription';
import SepaForm from './SepaForm';
import SubscriberForm from './SubscriberForm';

const EditSepaModalPure = ({initialSubscriber, isEditing, isSEPAEditable, subscriber, subscriptionState, useSmallButton, setIsEditing, setSubscriber}) => (
    <>
        <Button bsStyle='primary'
                bsSize={useSmallButton && 'xsmall'}
                onClick={() => setIsEditing(true)}>
            {useSmallButton ?
                <>
                    <Glyphicon glyph='pencil'/>
                    <Glyphicon glyph='user'/>
                </> :
                'SAISIE RIB'
            }
        </Button>
        <Modal bsSize='lg'
               show={isEditing}
               onHide={() => setIsEditing(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Proabono</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey={1} id='sepa_tabs'>
                    <Tab eventKey={1} title='Souscripteur'>
                        <Panel>
                            <Panel.Body>
                                <SubscriberForm subscriber={subscriber || initialSubscriber}
                                                setSubscriber={setSubscriber}/>
                            </Panel.Body>
                        </Panel>
                    </Tab>

                    {subscriptionState?.hasToBeSyncInProAbono &&
                    <Tab eventKey={2} title='SEPA'>
                        <Panel>
                            <Panel.Body>
                                <SepaForm isSEPAEditable={isSEPAEditable}
                                          subscriber={subscriber || initialSubscriber}/>
                            </Panel.Body>
                        </Panel>
                    </Tab>
                    }
                </Tabs>
            </Modal.Body>
        </Modal>
    </>
);

EditSepaModalPure.propTypes = {
    initialSubscriber: PropTypes.object,
    isEditing: PropTypes.bool,
    subscriber: PropTypes.object,
    subscriptionState: PropTypes.object,
    useSmallButton: PropTypes.bool,
    setIsEditing: PropTypes.func,
    setSubscriber: PropTypes.func,
};

const withIsEditingState = withState('isEditing', 'setIsEditing', false);
const withSubscriberState = withState('subscriber', 'setSubscriber', ({ initialSubscriber }) => initialSubscriber);
const withSubscriptionStateState = withState('subscriptionState', 'setSubscriptionState', ({subscriptionState}) => subscriptionState);

const withCurrentCustomerData = graphql(GET_CURRENT_SUBSCRIBER, {
    options: ({contractNumber}) => ({
        variables: {
            input: {
                contractNumber,
            },
        },
    }),
    skip: ({subscriptionState, contractNumber}) => !!subscriptionState || !contractNumber || contractNumber === CONTRACT_DETACHED,
});

const withCustomerDataFromID = graphql(GET_CUSTOMER, {
    options: ({subscriptionState}) => ({
        variables: {
            id: subscriptionState.subscriberCustomerID,
        },
    }),
    skip: ({subscriptionState}) => !subscriptionState?.subscriberCustomerID,
});

const withCalculatedProps = withProps(
    ({data}) => {
		const subscriber = data?.customer?.customer || data?.currentSubscriber?.subscriber;
		return {
            initialSubscriber: subscriber,
        };
	},
);

const withLifecycle = lifecycle({
    componentDidMount() {
        if(!this.props.subscriptionState && this.props.contractNumber && this.props.contractNumber !== CONTRACT_DETACHED) {
            const _this = this;
            jsRoutes.controllers.backoffice.SupportV2Controller.getSubscription(_this.props.contractNumber).ajax({
                dataType: 'json',
                processData: false,
            }).then(
                response =>
                    this.props.setSubscriptionState(response?.metadata?.subscriptionStates?.[0])
            );
        }
    },
});

const hideEditSepaModal = ({initialSubscriber, subscriptionState, ticket}) =>
    !initialSubscriber ||
    initialSubscriber.customerTypology !== CUSTOMER_TYPOLOGY_TYPES.PART ||
    !subscriptionState?.type ||
    [
        SUBSCRIPTION_TYPES.DEMO,
        SUBSCRIPTION_TYPES.PREPAYE_3G,
        SUBSCRIPTION_TYPES.PREPAYE_SIM_CASSE,
        SUBSCRIPTION_TYPES.PREPAYE_WIFI,
        SUBSCRIPTION_TYPES.PREPAYE_WIFI_CASSE,
    ].includes(subscriptionState?.type) ||
    (ticket && (ticket?.subject !== TICKET_SUBJECTS.CONTRAT || ticket?.state === TICKET_STATES.DONE)) ||
    !initialSubscriber?.hasToBeSyncInProAbono;

const EditSepaModal = compose(
    withCurrentCustomerData,
    withCustomerDataFromID,
    withCalculatedProps,
    withSubscriptionStateState,
    withLifecycle,
    withSubscriberState,
    branch(
        hideEditSepaModal,
        renderNothing,
    ),
    withIsEditingState,
    pure,
)(EditSepaModalPure);

EditSepaModal.propTypes = {
    isSEPAEditable: PropTypes.bool,
    subscriptionState: PropTypes.object,
    ticket: PropTypes.object,
    useSmallButton: PropTypes.bool,
};

export default EditSepaModal;