import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, lifecycle, pure, withHandlers, withState} from 'recompose';
import {setMaintenance} from '../../AccountMaintenance/AccountMaintenances/AccountMaintenanceEdit';
import { fetchMaintenances } from '../../../../../../utils/requests';

const CreateResetUpdateDeviceMaintenanceButtonPure = ({
                                                  disabled,
                                                  handleCreateResetUpdateMaintenance,
                                                }) => (
  <Button bsSize='xsmall'
          bsStyle='info'
          disabled={disabled}
          onClick={handleCreateResetUpdateMaintenance}>
    Créer une maintenance de suppression du répertoire updatev2
  </Button>
);

CreateResetUpdateDeviceMaintenanceButtonPure.propTypes = {
  disabled: PropTypes.bool,
  handleCreateResetUpdateMaintenance: PropTypes.func,
};

const withMaintenanceInfoState = withState('maintenanceInfo', 'setMaintenanceInfo', {});

const withLifecycle = lifecycle({
  componentDidMount() {
    const {setMaintenanceInfo, setLoading} = this.props;

    fetchMaintenances(this.props.contractNumber).then(
      (response) =>
        setMaintenanceInfo(
          response,
          () => setLoading(false),
        ),
      () => setLoading(false),
    );
  },
});

const createResetUpdateMaintenance = withHandlers({
  handleCreateResetUpdateMaintenance: ({
                                         contractNumber,
                                         maintenanceInfo,
                                       }) => () => {
    setMaintenance({
      contractNumber,
      formData: {
        type: 'DELETE_DIRECTORY',
        targetFilePath: 'tooti/system/updatev2',
      },
      maintenanceInfo,
    });
    alert('Vous avez bien relancé la dernière MAJ, invitez à patienter 24/48H')
  },
});

const CreateResetUpdateDeviceMaintenanceButton = compose(
  withMaintenanceInfoState,
  withLifecycle,
  createResetUpdateMaintenance,
  pure,
)(CreateResetUpdateDeviceMaintenanceButtonPure);

CreateResetUpdateDeviceMaintenanceButton.propTypes = {
  contractNumber: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CreateResetUpdateDeviceMaintenanceButton;