import React from 'react';
import PropTypes from 'prop-types';
import DevicesTableFilters from './DevicesTableFilters';
import DevicesTableRow from './DevicesTableRow';

const DevicesTableBody = ({
	entries = [],
	loading,
	properties,
	tags,
	filters,
	createFilterInputChangeHandler,
	createAssignContractClickHandler,
	createCreateContractClickHandler,
	createDetachContractClickHandler,
	createEnableTransferClickHandler,
	createDisableTransferClickHandler,
	createEditDMDClickHandler,
	createContractHistoryClickHandler,
}) => {
	return (
		<tbody>
			<DevicesTableFilters
				loading={loading}
				filters={filters}
				properties={properties}
				createFilterInputChangeHandler={createFilterInputChangeHandler}
			/>
			{entries.map(entry => (
				<DevicesTableRow
					key={entry._id}
					entry={entry}
					properties={properties}
					tags={tags}
					handleAssignContractClick={createAssignContractClickHandler(entry._id)}
					handleCreateContractClick={createCreateContractClickHandler(entry._id)}
					handleDetachContractClick={createDetachContractClickHandler(entry._id)}
					handleEnableTransferClick={createEnableTransferClickHandler(entry._id)}
					handleDisableTransferClick={createDisableTransferClickHandler(entry._id)}
					handleEditDMDClick={createEditDMDClickHandler(entry._id)}
					handleContractHistoryClick={createContractHistoryClickHandler(entry._id.split('::')[1])}
				/>
			))}
		</tbody>
	);
}

DevicesTableBody.propTypes = {
	entries: PropTypes.array,
	loading: PropTypes.bool,
	properties: PropTypes.array,
	tags: PropTypes.array,
	filters: PropTypes.object,
	createFilterInputChangeHandler: PropTypes.func,
	createAssignContractClickHandler: PropTypes.func,
	createCreateContractClickHandler: PropTypes.func,
	createDetachContractClickHandler: PropTypes.func,
	createEnableTransferClickHandler: PropTypes.func,
	createDisableTransferClickHandler: PropTypes.func,
	createEditDMDClickHandler: PropTypes.func,
	createContractHistoryClickHandler: PropTypes.func,
};

export default DevicesTableBody;