import React from 'react';
import { Grid } from 'react-bootstrap';
import PropTypes from 'prop-types';

import LoadingProgress from '../../components/LoadingProgress';
import UploadProgress from '../../components/UploadProgress';

import CreateNewTicketsPanel from '../CreateNewTicketsPanel';
import ContractTicketsTablePanel from './ContractTicketsTablePanel';

const ContractTickets = ({ contractNumber, loading, progress, ticketNumber, tags, tickets, deleteTicket, removeTicket, addTicket, setAnswer, setTicket }) =>  (
	<>
		<LoadingProgress show={loading}/>
		<UploadProgress progress={progress}/>
		<Grid>
			<CreateNewTicketsPanel contractNumber={contractNumber} tagList={tags} setTicket={setTicket} />
		</Grid>
		<Grid fluid>
			{tickets.length > 0 && (
				<ContractTicketsTablePanel
					contractNumber={contractNumber}
					ticketNumber={ticketNumber}
					tagList={tags}
					tickets={tickets}
					deleteTicket={deleteTicket}
					removeTicket={removeTicket}
					addTicket={addTicket}
					setTicket={setTicket}
					setAnswer={setAnswer}
					loading={loading}
				/>
			)}
		</Grid>
	</>
);

ContractTickets.propTypes = {
	contractNumber: PropTypes.string,
	loading: PropTypes.bool,
	progress: PropTypes.number,
	ticketNumber: PropTypes.string,
	tags: PropTypes.array,
	tickets: PropTypes.array,
	deleteTicket: PropTypes.func,
	removeTicket: PropTypes.func,
	setAnswer: PropTypes.func,
	setTicket: PropTypes.func,
};

export default ContractTickets;