import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import { connectMeAndTeamsAndConstantsContext } from '../../../hoc/withMeAndTeamsAndConstants';
import { isN3User } from '../../../utils/functions';

const DevicesTableRowButtons = ({
	webUserJson,
	entry,
	handleAssignContractClick,
	handleCreateContractClick,
	handleDetachContractClick,
	handleEnableTransferClick,
	handleDisableTransferClick,
	handleEditDMDClick,
}) => {
	const showDetachButton = !!entry?.contractNumber;
	return (
		<td>
			<ButtonGroup vertical block>
				<Button bsStyle='info' bsSize='xsmall' onClick={handleEditDMDClick}>
					{isN3User(webUserJson) ? 'Éditer' : 'Changer SIM'}
				</Button>
				{showDetachButton
				? <>
						{entry?.canBeTransferred
						? <Button bsStyle='success' bsSize='xsmall' onClick={handleDisableTransferClick}>
								Désactiver la reprise
							</Button>
						: <Button bsStyle='warning' bsSize='xsmall' onClick={handleEnableTransferClick}>
								Activer la reprise
							</Button>
						}
						<Button bsStyle='danger' bsSize='xsmall' onClick={handleDetachContractClick}>
							Détacher
						</Button>
					</>
				: <>
						<Button bsStyle='info' bsSize='xsmall' onClick={handleAssignContractClick}>
							Assigner
						</Button>
						<Button bsStyle='success' bsSize='xsmall' onClick={handleCreateContractClick}>
							Créer
						</Button>
					</>
				}
			</ButtonGroup>
		</td>
	);
}

export default connectMeAndTeamsAndConstantsContext(DevicesTableRowButtons);