import PropTypes from 'prop-types';
import React from 'react';
import {Modal} from 'react-bootstrap';
import AppVersionsTable from './AppVersionsTable';

const AppVersionsModal = ({packagesWithVersion = {}, open = false, handleClose}) => (
	<Modal bsSize='lg' show={open} onHide={handleClose}>
		<Modal.Header closeButton>
			<Modal.Title>Versions des applications</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<AppVersionsTable packagesWithVersion={packagesWithVersion} />
		</Modal.Body>
	</Modal>
);

AppVersionsModal.propTypes = {
	packagesWithVersion: PropTypes.object,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
};

export default AppVersionsModal;