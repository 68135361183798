import PropTypes from 'prop-types';
import React from 'react';
import './MaintenanceThreshold.css';

const MaintenanceThresholdPure = ({
                                    level,
                                    value,
                                  }) => {
  if (value) {
    return <span className={level}>{value}</span>;
  }

  return null;
};

MaintenanceThresholdPure.propTypes = {
  level: PropTypes.oneOf(['error', 'warning']),
  value: PropTypes.string,
};

const MaintenanceThreshold = ({
                                errorThreshold,
                                warningThreshold,
                              }) => (
  <div className="maintenance-threshold">
    <MaintenanceThresholdPure level="warning" value={warningThreshold}/>
    <MaintenanceThresholdPure level="error" value={errorThreshold}/>
  </div>
);

MaintenanceThreshold.propTypes = {
  errorThreshold: PropTypes.string,
  warningThreshold: PropTypes.string,
};

export default MaintenanceThreshold;