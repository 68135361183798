import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {Button} from 'react-bootstrap';
import AppVersionsModal from './AppVersionsModal';

const AppVersionsButton = ({packagesWithVersion = {}}) => {
	const [open, toggleOpen] = useState(false);

	const handleOpen = () => toggleOpen(true);

	const handleClose = () => toggleOpen(false);

	return <>
		<Button bsStyle='primary' block data-test-id='appVersionsShowModalButton' onClick={handleOpen}>
			Versions applicatives
		</Button>
		<AppVersionsModal packagesWithVersion={packagesWithVersion} open={open} handleClose={handleClose} />
	</>
}

AppVersionsButton.propTypes = {
	packagesWithVersion: PropTypes.object,
};

export default AppVersionsButton;