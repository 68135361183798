import React from 'react';
import TicketsTableHeader from './TicketsTableHeader';

const TicketsTableHead = ({properties = [], sort, createSortHandler, actionsHeader}) => {
	return (
		<thead>
			<tr>
				{properties.map(property => (
					<TicketsTableHeader
						key={'property_header_' + property.name}
						property={property}
						sort={sort}
						handleSort={createSortHandler(property.name)}
					/>
				))}
				{actionsHeader && <th></th>}
			</tr>
		</thead>
	);
}

export default TicketsTableHead;