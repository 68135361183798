const SIM_STATES = {
  ACTIVATED: {value: 'ACTIVATED', label: 'Activée'},
  ACTIVATING: {value: 'ACTIVATING', label: 'En cours d\'activation'},
  DOWNGRADING: {value: 'DOWNGRADING', label: 'Changement vers forfait inférieur'},
  NEED_ACTIVATION: {value: 'NEED_ACTIVATION', label: 'Demande d\'activation'},
  NEED_DOWNGRADE: {value: 'NEED_DOWNGRADE', label: 'Demande de forfait supérieur'},
  NEED_TERMINATION: {value: 'NEED_TERMINATION', label: 'Demande de désactivation'},
  NEED_UPGRADE: {value: 'NEED_UPGRADE', label: 'Demande de forfait inférieur'},
  NOT_ACTIVATED: {value: 'NOT_ACTIVATED', label: 'Non activée'},
  SUSPENDED: {value: 'SUSPENDED', label: 'Suspendue'},
  TERMINATED: {value: 'TERMINATED', label: 'Désactivée'},
  TERMINATING: {value: 'TERMINATING', label: 'En cours de désactivation'},
  UNKNOWN: {value: 'UNKNOWN', label: 'Inconnu'},
  UPGRADING: {value: 'UPGRADING', label: 'Changement vers forfait supérieur'},
};

const isTransatel = sim => sim?.operator?.toUpperCase()?.startsWith('TRANSATEL');

const getSimStateClass = (simState) => {
  switch (simState) {
    case SIM_STATES.NEED_ACTIVATION.value:
    case SIM_STATES.ACTIVATING.value:
    case SIM_STATES.DOWNGRADING.value:
    case SIM_STATES.NEED_DOWNGRADE.value:
    case SIM_STATES.NEED_UPGRADE.value:
    case SIM_STATES.UPGRADING.value:
      return 'warning';
    case SIM_STATES.ACTIVATED.value:
      return 'success';
    case SIM_STATES.NEED_TERMINATION.value:
    case SIM_STATES.TERMINATING.value:
    case SIM_STATES.TERMINATED.value:
      return 'danger';
    case SIM_STATES.NOT_ACTIVATED.value:
    default:
      return 'default';
  }
};

const getSimStateIcon = (simState) => {
  switch (simState) {
    case SIM_STATES.NOT_ACTIVATED.value:
      return 'times-circle';
    case SIM_STATES.ACTIVATED.value:
      return 'check-circle';
    case SIM_STATES.NEED_ACTIVATION.value:
    case SIM_STATES.NEED_TERMINATION.value:
      return 'info-circle';
    case SIM_STATES.ACTIVATING.value:
    case SIM_STATES.DOWNGRADING.value:
    case SIM_STATES.TERMINATING.value:
    case SIM_STATES.UPGRADING.value:
      return 'spinner';
    case SIM_STATES.TERMINATED.value:
      return 'stop-circle';
    case SIM_STATES.SUSPENDED.value:
      return 'pause-circle';
    default:
      return 'question-circle';
  }

};

const SIM_OPERATORS = {
  LPM: {
    length: 20,
    name: 'LPM',
    prefix: '893310',
  },
  TRANSATEL: {
    length: 20,
    name: 'TRANSATEL',
    prefix: '893301',
  },
  TRANSATEL_FULL: {
    length: 19,
    name: 'TRANSATEL_FULL',
    prefix: '893322',
  },
  TRANSATEL_MULTI: {
    length: 19,
    name: 'TRANSATEL_MULTI',
    prefix: '898824',
  },
};

const SIM_PLANS = {
  CLASSIC: {
    label: 'Sim',
    value: 'Classic',
  },
  SIM_PLUS: {
    label: 'Sim Plus',
    value: 'SimPlus',
  },
};

export const getSimPlanLabel = plan => Object.values(SIM_PLANS).find(
  (p) => p.value === plan,
)?.label || '';

export const getSimPlanFromState = currentState => {
  if ([SIM_STATES.NEED_DOWNGRADE.value, SIM_STATES.NEED_UPGRADE.value].includes(currentState?.state)) {
    return `${getSimPlanLabel(currentState?.simState?.from)} => ${getSimPlanLabel(currentState?.simState?.to)}`;
  } else {
    return getSimPlanLabel(currentState?.simState?.plan);
  }
};

export {
  SIM_OPERATORS,
  SIM_PLANS,
  SIM_STATES,
  getSimStateClass,
  getSimStateIcon,
  isTransatel,
};