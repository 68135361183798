import {graphql} from 'react-apollo';
import {UPDATE_CUSTOMER} from '../../schema/CustomerSchema';

const updateCustomer = mutate => (address, id, callback) => {
    mutate({
        variables: {
            customerInput: {
                address,
                id,
            },
        },
        update(store, {data}) {
            if (callback) {
                callback(data?.customerUpdate?.customer);
            }
        },
    });
};

const withUpdateCustomerMutation = graphql(UPDATE_CUSTOMER, {
    props: props => ({
        updateCustomer: updateCustomer(props.mutate),
    }),
});

export default withUpdateCustomerMutation;