import PropTypes from 'prop-types';
import React from 'react';
import {Col, ControlLabel, FormGroup, Row} from 'react-bootstrap';
import TicketContract from './TicketContract';
import PhoneAndCellphone from './PhoneAndCellphone';
import Sim from './Sim';

const TicketClient = ({
	contract,
	dmd,
	ticket,
	setLoading,
	handleManageSim,
}) => (
	<>
		<TicketContract
			contract={contract}
			contractNumber={ticket.contractNumber}
		/>
		<FormGroup>
			<Row>
				<Col md={4}>
					<ControlLabel>Numéro de contrat</ControlLabel>
					<div>{ticket.contractNumber}</div>
				</Col>
				<Col md={4}>
					<ControlLabel>Acheteur</ControlLabel>
					<div>{ticket.buyerFirstName} {ticket.buyerLastName}</div>

					<PhoneAndCellphone
						cellphone={ticket.buyerCellphone}
						phone={ticket.buyerPhone}
					/>
				</Col>
				<Col md={4}>
					<ControlLabel>Utilisateur</ControlLabel>
					<div>{ticket.mainUserFirstName} {ticket.mainUserLastName}</div>

					<PhoneAndCellphone
						cellphone={ticket.mainUserCellphone}
						phone={ticket.mainUserPhone}
					/>
				</Col>
			</Row>
		</FormGroup>
		<hr/>
		<Sim
			ticket={ticket}
			dmd={dmd}
			setLoading={setLoading}
			handleManageSim={handleManageSim}
		/>
	</>
);

TicketClient.propTypes = {
	contract: PropTypes.object,
	dmd: PropTypes.object,
	ticket: PropTypes.object,
	setLoading: PropTypes.func,
	handleManageSim: PropTypes.func,
};

export default TicketClient;