import React, { useEffect, useState } from 'react';
import { Button, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Panel, Row } from 'react-bootstrap';

const DevicesViewSearchInputs = ({properties, searchInputs, createKeyPressHandler, createInputChangeHandler, handleSNSearch, handleImportOldDeviceModalOpen}) => {
	const { SN = '' } = searchInputs;
	const [rows, setRows] = useState(1);

	useEffect(() => {
		const rows = SN.split('\n').length;
		setRows(rows);
	}, [SN]);

	return (
		<Panel bsStyle='success'>
			<Panel.Heading>Chercher un device</Panel.Heading>
			<Panel.Body>
				<Row>
					<Col md={2}>
						<FormGroup>
							<ControlLabel>SN</ControlLabel>
							<FormControl
								componentClass='textarea'
								className='no-resize'
								type='text'
								rows={rows}
								value={SN}
								onKeyDown={createKeyPressHandler('SN')}
								onChange={createInputChangeHandler('SN')}
							/>
							<Button bsSize='xsmall' onClick={handleSNSearch}>
								<Glyphicon glyph='search'/>
							</Button>
							<Button bsSize='xsmall' onClick={handleImportOldDeviceModalOpen}>
								<Glyphicon glyph='plus'/>
							</Button>
						</FormGroup>
					</Col>
					{properties.map(property => {
						if (!property.searchable) return null;
						return (
							<Col key={property.key} md={2}>
								<FormGroup>
									<ControlLabel>{property.name}</ControlLabel>
									<FormControl
										type='text'
										value={searchInputs[property.key] || ''}
										onKeyDown={createKeyPressHandler(property.key)}
										onChange={createInputChangeHandler(property.key)}
									/>
								</FormGroup>
							</Col>
						)
					})}
				</Row>
			</Panel.Body>
		</Panel>
	);
}

export default DevicesViewSearchInputs;