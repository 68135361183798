import PropTypes from 'prop-types';
import React from 'react';
import {Button, Form, FormGroup} from 'react-bootstrap';
import {compose, getContext, pure, withProps, withState} from 'recompose';
import {getMidday, getTodayMidday} from '../../../../../../utils/Date';
import {isProAbono} from '../../../../../../utils/Subscription';
import {FORM_MODES} from '../SubscriptionStateEdit';
import './SubscriptionStateForm.css';
import DateBeginField from './SubscriptionStateForm/DateBeginField';
import DateEndField from './SubscriptionStateForm/DateEndField';
import SubscriptionType from './SubscriptionStateForm/SubscriptionType';

const SubscriptionStateFormPure = ({
                                     currentSubscriberLabel,
                                     isACreation,
                                     isImmediateDate,
                                     mode,
                                     subscription,
                                     subscriptionState,
                                     handleSelectCurrentSubscriptionType,
                                     setIsImmediateDate,
                                     setMode,
                                     setSubscriptionState,
                                   }) => (
  <Form>
    <fieldset className="subscription-edit-fieldset">
      <legend>Sélection de l'offre</legend>

      <SubscriptionType isACreation={isACreation}
                        handleSelectCurrentSubscriptionType={handleSelectCurrentSubscriptionType}
                        updateSubscriptionType={(subscriptionType) => setSubscriptionState({
                          ...subscriptionState,
                          type: subscriptionType,
                        })}/>
    </fieldset>

    <fieldset className="subscription-edit-fieldset">
      <legend>Dates d'abonnement</legend>

      <DateBeginField subscription={subscription}
                      setIsImmediateDate={setIsImmediateDate}
                      setSubscriptionState={setSubscriptionState}/>

      <DateEndField subscription={subscription}
                    setSubscriptionState={setSubscriptionState}/>
    </fieldset>

      {
        mode === FORM_MODES.CREATE &&
        isProAbono(subscriptionState?.type) &&
        !isImmediateDate &&
        <>
          Souscripteur actuel : {currentSubscriberLabel || 'Non défini'}
          <Button bsStyle="link"
                  onClick={() => setMode(FORM_MODES.SUBSCRIBER_CREATE)}>Créer un nouveau souscripteur</Button>
        </>
      }

      {mode === FORM_MODES.SUBSCRIBER_CREATE &&
      <FormGroup>
        <Button bsSize="xsmall"
                bsStyle="danger"
                onClick={() => setMode(FORM_MODES.CREATE)}>Annuler la création du souscripteur</Button>
      </FormGroup>
      }
  </Form>
);

SubscriptionStateFormPure.propTypes = {
  isACreation: PropTypes.bool,
  mode: PropTypes.number,
  subscription: PropTypes.object,
  subscriptionState: PropTypes.object,
  handleSelectCurrentSubscriptionType: PropTypes.func,
  setMode: PropTypes.func,
  setSubscriptionState: PropTypes.func,
};

const withIsImmediateDateState = withState('isImmediateDate', 'setIsImmediateDate', ({subscriptionState}) => {
  if (subscriptionState?.dateBegin) {
    const dateBegin = getMidday(subscriptionState?.dateBegin).valueOf();
    const today = getTodayMidday().valueOf();

    return dateBegin === today;
  }

  return false;
});

const SubscriptionStateForm = compose(
  getContext(
    {
      subscriptionState: PropTypes.object,
    },
  ),
  withProps(
    ({currentSubscriber}) => ({
      currentSubscriberLabel: !!currentSubscriber?.current && `${currentSubscriber?.current?.firstName} ${currentSubscriber?.current?.lastName}`,
    }),
  ),
  withIsImmediateDateState,
  pure,
)(SubscriptionStateFormPure);

SubscriptionStateForm.propTypes = {
  currentSubscriber: PropTypes.object,
  isACreation: PropTypes.bool,
  mode: PropTypes.number,
  subscription: PropTypes.object,
  handleSelectCurrentSubscriptionType: PropTypes.func,
  setMode: PropTypes.func,
  setSubscriptionState: PropTypes.func,
};

export default SubscriptionStateForm;