import React from 'react';
import {FormControl} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {branch, compose, pure, renderComponent} from 'recompose';
import moment from 'moment';

import {FRENCH_DAY_LABELS, FRENCH_MONTH_LABELS} from '../../../../../utils/constants';
import {DatePicker} from '../../../../components/DatePicker';

const DateInputPure = ({ bsSize, value, handleChange }) => (
    <DatePicker value={value ? new Date(value).toISOString() : null}
                onChange={handleChange}
                weekStartsOnMonday
                monthLabels={FRENCH_MONTH_LABELS}
                dayLabels={FRENCH_DAY_LABELS}
                bsSize={bsSize} />
);

DateInputPure.propTypes = {
    bsSize: PropTypes.string,
    value: PropTypes.number,
    handleChange: PropTypes.func,
};

const ReadOnlyDate = ({ bsSize, value }) => (
    <FormControl bsSize={bsSize}
                 type='text'
                 value={value ? moment(value).format('L') : ''}
                 readOnly />
);

ReadOnlyDate.propTypes = {
    bsSize: PropTypes.string,
    value: PropTypes.number,
};

const DateInput = compose(
    branch(
        ({ modificationEnabled }) => !modificationEnabled,
        renderComponent(ReadOnlyDate),
    ),
    pure,
)(DateInputPure);

DateInput.propTypes = {
    bsSize: PropTypes.string,
    modificationEnabled: PropTypes.bool,
    value: PropTypes.number,
    handleChange: PropTypes.func,
};

export default DateInput;