import React from 'react';
import { Glyphicon, NavItem } from 'react-bootstrap';
import {errorMsg} from '../../utils/Notify';
import {logout} from '../../utils/requests';

const LogoutButton = () => {

  const handleLogout = () => {
		logout()
		.then((response, textStatus, jqXHR) => {
			if (jqXHR.status === 200) {
				window.tags = null;
				window.teams = null;
				window.types = null;
				window.webUserJson = null;
				window.location.href = '/login';
			}
		}, (jqXHR, textStatus, errorThrown) => {
			errorMsg('Impossible de se déconnecter');
		});
	}

	return (
		<NavItem onClick={handleLogout}>
			<Glyphicon glyph='log-out'/> Déconnexion
		</NavItem>
	);
}

export default LogoutButton;