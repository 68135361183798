import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../../../routes/routes';
import {changeSimStateWithPlanFunction, withButtonContext, withSelectPlanStates, wrapWithModal} from './Helpers';

const ChangePlanButtonPure = ({
                                buttonSize,
                                setShowModal,
                              }) => (
  <Button bsStyle='success' bsSize={buttonSize} onClick={() => setShowModal(true)}>
    Changer de forfait
  </Button>
);

ChangePlanButtonPure.propTypes = {
  buttonSize: PropTypes.string,
  setShowModal: PropTypes.func,
};

const handlers = withHandlers({
  onValidateClick: changeSimStateWithPlanFunction,
});

const ChangePlanButton = compose(
  withButtonContext,
  withSelectPlanStates,
  withProps(
    () => ({
      route: jsRoutes.controllers.backoffice.SupportV2SimController.changePlan,
    }),
  ),
  handlers,
  wrapWithModal,
  pure,
)(ChangePlanButtonPure);

ChangePlanButton.propTypes = {};

export default ChangePlanButton;