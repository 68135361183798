import React from 'react';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';
import PropTypes from 'prop-types';

import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {isN2OrHigher, isValidationUser} from '../../../../../utils/functions';

const ButtonToolbarEditPure = ({ currentSubscriptionStateErrorIndexes, partialTerminationFill, somethingHasBeenModified, handleCancel, handleSave }) => (
    <ButtonToolbar className='pull-right'>
        <Button bsStyle='danger'
                bsSize='small'
                onClick={handleCancel}
                disabled={!somethingHasBeenModified}>
            Annuler
        </Button>
        <Button bsStyle='success'
                bsSize='small'
                onClick={handleSave}
                disabled={!somethingHasBeenModified || partialTerminationFill || currentSubscriptionStateErrorIndexes.length > 0}>
            Valider
        </Button>
    </ButtonToolbar>
);

ButtonToolbarEditPure.propTypes = {
    currentSubscriptionStateErrorIndexes: PropTypes.array,
    partialTerminationFill: PropTypes.bool,
    somethingHasBeenModified: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleSave: PropTypes.func,
};

const ButtonToolbarEdit = compose(
    connectMeAndTeamsAndConstantsContext,
    branch(
        ({ somethingHasBeenModified, webUserJson }) =>
            !somethingHasBeenModified ||
            (
                !isN2OrHigher(webUserJson) &&
                !isValidationUser(webUserJson)
            ),
        renderNothing,
    ),
    pure,
)(ButtonToolbarEditPure);

ButtonToolbarEdit.propTypes = {
    currentSubscriptionStateErrorIndexes: PropTypes.array,
    partialTerminationFill: PropTypes.bool,
    somethingHasBeenModified: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleSave: PropTypes.func,
};

export default ButtonToolbarEdit;