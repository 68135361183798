import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {connectTypesAndTeamsContext} from '../../../../hoc/withMeAndTeamsAndConstants';
import {getResultType, getTeam, getTeamType} from '../../../../utils/functions';
import { parseResult } from '../../../../utils/types';

const TicketClosureResult = connectTypesAndTeamsContext(({ teams, types, closed, ticket }) => {

	if (!closed) return null;

	const closeAuthor = `${getTeamType(types, getTeam(teams, ticket.resultAuthor)) || ''} - ${ticket.resultAuthor || ''}`;
	const resultLabel = ticket.result ? getResultType(types, parseResult(ticket.result)) : '';
	const firstIntentionLabel = ticket.result && ticket.result.split('.').length > 2 ? getResultType(types, ticket.result) : null;

	return (
		<Alert bsStyle='info'>
			<strong>FERMETURE {closeAuthor}</strong>
			&nbsp; {resultLabel}
			{firstIntentionLabel && ` (${firstIntentionLabel})`}
		</Alert>
	);
});

TicketClosureResult.propTypes = {
	closed: PropTypes.bool,
	ticket: PropTypes.object,
};

export default TicketClosureResult;