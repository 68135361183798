import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import {connectTypesAndWebUserContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {AUTOMATED_TICKET_SUBJECTS, TICKET_STATES} from '../../../utils/constants';
import {fetchContract, fetchDmd, fetchTicketTypes} from './getData';
import TicketDetailsPanel from './TicketDetailsPanel';
import TicketDetailsModal from './TicketDetailsModal';
import TicketContent from './TicketContent';
import TicketActionsButtons from './TicketActionsButtons';

const TicketDetails = connectTypesAndWebUserContext(({webUserJson, types, modal, initialTicket, setTicket, setAnswer, deleteTicket, handleClose, tagList}) => {
  const [newAnswer, setNewAnswer] = useState({
    attachment: null,
    content: '',
    canal: '',
  });
  const [contract, setContract] = useState(null); // modal
  const [dmd, setDmd] = useState(null); // modal
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    type_demande: [],
    type_etat: [],
    type_team: [],
    type_canal: [],
    type_cloture: [],
  });
  const [ticket, setCurrentTicket] = useState(initialTicket ? {...initialTicket} : []);
  const [show, setShow] = useState(true);
  
  const {contractNumber} = initialTicket;
  
  const loadExtraInfos = useCallback(() => {
    fetchContract({ contractNumber, setContract, setLoading });
    fetchDmd({ contractNumber, setDmd, setLoading });
  }, [contractNumber]);

  useEffect(function useFetchTicketDetails() {
  
    const loadTicket = () => {
      fetchTicketTypes({ webUserJson, setLoading, setOptions });
    
      if (modal) loadExtraInfos({ contractNumber, setContract, setDmd, setLoading });
    };

    setCurrentTicket({...initialTicket});
    loadTicket();
  }, [contractNumber, initialTicket, webUserJson, loadExtraInfos, modal]);

  const onSaveTicketSuccess = (id, ticket, success) => {
    if (success) {
      setCurrentTicket(ticket);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  
  const handleSaveTicket = data => {
    const formData = new FormData();

    for (var [key, value] of Object.entries(data)) {
      if (value) {
        let dataValue = JSON.stringify(value);
        if (key === 'ticket') {
          dataValue = JSON.stringify({...ticket, ...value});
        }
        if (key === 'answer_1_attachment_0') {
          dataValue = value;
        }

        formData.append(key, dataValue);
      }
    }

    setLoading(true);
    setTicket(ticket.id, formData, onSaveTicketSuccess);
  }

  const handleDeleteTicket = () => {
    setLoading(true);
    deleteTicket(ticket.id);
  }

  const handleSaveAnswer = answer => {
    const data = new FormData();
    data.append('answer', JSON.stringify(answer));

    setLoading(true);
    setAnswer(ticket.id, data, onSaveTicketSuccess);
  }

  const handleHide = () => {
    setShow(false);
    handleClose();
  }

  const closed = ticket.state === TICKET_STATES.DONE;
  
  const content = (
    <TicketContent
      newAnswer={newAnswer}
      setNewAnswer={setNewAnswer}
      closed={closed}
      loading={loading}
      options={options}
      ticket={ticket}
      tagList={tagList}
      handleSaveAnswer={handleSaveAnswer}
      handleSaveTicket={handleSaveTicket}
    />
  );

  const {canal: newAnswerCanal, content: newAnswerContent} = newAnswer;
  const notAnswered = newAnswerContent === '' || newAnswerCanal === '';
  const locked = AUTOMATED_TICKET_SUBJECTS.includes(ticket.subject);

  const actions = (
    <TicketActionsButtons
      newAnswer={newAnswer}
      setNewAnswer={setNewAnswer}
      closed={closed}
      locked={locked}
      notAnswered={notAnswered}
      options={options}
      ticket={ticket}
      initialTicket={initialTicket}
      setCurrentTicket={setCurrentTicket}
      handleSaveTicket={handleSaveTicket}
      handleDeleteTicket={handleDeleteTicket}
    />
  );

  if (modal) return (
    <TicketDetailsModal
      contract={contract}
      dmd={dmd}
      setLoading={setLoading}
      ticket={ticket}
      show={show}
      handleHide={handleHide}
      handleManageSim={loadExtraInfos}
      content={content}
      actions={actions}
    />
  )

  return (
    <TicketDetailsPanel
      handleClose={handleClose}
      ticket={ticket}
      content={content}
      actions={actions}
    />
  );
});

TicketDetails.propTypes = {
  initialTicket: PropTypes.object,
  tagList: PropTypes.array,
  deleteTicket: PropTypes.func,
  handleClose: PropTypes.func,
  setAnswer: PropTypes.func,
  setTicket: PropTypes.func,
  modal: PropTypes.bool,
};

export default TicketDetails;