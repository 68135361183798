import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';
import {compose, lifecycle, pure, withHandlers, withProps, withState} from 'recompose';
import withLoading from '../../../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import jsRoutes from '../../../../../routes/routes';
import {TICKET_STATES, TICKET_SUBJECTS} from '../../../../../utils/constants';
import DuplicationResult from './DuplicationResult';
import OrderEdit from './OrderEdit';
import {formatEntries, getNextOccurrenceNumber} from './OrderHelpers';
import OrderMigrate from './OrderMigrate';
import OrdersTable from './OrdersTable';

const OrdersPure = ({
                      currentEditedOrder,
                      currentMigratingOrder,
                      DAPOProducts,
                      entries,
                      isFreeContract,
                      showOrderDuplicationResult,
                      cancelOrder,
                      cancelAllOrders,
                      cloneOrder,
                      editOrder,
                      enableOrder,
                      migrateOrder,
                      recalculateOrder,
                      resendFSPlus,
                      setCurrentEditedOrder,
                      setCurrentMigratingOrder,
                      setShowOrderDuplicationResult,
                      submitDuplicatedOrder,
                      submitEditedOrder,
                      submitMigratedOrder,
                    }) => {
  let cancelContractButton;
  if (entries && entries.length > 0) {
    if (!entries.every(entry => entry.cancelled === true) && !isFreeContract) {
      cancelContractButton = <Button onClick={cancelAllOrders}>Annuler le contrat</Button>;
    }
  }

  return (
    <>
      {showOrderDuplicationResult && (
        <DuplicationResult onSubmitClick={submitDuplicatedOrder}
                           onHideClick={() => setShowOrderDuplicationResult(false)}/>
      )}
      {currentEditedOrder && (
        <OrderEdit DAPOProducts={DAPOProducts}
                   order={currentEditedOrder}
                   onSubmitClick={submitEditedOrder}
                   onHideClick={() => setCurrentEditedOrder(null)}/>
      )}
      {currentMigratingOrder && (
        <OrderMigrate onHideClick={() => setCurrentMigratingOrder(null)}
                      onSubmitClick={submitMigratedOrder}/>
      )}
      <OrdersTable
        isFreeContract={isFreeContract}
        orders={entries}
        onEditClick={editOrder}
        onCloneClick={cloneOrder}
        onCancelClick={cancelOrder}
        onResendFSPlusClick={resendFSPlus}
        onEnableClick={enableOrder}
        onMigrateToContractClick={migrateOrder}
        onRecalculateClick={recalculateOrder}
      />
      <div>
        <hr/>
        {cancelContractButton}</div>
    </>
  );
};

OrdersPure.propTypes = {
  currentEditedOrder: PropTypes.object,
  DAPOProducts: PropTypes.array,
  entries: PropTypes.array,
  showOrderDuplicationResult: PropTypes.bool,
  cancelOrder: PropTypes.func,
  cancelAllOrders: PropTypes.func,
  cloneOrder: PropTypes.func,
  editOrder: PropTypes.func,
  enableOrder: PropTypes.func,
  migrateOrder: PropTypes.func,
  recalculateOrder: PropTypes.func,
  resendFSPlus: PropTypes.func,
  setCurrentEditedOrder: PropTypes.func,
  setShowOrderDuplicationResult: PropTypes.func,
  submitDuplicatedOrder: PropTypes.func,
  submitEditedOrder: PropTypes.func,
  submitMigratedOrder: PropTypes.func,
};

const withCurrentEditedOrderState = withState('currentEditedOrder', 'setCurrentEditedOrder', null);
const withCurrentMigratingOrderState = withState('currentMigratingOrder', 'setCurrentMigratingOrder', null);
const withDAPOProductsState = withState('DAPOProducts', 'setDAPOProducts', []);
const withOrdersState = withState('orders', 'setOrders', []);
const withPostmanInstallationTypesState = withState('postmanInstallationTypes', 'setPostmanInstallationTypes', null);
const withShowOrderDuplicationResultState = withState('showOrderDuplicationResult', 'setShowOrderDuplicationResult', false);

const withCalculatedProps = withProps(
  ({contractNumber, orders, postmanInstallationTypes}) => ({
    isFreeContract: contractNumber === '00000001',
    entries: formatEntries(orders, postmanInstallationTypes),
  }),
);

const getOrderById = (orders, id) => orders.filter(order => order.id === id)[0];

const refreshOrdersHandler = withHandlers({
  refreshOrders: ({orders, setOrders}) => updatedOrder => {
    const updatedOrders = orders.filter(order => order.id !== updatedOrder.id);
    updatedOrders.push(updatedOrder);
    setOrders(updatedOrders);
  },
  removeOrder: ({orders, setOrders}) => orderIdToRemove => {
    const updatedOrders = orders.filter(order => order.id !== orderIdToRemove);
    setOrders(updatedOrders);
  },
});

const {
  calculateFSPlusAddress,
  getContractOrders,
  setOrder,
  setOrderContractNumber,
  setTicket,
} = jsRoutes.controllers.backoffice.SupportV2Controller;

const submitHandler = withHandlers({
  submitOrder: ({
                  contractNumber,
                  currentEditedOrder,
                  refreshOrders,
                  setCurrentEditedOrder,
                  setLoading,
                }) => async (order, callback) => {
    const route = setOrder(contractNumber);
    const orderToSave = order || currentEditedOrder;

    if (!orderToSave.prestations.fsPlus) {
      orderToSave.fsPlusAddressOpt = null;
    } else {
      orderToSave.prestations.fsPlus = {
        completed: false,
        confirmed: false,
        error: false,
        sent: false,
        ...orderToSave.prestations.fsPlus
      }
    }

    await setCurrentEditedOrder(null);
    await setLoading(true);

    route.ajax({
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      processData: false,
      data: JSON.stringify(orderToSave),
    }).then(
      async (response) => {
        if (callback) {
          callback(response);
        } else {
          await setLoading(false);
          refreshOrders(response);
        }
      },
      () => setLoading(false),
    );
  },
});

const handlers = withHandlers({
  cancelOrder: ({orders, submitOrder}) => id => {
    const order = getOrderById(orders, id);
    submitOrder({...order, cancelled: true});
  },

  cancelAllOrders: ({orders, submitOrder}) => () =>
    orders.forEach(order => submitOrder({...order, cancelled: true})),

  cloneOrder: ({orders, setCurrentEditedOrder}) => id => {
    let order = getOrderById(orders, id);

    order.occurrenceNumber = getNextOccurrenceNumber(orders);
    order.created = moment().valueOf();
    order.duplicatedFrom = order.id;

    const {typePresta} = order.prestations.fsPlus || {};
    const {dapo, lmr} = order.prestations;

    delete order.id;
    delete order.prestations;
    delete order.fsPlusAddressOpt;

    order.prestations = {};

    if (dapo) {
      order.prestations.dapo = {sent: false, confirmed: false, products: dapo.products};
    }

    if (lmr) {
      order.prestations.lmr = {sent: false, products: lmr.products};
    }

    if (typePresta) {
      order.prestations.fsPlus = {
        sent: false,
        error: false,
        confirmed: false,
        completed: false,
        typePresta: typePresta,
      };
    }

    setCurrentEditedOrder(order);
  },

  editOrder: ({orders, setCurrentEditedOrder}) => id => setCurrentEditedOrder(getOrderById(orders, id)),

  migrateOrder: ({orders, setCurrentMigratingOrder}) => id => setCurrentMigratingOrder(getOrderById(orders, id)),

  enableOrder: ({orders, submitOrder}) => id => {
    let order = getOrderById(orders, id);
    order.cancelled = false;
    submitOrder(order);
  },

  resendFSPlus: ({orders, submitOrder}) => id => {
    let order = getOrderById(orders, id);
    const {fsPlus} = order.prestations;

    if (fsPlus) {
      order.prestations.fsPlus = {
        ...fsPlus,
        error: false,
        missionInProgress: false,
        sent: false,
      };
    }

    submitOrder(order);
  },

  recalculateOrder: ({contractNumber, orders, refreshOrders, setLoading}) => async id => {
    const order = getOrderById(orders, id);
    const route = calculateFSPlusAddress(contractNumber, order.occurrenceNumber);

    await setLoading(true);
    route.ajax({
      dataType: 'json',
      processData: false,
    }).then(
      async (response) => {
        await setLoading(false);
        refreshOrders(response);
      },
      () => setLoading(false),
    );
  },

  submitDuplicatedOrder: ({
                            contractNumber,
                            currentEditedOrder,
                            webUserJson,
                            addTicket,
                            refreshOrders,
                            setLoading,
                            submitOrder,
                          }) => ({formData}) => {
    const {duplicationReason, duplicationSubReason} = formData;
    const {duplicatedFrom, ...order} = currentEditedOrder;

    submitOrder(
      order,
      (newOrder) => {
        const ticketResult = isNaN(parseFloat(duplicationReason)) ? duplicationSubReason : duplicationReason; // Look for result code (float-like)
        const ticket = {
          creator: webUserJson.email,
          team: webUserJson.team,
          state: TICKET_STATES.DONE,
          subject: TICKET_SUBJECTS.DUPLICATA,
          result: ticketResult,
          content: `Duplication de la commande ${duplicatedFrom} vers la commande ${newOrder.id}`,
        };

        const data = new FormData();
        data.append('ticket', JSON.stringify(ticket));

        const resultFunction = async () => {
          await setLoading(false);
          return refreshOrders(newOrder);
        };

        const route = setTicket(contractNumber);
        route.ajax({
          contentType: false,
          processData: false,
          data: data,
        }).then(
          async (ticket) => {
            await resultFunction();
            addTicket(ticket);
          },
          () => resultFunction(),
        );
      },
    );
  },

  submitEditedOrder: ({setCurrentEditedOrder, setShowOrderDuplicationResult, submitOrder}) => async (order) => {
    if (order.duplicatedFrom) {
      await setCurrentEditedOrder(order);
      setShowOrderDuplicationResult(true);
    } else {
      submitOrder(order);
    }
  },

  submitMigratedOrder: ({
                          contractNumber,
                          currentMigratingOrder,
                          removeOrder,
                          setCurrentMigratingOrder,
                          setLoading,
                        }) => async ({newContractNumber}) => {
    const route = setOrderContractNumber(contractNumber);

    await setCurrentMigratingOrder(null);
    await setLoading(true);

    route.ajax({
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      processData: false,
      data: JSON.stringify({
        newContractNumber,
        orderId: currentMigratingOrder.id,
      }),
    }).then(
      async () => {
        setLoading(false);
        removeOrder(currentMigratingOrder.id);
      },
      () => setLoading(false),
    );
  },
});

const withData = lifecycle({
  componentDidMount() {
    const route = getContractOrders(this.props.contractNumber);
    const {setOrders, setDAPOProducts, setPostmanInstallationTypes} = this.props;

    route.ajax({
      contentType: false,
      dataType: 'json',
      processData: false,
    }).then(
      async (response) => {
        const {DAPOProducts, ordersWithPrestations, postmanInstallationTypes} = response;

        await setOrders(ordersWithPrestations);
        await setPostmanInstallationTypes(postmanInstallationTypes);
        setDAPOProducts(DAPOProducts);
      },
      () => {
      },
    );
  },
});

const Orders = compose(
  connectMeAndTeamsAndConstantsContext,
  withLoading,
  withCurrentEditedOrderState,
  withCurrentMigratingOrderState,
  withDAPOProductsState,
  withOrdersState,
  withPostmanInstallationTypesState,
  withShowOrderDuplicationResultState,
  withCalculatedProps,
  withData,
  refreshOrdersHandler,
  submitHandler,
  handlers,
  pure,
)(OrdersPure);

Orders.propTypes = {
  contractNumber: PropTypes.string,
  addTicket: PropTypes.func,
};

export default Orders;