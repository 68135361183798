import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import TransferNewTicketModal from './TransferNewTicketModal';
import { connectWebUserContext } from '../../../../hoc/withMeAndTeamsAndConstants';

const TransferNewTicketModalButton = connectWebUserContext(({ webUserJson, ticket, options, addTicketTags, handleTicketChange, handleSaveData, disabled }) => {

  const [show, setShow] = useState(false);

  const handleShow = () => {
    handleTicketChange({
      ...ticket,
      team: options.type_team[0],
    });
    setShow(true);
  }

  const handleHide = () => setShow(false);

  const handleCancel = () => {
    let newTicket = {
      ...ticket,
      team: webUserJson.team,
    };
    delete newTicket.takeActionDay;
    delete newTicket.noLaterThanDay;
    handleTicketChange(newTicket);
    handleHide();
  }

  return <>
    <Button
      bsStyle='warning'
      disabled={disabled}
      onClick={handleShow}
    >
      Transférer
    </Button>

    {show && (
      <TransferNewTicketModal
        ticket={ticket}
        typeTeam={options.type_team}
        addTicketTags={addTicketTags}
        handleTicketChange={handleTicketChange}
        handleSave={handleSaveData}
        afterValidate={handleHide}
        onHide={handleCancel}
      />
    )}
  </>;
});

TransferNewTicketModalButton.propTypes = {
  disabled: PropTypes.bool,
  ticket: PropTypes.object,
  addTicketTags: PropTypes.func,
  handleTicketChange: PropTypes.func,
  handleSaveData: PropTypes.func,
};

export default TransferNewTicketModalButton;