import React from 'react';
import {ControlLabel, Panel} from 'react-bootstrap';
import {compose, pure} from 'recompose';
import {showIf} from '../../../../utils/functions';

const hasFleetMemberToDisplay = ({fleetMembershipPartnerAccount}) => fleetMembershipPartnerAccount?.name;

const hasOptionalContentFluxToDisplay = ({optionalContentFlux}) => optionalContentFlux?.length > 0;

const hasFleetMemberOrOptionalContentFluxToDisplay = (props) =>
    hasFleetMemberToDisplay(props) || hasOptionalContentFluxToDisplay(props);

const FleetMembershipBlockPure = ({fleetMembershipPartnerAccount}) =>
    <>
        <ControlLabel>Parc partenaire</ControlLabel>
        <p>{fleetMembershipPartnerAccount.name}</p>
    </>;

const FleetMembershipBlock = compose(
    showIf(hasFleetMemberToDisplay),
    pure
)(FleetMembershipBlockPure);

const OptionalContentFluxBlockPure = ({optionalContentFlux}) =>
    <>
        <ControlLabel>Flux</ControlLabel>
        {optionalContentFlux.map((name) => <p key={name}>{name}</p>)}
    </>;

const OptionalContentFluxBlock = compose(
    showIf(hasOptionalContentFluxToDisplay),
    pure
)(OptionalContentFluxBlockPure);

const ContentFluxBlock = (props) =>
    <Panel bsStyle='primary'>
        <Panel.Heading>Contenus</Panel.Heading>
        <Panel.Body>
            <FleetMembershipBlock {...props}/>
            <OptionalContentFluxBlock {...props}/>
        </Panel.Body>
    </Panel>;

export default compose(
    showIf(hasFleetMemberOrOptionalContentFluxToDisplay),
    pure
)(ContentFluxBlock);
