import jsRoutes from '../../../../../routes/routes';
import {USER_TYPES} from './ClientUser';

const {getBuyer, getMainUser, getSubscriber} = jsRoutes.controllers.backoffice.SupportV2Controller;

const bindUser = ({
                      loadContactInfo,
                      setFormData,
                      setUser,
                  },
                  user,
) => {
    const mappedUser = {
        gender: user.gender,
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        addressLine0: user.addressLine[0],
        addressLine1: user.addressLine[1],
        addressLine2: user.addressLine[2],
        addressLine3: user.addressLine[3],
        zipcode: user.zipCode,
        city: user.city,
        country: user.country,
        email: user.email,
        phone: user.phone,
        cellphone: user.cellphone,
        isPrepayed: user.isPrepayed === true,
        isDemo: user.isDemo === true,
    };

    setUser(
        user,
        setFormData(
            mappedUser,
            () => loadContactInfo(mappedUser),
        ),
    );
};

const getUser = props => {
    const {
        contractNumber,
        userType,
        selectBuyer,
        setLoading,
    } = props;
    let route;

    if (userType === USER_TYPES.BUYER) {
        route = getBuyer(contractNumber);
    } else if (userType === USER_TYPES.SUBSCRIBER) {
        route = getSubscriber(contractNumber);
    } else {
        route = getMainUser(contractNumber);
    }

    route.ajax({
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        processData: false,
    }).then(
        user => setLoading(
            false,
            () => bindUser(props, user),
        ),
        () => {
            if (userType === USER_TYPES.MAIN_USER) {
                selectBuyer();
            }

            setLoading(false);
        },
    );
};

export {
    getUser as default,
    bindUser,
};