import React from 'react';
import PropTypes from 'prop-types';

const DevicesTableHead = ({properties}) => (
	<thead>
		<tr>
			{properties.map(property => (
				<th key={'property_header_' + property.name} style={{width: property.size}}>
					{property.name}&nbsp;
				</th>
			))}
			<th/>
		</tr>
	</thead>
);

DevicesTableHead.propTypes = {
	properties: PropTypes.array,
};

export default DevicesTableHead;