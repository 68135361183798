import PropTypes from 'prop-types';
import React from 'react';
import {Panel} from 'react-bootstrap';
import {compose, lifecycle, pure, withProps, withState} from 'recompose';
import {connectContractContext} from '../../../hoc/contract';
import {connectContractNumberContext} from '../../../hoc/contractNumber';
import LoadingProgress from '../../components/LoadingProgress';
import ContractInfo from './ContractDetails/ContractInfo';
import ContentFluxBlock from './ContractDetails/ContentFluxBlock';
import DeliveryBlock from './ContractDetails/DeliveryBlock';
import getContract from './ContractDetails/getContract';

const ContractDetailsPure = ({contract, deliveryBlock, loading}) => (
    <>
        <Panel bsStyle='primary' className='with-loading'>
            <Panel.Heading>Informations</Panel.Heading>
            <Panel.Body>
                <LoadingProgress show={loading}/>
                <ContractInfo contract={contract}/>
            </Panel.Body>
        </Panel>

        {deliveryBlock}

        <ContentFluxBlock fleetMembershipPartnerAccount={contract.fleetMembershipPartnerAccount}
                          optionalContentFlux={contract.optionalContentFlux}/>
    </>
);

ContractDetailsPure.propTypes = {
    contract: PropTypes.object,
    deliveryBlock: PropTypes.node,
    loading: PropTypes.bool,
};

const withLoadingState = withState('loading', 'setLoading', false);

const withLifecycle = lifecycle({
    componentDidMount() {
        getContract(this.props);
    },
});

const withCalculatedProps = withProps(
    ({contract}) => ({
        deliveryBlock: !contract?.lastDeliveryDate && contract?.lastShippedDate &&
            <DeliveryBlock trackingNumber={contract.lastTrackingNumber}/>,
    }),
);

const ContractDetails = compose(
    connectContractNumberContext,
    connectContractContext,
    withLoadingState,
    withLifecycle,
    withCalculatedProps,
    pure,
)(ContractDetailsPure);

ContractDetails.propTypes = {};

export default ContractDetails;