import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'react-apollo';
import {Button, ButtonToolbar} from 'react-bootstrap';
import {branch, compose, pure, renderComponent, withHandlers, withProps, withState} from 'recompose';

import {connectContractNumberContext} from '../../../../../../hoc/contractNumber';
import withLoading from '../../../../../../hoc/withLoading';
import {CREATE_BANK_ID} from '../../../../../../schema/BankSchema';
import {BIC_CODES} from '../../../../../../utils/BIC';
import {isDateInFuture} from '../../../../../../utils/Date';
import {emptyFunction} from '../../../../../../utils/functions';
import {errorMsg, successMsg} from '../../../../../../utils/Notify';
import SingleDatePicker from '../../../../../components/DatePicker/SingleDatePicker';
import JsonSchemaForm from '../../../../../components/Form/JsonSchemaForm';
import BICSelect from './SepaForm/BICSelect';

const IBAN_TOOLS = require('ibantools');

const getSchema = ({disabled}) => ({
    type: 'object',
    properties: {
        NameCustomer: {type: 'string', title: 'Identité mandat', readOnly: true},
        Iban: {type: 'string', title: 'IBAN', readOnly: disabled},
        Bic: {
            type: 'string',
            title: 'BIC',
            params: {
                creatable: true,
                disabled,
                options: BIC_CODES.map(_ => ({label: _.bic, value: _.bic})),
            },
        },
        DateSignature: {
            type: 'number',
            title: 'Date de signature',
            params: {
                disabled,
                isOutsideRange: isDateInFuture,
            },
        },
    },
    required: ['NameCustomer', 'Iban', 'Bic', 'DateSignature'],
});

const handleValidate = (formData, errors) => {
    if (formData.Iban && !IBAN_TOOLS.isValidIBAN(formData.Iban)) {
        errors.Iban.addError('L\'IBAN saisi est incorrect.');
    }

    if (formData.Bic && !IBAN_TOOLS.isValidBIC(formData.Bic)) {
        errors.Bic.addError('Le BIC saisi est incorrect.');
    }

    return errors;
};

const uiSchema = {
    Bic: {
        'ui:widget': 'reactSelect',
    },
    DateSignature: {
        'ui:widget': 'singleDatePicker',
    },
};

const widgets = {
    reactSelect: BICSelect,
    singleDatePicker: SingleDatePicker,
};

const SepaFormPure = ({bankInfos, disabled, hideSubmitButton, schema, handleSaveBankInfos, setBankInfos, setFormEl}) => (
    <JsonSchemaForm formData={bankInfos}
                    schema={schema}
                    showErrorList={false}
                    uiSchema={uiSchema}
                    widgets={widgets}
                    onChange={({formData}) => setBankInfos(formData)}
                    onSubmit={handleSaveBankInfos}
                    validate={handleValidate}
                    setFormEl={el => setFormEl && setFormEl(el)}>

        <ButtonToolbar className='pull-right'
                       hidden={hideSubmitButton}>
            <Button bsStyle='primary'
                    className={disabled && 'hidden'}
                    type='submit'>
                Ajouter le mandat SEPA
            </Button>
        </ButtonToolbar>

    </JsonSchemaForm>
);

SepaFormPure.propTypes = {
    bankInfos: PropTypes.object,
    disabled: PropTypes.bool,
    hideSubmitButton: PropTypes.bool,
    schema: PropTypes.object,
    handleSaveBankInfos: PropTypes.func,
    setBankInfos: PropTypes.func,
};

const withBankInfosState = withState('bankInfos', 'setBankInfos', ({subscriber}) => {
    const {DateSignature, NameCustomer, ...otherFields} = subscriber?.bankID || {};
    return {
        ...otherFields,
        DateSignature: moment(DateSignature).valueOf(),
    };
});

const submitCreateBankID = mutate => (bankIDInput, contractNumber, customerID, setLoading, callback) =>
    setLoading(
        true,
        () => mutate({
            variables: {
                input: {
                    bankIDInput,
                    contractNumber,
                    customerID,
                },
            },
            update: callback,
        }).then(
            emptyFunction,
            () => setLoading(false, () => errorMsg('Erreur lors de la sauvegarde du mandat SEPA')),
        ),
    );

const withBankInfosCreationMutation = graphql(CREATE_BANK_ID, {
    props: ({mutate}) => ({
        handleCreateBankID: submitCreateBankID(mutate),
    }),
});

const withBankInfosHandlers = withHandlers({
    handleSaveBankInfos: ({bankInfos, handleCreateBankID, contractNumber, subscriber, setLoading}) => () => {
        const {DateSignature, __typename, ...otherFields} = bankInfos;
        const bankInfosInput = {
            ...otherFields,
            DateSignature: moment(DateSignature)
                .startOf('day')
                .valueOf(),
        };

        handleCreateBankID(
            bankInfosInput,
            contractNumber,
            subscriber?.id,
            setLoading,
            () => {
                setLoading(
                    false,
                    () => successMsg('Mandat SEPA sauvegardé'),
                );
            },
        );
    },
});

const withCalculatedProps = withProps(props => {
    const {firstName, lastName} = props?.subscriber?.current;
    const disabled = !props.isSEPAEditable && !!props?.subscriber.bankID && !props?.subscriber?.notSaved;
    return {
        bankInfos: {
            ...props?.bankInfos,
            NameCustomer: firstName && lastName ? `${firstName} ${lastName}` : props?.bankInfos?.NameCustomer,
        },
        disabled,
        schema: getSchema({disabled, ...props}),
    };
});

const SepaForm = compose(
    connectContractNumberContext,
    withBankInfosState,
    withCalculatedProps,
    branch(
        ({handleSaveBankInfos}) => !!handleSaveBankInfos,
        renderComponent(SepaFormPure),
    ),
    withLoading,
    withBankInfosCreationMutation,
    withBankInfosHandlers,
    pure,
)(SepaFormPure);

SepaForm.propTypes = {
    hideSubmitButton: PropTypes.bool,
    isSEPAEditable: PropTypes.bool,
    subscriber: PropTypes.object,
    handleSaveBankInfosCustom: PropTypes.func,
};

export {
    SepaForm as default,
    withBankInfosState,
    withBankInfosCreationMutation,
};