import React, { Component } from 'react';
import {  Modal } from 'react-bootstrap';

class WarningModal extends Component {
    render() {
        const warningIconStyle = {
            fontSize: '30px',
            color: '#eb9316',
        };

        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-lg'>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className='glyphicon glyphicon-alert' style={warningIconStyle} /> &nbsp;{this.props.message}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.buttons.map((button, index) => <span key={index}>{button}</span>)}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default WarningModal;