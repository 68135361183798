import React from 'react';
import ReactTable from 'react-table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NullComponent } from '../../../../../components';
import { isEmpty } from '../../../../../../utils/Array';

const APP_VERSION_HISTORY_COLUMNS = [
	{
		width: 34,
	},
	{
		accessor: 'name',
		width: 277,
	},
	{
		accessor: 'version',
		Cell: ({value, ...row}) => value
		? <OverlayTrigger placement="right" overlay={<Tooltip id={`${value}`}>{value}</Tooltip>}>
				<span>{value}</span>
			</OverlayTrigger>
		: <span className="grey">Non renseignée</span>,
	},
	{
		accessor: 'updateDate',
		Cell: ({value, ...row}) => value || <span className="grey">Non renseignée</span>,
	},
];

export const EMPTY_APP_VERSION_HISTORY_ROW = {
	name: undefined,
	version: <span className="grey">Aucune version précédente</span>,
	updateDate: ' ',
};

const AppVersionHistorySubtable = rowProps => {
	const {original: {history = []}} = rowProps;
	const data = isEmpty(history) ? [EMPTY_APP_VERSION_HISTORY_ROW] : history;
	return (
		<ReactTable
			className='-striped -highlight app-version-history-subtable'
			data={data}
			columns={APP_VERSION_HISTORY_COLUMNS}
			sortable={false}
			resizable={false}
			showPagination={false}
			pageSize={data.length}
			NoDataComponent={NullComponent}
		/>
	);
}

export default AppVersionHistorySubtable;