import React from 'react';
import PropTypes from 'prop-types';

const TicketTransferButton = ({onClick}) =>
  <button className='btn btn-default btn-xs center-block' onClick={onClick}>
    <span className='glyphicon glyphicon-transfer' aria-hidden='true'/>
  </button>;

TicketTransferButton.propTypes = {
  onClick: PropTypes.func,
};

export default TicketTransferButton;