import moment from 'moment';
import {toFrenchDateString} from '../../../../../utils/Date';
import {getOrderProduct} from '../../../../../utils/Order';

const formatEntries = (orders, postmanInstallationTypes) =>
  orders.map(order => formatEntry(order, postmanInstallationTypes));

const formatEntry = (order, postmanInstallationTypes) => ({
  '_id': order.id,
  'cancelled': order.cancelled === true,
  'cancellable': !order.cancelled && !order.prestations.dapo?.sent && !order.prestations.lmr?.sent,
  'fsPlusError': order.prestations.fsPlus?.error === true,
  'Id': order.occurrenceNumber,
  'Date Créa': order.created,
  'Produit': getOrderProduct(order),
  'Adresse FS+': formatAddress(order.fsPlusAddressOpt),
  'Adresse client': formatAddress(order.shippingAddress),
  'DAPO': formatDAPO(order),
  'Livraison': formatDelivery(order),
  'FS+': formatFSPlus(order, postmanInstallationTypes),
  prestations: order.prestations,
});

const formatDAPO = order => {
  const {dapo} = order.prestations;

  if (order.cancelled === true) {
    return 'Annulé';
  } else if (!dapo) {
    return 'Inconnu';
  } else if (dapo.sent !== true) {
    if (dapo.hasToBeSentToDAPONotBefore) {
      return `À expédier le ${toFrenchDateString(moment(dapo.hasToBeSentToDAPONotBefore).add(1, 'day'))}`;
    }

    return 'À préparer';
  } else if (dapo.confirmed !== true) {
    return 'En cours';
  } else {
    const formattedSerialNumbersAndSentDate = [
      order.serialNumber && `tablette ${order.serialNumber}`,
      dapo.dockingSerialNumber && `station ${dapo.dockingSerialNumber}`,
      dapo.confirmedTimestamp && moment(dapo.confirmedTimestamp).format('L'),
    ].filter(_ => !!_)
      .join(', ');

    return `Expédié (${formattedSerialNumbersAndSentDate})`;
  }
};

const formatDelivery = order => {
  const {dapo, delivery} = order.prestations;
  if (!dapo) {
    return;
  }

  if (order.cancelled === true) {
    return 'Annulé';
  } else {
    if (dapo.confirmed !== true) {
      return 'Attente expédition';
    }
    if (delivery) {
      if (delivery.delivered === true) {
        if (delivery.deliveryTimestamp) {
          return 'Livré (' + delivery.trackingNumber + ', ' + moment(delivery.deliveryTimestamp).format('L') + ')';
        }
        return 'Livré (' + delivery.trackingNumber + ')';
      } else if (delivery.error === true) {
        return 'Erreur (' + delivery.trackingNumber + ')';
      } else {
        return 'En cours (' + delivery.trackingNumber + ')';
      }
    } else {
      return 'Inconnu';
    }
  }
};

const formatFSPlus = (order, postmanInstallationTypes) => {
  const {dapo, fsPlus} = order.prestations;

  if (!dapo) {
    return;
  }

  let postmanInstallationLabel = 'NA';
  if (postmanInstallationTypes) {
    let postmanInstallationType = order.prestations.fsPlus?.typePresta || null;
    postmanInstallationLabel = (postmanInstallationType ? postmanInstallationTypes[postmanInstallationType] : 'NON').toLocaleLowerCase();
  }

  if (order.cancelled === true) {
    return 'Annulé';
  } else {
    if (postmanInstallationLabel === 'SANS') {
      return 'Sans installation';
    } else if (!dapo || dapo.confirmed !== true) {
      if (fsPlus?.sent === true) {
        return 'Ne pas faire de demande';
      }
      return 'Attente expédition (' + postmanInstallationLabel + ')';
    } else if (fsPlus) {
      if (fsPlus.completed === true) {
        if (fsPlus.completedTimestamp) {
          return 'Effectué (' + postmanInstallationLabel + ', ' + moment(fsPlus.completedTimestamp).format('L') + ')';
        }
        return 'Effectué (' + postmanInstallationLabel + ')';
      } else if (fsPlus.error === true) {
        return 'Erreur (' + postmanInstallationLabel + ')';
      } else if (fsPlus.sent === false) {
        return 'À commander (' + postmanInstallationLabel + ')';
      } else {
        return 'En cours (' + postmanInstallationLabel + ')';
      }
    } else {
      return 'Inconnu';
    }
  }
};

const formatAddress = (address) => {
  if (address) {
    return address.firstName + ' ' + address.lastName + '\n' + address.addressLine + '\n' + address.zipCode + ' ' + address.city;
  }
  return 'NA';
};

const getNextOccurrenceNumber = (orders) => {
  return ('00' + (Math.max.apply(Math, orders.map(order => parseInt(order.occurrenceNumber, 10))) + 1)).slice(-2);
};

export {formatEntries, getNextOccurrenceNumber};