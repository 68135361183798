import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import DeleteTicketModal from './DeleteTicketModal';

const DeleteTicketModalButton = ({ hidden, disabled, handleDeleteTicket }) => {
	const [show, setShow] = useState(false);
	
	if (hidden) return null;
	
	const handleShow = () => setShow(true);
	
	const handleHide = () => setShow(false);

	return <>
		<Button bsStyle='danger' className='pull-left' disabled={disabled} onClick={handleShow}>
			Supprimer
		</Button>
		<DeleteTicketModal
			show={show}
			onHide={handleHide}
			onSubmit={handleDeleteTicket}
		/>
	</>;
};

DeleteTicketModalButton.propTypes = {
	hidden: PropTypes.bool,
	disabled: PropTypes.bool,
	handleDeleteTicket: PropTypes.func,
};

export default DeleteTicketModalButton;