import React from 'react';
import PropTypes from 'prop-types';
import {branch, compose, pure, renderNothing, withProps} from 'recompose';
import { Label } from 'react-bootstrap';
import moment from 'moment';

import {BREAKAGE_INSURANCE_STATUS_TYPES} from '../../../../../utils/constants';

moment.locale('fr');

const BreakageInsuranceLabelPure = ({ label, style }) =>
    <Label bsStyle={style}>{label}</Label>;

BreakageInsuranceLabelPure.propTypes = {
    label: PropTypes.string,
    style: PropTypes.string,
};

const calculatedProps = withProps(
    ({ breakageInsurance }) => {
        let label,
            style;

        if(breakageInsurance?.status) {
            switch (breakageInsurance.status) {
                case BREAKAGE_INSURANCE_STATUS_TYPES.ENABLED.value:
                    label = 'Option Casse';
                    style = 'success';
                    break;
                case BREAKAGE_INSURANCE_STATUS_TYPES.SUBSCRIBED.value:
                    label = 'Option Casse souscrite';
                    style = 'warning';
                    break;
                case BREAKAGE_INSURANCE_STATUS_TYPES.TERMINATED.value:
                    label = 'Option Casse terminée';
                    style = 'default';
                    break;
                case BREAKAGE_INSURANCE_STATUS_TYPES.DISABLED.value:
                case BREAKAGE_INSURANCE_STATUS_TYPES.UNDEFINED.value:
                default:
                    label = 'Sans Option Casse';
                    style = 'danger';
                    break;
            }
        }

        return {
            label,
            style,
        };
    }
);

const BreakageInsuranceLabel = compose(
    branch(
        ({ breakageInsurance }) => !breakageInsurance,
        renderNothing,
    ),
    calculatedProps,
    pure,
)(BreakageInsuranceLabelPure);

BreakageInsuranceLabel.propTypes = {
    breakageInsurance: PropTypes.object,
};

export default BreakageInsuranceLabel;