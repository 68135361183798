import gql from 'graphql-tag';

const UPDATE_SIMSTATE_ON_CONTRACT_LIST = gql`
    mutation SimUpdateSimStateOnContractList($input: SimUpdateSimStateOnContractListInput!) {
        simUpdateSimStateOnContractList(input: $input) {
            errors {
                reason
                subject
            }
            simInfos {
                contractNumber
                sim {
                    ICCID
                    currentState {
                        state
                    }
                    operator
                }
            }
        }
    }
`;

const GET_SIM_CONTRACT_HISTORY = gql`
    query GetSimContractHistory($id: String!) {
        getSimContractHistory(id: $id) {
            contractHistory {
                attached
                contractNumber
                detached
            }
        }
    }
`;

export {
  GET_SIM_CONTRACT_HISTORY,
  UPDATE_SIMSTATE_ON_CONTRACT_LIST,
};