import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Grid, Row} from 'react-bootstrap';
import { connectWebUserContext } from '../../../../hoc/withMeAndTeamsAndConstants';
import { isN1User, isN2OrHigher } from '../../../../utils/functions';
import { fetchDMD, fetchMaintenances, patchDMD } from '../../../../utils/requests';
import DeviceEditModal from '../../../devices/modals/DeviceEditModal';
import SimHistory from './Device/SimHistory';
import AndroidSwitch from './Device/AndroidSwitch';
import AppVersionsButton from './Device/AppVersions/AppVersionsButton';

import DetachDeviceButton from './Device/DetachDeviceButton';
import DeviceInfos from './Device/DeviceInfos';
import DeviceMetadataButtonGroup from './Device/DeviceMetadataButtonGroup';
import DevicesHistory from './Device/DevicesHistory';
import FlavorEdit from './Device/FlavorEdit';
import DeviceMaintenance from './Device/DeviceMaintenance';
import Sim from './Device/Sim';
import SimPlan from './Device/SimPlan';
import Software from './Device/Software';
import ToggleDeviceTransferButton from './Device/ToggleDeviceTransferButton';
import { extractPackagesInfoFromMaintenance } from '../../../../utils';
import { LoadingProgress } from '../../../components';
import { isIMEI, isMAC } from '../../../../utils/dmd';

const CORE_PACKAGE_NAME = 'com.tikeasy.core';
const INITIAL_VALIDATOR = {MAC: null, IMEI: null};

const Device = connectWebUserContext(({
  webUserJson,
  contractNumber,
  loadDmd,
}) => {

  const [loading, setLoading] = useState(false);
  const [dmd, setDmd] = useState({});
  const [dmdLocked, setDmdLocked] = useState(true);
  const [editDMD, setEditDMD] = useState(false);
  const [packagesWithVersion, setPackagesWithVersion] = useState({});
  const [savedDmd, setSavedDmd] = useState({});
  const [validator, setValidator] = useState(INITIAL_VALIDATOR);

  const currentDeviceIndex = dmd && dmd.states ? 0 : -1;

  const fetchHardwareMetadata = useCallback(() => {
    setLoading(true);
    fetchDMD(contractNumber)
    .then(response => {
      setDmd({...response});
      setSavedDmd(response);
      setLoading(false);
      loadDmd(response);
    },
    () => setLoading(false));
  }, [contractNumber, loadDmd, setLoading]);

  useEffect(() => {
    fetchHardwareMetadata();
    fetchMaintenances(contractNumber).then(maintenance => {
      const packages = extractPackagesInfoFromMaintenance(maintenance);
      setPackagesWithVersion(packages);
    });
  }, [contractNumber, fetchHardwareMetadata]);
  
  const saveDmd = () => {
    setLoading(true);
    patchDMD(contractNumber, dmd)
    .then(response => {
      setDmd({...response});
      setSavedDmd(response);
      setDmdLocked(true);
      setLoading(false);
    },
    () => setLoading(false));
  }

  const handleCancel = () => {
    setDmd(savedDmd);
    setValidator(INITIAL_VALIDATOR);
    setDmdLocked(true);
  }

  const handleDmdPropertyChange = (propertyName, value) => {
    let newDmd = {...dmd};

    if (value || value === false || value === 0) {
      newDmd[propertyName] = value;
    } else if (savedDmd.hasOwnProperty(propertyName)) {
      newDmd[propertyName] = '';
    } else {
      delete newDmd[propertyName];
    }

    setDmd(newDmd);
  }

  const handleSave = () => {
    const atLeastOne = dmd.MAC !== '' || dmd.IMEI !== '';
    const validator = {
      MAC: !atLeastOne || !isMAC(dmd.MAC) ? 'error' : null,
      IMEI1: !atLeastOne || !isIMEI(dmd.IMEI1) ? 'error' : null,
    };

    setValidator(validator);

    if (JSON.stringify(validator).indexOf('error') === -1) {
      saveDmd();
    }
  }

  return (
    <Grid fluid>
      <LoadingProgress show={loading} />
      
      {editDMD && (
        <DeviceEditModal
          dmd={dmd}
          onHide={() => setEditDMD(false)}
          handleAfterEdit={fetchHardwareMetadata}
          onlySim
        />
      )}
      <Row>
        <DeviceInfos
          dmd={dmd}
          dmdLocked={dmdLocked}
          validator={validator}
          handleDmdPropertyChange={handleDmdPropertyChange}
        />

        <DeviceMetadataButtonGroup
          dmdLocked={dmdLocked}
          somethingHasBeenModified={JSON.stringify(dmd) !== JSON.stringify(savedDmd)}
          handleCancel={handleCancel}
          handleEdit={() => setDmdLocked(false)}
          handleSave={handleSave}
        />
        <hr/>

        <h4>Sim</h4>

        <Row>
          <Col md={4}>
            <SimPlan dmd={dmd}/>
          </Col>
        </Row>

        <Sim
          dmd={dmd}
          handleDmdPropertyChange={handleDmdPropertyChange}
          handleLoading={setLoading}
          handleManageSim={fetchHardwareMetadata}
          onEditDMDClick={() => setEditDMD(true)}
        />

        <Row>
          <Col md={4}>
            <FormGroup>
              <ControlLabel>Numéro de téléphone de la carte SIM (ne pas communiquer au client)</ControlLabel>
              <FormControl
                type='text'
                value={dmd.sim && dmd.sim.phone ? dmd.sim.phone : ''}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <hr/>

        <DevicesHistory
          currentDmdStateIndex={currentDeviceIndex}
          dmd={dmd}
        />

        <SimHistory />

        <hr/>
        
        <Row>
          {!isN1User(webUserJson) && (
            <Col md={4}>
              <DetachDeviceButton
                dmdID={dmd.id}
                onSuccess={(response) => setDmd({states: response.devices})}
              />
            </Col>
          )}
            
          {!!contractNumber && isN2OrHigher(webUserJson) && (
            <Col md={4}>
              <ToggleDeviceTransferButton
                contractNumber={contractNumber}
                canBeTransferred={dmd.canBeTransferred}
                setDmd={setDmd}
              />
            </Col>
          )}
        </Row>

        <Software
          dmd={dmd}
          currentCoreVersion={packagesWithVersion?.[CORE_PACKAGE_NAME]?.version}
        />

        <Row>
          <Col md={4}>
            <FlavorEdit
              contractNumber={contractNumber}
              dmd={dmd}
              handleDmdChange={handleDmdPropertyChange}
            />
          </Col>

          <Col md={4} style={{paddingTop: '25px'}}>
            <AppVersionsButton packagesWithVersion={packagesWithVersion} />
          </Col>

          <Col md={4}>
            <AndroidSwitch hasSwitchedFromArdoizToAndroidRecently={dmd.hasSwitchedFromArdoizToAndroidRecently} />
          </Col>
        </Row>
        <hr/>

        <DeviceMaintenance
          contractNumber={contractNumber}
          dmd={dmd}
          loading={loading}
          setLoading={setLoading}
        />
      </Row>
    </Grid>
  );
});

Device.propTypes = {
  contractNumber: PropTypes.string,
  loadDmd: PropTypes.func,
};

export default Device;