import gql from 'graphql-tag';

const CUSTOMER_FRAGMENT = gql`
    fragment customerFields on Customer {
        contractType
        created
        current {
            addressLine
            cellphone
            city
            company
            country
            email
            firstName
            gender
            lastName
            phone
            yearOfBirth
            zipCode
        }
        hasToBeSyncInProAbono
        customerTypology
        id
    }
`;

const CUSTOMER_FULL_FRAGMENT = gql`
    fragment customerFullFields on Customer {
        bankID {
            Bic
            DateSignature
            Iban
            NameCustomer
        }
        ...customerFields
    }
    ${CUSTOMER_FRAGMENT}
`;

const GET_CURRENT_SUBSCRIBER = gql`
    query GetCurrentSubscriber($input: CurrentSubscriberInput!) {
        currentSubscriber(input: $input) {
            subscriber {
                ...customerFullFields
            }
        }
    }
    ${CUSTOMER_FULL_FRAGMENT}
`;

const GET_CUSTOMER = gql`
    query GetCustomer($id: String!) {
        customer(id: $id) {
            customer {
                ...customerFullFields
            }
        }
    }
    ${CUSTOMER_FULL_FRAGMENT}
`;

const CREATE_CUSTOMER = gql`
    mutation CreateCustomer($customerInput: CustomerCreateInput!) {
        customerCreate(input : $customerInput) {
            customer {
                ...customerFields
            }
        }
    }
    ${CUSTOMER_FRAGMENT}
`;

const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($customerInput: CustomerUpdateInput!) {
        customerUpdate(input : $customerInput) {
            customer {
                ...customerFields
            }
        }
    }
    ${CUSTOMER_FRAGMENT}
`;

export {
    CUSTOMER_FRAGMENT,
    CUSTOMER_FULL_FRAGMENT,
    GET_CURRENT_SUBSCRIBER,
    GET_CUSTOMER,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
};