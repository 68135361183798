import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { compose, pure, withHandlers, withProps } from 'recompose';

import { DEVICE_RESET_MODES } from '../../../../../../utils/constants';
import jsRoutes from '../../../../../../routes/routes';

const ResetAuthorizedSelectPure = ({ accountResetAuthorizedMode, handleSelectAccountResetAuthorized }) => (
    <FormGroup>
        <ControlLabel>Reset autorisé</ControlLabel>
        <FormControl componentClass='select'
                     value={accountResetAuthorizedMode}
                     onChange={(event) => handleSelectAccountResetAuthorized(event.target.value)}>
            { DEVICE_RESET_MODES.map(mode => <option key={mode.value} value={mode.value}>{mode.label}</option>) }
        </FormControl>
    </FormGroup>
);

ResetAuthorizedSelectPure.propTypes = {
    accountResetAuthorizedMode: PropTypes.string,
    handleSelectAccountResetAuthorized: PropTypes.func,
};

const getProps = withProps(({ resetAuthorized, resetAuthorizedMode }) => ({
    accountResetAuthorizedMode: resetAuthorizedMode ? resetAuthorizedMode : (resetAuthorized ? 'ALWAYS' : 'NONE') // Retro compatibility
}));

const setAccountResetAuthorized = (contractNumber, value) => jsRoutes.controllers.backoffice.MaintenanceV2Controller
    .setAccountResetAuthorized(contractNumber, value)
    .ajax()

const handlers = withHandlers({
    handleSelectAccountResetAuthorized: ({ contractNumber, setLoading, updateMaintenanceInfo }) => (value) =>
        setLoading(
            true,
            () => setAccountResetAuthorized(contractNumber, value).then(
                () => updateMaintenanceInfo(
                    { resetAuthorizedMode: value },
                    () => setLoading(false)
                ),
                () => setLoading(false),
            )
        ),
});

const ResetAuthorizedSelect = compose(
    getProps,
    handlers,
    pure,
)(ResetAuthorizedSelectPure);

ResetAuthorizedSelect.propTypes = {
    contractNumber: PropTypes.string,
    resetAuthorized: PropTypes.bool,
    resetAuthorizedMode: PropTypes.string,
    setLoading: PropTypes.func,
    updateMaintenanceInfo: PropTypes.func,
};

export default ResetAuthorizedSelect;