import PropTypes from 'prop-types';
import React from 'react';
import {compose, pure, withHandlers, withProps} from 'recompose';
import jsRoutes from '../../routes/routes';
import ActionButtons from './SimManagement/ActionButtons';
import ChangeOperatorButton from './SimManagement/ActionButtons/ChangeOperatorButton';
import ContractHistory from './SimManagement/ContractHistory';
import {withSimManagementContext} from './SimManagement/Helpers';

const SimManagementPure = ({
                             sim,
                             simState,
                             onEditOperatorClick,
                           }) => (
  <>
    <ChangeOperatorButton onClick={onEditOperatorClick}/>
    <ActionButtons simState={simState}/>
    <ContractHistory simId={sim?.id}/>
  </>
);

const updateSimHandlers = withHandlers({
  setSim: ({
             callBack,
             handleLoading,
           }) => async (sim) => {
    await handleLoading(true);

    jsRoutes.controllers.backoffice.SupportV2Controller.setSim(sim.ICCID)
      .ajax({
        data: JSON.stringify(sim),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
      })
      .then(
        async (response) => {
          await callBack(response);
          handleLoading(false);
        },
        () => handleLoading(false),
      );
  },
});

const buttonHandlers = withHandlers({
  onEditOperatorClick: ({
                          fromDevice,
                          operator,
                          sim,
                          editOperatorHandler,
                          setSim,
                        }) => () => {
    if (fromDevice) {
      setSim({
        ...sim,
        operator,
      });
    } else {
      editOperatorHandler(() => o => {
        setSim({
          ...sim,
          operator: o,
        });
      });
    }
  },
});

const SimManagement = compose(
  withProps(
    ({displayVertical, sim}) => ({
      buttonSize: displayVertical ? 'xsmall' : 'sm',
      simState: sim?.currentState?.state || 'N/A',
    }),
  ),
  updateSimHandlers,
  buttonHandlers,
  withSimManagementContext,
  pure,
)(SimManagementPure);

SimManagement.propTypes = {
  displayVertical: PropTypes.bool,
  fromDevice: PropTypes.bool,
  operator: PropTypes.string,
  sim: PropTypes.object,
  callBack: PropTypes.func,
  editOperatorHandler: PropTypes.func,
  handleLoading: PropTypes.func,
};

export default SimManagement;