import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'react-bootstrap';
import {branch, compose, pure, renderNothing} from 'recompose';

const ToucanListOperationErrorsPure = ({errors}) => (
    <Alert bsStyle='danger'>
        <ul>
            {errors.map((error, index) => <li key={index}>{error}</li>)}
        </ul>
    </Alert>
);

ToucanListOperationErrorsPure.propTypes = {
    errors: PropTypes.array,
};

const ToucanListOperationErrors = compose(
    branch(
        ({errors}) => !errors?.length,
        renderNothing,
    ),
    pure,
)(ToucanListOperationErrorsPure);

ToucanListOperationErrors.propTypes = {
    errors: PropTypes.array,
};

export default ToucanListOperationErrors;