import React, { Fragment } from 'react';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeviceInfos = ({ dmd, dmdLocked, validator, handleDmdPropertyChange }) => (
    <Fragment>
        <h4>Matériel</h4>
        <Row>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Numéro de série</ControlLabel>
                    <FormControl type='text' value={dmd.SN ? dmd.SN : ''} readOnly />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup validationState={validator.MAC} >
                    <ControlLabel>Adresse MAC</ControlLabel>
                    <FormControl type='text' value={dmd.MAC ? dmd.MAC : ''}
                                 onChange={(e) => handleDmdPropertyChange('MAC', e.target.value)}
                                 readOnly={dmdLocked} />
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup validationState={validator.IMEI1}>
                    <ControlLabel >Numéro IMEI</ControlLabel>
                    <FormControl type='text' value={dmd.IMEI1 ? dmd.IMEI1: ''}
                                 onChange={(e) => handleDmdPropertyChange('IMEI1', e.target.value)}
                                 readOnly={dmdLocked} />
                </FormGroup>
            </Col>
        </Row>
    </Fragment>
);

DeviceInfos.propTypes = {
    dmd: PropTypes.object,
    dmdLocked: PropTypes.bool,
    validator: PropTypes.object,
    handleDmdPropertyChange: PropTypes.func,
};

export default DeviceInfos;