import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormControl, FormGroup, Modal} from 'react-bootstrap';
import {sortAlpha} from '../../../../../utils/Array';
import {getTeamType} from '../../../../../utils/functions';
import ProcessingDatesInputs from '../../ProcessingDatesInputs';
import { connectTypesContext } from '../../../../../hoc/withMeAndTeamsAndConstants';

const TransferModal = connectTypesContext(({
	types,
	show,
	onHide,
	initialTeam,
	teamOptions,
	ticket,
	handleTeamChange,
	handleDateChange,
	onSubmit,
}) => (
	<Modal show={show} onHide={onHide}>
		<Modal.Header closeButton>
			<Modal.Title>Transférer le ticket</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<FormGroup>
				<ControlLabel>Transférer à une autre team</ControlLabel>
				<FormControl componentClass='select' data-test-id='teamSelect' placeholder='Equipe' value={ticket.team} onChange={handleTeamChange}>
					{sortAlpha(teamOptions, _ => getTeamType(types, _)).map(type_team => (
						<option className={type_team === initialTeam ? 'hidden' : ''} key={type_team} value={type_team}>
							{getTeamType(types, type_team)}
						</option>
					))}
				</FormControl>
			</FormGroup>
			<ProcessingDatesInputs ticket={ticket} handleDateChange={handleDateChange} />
		</Modal.Body>
		<Modal.Footer>
			<Button bsStyle='warning' data-test-id='transferModalSubmit' disabled={ticket.team === initialTeam} onClick={onSubmit}>
				Transférer
			</Button>
		</Modal.Footer>
	</Modal>
));

TransferModal.propTypes = {
	initialTeam: PropTypes.string,
	show: PropTypes.bool,
	teamOptions: PropTypes.array,
	ticket: PropTypes.object,
	types: PropTypes.object,
	handleChange: PropTypes.func,
	handleChangeDate: PropTypes.func,
	onHide: PropTypes.func,
	handleTransferTicket: PropTypes.func,
};

export default TransferModal;