import React from 'react';
import PropTypes from 'prop-types';
import {InputGroup} from 'react-bootstrap';
import {SingleDatePicker as ReactSingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import {compose, pure, withHandlers, withState} from 'recompose';
import moment from 'moment';

import './SingleDatePicker.css';

const withFocusedState = withState('focused', 'setFocused', false);

const SingleDatePickerPure = ({className = '', focused, value, onChange, setFocused, schema = {params: {}}, handleClear, rawErrors}) => {
    const {params = {}} = schema;
    const {disabled} = params;

    return (
        <InputGroup bsSize='sm' className={`singleDatePickerInputGroup ${rawErrors && rawErrors.length && 'inError'} ${className}`}>
            <ReactSingleDatePicker
                date={value ? moment(value) : null}
                onDateChange={v => onChange(v ? moment(v).valueOf() : v)}
                focused={focused}
                onFocusChange={({focused}) => setFocused(focused)}
                small={true}
                {...params}
            />
            <InputGroup.Addon
                onClick={disabled ? null : handleClear}
                style={{cursor: (value && !disabled) ? 'pointer' : 'not-allowed'}}
            >
                <div style={{opacity: (value && !disabled) ? 1 : 0.5}}>×</div>
            </InputGroup.Addon>
        </InputGroup>
    );
};

SingleDatePickerPure.propTypes = {
    handleClear: PropTypes.func,
};

const withClearHandler = withHandlers({
    handleClear: ({onChange}) => () => onChange(),
});

const SingleDatePicker = compose(
    withFocusedState,
    withClearHandler,
    pure,
)(SingleDatePickerPure);

export default SingleDatePicker;
