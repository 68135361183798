import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, Glyphicon} from 'react-bootstrap';
import ReactTable from 'react-table';
import {compose, pure, withHandlers, withProps} from 'recompose';
import {tsToDateForInput} from '../../../../utils/Date';
import {ReactTableTextProps} from '../../../../utils/ReactTable';
import B2BFeatureForm from './B2BFeatureForm';

const getColumns = ({
                      features,
                      removeB2BFeature,
                    }) => ([
  {
    Header: 'Option',
    accessor: 'featureId',
    Cell: cell => {
      const feature = features.find(f => f.id === cell.value);
      return feature?.label;
    },
  }, {
    Header: 'Active',
    accessor: 'enabled',
    Cell: cell => cell.value ? 'Oui' : 'Non',
  }, {
    Header: 'Début',
    accessor: 'start',
    type: 'date',
    width: 80,
    Cell: cell => <div>{cell.value ? tsToDateForInput(cell.value) : null}</div>,
  }, {
    Header: 'Fin',
    accessor: 'end',
    type: 'date',
    width: 80,
    Cell: cell => <div>{cell.value ? tsToDateForInput(cell.value) : null}</div>,
  }, {
    Header: '',
    accessor: 'featureId',
    Cell: cell => (
      <Button bsSize="sm"
              bsStyle="danger"
              className="center-block"
              onClick={() => removeB2BFeature(cell.value)}>
        <Glyphicon glyph="minus"/>
      </Button>
    ),
  },
]);

const B2bFeatureTableWidgetPure = ({
                                     availableFeatures,
                                     b2bFeatures,
                                     columns,
                                     addB2BFeature,
                                   }) => (
  <>
    <ControlLabel bsSize="sm">Ajouter une option à la liste</ControlLabel>

    <B2BFeatureForm availableFeatures={availableFeatures}
                    addB2BFeature={addB2BFeature}/>

    <br/>

    <ControlLabel bsSize="sm">Options à ajouter/modifier</ControlLabel>

    <ReactTable
      className="-highlight"
      columns={columns}
      data={b2bFeatures}
      fill
      pageSize={b2bFeatures.length}
      resizable={false}
      showPagination={false}
      style={{
        border: 'none',
        marginTop: 5,
        minHeight: 110,
      }}
      {...ReactTableTextProps}
    />
  </>
);

B2bFeatureTableWidgetPure.propTypes = {
  availableFeatures: PropTypes.array,
  b2bFeatures: PropTypes.array,
  columns: PropTypes.array,
  addB2BFeature: PropTypes.func,
};

const handlers = withHandlers({
  addB2BFeature: ({b2bFeatures, setB2BFeatures}) => b2BFeature => setB2BFeatures([...b2bFeatures, b2BFeature]),
  removeB2BFeature: ({
                       b2bFeatures,
                       setB2BFeatures,
                     }) => b2BFeatureId => setB2BFeatures(b2bFeatures.filter(b2bFeature => b2bFeature.featureId !== b2BFeatureId)),
});

const B2BFeatureTableWidget = compose(
  handlers,
  withProps(
    ({features, removeB2BFeature}) => ({
      columns: getColumns({
        features,
        removeB2BFeature,
      }),
    }),
  ),
  pure,
)(B2bFeatureTableWidgetPure);

B2BFeatureTableWidget.propTypes = {
  availableFeatures: PropTypes.array,
  b2bFeatures: PropTypes.array,
  features: PropTypes.array,
  setB2BFeatures: PropTypes.func,
};

export default B2BFeatureTableWidget;