import React from 'react';
import DevicesSearchView from './devices/DevicesSearchView';
import { navigateTo } from '../utils/functions';
import { connectWebUserContext } from '../hoc/withMeAndTeamsAndConstants';
import { isDevicesAndSimsVisible } from '../utils/accessRights';

const Devices = connectWebUserContext(({webUserJson}) => {
  if (!webUserJson || !isDevicesAndSimsVisible(webUserJson)) {
    navigateTo('/ticket');
  }
  return <DevicesSearchView />;
});

export default Devices;