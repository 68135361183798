import {compose, graphql} from 'react-apollo';
import {graphqlDynamic} from 'react-apollo-dynamic-query';
import {withHandlers} from 'recompose';

import {RETURN_TYPES} from '../../../../../utils/AfterSale';
import {
    AFTERSALES_BY_CONTRACTNUMBER_QUERY,
    CANCEL_AFTERSALE_MUTATION,
    CREATE_AFTERSALE_SERVICE_MUTATION,
    CREATE_SATISFIED_OR_REFUNDED_MUTATION,
    UPDATE_AFTERSALE_ADDRESS_MUTATION,
    UPDATE_AFTERSALE_QUOTE_STATE_MUTATION,
    UPDATE_AFTERSALE_STATUS_MUTATION
} from '../../../../../schema/AfterSaleSchema';

const createAfterSaleService = mutate =>
                                ({ comment, contractNumber, customerReturnAddress, email, phone, productState, productType, provider, reason, serialNumber, trackingNumber }) =>
    mutate({
        variables: {
            afterSale: {
                comment,
                contractNumber,
                customerReturnAddress,
                email,
                phone,
                productState,
                productType,
                provider,
                reason,
                serialNumber,
                trackingNumber,
            },
        },
        update: (store, { data: { afterSaleOrderCreateAfterSaleService } }) => {
            const variables = {
                getAfterSaleOrdersInput: {
                    byContractNumber: contractNumber
                },
            };
            const data = store.readQuery({ query: AFTERSALES_BY_CONTRACTNUMBER_QUERY, variables });
            data.afterSaleOrders.afterSaleOrders
                .push(afterSaleOrderCreateAfterSaleService.afterSaleOrder);
            store.writeQuery({ query: AFTERSALES_BY_CONTRACTNUMBER_QUERY, variables, data });
        },
    });

const createSatisfiedOrRefunded = mutate =>
    ({ comment, contractNumber, customerReturnAddress, email, phone, productState, productType, provider, reason, serialNumber, trackingNumber }) =>
        mutate({
            variables: {
                afterSale: {
                    comment,
                    contractNumber,
                    email,
                    phone,
                    provider,
                    reason,
                    serialNumber,
                    trackingNumber,
                },
            },
            update: (store, { data: { afterSaleOrderCreateSatisfiedOrRefunded } }) => {
                const variables = {
                    getAfterSaleOrdersInput: {
                        byContractNumber: contractNumber
                    },
                };
                const data = store.readQuery({ query: AFTERSALES_BY_CONTRACTNUMBER_QUERY, variables });
                data.afterSaleOrders.afterSaleOrders
                    .push(afterSaleOrderCreateSatisfiedOrRefunded.afterSaleOrder);
                store.writeQuery({ query: AFTERSALES_BY_CONTRACTNUMBER_QUERY, variables, data });
            },
        });

const getCreationMutation = ({ returnType }) => {
    switch (returnType) {
        case RETURN_TYPES.AFTER_SALE_SERVICE.value:
            return CREATE_AFTERSALE_SERVICE_MUTATION;
        case RETURN_TYPES.DISPATCHED_SATISFIED_OR_REFUNDED.value:
        case RETURN_TYPES.SATISFIED_OR_REFUNDED.value:
            return CREATE_SATISFIED_OR_REFUNDED_MUTATION;
        case RETURN_TYPES.LEASING:
        case RETURN_TYPES.WITHOUT_REFUND:
            //return CREATE_TABLET_RETURN_MUTATION;
            break;
        default:
            return;
    }
};

const getCreationMutationFunction = (returnType) => {
    switch (returnType) {
        case RETURN_TYPES.AFTER_SALE_SERVICE.value:
            return createAfterSaleService;
        case RETURN_TYPES.DISPATCHED_SATISFIED_OR_REFUNDED.value:
        case RETURN_TYPES.SATISFIED_OR_REFUNDED.value:
            return createSatisfiedOrRefunded;
        case RETURN_TYPES.LEASING:
        case RETURN_TYPES.WITHOUT_REFUND:
            //return createTabletReturn;
            break;
        default:
            return;
    }
};

const submitUpdateAfterSaleAddress =  mutate =>
    ({ id, customerReturnAddress }) =>
        mutate({
            variables: {
                afterSaleOrderUpdateInput: {
                    afterSaleOrderID: id,
                    address: customerReturnAddress,
                },
            }
        });

const withUpdateAfterSaleAddressMutation = graphql(UPDATE_AFTERSALE_ADDRESS_MUTATION, {
    props: props => ({
        updateAfterSaleAddress: submitUpdateAfterSaleAddress(props.mutate)
    })
});

const submitUpdateAfterSaleQuoteState =  mutate =>
    ({ id, afterSaleRequest: { productState, productType, quoteState } }) =>
        mutate({
            variables: {
                afterSaleOrderUpdateInput: {
                    afterSaleOrderID: id,
                    productState,
                    productType,
                    quoteState,
                },
            }
        });

const withUpdateAfterSaleQuoteStateMutation = graphql(UPDATE_AFTERSALE_QUOTE_STATE_MUTATION, {
    props: props => ({
        updateAfterSaleQuoteState: submitUpdateAfterSaleQuoteState(props.mutate)
    })
});

const submitUpdateAfterSaleStatus =  mutate =>
    ({ id, currentStatus }) =>
        mutate({
            variables: {
                afterSaleOrderUpdateInput: {
                    afterSaleOrderID: id,
                    providerOutputInput: {
                        state: currentStatus,
                    },
                },
            }
        });

const withUpdateAfterSaleStatusMutation = graphql(UPDATE_AFTERSALE_STATUS_MUTATION, {
    props: props => ({
        updateAfterSaleStatus: submitUpdateAfterSaleStatus(props.mutate)
    })
});

const handlers = withHandlers({
    mutateAfterSale: ({ editedAfterSale, isEditMode, createAfterSale, onHide, updateAfterSaleAddress, updateAfterSaleQuoteState, updateAfterSaleStatus }) => afterSale => {
        if(!isEditMode) {
            createAfterSale(afterSale);
        } else {
            const customerReturnAddress = {
                addressLine: null,
                city: null,
                company: null,
                country: null,
                firstName: null,
                gender: null,
                lastName: null,
                phone: null,
                zipCode: null,
                __typename: 'CustomerAddress',
                ...afterSale?.customerReturnAddress,
            };

            const promises = [];
            if(
                afterSale?.currentState?.canBeUpdatedWithCustomerAddress &&
                JSON.stringify(customerReturnAddress) !== JSON.stringify(editedAfterSale?.afterSaleRequest?.customerReturnAddress)
            ) {
                promises.push(updateAfterSaleAddress(afterSale));
            }

            if(
                afterSale?.currentState?.canBeUpdatedWithQuoteAnswer &&
                afterSale?.afterSaleRequest?.quoteState
            ) {
                promises.push(updateAfterSaleQuoteState(afterSale));
            }

            if(
                afterSale?.currentState?.canBeUpdatedWithAfterSaleProviderOutput &&
                afterSale?.currentStatus !== editedAfterSale?.currentStatus
            ) {
                promises.push(updateAfterSaleStatus(afterSale));
            }

            Promise.all(promises)
                    .then(_ => onHide());
        }
    },
});

const withAfterSaleMutation = compose(
    graphqlDynamic(
        getCreationMutation,
        { props: ({ mutate, ownProps: { returnType } }) => ({ createAfterSale: getCreationMutationFunction(returnType)(mutate) }) }
    ),
    withUpdateAfterSaleStatusMutation,
    withUpdateAfterSaleAddressMutation,
    withUpdateAfterSaleQuoteStateMutation,
    handlers,
);

const withCancelAfterSaleMutation = graphql(CANCEL_AFTERSALE_MUTATION, { name: 'cancelAfterSale' });

export {
    withAfterSaleMutation,
    withCancelAfterSaleMutation,
};

export default withAfterSaleMutation;