import PropTypes from 'prop-types';
import React from 'react';
import {Button, ButtonToolbar, Glyphicon} from 'react-bootstrap';
import {branch, compose, pure, renderComponent, renderNothing} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {isN3User} from '../../../../../utils/functions';

const DeviceMetadataButtonGroupLockedPure = ({handleEdit}) => (
  <ButtonToolbar>
    <div className='pull-right form-group'>
      <Button bsStyle='primary' bsSize='small' onClick={handleEdit}>
        <Glyphicon glyph='pencil'/>&nbsp;Modifier
      </Button>
    </div>
  </ButtonToolbar>
);

const DeviceMetadataButtonGroupNotLockedPure = ({somethingHasBeenModified, handleCancel, handleSave}) => (
  <ButtonToolbar>
    <div className='pull-right form-group'>
      <Button bsStyle='danger' bsSize='small' onClick={handleCancel}>
        Annuler
      </Button>
      <Button bsStyle='success' bsSize='small' onClick={handleSave} disabled={!somethingHasBeenModified}>
        Valider
      </Button>
    </div>
  </ButtonToolbar>
);

const DeviceMetadataButtonGroup = compose(
  connectMeAndTeamsAndConstantsContext,
  branch(
    ({webUserJson}) => !isN3User(webUserJson),
    renderNothing,
  ),
  branch(
    ({dmdLocked}) => dmdLocked,
    renderComponent(DeviceMetadataButtonGroupLockedPure),
    renderComponent(DeviceMetadataButtonGroupNotLockedPure),
  ),
  pure,
)();

DeviceMetadataButtonGroup.propTypes = {
  dmdLocked: PropTypes.bool,
  somethingHasBeenModified: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
};

export default DeviceMetadataButtonGroup;