import React from 'react';
import ClearFiltersButton from '../../components/ClearFiltersButton';
import TicketsTableFilterInput from './TicketsTableFilterInput';

const TicketsTableFilters = ({loading, showClearButton, properties = [], tagList = [], filters, setFilters, setActivePage}) => {

	const createFilterInputChangeHandler = name => event => {
		setActivePage(1);
		if (name === '') {
			setFilters(null);
			return;
		}
		var newFilters = {...filters};
		if (!newFilters) {
			newFilters = {};
		}
		if (!event.target.value) {
			delete newFilters[name];
		} else {
			newFilters[name] = event.target.value;
		}
		setFilters(newFilters);
	}

	const handleTagsChange = value => {
		let newFilters = {...filters};
		if (!newFilters) {
			newFilters = {};
		}
		if (!value || value.length === 0) {
			delete newFilters.tags;
		} else {
			newFilters.tags = value;
		}
		setFilters(newFilters);
		setActivePage(1);
	}

	return (
		<tr>
			{properties.map(({name, ...property}) => name === 'tags'
			? <TicketsTableFilterInput
					key={'property_filter_' + name}
					name={name}
					value={filters && filters[name] ? filters[name] : null}
					onChange={handleTagsChange}
					tags={tagList}
				/>
			:	<TicketsTableFilterInput
					key={'property_filter_' + name}
					name={name}
					value={filters && filters[name] ? filters[name] : ''}
					onChange={createFilterInputChangeHandler(name)}
				/>
			)}
			<th>
				{showClearButton && (
					<ClearFiltersButton
						disabled={loading && !!filters}
						onClick={createFilterInputChangeHandler('')}
					/>
				)}
			</th>
		</tr>
	);
};

export default TicketsTableFilters;