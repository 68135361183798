import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {ButtonToolbar, Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import {hasTeamViewerRight} from '../../../../../utils/UserRights';

import TeamViewerButton from '../AccountMaintenance/AccountMaintenances/TeamViewerButton';
import CreateResetUpdateDeviceMaintenanceButton from './DeviceMaintenance/CreateResetUpdateDeviceMaintenanceButton';

const DeviceMaintenancePure = ({
                           consoDataValue,
                           contractNumber,
                           loading,
                           lockPasswordValue,
                           operationsRights,
                           setLoading,
                         }) => (
  <Fragment>
    <h4>Maintenance</h4>
    <Row>
      <Col md={6}>
        <FormGroup>
          <ControlLabel>Code de déverrouillage</ControlLabel>
          <FormControl type="text" value={lockPasswordValue} readOnly/>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <ControlLabel>Consommation 3G</ControlLabel>
          <FormControl type="text" value={consoDataValue} readOnly/>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <ControlLabel>Opérations</ControlLabel>
        <ButtonToolbar>
          <TeamViewerButton contractNumber={contractNumber}
                            disabled={loading}
                            hasTeamviewerRight={operationsRights.hasTeamviewerRight}/>


          <CreateResetUpdateDeviceMaintenanceButton contractNumber={contractNumber}
                                                    disabled={loading}
                                                    setLoading={setLoading}/>
        </ButtonToolbar>
      </Col>
    </Row>
  </Fragment>
);

DeviceMaintenancePure.propTypes = {
  consoDataValue: PropTypes.string,
  contractNumber: PropTypes.string,
  loading: PropTypes.bool,
  lockPasswordValue: PropTypes.string,
  operationsRights: PropTypes.object,
  setLoading: PropTypes.func,
};

const DeviceMaintenance = compose(
  connectMeAndTeamsAndConstantsContext,
  withProps(({dmd, webUserJson}) => {
    const operationsRights = {
      hasTeamviewerRight: hasTeamViewerRight(webUserJson),
    };

    return {
      consoDataValue: dmd.consoData > 0 ? `${Math.floor(dmd.consoData / 1048576)} Mo` : 'NA',
      lockPasswordValue: !dmd.lockPassword ? 'Désactivé' : `Activé (${dmd.lockPassword})`,
      operationsRights,
    };
  }),
  pure,
)(DeviceMaintenancePure);

DeviceMaintenancePure.propTypes = {
  contractNumber: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default DeviceMaintenance;