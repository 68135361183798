import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Col, Panel, Row} from 'react-bootstrap';

import {connectContractNumberContext} from '../../../hoc/contractNumber';
import {connectWebUserContext} from '../../../hoc/withMeAndTeamsAndConstants';
import {isAfterSale} from '../../../utils/AfterSale';
import {removeExtraWhiteSpaces} from '../../../utils/Strings';

import AfterSaleTabletReturnForm from './AfterSaleTabletReturnForm';
import NewTicketForm from './NewTicketForm';
import NewTicketActions from './NewTicketActions';

const NewTicket = connectContractNumberContext(connectWebUserContext(
  ({ contractNumber, webUserJson, index, options, tagList, ticket, handleTicketChange, handleTicketRemove, handleTicketSave }) => {
  
  const [file, setFile] = useState(null);
  const [showTabletReturnForm, setShowTabletReturnForm] = useState(false);
  const [tagsState, setTagsState] = useState([]);

  const addTicketTags = ticket => {
    if (tagsState && tagsState.length > 0) {
      if (!ticket.metadata) {
        ticket.metadata = {};
      }
      ticket.metadata.tags = tagsState.map(data => data.value);
    } else {
      if (ticket.metadata && ticket.metadata.tags) {
        ticket.metadata.tags = [];
      }
    }

    return ticket;
  }

  const handleTicketPropertyChange = (propertyName, event) => {
    let newTicket = {...ticket};
    const value = event.target.value;
    newTicket[propertyName] = value;
    handleTicketChange(newTicket);

    if (propertyName === 'subject' && isAfterSale(value) !== isAfterSale(ticket.subject)) {
      setShowTabletReturnForm(false);
    }
  }

  const handleFileChange = event => setFile(event.target.files[0]);

  const handleSaveData = data => {
    if (!data) {
      data = new FormData();
    }

    data.append(
      'ticket',
      JSON.stringify({
        ...ticket,
        content: removeExtraWhiteSpaces(ticket.content),
      }),
    );

    if (file) {
      data.append('attachment_0', file);
    }

    handleTicketSave(data);
  }

  const handleSaveAfterSale = callback => {
    const data = new FormData();

    data.append('ticket', JSON.stringify(ticket));
    if (file) {
      data.append('attachment_0', file);
    }

    handleTicketSave(data, callback);
  }

  const handleShowTabletReturnForm = () => setShowTabletReturnForm(true);

  const handleHideTabletReturnForm = () => setShowTabletReturnForm(false);

  const handleCancel = () => handleTicketRemove(ticket);

  const title = `Nouveau ticket ${index + 1}`;

  return (
    <Panel>
      <Panel.Heading>{title}</Panel.Heading>
      <Panel.Body>
        <Row>
          <Col md={12}>
            <NewTicketForm
              disabled={showTabletReturnForm}
              selectedTags={tagsState}
              ticket={ticket}
              tagList={tagList}
              canalTypes={options.type_canal}
              subjectTypes={options.type_demande}
              handleChange={handleTicketPropertyChange}
              handleTagChange={setTagsState}
              handleFileChange={handleFileChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <NewTicketActions
              ticket={ticket}
              contractNumber={contractNumber}
              options={options}
              addTicketTags={addTicketTags}
              handleTicketChange={handleTicketChange}
              handleSaveData={handleSaveData}
              showTabletReturnForm={showTabletReturnForm}
              handleShowTabletReturnForm={handleShowTabletReturnForm}
              handleCancel={handleCancel}
            />
          </Col>
        </Row>
        <>
          {showTabletReturnForm && (
            <AfterSaleTabletReturnForm
              options={options}
              ticket={ticket}
              addTicketTags={addTicketTags}
              handleTicketChange={handleTicketChange}
              handleSave={handleSaveAfterSale}
              onHide={handleHideTabletReturnForm}
            />
          )}
        </>
      </Panel.Body>
    </Panel>
  );
}));

NewTicket.propTypes = {
  index: PropTypes.number,
  options: PropTypes.object,
  tagList: PropTypes.array,
  ticket: PropTypes.object,
  handleTicketChange: PropTypes.func,
  handleTicketRemove: PropTypes.func,
  handleSave: PropTypes.func,
};

export default NewTicket;