import PropTypes from 'prop-types';
import React from 'react';
import './Contract.css';
import LaPosteContract from './contract/LaPosteContract';
import FreeContract from './contract/FreeContract';
import CustomerContract from './contract/CustomerContract';
import { CONTRACT_LAPOSTE, CONTRACT_LIBRE } from '../utils/constants';

const Contract = ({contractNumber, ticketNumber}) => {
  if (contractNumber === CONTRACT_LAPOSTE) {
    return <LaPosteContract contractNumber={contractNumber} ticketNumber={ticketNumber} />;
  }
  if (contractNumber === CONTRACT_LIBRE) {
    return <FreeContract contractNumber={contractNumber} ticketNumber={ticketNumber} />;
  }
  return <CustomerContract contractNumber={contractNumber} ticketNumber={ticketNumber} />;
}

Contract.propTypes = {
  contractNumber: PropTypes.string,
  ticketNumber: PropTypes.string,
};

export default Contract;