import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {Table} from 'react-bootstrap';
import {CopyTableDataButton, UltimatePagination} from '../../components';
import {isEmpty} from '../../../utils/Array';
import DevicesTableHead from './DevicesTableHead';
import DevicesTableBody from './DevicesTableBody';

const ITEMS_PER_PAGE = 50;

const COPY_TABLE_DATA_BUTTON_PROPERTIES = [
	{label: 'Contrat', key: 'contractNumber'},
	{label: 'SN', key: 'SN'},
	{label: 'IMEI', key: 'IMEI'},
	{label: 'ICCID', key: 'ICCID'}
];

const compare = (a, b) => {
	let diff = a.SN.localeCompare(b.SN);
	if (diff === 0) {
			diff = a.created - b.created;
	}
	return diff;
};

const DevicesTable = ({
	entries = [],
	loading,
	properties,
	noPagination,
	tags,
	createAssignContractClickHandler,
	createCreateContractClickHandler,
	createDetachContractClickHandler,
	createEnableTransferClickHandler,
	createDisableTransferClickHandler,
	createEditDMDClickHandler,
	createContractHistoryClickHandler
}) => {
	const [activePage, setActivePage] = useState(1);
	const [filters, setFilters] = useState(null);

	const createFilterInputChangeHandler = useCallback(name => event => {
		const value = event.target.value;
		let newFilters = null;
		
		if (name !== '') {
			newFilters = {...filters};
			if (!value) {
				delete newFilters[name];
			} else {
				newFilters[name] = value;
			}
		}

		setFilters(newFilters);
		setActivePage(1);
	}, [filters, setActivePage]);
	
	const {sortedEntries, pageNumber} = useMemo(() => {

		const isFiltered = entry => {
			if (filters) {
				for (var property in filters) {
					if (
						!entry?.[property] ||
						entry[property].toString().toLocaleLowerCase().indexOf(filters[property].toLocaleLowerCase()) === -1
					) {
						return false;
					}
				}
			}
			return true;
		};

		let pageNumber = 1;
		const filteredEntries = entries.filter(isFiltered);
	
		if (filteredEntries.length > 0) {
			pageNumber = Math.ceil(filteredEntries.length / ITEMS_PER_PAGE);
		}

		const sortedEntries = filteredEntries.sort(compare).slice((activePage - 1) * ITEMS_PER_PAGE, (activePage) * ITEMS_PER_PAGE);

		return {sortedEntries, pageNumber};
	}, [activePage, entries, filters]);

	if (isEmpty(entries)) return null;

	return (
		<div>
			<div className='flex justify-space-between align-center'>
				<p className='text-muted no-margin'>
					Résultats de la recherche (50 premiers résultats max) :
				</p>
				<div>
					<CopyTableDataButton entries={entries} properties={COPY_TABLE_DATA_BUTTON_PROPERTIES} />
				</div>
			</div>

			<Table striped bordered condensed hover>
				<DevicesTableHead properties={properties} />
				<DevicesTableBody
					entries={sortedEntries}
					filters={filters}
					loading={loading}
					properties={properties}
					tags={tags}
					createFilterInputChangeHandler={createFilterInputChangeHandler}
					createAssignContractClickHandler={createAssignContractClickHandler}
					createCreateContractClickHandler={createCreateContractClickHandler}
					createDetachContractClickHandler={createDetachContractClickHandler}
					createEnableTransferClickHandler={createEnableTransferClickHandler}
					createDisableTransferClickHandler={createDisableTransferClickHandler}
					createEditDMDClickHandler={createEditDMDClickHandler}
					createContractHistoryClickHandler={createContractHistoryClickHandler}
				/>
			</Table>
			{!noPagination && (
				<div className='text-center'>
					<UltimatePagination
						currentPage={activePage}
						totalPages={pageNumber}
						onChange={setActivePage}
					/>
				</div>
			)}
		</div>
	);
}

DevicesTable.propTypes = {
	entries: PropTypes.array,
	loading: PropTypes.bool,
	noPagination: PropTypes.bool,
	properties: PropTypes.array,
	tags: PropTypes.array,
	createAssignContractClickHandler: PropTypes.func,
	createCreateContractClickHandler: PropTypes.func,
	createDetachContractClickHandler: PropTypes.func,
	createEnableTransferClickHandler: PropTypes.func,
	createDisableTransferClickHandler: PropTypes.func,
	createEditDMDClickHandler: PropTypes.func,
	createContractHistoryClickHandler: PropTypes.func,
};

export default DevicesTable;