import PropTypes from 'prop-types';
import React from 'react';
import {Col, Collapse, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {compose, pure} from 'recompose';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';

import {isCCAUser, isN2User, isValidationUser} from '../../../../../utils/functions';
import CollapsibleComponent from '../../../../components/CollapsibleComponent';
import DateInput from './DateInput';


const EndOfContractInfosPure = ({
                                  subscription,
                                  types,
                                  webUserJson,
                                  handleChange,
                                  handleDateChange,
                                  handleStringChange,
                                }) => (
  <>
    <h4>Fin de contrat</h4>
    <Row>
      <Col md={3}>
        <FormGroup>
          <ControlLabel>Date de demande du satisfait ou remboursé</ControlLabel>
          <DateInput modificationEnabled={isN2User(webUserJson) || isValidationUser(webUserJson)}
                     value={subscription.notSatisfyAsk}
                     handleChange={event => handleDateChange('notSatisfyAsk', event)}/>
        </FormGroup>
      </Col>

      <Col md={3}>
        <FormGroup>
          <ControlLabel>Date de demande de résiliation Sérénité</ControlLabel>
          <DateInput modificationEnabled
                     value={subscription?.metadata?.sereniteTerminationAskTimestamp}
                     handleChange={event => handleDateChange('metadata.sereniteTerminationAskTimestamp', event)}/>
        </FormGroup>
      </Col>
    </Row>

    <Row>
      <Col md={3}>
        <FormGroup>
          <ControlLabel>Date de demande de résiliation</ControlLabel>
          <DateInput modificationEnabled={isN2User(webUserJson) || isValidationUser(webUserJson)}
                     value={subscription.terminationAsk}
                     handleChange={event => handleDateChange('terminationAsk', event)}/>
        </FormGroup>
      </Col>

      <Collapse mountOnEnter={true}
                unmountOnExit={true}
                in={!!subscription.terminationAsk}
                onExited={() => handleChange('terminationReason', undefined)}>
        <CollapsibleComponent>
          <Col md={3}>
            <FormGroup validationState={subscription.terminationReason ? null : 'error'}>
              <ControlLabel>Nature de la résiliation<br/>&nbsp;</ControlLabel>
              <FormControl componentClass="select"
                           value={subscription.terminationReason}
                           onChange={event => handleStringChange('terminationReason', event)}>
                <option value="">---</option>
                {Object.keys(types.type_termination).map(type_value =>
                  <option key={type_value} value={type_value}>{types.type_termination[type_value]}</option>,
                )}
              </FormControl>
            </FormGroup>
          </Col>
        </CollapsibleComponent>
      </Collapse>

      <Col md={3}>
        <FormGroup>
          <ControlLabel>Date de fin de service<br/>&nbsp;</ControlLabel>
          <DateInput
            modificationEnabled={isN2User(webUserJson) || isCCAUser(webUserJson) || isValidationUser(webUserJson)}
            value={subscription.serviceTermination}
            handleChange={event => handleDateChange('serviceTermination', event)}/>
        </FormGroup>
      </Col>
    </Row>
  </>
);

EndOfContractInfosPure.propTypes = {
  subscription: PropTypes.object,
  webUserJson: PropTypes.object,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleStringChange: PropTypes.func,
};

const EndOfContractInfos = compose(
  connectMeAndTeamsAndConstantsContext,
  pure,
)(EndOfContractInfosPure);

EndOfContractInfos.propTypes = {
  subscription: PropTypes.object,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleStringChange: PropTypes.func,
};

export default EndOfContractInfos;