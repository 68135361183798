import PropTypes from 'prop-types';
import React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import {compose, pure, withHandlers} from 'recompose';
import {sendGCMOrder} from './SendGCMOrderButton/sendGCMOrder';

const SendGCMOrderButtonPure = ({handleSendGCMOrderClick}) => (
    <Button bsSize='xsmall'
            bsStyle='primary'
            data-test-id='sendGCMOrderButton'
            title='Envoyer ordre GCM'
            onClick={handleSendGCMOrderClick}>
        <Glyphicon glyph='circle-arrow-down'/>
    </Button>
);

SendGCMOrderButtonPure.propTypes = {
    handleSendGCMOrderClick: PropTypes.func,
};

const handlers = withHandlers({
    handleSendGCMOrderClick: ({contractNumber, type, setLoading}) => () =>
        setLoading(
            true,
            () => sendGCMOrder(contractNumber, type).then(
                () => setLoading(false),
                () => setLoading(false),
            ),
        ),
});

const SendGCMOrderButton = compose(
    handlers,
    pure,
)(SendGCMOrderButtonPure);

SendGCMOrderButton.propTypes = {
    contractNumber: PropTypes.string,
    type: PropTypes.string,
    setLoading: PropTypes.func,
};

export default SendGCMOrderButton;