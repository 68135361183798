import PropTypes from 'prop-types';
import {getContext, withContext} from 'recompose';

const withContractNumberContext = withContext({
        contractNumber: PropTypes.string,
    },
    ({ contractNumber }) => ({ contractNumber }),
);

const connectContractNumberContext = Component => getContext(
    {
        contractNumber: PropTypes.string,
    }
)(Component);

export { withContractNumberContext, connectContractNumberContext };