import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import FontAwesome from './FontAwesome';

class FAIconWithTextAndTooltip extends Component {
    render() {
        let tooltip = <Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>;
        let bsStyle = 'pill-'+this.props.bsStyle;
        return (
            <OverlayTrigger
                overlay={tooltip} placement='right'
                delayShow={300} delayHide={150}>
                <span>
                    <FontAwesome name={this.props.icon} className={bsStyle} spin={this.props.spin} pulse={this.props.pulse}/> {this.props.text}
                </span>
            </OverlayTrigger>
        )
    }
}

export default FAIconWithTextAndTooltip;