import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {Panel} from 'react-bootstrap';
import {connectTypesContext} from '../../../hoc/withMeAndTeamsAndConstants';
import { getSubjectTypeLabel, getTeamType } from '../../../utils/functions';
import TicketsTable, { PAGINATION } from '../TicketsTable';
import ContractTicketsTableRowActions from './ContractTicketsTableRowActions';

const CONTRACT_TICKET_PROPERTIES = [
	{
		label: 'ID',
    name: 'ticketNumber',
		size: '3%'
	},
	{
		label: 'Date Créa',
		name: 'created',
		type: 'date',
		size: '10%'
	},
	{
    label: 'Date Ferm',
    name: 'resultTimestamp',
		type: 'date',
    size: '10%',
	},
	{
    label: 'Équipe',
    name: 'teamLabel',
    size: '5%',
	},
	{
		label: 'Demande',
		name: 'subjectLabel',
		size: '7%'
	},
	{
		label: 'État',
		name: 'state',
		size: '3%'
	},
	{
		label: 'Contenu',
		name: 'content',
		size: '62%'
	}
];

const createContractTicketFormatter = types => (ticket = {}) => {
  const {answers = []} = ticket;

  let lastAnswer;
  if (answers.length > 0) {
    answers.sort((a = {}, b = {}) => a.created - b.created);
    lastAnswer = answers[answers.length - 1];
  }
  
  return {
    ...ticket,
		subjectLabel: getSubjectTypeLabel(types, ticket.subject) || '',
    teamLabel: getTeamType(types, ticket.team),
    closeResult: ticket.result,
    lastAnswer
  };
};

const ContractTicketsTable = connectTypesContext(({
  types,
  loading,
  tickets,
  tagList,
  selectedTicket,
  transferTicket,
  openTicket,
}) => {
  
  const formatContractTicket = useMemo(() => createContractTicketFormatter(types), [types]);
  
  const createOpenHandler = ticket => () => openTicket(ticket.ticketNumber);
  
  return (
    <div>
      <Panel bsStyle='info'>
        <Panel.Heading>Tickets</Panel.Heading>
        <Panel.Body>
          <TicketsTable
            loading={loading}
            tickets={tickets || []}
            tagList={tagList}
            selectedTicket={selectedTicket}
            properties={CONTRACT_TICKET_PROPERTIES}
            formatTicket={formatContractTicket}
            pagination={PAGINATION.BOTTOM}
            actions={ticket => (
              <ContractTicketsTableRowActions
                ticket={ticket}
                transferTicket={transferTicket}
                handleOpen={createOpenHandler(ticket)}
              />
            )}
          />
        </Panel.Body>
      </Panel>
    </div>
  );
});

ContractTicketsTable.propTypes = {
  openTicket: PropTypes.func,
  transferTicket: PropTypes.func,
  tickets: PropTypes.array,
  tagList: PropTypes.array,
  selectedTicket: PropTypes.object,
  loading: PropTypes.bool,
};

export default ContractTicketsTable;