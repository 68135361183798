import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { compose, pure, withHandlers, withState } from 'recompose';

import withLoading from '../../../../../../hoc/withLoading';
import jsRoutes from '../../../../../../routes/routes';

const SwitchChannelAndDevicePure = ({ switchChannelAndDeviceTarget, handleSwitchChannelAndDevice, setShowSwitchChannelAndDevice, setSwitchChannelAndDeviceTarget }) => (
    <Modal show={true} onHide={() => setShowSwitchChannelAndDevice(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Échanger les données et la tablette</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FormGroup>
                <ControlLabel>Numéro de contrat</ControlLabel>
                <FormControl type='text' value={switchChannelAndDeviceTarget}
                             onChange={(event) => setSwitchChannelAndDeviceTarget(event.target.value)}/>

            </FormGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button bsStyle='success' onClick={() => setShowSwitchChannelAndDevice(false)}>
                Annuler
            </Button>
            <Button bsStyle='danger' className='pull-left' onClick={handleSwitchChannelAndDevice}>
                Échanger
            </Button>
        </Modal.Footer>
    </Modal>
);

SwitchChannelAndDevicePure.propTypes = {
    switchChannelAndDeviceTarget: PropTypes.string,
    handleSwitchChannelAndDevice: PropTypes.func,
    setSwitchChannelAndDeviceTarget: PropTypes.func,
};

const withSwitchChannelAndDeviceTargetState = withState('switchChannelAndDeviceTarget', 'setSwitchChannelAndDeviceTarget', '');

const switchChannelAndDevice = (contractNumber, switchChannelAndDeviceTarget) => jsRoutes.controllers.backoffice.MaintenanceV2Controller.switchChannelAndDevice(contractNumber, switchChannelAndDeviceTarget).ajax();

const handlers = withHandlers({
    handleSwitchChannelAndDevice: ({ contractNumber, switchChannelAndDeviceTarget, handleReloadMaintenanceInfo, setShowSwitchChannelAndDevice, setLoading }) => () => {
        setLoading(
            true,
            () => switchChannelAndDevice(contractNumber, switchChannelAndDeviceTarget).then(
                () => setShowSwitchChannelAndDevice(false, handleReloadMaintenanceInfo),
                () => setLoading(false),
            )
        );
    },
});

const SwitchChannelAndDevice = compose(
    withLoading,
    withSwitchChannelAndDeviceTargetState,
    handlers,
    pure,
)(SwitchChannelAndDevicePure);

SwitchChannelAndDevice.propTypes = {
    contractNumber: PropTypes.string,
    handleReloadMaintenanceInfo: PropTypes.func,
    setShowSwitchChannelAndDevice: PropTypes.func,
};

export default SwitchChannelAndDevice;