import { branch, compose, renderComponent, withState } from 'recompose';
import Loading from '../views/components/Loading';

const withLoadingState = withState('loading', 'setLoading', false);

const withLoading = compose(
    withLoadingState,
    branch(
        (props) => props.loading || props?.data?.loading,
        renderComponent(Loading),
    ),
);

export default withLoading;