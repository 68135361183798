import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {branch, compose, pure, renderNothing, withHandlers, withState} from 'recompose';
import PropTypes from 'prop-types';

import {withCancelAfterSaleMutation} from '../../../../../Tickets/CreateNewTicketsPanel/AfterSaleTabletReturnForm/TabletReturnForm/withAfterSaleMutation';

const CancelAfterSalePure = ({ afterSale, handleCancel, showConfirmation, setShowConfirmation }) => (
  <>
      <Button bsStyle='danger' bsSize='xsmall' onClick={() => setShowConfirmation(true)}>Annuler</Button>
      <Modal show={showConfirmation}>
          <ModalHeader>
              <ModalTitle>Annulation du retour</ModalTitle>
          </ModalHeader>
          <ModalBody>
              Avant de valider votre demande d'annulation avez-vous bien vérifié que le BR, via le tracking N°{afterSale?.afterSaleRequest?.trackingNumber || 'N/A'}, n'a pas été utilisé ?
              <br /><br />
              Si le BR n'a pas été utilisé, vous pouvez valider votre demande.
          </ModalBody>
          <ModalFooter>
              <Button bsStyle='danger' className={'pull-left'} onClick={() => setShowConfirmation(false)}>Fermer</Button>
              <Button bsStyle='success' className={'pull-right'} onClick={handleCancel}>Valider l'annulation</Button>
          </ModalFooter>
      </Modal>
  </>
);

CancelAfterSalePure.propTypes = {
    afterSale: PropTypes.object,
    showConfirmation: PropTypes.bool,
    handleCancel: PropTypes.func,
    setShowConfirmation: PropTypes.func,
};

const withShowConfirmationState = withState('showConfirmation', 'setShowConfirmation', false);

const handlers = withHandlers({
    handleCancel: ({ afterSale, cancelAfterSale }) => () => {
        cancelAfterSale({
            variables: {
                afterSaleOrderCancelInput: {
                    afterSaleOrderID: afterSale.id,
                },
            },
        })
    },
});

const CancelAfterSale = compose(
    branch(
        ({ afterSale }) => !afterSale?.currentState?.canBeCancelled,
        renderNothing,
    ),
    withShowConfirmationState,
    withCancelAfterSaleMutation,
    handlers,
    pure,
)(CancelAfterSalePure);

CancelAfterSale.propTypes = {
    afterSale: PropTypes.object,
};

export default CancelAfterSale;