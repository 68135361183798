import PropTypes from 'prop-types';
import React from 'react';
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';
import {getSimPlanFromState} from '../../../../../utils/Sim';

const SimPlanPure = ({plan}) => (
  <>
    <FormGroup>
      <ControlLabel>Forfait</ControlLabel>
      <FormControl type="text"
                   value={plan}
                   readOnly={true}
      />
    </FormGroup>
  </>
);

SimPlanPure.propTypes = {
  plan: PropTypes.string,
};

const SimPlan = compose(
  withProps(
    ({dmd}) => ({
      plan: getSimPlanFromState(dmd?.sim?.currentState),
    }),
  ),
  pure,
)(SimPlanPure);

SimPlan.propTypes = {
  dmd: PropTypes.object,
};

export default SimPlan;