import PropTypes from 'prop-types';
import React from 'react';
import {Grid} from 'react-bootstrap';
import ContractTickets from '../Tickets/ContractTickets';

const SpecialContract = ({
  header,
  contractNumber,
  loading,
  progress,
  ticketNumber,
  tags,
  tickets,
  addTicket,
  deleteTicket,
  removeTicket,
  setTicket,
  setAnswer,
}) => (
  <>
    <Grid>
      {header}
    </Grid>
    <hr/>
    <Grid fluid>
      <ContractTickets
        contractNumber={contractNumber}
        loading={loading}
        progress={progress}
        ticketNumber={ticketNumber}
        tags={tags}
        tickets={tickets}
        deleteTicket={deleteTicket}
        removeTicket={removeTicket}
        addTicket={addTicket}
        setTicket={setTicket}
        setAnswer={setAnswer}
      />
    </Grid>
  </>
);

SpecialContract.propTypes = {
  contractNumber: PropTypes.string,
  loading: PropTypes.bool,
  progress: PropTypes.number,
  ticketNumber: PropTypes.string,
  tags: PropTypes.array,
  tickets: PropTypes.array,
  deleteTicket: PropTypes.func,
  removeTicket: PropTypes.func,
  setTicket: PropTypes.func,
  setAnswer: PropTypes.func,
};

export default SpecialContract;