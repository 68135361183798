import PropTypes from 'prop-types';
import React from 'react';
import {ButtonGroup} from 'react-bootstrap';
import {compose, getContext, pure} from 'recompose';
import NeedActivationButton from './ActionButtons/NeedActivationButton';
import NeedTerminationButton from './ActionButtons/NeedTerminationButton';
import SetActivatedButton from './ActionButtons/SetActivatedButton';
import SetTerminatedButton from './ActionButtons/SetTerminatedButton';

const UnknownGroupPure = ({
                            displayVertical,
                          }) => (
  <ButtonGroup vertical={displayVertical} block={displayVertical}>
    <NeedTerminationButton/>

    <NeedActivationButton/>

    <SetActivatedButton
      bsStyle='info'
      force
      label="Confirmer l'activation"/>

    <SetTerminatedButton/>
  </ButtonGroup>
);

UnknownGroupPure.propTypes = {
  displayVertical: PropTypes.bool,
};

const UnknownButtonGroup = compose(
  getContext(
    {
      displayVertical: PropTypes.bool,
    },
  ),
  pure,
)(UnknownGroupPure);

export default UnknownButtonGroup;