import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, withState } from 'recompose';
import FSPlusAddressEdit from './FSPlusAddress/FSPlusAddressEdit';
import FSPlusAddressSearch from './FSPlusAddress/FSPlusAddressSearch';

const withAdressState = withState('address', 'setAddress', null);
const withZipCodeState = withState('zipCode', 'setZipCode', '');

const FSPlusAddressPure = ({ address, zipCode, handleAddressChange }) => (
    <Fragment>
        <FSPlusAddressSearch handleAddressChange={handleAddressChange} />
        { address && zipCode && <FSPlusAddressEdit address={address} zipCode={zipCode} handleAddressChange={handleAddressChange} /> }
    </Fragment>
);

FSPlusAddressPure.propTypes = {
    address: PropTypes.object,
    zipCode: PropTypes.string,
    handleAddressChange: PropTypes.func,
};

const withAddressFoundHandler = withHandlers({
    handleAddressChange: ({ setAddress, setZipCode }) => (address, zipCode) =>
        setAddress(
            address,
            () => setZipCode(zipCode)
        ),
});

const FSPlusAddress = compose(
    withAdressState,
    withZipCodeState,
    withAddressFoundHandler,
    pure,
)(FSPlusAddressPure);

export default FSPlusAddress;