import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {connectTypesContext} from '../../../../hoc/withMeAndTeamsAndConstants';
import {RETURN_TYPES} from '../../../../utils/AfterSale';
import {SAUVEGARDE_PREFIX, TICKET_STATES, TICKET_SUBJECTS} from '../../../../utils/constants';
import {checkIfIsOtherResult, getResultType} from '../../../../utils/functions';
import CloseTicketModal from '../../modals/CloseTicketModal';
import TabletReturnForm from './TabletReturnForm';

const AfterSaleTabletReturnForm = connectTypesContext(({ types, editedAfterSale, isEditMode, ticket, options, addTicketTags, handleSave, handleTicketChange, onHide }) => {

	const [handleSaveAfterSale, setHandleSaveAfterSale] = useState(() => () => console.error('handleSaveAfterSale not initialized'));
	const [showResultModal, setShowResultModal] = useState(false);

	let clotures = [];
	let returnType;

	if (isEditMode) {
		returnType = editedAfterSale?.afterSaleRequest?.returnType;
	} else {
		clotures = options.type_cloture.filter(type_cloture => type_cloture.startsWith(`${ticket.subject}.`));

		switch (ticket.subject) {
			case TICKET_SUBJECTS.SAV:
				returnType = RETURN_TYPES.AFTER_SALE_SERVICE.value;
				break;
			case TICKET_SUBJECTS.SATISFAIT_REMBOURSE:
				returnType = RETURN_TYPES.SATISFIED_OR_REFUNDED.value;
				clotures = clotures.filter(cloture => {
					const resultType = getResultType(types, cloture);
					return resultType !== 'SAUVEGARDE' && !resultType.startsWith(SAUVEGARDE_PREFIX);
				});
				break;
			case TICKET_SUBJECTS.RETOUR_TABLETTE:
				returnType = RETURN_TYPES.WITHOUT_REFUND.value;
				break;
			default:
		}
	}

	if (!returnType) return null;

	const handleTicketClosure = ({ result, firstIntention, resultComment }) => {
		ticket.state = TICKET_STATES.DONE;
		ticket.result = firstIntention !== '' ? firstIntention : result;
		ticket.resultComment = checkIfIsOtherResult(result) && resultComment ? resultComment : '';
		ticket = addTicketTags(ticket);

		handleTicketChange(ticket);
		handleSave(handleSaveAfterSale);
		setShowResultModal(false);
	}

	const handleValidateAfterSale = handleSaveAfterSale => {
		if (!isEditMode) {
			ticket.state = TICKET_STATES.DONE;
			handleTicketChange(ticket);
			setHandleSaveAfterSale(
				() => handleSaveAfterSale,
			);
			setShowResultModal(true);
		}
	}

	const handleHide = () => setShowResultModal(false);

	return (
    <>
			<TabletReturnForm
				editedAfterSale={editedAfterSale}
				isEditMode={isEditMode}
				returnType={returnType}
				handleValidateAfterSale={handleValidateAfterSale}
				onHide={onHide}
			/>

			{!isEditMode && showResultModal && (
				<CloseTicketModal
					subjectResultOptions={clotures}
					ticket={ticket}
					onHide={handleHide}
					onSubmit={handleTicketClosure}
				/>
			)}
    </>
	);
});

AfterSaleTabletReturnForm.propTypes = {
	editedAfterSale: PropTypes.object,
	isEditMode: PropTypes.bool,
	options: PropTypes.object,
	ticket: PropTypes.object,
	addTicketTags: PropTypes.func,
	handleSave: PropTypes.func,
	handleTicketChange: PropTypes.func,
	onHide: PropTypes.func,
};

export default AfterSaleTabletReturnForm;