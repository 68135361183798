import React from 'react';
import { Button, ButtonGroup, Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose';
import { notify } from 'react-notify-toast';

import withLoading from '../../../../../hoc/withLoading';
import ContractTags from '../../../../components/ContractTags';

import AndroidUpdateSelect from './AccountMaintenances/AndroidUpdateSelect';
import AccountMaintenanceEdit from './AccountMaintenances/AccountMaintenanceEdit';
import AccountMaintenanceTable from './AccountMaintenances/AccountMaintenanceTable';
import PasswordEdit from './AccountMaintenances/PasswordEdit';
import RemoveChannelModal from './AccountMaintenances/RemoveChannelModal';
import ResetAuthorizedSelect from './AccountMaintenances/ResetAuthorizedSelect';
import SwitchChannelAndDevice from './AccountMaintenances/SwitchChannelAndDeviceModal';
import MailConfiguration from './MailConfiguration';
import SynchronizationTest from './SynchronizationTest';
import { fetchMaintenances } from '../../../../../utils/requests';

const getGoogleAccountLabelFromValue = (hasGoogleAccount) => hasGoogleAccount ? 'Oui' : (hasGoogleAccount === false ? 'Non' : 'NA');

const AccountMaintenancesPure = ({
	contractNumber,
	loading,
	maintenanceInfo,
	handleAddMaintenance,
	handleReloadMaintenanceInfo,
	handleReloadMaintenances,
	handleUpdateMaintenanceInfo,
	setLoading,
	setMaintenanceInfo,
	showCreateMaintenance,
	showRemoveChannel,
	showSwitchChannelAndDevice,
	setShowCreateMaintenance,
	setShowRemoveChannel,
	setShowSwitchChannelAndDevice,
}) => (
	<>
		{showCreateMaintenance && (
			<AccountMaintenanceEdit
				contractNumber={contractNumber}
				maintenanceInfo={maintenanceInfo}
				handleAddMaintenance={handleAddMaintenance}
				onHide={() => setShowCreateMaintenance(false)}
				setMaintenanceInfo={setMaintenanceInfo}
			/>
		)}

		{showRemoveChannel && (
			<RemoveChannelModal
				onHide={() => setShowRemoveChannel(false)}
				contractNumber={contractNumber}
			/>
		)}

		{showSwitchChannelAndDevice && (
			<SwitchChannelAndDevice
				setShowSwitchChannelAndDevice={setShowSwitchChannelAndDevice}
				handleReloadMaintenanceInfo={handleReloadMaintenanceInfo}
				contractNumber={contractNumber}
			/>
		)}

		<SynchronizationTest contractNumber={contractNumber} />

		<AccountMaintenanceTable
			contractNumber={contractNumber}
			maintenances={maintenanceInfo.maintenances}
			handleCreateMaintenance={() => setShowCreateMaintenance(true)}
			handleUpdateMaintenanceInfo={handleUpdateMaintenanceInfo}
			handleReloadMaintenances={handleReloadMaintenances}
		/>

		<hr/>

		<Row>
			<Col md={6}>
				<FormGroup>
					<ControlLabel>Channel</ControlLabel>
					<FormControl type='text' value={maintenanceInfo.channel} readOnly />
				</FormGroup>
			</Col>

			<Col md={6}>
				<ButtonGroup vertical block>
					<Button bsStyle='danger' bsSize='xsmall' onClick={() => setShowRemoveChannel(true)} disabled={loading}>
						Supprimer le channel
					</Button>
					<Button bsStyle='primary' bsSize='xsmall' onClick={() => setShowSwitchChannelAndDevice(true)} disabled={loading}>
						Échanger les données et la tablette
					</Button>
				</ButtonGroup>
			</Col>
		</Row>

		<hr/>

		<Row>
			<Col md={6}>
				<FormGroup>
					<ControlLabel>User SG</ControlLabel>
					<FormControl type='text' value={maintenanceInfo.sgUser} readOnly />
				</FormGroup>
			</Col>

			<Col md={6}>
				<PasswordEdit
					contractNumber={contractNumber}
					sgPassword={maintenanceInfo.sgPassword}
					handleUpdateMaintenanceInfo={handleUpdateMaintenanceInfo}
				/>
			</Col>
		</Row>

		<hr/>

		<Row>
			<Col md={6}>
				<FormGroup>
					<ControlLabel>Lock</ControlLabel>
					<FormControl
						type='text'
						value={!maintenanceInfo.lockPassword ? 'Désactivé' : 'Activé (' + maintenanceInfo.lockPassword + ')'}
						readOnly
					/>
				</FormGroup>
			</Col>

			<Col md={6}>
				<FormGroup>
					<ControlLabel>Data 3G</ControlLabel>
					<FormControl type='text' value={maintenanceInfo.consoDataValue} readOnly />
				</FormGroup>
			</Col>
		</Row>

		<Row>
			<Col md={6}>
				<ControlLabel>Tags</ControlLabel>
				<ContractTags
					tagsOptions={maintenanceInfo.contractTags}
					contractNumber={contractNumber}
				/>
			</Col>
		</Row>

		<Row>
			<Col md={6}>
				<ResetAuthorizedSelect
					contractNumber={contractNumber}
					resetAuthorized={maintenanceInfo.resetAuthorized}
					resetAuthorizedMode={maintenanceInfo.resetAuthorizedMode}
					setLoading={setLoading}
					updateMaintenanceInfo={handleUpdateMaintenanceInfo}
				/>
			</Col>
			<Col md={6}>
				<ControlLabel>Compte Google</ControlLabel>
				<FormControl
					type='text'
					value={getGoogleAccountLabelFromValue(maintenanceInfo.hasGoogleAccount)}
					readOnly
				/>
			</Col>
		</Row>

		<Row>
			<Col md={6}>
				<AndroidUpdateSelect
					contractNumber={contractNumber}
					supportChannels={maintenanceInfo.supportChannels}
				/>
			</Col>
		</Row>

		<MailConfiguration contractNumber={contractNumber} />
	</>
);

AccountMaintenancesPure.propTypes = {
	loading: PropTypes.bool,
	maintenanceInfo: PropTypes.object,
	showCreateMaintenance: PropTypes.bool,
	showRemoveChannel: PropTypes.bool,
	showSwitchChannelAndDevice: PropTypes.bool,
	handleAddMaintenance: PropTypes.func,
	handleReloadMaintenanceInfo: PropTypes.func,
	handleReloadMaintenances: PropTypes.func,
	handleUpdateMaintenanceInfo: PropTypes.func,
	setLoading: PropTypes.func,
	setMaintenanceInfo: PropTypes.func,
	setShowCreateMaintenance: PropTypes.func,
	setShowRemoveChannel: PropTypes.func,
	setShowSwitchChannelAndDevice: PropTypes.func,
};

const maintenanceInfoDefaultState = {
	channel: '',
	consoData: null,
	consoDataValue: '',
	contractTags: [],
	lockPassword: null,
	maintenances: [],
	resetAuthorized: false,
	resetAuthorizedMode: null,
	sgPassword: '',
	sgUser: '',
	supportChannels: undefined,
	types: null,
};

const withMaintenanceInfoState = withState('maintenanceInfo', 'setMaintenanceInfo', maintenanceInfoDefaultState);
const withShowCreateMaintenanceState = withState('showCreateMaintenance', 'setShowCreateMaintenance', false);
const withShowRemoveChannelState = withState('showRemoveChannel', 'setShowRemoveChannel', false);
const withShowSwitchChannelAndDeviceState = withState('showSwitchChannelAndDevice', 'setShowSwitchChannelAndDevice', false);

const ERROR_MESSAGES = {
	RELOAD_MAINTENANCES: 'Error lors du chargement des maintenances',
};

const handleError = (errorMsg) => {
	notify.show(errorMsg, 'error', 3000);
};

const getConsoDataValueFromConsoData = (consoData) => consoData && consoData > 0 ? `${Math.floor(consoData / 1048576)} Mo` : 'NA';

const withLifecycle = lifecycle({
	componentDidMount() {
		const { setMaintenanceInfo, setLoading } = this.props;

		fetchMaintenances(this.props.contractNumber).then(
			(response) =>
				setMaintenanceInfo({
						...response,
						consoDataValue: getConsoDataValueFromConsoData(response.consoData),
						originalSGPassword: response.sgPassword,
					},
					() => setLoading(false)
				),
			() => setLoading(false),
		);
	},
});

const handlers = withHandlers({
	handleReloadMaintenanceInfo: ({ contractNumber, setLoading, setMaintenanceInfo }) => () =>
		setLoading(
			true,
			() => fetchMaintenances(contractNumber).then(
				(response) => {
					const maintenanceInfo = response || maintenanceInfoDefaultState;
					setLoading(
						false,
						() => setMaintenanceInfo({
							...maintenanceInfo,
							consoDataValue: getConsoDataValueFromConsoData(maintenanceInfo.consoData),
							originalSGPassword: maintenanceInfo.sgPassword,
						})
					);
				},
				() => setLoading(false),
			)
		),
	handleReloadMaintenances: ({ contractNumber, maintenanceInfo, setMaintenanceInfo }) => (setLoading) =>
		setLoading(
			true,
			() => fetchMaintenances(contractNumber).then(
				({ maintenances }) =>
					setLoading(
						false,
						() => setMaintenanceInfo({
							...maintenanceInfo,
							maintenances: maintenances || maintenanceInfoDefaultState.maintenances,
						})
					),
				() => {
					setLoading(
						false,
						() => setMaintenanceInfo({
							...maintenanceInfo,
							maintenances: maintenanceInfoDefaultState.maintenances,
						})
					);
					handleError(ERROR_MESSAGES.RELOAD_MAINTENANCES);
				},
			)
		),
	handleUpdateMaintenanceInfo: ({ maintenanceInfo, setMaintenanceInfo }) => (values, callback) =>
		setMaintenanceInfo({
			...maintenanceInfo,
			...values,
		}, callback),
});

const AccountMaintenances = compose(
	withShowCreateMaintenanceState,
	withShowRemoveChannelState,
	withShowSwitchChannelAndDeviceState,
	pure,
	withMaintenanceInfoState,
	withLoading,
	withLifecycle,
	handlers,
)(AccountMaintenancesPure);

AccountMaintenances.propTypes = {
	contractNumber: PropTypes.string,
};

export default AccountMaintenances;