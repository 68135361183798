import React from 'react';
import { Label } from 'react-bootstrap';

import { connectTypesContext } from '../../hoc/withMeAndTeamsAndConstants';
import { getStateType } from '../../utils/functions';

const TicketState = connectTypesContext(({types, value}) => {

	const stateLabel = getStateType(types, value);

	if (value === '0') {
		return <Label bsStyle='warning'>{stateLabel}</Label>
	}

	if (value === '1') {
		return <Label bsStyle='success'>{stateLabel}</Label>
	}

	if (value === '2') {
		return <Label>{stateLabel}</Label>
	}

	return null;
});

export default TicketState;