import moment from 'moment';
moment.locale('fr');

const toFrenchDateString = date => date ? moment(date).format('DD/MM/YYYY') : '';

const tsToDateForInput = (ts, withoutHours) => {
    if (typeof ts !== 'number') {
        return '-';
    }
    if (withoutHours) {
        return moment(ts).format('L');
    } else {
        return moment(ts).format('L') + ' ' + moment(ts).format('LT');
    }
};

export const tsToFullDate = (ts) => {

    if (typeof ts !== 'number') {
        return '-';
    }

    return moment(ts).format('L') + ' ' + moment(ts).format('LTS');
};

const getTodayMidday = () => getMidday(moment());

const getNextMonthFirstDayMidday = day =>
    getMidday(
        moment(day)
            .add(1, 'month')
            .startOf('month')
    );

const getMidday = day =>
    moment(day)
        .startOf('day')
        .add('12', 'hours');

const getToday = () => moment().startOf('day');

const isDateInFuture = date => moment(date).startOf('day').isAfter(getToday());
const isDateNowOrInFuture = date => moment(date).startOf('day').isSameOrAfter(getToday());

export {
    toFrenchDateString,
    tsToDateForInput,
    getTodayMidday,
    getNextMonthFirstDayMidday,
    getMidday,
    getToday,
    isDateInFuture,
    isDateNowOrInFuture,
}