import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import TicketClient from './TicketClient';

const TicketDetailsModal = ({ contract, dmd, setLoading, ticket, show, handleHide, handleManageSim, content, actions }) => {
  return (
    <Modal show={show} bsSize='large' onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>{ticket?.ticketNumber}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TicketClient
          contract={contract}
          dmd={dmd}
          ticket={ticket}
          handleManageSim={handleManageSim}
          setLoading={setLoading}
        />
        {content}
      </Modal.Body>
      <Modal.Footer>
        {actions}
      </Modal.Footer>
    </Modal>
  );
}

TicketDetailsModal.propTypes = {
  contract: PropTypes.object,
  dmd: PropTypes.object,
  setLoading: PropTypes.func,
  ticket: PropTypes.object,
  show: PropTypes.bool,
  handleHide: PropTypes.func,
  handleManageSim: PropTypes.func,
  content: PropTypes.node,
  actions: PropTypes.node,
};

export default TicketDetailsModal;